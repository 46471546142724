import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  CheckIcon,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Menu,
  Modal,
  Notification,
  NumberFormatter,
  NumberInput,
  ScrollArea,
  Select,
  SelectProps,
  Table,
  Tabs,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { DateInput, DatePickerInput } from "@mantine/dates";
import {
  IconAlertCircle,
  IconBrandBootstrap,
  IconBuildingFactory2,
  IconCheck,
  IconDiscount,
  IconInfoCircle,
  IconMessageCircle,
  IconMinus,
  IconPhoto,
  IconPlus,
  IconSearch,
  IconSettings,
  IconTicket,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { _localization } from "../../../config/location";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import EditableTextWithOptions from "../../../common/selectValue";
import { MessageResponse } from "../../../model/MessageResponse";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { EmployeeModel } from "../../../model/Employee";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  useClickOutside,
  useDebouncedState,
  useDisclosure,
  useMediaQuery,
} from "@mantine/hooks";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { notifications } from "@mantine/notifications";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { getListItem } from "../../../api/apiItem";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import { isNullOrUndefinedArry } from "../../../_base/extension/StringExtension";
import { ProductModel } from "../../../model/TblDMItem";
import { orange } from "../../../const/variables";

interface SearchParams {
  searchKey: string;
  searchFrom: string;
  searchTo: string;
  handeDateStart: Date | null;
  handeDateEnd: Date | null;
}

interface PoLineModel {
  poLineId: number | null;
  poHeaderId: number | null;
  lineTypeId: number | null;
  lineNum: number | null;
  itemId: number | null;
  itemDescription: string | null;
  uom: string | null;
  unitPrice: number | null;
  quantity: number | null;
  noteToVendor: string | null;
  fromHeaderQuotationId: number | null;
  fromLineQuotationId: number | null;
  minOrderQuantity: number | null;
  maxOrderQuantity: number | null;
  qtyRcvTolerance: number | null;
  closedFlag: string | null;
  userHoldFlag: string | null;
  cancelFlag: string | null;
  cancelledBy: number | null;
  cancelDate: string | null;
  cancelReason: string | null;
  taxCodeId: number | null;
  taxableFlag: string | null;
  taxName: string | null;
  attribute1: string | null;
  attribute2: string | null;
  attribute3: string | null;
  attribute4: string | null;
  minReleaseAmount: number | null;
  closedDate: string | null;
  closedReason: string | null;
  closedBy: number | null;
  orgId: number | null;
  primaryUom: string | null;
  primaryQty: number | null;
  createdDate?: string; // Thêm dấu "?" để cho phép giá trị null
  createdBy: number | null;
  lastUpdateDate: string | null;
  lastUpdatedBy: number | null;
  totalAmout: number | null;
}

interface Item {
  itemId: number;
  categoryId: number;
  barcode: string | null;
  itemCode: string;
  oldItemCode: string;
  itemName: string;
  uomCodeId: number;
  primaryUomCodeId: number;
  poPrice: number | null;
  description: string;
  active: number;
  checkDuobleBarcode: number;
  itemShortName: string | null;
  discount: number;
  warrantyVendor: number;
  warranty: number;
  vatRate: number;
  isPromoteOnly: number;
  isSparePart: number;
  isOnhand: number;
  isShowWeb: boolean | null;
  attribute1: string;
  attribute2: string | null;
  attribute3: string | null;
  attribute4: string | null;
  attribute5: string | null;
  attribute6: string | null;
  attribute7: string | null;
  attribute8: string | null;
  attribute9: string | null;
  attribute10: string | null;
  attribute11: string | null;
  attribute12: string | null;
  attribute13: string | null;
  attribute14: string | null;
  attribute15: string | null;
  createBy: string | null;
  createDate: Date | null;
  lastUpdateBy: string | null;
  lastUpdateDate: Date | null;
  segment1: string;
  segment2: string;
  segment3: string;
  segment4: string;
  segment5: string;
  segment6: string;
}

interface PurchaseOrder {
  poHeaderId: number | null;
  buyerId: number | null;
  type: string | null;
  poNumber: string | null | undefined;
  vendorId: number | null;
  vendorSiteId: number | null;
  vendorContactId: number | null;
  noteToVendor: string | null;
  shipToLocationId: number | null;
  billToLocationId: number | null;
  termsId: number | null;
  status: string | null;
  currencyCode: string | null;
  rateType: string | null;
  rateDate: string | null;
  rate: number | null;
  fromHeaderId: number | null;
  fromTypeLookupCode: string | null;
  startDate: string | null;
  endDate: string | null;
  approvedFlag: number | null; // Đây là anh (đổi thành kiểu ( một  hoặc không ))
  approvedDate: string | null;
  closedDate: string | null;
  blanketNumber: number | null; // Đây nữa
  blanketTotalAmount: number | null;
  attribute1: string | null;
  attribute2: string | null;
  attribute3: string | null;
  amountLimit: number | null;
  minReleaseAmount: number | null;
  rfqNumber: string | null;
  rfqAttached: string | null;
  replyDate: string | null;
  replyMethodLookupCode: string | null;
  rfqCloseDate: string | null;
  quotationNumber: string | null;
  quotationAttached: string | null;
  quoteTypeLookupCode: string | null;
  quotationClassCode: string | null;
  acceptanceRequiredFlag: boolean | null;
  acceptanceDueDate: string | null;
  userHoldFlag: boolean | null;
  noteToReceiver: string | null;
  comments: string | null;
  cancelFlag: string | null;
  orgId: number | null;
  creationDate: string | null;
  createdBy: number | null;
  lastUpdateDate: string | null;
  lastUpdatedBy: number | null;
  tblPoLineModels: PoLineModel[];
}

interface DocumentType {
  value: number;
  text: string;
}

interface WarehouseOrDepartment {
  id: number;
  name: string;
}

interface Contact {
  id: number;
  name: string;
  phone: string;
}

interface DeliveryLocation {
  id: number;
  name: string;
  address: string;
}

interface PaymentLocation {
  id: number;
  name: string;
  address: string;
}

const documentTypes: DocumentType[] = [
  { value: 1, text: "PO standard" },
  { value: 2, text: "PO planed" },
  { value: 3, text: "PO planket (hd khung)" },
  { value: 4, text: "Thư báo giá" },
  { value: 5, text: "Thư hỏi giá" },
];

const blanketNumberSelect: DocumentType[] = [
  { value: 0, text: "Chấp thuận" },
  { value: 1, text: "Từ Chối" },
];

const approvedFlagSelect: DocumentType[] = [
  { value: 0, text: "Đã phê duyệt" },
  { value: 1, text: "Chưa phê duyệt" },
];

const newWarehouseOrDepartments: WarehouseOrDepartment[] = [
  { id: 501, name: "Kho tổng Đà Nẵng" },
  { id: 502, name: "Kho tổng Nha Trang" },
  { id: 503, name: "Kho tổng Cần Thơ" },
  { id: 504, name: "Kho tổng Biên Hòa" },
  { id: 505, name: "Kho tổng Bắc Ninh" },
  { id: 506, name: "Phòng Kỹ thuật Sản xuất" },
  { id: 507, name: "Phòng Quản lý Dự án" },
  { id: 508, name: "Phòng Giao nhận" },
  { id: 509, name: "Phòng Hành chính Tổng hợp" },
  { id: 510, name: "Phòng Kinh doanh Trực tiếp" },
  { id: 511, name: "Tổng Kho Đại lý" },
  { id: 512, name: "Kho tổng Bình Thạnh" },
  { id: 513, name: "Kho tổng Thủ Đức" },
  { id: 514, name: "Kho tổng Vũng Tàu" },
  { id: 515, name: "Kho tổng Hạ Long" },
  { id: 516, name: "Kho tổng Mỹ Đình" },
  { id: 517, name: "Kho tổng Hải Dương" },
  { id: 518, name: "Kho tổng Nghệ An" },
  { id: 519, name: "Kho tổng Đồng Nai" },
  { id: 520, name: "Kho tổng Hà Nam" },
  { id: 521, name: "Kho tổng Điện Biên" },
];

const contacts: Contact[] = [
  { id: 601, name: "Nguyễn Văn A", phone: "0123-456-789" },
  { id: 602, name: "Trần Thị B", phone: "0123-456-788" },
  { id: 603, name: "Lê Văn C", phone: "0123-456-787" },
  { id: 604, name: "Phạm Thị D", phone: "0123-456-786" },
  { id: 605, name: "Nguyễn Thị E", phone: "0123-456-785" },
  { id: 606, name: "Vũ Văn F", phone: "0123-456-784" },
  { id: 607, name: "Hoàng Thị G", phone: "0123-456-783" },
  { id: 608, name: "Nguyễn Văn H", phone: "0123-456-782" },
  { id: 609, name: "Trần Văn I", phone: "0123-456-781" },
  { id: 610, name: "Lê Thị J", phone: "0123-456-780" },
  { id: 611, name: "Phạm Văn K", phone: "0123-456-779" },
  { id: 612, name: "Vũ Thị L", phone: "0123-456-778" },
  { id: 613, name: "Nguyễn Văn M", phone: "0123-456-777" },
  { id: 614, name: "Trần Thị N", phone: "0123-456-776" },
  { id: 615, name: "Lê Văn O", phone: "0123-456-775" },
  { id: 616, name: "Nguyễn Thị P", phone: "0123-456-774" },
  { id: 617, name: "Hoàng Văn Q", phone: "0123-456-773" },
  { id: 618, name: "Phạm Văn R", phone: "0123-456-772" },
  { id: 619, name: "Vũ Thị S", phone: "0123-456-771" },
  { id: 620, name: "Nguyễn Văn T", phone: "0123-456-770" },
  { id: 621, name: "Trần Thị U", phone: "0123-456-769" },
];

const deliveryLocations: DeliveryLocation[] = [
  { id: 700, name: "Địa chỉ Giao hàng A", address: "124 Minh Khai" },
  { id: 701, name: "Địa chỉ Giao hàng A", address: "123 Đường ABC, Đà Nẵng" },
  { id: 702, name: "Địa chỉ Giao hàng B", address: "456 Đường DEF, Nha Trang" },
  { id: 703, name: "Địa chỉ Giao hàng C", address: "789 Đường GHI, Cần Thơ" },
  { id: 704, name: "Địa chỉ Giao hàng D", address: "321 Đường JKL, Biên Hòa" },
  { id: 705, name: "Địa chỉ Giao hàng E", address: "654 Đường MNO, Bắc Ninh" },
  { id: 706, name: "Địa chỉ Giao hàng F", address: "987 Đường PQR, Hạ Long" },
  { id: 707, name: "Địa chỉ Giao hàng G", address: "159 Đường STU, Vũng Tàu" },
  { id: 708, name: "Địa chỉ Giao hàng H", address: "753 Đường VWX, Thủ Đức" },
  { id: 709, name: "Địa chỉ Giao hàng I", address: "852 Đường YZ, Hải Dương" },
  { id: 710, name: "Địa chỉ Giao hàng J", address: "951 Đường AAB, Nghệ An" },
  { id: 711, name: "Địa chỉ Giao hàng K", address: "357 Đường CCD, Đồng Nai" },
  { id: 712, name: "Địa chỉ Giao hàng L", address: "159 Đường DDE, Hà Nam" },
  { id: 713, name: "Địa chỉ Giao hàng M", address: "753 Đường FFG, Điện Biên" },
  {
    id: 714,
    name: "Địa chỉ Giao hàng N",
    address: "951 Đường HHI, Bình Thạnh",
  },
  { id: 715, name: "Địa chỉ Giao hàng O", address: "357 Đường JJK, Long Biên" },
  { id: 716, name: "Địa chỉ Giao hàng P", address: "159 Đường KKL, Từ Sơn" },
  { id: 717, name: "Địa chỉ Giao hàng Q", address: "753 Đường LLM, Thanh Trì" },
  { id: 718, name: "Địa chỉ Giao hàng R", address: "951 Đường MNN, Đông Anh" },
  { id: 719, name: "Địa chỉ Giao hàng S", address: "357 Đường OOO, Bạc Liêu" },
  { id: 720, name: "Địa chỉ Giao hàng T", address: "159 Đường PPP, Hậu Giang" },
];

const paymentLocations: PaymentLocation[] = [
  { id: 800, name: "Địa điểm Thanh toán A", address: "131 Lê Thanh Nghị" },

  { id: 801, name: "Địa điểm Thanh toán A", address: "123 Đường ZYX, Hà Nội" },
  { id: 802, name: "Địa điểm Thanh toán B", address: "456 Đường QWE, Đà Nẵng" },
  { id: 803, name: "Địa điểm Thanh toán C", address: "789 Đường RTY, TP.HCM" },
  {
    id: 804,
    name: "Địa điểm Thanh toán D",
    address: "321 Đường UIO, Hải Phòng",
  },
  { id: 805, name: "Địa điểm Thanh toán E", address: "654 Đường PAS, Cần Thơ" },
  {
    id: 806,
    name: "Địa điểm Thanh toán F",
    address: "987 Đường DFG, Nha Trang",
  },
  {
    id: 807,
    name: "Địa điểm Thanh toán G",
    address: "159 Đường HJK, Thái Nguyên",
  },
  {
    id: 808,
    name: "Địa điểm Thanh toán H",
    address: "753 Đường LOP, Bắc Ninh",
  },
  {
    id: 809,
    name: "Địa điểm Thanh toán I",
    address: "852 Đường QRS, Biên Hòa",
  },
  {
    id: 810,
    name: "Địa điểm Thanh toán J",
    address: "951 Đường TUV, Long Biên",
  },
  { id: 811, name: "Địa điểm Thanh toán K", address: "357 Đường WXY, Vinh" },
  { id: 812, name: "Địa điểm Thanh toán L", address: "159 Đường ZZZ, Hạ Long" },
  { id: 813, name: "Địa điểm Thanh toán M", address: "753 Đường AAA, Đà Lạt" },
  {
    id: 814,
    name: "Địa điểm Thanh toán N",
    address: "951 Đường BBB, Bạc Liêu",
  },
  {
    id: 815,
    name: "Địa điểm Thanh toán O",
    address: "357 Đường CCC, Thái Bình",
  },
  {
    id: 816,
    name: "Địa điểm Thanh toán P",
    address: "159 Đường DDD, Hưng Yên",
  },
  {
    id: 817,
    name: "Địa điểm Thanh toán Q",
    address: "753 Đường EEE, Bình Dương",
  },
  {
    id: 818,
    name: "Địa điểm Thanh toán R",
    address: "951 Đường FFF, Quảng Ninh",
  },
  {
    id: 819,
    name: "Địa điểm Thanh toán S",
    address: "357 Đường GGG, Ninh Bình",
  },
  { id: 820, name: "Địa điểm Thanh toán T", address: "159 Đường HHH, Nghệ An" },
];

const orderTypes = [
  { value: "1", label: "Tạo mới", color: "blue" },
  { value: "2", label: "Đã duyệt", color: "green" },
  { value: "3", label: "Đóng đơn", color: "yellow" },
  { value: "4", label: "Hủy đơn", color: "red" },
];

interface ApiResponse {
  lists: PurchaseOrder[];
}

interface TableSelectProps {
  dataProducts: Item[];
  onSelectProduct: (product: Item) => void;
  rowIndex: any;
}

const ImportPurchasesDetail = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [dataListPoHeader, setDataListPoHeader] = useState<PurchaseOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(1);
  const [take, setTake] = useState<number>(30);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dataProducts, setDataProducts] = useState<ProductModel[]>([]);
  const [data, setData] = useState<PurchaseOrder | null>(null);
  const [dataPoHeader, setDataPoHeader] = useState<PurchaseOrder>();

  const [dataDetail, setDataDetail] = useState<any>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const [listAdd, setListAdd] = useState<PoLineModel[]>([]);

  const [dataListItem, setDataListItem] = useState<Item[]>([]);
  const [valueDebounced, setValueDebounced] = useDebouncedState("", 500);

  const [keySearch, setKeySearch] = useState<string>("");
  const [keepRef, setKeepRef] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const [dataListVendor, setDataListVendor] = useState<any[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [searchParams, setSearchParams] = useState<SearchParams>({
    searchKey: "",
    searchFrom: "",
    searchTo: "",
    handeDateStart: new Date(),
    handeDateEnd: null,
  });

  const location = useLocation();
  const valueDetail = location.state?.value || "No Value Passed";

  const [formData, setFormData] = useState({
    poHeaderId: null,
    buyerId: null,
    type: "PO standard",
    poNumber: "",
    vendorId: null,
    vendorSiteId: 511,
    vendorContactId: 601,
    noteToVendor: null,
    shipToLocationId: 700,
    billToLocationId: 800,
    termsId: null,
    status: "Tạo mới",
    currencyCode: "VND",
    rateType: null,
    rateDate: null,
    rate: null,
    fromHeaderId: null,
    fromTypeLookupCode: null,
    startDate: null,
    endDate: null,
    approvedFlag: 1, // Đây là anh (đổi thành kiểu ( một  hoặc không ))
    approvedDate: null,
    closedDate: null,
    blanketNumber: 0, // Đây nữa
    blanketTotalAmount: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    amountLimit: null,
    minReleaseAmount: null,
    rfqNumber: null,
    rfqAttached: null,
    replyDate: null,
    replyMethodLookupCode: null,
    rfqCloseDate: null,
    quotationNumber: null,
    quotationAttached: null,
    quoteTypeLookupCode: null,
    quotationClassCode: null,
    acceptanceRequiredFlag: null,
    acceptanceDueDate: null,
    userHoldFlag: null,
    noteToReceiver: "",
    comments: null,
    cancelFlag: null,
    orgId: null,
    creationDate: null,
    createdBy: null,
    lastUpdateDate: null,
    lastUpdatedBy: null,
    totalAmout: 210000,
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemId",
        header: "Mã hàng",
        enableEditing: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Text
            size="sm"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
            fw={600}
          >
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "itemDescription",
        header: "Tên hàng",
      },
      // {
      //   accessorKey: "unitPrice",
      //   header: "Giá",
      // },
      {
        accessorKey: "quantity",
        header: "Số lượng",
      },
      {
        accessorKey: "totalAmout",
        header: "Đơn giá",
        enableEditing: false,
        Cell: ({ row, column, table }) => {
          return (
            <NumberFormatter
              thousandSeparator
              value={row.original.itemId ? 210000 : 0}
            />
          );
        },
      },
      {
        accessorKey: "uom",
        header: "Đơn vị tính",
        enableEditing: false,
        Cell: ({ row, column, table }) => {
          return <Text> {row.original.itemId ? "Chiếc" : ""}</Text>;
        },
      },
      {
        accessorKey: "unitPrice",
        header: "Thành tiền",
        enableEditing: false,
        Cell: ({ row, column, table }) => {
          const totalPrice = row.original.unitPrice * row.original.quantity;
          console.log(totalPrice);
          return <NumberFormatter thousandSeparator value={totalPrice} />;
        },
      },
      {
        accessorKey: "noteToVendor",
        header: "Ghi chú",
      },
    ],
    [dataProducts, dataListItem]
  );

  const table = useMantineReactTable<any>({
    columns,
    data: dataDetail ?? [],
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: false,
    enableRowActions: false,

    localization: _localization,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        right: ["quantity", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height + 25, minHeight: height + 25 },
    },
    enableStickyHeader: true,

    renderTopToolbarCustomActions: ({ table }) => <></>,

    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "12.5px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: true,
    },
    mantineTopToolbarProps: {
      style: {
        display: "none",
      },
    },

    mantineBottomToolbarProps: {
      style: {
        display: "none",
      },
    },
  });

  const toLocations =
    dataListVendor?.find((item: any) => item.id === data?.vendorId)
      ?.vendorName || "";

  const vendorSite =
    newWarehouseOrDepartments?.find(
      (item: any) => item.id === data?.vendorSiteId
    )?.name || "";

  const contact =
    contacts?.find((item: any) => item.id === data?.vendorContactId)?.name ||
    "";

  const toLocationOrder =
    deliveryLocations?.find((item: any) => item.id === data?.shipToLocationId)
      ?.address || "";

  const billToLocation =
    paymentLocations?.find((item: any) => item.id === data?.billToLocationId)
      ?.address || "";

  useEffect(() => {
    const fetchDataDetail = async () => {
      const response = await repositoryMdm.get<MessageResponse<PurchaseOrder>>(
        `/api/v1/TblPoHeader/detail?id=${valueDetail}`
      );

      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataDetail(result.tblPoLineModels);
        setRowCount(response.totalCount);
      }
    };
    fetchDataDetail();
  }, [valueDetail]);

  const fetchDataListVendor = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/TblVendor/get-all");

    if (response && response.success) {
      let result = response.data;
      setDataListVendor(result);
    }
  };

  useEffect(() => {
    fetchDataListVendor();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (120 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify="space-between"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <_breadcrumb></_breadcrumb>
        </Flex>
      </div>
      <Grid>
        <GridCol style={{ boxShadow: "0 2px 2px #ccc" }} span={5}>
          <ScrollArea pr={10} scrollbars="y" h={height} offsetScrollbars>
            <Grid grow>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    Mã đơn:
                  </Text>
                  <Text fw="600">{data?.poNumber}</Text>
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    Loại đơn ĐH:
                  </Text>
                  <Text fw="600">{data?.type}</Text>
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    Nhà cung cấp
                  </Text>
                  <Text>{toLocations}</Text>
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    Loại tiền tệ
                  </Text>
                  <Text fw={600}>{formData?.currencyCode}</Text>
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    ĐĐ cung cấp
                  </Text>
                  <Text fw={600}>{vendorSite}</Text>
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    Người liên hệ
                  </Text>
                  <Text fw={600}>{contact}</Text>
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={20} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    ĐĐ giao hàng
                  </Text>
                  <Text fw={600}>{toLocationOrder}</Text>
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={20} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    ĐĐ thanh toán
                  </Text>
                  <Text fw={600}>{billToLocation}</Text>
                </Flex>
              </GridCol>
            </Grid>
            <GridCol pl={0} pb={0} span={{ base: 12, md: 12, lg: 6 }}>
              <Group mt={20}>
                <Text style={{ flex: "0 0 95px" }}>Tổng tiền</Text>
                <NumberFormatter
                  color="red"
                  value={
                    typeof data?.blanketTotalAmount === "number" ||
                    typeof data?.blanketTotalAmount === "string"
                      ? data?.blanketTotalAmount
                      : 0
                  }
                  thousandSeparator
                />
              </Group>
            </GridCol>

            <GridCol pl={0} pb={0} span={{ base: 12, md: 12, lg: 12 }}>
              <Group mt={10}>
                <Text style={{ flex: "0 0 95px" }}>Ghi chú từ người nhận</Text>
                <Text>{data?.noteToReceiver}</Text>
              </Group>
            </GridCol>
            <GridCol pl={0} pb={0} span={{ base: 12, md: 12, lg: 12 }}>
              <Group mt={10}>
                <Text style={{ flex: "0 0 95px", whiteSpace: "nowrap" }}>
                  Ghi chú
                </Text>
                <Text>{data?.comments}</Text>
              </Group>
            </GridCol>
          </ScrollArea>
          <Group w="100%" justify="end">
            <Button disabled>Đã khóa</Button>
          </Group>
        </GridCol>
        <GridCol span={7}>
          <div className="mt-5" style={{ marginTop: "10px" }}>
            <MantineReactTable table={table} />
          </div>
        </GridCol>
      </Grid>
    </>
  );
};

export default ImportPurchasesDetail;
