import { format, toZonedTime } from "date-fns-tz";

const timeZone = "Asia/Ho_Chi_Minh";

export const formatToVietnamTz = (date?: Date): any | null => {
  if (!date) return null;
  return format(toZonedTime(date, timeZone), "yyyy-MM-dd'T'HH:mm:ssXXX", {
    timeZone,
  });
};
