import {
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Modal,
  NumberFormatter,
  NumberInput,
  Select,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { _localization } from "../../../../config/location";
import { styleCellTable } from "../../../../_setup/navdata/baseStyleCellTable";
import { Panel, PanelGroup } from "react-resizable-panels";
import EditableTextWithOptions from "../../../../common/selectValue";
import { MessageResponse } from "../../../../model/MessageResponse";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { EmployeeModel } from "../../../../model/Employee";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../../common/FormatDate/FormatDate";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { useDisclosure } from "@mantine/hooks";
import { TblDMPaymentMethod } from "../../../../model/TblDMPaymentMethod";
import moment from "moment";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";

interface DetaiReceiptsProps {
  receiptId: number;
  deltail: number;
}

interface PaymentReceiptDetail {
  accountfund: number | null;
  codefund: string | null;
  createby: number;
  createdDate: string;
  creatorbranch: string | null;
  creatordepartment: string | null;
  creatorfullname: string | null;
  creatorsalesbranch: string | null;
  creatorworkplace: string | null;
  fundbranch: string | null;
  funddepartment: string | null;
  fundsalesbranch: string | null;
  fundworkplace: string | null;
  funfullname: string | null;
  id: number;
  payerdescription: string | null;
  payerfullname: string | null;
  payernote: string | null;
  payertype: string | null;
  receiptNumber: string;
  totalAmount: number;
  type: number;
}

interface InvoiceReceipt {
  allocatedAmount: number;
  amountPaid: number;
  amountRe: number;
  notes: string | null;
  orderDate: string;
  orderNumber: string;
  totalAmount: number;
}

interface InvoiceReceiptsPaymentModels {
  paymentReceiptDetail: PaymentReceiptDetail;
  invoiceReceiptsPaymentModels: InvoiceReceipt[];
}

const DetaiReceipts = ({ receiptId, deltail }: DetaiReceiptsProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [height, setHeight] = useState(0);
  const [rowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [heightContent, setHeightContent] = useState(0);
  const [, setEmployeeSalesName] = useState("");
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const [invoiceReceiptsPaymentModels, setInvoiceReceiptsPaymentModels] =
    useState<InvoiceReceiptsPaymentModels | null>(null);

  const [paymentReceiptDetail, setPaymentReceiptDetail] =
    useState<PaymentReceiptDetail | null>(null);

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [options, setOptions] = useState("488");

  const [totalAllocatedAmount, setTotalAllocatedAmount] = useState<any>(0);
  const [totalAmountRemaining, setTotalAmountRemaining] = useState<number>(0);

  const [paymenBankAccount, setPaymenBankAccount] = useState<any[]>();

  const [paymentMethod, setPaymentMethod] = useState<TblDMPaymentMethod[]>();
  const [paymentTypes, setPaymentTypes] = useState<any[]>();
  const [dataGetReceiptParent, setDataGetReceiptParent] = useState<any>();
  const [valueOrderNumber, setValueOrderNuymber] = useState<string | number>();
  const [listEmployeeSales, setListEmployeeSales] = useState<string[]>([""]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [isError] = useState(false);
  const [isLoading] = useState(false);
  const [isRefetching] = useState(false);
  const [sorting] = useState<MRT_SortingState>([]);
  const [sumNumber, setSumnumber] = useState<number>(0);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const originalValue = 10500000;
  const userName = localStorage.getItem("userName") || "";

  const styGridCol4 = { base: 12, xs: 3 };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "orderNumber",
        header: "Số đơn hàng",
        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "orderDate",
        header: "Ngày đặt hàng",
        Cell: ({ renderedCellValue, cell }) => {
          const value =
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
              ? renderedCellValue
              : null;

          return (
            <Text size="sm" fw={600}>
              {formatDateTransfer(value)}
            </Text>
          );
        },
      },
      {
        accessorKey: "totalAmount",
        header: "Tổng số tiền",
        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              suffix=" ₫"
              thousandSeparator=","
            />
          </Text>
        ),
      },
      // {
      //   accessorKey: "allocatedAmount", //access nested data with dot notation
      //   header: "Số tiền phân bổ",
      //   Cell: ({ renderedCellValue, cell }) => (
      //     <Text size="sm" fw={600}>
      //       <NumberFormatter
      //         value={(renderedCellValue as string | number) || 0}
      //         suffix=" ₫"
      //         thousandSeparator=","
      //       />
      //     </Text>
      //   ),
      // },
      {
        accessorKey: "amountPaid",
        header: "Số tiền đã thanh toán",
        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              suffix=" ₫"
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "amountRe", //normal accessorKey
        header: "Số tiền còn lại",
        Cell: ({ renderedCellValue, cell }) => (
          <Text size="sm" fw={600}>
            <NumberFormatter
              value={(renderedCellValue as string | number) || 0}
              suffix=" ₫"
              thousandSeparator=","
            />
          </Text>
        ),
      },
      {
        accessorKey: "notes",
        header: "Ghi chú",
      },
    ],
    [validationErrors]
  );

  const table = useMantineReactTable<any>({
    columns,
    data: invoiceReceiptsPaymentModels?.invoiceReceiptsPaymentModels ?? [],
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: false,
    enableBatchRowSelection: true,
    enableBottomToolbar: true,
    // enableExpanding: true,
    // renderDetailPanel: ({ row }) => (
    //   <PaymentnDetail dataDetail={row.original.orderNumber} />
    // ),
    // renderRowActions: ({ row, table }) => {
    //   return (
    //     <Flex gap="md">
    //       <Tooltip label="Xóa">
    //         <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
    //           <IconTrash size={14} />
    //         </ActionIcon>
    //       </Tooltip>
    //     </Flex>
    //   );
    // },

    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        display: "none",
      },
    },

    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id?.toString(),
    mantineTableContainerProps: {
      style: { maxHeight: height - 150, minHeight: height - 150 },
    },
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        // left: ["mrt-row-select", "mrt-row-actions"],
        right: [""],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    enableStickyHeader: true,
    positionActionsColumn: "last",
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: false,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination, // Add onPaginationChange handler
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    onCreatingRowCancel: () => setValidationErrors({}),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  const managerOrder = () => (
    <>
      <Grid>
        <GridCol>
          <Flex mt={15} align="center" gap="xl">
            <Text style={{ flex: "0 0 140px" }}>Mã phiếu thu</Text>
            <Text fw={500}>
              {
                invoiceReceiptsPaymentModels?.paymentReceiptDetail
                  ?.receiptNumber
              }
            </Text>
          </Flex>
          {deltail === 1 ? (
            <Flex mt={15} align="center" gap="xl">
              <Text style={{ flex: "0 0 140px" }}>Tên người nộp</Text>
              <Text fw={500}>
                {
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.payerfullname
                }
              </Text>
            </Flex>
          ) : null}
          {/* <Flex mt={15} align="center" gap="xl">
            <EditableTextWithOptions
              label="Loại phiếu thu:"
              value={"Thu tiền bán hàng"}
              options={[
                "Thu tiền bán hàng",
                "Thu tiền dịch vụ",
                "Thu tiền cho thuê mặt bằng",
              ]}
              onChange={(value: any) => {
                // handleChangeValueHeader(value, "saleId");
                // setEmployeeSalesName(value);
              }}
              width="250px"
            />
            </Flex> */}
          <Flex mt={15} align="center" gap="xl">
            <Text style={{ flex: "0 0 140px" }}>Ngày tạo</Text>
            <Text fw={500}>
              {formatDateTransfer(
                invoiceReceiptsPaymentModels?.paymentReceiptDetail?.createdDate
              )}
            </Text>
          </Flex>
          <Flex mt={15} align="center" gap="xl">
            <Text style={{ flex: "0 0 140px" }}>Tên người tạo</Text>
            <Text c="red" fw={500}>
              {
                invoiceReceiptsPaymentModels?.paymentReceiptDetail
                  ?.creatorfullname
              }
            </Text>
          </Flex>
          <Flex mt={15} align="center" gap="xl">
            <Text style={{ flex: "0 0 140px" }}>Chức danh người tạo</Text>
            <Text fw={500}>
              {
                invoiceReceiptsPaymentModels?.paymentReceiptDetail
                  ?.creatorbranch
              }
            </Text>
          </Flex>
          <Flex mt={15} align="center" gap="xl">
            <Text style={{ flex: "0 0 140px" }}>Phòng ban</Text>
            <Text fw={500}>
              {
                invoiceReceiptsPaymentModels?.paymentReceiptDetail
                  ?.creatordepartment
              }
            </Text>
          </Flex>
          <Flex mt={15} align="center" gap="xl">
            <Text style={{ flex: "0 0 140px" }}>Địa điểm</Text>
            <Text fw={500}>
              {
                invoiceReceiptsPaymentModels?.paymentReceiptDetail
                  ?.creatorworkplace
              }
            </Text>
          </Flex>
          {deltail === 1 ? (
            <Flex mt={15} align="center" gap="xl">
              <Text style={{ flex: "0 0 140px" }}>Chi nhánh bán</Text>
              <Text fw={500}>
                {
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.creatorsalesbranch
                }
              </Text>
            </Flex>
          ) : null}

          {/* <Flex mt={15} align="center" gap="xl">
            <EditableTextWithOptions
              label="Ngân hàng:"
              value="VBbank"
              options={["VBbank", "BIDV", "Techcombank"]}
              onChange={(value: any) => {
                // handleChangeValueHeader(value, "saleId");
                setEmployeeSalesName(value);
              }}
              width="250px"
            />
          </Flex>
          <Flex mt={15} align="center" gap="xl">
            <EditableTextWithOptions
              label="Mã chi phí:"
              value="CP001"
              options={["CP001", "CP002", "CP003"]}
              onChange={(value: any) => {
                // handleChangeValueHeader(value, "saleId");
                setEmployeeSalesName(value);
              }}
              width="250px"
            />
          </Flex> */}

          {deltail !== 1 ? (
            <>
              <Flex mt={15} align="center" gap="xl">
                <Text style={{ flex: "0 0 140px" }}>Phòng ban</Text>
                <Text fw={500}>
                  {
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.funddepartment
                  }
                </Text>
              </Flex>
              <Flex mt={15} align="center" gap="xl">
                <Text style={{ flex: "0 0 140px" }}>Địa điểm làm việc</Text>
                <Text fw={500}>
                  {
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.fundworkplace
                  }
                </Text>
              </Flex>
              <Flex mt={15} align="center" gap="xl">
                <Text style={{ flex: "0 0 140px" }}>Họ và tên</Text>
                <Text fw={500}>
                  {
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.funfullname
                  }
                </Text>
              </Flex>
              <Flex mt={15} align="center" gap="xl">
                <Text style={{ flex: "0 0 140px" }}>Chức danh</Text>
                <Text fw={500}>
                  {
                    invoiceReceiptsPaymentModels?.paymentReceiptDetail
                      ?.fundbranch
                  }
                </Text>
              </Flex>
            </>
          ) : null}
          <Flex mt={15} align="center" gap="xl">
            <Text style={{ flex: "0 0 140px" }}>Nội dung</Text>
            <Text fw={500}>
              {
                invoiceReceiptsPaymentModels?.paymentReceiptDetail
                  ?.payerdescription
              }
            </Text>
          </Flex>
          <Flex mt={15} align="center" gap="xl">
            <Text style={{ flex: "0 0 140px" }}>Ghi chú</Text>
            <Text lineClamp={1} fw={500}>
              {invoiceReceiptsPaymentModels?.paymentReceiptDetail?.payernote}
            </Text>
          </Flex>
          <Flex mt={15} align="center" gap="xl">
            <Text style={{ flex: "0 0 140px" }}>Tổng tiền PT</Text>
            <Text c="red" fw={500}>
              <NumberFormatter
                thousandSeparator
                value={
                  invoiceReceiptsPaymentModels?.paymentReceiptDetail
                    ?.totalAmount
                }
                suffix={" ₫"}
              />
            </Text>
          </Flex>
        </GridCol>
      </Grid>
    </>
  );

  const detailReceipts = async () => {
    // Example id
    const response = await repositoryPos.get<
      MessageResponse<InvoiceReceiptsPaymentModels>
    >(`/api/v1/CreateSellItem/details-receipt?id=${receiptId}`);

    if (response && response.success) {
      setInvoiceReceiptsPaymentModels(response.data);
    }
  };

  console.log(invoiceReceiptsPaymentModels);

  useEffect(() => {
    detailReceipts();
  }, [receiptId]);

  const fetchDataReceiptParent = async () => {
    const response = await repositoryPos.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/CreateSellItem/create-receipt-parent");

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptParent(result);
    }
  };

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (90 + headerHeight));
      setHeightContent(window.innerHeight - (105 + contentHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [heightContent, height]);

  return (
    <Box mt={10}>
      {deltail === 1 ? (
        <Grid>
          <GridCol span={{ base: 12, md: 6, lg: 4 }}>
            <Card
              shadow="sm"
              radius="md"
              withBorder
              p="md"
              style={{
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                maxHeight: height - 90,
                minHeight: height - 90,
              }}
            >
              <Flex mt={10} align="center" gap="xs"></Flex>
              {managerOrder()}
            </Card>
          </GridCol>
          <GridCol span={{ base: 12, md: 6, lg: 8 }}>
            <MantineReactTable table={table} />
          </GridCol>
        </Grid>
      ) : (
        <>{managerOrder()}</>
      )}
    </Box>
  );
};

export default DetaiReceipts;
