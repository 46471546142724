import { Button, Flex, Input, Select } from "@mantine/core";
import { IconTransactionEuro, IconZoomReset } from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { _localization } from "../../config/location";
import { MessageResponse } from "../../model/MessageResponse";
import "../../Styles/tab.component.css";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { repositoryPos } from "../../_base/_const/_constVar";
import { useDebouncedCallback } from "@mantine/hooks";
import { getTblDepartmentSelect } from "../../service/getSelectApi";
import { SelectListItemBase } from "../../_base/model/_base/SelectListItemBase";

const GroupsUserBrandAdd = ({
  idGroup,
  idsBrand,
  onLoad,
}: {
  idGroup: string;
  idsBrand: Array<string>;
  onLoad: () => Promise<void>;
}) => {
  //#region  table

  //data and fetching state
  const [data, setData] = useState<SelectListItemBase[]>([]);
  const [dataTemp, setDataTemp] = useState<SelectListItemBase[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  //table state

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dateSelect, setDataSelect] = useState<string[]>([]);
  const [roleList, setRoleList] = useState<string[]>([]);
  const [dateSelectValue, setDataSelectValue] = useState<string>();
  const [keySearch, setKeySearch] = useState<string>("");
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const res: MRT_RowSelectionState = {};
    for (let index = 0; index < roleList.length; index++) {
      const element = roleList[index];
      res[element] = true;
    }
    setRowSelection(res);
  }, [roleList]);

  useEffect(() => {
    handleSearch(keySearch);
  }, [dateSelectValue]);

  const handleSearch = useDebouncedCallback(async (query: string) => {
    let searchResult: SelectListItemBase[] = dataTemp;

    if (query.length > 0) {
      const k = query.toLowerCase();

      const isSearch = (product: SelectListItemBase): boolean => {
        return product.text?.toLowerCase().includes(k) ?? false;
      };
      searchResult = dataTemp.filter(isSearch);
    }
    if (dateSelectValue && dateSelectValue.length > 0) {
      searchResult = searchResult.filter(
        (product) => product.att5[0] === dateSelectValue
      );
    }
    setData(searchResult);
    setKeySearch(query);
  }, 500);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const q = event.currentTarget.value;
    handleSearch(q);
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    try {
      const json = await getTblDepartmentSelect();
      if (json) {
        let _d = json.filter((x) => x.value != "-1");
        if (idsBrand)
          _d = _d.sort((a, b) => {
            const indexA = idsBrand.indexOf(a.value);
            const indexB = idsBrand.indexOf(b.value);
            if (indexA === -1 && indexB === -1) {
              return 0;
            } else if (indexA === -1) {
              return 1;
            } else if (indexB === -1) {
              return -1;
            } else {
              return indexA - indexB;
            }
          });
        setData(_d);
        setDataTemp(_d);
        const uniqueApps = Array.from(
          new Set(
            _d
              .map((item) => item.att5[0] ?? "")
              .filter((value, index, self) => self.indexOf(value) === index)
          )
        );
        if (uniqueApps && uniqueApps.length > 0) setDataSelect(uniqueApps);
        if (idsBrand) setRoleList(idsBrand);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const authRole = async () => {
    if (!idGroup || idGroup.length < 1) {
      NotificationExtension.Fails("Nhóm quyền rỗng !");
      return;
    }

    let arryKey: string[] = [];
    for (let index = 0; index < Object.keys(rowSelection).length; index++) {
      const element = Object.keys(rowSelection)[index];
      if (element && element.length > 0) arryKey.push(element);
    }

    const url = "/api/v1/auth/auth-group-brand?idAuth=" + idGroup;
    const res = await repositoryPos.post<MessageResponse<boolean>>(
      url,
      arryKey
    );
    if (res && res.httpStatusCode == 200)
      if (res.success) {
        await onLoad();
        NotificationExtension.Success("Phân quyền thành công !");
      } else NotificationExtension.Fails("Phân quyền thất bại !");
    return;
  };
  const columns = React.useMemo<MRT_ColumnDef<SelectListItemBase>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },

      {
        accessorKey: "text",
        header: "Tên phòng ban",
      },
      {
        accessorKey: "att5",
        header: "Chi nhánh",
        Cell: ({ renderedCellValue, row }) => <>{renderedCellValue}</>,
      },
    ],
    []
  );
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enablePagination: false,
    enableRowVirtualization: true,
    enableRowNumbers: true,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input placeholder="Nhập từ khóa ..." onChange={handleChange} />
          {dateSelect && (
            <Select
              placeholder="Lọc theo ứng dụng"
              data={dateSelect}
              clearable
              onChange={(e) => {
                setDataSelectValue(e ?? "");
              }}
            />
          )}
        </Flex>
      </>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconTransactionEuro size={14} />}
            color=""
            variant="outline"
            onClick={async () => {
              await authRole();
            }}
          >
            Phân quyền
          </Button>
          <Button
            leftSection={<IconZoomReset size={14} />}
            color="violet"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Load data
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.value,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: {
        pageIndex: 0,
        pageSize: 1000,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: "60vh", minHeight: "50vh" },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount: data.length,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "bold",
        fontSize: "11px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["1000"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    rowVirtualizerOptions: {
      overscan: 5,
    },
  });
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default GroupsUserBrandAdd;
