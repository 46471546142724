import React, { useEffect, useState } from "react";
import {
  Modal,
  Table,
  ActionIcon,
  Tooltip,
  Text,
  Center,
  ScrollArea,
} from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import { MessageResponse } from "../../../../model/MessageResponse";
import { repositoryPos } from "../../../../_base/_const/_constVar";

const PaymentDetails = ({ orderNumber }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataPayment, setDataPayment] = useState<any[]>([]);

  useEffect(() => {
    const handleFetchData = async () => {
      const url = `/api/v1/CreateSellItem/list-receipt?Skip=0&Take=100&KeySearch=${orderNumber}&Type=0`;
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response?.success) {
        setDataPayment(response?.data?.lists);
        console.log("response?.data?.lists", response?.data?.lists);
      }
    };
    handleFetchData();
  }, [orderNumber]);

  return (
    <>
      {/* Nút mở Modal */}
      <Tooltip label="Lịch sử thanh toán">
        <ActionIcon
          variant="light"
          aria-label="Xem chi tiết"
          color="cyan"
          onClick={() => setIsModalOpen(true)}
        >
          <IconEye size={24} />
        </ActionIcon>
      </Tooltip>

      {/* Modal hiển thị bảng */}
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={
          <Text size="lg" fw={600}>
            Chi tiết thanh toán
          </Text>
        }
        size="lg"
        centered
        withCloseButton
      >
        <ScrollArea>
          <Table highlightOnHover verticalSpacing="md" horizontalSpacing="md">
            <thead style={{ backgroundColor: "#f8f9fa" }}>
              <tr>
                <th style={{ textAlign: "left", fontSize: "16px" }}>
                  Mã phiếu thu
                </th>
                <th style={{ textAlign: "right", fontSize: "16px" }}>
                  Tổng số tiền
                </th>
              </tr>
            </thead>
            <tbody>
              {dataPayment?.map((item, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "left", fontWeight: 500 }}>
                    {item.receiptNumber}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      fontWeight: 500,
                      color: "#FF2D2D",
                    }}
                  >
                    {Number(item.totalAmount).toLocaleString()} VND
                  </td>
                </tr>
              ))}
              {dataPayment.length === 0 && (
                <tr>
                  <td colSpan={2}>
                    <Center>
                      <Text color="gray">Không có dữ liệu</Text>
                    </Center>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </ScrollArea>
      </Modal>
    </>
  );
};

export default PaymentDetails;
