import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Fieldset,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
  Text,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { handleKeyDown } from "../../../_base/helper/FunctionHelper";
import { TblDMCustomer } from "../../../model/TblDMCustomerSell";
import { DateInput } from "@mantine/dates";
import { useEffect, useState } from "react";
import {
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
} from "../../../service/getSelectApi";
import { sky_blue } from "../../../const/variables";

const CreateCustomerSite = ({ onClose }: CreateDataViewProps) => {
  const entity = {
    customer: {
      id: "0",
      parentId: null,
      code: null,
      name: null,
      contact: null,
      address: null,
      telephoneNumber: null,
      fax: null,
      email: null,
      taxCode: null,
      type: null,
      active: false,
      description: null,
      sex: null,
      birthOfDate: null,
      groupId: null,
    },
    customerSite: {
      customerSiteId: null,
      customerId: null,
      customerSiteType: null,
      address: null,
      customerSiteNumber: null,
      customerSiteName: null,
      telephoneNumber: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      createdBy: null,
      lastUpdateDate: null,
      lastUpdatedBy: null,
      lastUpdateLogin: null,
      creationDate: null,
      wardId: null,
      districtId: null,
      provinceId: null,
      telephone: null,
      isMain: null,
      email: null,
    },
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  //data
  const [dataTblProvinceSelect, setDataTblProvinceSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblDistrictSelect, setDataTblDistrictSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblCommuneSelect, setDataTblCommuneSelect] = useState<
    ComboboxItem[]
  >([]);

  //state
  const [province, setProvince] = useState<string | null>(null);
  const [district, setDistrict] = useState<string | null>(null);
  const [commune, setCommune] = useState<string | null>(null);

  const form = useForm<TblDMCustomer>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    transformValues: (values) => ({
      ...values,
      customer: {
        ...values.customer,
        parentId: Number(values.customer.parentId),
        groupId: Number(values.customer.groupId),
        type: Number(values.customer.type),
        sex: Boolean(values.customer.sex),
      },
      customerSite: {
        ...values.customerSite,
        customerId: Number(values.customerSite.customerId),
        customerSiteId: Number(values.customerSite.customerSiteId),
        provinceId: Number(values.customerSite.provinceId),
        districtId: Number(values.customerSite.districtId),
        wardId: Number(values.customerSite.wardId),
      },
    }),

    validate: {
      customer: {
        parentId: (value: number | null) => {
          if (!value) {
            return "Vui lòng nhập mã doanh nghiệp !";
          }
          return hasLength(
            { max: 20 },
            "Mã khách hàng doanh nghiệp không được vượt quá 20 kí tự !"
          )(value as number);
        },
        code: (value: string | null) => {
          if (!value) {
            return "Vui lòng nhập mã khách hàng !";
          }
          return hasLength(
            { max: 20 },
            "Mã khách hàng không được vượt quá 20 kí tự !"
          )(value as string);
        },
        name: (value: string | null) => {
          if (!value) {
            return "Vui lòng nhập tên khách hàng !";
          }
          return hasLength(
            { max: 30, min: 2 },
            "Tên khách hàng phải nằm trong khoảng từ 2 đến 30 kí tự !"
          )(value as string);
        },
        sex: (value: boolean | null) => {
          if (!value) {
            return "Vui lòng chọn giới tính khách hàng !";
          }
        },
        email: (value: string | null) => {
          if (value) {
            return /^\S+@\S+$/.test(value ?? "")
              ? null
              : "Email chưa đúng định dạng !";
          }
          return null;
        },
        telephoneNumber: (value: string | null) => {
          if (value) {
            return hasLength(
              { max: 10, min: 10 },
              "Số điện thoại chưa đúng địng dạng !"
            )(value as string);
          }
        },
        type: (value: number | null) => {
          if (!value) {
            return "Vui lòng chọn loại khách hàng !";
          }
          return hasLength(
            { max: 20 },
            "Loại khách hàng không được vượt quá 20 kí tự !"
          )(value as number);
        },
        groupId: (value: number | null) => {
          if (!value) {
            return "Vui lòng chọn nhóm khách hàng !";
          }
          return hasLength(
            { max: 20 },
            "Nhóm khách hàng không được vượt quá 20 kí tự !"
          )(value.toString() as string);
        },
      },
      customerSite: {
        customerSiteId: (value: number | null) => {
          if (!value) {
            return "Vui lòng nhập mã doanh nghiệp !";
          }
          return hasLength(
            { max: 20 },
            "Mã doanh nghiệp không được vượt quá 20 kí tự !"
          )(value as number);
        },
        customerId: (value: number | null) => {
          if (!value) {
            return "Vui lòng nhập mã khách hàng !";
          }
          return hasLength(
            { max: 20 },
            "Mã khách hàng không được vượt quá 20 kí tự !"
          )(value as number);
        },
        customerSiteNumber: (value: string | null) => {
          if (!value) {
            return "Vui lòng nhập số doanh nghiệp !";
          }
          return hasLength(
            { max: 25 },
            "Số doanh nghiệp không được vượt quá 25 kí tự !"
          )(value as string);
        },
        customerSiteName: (value: string | null) => {
          if (!value) {
            return "Vui lòng nhập tên doanh nghiệp !";
          }
          return hasLength(
            { max: 225, min: 5 },
            "Tên doanh nghiệp phải nằm trong khoảng từ 5 đến 225 kí tự !"
          )(value as string);
        },
        customerSiteType: (value: string | null) => {
          if (!value) {
            return "Vui lòng nhập loại doanh nghiệp !";
          }
        },
        email: (value: string | null) => {
          if (value) {
            return /^\S+@\S+$/.test(value ?? "")
              ? null
              : "Email chưa đúng định dạng !";
          }
          return null;
        },
        telephoneNumber: (value: string | null) => {
          if (value) {
            return hasLength(
              { max: 10, min: 10 },
              "Số điện thoại chưa đúng địng dạng !"
            )(value as string);
          }
        },
      },
    },
  });

  const handleCreateTblDmCustomer = async (dataSubmit: TblDMCustomer) => {
    open();
    const dataApi = await repositoryPos.post<MessageResponse<TblDMCustomer>>(
      "/api/v1/TblDmCustomer/create",
      dataSubmit
    );
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    } else NotificationExtension.Fails("Thêm thất bại !");
    close();
  };

  const fetchDataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataTblProvinceSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataDistrictSelect = async () => {
    const getData = await getDistrictSelect(province ?? "");
    setDataTblDistrictSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataCommuneSelect = async () => {
    const getData = await getCommuneSelect(district ?? "");
    setDataTblCommuneSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    fetchDataProvinceSelect();
  }, []);

  useEffect(() => {
    if (province) {
      fetchDataDistrictSelect();
    } else {
      setDistrict(null);
    }
  }, [province]);

  useEffect(() => {
    if (district) {
      fetchDataCommuneSelect();
    } else {
      setCommune(null);
    }
  }, [district]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "300px", md: "500px", lg: "675px" }}
        onSubmit={form.onSubmit((e: TblDMCustomer) => {
          handleCreateTblDmCustomer(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Text fw={"bold"} size="13px" mt={10}>
          Thông tin khách hàng
        </Text>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              size="xs"
              label="Mã doanh nghiệp"
              placeholder="Nhập mã doanh nghiệp"
              onKeyDown={handleKeyDown}
              type="number"
              {...form.getInputProps("customer.parentId")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              size="xs"
              label="Mã khách hàng"
              placeholder="Nhập mã khách hàng"
              onKeyDown={handleKeyDown}
              {...form.getInputProps("customer.code")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              size="xs"
              label="Tên khách hàng"
              placeholder="Nhập tên khách hàng"
              {...form.getInputProps("customer.name")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <Select
              size="xs"
              label="Giới tính"
              placeholder="Chọn giới tính"
              data={[
                { label: "Nam", value: "true" },
                { label: "Nữ", value: "false" },
              ]}
              {...form.getInputProps("customer.sex")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <DateInput
              size="xs"
              label="Ngày sinh"
              valueFormat="DD/MM/YYYY"
              clearable
              placeholder="Nhập ngày sinh"
              {...form.getInputProps("customer.birthOfDate")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Địa chỉ"
              placeholder="Nhập địa chỉ"
              {...form.getInputProps("customer.address")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Email"
              placeholder="Nhập email"
              {...form.getInputProps("customer.email")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Số điện thoại"
              type="number"
              placeholder="Nhập số điện thoại"
              {...form.getInputProps("customer.telephoneNumber")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Liên hệ"
              placeholder="Nhập liên hệ"
              {...form.getInputProps("customer.contact")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Số Fax"
              placeholder="Nhập số fax"
              {...form.getInputProps("customer.fax")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Biểu mã thuế"
              placeholder="Nhập biểu mã thuế"
              {...form.getInputProps("customer.taxCode")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Loại khách hàng"
              placeholder="Nhập loại khách hàng"
              onKeyDown={handleKeyDown}
              type="number"
              {...form.getInputProps("customer.type")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Nhóm khách hàng"
              placeholder="Nhập khách hàng"
              type="number"
              onKeyDown={handleKeyDown}
              {...form.getInputProps("customer.groupId")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <TextInput
              size="xs"
              label="Mô tả"
              placeholder="Nhập mô tả"
              {...form.getInputProps("customer.description")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Checkbox
              size="xs"
              label="Sử dụng"
              {...form.getInputProps("customer.active")}
            />
          </Grid.Col>
        </Grid>
        <Text fw={"bold"} size="13px" mt={10}>
          Thông tin khách hàng doanh nghiệp
        </Text>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Mã doanh nghiệp"
              placeholder="Nhập mã doanh nghiệp"
              type="number"
              onKeyDown={handleKeyDown}
              {...form.getInputProps("customerSite.customerSiteId")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Mã khách hàng"
              placeholder="Nhập mã khách hàng"
              type="number"
              onKeyDown={handleKeyDown}
              {...form.getInputProps("customerSite.customerId")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Số doanh nghiệp"
              placeholder="Nhập số doanh nghiệp"
              {...form.getInputProps("customerSite.customerSiteNumber")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Tên doanh nghiệp"
              placeholder="Nhập tên doanh nghiệp"
              {...form.getInputProps("customerSite.customerSiteName")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              size="xs"
              label="Địa chỉ doanh nghiệp"
              placeholder="Nhập địa chỉ doanh nghiệp"
              {...form.getInputProps("customerSite.address")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Select
              label="Tỉnh/Thành phố"
              placeholder={"Nhập tỉnh/thành phố"}
              data={dataTblProvinceSelect}
              clearable
              searchable
              value={province}
              onChange={(e) => {
                setProvince(e ?? "");
                setDataTblDistrictSelect([]);
                setDataTblCommuneSelect([]);
                form.setFieldValue(
                  "customerSite.provinceId",
                  e ? Number(e) : null
                );
              }}
              nothingFoundMessage="Không tìm thấy dữ liệu !!!"
              size="xs"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Select
              label="Quận/huyện"
              placeholder={"Nhập quận/huyện"}
              data={dataTblDistrictSelect}
              clearable
              searchable
              value={district}
              onChange={(e) => {
                setDistrict(e ?? "");
                setDataTblCommuneSelect([]);
                form.setFieldValue(
                  "customerSite.districtId",
                  e ? Number(e) : null
                );
              }}
              nothingFoundMessage="Không tìm thấy dữ liệu !!!"
              size="xs"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Select
              label="Phường/Xã"
              placeholder={"Nhập phường/xã"}
              data={dataTblCommuneSelect}
              clearable
              searchable
              value={commune}
              onChange={(e) => {
                setCommune(e ?? "");
                form.setFieldValue("customerSite.wardId", e ? Number(e) : null);
              }}
              nothingFoundMessage="Không tìm thấy dữ liệu !!!"
              size="xs"
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              size="xs"
              label="Điện thoại"
              placeholder="Nhập điện thoại"
              {...form.getInputProps("customerSite.telephone")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              size="xs"
              label="Số điện thoại"
              placeholder="Nhập số điện thoại"
              {...form.getInputProps("customerSite.telephoneNumber")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              size="xs"
              label="Email"
              placeholder="Nhập email"
              {...form.getInputProps("customerSite.email")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              size="xs"
              label="Loại doanh nghiệp"
              placeholder="Nhập loại doanh nghiệp"
              {...form.getInputProps("customerSite.customerSiteType")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              size="xs"
              label="Doanh nghiệp chính"
              placeholder="Doanh nghiệp chính"
              {...form.getInputProps("customerSite.isMain")}
            />
          </Grid.Col>
        </Grid>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default CreateCustomerSite;

type CreateDataViewProps = {
  onClose: any;
};
