import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Input,
  Select,
  Tooltip,
} from "@mantine/core";
import {
  IconHistory,
  IconTransactionEuro,
  IconTrash,
  IconUpload,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { _localization } from "../../config/location";
import { ListRoleModel } from "../../model/ListRoleModel ";
import { MessageResponse } from "../../model/MessageResponse";
import "../../Styles/tab.component.css";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { repositoryMaster } from "../../_base/_const/_constVar";
import { useDebouncedCallback, useDebouncedValue } from "@mantine/hooks";

const GroupAddRoleAuthozire = ({ idAuthozire }: { idAuthozire: string }) => {
  //#region  table

  //data and fetching state
  const [data, setData] = useState<ListRoleModel[]>([]);
  const [dataTemp, setDataTemp] = useState<ListRoleModel[]>([]);
  const [dataApi, setDataApi] = useState<ListRoleModel[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state

  const [pagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dateSelect, setDataSelect] = useState<string[]>([]);
  const [roleList, setRoleList] = useState<string[]>([]);
  const [dateSelectValue, setDataSelectValue] = useState<string>();
  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
  }, [rowSelection]);

  useEffect(() => {
    const res: MRT_RowSelectionState = {};
    for (let index = 0; index < roleList.length; index++) {
      const element = roleList[index];
      const filtered = dataApi.find((x) => x.key === element);
      if (filtered?.id) res[filtered.id] = true;
    }
    setRowSelection(res);
  }, [roleList]);

  useEffect(() => {
    handleSearch(keySearch);
  }, [dateSelectValue]);

  const handleSearch = useDebouncedCallback(async (query: string) => {
    let searchResult: ListRoleModel[] = dataTemp;

    if (query.length > 0) {
      const k = query.toLowerCase();

      const isSearch = (product: ListRoleModel): boolean => {
        return (
          (product.createBy?.toLowerCase().includes(k) ?? false) ||
          (product.name?.toLowerCase().includes(k) ?? false) ||
          (product.key?.toLowerCase().includes(k) ?? false) ||
          (product.app?.toLowerCase().includes(k) ?? false) ||
          (product.description?.toLowerCase().includes(k) ?? false) ||
          (product.route?.toLowerCase().includes(k) ?? false) ||
          // Tìm kiếm trong các phần tử con (subRows)
          (product.subRows
            ? product.subRows.some((subProduct) => isSearch(subProduct))
            : false)
        );
      };
      searchResult = dataTemp.filter(isSearch);
    }

    if (dateSelectValue && dateSelectValue.length > 0) {
      searchResult = searchResult.filter(
        (product) => product.app === dateSelectValue
      );
    }

    setData(searchResult);
    setKeySearch(query);
  }, 500);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const q = event.currentTarget.value;
    handleSearch(q);
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    try {
      const json = await repositoryMaster.get<MessageResponse<ListRoleModel[]>>(
        "/api/v1/ListRole/keys-tree"
      );
      if (json && json.data) {
        await getAuthRole(idAuthozire);
        const _d = json.data;
        // Sắp xếp danh sách sao cho các đối tượng có id nằm trong danh sách keys lên đầu
        const sortedList = _d.sort((a, b) => {
          const aInKeys = roleList.includes(a.id);
          const bInKeys = roleList.includes(b.id);

          if (aInKeys && !bInKeys) {
            return -1;
          } else if (!aInKeys && bInKeys) {
            return 1;
          } else {
            return 0;
          }
        });

        setDataApi(sortedList);
        const treeData = buildTree(sortedList);
        setData(treeData);
        setRowCount(treeData.length);
        setDataTemp(treeData);
        const uniqueApps = Array.from(
          new Set(json.data.map((item) => item.app ?? ""))
        );
        if (uniqueApps && uniqueApps.length > 0) setDataSelect(uniqueApps);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };
  function buildTree(
    data: ListRoleModel[],
    parentId: string | null = null
  ): ListRoleModel[] {
    return data
      .filter((item) => item.parentId === parentId)
      .map((item) => ({
        ...item,
        subRows: buildTree(data, item.id),
      }));
  }

  const getAuthRole = async (idAuth: string) => {
    if (!idAuth || idAuth.length < 1) {
      NotificationExtension.Fails("Nhóm quyền rỗng !");
      return;
    }
    const url = "/api/v1/ListAuthozire/get-role?id=" + idAuthozire;
    const res = await repositoryMaster.get<MessageResponse<string[]>>(url);
    if (res && res.data && res.data.length > 0) setRoleList(res.data);
  };

  const authRole = async () => {
    if (!idAuthozire || idAuthozire.length < 1) {
      NotificationExtension.Fails("Nhóm quyền rỗng !");
      return;
    }

    let arryKey: string[] = [];
    for (let index = 0; index < Object.keys(rowSelection).length; index++) {
      const element = Object.keys(rowSelection)[index];
      const filtered = dataApi.find((x) => x.id === element);
      if (filtered?.key) arryKey.push(filtered?.key);
    }

    const url = "/api/v1/ListAuthozire/auth-role?idAuth=" + idAuthozire;
    const res = await repositoryMaster.post<MessageResponse<any>>(url, arryKey);
    if (res)
      if (res.success) {
        NotificationExtension.Success("Phân quyền thành công !");
        return;
      } else {
        NotificationExtension.Fails("Phân quyền thất bại !");
        return;
      }
  };

  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  const columns = React.useMemo<MRT_ColumnDef<ListRoleModel>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },

      {
        accessorKey: "name",
        header: "Tên chức năng",
        size: 390,
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row && row.original?.parentId && row.original?.parentId?.length > 0
              ? "----- " + renderedCellValue
              : renderedCellValue}
          </>
        ),
      },
      {
        accessorKey: "key",
        header: "Mã chức năng",
        size: 450,
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row && row.original?.parentId && row.original?.parentId?.length > 0
              ? "----- " + renderedCellValue
              : renderedCellValue}
          </>
        ),
      },
      {
        accessorKey: "inActive",
        header: "Trạng thái",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(row.original.inActive ?? false)}>
              {row.original.inActive ? "Đang hoạt động" : "Dừng hoạt động"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "app",
        header: "Service",
      },
      {
        accessorKey: "route",
        header: "Router",
      },

      {
        accessorKey: "httpMethod",
        header: "Phương thức",
      },
      {
        accessorKey: "createBy",
        header: "Người tạo",
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo",
      },
    ],
    []
  );
  const [openedMenu, setOpenedMenu] = useState(false);

  const initialExpandedRootRows = useMemo<MRT_ExpandedState>(
    () =>
      data
        .map((originalRow) => originalRow.id) //lấy tất cả id hàng gốc, sử dụng đệ quy cho các cấp độ bổ sung
        .reduce((a, v) => ({ ...a, [v]: true }), {}), //chuyển đổi thành một đối tượng có tất cả id là khóa và `true` làm giá trị
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enablePagination: false,
    enableRowVirtualization: true,
    enableRowNumbers: true,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input placeholder="Nhập từ khóa ..." onChange={handleChange} />
          {dateSelect && (
            <Select
              placeholder="Lọc theo ứng dụng"
              data={dateSelect}
              clearable
              onChange={(e) => {
                setDataSelectValue(e ?? "");
              }}
            />
          )}
        </Flex>
      </>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconTransactionEuro size={14} />}
            color=""
            variant="outline"
            onClick={async () => {
              await authRole();
            }}
          >
            Phân quyền
          </Button>
          <Button
            leftSection={<IconZoomReset size={14} />}
            color="violet"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Load data
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    enableExpanding: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"],
      },
      columnVisibility: { id: false },
      density: "xs",
      expanded: true,
      pagination: {
        pageIndex: 0,
        pageSize: 1000,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: "60vh", minHeight: "50vh" },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    //  manualPagination: true,
    // manualSorting: true,
    rowCount: data.length,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "11px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["1000"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    rowVirtualizerOptions: {
      overscan: 5,
    },
  });
  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default GroupAddRoleAuthozire;
