export const styleCellTable = (row?: any) => {

    return {
        fontWeight: row?.getIsSelected() ? "bold" : "500", //conditional styling
        //  fontWeight: "bold", //conditional styling
        fontSize: "11px",
        padding: "5px 15px",

    }

}