import {
  Box,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMDeliverPriority } from "../../../../model/TblDMDeliverPriority";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    name: "",
    code: "",
    active: false,
    note: null,
    deliveryTime: 0,
    createBy: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMDeliverPriority>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<
      MessageResponse<TblDMDeliverPriority>
    >(`/api/v1/TblDMDeliverPriority/detail?id=${id}`);
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  return (
    <>
      <Box component="form" mx="auto" style={{ position: "relative" }}>
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={6}>
            <TextInput
              readOnly
              variant="filled"
              label={"Mã mức độ ưu tiên giao hàng"}
              {...form.getInputProps("code")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              readOnly
              variant="filled"
              label={"Tên mức độ ưu tiên giao hàng"}
              w={"100%"}
              {...form.getInputProps("name")}
            />
          </Grid.Col>
        </Grid>

        <Grid align="center" pos={"relative"}>
          <Grid.Col>
            <NumberInput
              hideControls
              readOnly
              variant="filled"
              min={0}
              label={"Khoảng thời gian giao hàng"}
              {...form.getInputProps("deliveryTime")}
            />
          </Grid.Col>
        </Grid>

        <Grid align="center">
          <Grid.Col>
            <Textarea
              readOnly
              variant="filled"
              label={"Ghi chú"}
              {...form.getInputProps("note")}
            />
          </Grid.Col>
          <Grid.Col>
            <Checkbox
              label={"Sử dụng"}
              readOnly
              checked={form.values.active}
              {...form.getInputProps("active")}
              onChange={() => {}}
            />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
