import { NumberInput } from "@mantine/core";
import { IconMinus } from "@tabler/icons-react";
import React, { FC, useState } from "react";

interface FormatInputValueNumberProps {
  value?: number;
  label?: string;
  sizeFont?: string;
  placeholder?: string;
  vnd?: boolean;
  onChange?: (value: number) => void;
  disabled?: boolean;
  onBlur?: () => void;
  autoFocus?: boolean;
}

const FormatInputValueNumber: FC<FormatInputValueNumberProps> = ({
  value,
  label,
  placeholder,
  vnd,
  sizeFont,
  onChange,
  disabled,
  onBlur,
  autoFocus,
  ...pastProps
}) => {
  const [internalValue, setInternalValue] = useState<number | undefined>(value);

  const removeLeadingZeros = (val: string) => {
    return val.replace(/^0+(?!\d)/, "");
  };

  const handleChange = (value: number | undefined) => {
    const formattedValue = value
      ? parseFloat(removeLeadingZeros(value.toString()))
      : undefined;

    setInternalValue(formattedValue);
    if (onChange) {
      onChange(formattedValue ?? 0);
    }
  };

  return (
    <NumberInput
      autoFocus={autoFocus}
      size={sizeFont}
      label={label}
      value={internalValue}
      placeholder={placeholder}
      thousandSeparator=","
      suffix={vnd ? " ₫" : undefined}
      rightSection={<IconMinus size={"12px"} />}
      onChange={(value) => handleChange(value as number)}
      disabled={disabled} // Pass disabled prop to NumberInput
      onBlur={onBlur}
      {...pastProps}
    />
  );
};

export default FormatInputValueNumber;
