//#region start
import {
  Badge,
  Box,
  Button,
  Fieldset,
  Flex,
  Grid,
  LoadingOverlay,
  Menu,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowBack,
  IconCheck,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";

import { DateTimePicker } from "@mantine/dates";
import { useClickOutside, useDebouncedState } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { useNavigate } from "react-router-dom";
import {
  repositoryDelivery,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { styleCellTable } from "../../../../_setup/navdata/baseStyleCellTable";
import { _localization } from "../../../../config/location";
import BorrowedGoodList from "./BorrowedGoodList";

interface TableSelectProps {
  data: any;
}

type BorrowedItem = {
  itemBorrowId: number; // ID tham chiếu đến lần mượn sản phẩm
  condition: string; // Trạng thái của sản phẩm (ví dụ: mới, cũ, hỏng, etc.)
  priceNew: number; // Giá hiện tại của sản phẩm
  note: string; // Ghi chú thêm về sản phẩm
  type: boolean; // Loại sản phẩm: `true` = sản phẩm bảo hành, `false` = sản phẩm cho mượn
  priceOld: number; // Giá cũ của sản phẩm
  itemId: number; // ID của sản phẩm
  attribute1: Date; // Ngày hoặc thông tin liên quan đến thời gian (đã chuyển thành kiểu `Date`)
  attribute2: string; // Tên sản phẩm đính kèm (dành cho sản phẩm cho mượn)
  attribute3: string; // Serial của sản phẩm (dành cho sản phẩm cho mượn)
  attribute4: number; // Thuộc tính bổ sung (sử dụng theo ngữ cảnh)
  attribute5: number; // Thuộc tính bổ sung (sử dụng theo ngữ cảnh)
  attribute6: number; // Thuộc tính bổ sung (sử dụng theo ngữ cảnh)
  deposit: number; // Giá trị thực của sản phẩm
  itemCode: string; // Mã sản phẩm
  itemName: string; // Tên sản phẩm
  uomName: string; // Đơn vị tính của sản phẩm
  amount: number; // Số lượng sản phẩm
};

const formatCurrency = (value: any) => {
  if (value == null) {
    return "";
  }
  // Định dạng số theo chuẩn Việt Nam
  return value.toLocaleString("vi-VN", {
    minimumFractionDigits: 0, // Không hiển thị phần thập phân
    maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
  });
};
const CreateExportBorrwedGood = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [userInfo, setUserInfo] = useState<any>();
  //Phiếu yêu cầu hàng mượn
  const [codeYCHM, setCodeYCHM] = useState<string>();
  //Tìm kiếm sản phẩm hàng mượn
  const [searchItem, setSearchItem] = useDebouncedState<string>("", 300);
  //Danh sách sản phẩm hàng mượn
  const [dataSearchLoanRequest, setDataSearchLoanRequest] = useState<any>([]);
  //list sản phẩm cho mượn đã chọn
  const [selectedItemCodes, setSelectedItemCodes] = useState<string[]>([]); //thêm serial sản phẩm vào mảng
  const [dataSelectLoanProduct, setDataSelectLoanProduct] = useState<
    BorrowedItem[]
  >([]); //sản phẩm cho mượn đã chọn
  console.log(dataSelectLoanProduct);
  //#region hàng lỗi
  const [KeySearch, setKeySearch] = useState("");
  const [IdFormDataSelected, setIdFormDataSelected] = useState<any>(null);
  // dữ liệu phiếu hàng lỗi
  const [dataWanrrantySelected, setDataWanrrantySelected] = useState<any>([]);
  // sản phẩm hàng lỗi
  const [warrantyProducts, setWarrantyProducts] = useState<any[]>([]);
  // trạng thái chỉnh sửa
  const [isEditing, setIsEditing] = useState(false);
  //giá trị thực sản phẩm lỗi
  const [depositItemWarrantyProduct, setDepositItemWarrantyProduct] = useState(
    []
  );
  //Gía cọc sản phẩm
  const [totalDeposit, setTotalDeposit] = useState<number>(0);
  // Ghi chú
  const [note, setNote] = useState<string>("");
  // quét serial
  const [isSerial, setIsSerial] = useDebouncedState<string>("", 200);
  const fetchDetailDefectivegoods = async () => {
    if (IdFormDataSelected === null || IdFormDataSelected === undefined) return;
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyReceiveTicket/detail?id=${IdFormDataSelected}`
      );
      setDataWanrrantySelected(response.data);
      setKeySearch(response.data.errorNumber);
      const mappedItems =
        response.data.tblWarrantyReceiveTicketDetailModels.map((item: any) => {
          return {
            id: 0,
            status: "Đã qua sử dụng",
            actualPrice: 0,
            note: item.note,
            type: true,
            sellingPrice: 0,
            attribute1: item.acessory,
            attribute2: null,
            attribute3: null,
            attribute4: 1,
            attribute5: null,
            attribute6: null,
            deposit: totalDeposit,
            orgPrice: item.latestPrice,
            serial: item.serial,
            itemBorrowId: null,
            itemCode: item.itemCode,
            itemName: item.itemName,
            uomName: item.uomName,
            quantity: 1,
          };
        });
      if (mappedItems.length > 0) {
        setWarrantyProducts(mappedItems);
      }
    } catch (error) {
      NotificationExtension.Fails("Lấy dữ liệu hàng lỗi thất bại");
    }
  };
  // #region hàng mượn
  const handSelectItem = (item: any) => {
    // Thêm mã sản phẩm đã chọn vào mảng
    setSelectedItemCodes((prev) => [...prev, item.maBarcode]);
    setDataSelectLoanProduct((prev: any) => {
      // Gộp Sản phẩm cho mượn cũ và Sản phẩm cho mượn mới
      const loanProduct = {
        id: 0,
        status: "Mới 100%",
        actualPrice: 0,
        note: null,
        type: false,
        sellingPrice: 0,
        attribute1: null,
        attribute2: null,
        attribute3: null,
        attribute4: 1,
        attribute5: null,
        attribute6: null,
        deposit: totalDeposit,
        orgPrice: item.donGiaNhap,
        serial: item.maBarcode,
        itemBorrowId: 0,
        itemCode: item.maSp,
        itemName: item.tenSp,
        uomName: item.dvt,
        quantity: 1,
      };
      return [loanProduct, ...prev];
    });
    setSearchItem("");
  };

  const fetchDataLoanRequest = async () => {
    try {
      let url = `?Skip=0&Take=50`;
      if (searchItem) {
        url += `&KeySearch=${searchItem}`;
      }
      const response = await repositoryDelivery.get(
        `/api/v1/TblInventorySerialOnhand/get-list-serial${url}`
      );
      setDataSearchLoanRequest(response.data);
    } catch (error) {
      NotificationExtension.Fails("Lấy dữ liệu thất bại");
    }
  };

  const TableSelect: React.FC<TableSelectProps> = ({ data }: any) => {
    return (
      <Table.ScrollContainer minWidth={500} mah={250} type="native">
        <Table striped highlightOnHover stickyHeader>
          <Table.Thead>
            <Table.Tr>
              <Table.Th />
              <Table.Th>Mã sản phẩm</Table.Th>
              <Table.Th>Tên sản phẩm</Table.Th>
              <Table.Th>Serial</Table.Th>
              <Table.Th>Giá</Table.Th>
              <Table.Th>Đơn vị tính</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataSearchLoanRequest?.map((item: any, index: number) => {
              return (
                <Table.Tr key={index}>
                  <Table.Td>
                    <Flex justify={"center"}>
                      <Button
                        onClick={() => {
                          handSelectItem(item);
                          table.setCreatingRow(null);
                        }}
                        variant="filled"
                        color="teal"
                        disabled={selectedItemCodes.includes(item.serial)}
                      >
                        {selectedItemCodes.includes(item.serial)
                          ? "Đã chọn"
                          : "Chọn"}
                      </Button>
                    </Flex>
                  </Table.Td>
                  <Table.Td>{item.maSp}</Table.Td>
                  <Table.Td>{item.tenSp}</Table.Td>
                  <Table.Td>{item.maBarcode}</Table.Td>
                  <Table.Td>{item.donGiaNhap}</Table.Td>
                  <Table.Td>{item.dvt}</Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
          {data.length < 1 && (
            <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
          )}
        </Table>
      </Table.ScrollContainer>
    );
  };
  // #endregion hàng mượn

  const dataAll = [...dataSelectLoanProduct, ...warrantyProducts];

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "type",
        header: "Loại",
        enableEditing: false,
        size: 100,
        Cell: ({ row, renderedCellValue }: any) => {
          return (
            <>
              {renderedCellValue === true ? (
                <Badge color="blue" radius="md">
                  Bảo hành
                </Badge>
              ) : (
                <Badge color="green" radius="md">
                  Cho mượn
                </Badge>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 100,
        enableEditing: (row) => {
          if (row.original.type == true) {
            return false;
          } else if (
            row.original.type == false &&
            selectedItemCodes.includes(row.original.serial)
          ) {
            return false;
          }
          return true;
        },
        Edit: ({ cell, row, table, column }) => {
          const [keepRef, setKeepRef] = useState(false);
          const ref = useClickOutside(() => {
            if (!keepRef) {
              table.setEditingCell(null);
            }
          });

          return (
            <div ref={ref}>
              <Menu
                trapFocus={false}
                trigger="hover"
                shadow="md"
                width={"800"}
                position="bottom-start"
              >
                <Menu.Target>
                  <TextInput
                    onChange={(e) => {
                      setSearchItem(e.currentTarget.value);
                    }}
                    onBlur={() => setKeepRef(false)}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <TableSelect data={dataSearchLoanRequest} />
                </Menu.Dropdown>
              </Menu>
            </div>
          );
        },
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableEditing: false,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "uomName",
        header: "Đơn vị tính",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        size: 100,
        enableEditing: false,
        Cell: ({ row }) => (
          <Text fw={500} size="11.5px">
            1
          </Text>
        ),
      },
      {
        accessorKey: "attribute1",
        header: "Phụ kiện theo kèm",
        enableEditing: (row) => row.original.type === false,
        Edit: ({ row, column, table }) => {
          return (
            <TextInput
              placeholder="Nhập phụ kiện"
              value={row._valuesCache[column.id]}
              onBlur={(e) => {
                table.setEditingCell(null);
              }}
              onChange={(e) => {
                setDataSelectLoanProduct((prevData: any) => {
                  return prevData.map((item: any) => {
                    if (item.serial === row.original.serial) {
                      return {
                        ...item,
                        attribute1: e.target.value || "",
                      };
                    }
                    return item;
                  });
                });
              }}
            />
          );
        },
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 170,
        Edit: ({ row, column, table }) => {
          const isWarrantyProduct = row.original.type === true;
          return (
            <Select
              data={[
                { label: "Mới 100%", value: "Mới 100%" },
                { label: "Đã qua sử dụng", value: "Đã qua sử dụng" },
              ]}
              defaultValue={row._valuesCache[column.id]}
              value={row.original.status?.toString()}
              onBlur={(e) => {
                table.setEditingCell(null);
              }}
              onChange={(e) => {
                if (isWarrantyProduct) {
                  setWarrantyProducts((prevData: any) => {
                    return prevData.map((item: any) => {
                      if (item.serial === row.original.serial) {
                        return {
                          ...item,
                          status: e && e != "" ? e : "Đã qua sử dụng",
                        };
                      }
                      return item;
                    });
                  });
                } else {
                  setDataSelectLoanProduct((prevData: any) => {
                    return prevData.map((item: any) => {
                      if (item.serial === row.original.serial) {
                        return {
                          ...item,
                          status: e && e != "" ? e : "Đã qua sử dụng",
                        };
                      }
                      return item;
                    });
                  });
                }
              }}
            />
          );
        },
      },
      {
        accessorKey: "orgPrice",
        header: "Giá",
        size: 100,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }: any) => (
          <>{renderedCellValue?.toLocaleString("vi-VN")} </>
        ),
      },
      {
        accessorKey: "sellingPrice",
        header: "Giá hiện hành",
        size: 100,
        Edit: ({ row, column, table }) => {
          const isWarrantyProduct = row.original.type === true;
          return (
            <NumberInput
              hideControls
              placeholder="Nhập giá hiện hành"
              value={row._valuesCache[column.id]}
              onBlur={(e) => {
                table.setEditingCell(null);
              }}
              onChange={(e) => {
                if (isWarrantyProduct) {
                  setWarrantyProducts((prevData: any) => {
                    return prevData.map((item: any) => {
                      if (item.serial === row.original.serial) {
                        return { ...item, sellingPrice: e };
                      }
                      return item;
                    });
                  });
                } else {
                  setDataSelectLoanProduct((prevData: any) => {
                    return prevData.map((item: any) => {
                      if (item.serial === row.original.serial) {
                        return { ...item, sellingPrice: e, actualPrice: e };
                      }
                      return item;
                    });
                  });
                }
              }}
            />
          );
        },
        Cell: ({ renderedCellValue, row }: any) => (
          <>{renderedCellValue?.toLocaleString("vi-VN")} </>
        ),
      },
      {
        accessorKey: "actualPrice",
        header: "Giá trị thực",
        size: 100,
        Edit: ({ row, column, table }) => {
          const isWarrantyProduct = row.original.type === true;
          return (
            <NumberInput
              hideControls
              placeholder="Nhập giá trị thực"
              value={row._valuesCache[column.id]}
              onBlur={(e) => {
                table.setEditingCell(null);
              }}
              onChange={(e) => {
                if (isWarrantyProduct) {
                  setWarrantyProducts((prevData: any) => {
                    return prevData.map((item: any) => {
                      if (item.serial === row.original.serial) {
                        return { ...item, actualPrice: e };
                      }
                      return item;
                    });
                  });
                } else {
                  setDataSelectLoanProduct((prevData: any) => {
                    return prevData.map((item: any) => {
                      if (item.serial === row.original.serial) {
                        return { ...item, actualPrice: e };
                      }
                      return item;
                    });
                  });
                }
              }}
            />
          );
        },
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            {renderedCellValue
              ? renderedCellValue?.toLocaleString("vi-VN")
              : null}{" "}
          </>
        ),
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 100,
        Edit: ({ row, column, table }) => {
          const isWarrantyProduct = row.original?.type === true;

          return (
            <TextInput
              placeholder="Nhập ghi chú"
              value={row._valuesCache[column.id] || ""}
              onBlur={(e) => {
                table.setEditingCell(null);
              }}
              onChange={(e) => {
                const newValue = e.currentTarget.value;
                if (isWarrantyProduct) {
                  setWarrantyProducts((prevData: any) => {
                    return prevData.map((item: any) => {
                      if (item.serial === row.original.serial) {
                        return { ...item, note: newValue };
                      }
                      return item;
                    });
                  });
                } else {
                  setDataSelectLoanProduct((prevData: any) => {
                    return prevData.map((item: any) => {
                      if (item.serial === row.original.serial) {
                        return { ...item, note: newValue };
                      }
                      return item;
                    });
                  });
                }
              }}
            />
          );
        },
      },
    ],
    [
      dataWanrrantySelected,
      dataSearchLoanRequest,
      dataSelectLoanProduct,
      selectedItemCodes,
      KeySearch,
      searchItem,
      dataAll,
    ]
  );

  const table = useMantineReactTable({
    columns,
    data: dataAll ? dataAll : [],
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: true,
    enableRowActions: true,
    enableColumnResizing: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    renderToolbarInternalActions: () => <></>,
    enableBatchRowSelection: true,
    onCreatingRowCancel: () => {},
    onEditingRowCancel: () => {},
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Flex gap="4">
        <Tooltip label="Chỉnh sửa">
          <IconEdit
            color="orange"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              // Toggle trạng thái chỉnh sửa
              setIsEditing((prev) => !prev);
              if (!isEditing) {
                table.setEditingRow(row); // Bắt đầu chỉnh sửa
              } else {
                table.setEditingRow(null); // Hủy chỉnh sửa
              }
            }}
          ></IconEdit>
        </Tooltip>
        {row.original.type === false && (
          <Tooltip label="Xóa">
            <IconTrash
              color="red"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setDataSelectLoanProduct((prevData: any) => {
                  return prevData.filter(
                    (item: any) => item.serial !== row.original.serial
                  );
                });
                setSelectedItemCodes((prev) => {
                  return prev.filter((item) => item !== row.original.serial);
                });
              }}
            >
              Xóa
            </IconTrash>
          </Tooltip>
        )}
      </Flex>
    ),
    mantineTableBodyCellProps: ({ row, column }) => ({
      style: styleCellTable(row),
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <TextInput
            flex={3}
            placeholder="Quét serial sản phẩm"
            size="xs"
            style={{ flex: 1 }}
            value={isSerial}
            onChange={(e) => setIsSerial(e?.currentTarget?.value.trimStart())}
          />
        </div>
        <Button
          leftSection={<IconPlus size={14} />}
          variant="outline"
          color="blue"
          size="xs"
          onClick={() => {
            table.setCreatingRow(true);
          }}
          disabled={dataAll.length === 0}
        >
          Thêm sản phẩm
        </Button>
      </div>
    ),
    enableToolbarInternalActions: false,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
        width: "100%",
      },
    },
    localization: _localization,
    mantineTableContainerProps: {
      style: { maxHeight: height - 148, minHeight: height - 148 },
    },
    initialState: {
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },

    enableStickyHeader: true,
    manualSorting: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    if (dataWanrrantySelected && dataAll.length > 0) {
      //lấy ngày nhận bảo hành
      const timeCreate = new Date(dataWanrrantySelected?.createDate);
      //lấy ngày mua
      // Kiểm tra ngày mua
      const result =
        dataWanrrantySelected?.tblWarrantyReceiveTicketDetailModels.map(
          (item: any) => {
            if (item.buyingDate === null) {
              return NotificationExtension.Fails(
                "Ngày mua sản phẩm bảo hành không hợp lệ. Vui lòng chọn số phiếu khác!"
              );
            } else {
              const serialItem = item?.serial; //serrial
              //lấy giá mới
              const priceNew = dataAll.find(
                (x) => x.serial == item?.serial
              )?.sellingPrice;
              //lấy ngày mua
              // const timeByingDate = new Date(item.buyingDate);
              // //thồi gian đã sử dụng
              // const timeUsed =
              //   (timeCreate.getFullYear() - timeByingDate.getFullYear()) * 12 +
              //   (timeCreate.getMonth() - timeByingDate.getMonth());
              //giá lúc mua
              const latestPrice = item.latestPrice;
              const warrantyTerm = item.warrantyTerm; // Thời gian bảo hành (tính bằng tháng)

              if (priceNew !== undefined) {
                //giá trị tính khấu hao
                const depreciationValue = Math.min(priceNew, latestPrice) * 0.9;
                //tính giá trị khấu hao
                // console.log("depreciationValue", depreciationValue);
                const depreciation = (depreciationValue * 1) / warrantyTerm;
                // console.log("giá trị đã kháu hao", depreciation);
                //Tính giá trị thực trả khách
                const depositItem =
                  Math.min(priceNew, latestPrice) - depreciation;
                return {
                  actualPrice: Math.round(depositItem / 1000) * 1000,
                  serial: serialItem,
                };
              }
            }
          }
        );

      if (result !== undefined) {
        setDepositItemWarrantyProduct(result);
        let depositItemLoanProduct = 0; //giá trị thực trả khác cuar  sp cho mượn
        let depositItemWarrantyProduct = 0; //giá trị thực trả khác cuar sp bảo hành
        result.map((item: any) => {
          if (item !== undefined) {
            return (depositItemWarrantyProduct += item.actualPrice);
          }
        });

        dataAll.map((item: any) => {
          if (item.type === false) {
            return (depositItemLoanProduct += item.actualPrice);
          }
        });
        // console.log("depositItemLoanProduct", depositItemLoanProduct);
        // console.log("depositItemWarrantyProduct", depositItemWarrantyProduct);
        if (depositItemLoanProduct > depositItemWarrantyProduct) {
          const totalDepositValue =
            Math.round(
              (depositItemLoanProduct - depositItemWarrantyProduct) / 1000
            ) * 1000;
          setTotalDeposit(totalDepositValue > 0 ? totalDepositValue : 0);
          // const dataAll = [...dataSelectLoanProduct, ...warrantyProducts];
          setDataSelectLoanProduct((prev) =>
            prev?.map((item) => ({
              ...item,
              deposit: totalDepositValue,
            }))
          );
          setWarrantyProducts((prev) =>
            prev?.map((item) => ({
              ...item,
              deposit: totalDepositValue,
            }))
          );
        } else {
          setTotalDeposit(0);
        }
      }
    }
  }, [warrantyProducts, dataSelectLoanProduct]);

  const prevDepositItemWarrantyProduct = useRef<any[]>([]);
  //Tạo phiếu yêu cầu xuất mượn
  const handleCreate = async () => {
    const dataSubmit = {
      receiveId: IdFormDataSelected, //id phiếu tiếp nhận hàng lỗi
      borrowCode: codeYCHM, //mã phiếu xuất hàng mượn
      totalDeposit: totalDeposit, // tổng tiền cọc
      note: note,
      status: null,
      attribute1: null,
      attribute2: codeYCHM,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      departmentId: userInfo.departmentId,
      inventoryId: dataWanrrantySelected?.inventoryId,
      branchId: dataWanrrantySelected?.branchId,
      roleId: userInfo.roleId,
      tblWarrantyExportItemBorrowTicketDetailCommands: [...dataAll],
    };
    const response = await repositoryMdm.post(
      "/api/v1/TblWarrantyExportItemBorrowTicket/create",
      dataSubmit
    );
    if (response && response.httpStatusCode === 200) {
      NotificationExtension.Success("Tạo phiếu yêu cầu hàng mượn thành công");
      setTimeout(() => {
        navigate("/warranty/list-borrowed-goods");
      }, 2000);
    }
    try {
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (depositItemWarrantyProduct) {
      if (
        JSON.stringify(prevDepositItemWarrantyProduct.current) !==
        JSON.stringify(depositItemWarrantyProduct)
      ) {
        depositItemWarrantyProduct.map((item: any) => {
          setWarrantyProducts((prev) => {
            return prev.map((itemProduct) => {
              if (itemProduct?.serial === item?.serial) {
                return { ...itemProduct, actualPrice: item.actualPrice };
              }
              return itemProduct;
            });
          });
        });
        prevDepositItemWarrantyProduct.current = depositItemWarrantyProduct;
      }
    }
  }, [depositItemWarrantyProduct]);

  useEffect(() => {
    // lấy danh sách sản phẩm cho mượn
    fetchDataLoanRequest();
  }, [searchItem, selectedItemCodes, isSerial]);

  useEffect(() => {
    fetchDetailDefectivegoods();
  }, [IdFormDataSelected]);
  useEffect(() => {
    if (isSerial !== "") {
      const item = dataSearchLoanRequest.find(
        (x: any) => x.maBarcode === isSerial
      );
      if (item) {
        handSelectItem(item);
        setIsSerial("");
      } else {
        NotificationExtension.Fails("Serial không tồn tại");
        setIsSerial("");
      }
    }
  }, [isSerial]);

  useEffect(() => {
    // lấy thông tin user
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) setUserInfo(JSON.parse(userLogin));

    //Tạo phiếu YCHM
    const getCreateExportBorrwedGood = async () => {
      try {
        const response = await repositoryMdm.get(
          `/api/v1/TblWarrantyExportItemBorrowTicket/create-pxm?prefix=PYCXM`
        );
        setCodeYCHM(response.data.borrowCode);
      } catch (error) {
        NotificationExtension.Fails("Lấy dữ liệu thất bại");
      }
    };
    getCreateExportBorrwedGood();
  }, []);

  const handleReturnPage = () => {
    navigate("/warranty/list-borrowed-goods");
  };
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (212.5 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        p={"0px 10px"}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex
          justify={"space-between"}
          align={"center"}
          style={{ marginBottom: "10px" }}
        >
          <Flex w={"100%"} justify={"flex-end"} gap={10}>
            <Button
              type="button"
              color="red"
              onClick={() => handleReturnPage()}
              leftSection={<IconArrowBack size={18} />}
            >
              Thoát
            </Button>
            <Button
              type="button"
              color={"teal"}
              leftSection={<IconCheck size={18} />}
              onClick={() => handleCreate()}
            >
              Lưu
            </Button>
          </Flex>
        </Flex>
        <Grid>
          <Grid.Col span={5}>
            <ScrollArea h={height + 80} scrollbars="y">
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Fieldset legend="Thông tin khách hàng">
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Tên khách hàng"
                          placeholder="Tên khách hàng"
                          readOnly
                          variant="filled"
                          value={dataWanrrantySelected?.custMeeting ?? ""}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số điện thoại"
                          placeholder="Số điện thoại"
                          readOnly
                          variant="filled"
                          value={dataWanrrantySelected?.custPhone ?? ""}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                        <TextInput
                          label="Địa chỉ"
                          placeholder="Địa chỉ"
                          readOnly
                          variant="filled"
                          value={dataWanrrantySelected?.meetingAddress ?? ""}
                        />
                      </Grid.Col>
                    </Grid>
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Fieldset legend="Thông tin phiếu yêu cầu xuất mượn">
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số phiếu yêu cầu hàng mượn"
                          placeholder="Số phiếu yêu cầu hàng mượn"
                          readOnly
                          variant="filled"
                          value={codeYCHM ?? ""}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <Menu
                          trigger="hover"
                          openDelay={100}
                          closeDelay={400}
                          width={500}
                        >
                          <Menu.Target>
                            <TextInput
                              label="Số phiếu tiếp nhận hàng lỗi"
                              placeholder="Nhập số phiếu tiếp nhận hàng lỗi"
                              value={KeySearch}
                              onChange={(e) => {
                                setKeySearch(e.currentTarget.value);
                              }}
                            />
                          </Menu.Target>
                          <Menu.Dropdown
                            w={800}
                            h={150}
                            style={{ height: "100px" }}
                          >
                            <BorrowedGoodList
                              IdFormDataSelected={IdFormDataSelected}
                              setIdFormDataSelected={setIdFormDataSelected}
                              KeySearch={KeySearch}
                            />
                          </Menu.Dropdown>
                        </Menu>
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Bộ phận / Phòng ban"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={userInfo?.depName || ""}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Người lập"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={userInfo?.fullName || ""}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <DateTimePicker
                          label="Ngày tạo phiếu"
                          valueFormat="DD-MM-YYYY hh:mm A"
                          value={new Date()}
                          variant="filled"
                          readOnly
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Kho"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={dataWanrrantySelected?.inventoryCode ?? ""}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Chi nhánh"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={userInfo?.branchName || ""}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Chức danh"
                          placeholder=""
                          readOnly
                          variant="filled"
                          value={userInfo?.roleName || ""}
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                        <div>
                          <Textarea
                            h={60}
                            label="Ghi chú"
                            onChange={(e) => setNote(e.target.value)}
                          />
                        </div>{" "}
                      </Grid.Col>
                    </Grid>
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </ScrollArea>
          </Grid.Col>
          <Grid.Col span={7}>
            <Fieldset legend="Thông tin sản phẩm">
              <MantineReactTable table={table} />
            </Fieldset>
            <Flex justify={"start"} gap={"xs"} h={100} mt={5}>
              <Flex
                direction={"row"}
                align={"center"}
                justify={"center"}
                bd={"1px solid #DEE2E6"}
                style={{ borderRadius: "10px" }}
                gap={"md"}
                w={300}
              >
                <Text size="14px" fw={"bold"}>
                  Tổng số tiền cọc :
                </Text>
                <Text size="13px" fw={"bold"} c={"red"}>
                  {formatCurrency(totalDeposit)} VND
                </Text>
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default CreateExportBorrwedGood;
