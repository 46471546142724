import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  Menu,
  NumberFormatter,
  Select,
  Switch,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState, useDisclosure, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCheck,
  IconMaximize,
  IconMaximizeOff,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { anyAsciiCode } from "../../../../_base/helper/AnyAscii";
import { _localization } from "../../../../config/location";
import { sky_blue } from "../../../../const/variables";
import { MessageResponse } from "../../../../model/MessageResponse";
import { OrderDetailOutWardNew } from "../../../../model/SaleOrderModel";
import {
  TblInventorySerialItemDetailOnHand,
  TblInventorySerialOrderDetailOnHand,
} from "../../../../model/TblInventorySerialOnHand";
import {
  detailOrderForOutWardModel,
  TblOutward,
  TblOutwardDetail,
} from "../../../../model/TblOutWard";
import { getTblInventoryTransactionTypeOutWard } from "../../../../service/getSelectApi";
import RequestProductionOutWard from "../components/RequestProductionOutWard";
import RequestTransferOutWard from "../components/RequestTransferOutWard";
import RequestTransferOutWarDaccessory from "../components/RequestTransferOutWarDaccessory";
import RequestVendorReturnOutWard from "../components/RequestVendorReturnOutWard";
import SaleOrderOutward from "../components/SaleOrderOutward";
import useDynamicHeight from "../hooks/useDynamicHeight";
import { Inventory } from "../interface/interfaceInvertory";
import { outward } from "../services";
import { getActionFromPath } from "../utils";
import PrintWarehouseOutWard from "./PrintWarehouseOutWard";

interface TblInventorySerialOrderDetailOnHandEdit
  extends TblInventorySerialOrderDetailOnHand {
  type?: string;
  poPrice1?: number;
  note?: string | null;
}

const WarehouseOutWardAction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // Location
  const sourceCode = location.state && location.state?.sourceCode;
  const transactionCode = location.state && location.state?.transactionCode;
  const sourceType =
    (location.state && location.state?.sourceType) || location.state?.type;
  const locker = location.state && location.state.isLocked;
  const id = location.state && location.state.id;

  // Print
  const [currentDate, setCurrentDate] = useState<string>("");
  const [idPrint, setIdPrint] = useState<string | number>("");
  const [typePrint, setTypePrint] = useState<string | null>("");
  const [dataPrint, setDataPrint] = useState(null);
  const componentRef = React.useRef(null);
  const [statusReponse, setStatusReponse] = useState<boolean>(false);
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: `
    @page{
      size: A4;
      margin:0;
    }
    body:{
      margin: 0;
      color: #000;
    }`,
    // onBeforePrint: () => console.log("Before printing..."),
    // onAfterPrint: () => console.log("After printing..."),
  });
  // Action define
  const action = getActionFromPath(location?.pathname ?? "");
  //#region render ui outward
  const getOutWardProps = (additionalProps = {}) => ({
    dataSearchSourceCodeOption,
    dataPosition,
    dataInventoryByBranch,
    dataOutWard: dataOutWard?.tblItemTransactionHeaderCommand,
    dataOrderDetail: dataOrderDetail?.tblInventoryTransactionModel,
    handleChangeValueInput,
    setSourceCode,
    handleKeyDownSourceCode,
    sourceCode,
    isSave,
    searchSourceCode,
    setSearchSourceCode,
    isLocked: locker,
    haveSourceCode: sourceCode,
    ...additionalProps,
  });

  // Thong tin chung xuat lien chi nhanh
  const requestTransfetOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({
          haveStatus: true,
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };

  // Thong tin chung xuat noi bo
  const requestTransferInternalOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({
          haveStatus: true,
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };

  // Thong tin chung xuat ban hang
  const orderOutWard = () => {
    return (
      <SaleOrderOutward
        {...getOutWardProps({ isEdit: ["EDIT", "DETAIL"].includes(action) })}
      />
    );
  };

  // Thong tin chung xuat huy
  const cancelOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({ isEdit: ["EDIT", "DETAIL"].includes(action) })}
      />
    );
  };

  // Thong tin chung xuat dieu chuyen serial
  const serialOutWard = cancelOutWard;

  // Thong tin chung xuat dieu chuyen noi bo serial
  const serialInternalOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };

  // Thong tin chung xuat kho dieu chinh
  const adjustmentOutWard = cancelOutWard;

  // Thong tin chung xuat kho tra ncc
  const returnVendorOutWard = () => {
    return (
      <RequestVendorReturnOutWard
        {...getOutWardProps({ isEdit: ["EDIT", "DETAIL"].includes(action) })}
      />
    );
  };

  // Thong tin chung xuat dieu chuyen lap rap
  const createProductOutWard = () => {
    return (
      <RequestProductionOutWard
        {...getOutWardProps({
          haveSourceCode: true,
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };
  //Thông tin chung xuất điều chuyển linh kiện sản xuất
  const createTransferProduction = () => {
    return (
      <RequestTransferOutWarDaccessory
        {...getOutWardProps({
          haveSourceCode: true,
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };
  //#endregion

  //#region state
  const outWardEntity = {
    sourceId: 0,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: sourceCode || transactionCode || "",
      sourceType: sourceType || "",
      inventoryId: null,
      subInvId: null,
      departmentId: 0,
      status: "NEW",
      description: "",
      atribute1: "",
      atribute2: "",
      atribute3: "",
      atribute4: "",
      atribute5: "",
      atribute6: "",
      atribute7: "",
      atribute8: "",
      atribute9: "",
      atribute10: "",
      attribute11: "",
      attribute12: "",
      createBy: 0,
      createName: "",
      createDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    serials: [],
  };

  const [dataOutWard, setDataOutWard] = useState<TblOutward>(outWardEntity);

  const [isError, setIsError] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();

  //table state
  const entityOrderOutWard = {
    tblInventoryTransactionModel: {},
    inventoryTransactionRequestSourceModels: [],
    invCode: null,
    invId: null,
    invName: null,
    outWardCode: null,
    receiver: null,
    sourceCode: null,
    sourceId: null,
    subInvId: null,
    toAddess: null,
  };

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataOrderDetail, setDataOrderDetail] =
    useState<OrderDetailOutWardNew>(entityOrderOutWard);

  const [serial, setSerial] = useState<string>("");
  const [keySearch, setKeySearch] = useDebouncedState("", 300);
  const [searchSourceCode, setSearchSourceCode] = useDebouncedState("", 300);
  const [dataItem, setDataItem] = useState<
    TblInventorySerialItemDetailOnHand[]
  >([]);
  const [dataSearchSourceCodeOption, setDataSearchSourceCodeOption] = useState<
    any[]
  >([]);
  const [dataInventoryByBranch, setDataInventoryByBranch] = useState<
    Inventory[]
  >([]);
  const [dataSerialOnHand, setDataSerialOnHand] = useState<
    TblInventorySerialOrderDetailOnHandEdit[]
  >([]);
  const [dataPosition, setDataPosition] = useState<ComboboxItem[]>([]);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<string[]>([]);
  const [isExceedQuantity, setIsExceedQuantity] = useState(false);
  const [transactionTempId, setTransactionTempId] = useState(0);
  const [isSave, setIsSave] = useState(false);
  const [sourceCodeRef, setSourceCode] = useState(
    location.state && location.state.sourceCode
  );
  const [isLockForOrder, setIsLockForOrder] = useState(true);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const sidePanelRef = React.useRef<HTMLDivElement>(null);
  const [isCollapse, { toggle: toggleCollapse }] = useDisclosure(false);
  const height = useDynamicHeight(headerRef, 310);
  const [mainContentWidth, setMainContentWidth] = useState(0);

  //#endregion
  //Thay đổi khi có nhiều sourceType
  const changeSourceType =
    dataOutWard?.tblItemTransactionHeaderCommand?.sourceType;
  const renderMainOutWard = () => {
    const _sourceType =
      sourceType ?? dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    switch (_sourceType) {
      case "XUAT_BAN":
        return orderOutWard();
      case "XUAT_HUY":
        return cancelOutWard();
      case "XUAT_DIEU_CHINH":
        return adjustmentOutWard();
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return requestTransfetOutWard();
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return requestTransferInternalOutWard();
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return serialOutWard();
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return serialInternalOutWard();
      case "XUAT_TRA_NCC":
        return returnVendorOutWard();
      case "XUAT_TRA_NCC-DCTT":
        return returnVendorOutWard();
      case "XUAT_MUON_BAO_HANH":
        return cancelOutWard();
      case "XUAT_LINH_KIEN_SAN_XUAT":
        return createProductOutWard();
      // case "XUAT_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT":
      //   return createTransferProduction();
      default:
        return requestTransfetOutWard();
    }
  };

  const getColumnVisibility = () => {
    const _sourceType =
      sourceType ?? dataOutWard.tblItemTransactionHeaderCommand.sourceType;

    const defaultVisibility = {
      id: false,
      poPrice: true,
      subPrice: true,
      totalAmount: true,
      serial: false,
      limitQuantity: false,
    };

    if (
      ["XUAT_DIEU_CHUYEN_CHI_NHANH", "XUAT_DIEU_CHUYEN_NOI_BO"].includes(
        _sourceType
      )
    ) {
      return {
        ...defaultVisibility,
        poPrice: false,
        subPrice: false,
        totalAmount: false,
        limitQuantity: false,
      };
    }

    if (_sourceType === "XUAT_LINH_KIEN_SAN_XUAT") {
      return {
        ...defaultVisibility,
        poPrice: true,
        subPrice: false,
        serial: false,
        subQuantity: false,
        subUom: false,
        primaryQuantity: false,
        limitQuantity: true,
      };
    }

    if (_sourceType?.includes("SERIAL") || _sourceType === "XUAT_HUY") {
      return { ...defaultVisibility, serial: true };
    }

    return defaultVisibility;
  };

  // func render ui => title
  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap={20}>
              <Text c={"red"} fw={700}>
                Loại phiếu
              </Text>
              <Select
                searchable
                clearable
                w={200}
                size="xs"
                placeholder="Chọn loại phiếu"
                disabled={
                  dataOutWard.tblItemTransactionHeaderCommand.status ===
                    "LOCK" ||
                  sourceCode ||
                  isSave ||
                  ["DETAIL", "EDIT"].includes(action)
                }
                data={dataInventoryTransactionType}
                value={
                  dataOutWard.tblItemTransactionHeaderCommand.sourceType?.toString() ||
                  ""
                }
                onChange={(value) => {
                  handleChangeValueInput(value || "", "sourceType");
                }}
              />
            </Group>
            <Tooltip
              label={isCollapse ? "Mở rộng" : "Thu gọn"}
              position="bottom"
            >
              <Group align="center">
                <Switch
                  size="sm"
                  color={isCollapse ? "red" : "green"}
                  checked={!isCollapse}
                  onChange={toggleCollapse}
                  label={isCollapse ? "Thu gọn (Ctrl+M)" : "Mở rộng (Ctrl+M)"}
                  thumbIcon={
                    !isCollapse ? (
                      <IconMaximize
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    ) : (
                      <IconMaximizeOff
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    )
                  }
                />
              </Group>
            </Tooltip>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
            {dataOutWard.tblItemTransactionHeaderCommand.attribute14 === "1"
              ? dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
                  "XUAT_LINH_KIEN_SAN_XUAT" && (
                  <Button
                    variant="filled"
                    disabled={statusReponse}
                    leftSection={<IconX size={20} />}
                    color="#495057"
                    onClick={() => handleCancelTheSet()}
                  >
                    Hủy bộ
                  </Button>
                )
              : null}
            {dataOutWard.tblItemTransactionHeaderCommand.status !== "LOCK" &&
            changeSourceType === "XUAT_BAN" &&
            location.pathname ==
              "/warehouse-config/detail-warehouse-out-ward" ? (
              <>
                <Button
                  color="green"
                  onClick={() =>
                    onhandlePrint(location?.state.id, location?.state?.type)
                  }
                >
                  In Phiếu
                </Button>
              </>
            ) : (
              <></>
            )}
            <Button
              type="submit"
              color={sky_blue.base}
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                locker ||
                changeSourceType === "XUAT_LINH_KIEN_SAN_XUAT" ||
                dataSerialOnHand?.length === 0
              }
              onClick={() => {
                setIsSave(true);
                handleCreateOutWard("OPEN");
              }}
            >
              Lưu (Ctrl+S)
            </Button>
            <Button
              type="button"
              color="green"
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                locker ||
                isLockForOrder ||
                dataSerialOnHand?.length === 0
              }
              onClick={() => {
                handleCreateOutWard("LOCK", true);
              }}
            >
              Hoàn thành (Ctrl+Q)
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  // func render ui => select item outward
  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Tuổi tồn</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItem?.map((item) => {
            return (
              <Table.Tr
                key={item.serial}
                bg={
                  checkDuplicateCode.some((select) => select === item.serial)
                    ? "var(--mantine-color-blue-light)"
                    : undefined
                }
              >
                <Table.Td>
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      fetchDataSerialOnHand(item.serial);
                    }}
                    disabled={checkDuplicateCode.includes(item.serial)}
                  >
                    {checkDuplicateCode.includes(item.serial ?? "") ||
                    checkDuplicateCode.includes(item.itemCode ?? "")
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                </Table.Td>
                <Table.Td>{item.itemCode}</Table.Td>
                <Table.Td>
                  <Tooltip label={item.itemName}>
                    <Text size="sm" w={250} lineClamp={2} truncate="end">
                      {item.itemName}
                    </Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td>{item.serial}</Table.Td>
                <Table.Td>{item.itemAge}</Table.Td>
                <Table.Td>
                  <Tooltip label={item.invName}>
                    <Text size="sm" w={125} truncate="end">
                      {item.invName}
                    </Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td>
                  {item.primaryUomName || item.primaryUomCode}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
        <Table.Tfoot></Table.Tfoot>
        {dataItem.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  // func render ui => text input transaction header
  const TextTransactionHeader = () => (
    <TextInput
      flex={3}
      placeholder="Nhập mã vạch"
      size="xs"
      disabled={dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"}
      value={anyAsciiCode(serial).toLocaleUpperCase()}
      onKeyDown={(e) => {
        if (e.key === " ") {
          e.preventDefault();
          return;
        }
        if (e.key === "Enter" && e.currentTarget.value.trim() !== "") {
          handleAddProduct();
        }
      }}
      onChange={(e) => setSerial(e?.currentTarget?.value)}
    />
  );

  const MenuDataOutWart = () => (
    <Menu
      opened={keySearch.length > 2 && dataItem?.length !== 0}
      trapFocus={false}
      shadow="md"
      width={"800"}
      position="bottom-start"
    >
      <Menu.Target>
        <TextInput
          placeholder="Hoặc tìm kiếm mã sản phẩm"
          size="xs"
          disabled={
            dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
          }
          onChange={async (e) => {
            const _key = e.target.value ?? "";
            setKeySearch(_key);
          }}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <TableSelect />
      </Menu.Dropdown>
    </Menu>
  );

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleCancelTheSet = async () => {
    const dataCreate = {
      outWardId: dataOutWard.tblItemTransactionHeaderCommand.transactionId ?? 0,
      outWardTransactionCode:
        dataOutWard.tblItemTransactionHeaderCommand.transactionCode ?? "",
    };

    const response = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/TblMfgProductOrder/cancel-item",
      dataCreate
    );
    if (response && response?.success) {
      setStatusReponse(response?.success);
      NotificationExtension.Success("Hủy thành công");
      return response;
    }
  };

  const handleKeyDownSourceCode = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      if (dataSearchSourceCodeOption.length === 1) {
        handleChangeValueInput(searchSourceCode || "", "sourceCode");
      }
    }
  };

  const handleChangeValueInput = (
    value: null | string | boolean,
    key: string
  ) => {
    setDataOutWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        [key]: value,
      },
    }));
  };

  const handleAddProduct = () => {
    if (dataOutWard.tblItemTransactionHeaderCommand?.sourceCode) {
      if (checkDuplicateCode.length === 0) {
        fetchDataSerialOnHand();
        setSerial("");
      } else if (checkDuplicateCode.length > 0) {
        if (checkDuplicateCode.includes(serial)) {
          NotificationExtension.Fails("Mã Serial đã được quét !");
        } else {
          setCheckDuplicateCode((prev) => [...prev, serial]);
          fetchDataSerialOnHand();
          setSerial("");
        }
      }
    } else {
      NotificationExtension.Fails("Chưa nhập số chứng từ");
    }
  };

  const deleteItemSerial = (serial: string) => {
    setDataSerialOnHand((prevData) =>
      prevData.filter((item) => item?.serial !== serial)
    );
    setCheckDuplicateCode((prevData) =>
      prevData.filter((item) => item !== serial)
    );
  };

  const checkVarianceItem = (
    items: detailOrderForOutWardModel[],
    checkQuantityBy: string
  ) => {
    let variance = false;
    let variaceItems: { itemName: string; varianceQuantity: number }[] = [];
    items.forEach((item) => {
      const _varianceQuantity =
        (item.scannedQuantity || 0) - ((item as any)[checkQuantityBy] || 0);
      if (_varianceQuantity !== 0) {
        variaceItems = [
          ...variaceItems,
          {
            itemName: item.itemName,
            varianceQuantity: _varianceQuantity,
          },
        ];
        variance = true;
      }
    });
    return {
      isVariance: variance,
      variaceItems: variaceItems,
    };
  };

  const openCofirmModal = (
    variaceItems: any[],
    isLocked: boolean
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      modals.openConfirmModal({
        size: "auto",
        children: (
          <Box component="form" mx="auto" style={{ position: "relative" }}>
            <Text size="20px" fw={"bold"} my={10}>
              Có những sản phẩm sau có lệch? Bạn có chắc chắn{" "}
              {isLocked ? "hoàn thành" : "lưu"} phiếu?
            </Text>
            <Box mt={3}>
              <Table
                highlightOnHover
                striped
                withColumnBorders
                horizontalSpacing="md"
                verticalSpacing="sm"
                bd={"1px solid #E0E0E0"}
              >
                <thead>
                  <tr style={{ backgroundColor: "#F8F9FD" }}>
                    <th
                      style={{
                        textAlign: "left",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Tên sản phẩm
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Số lượng lệch
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {variaceItems?.map((item, index) => {
                    const varianceQuantity = item.varianceQuantity || 0;
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "12px",
                            fontWeight: "bold",
                            textAlign: "left",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {item.itemName}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "red",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {varianceQuantity}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Box>

            <Group
              justify="end"
              mt="xs"
              style={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
              }}
            >
              <Button
                type="button"
                color="gray"
                onClick={() => {
                  handleChangeValueInput("", "status");
                  modals.closeAll();
                  resolve(false);
                }}
                leftSection={<IconX size={18} />}
              >
                Hủy
              </Button>

              <Button
                onClick={() => {
                  handleChangeValueInput("LOCK", "status");
                  modals.closeAll();
                  resolve(true);
                }}
                color={sky_blue.base}
                leftSection={<IconCheck size={18} />}
              >
                Xác nhận
              </Button>
            </Group>
          </Box>
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
        onClose() {
          handleChangeValueInput("", "status");
        },
      });
    });
  };

  const checkExceedQuantityFound = (
    data: any[],
    fieldCheckQuantity: string
  ) => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const _exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = data
            .filter((item) => {
              const checkItem = _sourceType?.includes("SERIAL")
                ? item.serial === order.serial
                : item.itemId === order.itemId ||
                  item.itemCode === order.itemCode;
              return checkItem && !item.type;
            })
            .map((item) => item?.primaryQuantity || 0);

          if (matchingOrder) {
            const totalQuantity = matchingOrder?.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const orderQuantity = (order as any)[fieldCheckQuantity] ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              action !== "CREATE"
                ? orderQuantity - (orderTransferQuantity + totalQuantity) || 0
                : orderQuantity - totalQuantity || 0;
            return remainQuantity < 0;
          }

          return false;
        }
      );

    if (_exceedQuantityFound) {
      return true;
    }
    return false;
  };

  const handleCreateOutWard = async (status: string, isLocked?: boolean) => {
    const getTotalQuantity = (key: string) =>
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.reduce(
        (total, item: any) => total + (item[key] || 0),
        0
      );
    if (
      dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
      "XUAT_DOI_LINH_KIEN"
    ) {
      if (dataOutWard.tblItemTransactionHeaderCommand.atribute10 === null) {
        NotificationExtension.Fails("Vui lòng chọn vị trí");
        return;
      }
    }
    if (
      dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
      "XUAT_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT"
    ) {
      if (dataOutWard.tblItemTransactionHeaderCommand.atribute10 === null) {
        NotificationExtension.Fails("Vui lòng chọn vị trí");
        return;
      }
    }

    const totalPrimaryQuantity = getTotalQuantity("primaryQuantity");
    const totalLimitQuantity = getTotalQuantity("limitQuantity");

    const checkTotalPrimary = totalPrimaryQuantity === dataSerialOnHand?.length;
    const checkTotalQuantity = totalLimitQuantity === dataSerialOnHand?.length;

    const _sourceType =
      dataOutWard?.tblItemTransactionHeaderCommand?.sourceType || sourceType;

    const validationErrors = [
      {
        condition: dataSerialOnHand.some((item) => item.warning),
        message: "Có sản phẩm không hợp lệ",
      },
      {
        condition: isExceedQuantity,
        message: "Có sản phẩm vượt quá số lượng cần xuất",
      },
      {
        condition:
          _sourceType === "XUAT_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT" &&
          !checkTotalPrimary,
        message: "Vui lòng nhập đủ linh kiện",
      },
      {
        condition:
          _sourceType === "XUAT_LINH_KIEN_SAN_XUAT" && !checkTotalQuantity,
        message: "Vui lòng nhập đủ linh kiện",
      },
      {
        condition:
          dataOutWard?.tblItemTransactionHeaderCommand?.description &&
          dataOutWard?.tblItemTransactionHeaderCommand?.description?.length >
            300,
        message: "Ghi chú phiếu xuất không quá 300 ký tự",
      },
      {
        condition:
          _sourceType === "XUAT_LINH_KIEN_SAN_XUAT" &&
          !dataOutWard?.tblItemTransactionHeaderCommand?.parentSerial,
        message: "Vui lòng nhập mã serial",
      },
    ];

    // Kiểm tra lỗi và thông báo
    for (const { condition, message } of validationErrors) {
      if (condition) {
        NotificationExtension.Fails(message);
        return;
      }
    }

    // Reset key search và thay đổi trạng thái
    setKeySearch("");
    handleChangeValueInput(status, "status");

    // Chuẩn bị dữ liệu tạo phiếu
    const serials = dataSerialOnHand?.map((item) => ({
      primaryQuantity: item.primaryQuantity,
      subQuantity: item.subQuantity,
      serial: item.serial,
      invId:
        dataOrderDetail?.tblInventoryTransactionModel?.fromInvId ?? item.invId,
      note: item.note,
      locatorId: Number(item.locatorId),
    }));

    const attributes = getAttributes(
      dataOutWard.tblItemTransactionHeaderCommand.sourceType
    );
    const dataCreate = {
      ...dataOutWard,
      tblItemTransactionHeaderCommand: {
        ...dataOutWard.tblItemTransactionHeaderCommand,
        branchId: dataOrderDetail?.tblInventoryTransactionModel?.fromBranchId,
        subInvId:
          dataOrderDetail?.tblInventoryTransactionModel?.fromInvId ?? 6014,
        status,
        description: dataOutWard.tblItemTransactionHeaderCommand.description,
        parentSerial:
          dataOutWard?.tblItemTransactionHeaderCommand?.parentSerial,

        attribute11:
          dataOutWard?.tblItemTransactionHeaderCommand?.attribute11?.toString() ??
          "",
        attribute12:
          dataOutWard?.tblItemTransactionHeaderCommand?.attribute12?.toString() ??
          "",
        fromPositionId:
          dataOutWard?.tblItemTransactionHeaderCommand?.fromPositionId,
        ...attributes,
      },
      serials,
      isLock: isLocked,
    };

    const url = getOutwardApiUrl(
      "create",
      dataOutWard.tblItemTransactionHeaderCommand.sourceType
    );

    try {
      const checkQuantityBy =
        _sourceType === "XUAT_LINH_KIEN_SAN_XUAT"
          ? "limitQuantity"
          : "primaryQuantity";
      const variance = checkVarianceItem(
        dataOrderDetail?.inventoryTransactionRequestSourceModels,
        checkQuantityBy
      );

      if (variance.isVariance) {
        const confirm = await openCofirmModal(
          variance.variaceItems,
          status === "LOCK"
        );
        if (!confirm) {
          handleChangeValueInput("OPEN", "status");
          return;
        }
      }

      // Xử lý khi trạng thái là "LOCK"
      if (status === "LOCK") {
        const lockResponse = await postOutwardRequest(url, dataCreate);
        if (lockResponse?.success) {
          handleChangeValueInput("LOCK", "status");
          handleChangeValueInput(Date().toString(), "finishDate");
          NotificationExtension.Success("Khóa phiếu xuất kho thành công");
          if (
            [
              "XUAT_LINH_KIEN_SAN_XUAT",
              "XUAT_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT",
            ].includes(changeSourceType ?? "")
          ) {
            setTimeout(() => navigate("/warehouse-config/wip"), 3000);
          }
          if (_sourceType === "XUAT_BAN") {
            setTimeout(
              () =>
                onhandlePrint(
                  lockResponse.data,
                  dataCreate.tblItemTransactionHeaderCommand.sourceType
                ),
              1000
            );
          }
        } else {
          handleChangeValueInput("", "status");
        }
        return;
      }

      // Xử lý khi trạng thái là "OPEN"
      const response = await postOutwardRequest(url, dataCreate);
      if (response && status === "OPEN") {
        handleChangeValueInput("OPEN", "status");
        setTransactionTempId(response?.data?.data);
        NotificationExtension.Success("Lưu phiếu xuất kho thành công");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onhandlePrint = (id: string | number, type: string | null) => {
    if (id != null && type != null) {
      setIdPrint(id);
      setTypePrint(type);
    }
  };

  const fetchDataToPrint = async (id: number | string, type: string | null) => {
    let apiPath;
    switch (type) {
      case "XUAT_BAN":
        apiPath = `/api/v1/Outward/print-sale-order-out-ward?id=${id}`;
        break;
      case "XUAT_TRA_NCC":
        apiPath = `/api/v1/Outward/print-request-vendor-return-outward?id=${id}`;
        break;
      case "XUAT_HUY":
        apiPath = `/api/v1/Outward/print-request-cancellation-outward?id=${id}`;
        break;
      case "XUAT_DIEU_CHINH":
        apiPath = `/api/v1/Outward/print-request-adjustment-outward?id=${id}`;
        break;
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        apiPath = `/api/v1/Outward/print-request-transfer-outward?id=${id}`;
        break;
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        apiPath = `/api/v1/Outward/print-request-transfer-internal-outward?id=${id}`;
        break;

      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        apiPath = `/api/v1/Outward/print-request-transfer-serial-outward?id=${id}`;
        break;
      // case "XUAT_LINH_KIEN_SAN_XUAT":
      //   apiPath = `/api/v1/Outward/print-production-order-outward?id=${id}`;
      //   break;
      // case "XUAT_LINH_KIEN_SAN_XUAT":
      //   apiPath = `/api/v1/Outward/print-production-order-outward?id=${id}`;
      //   break;
      default:
        return;
    }
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(apiPath);
      if (response?.success && response?.data) {
        setDataPrint(response?.data);
      } else {
        console.log("error fetch data to print");
        setDataPrint(null);
        NotificationExtension.Fails("error fetch data to print");
      }
    } catch (error) {
      console.log("error fetch data to print", error);
    }
  };

  useEffect(() => {
    if (idPrint !== "") {
      fetchDataToPrint(idPrint, typePrint);
    }
  }, [idPrint]);

  useEffect(() => {
    if (dataPrint !== null) {
      handlePrint();
      setIdPrint("");
      setDataPrint(null);
    }
  }, [dataPrint]);

  useEffect(() => {
    const getCurrentDateFormatted = (): string => {
      const today = new Date();
      const day = today.getDate()?.toString().padStart(2, "0");
      const month = (today.getMonth() + 1)?.toString().padStart(2, "0");
      const year = today.getFullYear();

      return `${day}.${month}.${year}`;
    };
    setCurrentDate(getCurrentDateFormatted());
  }, []);

  const postOutwardRequest = async (url: string, dataCreate: any) => {
    const response = await repositoryPos.post<MessageResponse<any>>(
      url,
      dataCreate
    );
    if (response?.httpStatusCode === 200 && response?.success) {
      return response;
    } else {
      return null;
    }
  };

  const fetchDataSerialOnHand = async (serialCode?: string) => {
    const _sourceType =
      dataOutWard?.tblItemTransactionHeaderCommand?.sourceType || sourceType;
    let url = `?SourceCode=${
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode || sourceCode
    }&Type=${dataOutWard.tblItemTransactionHeaderCommand.sourceType}&Serial=${
      serialCode || serial
    }`;

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInventorySerialOrderDetailOnHand>
      >(`api/v1/TblItemTransaction/search-serial-item-transaction${url}`);

      if (response && response.success) {
        const responseData = response.data;

        if (responseData.warning) {
          NotificationExtension.Fails(responseData.warning);
        } else {
          const dataDetail = {
            ...responseData,
            subInvName: responseData.invName,
            note: null,
            primaryQuantity: 1,
            locatorId: responseData.positionId,
          };
          const checkData = [...dataSerialOnHand, { ...dataDetail }];

          const checkQuantityBy =
            _sourceType === "XUAT_LINH_KIEN_SAN_XUAT"
              ? "limitQuantity"
              : "primaryQuantity";
          if (checkExceedQuantityFound(checkData, checkQuantityBy)) {
            NotificationExtension.Warn(
              "Sản phẩm vượt quá số lượng cần xuất, vui lòng kiểm tra lại"
            );
          } else {
            setDataSerialOnHand((prevData) => [...prevData, { ...dataDetail }]);
            setCheckDuplicateCode((prev) => [...prev, serialCode || serial]);
          }
        }
      } else {
        NotificationExtension.Fails(response?.message || "");
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
  };

  const updateItemByKey = <T extends TblInventorySerialOrderDetailOnHandEdit>(
    prev: T[],
    serial: string,
    updateKey: keyof T,
    updateValue: any
  ): T[] => {
    return prev?.map((item) =>
      item.serial === serial && item[updateKey] !== updateValue
        ? { ...item, [updateKey]: updateValue }
        : item
    );
  };

  const getOutwardApiUrl = (
    submitAction: string | null,
    sourceType: string | null
  ): string => {
    const _action = submitAction ?? action.toLowerCase();

    switch (sourceType) {
      case "XUAT_BAN":
        return `/api/v1/Outward/${_action}-sale-order-out-ward`;
      case "XUAT_HUY":
        return `/api/v1/Outward/${_action}-request-cancellation-outward`;
      case "XUAT_DIEU_CHINH":
        return `/api/v1/Outward/${_action}-request-adjustment-outward`;
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return `/api/v1/Outward/${_action}-request-transfer-outward`;
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return `/api/v1/Outward/${_action}-request-transfer-internal-outward`;
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return `/api/v1/Outward/${_action}-request-transfer-serial-outward`;
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return `/api/v1/Outward/${_action}-request-transfer-internal-serial-outward`;
      case "XUAT_LINH_KIEN_SAN_XUAT":
        return `/api/v1/Outward/${_action}-production-order-outward`;
      case "XUAT_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT":
        return `/api/v1/Outward/${_action}-production-order-transfer-outward`;
      case "XUAT_KHO_BAO_HANH_NCC":
        return `/api/v1/Outward/${_action}-warranty-out-ward`;
      case "XUAT_TRA_NCC":
        return `/api/v1/Outward/${_action}-request-vendor-return-outward`;
      case "XUAT_THAO_DO":
        return `/api/v1/Outward/${_action}-common`;
      case "XUAT_KHO_BAO_HANH":
        return `/api/v1/Outward/${_action}-common`;
      case "XUAT_DOI_LINH_KIEN":
        return `/api/v1/Outward/${_action}-exchange-serial-outward`;
      default:
        throw new Error("Unknown source type");
    }
  };

  const getAttributes = (
    sourceType: string | null
  ): { attribute4: string; attribute5: string } => {
    const { fromBranchName, cusCode, cusName, toBranchName } =
      dataOrderDetail?.tblInventoryTransactionModel || {};

    switch (sourceType) {
      case "XUAT_BAN":
        return {
          attribute4: fromBranchName,
          attribute5: `${cusCode} - ${cusName}`,
        };
      case "XUAT_TRA_NCC":
        return {
          attribute4: fromBranchName,
          attribute5: cusName,
        };
      default:
        return {
          attribute4: fromBranchName,
          attribute5: toBranchName,
        };
    }
  };

  const fetchDataPosition = async (fromInvId: number) => {
    try {
      const response = await outward.getSelectItemPosition(fromInvId);

      if (response?.success && Array.isArray(response.data)) {
        const mappedData = response.data.map((p) => ({
          label: p.positionName?.toString() || "",
          value: p.id?.toString() || "",
        }));

        setDataPosition(mappedData);
      } else {
        setDataPosition([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataOutWard = async () => {
    const _sourceCode =
      dataOutWard?.tblItemTransactionHeaderCommand?.sourceCode ||
      searchSourceCode;
    const _sourceType =
      dataOutWard?.tblItemTransactionHeaderCommand?.sourceType || sourceType;

    let url = getOutwardApiUrl(null, _sourceType);
    url += `?prefix=XK&SourceCode=${_sourceCode}`;
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response?.success) {
        setDataOutWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: {
            ...prevData.tblItemTransactionHeaderCommand,
            attribute11:
              response?.data?.tblInventoryTransactionModel?.toBranchId?.toString() ??
              "",
            attribute12:
              dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
                "XUAT_DOI_LINH_KIEN" ||
              dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
                "XUAT_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT"
                ? response?.data?.tblInventoryTransactionModel?.toInvId?.toString() ??
                  ""
                : "",
            fromInvId:
              response?.data?.tblInventoryTransactionModel?.fromInvId ?? 0,
            transactionCode:
              response?.data?.tblInventoryTransactionModel?.transactionCode,
          },
        }));
        const dataDetail = response?.data;
        setDataOrderDetail(dataDetail);
        if (response?.data?.tblInventoryTransactionModel?.fromInvId)
          fetchDataPosition(
            response?.data?.tblInventoryTransactionModel?.fromInvId
          );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useHotkeys([
    [
      "ctrl+S",
      () => {
        handleCreateOutWard("OPEN");
      },
    ],
    [
      "ctrl+Q",
      () => {
        handleCreateOutWard("LOCK", true);
      },
    ],
    ["ctrl+M", () => toggleCollapse()],
  ]);

  //#endregion

  const fetchdataInventoryTransactionType = async () => {
    const responseOutWard = await getTblInventoryTransactionTypeOutWard();

    setDataInventoryTransactionType(
      responseOutWard.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  // Get Data source code
  const fetchDataSourceCode = async () => {
    try {
      const _sourceType =
        dataOutWard.tblItemTransactionHeaderCommand.sourceType;
      let url = `api/v1/RequestInventoryTransaction/get-list-code-transaction-request?Type=${_sourceType}&Page=30`;
      if (searchSourceCode && sourceCode) {
        url += `?Key=${searchSourceCode || sourceCode}`;
      }
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);

      if (response?.success) {
        setDataSearchSourceCodeOption(response.data.map((data) => data) || []);
      }
    } catch (error) {
      setDataSearchSourceCodeOption([]);
    }
  };

  //Chọn kho theo chi nhánh
  const fetchBranches = async () => {
    try {
      let url = `api/v1/TblDmInventory/get-select-by-branch?branchId=${dataOrderDetail?.tblInventoryTransactionModel?.toBranchId}`;
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);
      if (response?.success) {
        setDataInventoryByBranch(response.data);
      }
    } catch (error) {
      setDataSearchSourceCodeOption([]);
    }
  };

  useEffect(() => {
    if (dataOrderDetail?.tblInventoryTransactionModel?.toBranchId)
      fetchBranches();
  }, [dataOrderDetail?.tblInventoryTransactionModel?.toBranchId]);

  // Get data sản phẩm
  const fetchDataItem = async () => {
    const _sourceType =
      dataOutWard.tblItemTransactionHeaderCommand?.sourceType ?? "";
    const _sourceCode =
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode ?? sourceCodeRef;
    const _invId = dataOutWard.tblItemTransactionHeaderCommand?.fromInvId ?? 0;
    if (keySearch && _sourceType && _sourceCode) {
      try {
        let url = `/api/v1/TblItemTransaction/search-list-serial-item-transaction?KeySearch=${keySearch}&SourceCode=${_sourceCode}&Page=50&Type=${_sourceType}`;
        if (_sourceType === "XUAT_LINH_KIEN_SAN_XUAT" && _invId) {
          url += `&InvId=${_invId}`;
        }
        const response = await repositoryPos.get(url);

        setDataItem(response?.data || []);
      } catch (error) {
        setDataItem([]);
      }
    }
  };

  const fetchData = async () => {
    let url = getOutwardApiUrl(null, sourceType);
    try {
      if (sourceCode) {
        url += `?SourceCode=${sourceCode}`;
      } else if (transactionCode) {
        url += `?transactionCode=${transactionCode}`;
      } else {
        url += `?id=${id}`;
      }
      const response = await repositoryPos.get<
        MessageResponse<TblOutwardDetail>
      >(`${url}`);
      if (response?.success && response?.data) {
        setDataOutWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand:
            response?.data?.tblInventoryTransactionModel,
        }));
        setDataOrderDetail((prevData) => ({
          ...prevData,
          inventoryTransactionRequestSourceModels:
            response?.data?.inventoryTransactionRequestSourceModels,
          tblInventoryTransactionModel:
            response?.data?.tblInventoryTransactionModel,
        }));
        setDataSerialOnHand(
          response.data.inventoryTransactionSerialModels.map((item) => ({
            ...item,
            type: "scanned",
          })) || []
        );
        setCheckDuplicateCode((prev) => [
          ...prev,
          ...response.data.inventoryTransactionSerialModels
            .map((item) => item.serial)
            .filter((serial): serial is string => serial !== null),
        ]);
        if (response?.data?.tblInventoryTransactionModel?.fromInvId)
          fetchDataPosition(
            response?.data?.tblInventoryTransactionModel?.fromInvId
          );
      } else {
        setDataOrderDetail(entityOrderOutWard);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderDetail(entityOrderOutWard);
    }
  };

  //#endregion

  //#region useEffects
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([fetchdataInventoryTransactionType()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) setUserInfo(JSON.parse(userLogin));

    const shouldFetchDataOutWard =
      sourceCode &&
      action === "CREATE" &&
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode;

    if (id || transactionCode) {
      fetchData();
    } else if (shouldFetchDataOutWard) {
      fetchDataOutWard();
    }

    fetchAllData();
  }, []);

  useEffect(() => {
    setDataOutWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        departmentId: userInfo?.depId || null,
      },
    }));
  }, [userInfo]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = dataSerialOnHand
            .filter((item) => {
              const checkItem = _sourceType?.includes("SERIAL")
                ? item.serial === order.serial
                : item.itemId === order.itemId ||
                  item.itemCode === order.itemCode;
              return checkItem && !item.type;
            })
            .map((item) => item.primaryQuantity || 0);

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng

            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              action !== "CREATE"
                ? orderQuantity - (orderTransferQuantity + totalQuantity) || 0
                : orderQuantity - totalQuantity || 0;

            return remainQuantity < 0;
          }

          return false;
        }
      );

    if (exceedQuantityFound) {
      setIsExceedQuantity(true);
    } else {
      setIsExceedQuantity(false);
    }

    setDataOrderDetail((prevData) => ({
      ...prevData,
      inventoryTransactionRequestSourceModels:
        prevData.inventoryTransactionRequestSourceModels?.map((order) => {
          const quantities =
            action === "CREATE"
              ? dataSerialOnHand
                  ?.filter((item) => {
                    const checkItem = _sourceType?.includes("SERIAL")
                      ? item.serial === order.serial
                      : item.itemId === order.itemId ||
                        item.itemCode === order.itemCode;
                    return checkItem && !item.type;
                  })
                  ?.map((item) => item.primaryQuantity || 0)
              : dataSerialOnHand
                  .filter((item) => item.itemId === order.itemId)
                  .map((item) => item.primaryQuantity || 0);

          const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const orderTransferQuantity = order.transactionQuantity ?? 0;
          const remainQuantity =
            orderQuantity - (orderTransferQuantity + totalQuantity) || 0;
          const priceItem =
            _sourceType === "XUAT_BAN"
              ? order.soPrice1 ?? 0
              : order.poPrice ?? 0;

          return action === "CREATE"
            ? {
                ...order,
                scannedQuantity: totalQuantity,
                remainQuantity: remainQuantity,
                priceItem: priceItem * totalQuantity,
              }
            : {
                ...order,
                scannedQuantity: totalQuantity,
                remainQuantity: remainQuantity,
                priceItem: priceItem * totalQuantity,
              };
        }),
    }));
  }, [dataSerialOnHand]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const newColumnVisibility = getColumnVisibility();
    if (table && action === "DETAIL") {
      table.setColumnVisibility({ action: false });
    }
    if (tableDetail) {
      tableDetail.setColumnVisibility(newColumnVisibility);
    }
    // Clear input field
    if (action === "CREATE") {
      setDataSearchSourceCodeOption([]);
      setDataOutWard({
        ...outWardEntity,
        tblItemTransactionHeaderCommand: {
          ...outWardEntity.tblItemTransactionHeaderCommand,
          sourceType: _sourceType,
          sourceCode: null,
        },
      });
      setDataOrderDetail(entityOrderOutWard);
      setDataSerialOnHand([]);
      setCheckDuplicateCode([]);
    }

    if (_sourceType) {
      if (action === "CREATE") fetchDataSourceCode();
      setIsLockForOrder(_sourceType === "XUAT_BAN");
    } else {
      setIsLockForOrder(false);
    }
  }, [dataOutWard.tblItemTransactionHeaderCommand.sourceType]);

  useEffect(() => {
    if (action !== "CREATE") {
      return;
    }
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const _sourceCode = dataOutWard.tblItemTransactionHeaderCommand.sourceCode;
    // Clear input field
    setDataOutWard({
      ...outWardEntity,
      tblItemTransactionHeaderCommand: {
        ...outWardEntity.tblItemTransactionHeaderCommand,
        sourceType: _sourceType,
        sourceCode: _sourceCode,
      },
    });
    setDataOrderDetail(entityOrderOutWard);
    setDataSerialOnHand([]);
    setCheckDuplicateCode([]);
  }, [dataOutWard.tblItemTransactionHeaderCommand.sourceCode]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    if (
      _sourceType === "XUAT_BAN" &&
      dataOrderDetail?.inventoryTransactionRequestSourceModels.length > 0
    ) {
      const variance = checkVarianceItem(
        dataOrderDetail?.inventoryTransactionRequestSourceModels,
        "primaryQuantity"
      );
      if (!variance.isVariance) {
        setIsLockForOrder(false);
      } else {
        setIsLockForOrder(true);
      }
    }
  }, [dataOrderDetail?.inventoryTransactionRequestSourceModels]);

  useEffect(() => {
    if (
      dataOutWard.tblItemTransactionHeaderCommand?.sourceType &&
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode &&
      action === "CREATE"
    ) {
      fetchDataOutWard();
    }
  }, [dataOutWard.tblItemTransactionHeaderCommand?.sourceCode]);

  useEffect(() => {
    if (!searchSourceCode && searchSourceCode.length > 3) {
      fetchDataSourceCode();
    }
  }, [searchSourceCode]);

  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItem();
    } else {
      setDataItem([]);
    }
  }, [keySearch]);

  useEffect(() => {
    const updateMainContentWidth = () => {
      const containerWidth = containerRef.current?.offsetWidth || 0;
      const sidePanelWidth = sidePanelRef.current?.offsetWidth || 0;
      const calculatedMainWidth = isCollapse
        ? containerWidth
        : containerWidth - sidePanelWidth;
      setMainContentWidth(calculatedMainWidth);
    };

    const containerObserver = new ResizeObserver(updateMainContentWidth);
    const sidePanelObserver = new ResizeObserver(updateMainContentWidth);

    if (containerRef.current) containerObserver.observe(containerRef.current);
    if (sidePanelRef.current) sidePanelObserver.observe(sidePanelRef.current);

    updateMainContentWidth();

    return () => {
      if (containerRef.current)
        containerObserver.unobserve(containerRef.current);
      if (sidePanelRef.current)
        sidePanelObserver.unobserve(sidePanelRef.current);
    };
  }, [isCollapse]);

  //#endregion

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },
      {
        accessorKey: "positionCode",
        header: "Mã vị trí",
        size: 30,
        Cell: ({ row }) => (
          <Tooltip label={row.original.positionCode}>
            <Text size="sm">{row.original.positionCode}</Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "positionName",
        header: "Tên vị trí",
        size: 100,
        Cell: ({ row }) => (
          <Tooltip label={row.original.positionName}>
            <Text size="sm" w={150}>
              {row.original.positionName}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "primaryUomCode",
        header: "ĐVT1",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
        Cell: () => <Text size="sm">1</Text>,
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard?.tblItemTransactionHeaderCommand?.sourceType ===
          "XUAT_BAN"
            ? "Giá bán"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard?.tblItemTransactionHeaderCommand?.sourceType ===
            "XUAT_BAN"
              ? row.original?.soPrice1 &&
                row.original.soPrice1?.toLocaleString()
              : row.original?.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },
      ...(changeSourceType === "XUAT_LINH_KIEN_SAN_XUAT"
        ? []
        : [
            {
              accessorKey: "subQuantity",
              header: "SL2",
              size: 30,
            },
            {
              accessorKey: "subUomName",
              header: "ĐVT2",
              size: 30,
            },
            {
              header: "Giá theo ĐVT2",
              size: 30,
            },
          ]),
      {
        header: "Thành tiền",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice &&
                (
                  Number(row.original.soPrice) *
                  (row.original.primaryQuantity || 0)
                ).toLocaleString()
              : row.original.poPrice &&
                (
                  Number(row.original.poPrice) *
                  Number(row.original.primaryQuantity || 0)
                ).toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 200,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 300,
        Cell: ({ row, renderedCellValue }) =>
          !locker ? (
            <TextInput
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                locker
              }
              placeholder={"Nhập ghi chú"}
              onChange={(e) => {
                setDataSerialOnHand((prev: any) => {
                  return updateItemByKey(
                    prev,
                    row?.original?.serial,
                    "locatorId",
                    e?.target?.value
                  );
                });
              }}
            />
          ) : (
            <>{renderedCellValue}</>
          ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xóa">
              <ActionIcon
                disabled={
                  dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
                }
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  deleteItemSerial(row.original.serial);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [
      dataOutWard,
      locker,
      action,
      changeSourceType,
      dataPosition,
      dataSerialOnHand,
    ]
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 30,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "primaryUom",
        header: "ĐVT1",
        size: 30,
      },

      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán"
            : "Giá nhập",
        size: 30,
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUom",
        header: "ĐVT2",
        size: 30,
      },
      {
        accessorKey: "limitQuantity",
        header: "SL định mức ",
        size: 30,
      },
      {
        accessorKey: "subPrice",
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        accessorKey: "totalAmount",
        header: "Thành tiền",
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 &&
                (
                  Number(row.original.soPrice1) *
                  (row.original.primaryQuantity || 0)
                ).toLocaleString()
              : row.original.poPrice &&
                (
                  Number(row.original.poPrice) *
                  Number(row.original.primaryQuantity || 0)
                ).toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "itemStatus",
        header: "Tình trạng",
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
      },
      {
        accessorKey: "deviated",
        header: "Lệch",
        size: 50,
        Cell: ({ row }) => {
          const primaryQuantity =
            changeSourceType === "XUAT_LINH_KIEN_SAN_XUAT"
              ? row.original.limitQuantity
              : row.original.primaryQuantity || 0;
          return (
            <Text fw={"bold"} c={"red"} size="sm">
              {(row.original.scannedQuantity || 0) - primaryQuantity}
            </Text>
          );
        },
      },
    ],
    [
      dataSerialOnHand,
      dataOrderDetail.inventoryTransactionRequestSourceModels,
      dataOutWard.tblItemTransactionHeaderCommand.sourceType,
      changeSourceType,
    ]
  );

  const table = useMantineReactTable({
    columns,
    data: dataSerialOnHand,
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: action !== "DETAIL",
    renderTopToolbarCustomActions: () => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Grid>
          <Grid.Col span={{ base: 6, md: 2.5, lg: 2.5 }}>
            {TextTransactionHeader()}
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 2, lg: 2 }}>
            <Button
              leftSection={<IconPlus size={14} />}
              size="xs"
              w={"100%"}
              onClick={handleAddProduct}
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
              }
            >
              Thêm barcode
            </Button>
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 2.5, lg: 2.5 }}>
            {MenuDataOutWart()}
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 5, lg: 5 }}>
            <Flex
              direction={"row"}
              align="center"
              justify={"flex-end"}
              w={"100%"}
              pr={20}
              gap={15}
            >
              <Text fw={700}>Số lượng:</Text>
              <Text c="red" fw={700}>
                <NumberFormatter
                  value={dataSerialOnHand?.length || 0}
                  thousandSeparator
                />
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
      </div>
    ),
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index", "positionCode", "positionName"],
        right: ["action"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.55 - 20,
        minHeight: height * 0.55 - 20,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: () => ({
      style: {
        fontWeight: "normal",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      showAlertBanner: isError,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data: dataOrderDetail?.inventoryTransactionRequestSourceModels ?? [],
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["index"],
        right: ["deviated"],
      },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.45,
        minHeight: height * 0.45,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "normal",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      showAlertBanner: isError,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box my={"10px"}>{titleSell()}</Box>
      </div>
      <Box
        style={{
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            width: "max-width",
          }}
          ref={containerRef}
        >
          {!isCollapse && (
            <Box
              style={{
                flexShrink: 0,
                transition: "width 0.3s ease, max-width 0.3s ease",
                display: "flex",
                alignItems: "stretch",
                overflow: "hidden",
                height: "100%",
                width: "fit-content",
              }}
              ref={sidePanelRef}
            >
              <Collapse
                in={!isCollapse}
                transitionDuration={100}
                transitionTimingFunction="ease-in-out"
              >
                <Fieldset
                  w="fit-content"
                  h="100%"
                  legend="Thông tin chung"
                  p={5}
                >
                  {renderMainOutWard()}
                </Fieldset>
              </Collapse>
            </Box>
          )}

          <Box
            style={{
              flexGrow: 1,
              flexShrink: 0,
              width: `${mainContentWidth}px`,
              transition: "width 0.3s ease",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Fieldset
              w={"max-width"}
              p={10}
              pb={0}
              legend={
                changeSourceType === "XUAT_LINH_KIEN_SAN_XUAT"
                  ? "Linh kiện"
                  : "Bên xuất"
              }
            >
              <MantineReactTable table={table} />
              <Group my="10px" justify="end" />
            </Fieldset>
            <Fieldset
              p={10}
              pb={0}
              w={"max-width"}
              legend={
                changeSourceType === "XUAT_LINH_KIEN_SAN_XUAT"
                  ? "Bộ cần lắp"
                  : dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
                    "XUAT_BAN"
                  ? "Thông tin đơn hàng"
                  : "Yêu cầu xuất kho"
              }
            >
              <MantineReactTable table={tableDetail} />
            </Fieldset>
          </Box>
        </Box>
      </Box>
      {/* {dataPrint && typePrint && ( */}
      <Box style={{ display: "none" }}>
        <PrintWarehouseOutWard
          dataPrint={dataPrint}
          typePrint={location?.state?.type}
          userInfo={userInfo}
          currentDate={currentDate}
          componentRef={componentRef}
        />
      </Box>
      {/* )} */}
    </Box>
  );
};
export default WarehouseOutWardAction;
