import { Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { deletePriceList } from "../../../api/apiPriceList";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

const DeleteView = ({ idItems, onSearch }: DeleteProduct) => {
  const handleDeletePriceList = async () => {
    const res = await repositoryPos.post(
      "/api/v1/TblPriceList/delete",
      idItems
    );
    if (res && res.success) {
      NotificationExtension.Success("Xóa thành công!");
      setTimeout(() => {
        modals.closeAll();
        onSearch();
      }, 1000);
    }
  };

  return (
    <div>
      <Text size="24px">Bạn có chắc chắn muốn xóa các bảng giá này ?</Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color="red"
          onClick={handleDeletePriceList}
          leftSection={<IconCheck size={18} />}
        >
          Xóa
        </Button>
      </Group>
    </div>
  );
};

type DeleteProduct = {
  idItems: (number | string)[];
  onSearch: () => void;
};

export default DeleteView;
