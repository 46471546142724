import { useLocation, useNavigate } from "react-router-dom";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { PaymentReceiptAttributes, RootObject } from "./model/model";
import React, { useEffect, useState } from "react";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import {
  DepartmentUser,
  FundDetails,
  UserData,
} from "../PaymentReceipt/model/modelPaymentReceipt";
import {
  AccountantAccount,
  DepartmentCustomerOrder,
  Permission,
  Program,
  RowErrors,
  User,
} from "../PaymentReceipt/InternalReceipt/InternalReceiptModel";
import { SelectListItemBase } from "../../../_base/model/_base/SelectListItemBase";
import { EmployeeModel } from "../../../model/Employee";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";

const AddAdjustmentSlip = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const idReceiptNumber = location.state && location.state?.id;
  const typeReceiptBill = location.state && location.state?.typeReceipt;
  const typeBill = location.state && location.state?.type;
  //formdata
  const [formData, setFormData] = useState<PaymentReceiptAttributes | null>(
    null
  );

  const [userData, setUserData] = useState<UserData | null>(null);
  const [listdata, setListData] = useState<FundDetails>();
  const [height, setHeight] = useState(0);
  const [branchDeparment, setBranchDeparment] = useState<DepartmentUser>();
  const [statusReponse, setStatusReponse] = useState<any>();
  const [changeFielselect, setChangeFielselect] = useState<any>();
  const [customerOrders, setCustomerOrders] = useState<
    DepartmentCustomerOrder[]
  >([]);
  const [selectedCalculation, setSelectedCalculation] = useState<string>("VND");
  const [dataGetDepartments, setDataGetDepartments] = useState<
    SelectListItemBase[]
  >([]);
  const [handleFullName, setHandleFullName] = useState<String | null>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [stoppedTime, setStoppedTime] = useState<Date | null>();
  const [valueDataLocation, setValueDataLocation] = useState<string>("");
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [listEmployee, setListEmployee] = useState<EmployeeModel[]>([]);
  const [listTblDmEmployee, setListTblDmEmployee] = useState<User>();
  const [listTblBranch, setListTblBranch] = useState<User>();
  const [handelAttrible, setHandelAttrible] = useState<boolean>(false);
  const [handlePayertype, setHandlePayertype] = useState<boolean>(false);
  const [handleOther, setHandleOther] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<Date | null>();
  const [completeTime, setCompleteTime] = useState<Date | null>(null);
  const [inputValues, setInputValues] = useState<{ [key: number]: any }>({});
  const [handelDateCurrent, setHandelDateCurrent] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>(1);
  const [branchAll, setBranchAll] = useState<DepartmentUser[]>([]);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);
  const [dataDepartments, setDataDepartments] = useState<SelectListItemBase[]>(
    []
  );
  const [isCollapse, { toggle: toggleCollapse }] = useDisclosure(false);
  const [dataAcount, setDataAcount] = useState<AccountantAccount[]>([]);

  const [dataGetReceiptContract, setDataGetReceiptContract] = useState<
    Program[]
  >([]);

  const [departmentsByBranch, setDepartmentsByBranch] = useState<
    Record<number, EmployeeModel>
  >({});

  const [selectedBranches, setSelectedBranches] = useState<
    Record<number, string>
  >({});
  const [dataGetReceiptFee, setDataReceiptFee] = useState<Permission[]>([]);

  const [occurringCredits, setOccurringCredits] = useState<number>(0);
  const [dataBranch, setDataBranch] = useState<SelectListItemBase[]>([]);
  const [filteredData, setFilteredData] = useState(dataCodeFund);
  const [fundepartmentId, setFundepartmentId] = useState<number | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState<
    Record<number, string>
  >({});
  const [inputValueDept, setInputValueDept] = useState("");

  const [errors, setErrors] = useState<{ [index: number]: RowErrors }>({});
  const isSmallScreen = useMediaQuery("(max-width: 900px)", true, {
    getInitialValueInEffect: false,
  });

  //#region tạo switch case để chia các điều kiện tạo phiếu
  const handleSwitchCase = () => {
    switch (typeReceiptBill) {
      case 1:
        return `Receipt ID: ${idReceiptNumber}, Type: ${typeReceiptBill}`;
      case 2:
        return `Invoice ID: ${idReceiptNumber}, Type: ${typeReceiptBill}`;
      case 3:
        return `Credit Note ID: ${idReceiptNumber}, Type: ${typeReceiptBill}`;
      default:
        return "Invalid type of bill!";
    }
  };

  const fetchDataReceiptParent = async () => {
    try {
      const response = await repositoryPos.get<
        MessageResponse<PaymentReceiptAttributes>
      >(
        `/api/v1/CreateSellItem/create-receipt-parent-adjust?receiptNumber=${idReceiptNumber}`
      );

      if (response && response.success && response.data) {
        let result = response.data;
        if (result) {
          setFormData(result);
        }
      } else {
        NotificationExtension.Fails("Không lấy được dữ liệu");
      }
    } catch (error) {
      console.error("Lỗi", error);
    }
  };

  console.log(formData, "sfdfsdfsdf");

  useEffect(() => {
    fetchDataReceiptParent();
  }, [idReceiptNumber]);

  return <div>{idReceiptNumber}</div>;
};

export default AddAdjustmentSlip;
