import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Input,
  Menu,
  rem,
  Select,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconBrandBootstrap,
  IconCaretDown,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileTypography,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconTrash,
  IconUserCode,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { cobalt_blue, green, teal } from "../../../const/variables";
import {
  getInventorySelectPos,
  getTblDmEmployeeSelect,
  getTblInventoryTransactionTypeOutWard,
} from "../../../service/getSelectApi";
import DeleteView from "./components/DeleteModal";
import { dataStatusOption, search } from "./constants";
import useDynamicHeight from "./hooks/useDynamicHeight";
import { outward } from "./services";
import { GetListOutwardParams } from "./types/GetListOutwardParams";
import { TblWarehouseOutwardGetList } from "./types/TblWarehouseOutward";
function getColorStatus(text: any) {
  switch (text) {
    case "OPEN":
      return cobalt_blue.base;
    case "LOCK":
      return green.light;
    default:
      return cobalt_blue.base;
  }
}

function getStatus(text: any) {
  switch (text) {
    case "OPEN":
      return "Lưu tạm";
    case "LOCK":
      return "Đã xuất";
    default:
      return "Tạo mới";
  }
}

const WarehouseOutWard = () => {
  const navigate = useNavigate();
  // Reference data from another table

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblWarehouseOutwardGetList[]>([]);
  const [dataInv, setDataInv] = useState<ComboboxItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataEmployee, setDataEmployee] = useState<ComboboxItem[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const height = useDynamicHeight(headerRef, 150);

  //count
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);

  const columns = React.useMemo<MRT_ColumnDef<TblWarehouseOutwardGetList>[]>(
    () => [
      {
        accessorKey: "transactionCode",
        header: "Mã phiếu",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() =>
                detailData(
                  row.original.transactionId,
                  row.original.sourceType,
                  true
                )
              }
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "status",
        header: "Trạng thái",
        size: 20,
        Cell: ({ row }) => (
          <>
            <Badge color={getColorStatus(row.original.status)}>
              {getStatus(row.original.status)}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "transactionDate",
        header: "Ngày giao dịch",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "createName",
        header: "Người lập",
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
        size: 30,
      },

      {
        accessorKey: "sourceCode",
        header: "Mã chứng từ gốc",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },

      {
        accessorKey: "sourceTypeName",
        header: "Loại chứng từ gốc",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorType(row.original.sourceType)}>
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "branchName",
        header: "Chi nhánh",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "invName",
        header: "Kho",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="11.5px">{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "description",
        header: "Ghi chú",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label={renderedCellValue}>
            <Text size="sm" maw={300}>
              {renderedCellValue}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                disabled={row.original.status === "LOCK"}
                onClick={async () => {
                  editData(
                    row.original.transactionId,
                    row.original.sourceType,
                    false // status !== "LOCK"
                  );
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xem chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="blue"
                onClick={async () => {
                  detailData(
                    row.original.transactionId,
                    row.original.sourceType,
                    true // status === "LOCK"
                  );
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                disabled={row.original.status === "LOCK"}
                onClick={async () => {
                  deleteData(
                    row.original.transactionId,
                    row.original.sourceType ?? ""
                  );
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataInventoryTransactionType, dataInv]
  );

  const form = useForm({
    mode: "uncontrolled",
    initialValues: search,
  });

  const getColorType = (type: string | null) => {
    switch (type) {
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return "#f06418";
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return "#ffab09";
      case "XUAT_HUY":
        return "#d9d02f";
      case "XUAT_DIEU_CHINH":
        return "#6bd731";
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return "#09b8ff";
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return "#1f32c4";
      case "XUAT_TRA_NCC":
        return "#2bdd66";
      case "XUAT_BAN":
        return teal.light;
      case null:
        return "rgba(0, 0, 0, 0)";
      case "NOI_BO":
        return "#656a7e";
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phieu-xuat-kho.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const createData = () => {
    navigate("/warehouse-config/create-warehouse-out-ward");
  };

  const editData = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean
  ) => {
    navigate("/warehouse-config/edit-warehouse-out-ward", {
      state: { id: id, type: type, isLocked: isLocked },
    });
  };

  const detailData = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean
  ) => {
    navigate("/warehouse-config/detail-warehouse-out-ward", {
      state: { id: id, type: type, isLocked: isLocked },
    });
  };

  const deleteData = (id: string | number, sourceType: string) => {
    try {
      modals.openConfirmModal({
        title: (
          <>
            <Title order={5}>Xóa phiếu xuất kho!</Title>
          </>
        ),

        size: "550px",
        children: (
          <DeleteView
            idItem={id}
            onClose={setDeleteViewStatus}
            sourceType={sourceType}
          />
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useHotkeys([
    [
      "F11",
      () => {
        createData();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
    }

    try {
      const queryParams: GetListOutwardParams = {
        Skip: pagination?.pageIndex * pagination?.pageSize,
        Take: pagination?.pageSize,
        KeySearch: form.getValues()?.keySearch,
        InvId: form.getValues()?.invId,
        FromTransationDate: form.getValues()?.fromTransationDate,
        ToTransationDate: form.getValues()?.toTransationDate,
        SourceTypeCode: form.getValues()?.sourceType,
        Status: form.getValues()?.status,
        EmpId: form.getValues()?.empId,
        sort: _softing,
        isOrder: isOrder,
        Type: 2,
      };
      const response = await outward.getListOutward(queryParams);
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      } else setData([]);
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const fetchdataInventoryTransactionType = async () => {
    const response = await getTblInventoryTransactionTypeOutWard();

    setDataInventoryTransactionType(
      response.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  const fetchDataEmployee = async () => {
    const response = await getTblDmEmployeeSelect();
    setDataEmployee(
      response.map((employee) => ({
        value: employee.value,
        label: employee.text,
      }))
    );
  };

  const fetchDataInv = async () => {
    const getDataInv = await getInventorySelectPos();

    const mappedData = getDataInv
      .filter(
        (item) => item.code != null && item.name != null && item.id !== null
      )
      .map((item) => ({
        value: item.id.toString(),
        label: `(${item.code}) ${item.name}`,
      }));
    setDataInv(mappedData);
  };

  useEffect(() => {
    fetchData();
  }, [sorting, deleteViewStatus, pagination.pageIndex, pagination.pageSize]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.transactionId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionCode", "status"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                createData();
              }}
            >
              Tạo mới (F11)
            </Button>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <form>
          <Grid mt={"10px"}>
          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.8 }}>
              <Input
                w={"100%"}
                placeholder="Từ khoá"
                type="text"
                leftSection={<IconSearch color="#15aabf" />}
                key={form.key("keySearch")}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    await fetchData();
                  }
                }}
                {...form.getInputProps("keySearch")}
              />
            </GridCol>

            <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.8 }}>
              <Select
                placeholder={"Trạng thái"}
                searchable
                clearable
                data={dataStatusOption}
                leftSection={<IconStatusChange color="#15aabf" />}
                comboboxProps={{
                  transitionProps: { transition: "pop", duration: 200 },
                }}
                key={form.key("status")}
                nothingFoundMessage={"Loading..."}
                {...form.getInputProps("status")}
              />
            </GridCol>

            <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.8 }}>
              <Select
                placeholder={"Loại chứng từ gốc"}
                searchable
                clearable
                leftSection={<IconFileTypography color="#15aabf" />}
                comboboxProps={{
                  transitionProps: { transition: "pop", duration: 200 },
                }}
                data={dataInventoryTransactionType}
                nothingFoundMessage={"Loading..."}
                key={form.key("sourceType")}
                onClick={() => {
                  if (dataInventoryTransactionType.length === 0) {
                    fetchdataInventoryTransactionType();
                  }
                }}
                {...form.getInputProps("sourceType")}
              />
            </GridCol>

            <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.8 }}>
              <Select
                searchable
                clearable
                placeholder="Chọn kho xuất"
                comboboxProps={{
                  transitionProps: { transition: "pop", duration: 200 },
                }}
                leftSection={<IconBrandBootstrap color="#15aabf" />}
                nothingFoundMessage={"Loading..."}
                data={dataInv}
                key={form.key("invId")}
                onClick={() => {
                  if (dataInv.length === 0) {
                    fetchDataInv();
                  }
                }}
                {...form.getInputProps("invId")}
              />
            </GridCol>

            <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.8 }}>
              <Select
                placeholder={"Chọn nhân viên"}
                searchable
                clearable
                data={dataEmployee}
                leftSection={<IconUserCode color="#15aabf" />}
                comboboxProps={{
                  transitionProps: { transition: "pop", duration: 200 },
                }}
                nothingFoundMessage={"Loading..."}
                key={form.key("empId")}
                onClick={() => {
                  if (dataEmployee.length === 0) {
                    fetchDataEmployee();
                  }
                }}
                {...form.getInputProps("empId")}
              />
            </GridCol>

            <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.8 }}>
              <DatePickerInput
                // type="multiple"
                type="range"
                size="sm"
                allowSingleDateInRange
                placeholder="Chọn khoảng ngày"
                leftSection={<IconTimeDuration15 color="#15aabf" />}
                locale="vi"
                // value={value}
                valueFormat="DD/MM/YYYY"
                onChange={(e) => {
                  form.setValues((prev) => ({
                    ...prev,
                    fromTransationDate: formatDateNotTimeZone(e[0]) ?? "",
                    toTransationDate: formatDateNotTimeZone(e[1]) ?? "",
                  }));
                }}
                clearable
              />
            </GridCol>
            <GridCol span={{ base: 4, sm: 4, md: 1.5, lg: 1 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={async () => {
                  await fetchData();
                }}
              >
                Tìm kiếm
              </Button>
            </GridCol>
          </Grid>
        </form>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default WarehouseOutWard;
