import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  Radio,
  Select,
  TagsInput,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";

import { DateInput } from "@mantine/dates";
import { repositoryPos } from "../../_base/_const/_constVar";
import { EmployeeModel } from "../../model/Employee";
import { MessageResponse } from "../../model/MessageResponse";
import {
  getBranchSelect,
  getTblDepartmentSelect,
  getTblDmEmployeeSelect,
  getTblRoleSelect,
} from "../../service/getSelectApi";

const EmployeeDetail = function ({ id }: { id: number }) {
  const entity: EmployeeModel = {
    id: 0,
    fullname: "",
    code: "",
    dateOfBirth: "",
    gender: 0,
    departmentId: 0,
    roleId: 0,
    departmentName: "",
    roleName: "",
    address: "",
    phoneNumber: "",
    active: false,
    email: "",
    description: null,
    accountingCenterId: 0,
    managerId: 0,
    isCrm: false,
    currentCardId: null,
    partCode: null,
    createDate: new Date(),
    createBy: 0,
    lastUpdateDate: new Date(),
    lastUpdateBy: "",
    listBranch: null,
    isAccountant: null,
    isInventory: null,
  };
  const form = useForm<EmployeeModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    //   validate: _validateUserMasterModelValidator,
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [value, setValue] = useState<Date | null>(null);

  useEffect(() => {
    if (id) callApiGetData();
  }, [id]);

  const [dataBranchdSelect, setDataBranchdSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataTblDepartmentSelect, setDataTblDepartmentSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblRoleSelect, setDataTblRoleSelect] = useState<ComboboxItem[]>(
    []
  );

  const [dataEmployeeSelect, setDataDmEmployeeSelect] = useState<
    ComboboxItem[]
  >([]);

  const callApiGetData = async () => {
    open();

    let urlCreate = `/api/v1/TblDmEmployee/edit?id=` + id;
    const callapi = await repositoryPos.get<MessageResponse<EmployeeModel>>(
      urlCreate
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        await Promise.all([
          dataDepartmentSelect(), // Hàm trả về một promise cho dữ liệu department
          dataRoleSelect(), // Hàm trả về một promise cho dữ liệu role
          dataDmEmployeeSelect(),
          dataBranchSelect(),
        ]);
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        setValue(new Date(dataApi.dateOfBirth ?? ""));
        //  await dataInventorySelect();
        // if (dataApi.departmentId > 0) await dataDepartmentSelect();
        // if (dataApi.roleId > 0) await dataRoleSelect();
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  const dataDepartmentSelect = async () => {
    const get = await getTblDepartmentSelect();
    setDataTblDepartmentSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataRoleSelect = async () => {
    const get = await getTblRoleSelect();
    setDataTblRoleSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataDmEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataDmEmployeeSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value?.toString(),
          label: item.text?.toString(),
        }))
    );
  };
  useEffect(() => {
    var br = form.getValues().listBranch;
    if (br) {
      const valuesArray = br.split(",").length > 0 ? br.split(",") : [br];

      const labels = valuesArray
        .map(
          (value) =>
            dataBranchdSelect.find((item) => item.value === value)?.label
        ) // Tìm `label` dựa trên `value`
        .filter((label): label is string => label !== undefined); // Loại bỏ `undefined`
      setValueInventory(labels);
      // if (dataApi.listBranch.split(",").length > 0) {
      //   dataApi.listBranch.split(",");
      // } else setValueInventory([dataApi.listBranch]);
    }
  }, [dataBranchdSelect, form.getValues().listBranch]);

  const dataBranchSelect = async () => {
    const get = await getBranchSelect();
    setDataBranchdSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const [valueInventory, setValueInventory] = useState<string[]>([]);
  return (
    <>
      <Box component="form" style={{ position: "relative" }}>
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Box className="flex-none" component="form" mx="auto">
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <TextInput
                key={form.key("code")}
                label="Mã nhân viên: "
                readOnly
                variant="filled"
                {...form.getInputProps("code")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <TextInput
                key={form.key("fullname")}
                label="Tên đầy đủ: "
                readOnly
                variant="filled"
                {...form.getInputProps("fullname")}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <DateInput
                key={form.key("dateOfBirth")}
                label="Ngày sinh: "
                valueFormat="DD-MM-YYYY"
                value={value}
                readOnly
                variant="filled"
                onChange={setValue}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <Radio.Group
                key={form.key("gender")}
                name="favoriteFramework"
                label="Giới tính: "
                value={form.getValues().gender.toString()}
                {...form.getInputProps("gender")}
              >
                <Group mt="xs">
                  <Radio value="0" label="Nam" />
                  <Radio value="1" label="Nữ" />
                  <Radio value="2" label="Không xác định" />
                </Group>
              </Radio.Group>
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <Select
                rightSection={<></>}
                id="departmentId"
                key={form.key("departmentId")}
                label="Phòng ban: "
                data={dataTblDepartmentSelect}
                value={form.getValues().departmentId?.toString()}
                readOnly
                variant="filled"
                {...form.getInputProps("departmentId")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <Select
                rightSection={<></>}
                id="roleId"
                key={form.key("roleId")}
                label="Chức danh: "
                nothingFoundMessage="Không có dữ liệu"
                data={dataTblRoleSelect}
                value={form.getValues().roleId?.toString()}
                readOnly
                variant="filled"
                {...form.getInputProps("roleId")}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, xs: 4 }}>
              {" "}
              <TextInput
                readOnly
                variant="filled"
                key={form.key("phoneNumber")}
                label="Điện thoại: "
                {...form.getInputProps("phoneNumber")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 4 }}>
              <TextInput
                readOnly
                variant="filled"
                key={form.key("email")}
                label="Email:  "
                {...form.getInputProps("email")}
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, xs: 4 }}>
              <Select
                rightSection={<></>}
                readOnly
                variant="filled"
                id="managerId"
                key={form.key("managerId")}
                label="QL trực tiếp: "
                nothingFoundMessage="Không có dữ liệu"
                data={dataEmployeeSelect}
                value={form.getValues().managerId?.toString()}
                {...form.getInputProps("managerId")}
              />
            </Grid.Col>
          </Grid>
          <TagsInput
            readOnly
            variant="filled"
            label="Chọn chi nhánh làm việc: "
            data={dataBranchdSelect}
            style={{ maxHeight: "250px", overflow: "auto" }}
            value={valueInventory}
          />

          <Grid>
            <Grid.Col span={{ base: 12, xs: 12 }}>
              {" "}
              <Textarea
                readOnly
                variant="filled"
                key={form.key("address")}
                label="Địa chỉ: "
                {...form.getInputProps("address")}
              />
            </Grid.Col>
          </Grid>
          <Checkbox
            mt={"xs"}
            key={form.key("isAccountant")}
            label="Kế toán"
            defaultValue={form.getValues().isAccountant ?? false}
            defaultChecked={form.getValues().isAccountant ?? false}
            {...form.getInputProps("isAccountant")}
          />
          <Checkbox
            mt={"xs"}
            key={form.key("isInventory")}
            label="Thủ kho"
            defaultValue={form.getValues().isInventory ?? false}
            defaultChecked={form.getValues().isInventory ?? false}
            {...form.getInputProps("isInventory")}
          />
          <Checkbox
            readOnly
            variant="filled"
            mt={"xs"}
            key={form.key("active")}
            label="Trạng thái"
            defaultValue={form.getValues().active ?? false}
            defaultChecked={form.getValues().active ?? false}
            {...form.getInputProps("active")}
          />
        </Box>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
        </Group>
      </Box>
    </>
  );
};

export default EmployeeDetail;
