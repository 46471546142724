import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  CheckIcon,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Menu,
  Modal,
  Notification,
  NumberFormatter,
  NumberInput,
  ScrollArea,
  Select,
  SelectProps,
  Table,
  Tabs,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { DateInput, DatePickerInput } from "@mantine/dates";
import {
  IconAlertCircle,
  IconBrandBootstrap,
  IconBuildingFactory2,
  IconCheck,
  IconDiscount,
  IconInfoCircle,
  IconMessageCircle,
  IconMinus,
  IconPhoto,
  IconPlus,
  IconSearch,
  IconSettings,
  IconTicket,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { _localization } from "../../../config/location";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import EditableTextWithOptions from "../../../common/selectValue";
import { MessageResponse } from "../../../model/MessageResponse";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { EmployeeModel } from "../../../model/Employee";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  useClickOutside,
  useDebouncedState,
  useDisclosure,
  useMediaQuery,
} from "@mantine/hooks";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { notifications } from "@mantine/notifications";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { getListItem } from "../../../api/apiItem";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import { isNullOrUndefinedArry } from "../../../_base/extension/StringExtension";
import { ProductModel } from "../../../model/TblDMItem";
import { orange } from "../../../const/variables";

interface SearchParams {
  searchKey: string;
  searchFrom: string;
  searchTo: string;
  handeDateStart: Date | null;
  handeDateEnd: Date | null;
}

interface PoLineModel {
  poLineId: number | null;
  poHeaderId: number | null;
  lineTypeId: number | null;
  lineNum: number | null;
  itemId: number | null;
  itemDescription: string | null;
  uom: string | null;
  unitPrice: number | null;
  quantity: number | null;
  noteToVendor: string | null;
  fromHeaderQuotationId: number | null;
  fromLineQuotationId: number | null;
  minOrderQuantity: number | null;
  maxOrderQuantity: number | null;
  qtyRcvTolerance: number | null;
  closedFlag: string | null;
  userHoldFlag: string | null;
  cancelFlag: string | null;
  cancelledBy: number | null;
  cancelDate: string | null;
  cancelReason: string | null;
  taxCodeId: number | null;
  taxableFlag: string | null;
  taxName: string | null;
  attribute1: string | null;
  attribute2: string | null;
  attribute3: string | null;
  attribute4: string | null;
  minReleaseAmount: number | null;
  closedDate: string | null;
  closedReason: string | null;
  closedBy: number | null;
  orgId: number | null;
  primaryUom: string | null;
  primaryQty: number | null;
  createdDate?: string; // Thêm dấu "?" để cho phép giá trị null
  createdBy: number | null;
  lastUpdateDate: string | null;
  lastUpdatedBy: number | null;
  totalAmout: number | null;
}

interface Item {
  itemId: number;
  categoryId: number;
  barcode: string | null;
  itemCode: string;
  oldItemCode: string;
  itemName: string;
  uomCodeId: number;
  primaryUomCodeId: number;
  poPrice: number | null;
  description: string;
  active: number;
  checkDuobleBarcode: number;
  itemShortName: string | null;
  discount: number;
  warrantyVendor: number;
  warranty: number;
  vatRate: number;
  isPromoteOnly: number;
  isSparePart: number;
  isOnhand: number;
  isShowWeb: boolean | null;
  attribute1: string;
  attribute2: string | null;
  attribute3: string | null;
  attribute4: string | null;
  attribute5: string | null;
  attribute6: string | null;
  attribute7: string | null;
  attribute8: string | null;
  attribute9: string | null;
  attribute10: string | null;
  attribute11: string | null;
  attribute12: string | null;
  attribute13: string | null;
  attribute14: string | null;
  attribute15: string | null;
  createBy: string | null;
  createDate: Date | null;
  lastUpdateBy: string | null;
  lastUpdateDate: Date | null;
  segment1: string;
  segment2: string;
  segment3: string;
  segment4: string;
  segment5: string;
  segment6: string;
}

interface PurchaseOrder {
  poHeaderId: number | null;
  buyerId: number | null;
  type: string | null;
  poNumber: string | null | undefined;
  vendorId: number | null;
  vendorSiteId: number | null;
  vendorContactId: number | null;
  noteToVendor: string | null;
  shipToLocationId: number | null;
  billToLocationId: number | null;
  termsId: number | null;
  status: string | null;
  currencyCode: string | null;
  rateType: string | null;
  rateDate: string | null;
  rate: number | null;
  fromHeaderId: number | null;
  fromTypeLookupCode: string | null;
  startDate: string | null;
  endDate: string | null;
  approvedFlag: number | null; // Đây là anh (đổi thành kiểu ( một  hoặc không ))
  approvedDate: string | null;
  closedDate: string | null;
  blanketNumber: number | null; // Đây nữa
  blanketTotalAmount: number | null;
  attribute1: string | null;
  attribute2: string | null;
  attribute3: string | null;
  amountLimit: number | null;
  minReleaseAmount: number | null;
  rfqNumber: string | null;
  rfqAttached: string | null;
  replyDate: string | null;
  replyMethodLookupCode: string | null;
  rfqCloseDate: string | null;
  quotationNumber: string | null;
  quotationAttached: string | null;
  quoteTypeLookupCode: string | null;
  quotationClassCode: string | null;
  acceptanceRequiredFlag: boolean | null;
  acceptanceDueDate: string | null;
  userHoldFlag: boolean | null;
  noteToReceiver: string | null;
  comments: string | null;
  cancelFlag: string | null;
  orgId: number | null;
  creationDate: string | null;
  createdBy: number | null;
  lastUpdateDate: string | null;
  lastUpdatedBy: number | null;
  tblPoLines: PoLineModel[];
}

interface DocumentType {
  value: number;
  text: string;
}

interface WarehouseOrDepartment {
  id: number;
  name: string;
}

interface Contact {
  id: number;
  name: string;
  phone: string;
}

interface DeliveryLocation {
  id: number;
  name: string;
  address: string;
}

interface PaymentLocation {
  id: number;
  name: string;
  address: string;
}

const documentTypes: DocumentType[] = [
  { value: 1, text: "PO standard" },
  { value: 2, text: "PO planed" },
  { value: 3, text: "PO planket (hd khung)" },
  { value: 4, text: "Thư báo giá" },
  { value: 5, text: "Thư hỏi giá" },
];

const blanketNumberSelect: DocumentType[] = [
  { value: 0, text: "Chấp thuận" },
  { value: 1, text: "Từ Chối" },
];

const approvedFlagSelect: DocumentType[] = [
  { value: 0, text: "Đã phê duyệt" },
  { value: 1, text: "Chưa phê duyệt" },
];

const newWarehouseOrDepartments: WarehouseOrDepartment[] = [
  { id: 501, name: "Kho tổng Đà Nẵng" },
  { id: 502, name: "Kho tổng Nha Trang" },
  { id: 503, name: "Kho tổng Cần Thơ" },
  { id: 504, name: "Kho tổng Biên Hòa" },
  { id: 505, name: "Kho tổng Bắc Ninh" },
  { id: 506, name: "Phòng Kỹ thuật Sản xuất" },
  { id: 507, name: "Phòng Quản lý Dự án" },
  { id: 508, name: "Phòng Giao nhận" },
  { id: 509, name: "Phòng Hành chính Tổng hợp" },
  { id: 510, name: "Phòng Kinh doanh Trực tiếp" },
  { id: 511, name: "Tổng Kho Đại lý" },
  { id: 512, name: "Kho tổng Bình Thạnh" },
  { id: 513, name: "Kho tổng Thủ Đức" },
  { id: 514, name: "Kho tổng Vũng Tàu" },
  { id: 515, name: "Kho tổng Hạ Long" },
  { id: 516, name: "Kho tổng Mỹ Đình" },
  { id: 517, name: "Kho tổng Hải Dương" },
  { id: 518, name: "Kho tổng Nghệ An" },
  { id: 519, name: "Kho tổng Đồng Nai" },
  { id: 520, name: "Kho tổng Hà Nam" },
  { id: 521, name: "Kho tổng Điện Biên" },
];

const contacts: Contact[] = [
  { id: 601, name: "Nguyễn Văn A", phone: "0123-456-789" },
  { id: 602, name: "Trần Thị B", phone: "0123-456-788" },
  { id: 603, name: "Lê Văn C", phone: "0123-456-787" },
  { id: 604, name: "Phạm Thị D", phone: "0123-456-786" },
  { id: 605, name: "Nguyễn Thị E", phone: "0123-456-785" },
  { id: 606, name: "Vũ Văn F", phone: "0123-456-784" },
  { id: 607, name: "Hoàng Thị G", phone: "0123-456-783" },
  { id: 608, name: "Nguyễn Văn H", phone: "0123-456-782" },
  { id: 609, name: "Trần Văn I", phone: "0123-456-781" },
  { id: 610, name: "Lê Thị J", phone: "0123-456-780" },
  { id: 611, name: "Phạm Văn K", phone: "0123-456-779" },
  { id: 612, name: "Vũ Thị L", phone: "0123-456-778" },
  { id: 613, name: "Nguyễn Văn M", phone: "0123-456-777" },
  { id: 614, name: "Trần Thị N", phone: "0123-456-776" },
  { id: 615, name: "Lê Văn O", phone: "0123-456-775" },
  { id: 616, name: "Nguyễn Thị P", phone: "0123-456-774" },
  { id: 617, name: "Hoàng Văn Q", phone: "0123-456-773" },
  { id: 618, name: "Phạm Văn R", phone: "0123-456-772" },
  { id: 619, name: "Vũ Thị S", phone: "0123-456-771" },
  { id: 620, name: "Nguyễn Văn T", phone: "0123-456-770" },
  { id: 621, name: "Trần Thị U", phone: "0123-456-769" },
];

const deliveryLocations: DeliveryLocation[] = [
  { id: 700, name: "Địa chỉ Giao hàng A", address: "124 Minh Khai" },
  { id: 701, name: "Địa chỉ Giao hàng A", address: "123 Đường ABC, Đà Nẵng" },
  { id: 702, name: "Địa chỉ Giao hàng B", address: "456 Đường DEF, Nha Trang" },
  { id: 703, name: "Địa chỉ Giao hàng C", address: "789 Đường GHI, Cần Thơ" },
  { id: 704, name: "Địa chỉ Giao hàng D", address: "321 Đường JKL, Biên Hòa" },
  { id: 705, name: "Địa chỉ Giao hàng E", address: "654 Đường MNO, Bắc Ninh" },
  { id: 706, name: "Địa chỉ Giao hàng F", address: "987 Đường PQR, Hạ Long" },
  { id: 707, name: "Địa chỉ Giao hàng G", address: "159 Đường STU, Vũng Tàu" },
  { id: 708, name: "Địa chỉ Giao hàng H", address: "753 Đường VWX, Thủ Đức" },
  { id: 709, name: "Địa chỉ Giao hàng I", address: "852 Đường YZ, Hải Dương" },
  { id: 710, name: "Địa chỉ Giao hàng J", address: "951 Đường AAB, Nghệ An" },
  { id: 711, name: "Địa chỉ Giao hàng K", address: "357 Đường CCD, Đồng Nai" },
  { id: 712, name: "Địa chỉ Giao hàng L", address: "159 Đường DDE, Hà Nam" },
  { id: 713, name: "Địa chỉ Giao hàng M", address: "753 Đường FFG, Điện Biên" },
  {
    id: 714,
    name: "Địa chỉ Giao hàng N",
    address: "951 Đường HHI, Bình Thạnh",
  },
  { id: 715, name: "Địa chỉ Giao hàng O", address: "357 Đường JJK, Long Biên" },
  { id: 716, name: "Địa chỉ Giao hàng P", address: "159 Đường KKL, Từ Sơn" },
  { id: 717, name: "Địa chỉ Giao hàng Q", address: "753 Đường LLM, Thanh Trì" },
  { id: 718, name: "Địa chỉ Giao hàng R", address: "951 Đường MNN, Đông Anh" },
  { id: 719, name: "Địa chỉ Giao hàng S", address: "357 Đường OOO, Bạc Liêu" },
  { id: 720, name: "Địa chỉ Giao hàng T", address: "159 Đường PPP, Hậu Giang" },
];

const paymentLocations: PaymentLocation[] = [
  { id: 800, name: "Địa điểm Thanh toán A", address: "131 Lê Thanh Nghị" },

  { id: 801, name: "Địa điểm Thanh toán A", address: "123 Đường ZYX, Hà Nội" },
  { id: 802, name: "Địa điểm Thanh toán B", address: "456 Đường QWE, Đà Nẵng" },
  { id: 803, name: "Địa điểm Thanh toán C", address: "789 Đường RTY, TP.HCM" },
  {
    id: 804,
    name: "Địa điểm Thanh toán D",
    address: "321 Đường UIO, Hải Phòng",
  },
  { id: 805, name: "Địa điểm Thanh toán E", address: "654 Đường PAS, Cần Thơ" },
  {
    id: 806,
    name: "Địa điểm Thanh toán F",
    address: "987 Đường DFG, Nha Trang",
  },
  {
    id: 807,
    name: "Địa điểm Thanh toán G",
    address: "159 Đường HJK, Thái Nguyên",
  },
  {
    id: 808,
    name: "Địa điểm Thanh toán H",
    address: "753 Đường LOP, Bắc Ninh",
  },
  {
    id: 809,
    name: "Địa điểm Thanh toán I",
    address: "852 Đường QRS, Biên Hòa",
  },
  {
    id: 810,
    name: "Địa điểm Thanh toán J",
    address: "951 Đường TUV, Long Biên",
  },
  { id: 811, name: "Địa điểm Thanh toán K", address: "357 Đường WXY, Vinh" },
  { id: 812, name: "Địa điểm Thanh toán L", address: "159 Đường ZZZ, Hạ Long" },
  { id: 813, name: "Địa điểm Thanh toán M", address: "753 Đường AAA, Đà Lạt" },
  {
    id: 814,
    name: "Địa điểm Thanh toán N",
    address: "951 Đường BBB, Bạc Liêu",
  },
  {
    id: 815,
    name: "Địa điểm Thanh toán O",
    address: "357 Đường CCC, Thái Bình",
  },
  {
    id: 816,
    name: "Địa điểm Thanh toán P",
    address: "159 Đường DDD, Hưng Yên",
  },
  {
    id: 817,
    name: "Địa điểm Thanh toán Q",
    address: "753 Đường EEE, Bình Dương",
  },
  {
    id: 818,
    name: "Địa điểm Thanh toán R",
    address: "951 Đường FFF, Quảng Ninh",
  },
  {
    id: 819,
    name: "Địa điểm Thanh toán S",
    address: "357 Đường GGG, Ninh Bình",
  },
  { id: 820, name: "Địa điểm Thanh toán T", address: "159 Đường HHH, Nghệ An" },
];

const orderTypes = [
  { value: "1", label: "Tạo mới", color: "blue" },
  { value: "2", label: "Đã duyệt", color: "green" },
  { value: "3", label: "Đóng đơn", color: "yellow" },
  { value: "4", label: "Hủy đơn", color: "red" },
];

interface ApiResponse {
  lists: PurchaseOrder[];
}

interface TableSelectProps {
  dataProducts: Item[];
  onSelectProduct: (product: Item) => void;
  rowIndex: any;
}

const ImportPurchases = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [dataListPoHeader, setDataListPoHeader] = useState<PurchaseOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(1);
  const [take, setTake] = useState<number>(30);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dataProducts, setDataProducts] = useState<ProductModel[]>([]);
  const [data, setData] = useState<ApiResponse[]>([]);
  const [dataPoHeader, setDataPoHeader] = useState<PurchaseOrder>();

  const [dataDetail, setDataDetail] = useState<ApiResponse[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const [listAdd, setListAdd] = useState<PoLineModel[]>([]);

  const [dataListItem, setDataListItem] = useState<Item[]>([]);
  const [valueDebounced, setValueDebounced] = useDebouncedState("", 500);

  const [keySearch, setKeySearch] = useState<string>("");
  const [keepRef, setKeepRef] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const [dataListVendor, setDataListVendor] = useState<any[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [searchParams, setSearchParams] = useState<SearchParams>({
    searchKey: "",
    searchFrom: "",
    searchTo: "",
    handeDateStart: new Date(),
    handeDateEnd: null,
  });

  const [formData, setFormData] = useState({
    poHeaderId: null,
    buyerId: null,
    type: "PO standard",
    poNumber: "",
    vendorId: null,
    vendorSiteId: 511,
    vendorContactId: 601,
    noteToVendor: null,
    shipToLocationId: 700,
    billToLocationId: 800,
    termsId: null,
    status: "Tạo mới",
    currencyCode: "VND",
    rateType: null,
    rateDate: null,
    rate: null,
    fromHeaderId: null,
    fromTypeLookupCode: null,
    startDate: null,
    endDate: null,
    approvedFlag: 0, // Đây là anh (đổi thành kiểu ( một  hoặc không ))
    approvedDate: null,
    closedDate: null,
    blanketNumber: 0, // Đây nữa
    blanketTotalAmount: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    amountLimit: null,
    minReleaseAmount: null,
    rfqNumber: null,
    rfqAttached: null,
    replyDate: null,
    replyMethodLookupCode: null,
    rfqCloseDate: null,
    quotationNumber: null,
    quotationAttached: null,
    quoteTypeLookupCode: null,
    quotationClassCode: null,
    acceptanceRequiredFlag: null,
    acceptanceDueDate: null,
    userHoldFlag: null,
    noteToReceiver: "",
    comments: null,
    cancelFlag: null,
    quantity: 0,
    orgId: null,
    creationDate: null,
    createdBy: null,
    lastUpdateDate: null,
    lastUpdatedBy: null,
    totalAmout: listAdd.length > 0 ? 210000 : 0,
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemId",
        header: "Mã hàng",
        enableEditing: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Text
            size="sm"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
            fw={600}
          >
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "itemDescription",
        header: "Tên hàng",
        Edit: ({ cell, row, table, column }) => {
          const ref = useClickOutside(() => {
            if (!keepRef) {
              table.setEditingCell(null);
            }
          });

          return (
            <div ref={ref}>
              <Menu
                trapFocus={false}
                trigger="hover"
                shadow="md"
                width={"800"}
                position="bottom-start"
              >
                <Menu.Target>
                  <TextInput
                    autoFocus
                    placeholder=""
                    onChange={async (e) => {
                      const _key = e.target.value ?? "";
                      setValueDebounced(_key);
                    }}
                    onFocus={() => setKeepRef(true)}
                    onBlur={() => setKeepRef(false)}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <TableSelect
                    dataProducts={dataListItem}
                    onSelectProduct={(product) => handleSelectProduct(product)}
                    rowIndex={row.index}
                  />
                </Menu.Dropdown>
              </Menu>
            </div>
          );
        },
        Cell: ({ renderedCellValue, row, table }) => (
          <Text size="sm" fw={600}>
            {renderedCellValue}
          </Text>
        ),
      },
      // {
      //   accessorKey: "unitPrice",
      //   header: "Giá",
      // },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        Cell: ({ row, column, table }) => {
          return (
            <NumberInput
              placeholder="Nhập số lượng"
              hideControls
              // value={row.original.quantity}
              onChange={(e) => {
                handleChange("quantity", e);
                setListAdd((prevData) => {
                  const updatedData = prevData.map((item, i) =>
                    i === row.index
                      ? {
                          ...item,
                          quantity: Number(e),
                          totalAmout: Number(e) * (item.unitPrice ?? 0),
                        }
                      : item
                  );

                  const newTotalAmount = updatedData.reduce(
                    (acc, item) => acc + (item.totalAmout ?? 0),
                    0
                  );
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    totalAmout: newTotalAmount,
                  }));

                  return updatedData;
                });
              }}
              onBlur={(e) => {
                table.setEditingCell(null);
              }}
            />
          );
        },
      },
      {
        accessorKey: "unitPrice",
        header: "Đơn giá",
        enableEditing: false,
        Cell: ({ row, column, table }) => {
          return (
            <NumberFormatter
              thousandSeparator
              value={row.original.itemId ? 210000 : 0}
            />
          );
        },
      },
      {
        accessorKey: "uom",
        header: "Đơn vị tính",
        enableEditing: false,
        Cell: ({ row, column, table }) => {
          return <Text> {row.original.itemId ? "Chiếc" : ""}</Text>;
        },
      },
      {
        accessorKey: "totalAmout",
        header: "Thành tiền",
        enableEditing: false,
        Cell: ({ row, column, table }) => {
          const totalPrice = row.original.unitPrice * row.original.quantity;
          console.log(totalPrice);
          return <NumberFormatter thousandSeparator value={totalPrice} />;
        },
      },
      {
        accessorKey: "noteToVendor",
        header: "Ghi chú",
        Cell: ({ row, column, table }) => {
          return (
            <TextInput
              placeholder="Nhập ghi chú"
              defaultValue={row.original.noteToVendor || ""}
              onChange={(e) => {
                const newValue = e.target.value;

                setListAdd((prevData) =>
                  prevData.map((item, i) =>
                    i === row.index
                      ? {
                          ...item,
                          noteToVendor: newValue,
                        }
                      : item
                  )
                );
              }}
              onBlur={() => {
                table.setEditingCell(null);
              }}
            />
          );
        },
      },
    ],
    [dataProducts, dataListItem]
  );

  console.log(columns);

  const TableSelect: React.FC<TableSelectProps> = ({
    dataProducts,
    onSelectProduct,
    rowIndex,
  }: any) => {
    return (
      <Table.ScrollContainer mah={300} minWidth={500} type="native">
        <Table striped withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={{ whiteSpace: "nowrap" }}>Chọn</Table.Th>
              <Table.Th style={{ whiteSpace: "nowrap" }}>Mã Item</Table.Th>
              <Table.Th style={{ whiteSpace: "nowrap" }}>Tên sản phẩm</Table.Th>
              <Table.Th style={{ whiteSpace: "nowrap" }}>
                Mã sản phẩm cũ
              </Table.Th>
              <Table.Th style={{ whiteSpace: "nowrap" }}>Giá</Table.Th>
              <Table.Th style={{ whiteSpace: "nowrap" }}>Thông tin</Table.Th>
              <Table.Th style={{ whiteSpace: "nowrap" }}>Giảm giá</Table.Th>
              <Table.Th style={{ whiteSpace: "nowrap" }}>Hàng có sẵn</Table.Th>
              <Table.Th style={{ whiteSpace: "nowrap" }}>Bảo hành</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {dataListItem?.length > 0 ? (
            <Table.Tbody>
              {dataListItem?.map((product: any, index: any) => {
                return (
                  <Table.Tr key={product.itemId + index}>
                    <Table.Td>
                      <Button
                        size="xs"
                        variant="filled"
                        onClick={() => {
                          onSelectProduct(product, rowIndex);
                          table.setCreatingRow(null);
                        }}
                        disabled={handleCheckChooseProduct(product, listAdd)}
                      >
                        {handleCheckChooseProduct(product, listAdd)
                          ? "Đã chọn"
                          : "Chọn"}
                      </Button>
                    </Table.Td>
                    <Table.Td>{product.itemCode}</Table.Td>
                    <Table.Td>
                      <Text w={250} size="sm" lineClamp={2}>
                        {product.itemName}
                      </Text>
                    </Table.Td>
                    <Table.Td>{product.oldItemCode}</Table.Td>
                    <Table.Td>{product.poPrice}</Table.Td>
                    <Table.Td>
                      {" "}
                      <Text w={250} size="sm" lineClamp={2}>
                        {product.description}
                      </Text>
                    </Table.Td>
                    <Table.Td>{product.discount}</Table.Td>
                    <Table.Td>{product.isOnhand}</Table.Td>
                    <Table.Td>{product.warranty}</Table.Td>
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text>Hãy nhập ID hoặc mã sản phẩm!</Text>
            </Box>
          )}
        </Table>
      </Table.ScrollContainer>
    );
  };

  // const openDeleteConfirmModal = (row: MRT_Row<dataOrderDetailTable>) => {
  //   return modals.openConfirmModal({
  //     title: "Bạn có chắc chắn xóa sản phẩm này?",
  //     children: (
  //       <Text size="lg" mt={15}>
  //         Hành động này có thể được hoàn tác khi được thêm mới.
  //       </Text>
  //     ),
  //     labels: { confirm: "Xóa", cancel: "Thoát" },
  //     confirmProps: { color: "red" },
  //     onConfirm: () => {
  //       const data = listAdd.filter((value) => value.id !== row.original.id);
  //       setListAdd(data);
  //     },
  //   });
  // };

  // hàm check sản phẩm đã chọn hay chưa

  const handleSelectProduct = (product: Item) => {
    const addData: PoLineModel = {
      poLineId: null,
      poHeaderId: null,
      lineTypeId: null,
      lineNum: null,
      itemId: product?.itemId,
      itemDescription: product?.description,
      uom: null,
      unitPrice: 210000,
      quantity: null,
      noteToVendor: null,
      fromHeaderQuotationId: null,
      fromLineQuotationId: null,
      minOrderQuantity: null,
      maxOrderQuantity: null,
      qtyRcvTolerance: null,
      closedFlag: null,
      userHoldFlag: null,
      cancelFlag: null,
      cancelledBy: null,
      cancelDate: null,
      cancelReason: null,
      taxCodeId: null,
      taxableFlag: null,
      taxName: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      minReleaseAmount: null,
      closedDate: null,
      closedReason: null,
      closedBy: null,
      orgId: null,
      primaryUom: null,
      primaryQty: null,
      lastUpdatedBy: null,
      createdBy: null,
      lastUpdateDate: null,
      totalAmout: null,
    };

    console.log(addData);

    setListAdd((prevList) => [...prevList, addData]);
  };

  // const total = requestData.tblPoLines.reduce((acc, item) => {
  //   return acc + (item.totalAmout ?? 0);
  // }, 0);

  // console.log(total);

  const fetchCreateReceiptParent = async () => {
    let isValid = true;
    const errors = {
      payerfullname: "",
      accountfund: 0,
      codefund: "",
      createby: 0,
      createdDate: "",
      creatorbranch: "",
      creatordepartment: "",
      creatorfullname: "",
      creatorsalesbranch: "",
      creatorworkplace: "",
      fundbranch: "",
      funddepartment: "",
      fundsalesbranch: "",
      fundworkplace: "",
      funfullname: "",
      id: 0,
      payerdescription: "",
      payernote: "",
      payertype: "",
      receiptNumber: "",
      totalAmount: 0,
      type: 0,
    };

    if (!isValid) {
      NotificationExtension.Fails("Vui lòng nhập đầy đủ thông tin.");
      return;
    }

    const requestData: PurchaseOrder = {
      poHeaderId: null,
      buyerId: null,
      type: formData?.type,
      poNumber: dataPoHeader?.poNumber,
      vendorId: formData?.vendorId,
      vendorSiteId: formData?.vendorSiteId,
      vendorContactId: formData?.vendorContactId,
      noteToVendor: formData?.noteToVendor,
      shipToLocationId: formData?.shipToLocationId,
      billToLocationId: formData?.billToLocationId,
      termsId: null,
      status: formData?.status,
      currencyCode: formData?.currencyCode,
      rateType: null,
      rateDate: null,
      rate: null,
      fromHeaderId: null,
      fromTypeLookupCode: null,
      startDate: null,
      endDate: null,
      approvedFlag: null, // Đây là anh (đổi thành kiểu ( một  hoặc không ))
      approvedDate: null,
      closedDate: null,
      blanketNumber: null, // Đây nữa
      blanketTotalAmount: formData?.totalAmout,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      amountLimit: null,
      minReleaseAmount: null,
      rfqNumber: null,
      rfqAttached: null,
      replyDate: null,
      replyMethodLookupCode: null,
      rfqCloseDate: null,
      quotationNumber: null,
      quotationAttached: null,
      quoteTypeLookupCode: null,
      quotationClassCode: null,
      acceptanceRequiredFlag: null,
      acceptanceDueDate: null,
      userHoldFlag: null,
      noteToReceiver: formData?.noteToReceiver,
      comments: formData?.comments,
      cancelFlag: null,
      orgId: null,
      creationDate: null,
      createdBy: null,
      lastUpdateDate: null,
      lastUpdatedBy: null,
      tblPoLines: Object.values(listAdd).map((e: any) => ({
        poLineId: null,
        poHeaderId: null,
        lineTypeId: null,
        lineNum: null,
        itemId: e?.itemId,
        itemDescription: e?.itemDescription,
        uom: "Chiếc",
        unitPrice: 210000,
        quantity: e?.quantity,
        noteToVendor: e?.noteToVendor,
        fromHeaderQuotationId: null,
        fromLineQuotationId: null,
        minOrderQuantity: null,
        maxOrderQuantity: null,
        qtyRcvTolerance: null,
        closedFlag: null,
        userHoldFlag: null,
        cancelFlag: null,
        cancelledBy: null,
        cancelDate: null,
        cancelReason: null,
        taxCodeId: null,
        taxableFlag: null,
        taxName: null,
        attribute1: null,
        attribute2: null,
        attribute3: null,
        attribute4: null,
        minReleaseAmount: null,
        closedDate: null,
        closedReason: null,
        closedBy: null,
        orgId: null,
        primaryUom: null,
        primaryQty: null,
        lastUpdatedBy: null,
        createdBy: null,
        lastUpdateDate: null,
        totalAmout: e?.quantity * 210000,
      })),
    };

    try {
      const response = await repositoryMdm.post<MessageResponse<PurchaseOrder>>(
        "/api/v1/TblPoHeader/create",
        requestData
      );

      if (response && response.success) {
        NotificationExtension.Success("Lưu phiếu thu thành công!");
        // setDataListReceipt([]);
        // fetchDataReceiptParent();
        // resetForm();
        // setTotalAllocatedAmount(0);
        // fetchDataListReceipt();
      } else {
        NotificationExtension.Fails("Lưu phiếu thu thất bại!");
      }
    } catch (error) {
      console.error("Error creating receipt:", error);
    }
  };

  const handleCheckChooseProduct = (product: any, listAdd: any[]): boolean => {
    const found = listAdd.some((item) => item.itemId === product.itemId);
    return found;
  };

  const deletesData = async (ids: number[]) => {
    if (ids.length < 1) {
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    } else {
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa !</Title>
            </>
          ),
          size: "550px",
          children: <>Bạn có thực sự muốn xóa ?</>,
          labels: { confirm: "Lưu lại", cancel: "Hủy bỏ" },
          onConfirm: async () => {
            try {
              for (const id of ids) {
                const payload = { id };

                const res = await repositoryMdm.post<MessageResponse<boolean>>(
                  "/api/v1/TblPoHeader/delete",
                  payload
                );

                if (res?.success) {
                  NotificationExtension.Success(`Xóa thành công !`);
                } else {
                  NotificationExtension.Fails(`Xóa thất bại !`);
                }
              }
              await fetchData();
            } catch (error) {
              NotificationExtension.Fails("Có lỗi xảy ra trong quá trình xóa!");
              console.error(error);
            }
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const table = useMantineReactTable<any>({
    columns,
    data: listAdd,
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: true,
    enableRowActions: true,

    //add custom action buttons to top-left of top toolbar
    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: true,
    // getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        // left: ["mrt-row-select", "receiptNumber"],
        right: ["quantity", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    // enableExpanding: true,
    // getSubRows: (originalRow) => originalRow.orderDetail,

    renderTopToolbarCustomActions: ({ table }) => <></>,

    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    renderRowActions: ({ row, table }) => (
      <Flex gap="4">
        {/* <Tooltip label="Chọn chính sách khuyến mãi">
            <ActionIcon
              color={orange.base}
              variant="outline"
              disabled={row.original.listIdPromotion.length < 1}
              onClick={async () => {
                openFormVoucher(row);
              }}
            >
              <IconDiscount
                style={{
                  color:
                    row.original.listIdPromotion.length < 1
                      ? "gray"
                      : orange.base,
                }}
              />
            </ActionIcon>
          </Tooltip> */}
        <Tooltip label="Xoá sản phẩm">
          <ActionIcon variant="outline" color="red">
            <IconTrash onClick={() => openDeleteConfirmModal(row)} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  const handleChange = (
    field: string,
    value: string | number | Date | null
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value instanceof Date ? value.toISOString() : value,
    }));
  };

  const formatDate = (date: Date | null): string => {
    if (date === null) return "";
    return moment(date).format("YYYY-MM-DD");
  };

  const fetchDataItem = async () => {
    if (valueDebounced) {
      let url = `/api/v1/TblItem/get-list?Skip=0&Take=20`;

      if (valueDebounced) {
        url += `&KeySearch=${valueDebounced}`;
      }

      try {
        const response = await repositoryPos.get<MessageResponse<Item[]>>(url);

        if (response && response.success) {
          setDataListItem(response.data);
        }
      } catch (error) {
        console.error("Error fetching data list receipt:", error);
      }
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        KeySearch: searchParams.searchKey,
      };

      const url = Object.entries(queryParams)
        .reduce((acc, [key, value]) => {
          if (value !== undefined && value !== "") {
            return `${acc}&${key}=${encodeURIComponent(value)}`;
          }
          return acc;
        }, "/api/v1/TblPoHeader/get-list?")
        .replace("&", "");

      const response = await repositoryMdm.get<MessageResponse<any>>(url);
      if (response && response.success) {
        setData(response?.data?.lists);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const openDeleteConfirmModal = (row: MRT_Row<any>) => {
    return modals.openConfirmModal({
      title: <Title order={5}>Bạn có chắc chắn xóa sản phẩm này?</Title>,
      children: (
        <Text size="lg" mt={15} mb={15}>
          Hành động này có thể được hoàn tác khi được thêm mới.
        </Text>
      ),
      labels: { confirm: "Xóa", cancel: "Thoát" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        const data = listAdd.filter(
          (value) => value.itemId !== row.original.itemId
        );
        setListAdd(data);
      },
    });
  };

  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={orderTypes.find((x) => x.value === option.value)?.color}
        radius="sm"
        variant="filled"
      >
        {option.label}{" "}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  const fetchDataListBranch = async () => {
    const response = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblPoHeader/create"
    );

    if (response && response.success) {
      let result = response.data;
      setDataPoHeader(result);
    }
  };

  const fetchDataListVendor = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/TblVendor/get-all");

    if (response && response.success) {
      let result = response.data;
      setDataListVendor(result);
    }
  };

  useEffect(() => {
    fetchDataItem();
  }, [pagination, sorting, valueDebounced]);

  useEffect(() => {
    fetchDataListBranch();
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (180 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify="space-between"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <_breadcrumb></_breadcrumb>
          <Badge
            size="xl"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            color="blue"
          >
            Tạo mới
          </Badge>
          <Button
            leftSection={<IconPlus size={14} />}
            variant="outline"
            color="blue"
            size="xs"
            onClick={() => {
              fetchDataItem();
              table.setCreatingRow(true);
            }}
          >
            Thêm sản phẩm (F11)
          </Button>
        </Flex>
      </div>
      <Grid>
        <GridCol pb={0} style={{ boxShadow: "0 2px 2px #ccc" }} span={5}>
          <ScrollArea pr={10} scrollbars="y" h={height} offsetScrollbars>
            <Grid grow>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    Mã đơn:
                  </Text>
                  <Text fw="600">{dataPoHeader?.poNumber}</Text>
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <EditableTextWithOptions
                    required
                    label="Loại đơn ĐH"
                    value={
                      documentTypes?.find(
                        (item: any) => item.text === formData.type
                      )?.text || "Vui lòng chọn"
                    }
                    options={
                      documentTypes
                        ?.map((e: any) => e.text)
                        .filter((text): text is string => !!text) ?? []
                    }
                    onChange={(selectedDescription) => {
                      const selectedOption = documentTypes?.find(
                        (item: any) => item.text === selectedDescription
                      );
                      if (selectedOption) {
                        handleChange("type", selectedOption.text);
                      }
                    }}
                  />
                </Flex>
              </GridCol>
              {/* <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    Trạng thái
                  </Text>
                  <Select
                    searchable
                    clearable
                    comboboxProps={{
                      transitionProps: { transition: "fade-up", duration: 200 },
                    }}
                    data={orderTypes}
                    placeholder="Vui lòng chọn"
                    onChange={(selectedDescription) => {
                      const selectedOption = orderTypes?.find(
                        (item: any) => item.value === selectedDescription
                      );
                      if (selectedOption) {
                        console.log(selectedOption.label);
                        handleChange("status", selectedOption.label);
                      }
                    }}
                    nothingFoundMessage="Dữ liệu không có !"
                    renderOption={renderSelectOption}
                  />
                </Flex>
              </GridCol> */}
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    Nhà cung cấp
                  </Text>
                  <Select
                    searchable
                    clearable
                    placeholder="Vui lòng chọn"
                    comboboxProps={{
                      transitionProps: { transition: "pop", duration: 200 },
                    }}
                    limit={50}
                    data={dataListVendor.map((item) => {
                      return {
                        label: item.vendorName ?? "",
                        value: String(item.id ?? ""),
                      };
                    })}
                    onClick={async () => {
                      if (!dataListVendor || dataListVendor.length < 1) {
                        await fetchDataListVendor();
                      }
                      return;
                    }}
                    onChange={(selectedDescription) => {
                      handleChange("vendorId", Number(selectedDescription));
                    }}
                  />
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    Loại tiền tệ
                  </Text>
                  <Text fw={600}>{formData?.currencyCode}</Text>
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <EditableTextWithOptions
                    label="ĐĐ cung cấp"
                    value={
                      newWarehouseOrDepartments?.find(
                        (item: any) => item.id === formData.vendorSiteId
                      )?.name || ""
                    }
                    options={
                      newWarehouseOrDepartments
                        ?.map((e: any) => e.name)
                        .filter((name): name is string => !!name) ?? []
                    }
                    onChange={(selectedDescription) => {
                      const selectedOption = newWarehouseOrDepartments?.find(
                        (item: any) => item.name === selectedDescription
                      );
                      if (selectedOption) {
                        handleChange("vendorSiteId", selectedOption.id);
                      }
                    }}
                  />

                  {/* <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    ĐĐ cung cấp
                  </Text>
                  <Select
                    searchable
                    clearable
                    placeholder="Vui lòng chọn"
                    comboboxProps={{
                      transitionProps: { transition: "pop", duration: 200 },
                    }}
                    limit={50}
                    data={newWarehouseOrDepartments.map((item) => {
                      return {
                        label: item.name ?? "",
                        value: String(item.id ?? ""),
                      };
                    })}
                    // onClick={async () => {
                    //   if (!newWarehouseOrDepartments || newWarehouseOrDepartments.length < 1) {
                    //     await fetchDataListVendor();
                    //   }
                    //   return;
                    // }}
                    onChange={(selectedOrDepartments) => {
                      handleChange(
                        "vendorSiteId",
                        Number(selectedOrDepartments)
                      );
                    }}
                  /> */}
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={10} align="center" gap="xs">
                  <EditableTextWithOptions
                    label="Người liên hệ"
                    value={
                      contacts?.find(
                        (item: any) => item.id === formData.vendorContactId
                      )?.name || ""
                    }
                    options={
                      contacts
                        ?.map((e: any) => e.name)
                        .filter((name): name is string => !!name) ?? []
                    }
                    onChange={(selectedDescription) => {
                      const selectedOption = contacts?.find(
                        (item: any) => item.name === selectedDescription
                      );
                      if (selectedOption) {
                        handleChange("vendorContactId", selectedOption.id);
                      }
                    }}
                  />

                  {/* <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    Người liên hệ
                  </Text>
                  <Select
                    searchable
                    clearable
                    placeholder="Vui lòng chọn"
                    comboboxProps={{
                      transitionProps: { transition: "pop", duration: 200 },
                    }}
                    limit={50}
                    data={contacts.map((item) => {
                      return {
                        label: item.name ?? "",
                        value: String(item.id ?? ""),
                      };
                    })}
                    // onClick={async () => {
                    //   if (!contacts || contacts.length < 1) {
                    //     await fetchDataListVendor();
                    //   }
                    //   return;
                    // }}
                    onChange={(selectedDescription) => {
                      handleChange(
                        "vendorContactId",
                        Number(selectedDescription)
                      );
                    }}
                  /> */}
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={20} align="center" gap="xs">
                  <EditableTextWithOptions
                    label="ĐĐ giao hàng"
                    value={
                      deliveryLocations?.find(
                        (item: any) => item.id === formData.shipToLocationId
                      )?.address || ""
                    }
                    options={
                      deliveryLocations
                        ?.map((e: any) => e.address)
                        .filter((address): address is string => !!address) ?? []
                    }
                    onChange={(selectedDescription) => {
                      const selectedOption = deliveryLocations?.find(
                        (item: any) => item.address === selectedDescription
                      );
                      if (selectedOption) {
                        handleChange("shipToLocationId", selectedOption.id);
                      }
                    }}
                  />

                  {/* <Text
                    style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}
                  ></Text>
                  <Select
                    searchable
                    clearable
                    placeholder={
                      deliveryLocations?.find(
                        (item: any) => item.id === formData.shipToLocationId
                      )?.address || "124 Minh Khai"
                    }
                    comboboxProps={{
                      transitionProps: { transition: "pop", duration: 200 },
                    }}
                    limit={50}
                    data={deliveryLocations.map((item) => {
                      return {
                        label: item.address ?? "124 Minh Khai",
                        value: String(item.id ?? ""),
                      };
                    })}
                    // onClick={async () => {
                    //   if (!deliveryLocations || deliveryLocations.length < 1) {
                    //     await fetchDataListVendor();
                    //   }
                    //   return;
                    // }}
                    onChange={(selectedDescription) => {
                      handleChange(
                        "shipToLocationId",
                        Number(selectedDescription)
                      );
                    }}
                  /> */}
                </Flex>
              </GridCol>
              <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                <Flex mt={20} align="center" gap="xs">
                  <EditableTextWithOptions
                    label=" ĐĐ thanh toán"
                    value={
                      paymentLocations?.find(
                        (item: any) => item.id === formData.billToLocationId
                      )?.address || ""
                    }
                    options={
                      paymentLocations
                        ?.map((e: any) => e.address)
                        .filter((address): address is string => !!address) ?? []
                    }
                    onChange={(selectedDescription) => {
                      const selectedOption = paymentLocations?.find(
                        (item: any) => item.address === selectedDescription
                      );
                      if (selectedOption) {
                        handleChange("billToLocationId", selectedOption.id);
                      }
                    }}
                  />
                  {/* <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                    ĐĐ thanh toán
                  </Text>
                  <Select
                    searchable
                    clearable
                    placeholder="Vui lòng chọn"
                    comboboxProps={{
                      transitionProps: { transition: "pop", duration: 200 },
                    }}
                    limit={50}
                    data={dataListVendor.map((item) => {
                      return {
                        label: item.vendorName ?? "",
                        value: String(item.id ?? ""),
                      };
                    })}
                    onClick={async () => {
                      if (!dataListVendor || dataListVendor.length < 1) {
                        await fetchDataListVendor();
                      }
                      return;
                    }}
                    onChange={(selectedDescription) => {
                      handleChange(
                        "billToLocationId",
                        Number(selectedDescription)
                      );
                    }}
                  /> */}
                </Flex>
              </GridCol>
              {/* <Grid.Col pb={0} span={12}>
                <Divider
                  my="xs"
                  label={
                    <Text fw={600} size="xs">
                      Người lập phiếu thu
                    </Text>
                  }
                  labelPosition="left"
                />
              </Grid.Col> */}
            </Grid>

            <GridCol pl={0} pb={0} span={{ base: 12, md: 12, lg: 6 }}>
              <Group mt={10}>
                <Text style={{ flex: "0 0 95px" }}>Tổng tiền</Text>
                <NumberFormatter
                  thousandSeparator
                  value={formData?.totalAmout}
                />
              </Group>
            </GridCol>

            <GridCol pl={0} pb={0} span={{ base: 12, md: 12, lg: 12 }}>
              <Group mt={10}>
                <Text style={{ flex: "0 0 95px" }}>Ghi chú từ người nhận</Text>
                <Textarea
                  size="sm"
                  flex={1}
                  placeholder="Nhập..."
                  // value={formData.payerName}
                  onChange={(e) =>
                    handleChange("noteToReceiver", e.currentTarget.value)
                  }
                />
              </Group>
            </GridCol>
            <GridCol pl={0} pb={0} span={{ base: 12, md: 12, lg: 12 }}>
              <Group mt={10}>
                <Text style={{ flex: "0 0 95px", whiteSpace: "nowrap" }}>
                  Ghi chú
                </Text>
                <Textarea
                  size="sm"
                  flex={1}
                  placeholder="Nhập..."
                  // value={formData.payerName}
                  onChange={(e) =>
                    handleChange("comments", e.currentTarget.value)
                  }
                />
              </Group>
            </GridCol>
          </ScrollArea>
          <Group w="100%" justify="end">
            <Button onClick={fetchCreateReceiptParent}>Gửi</Button>
          </Group>
        </GridCol>
        <GridCol span={7}>
          <div className="mt-5" style={{ marginTop: "10px" }}>
            <MantineReactTable table={table} />
          </div>
        </GridCol>
      </Grid>
    </>
  );
};

export default ImportPurchases;
