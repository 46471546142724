import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { useEffect, useState } from "react";

type InventoryPosition = {
  id: number;
  invId: number | null;
  invCode: string | null;
  subInvId: string | null;
  subInvCode: string | null;
  positionCode: string | null;
  positionName: string | null;
  active: boolean;
  createDate: string | null;
  createBy: number | null;
  lastUpdateDate: string | null;
  lastUpdateBy: number | null;
};

type CreateDataViewProps = {
  onClose: any;
};

type Warehouse = {
  att1: string | null;
  att2: string | null;
  att3: number;
  att4: number;
  att5: string | null;
  disabled: boolean;
  group: string | null;
  selected: boolean;
  text: string;
  value: string;
};

const CreateWarehouseLocationList = ({ onClose }: CreateDataViewProps) => {
  const [listData, setListData] = useState<{ value: string; label: string }[]>(
    []
  );

  const entity = {
    id: 0,
    invId: null,
    invCode: null,
    subInvId: null,
    subInvCode: null,
    positionCode: null,
    positionName: null,
    active: false,
    createDate: null,
    createBy: null,
    lastUpdateDate: null,
    lastUpdateBy: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const fetchDataWarehouse = async () => {
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblDmInventory/get-select`
      );
      if (response && response.success) {
        const data = response.data;
        console.log(data);
        const formattedData = data.map((item: Warehouse) => ({
          value: item.value,
          label: item.text,
        }));
        setListData(formattedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    fetchDataWarehouse();
  }, []);

  const form = useForm<InventoryPosition>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
      invId: 1,
      subInvCode: "1",
      invCode: "1",
    },
    validate: {
      subInvId: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn kho con!";
        }
      },
      positionCode: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập mã vị trí!";
        }
        return hasLength(
          { max: 15 },
          "Mã vị trí phải nhỏ hơn 15 kí tự!"
        )(value as string);
      },
      positionName: (value?: string | null) => {
        if (value) {
          return hasLength(
            { max: 250 },
            "Tên vị trí phải nhỏ hơn 250 kí tự!"
          )(value as string);
        }
        return "Vui lòng nhập tên vị trí!";
      },
    },
  });

  const handleCreateTblDMSaleChannel = async (
    dataSubmit: InventoryPosition
  ) => {
    open();
    const dataApi = await repositoryDelivery.post<
      MessageResponse<InventoryPosition>
    >("/api/v1/TblDmItemPosition/create", dataSubmit);
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit((e: InventoryPosition) => {
        handleCreateTblDMSaleChannel(e);
      })}
      style={{ position: "relative", width: "350px", height: "auto" }}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Grid mt={10}>
        {/* <Grid.Col span={12}>
          <TextInput
            label={"Kho tổng"}
            placeholder={"Mã kho tổng"}
            type="text"
            withAsterisk
            {...form.getInputProps("invCode")}
          />
        </Grid.Col> */}
        <Grid.Col span={12}>
          <Select
            label="Kho"
            placeholder="Chọn kho"
            withAsterisk
            data={listData}
            {...form.getInputProps("subInvId")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            label={"Mã vị trí"}
            placeholder={"Nhập mã vị trí"}
            type="text"
            withAsterisk
            {...form.getInputProps("positionCode")}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <TextInput
            label={"Tên vị trí"}
            placeholder={"Nhập tên vị trí"}
            type="text"
            withAsterisk
            {...form.getInputProps("positionName")}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Checkbox label="Hoạt động" {...form.getInputProps("active")} />
        </Grid.Col>
      </Grid>

      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
        >
          Đóng
        </Button>
        <Button type="submit" loading={visible}>
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default CreateWarehouseLocationList;
