import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Select,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconEdit,
  IconHandStop,
  IconPlus,
  IconSearch,
  IconUser,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { paginationBase } from "../../_base/model/_base/BaseTable";
import { _localization } from "../../config/location";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { repositoryPos } from "../../_base/_const/_constVar";
import { IconTrash } from "@tabler/icons-react";
import DeleteDepartmen from "./DeleteDepartmen";
import CreateDepartmen from "./CreateDepartmen";
import EditDepartmen from "./EditDepartmen";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../model/MessageResponse";
const DepartmentsList = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [data, setData] = useState<Department[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [dataDepartmen, setdataDepartmen] = useState<DataItem[]>([]);
  const [search, setSearch] = useState({
    branchId: 0,
  });
  type Department = {
    departmentId: number;
    name: string;
    description: string | null;
    active: boolean;
    deparmentCode: string;
    creationDate: string | null;
    createdBy: string | null;
    lastUpdateDate: string | null;
    lastUpdatedBy: string | null;
    address: string;
    branchId: number;
  };
  type DataItem = {
    att1: string | null;
    att2: string | null;
    att3: number;
    att4: number;
    att5: string | null;
    disabled: boolean;
    group: string | null;
    selected: boolean;
    text: string;
    value: string;
  };

  const handleChangeSearchValue = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };
  const activesData = async (status: boolean, ids: string[]) => {
    if (ids.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryPos.post<MessageResponse<boolean>>(
          `/api/v1/TblDepartment/actives`,
          {
            ids,
            status,
          }
        );
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          setDeleteViewStatus((prev: any) => !prev);
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "deparmentCode",
        header: "Mã bộ phận",
        size: 50,
      },
      {
        accessorKey: "name",
        header: "Tên bộ phận",
        size: 30,
      },
      {
        accessorKey: "address",
        header: "Địa Chỉ",
        size: 30,
      },

      {
        accessorKey: "description",
        header: "Mô tả",
        size: 30,
      },
      {
        accessorKey: "active",
        header: "Trạng thái",
        size: 10,
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor: cell.getValue() ? "#12B886" : "#FA5252",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
              textAlign: "center",
            }}
          >
            {cell.getValue() ? "Hoạt động" : "Không hoạt động"}
          </div>
        ),
      },
      {
        accessorKey: "branchId",
        header: "Chi nhánh",
        size: 30,
        Cell: ({ cell }) => {
          const branchId = cell.getValue() as any;
          const subInventory = dataDepartmen.find(
            (item) => Number(item.value) === branchId
          );
          return subInventory ? subInventory.text : "Không tìm thấy";
        },
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 20,

        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {processTaskActionMenu(row)}
          </Box>
        ),
      },
    ],
    [dataDepartmen]
  );

  function processTaskActionMenu(row: MRT_Row<any>): any {
    return (
      <>
        <Tooltip label="Sửa">
          <ActionIcon variant="light" aria-label="Settings" color="yellow">
            <IconEdit size={20} onClick={() => handleEdit(row?.id)} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xóa">
          <ActionIcon variant="light" aria-label="Settings" color="red">
            <IconTrash size={20} onClick={() => handleDelete([row?.id])} />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          label={row.original.active === true ? "Dừng hoạt động" : "Hoạt động"}
        >
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color={row.original.active === true ? "violet" : "green"}
            onClick={async () => {
              await activesData(!row.original.active, [row.id]);
            }}
          >
            {row.original.active === true ? (
              <IconHandStop size={20} />
            ) : (
              <IconActivity size={20} />
            )}
          </ActionIcon>
        </Tooltip>
      </>
    );
  }
  const handleDelete = (ids: (number | string)[]) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xóa bảng vị trí kho</Title>
        </>
      ),
      children: <DeleteDepartmen idItems={ids} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  const handleEdit = (id: number | string) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa</Title>
        </>
      ),
      size: "auto",
      children: <EditDepartmen id={id} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  const handleAdd = () => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Tạo mới</Title>
        </>
      ),
      size: "auto",
      children: <CreateDepartmen onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const DataDepartmen = async () => {
    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmInventory/get-select-branch`
      );
      if (response && response.success) {
        setdataDepartmen(response.data);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    DataDepartmen();
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    // let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
    //   pagination.pageSize
    // }`;

    // if (search.keySearch) {
    //   url += `&KeySearch=${search.keySearch.trim()}`;
    // }

    // if (search.status) {
    //   url += `&Active=${search.status}`;
    // }
    let url = `?branchId=${search.branchId}`;
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDepartment/get-all${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  const table = useMantineReactTable({
    columns,
    data,
    onRowSelectionChange: setRowSelection,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      //sorting,
      rowSelection,
    },

    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Input
            style={{ flex: 1, maxWidth: "180px" }}
            placeholder="Nhập tìm kiếm"
            type="text"
            leftSection={<IconSearch size={"20"} />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "branchId");
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                fetchData();
              }
            }}
          />
          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconActivity size={20} />}
            color="green"
            variant="outline"
            disabled={selectedIds.length < 1}
            onClick={() => activesData(true, selectedIds)}
          >
            Hoạt động
          </Button>
          <Button
            leftSection={<IconHandStop size={20} />}
            color="red"
            variant="outline"
            disabled={selectedIds.length < 1}
            onClick={() => activesData(false, selectedIds)}
          >
            Dừng hoạt động
          </Button>
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={() => handleAdd()}
          >
            Thêm
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableRowSelection: true,
    localization: _localization,
    getRowId: (row) => row.departmentId,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "deparmentCode"],
        right: ["action"],
      },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    if (valuesList.length < 1) setSelectedIds([]);
    else setSelectedIds(valuesList);
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (210 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DepartmentsList;
