import React from "react";
import {Center, Table} from "@mantine/core";

interface TableHeader {
    label: string;
    style: React.CSSProperties;
}

interface TableCell {
    content: React.ReactNode;
    colSpan?: number;
}

interface RenderTableProps {
    headers: TableHeader[];
    rows: TableCell[][];
}

const RenderTable: React.FC<RenderTableProps> = ({headers, rows}) => (
    <Table striped horizontalSpacing="sm" withColumnBorders>
        <Table.Thead>
            <Table.Tr>
                {headers.map(({label, style}, index) => (
                    <Table.Th key={index} style={style}>
                        <Center>{label}</Center>
                    </Table.Th>
                ))}
            </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            {rows?.map((cells, rowIndex) => (
                <Table.Tr key={rowIndex}>
                    {cells?.map(({content, colSpan = 1}, cellIndex) => (
                        <Table.Th key={cellIndex} colSpan={colSpan}>
                            {content}
                        </Table.Th>
                    ))}
                </Table.Tr>
            ))}
        </Table.Tbody>
    </Table>
);

export default RenderTable;
