import {
  Box,
  Button,
  Checkbox,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  TextInput,
  Menu,
  ScrollArea,
  Table,
  Text,
  NumberInput,
  Select,
  ComboboxItem,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconPlus, IconArrowLeft } from "@tabler/icons-react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import {
  TblInventoryCycleCount,
  TblInventoryCycleCountDetailCommand,
} from "../../../../model/TblInventoryCycleCount";
import { TblInventorySerialOnHand } from "../../../../model/TblInventorySerialOnHand";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { DatePickerInput } from "@mantine/dates";
import { useEffect, useRef, useState } from "react";
import cx from "clsx";
import classes from "../../../../Styles/TableScrollArea.module.css";
import ModalICCP from "./ModalICCP";
import { getTblDmEmployeeSelect } from "../../../../service/getSelectApi";
import React from "react";
import { anyAsciiCode } from "../../../../_base/helper/AnyAscii";

const CreateCycleCount = ({ handleCreate }: { handleCreate: any }) => {
  const [ICCP, setICCP] = useState("");
  const [serial, setSerial] = useState<string>("");
  const [dataSerialOnHand, setDataSerialOnHand] = useState<
    TblInventorySerialOnHand[]
  >([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [code, setCode] = useState<string>("");
  console.log(code);
  const [subInvCode, setSubInvCode] = useState<string>("");
  const [
    tblInventoryCycleCountDetailCommands,
    setTblInventoryCycleCountDetailCommands,
  ] = useState<TblInventoryCycleCountDetailCommand[]>([]);
  const [isEditQuantity, setIsEditQuantity] = useState(false);
  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [scrolled1, setScrolled1] = useState(false);
  const [checked, setChecked] = useState(true);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const scrollAreaRef1 = useRef<HTMLDivElement | null>(null);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<any[]>([]);

  const entity = {
    id: "0",
    code: null,
    cycleCountPeriodId: null,
    cycleCountDate: new Date().toISOString(),
    employeeId: null,
    isCustomerInv: null,
    status: null,
    autoDetectSubInvOrg: checked,
    note: null,
    subInvCode: null,
    tblInventoryCycleCountDetailCommands: null,
  };

  const form = useForm<TblInventoryCycleCount>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      employeeId: Number(values.employeeId),
      cycleCountPeriodId: Number(values.cycleCountPeriodId),
    }),

    validate: {
      employeeId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập người kiểm kê !";
        }
      },
      cycleCountPeriodId: (value: number | string | null) => {
        if (!value) {
          return "Vui lòng nhập đợt kiểm kê !";
        }
      },
      note: (value: string | null) => {
        if (value) {
          return hasLength(
            { max: 500 },
            "Ghi chú không được dài hơn 500 ký tự !"
          )(value as string);
        }
      },
    },
  });

  const handleCreateTblInventoryCycleCount = async (
    dataSubmit: TblInventoryCycleCount
  ) => {
    open();
    const dataApi = await repositoryMdm.post<
      MessageResponse<TblInventoryCycleCount>
    >("/api/v1/TblInventoryCycleCount/create", dataSubmit);
    if (dataApi?.success) {
      NotificationExtension.Success("Thêm thành công !");
      handleCreate();
    }
    close();
  };

  const getCreate = async () => {
    const dataApi = await repositoryMdm.get<
      MessageResponse<TblInventoryCycleCount>
    >("api/v1/TblInventoryCycleCount/create?prefix=PKK-");

    if (dataApi?.success) {
      const data = dataApi.data;
      setCode(data.code ?? "");
    }
  };

  const fetchDataSerialOnHand = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    let url = `?serial=${serial}`;

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInventorySerialOnHand[] | TblInventorySerialOnHand>
      >(`api/v1/TblInventorySerialOnHand/detail-for-cycle-count${url}`);

      if (response && response.success) {
        const responseData = response.data as
          | TblInventorySerialOnHand[]
          | TblInventorySerialOnHand;

        if (Array.isArray(responseData)) {
          setDataSerialOnHand((prevData) => [...prevData, ...responseData]);
        } else if (responseData) {
          console.log(responseData);
          setDataSerialOnHand((prevData) => [
            ...prevData,
            { ...responseData, quantity: 1 },
          ]);
        }
      } else {
        setDataSerialOnHand((prevData) => [...prevData]);
      }
    } catch (error) {
      setDataSerialOnHand([]);
      setIsError(true);
      console.error(error);
      return;
    }

    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const fetchDataEmployee = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const handleAddProduct = () => {
    if (checkDuplicateCode.length === 0) {
      setCheckDuplicateCode((prev) => [...prev, serial]);
      fetchDataSerialOnHand();
      setSerial("");
    } else if (checkDuplicateCode.length > 0) {
      if (checkDuplicateCode.includes(serial)) {
        NotificationExtension.Fails("Mã Code đã được quét !");
      } else {
        setCheckDuplicateCode((prev) => [...prev, serial]);
        fetchDataSerialOnHand();
        setSerial("");
      }
    }
  };

  const handleDeleteProduct = (index: number) => {
    setDataSerialOnHand((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleQuantityChange = (value: number, index: number) => {
    setDataSerialOnHand((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, quantity: value } : item
      )
    );
  };

  const groupedData = dataSerialOnHand.reduce((acc, item) => {
    if (!acc[item.invCode ?? ""]) {
      acc[item.invCode ?? ""] = [];
    }
    acc[item.invCode ?? ""].push(item);
    return acc;
  }, {} as { [key: string]: TblInventorySerialOnHand[] });

  const rows = Object.entries(groupedData).flatMap(
    ([invCode, items], index) => {
      return items.map((item, itemIndex) => ({
        ...item,
        isFirstInGroup: itemIndex === 0,
        rowSpan: items.length,
      }));
    }
  );

  useEffect(() => {
    form.setValues((prev) => ({ ...prev, code: code }));
  }, [code]);

  useEffect(() => {
    Promise.all([fetchDataEmployee(), getCreate()]);
  }, []);

  useEffect(() => {
    form.setValues((prev) => ({ ...prev, subInvCode: subInvCode }));
    form.setValues((prev) => ({
      ...prev,
      tblInventoryCycleCountDetailCommands:
        tblInventoryCycleCountDetailCommands,
    }));
    form.setValues((prev) => ({ ...prev, cycleCountPeriodId: Number(ICCP) }));
  }, [subInvCode, tblInventoryCycleCountDetailCommands, ICCP]);

  useEffect(() => {
    const updateSubInvCode = () => {
      const uniqueSubInvCodes = Array.from(
        new Set(
          dataSerialOnHand
            .map((item) => item.invCode)
            .filter((code) => code !== null)
        )
      );
      const joinedSubInvCodes = uniqueSubInvCodes.join(",");
      setSubInvCode(joinedSubInvCodes);
    };

    updateSubInvCode();
  }, [dataSerialOnHand]);

  useEffect(() => {
    const updateCycleCountDetails = () => {
      const updatedDetails = dataSerialOnHand.map((item) => ({
        id: "0",
        serialCode: item.serialCode,
        serialStatus: item.serialStatus,
        quantity: item.quantity,
        note: item.note,
      }));

      setTblInventoryCycleCountDetailCommands(updatedDetails);
    };

    updateCycleCountDetails();
  }, [dataSerialOnHand]);

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        containerRef.current.style.height = `${window.innerHeight - 180}px`;
      }
      if (scrollAreaRef1.current) {
        const height = window.innerHeight - 480;
        scrollAreaRef1.current.style.height = `${height}px`;
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div ref={containerRef}>
      <Box
        mt={10}
        component="form"
        onSubmit={form.onSubmit((e: TblInventoryCycleCount) => {
          handleCreateTblInventoryCycleCount(e);
        })}
        onMouseDown={() => setIsEditQuantity(false)}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Group
          justify="end"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="red"
            loading={visible}
            onClick={handleCreate}
            leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
          >
            Quay lại
          </Button>
          <Button
            type="submit"
            color="teal"
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Xác nhận
          </Button>
        </Group>
        <Fieldset legend="Thông tin phiếu kiểm kê">
          <Grid>
            <Grid.Col span={{ base: 12, md: 3, lg: 1.75 }}>
              <TextInput
                label="Số phiếu"
                placeholder="Nhập số phiếu"
                size="xs"
                variant="filled"
                value={code}
                onChange={(e) => setCode(e.currentTarget.value)}
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 3, lg: 1.75 }}>
              <DatePickerInput
                label="Ngày kiểm kê"
                valueFormat="DD/MM/YYYY"
                variant="filled"
                size="xs"
                value={new Date()}
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 3, lg: 1.75 }}>
              <Select
                label="Người kiểm"
                placeholder="Nhập tên người kiểm"
                data={dataEmployeeSelect}
                searchable
                clearable
                size="xs"
                withAsterisk
                {...form.getInputProps("employeeId")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 3, lg: 1.75 }}>
              <Menu
                trigger="hover"
                openDelay={100}
                closeDelay={400}
                width={550}
              >
                <Menu.Target>
                  <TextInput
                    label="Đợt kiểm kê"
                    placeholder="Nhập đợt kiểm kê"
                    type="number"
                    {...form.getInputProps("employeeId")}
                    value={ICCP ?? ""}
                    onChange={(event) => {
                      setICCP(event.currentTarget.value);
                    }}
                    withAsterisk
                    size="xs"
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <ModalICCP setICCP={setICCP} ICCP={ICCP} />
                </Menu.Dropdown>
              </Menu>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 3, lg: 3.5 }}>
              <TextInput
                label="Diễn giải"
                placeholder="Nhập diễn giải"
                size="xs"
                {...form.getInputProps("note")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 3, lg: 1.5 }}>
              <Checkbox
                label="Kho khách"
                size="xs"
                mt={27.5}
                {...form.getInputProps("isCustomerInv")}
              />
            </Grid.Col>
          </Grid>
        </Fieldset>
        <Fieldset legend="Mã vạch" mt={5}>
          <Flex align="center" gap={"md"}>
            <TextInput
              placeholder="Nhập mã vạch"
              size="xs"
              w={"87.5%"}
              value={anyAsciiCode(serial)}
              onChange={(e) => setSerial(e.currentTarget.value)}
            />
            <Button
              leftSection={<IconPlus size={14} />}
              size="xs"
              w={"11%"}
              onClick={handleAddProduct}
              disabled={serial?.length < 10}
            >
              Thêm hàng
            </Button>
          </Flex>
          <Checkbox
            mt={8}
            label="Tự động nhận diện kho kiểm kê (Bỏ chọn khi cần kiểm kê thừa serial)"
            size="xs"
            checked={checked}
            onChange={(event) => {
              setChecked(event.currentTarget.checked);
            }}
          />
        </Fieldset>
        <Fieldset legend="Danh sách mã vạch trong kho" mt={5}>
          <ScrollArea
            ref={scrollAreaRef1}
            onScrollPositionChange={({ y }) => setScrolled1(y !== 0)}
          >
            <Table striped highlightOnHover withTableBorder withColumnBorders>
              <Table.Thead
                className={cx(classes.header, {
                  [classes.scrolled1]: scrolled1,
                })}
              >
                <Table.Tr>
                  <Table.Th>Mã vạch</Table.Th>
                  <Table.Th>Mã kho</Table.Th>
                  <Table.Th>Mã sản phẩm</Table.Th>
                  <Table.Th>Tên sản phẩm</Table.Th>
                  <Table.Th>Số lượng kiểm kê</Table.Th>
                  <Table.Th>Trạng thái serial</Table.Th>
                  <Table.Th>Ghi chú</Table.Th>
                  <Table.Th>Thao tác</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {Array.isArray(rows) &&
                  rows?.map((item, index) => (
                    <Table.Tr key={index}>
                      <Table.Th>{item.serialCode}</Table.Th>
                      {item.isFirstInGroup ? (
                        <Table.Th rowSpan={item.rowSpan}>
                          {item.invCode}
                        </Table.Th>
                      ) : (
                        <></>
                      )}
                      <Table.Th>{item.serialCode}</Table.Th>
                      <Table.Th>{item.itemName}</Table.Th>
                      <Table.Th
                        onDoubleClick={() => {
                          setIsEditQuantity(true);
                          setEditIndex(index);
                        }}
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                      >
                        {editIndex === index && isEditQuantity ? (
                          <NumberInput
                            value={item.quantity ?? 1}
                            onChange={(value: string | number) =>
                              handleQuantityChange(Number(value) ?? 1, index)
                            }
                            size="xs"
                            w={95}
                            hideControls
                          />
                        ) : (
                          <Text fw={700} size="13px">
                            {item.quantity}
                          </Text>
                        )}
                      </Table.Th>
                      <Table.Th>{item.serialStatus}</Table.Th>
                      <Table.Th>{item.note}</Table.Th>
                      <Table.Th>
                        <Button
                          size="xs"
                          variant="filled"
                          color="red"
                          onClick={() => handleDeleteProduct(index)}
                        >
                          Xoá
                        </Button>
                      </Table.Th>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        </Fieldset>
        {/* <Fieldset legend="Danh sách mã vạch không tìm thấy" mt={5}>
          <ScrollArea
            h={90}
            onScrollPositionChange={({ y }) => setScrolled2(y !== 0)}
          >
            <Table striped highlightOnHover withTableBorder withColumnBorders>
              <Table.Thead
                className={cx(classes.header, {
                  [classes.scrolled2]: scrolled2,
                })}
              >
                <Table.Tr>
                  <Table.Th>Mã vạch</Table.Th>
                  <Table.Th>Mã kho</Table.Th>
                  <Table.Th>Mã sản phẩm</Table.Th>
                  <Table.Th>Tên sản phẩm</Table.Th>
                  <Table.Th>Số lượng kiểm kê</Table.Th>
                  <Table.Th>Trạng thái serial</Table.Th>
                  <Table.Th>Ghi chú</Table.Th>
                  <Table.Th>Thao tác</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody></Table.Tbody>
            </Table>
          </ScrollArea>
        </Fieldset> */}
      </Box>
    </div>
  );
};

export default CreateCycleCount;
