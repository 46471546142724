import {
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  ScrollArea,
  Select,
  Table,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { TblWarrantyReceiveItem } from "../../../model/TblWarrantyReceiveItem";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { useEffect, useState } from "react";
import { DatePickerInput } from "@mantine/dates";
import {
  getTblDmEmployeeSelect,
  getTblDMInventory,
} from "../../../service/getSelectApi";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { modals } from "@mantine/modals";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { sky_blue } from "../../../const/variables";

const CreateDataView = ({ onClose }: { onClose: any }) => {
  const entity = {
    id: "0",
    empId: null,
    inventoryReceiveId: null,
    receiveNum: null,
    inventoryDeliveryId: null,
    note: null,
    receiveTicketId: null,
    tblWarrantyReceiveItemDetailsCommand: null,
  };

  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataInventorySelect, setDataInventorySelect] = useState<
    ComboboxItem[]
  >([]);

  const [scrolled, setScrolled] = useState(false);
  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<TblWarrantyReceiveItem>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {},
  });

  const getCreate = async () => {
    const res = await repositoryMdm.get<
      MessageResponse<TblWarrantyReceiveItem>
    >(`/api/v1/TblWarrantyReceiveItem/create?prefix=PNKHL-`);

    if (res && res?.success) {
      form.setValues((prev) => ({ ...prev, receiveNum: res.data.receiveNum }));
      await Promise.all([getDataEmployeeSelect(), getDataInventorySelect()]);
    }
  };

  const getDataInventorySelect = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmInventory/get-all?checkAuth=false&isAll=true&select=false"
    );

    if (res && res?.success) {
      setDataInventorySelect(
        res.data
          ?.filter(
            (item: any) =>
              item.id != null && item.name != null && item.id !== -1
          )
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.name,
          }))
      );
    }
  };

  const getDataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    getCreate();
  }, []);

  return (
    <Box
      component="form"
      mx="auto"
      w={"82.5vw"}
      maw={1350}
      //   onSubmit={form.onSubmit((e: ProcessingAssignment) => {
      //     handleAssignRemoteProcessing(e);
      //   })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Fieldset legend="Thông tin phiếu" p={"0px 15px 10px 15px"} mt={10}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Số phiếu nhập kho"
              {...form.getInputProps("receiveNum")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <DatePickerInput
              label="Ngày lập phiếu"
              valueFormat="DD-MM-YYYY"
              value={new Date()}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Người lập"
              value={
                JSON.parse(localStorage.getItem("userLogin") || "{}")
                  ?.fullName || ""
              }
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Nơi nhập"
              placeholder="Chi nhánh của tài khoản đăng nhập"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Select
              label="Nơi giao"
              placeholder="Nhập tên chi nhánh"
              data={dataInventorySelect}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Select
              label="Nhân viên giao"
              placeholder="Nhập tên nhân viên giao"
              data={dataEmployeeSelect}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Ghi chú"
              placeholder="Nhập ghi chú"
              {...form.getInputProps("note")}
            />
          </Grid.Col>
        </Grid>
      </Fieldset>
      <Fieldset
        legend="Thông tin sản phẩm nhập kho"
        p={"0px 15px 10px 15px"}
        mt={10}
      >
        <Flex justify={"start"} gap={"md"} wrap={"wrap"}>
          <TextInput
            label="Số phiếu tiếp nhận hàng lỗi"
            placeholder="Số phiếu tiếp nhận hàng lỗi"
            variant="filled"
            readOnly
            w={200}
          />
          <DatePickerInput
            label="Ngày nhận"
            placeholder="Ngày nhận"
            valueFormat="DD-MM-YYYY"
            variant="filled"
            readOnly
            w={200}
          />
        </Flex>
        <ScrollArea
          h={285}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
          scrollbars="x"
          mt={10}
        >
          <Table striped highlightOnHover withTableBorder withColumnBorders>
            <Table.Thead
              className={cx(classes.header, {
                [classes.scrolled]: scrolled,
              })}
            >
              <Table.Tr>
                <Table.Th>Serial</Table.Th>
                <Table.Th>Serial gốc</Table.Th>
                <Table.Th>Mã sản phẩm</Table.Th>
                <Table.Th>Tên sản phẩm</Table.Th>
                <Table.Th>Kết quả kiểm tra</Table.Th>
                <Table.Th>Hình thức BH</Table.Th>
                <Table.Th>Ghi chú nhập kho</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody></Table.Tbody>
          </Table>
        </ScrollArea>
      </Fieldset>
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
        >
          Quay lại
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default CreateDataView;
