import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  FileInput,
  Grid,
  Group,
  LoadingOverlay,
  rem,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconCheck,
  IconEye,
  IconFileCv,
  IconWindow,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { MessageResponse } from "../../model/MessageResponse";
import { repositoryMaster, repositoryPos } from "../../_base/_const/_constVar";
import { _validateUserMasterModelValidator } from "../../validate/UserCreateModelValidator";
import { ListAuthozireModel } from "../../model/ListAuthozireModel";
import { getTblDmEmployeeSelect } from "../../service/getSelectApi";
import { cobalt_blue, gray, sky_blue } from "../../const/variables";
import { TblfileReportModel } from "../../model/Report/TblfileReportModel";

const AddOrUpdateFileReport = function ({
  onLoad,
  id,
  model,
}: {
  id: number;
  onLoad: () => Promise<void>;
  model?: TblfileReportModel;
}) {
  const entity: TblfileReportModel = {
    id: 0,
    router: "",
    file: undefined,
    createBy: "",
    modifyby: "",
    name: "",
    fileInput: undefined,
  };
  const form = useForm<TblfileReportModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    //  validate: _validateUserMasterModelValidator,
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);

  function convertUint8ArrayToBase64(uint8Array: Uint8Array): string {
    let binary = "";
    const len = uint8Array.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binary);
  }

  function convertFileToUint8Array(file: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          // Convert result (which is an ArrayBuffer) to Uint8Array
          const arrayBuffer = reader.result as ArrayBuffer;
          const uint8Array = new Uint8Array(arrayBuffer);
          resolve(uint8Array);
        } else {
          reject(new Error("Could not read file"));
        }
      };

      reader.onerror = () => {
        reject(new Error("File reading has failed"));
      };

      // Read file as ArrayBuffer
      reader.readAsArrayBuffer(file);
    });
  }

  useEffect(() => {
    if (model) form.setValues(model);
    form.resetDirty(model);
  }, []);


  const apiCreate = async (data: TblfileReportModel) => {
    open();
    data.id = id;
    if (data.fileInput) {
      data.file = convertUint8ArrayToBase64(
        await convertFileToUint8Array(data.fileInput)
      );
    }
    let urlCreate = `/api/v1/report/add-or-update-file`;
    const callapi = await repositoryPos.post(urlCreate, data);
    if (callapi)
      if (callapi?.success) {
        await onLoad();
        NotificationExtension.Success("Thêm mới thành công !");
        modals.closeAll();
      } else if (callapi != null)
        NotificationExtension.Fails("Thêm mới thất bại !");
    close();
  };
  return (
    <>
      <Box
        component="form"
        onSubmit={form.onSubmit(async (e: TblfileReportModel) => {
          await apiCreate(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Box className="flex-none" component="form" mx="auto">
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <TextInput
                label="Tên đầy đủ: "
                placeholder="Nhập..."
                withAsterisk
                {...form.getInputProps("name")}
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, xs: 6 }}>
              <TextInput
                label="Router: "
                placeholder="Nhập..."
                withAsterisk
                {...form.getInputProps("router")}
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, xs: 12 }}>
              <FileInput
                leftSection={
                  <IconFileCv
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={1.5}
                  />
                }
                label="Template"
                placeholder="Upload..."
                leftSectionPointerEvents="none"
                {...form.getInputProps("fileInput")}
              />
            </Grid.Col>
          </Grid>
        </Box>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default AddOrUpdateFileReport;
