import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Flex,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { useState } from "react";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";

const ProductWarrantyList = ({
  dataRequestFormSelectedDetail,
  warrantyReceiveTicketDetail,
  editAndSave,
  setEditAndSave,
  isEdit,
  setIsEdit,
  handleChangeValue,
  actionView,
  typeProduct,
}: {
  dataRequestFormSelectedDetail: any;
  warrantyReceiveTicketDetail: any;
  editAndSave: any;
  setEditAndSave: any;
  isEdit: any;
  setIsEdit: any;
  handleChangeValue: any;
  actionView?: any;
  typeProduct: any;
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  return (
    <>
      <Flex justify={"end"} gap={"md"}>
        <TextInput placeholder="Nhập mã barcode hoặc dùng máy quét" w={250} />
        <Button>Quét mã</Button>
      </Flex>
      <ScrollArea
        h={275}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        scrollbars="x"
        mt={10}
      >
        <Table
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
          w={2900}
        >
          <Table.Thead
            className={cx(classes.header, {
              [classes.scrolled]: scrolled,
            })}
          >
            <Table.Tr>
              <Table.Th>Hẹn trả hàng</Table.Th>
              <Table.Th>Số ngày hẹn trả</Table.Th>
              <Table.Th>Phụ kiện kèm theo</Table.Th>
              <Table.Th>Loại dịch vụ</Table.Th>
              <Table.Th>Hóa đơn</Table.Th>
              <Table.Th>Serial</Table.Th>
              <Table.Th>Sản phẩm</Table.Th>
              <Table.Th>Mã sản phẩm</Table.Th>
              <Table.Th>Đơn vị tính</Table.Th>
              <Table.Th>Số lượng</Table.Th>
              <Table.Th>Thời hạn bảo hành</Table.Th>
              <Table.Th>Ghi nhận lỗi ban đầu</Table.Th>
              <Table.Th>Trạng thái serial</Table.Th>
              <Table.Th>Nhà cung cấp</Table.Th>
              <Table.Th>Người mua</Table.Th>
              <Table.Th>Giá bán</Table.Th>
              <Table.Th>Giá hiện tại</Table.Th>
              <Table.Th>Ghi chú</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Array.isArray(dataRequestFormSelectedDetail) &&
              dataRequestFormSelectedDetail.length > 0 &&
              dataRequestFormSelectedDetail?.map((item: any, index: any) => (
                <Table.Tr key={index}>
                  {actionView === "DETAIL" ? (
                    <Table.Td w={150}>
                      <DatePickerInput
                        valueFormat="DD-MM-YYYY"
                        value={
                          item.scheduleTime ? new Date(item.scheduleTime) : null
                        }
                        variant="unstyled"
                      />
                    </Table.Td>
                  ) : (
                    <Table.Td w={150}>
                      <DatePickerInput
                        placeholder="Nhập ngày hẹn trả"
                        valueFormat="DD-MM-YYYY"
                        locale="vi"
                        value={
                          warrantyReceiveTicketDetail[index]?.scheduleTime
                            ? new Date(
                                warrantyReceiveTicketDetail[index]?.scheduleTime
                              )
                            : null
                        }
                        minDate={new Date()}
                        onChange={(e: any) => {
                          const selectedDate = dayjs(e);
                          const today = dayjs();
                          const daysDifference = selectedDate.diff(
                            today,
                            "day"
                          );
                          handleChangeValue(
                            "scheduleTime",
                            e ? new Date(e ?? "").toDateString() : null,
                            Number(index)
                          );
                          handleChangeValue(
                            "daysReturn",
                            daysDifference,
                            Number(index)
                          );
                        }}
                      />
                    </Table.Td>
                  )}
                  {actionView === "DETAIL" ? (
                    <Table.Td w={110}>{item.daysReturn}</Table.Td>
                  ) : (
                    <Table.Td w={110}>
                      <NumberInput
                        placeholder="Nhập số ngày hẹn trả hàng"
                        hideControls
                        value={
                          warrantyReceiveTicketDetail[index]?.daysReturn
                            ? warrantyReceiveTicketDetail[index]?.daysReturn
                            : null
                        }
                        onChange={(e) => {
                          handleChangeValue(
                            "daysReturn",
                            e ? Number(e) : null,
                            Number(index)
                          );
                          handleChangeValue(
                            "scheduleTime",
                            e
                              ? new Date(
                                  dayjs(new Date())
                                    .add(Number(e), "day")
                                    .toDate()
                                )
                              : null,
                            Number(index)
                          );
                        }}
                      />
                    </Table.Td>
                  )}
                  {actionView === "DETAIL" ? (
                    <Table.Td w={150}>{item.acessory}</Table.Td>
                  ) : (
                    <Table.Td
                      onMouseDown={(event: React.MouseEvent) =>
                        event.stopPropagation()
                      }
                      w={150}
                    >
                      <TextInput
                        placeholder="Nhập phụ kiện kèm theo"
                        value={warrantyReceiveTicketDetail[index]?.acessory}
                        onChange={(e: any) =>
                          handleChangeValue(
                            "acessory",
                            e ? e.currentTarget.value : null,
                            Number(index)
                          )
                        }
                      />
                    </Table.Td>
                  )}
                  {actionView === "DETAIL" ? (
                    <Table.Td w={150}>
                      <Text size="12.5px">
                        {warrantyReceiveTicketDetail[index]?.serviceType
                          ? warrantyReceiveTicketDetail[index]?.serviceType
                          : dataRequestFormSelectedDetail[
                              index
                            ]?.serviceType.toString()}
                      </Text>
                    </Table.Td>
                  ) : (
                    <Table.Td
                      onMouseDown={(event: React.MouseEvent) =>
                        event.stopPropagation()
                      }
                      w={150}
                    >
                      <Select
                        placeholder="Chọn loại dịch vụ"
                        data={[
                          { label: "Bảo hành", value: "Bảo hành" },
                          { label: "Hỗ trợ", value: "Hỗ trợ" },
                          { label: "Dịch vụ", value: "Dịch vụ" },
                        ]}
                        value={
                          warrantyReceiveTicketDetail[index]?.serviceType
                            ? warrantyReceiveTicketDetail[index]?.serviceType
                            : dataRequestFormSelectedDetail[
                                index
                              ]?.serviceType.toString()
                        }
                        onChange={(e: any) =>
                          handleChangeValue(
                            "serviceType",
                            e
                              ? e?.toString()
                              : dataRequestFormSelectedDetail[
                                  index
                                ]?.serviceType.toString(),
                            Number(index)
                          )
                        }
                      />
                    </Table.Td>
                  )}
                  <Table.Td w={125}>
                    {typeProduct === "HANG_HACOM"
                      ? item.orderCode
                      : "Không có hóa đơn"}
                  </Table.Td>
                  <Table.Td w={120}>{item.serial}</Table.Td>
                  <Table.Td w={300}>
                    <Box w={275}>
                      <Tooltip
                        label={
                          typeProduct === "HANG_HACOM"
                            ? item.itemName
                            : item.attribute2
                        }
                      >
                        <Text truncate="end" size="12.5px" h={15}>
                          {typeProduct === "HANG_HACOM"
                            ? item.itemName
                            : item.attribute2}
                        </Text>
                      </Tooltip>
                    </Box>
                  </Table.Td>
                  <Table.Td w={100}>{item.itemCode}</Table.Td>
                  <Table.Td w={100}>
                    {typeProduct === "HANG_HACOM"
                      ? item.uomName
                      : item.attribute3}
                  </Table.Td>
                  <Table.Td w={70}>1</Table.Td>
                  <Table.Td w={125}>{item.warrantyTerm} tháng</Table.Td>
                  <Table.Td w={200}>{item.conclusionError}</Table.Td>
                  <Table.Td w={150}>{item.serialStatus}</Table.Td>
                  <Table.Td w={150}>{item.supplierNum}</Table.Td>
                  <Table.Td w={150}>{item.customerOrderName}</Table.Td>
                  <Table.Td w={120}>
                    {item.priceOnPriceList?.toLocaleString()} đ
                  </Table.Td>
                  <Table.Td w={120}>
                    {item.latestPrice?.toLocaleString()} đ
                  </Table.Td>
                  {actionView === "DETAIL" ? (
                    <Table.Td w={200}>{item.note}</Table.Td>
                  ) : (
                    <Table.Td
                      onDoubleClick={() => {
                        setIsEdit((prev: any) => ({
                          ...prev,
                          note: true,
                        }));
                        setEditIndex(index);
                      }}
                      onMouseDown={(event: React.MouseEvent) =>
                        event.stopPropagation()
                      }
                      w={200}
                    >
                      {editIndex === Number(index) && isEdit.note ? (
                        <TextInput
                          placeholder="Nhập ghi chú"
                          value={
                            warrantyReceiveTicketDetail[index]?.note
                              ? warrantyReceiveTicketDetail[index]?.note
                              : null
                          }
                          onChange={(e) => {
                            handleChangeValue(
                              "note",
                              e ? e.currentTarget.value : null,
                              Number(index)
                            );
                          }}
                        />
                      ) : (
                        <>{warrantyReceiveTicketDetail[index]?.note}</>
                      )}
                    </Table.Td>
                  )}
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </>
  );
};

export default ProductWarrantyList;
