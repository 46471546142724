export const getActionFromPath = (pathname: string): string => {
  switch (pathname) {
    case "/warehouse-config/create-warehouse-out-ward":
      return "CREATE";
    case "/warehouse-config/edit-warehouse-out-ward":
      return "EDIT";
    case "/warehouse-config/detail-warehouse-out-ward":
      return "DETAIL";
    default:
      return "";
  }
};
