import { Badge, Box, Button, Input, Select } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import {
  IconCalculator,
  IconCalendar,
  IconSearch,
  IconSwitch,
  IconTruckDelivery,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
const DeliveryPay = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<Department[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [search, setSearch] = useState({
    keySearch: "",
    startDate: "",
    endDate: "",
    deliveryParoll: "",
    distance: "",
    statusName: "",
  });
  const handleChangeSearchValue = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };
  function getColorStatus(text: any) {
    switch (text) {
      case "Đã phân công":
        return "teal";
      case "Đơn hàng giao chưa thành công do Khách hàng":
        return "red";
      default:
        return "yellow";
    }
  }
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "ngayCt",
        header: "Ngày chứng từ",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "maCt",
        header: "Mã chứng từ",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
        size: 220,
      },
      {
        accessorKey: "soCtGoc",
        header: "Chứng từ gốc",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "maKh",
        header: "Mã khách hàng",
        size: 30,
      },
      {
        accessorKey: "tenKh",
        header: "Tên khách hàng",
        size: 30,
      },
      {
        accessorKey: "nhomKh",
        header: "Nhóm khách hàng",
        size: 30,
      },
      {
        accessorKey: "telephoneNumber",
        header: "Số điện thoại",
      },
      {
        accessorKey: "maBp",
        header: "Mã bộ phận",
        size: 30,
      },
      {
        accessorKey: "tenBp",
        header: "Tên bộ phận",
      },
      {
        accessorKey: "soHd",
        header: "Số hợp đồng",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "ngayHd",
        header: "Ngày hóa đơn",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "dcNhan",
        header: "Địa chỉ nhận",
      },
      {
        accessorKey: "deliveryDeptCode",
        header: "Mã phòng giao hàng",
      },
      {
        accessorKey: "deliveryDept",
        header: "Phòng giao hàng",
      },
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
      },
      {
        accessorKey: "tenCn",
        header: "Tên Chi nhánh",
      },
      {
        accessorKey: "tenDuong",
        header: "Tên đường",
      },
      {
        accessorKey: "tenPhuong",
        header: "Tên phường",
      },
      {
        accessorKey: "tenQuan",
        header: "Tên quận",
      },
      {
        accessorKey: "sourceType",
        header: "Loại đơn",
        Cell: ({ cell }) => (
          <div
            style={{
              backgroundColor:
                cell.getValue() === 1
                  ? "#12B886"
                  : cell.getValue() === 3
                  ? "#FAB20D"
                  : cell.getValue() === 2
                  ? "blueviolet"
                  : "transparent",
              color: "white",
              borderRadius: "50px",
              padding: "5px",
              textAlign: "center",
              width: "100px",
            }}
          >
            {cell.getValue() === 1
              ? "Đơn hàng"
              : cell.getValue() === 3
              ? "Khác"
              : cell.getValue() === 2
              ? "Điều chuyển"
              : ""}
          </div>
        ),
      },
      {
        accessorKey: "dcGiao",
        header: "Địa chỉ giao hàng",
      },
      {
        accessorKey: "ghiChuGh",
        header: "Ghi chú yêu cầu giao hàng",
        size: 250,
      },
      {
        accessorKey: "ngayGh",
        header: "Ngày giao hàng",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "ttGh",
        header: "Trạng thái giao hàng",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(renderedCellValue)}>
              {renderedCellValue === "Đã phân công"
                ? "Đã phân công"
                : renderedCellValue ===
                  "Đơn hàng giao chưa thành công do Khách hàng"
                ? "Đơn hàng giao chưa thành công do Khách hàng"
                : "Hàng đã giao"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "tTienDh",
        header: "Tổng tiền đơn hàng",
      },

      {
        accessorKey: "ttDh",
        header: "Trạng thái đơn hàng",
      },

      {
        accessorKey: "trangThaiTt",
        header: "Trạng thái thanh toán",
        size: 250,
      },
      {
        accessorKey: "ttTienMat",
        header: "Thu tiền mặt",
      },
      {
        accessorKey: "ttChuyenKhoan",
        header: "Thu tiền chuyển khoản",
        size: 200,
      },
      {
        accessorKey: "tongTienTt",
        header: "Tổng tiền thanh toán",
        size: 200,
      },
      {
        accessorKey: "tgXuatKho",
        header: "Thời gian xuất kho",
      },
      {
        accessorKey: "maNguoiGh",
        header: "Mã người giao hàng",
      },
      {
        accessorKey: "tenNguoiGh",
        header: "Tên người giao hàng",
        Cell: ({ renderedCellValue }) => (
          <>
            <AvatarUtils
              value={renderedCellValue?.toString().split("-")[0] ?? ""}
            />
          </>
        ),
      },
      {
        accessorKey: "tinhLuong",
        header: "Tính lương",
      },
      {
        accessorKey: "nguoiLaiXe",
        header: "Người lái xe",
      },
      {
        accessorKey: "dienGiai",
        header: "Diễn giải",
      },
      {
        accessorKey: "distance",
        header: "Khoảng cách",
      },
      {
        accessorKey: "distanceTotal",
        header: "Khoảng cách tổng cộng",
        size: 200,
      },
    ],
    []
  );

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    if (search.startDate) {
      url += `&StartTime=${search.startDate}`;
    }
    if (search.deliveryParoll) {
      url += `&DeliveryParoll=${search.deliveryParoll}`;
    }
    if (search.distance) {
      url += `&Distance=${search.distance}`;
    }
    if (search.statusName) {
      url += `&StatusName=${search.statusName}`;
    }
    if (search.endDate) {
      url += `&EndTime=${search.endDate}`;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/ReportDelivery/employee-delivery-payroll${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["maCt", "soHd"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Input
            style={{ flex: 1, maxWidth: "180px" }}
            placeholder="Nhập từ khóa"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "keySearch");
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />
          <Input
            style={{ flex: 1, maxWidth: "180px" }}
            placeholder="Nhập khoảng cách"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "distance");
            }}
          />
          <DatePickerInput
            type="range"
            size="sm"
            placeholder="Từ ngày - Đến ngày"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            onChange={(e) => {
              handleChangeSearchValue(
                formatDateNotTimeZone(e[0]) ?? "",
                "startDate"
              );
              handleChangeSearchValue(
                formatDateNotTimeZone(e[1]) ?? "",
                "endDate"
              );
            }}
            leftSection={<IconCalendar color="#15aabf" />}
            w={250}
            clearable
          />
          <Select
            size="sm"
            placeholder="Bảng lương giao hàng"
            searchable
            clearable
            data={[
              { value: "true", label: "Có tính lương" },
              { value: "false", label: "Không tính lương" },
            ]}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconCalculator size={20} color="#15aabf" />}
            onChange={(value) =>
              handleChangeSearchValue(value, "deliveryParoll")
            }
          />
          <Select
            size="sm"
            placeholder="Tên trạng thái"
            searchable
            clearable
            data={[
              { value: "Đã phân công", label: "Đã phân công" },
              { value: "Hàng đã giao", label: "Hàng đã giao" },
              {
                value: "Đơn hàng giao chưa thành công do Khách hàng",
                label: "Đơn hàng giao chưa thành công do Khách hàng",
              },
            ]}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconSwitch size={20} color="#15aabf" />}
            onChange={(value) => handleChangeSearchValue(value, "statusName")}
          />
          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    fetchData();
  }, [pagination]);

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DeliveryPay;
type Department = {
  ngayCt: string;
  maCt: string;
  soCtGoc: string;
  maKh: string | null;
  tenKh: string | null;
  nhomKh: string | null;
  telephoneNumber: string | null;
  deptId: string | null;
  maBp: string | null;
  tenBp: string | null;
  soHd: string | null;
  ngayHd: string | null;
  dcNhan: string;
  deliveryDeptCode: string | null;
  deliveryDept: string | null;
  branchId: number;
  maCn: string;
  tenCn: string;
  tenDuong: string | null;
  tenPhuong: string | null;
  tenQuan: string | null;
  sourceType: number;
  dcGiao: string;
  ghiChuGh: string | null;
  ngayGh: string;
  ttGh: string;
  tTienDh: number | null;
  ttDh: string | null;
  trangThaiTt: string | null;
  ttTienMat: number | null;
  ttChuyenKhoan: number | null;
  tongTienTt: number | null;
  tgXuatKho: string;
  maNguoiGh: string;
  tenNguoiGh: string;
  tinhLuong: boolean;
  nguoiLaiXe: string | null;
  dienGiai: string | null;
  distance: number;
  distanceTotal: number;
};
