import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Menu,
  rem,
  Select,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import {
  IconCalendar,
  IconCaretDown,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { DatePickerInput } from "@mantine/dates";
import { TblDMBarcodeStatus } from "../../../model/BarcodeStatus";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { modals } from "@mantine/modals";
import { CreateItemView } from "./CreateItemView";
import { DeleteItemView } from "./DeleteItemView";
import { useDebouncedState, useDebouncedValue } from "@mantine/hooks";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { DetailItemView } from "./DetaiItemStatus";
import { EditItemView } from "./EditItemView";

export const BarcodeStatus = () => {
  //column definition
  const columns = useMemo<MRT_ColumnDef<TblDMBarcodeStatus>[]>(
    () => [
      {
        header: "Id",
        accessorKey: "id",
      },
      {
        accessorKey: "maTtmv",
        header: "Mã trạng thái mã vạch",
        Cell: ({ row }) => (
          <Badge
            size="lg"
            style={{
              borderRadius: "4px",
            }}
            variant="dot"
            color={getColorByStatus(!row.original.ksd)}
          >
            {row.original.maTtmv}
          </Badge>
        ),
      },
      {
        accessorKey: "tenTtmv",
        header: "Tên trạng thái mã vạch",
      },
      {
        accessorKey: "ksd",
        header: "Trạng thái sử dụng",
        Cell: ({ row }) => (
          <Badge color={getColorByStatus(!row.original.ksd)}>
            {row.original.ksd ? "Không sử dụng" : "Đang sử dụng"}
          </Badge>
        ),
      },
      {
        accessorKey: "isMacDinhNhapMua",
        header: "Mặc định nhập/mua",
        Cell: ({ row }) => (
          <Badge
            color={getColorByStatus(row.original.isMacDinhNhapMua ?? false)}
          >
            {row.original.isMacDinhNhapMua ? "Có" : "Không"}
          </Badge>
        ),
      },
      {
        accessorKey: "maCty",
        header: "Mã công ty",
      },
      {
        accessorKey: "action",
        header: "Hành động",
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={() => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={() => {
                  editItem(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  deleteItem([row.original.id]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],

    []
  );
  //state
  const headerRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMBarcodeStatus[]>([]);
  const [height, setHeight] = useState(0);
  //table
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [keySearch, setKeySearch] = useDebouncedState("", 200);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "maTtmv"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: true,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //func
  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phieu-trang-thai-ma-vach.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  function getColorByStatus(status: boolean) {
    return status ? "green" : "red";
  }
  //modal
  const createItem = () => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thêm mã trạng thái mã vạch!</Title>
        </>
      ),
      size: "400px",
      children: <CreateItemView onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const editItem = (id: string) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Sửa mã trạng thái mã vạch!</Title>
        </>
      ),
      size: "400px",
      children: <EditItemView id={id} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const detailItem = (id: string) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Sửa mã trạng thái mã vạch!</Title>
        </>
      ),
      size: "400px",
      children: <DetailItemView id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const deleteItem = (ids: string[]) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xóa mã trạng thái mã vạch!</Title>
        </>
      ),
      size: "auto",
      children: <DeleteItemView idItems={ids} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  // fetch data
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblDMBarcodeStatus[]>
      >(`/api/v1/BarcodeStatus/get-list${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(result.length);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  // useEffects
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (145 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
    // setData([
    //   {
    //     id: "12345-abcde",
    //     createDate: new Date("2023-10-01"),
    //     createBy: 101,
    //     lastUpdateDate: new Date("2023-10-15"),
    //     lastUpdateBy: 0,
    //     maTtmv: "TTMV001",
    //     tenTtmv: "Barcode System 1",
    //     ksd: true,
    //     isMacDinhNhapMua: false,
    //     maCty: "CTY01",
    //   },
    //   {
    //     id: "67890-fghij",
    //     createDate: new Date("2023-09-15"),
    //     createBy: 102,
    //     lastUpdateDate: new Date("2023-10-10"),
    //     lastUpdateBy: 1,
    //     maTtmv: "TTMV002",
    //     tenTtmv: "Barcode System 2",
    //     ksd: false,
    //     isMacDinhNhapMua: true,
    //     maCty: "CTY02",
    //   },
    //   {
    //     id: "54321-klmno",
    //     createDate: new Date("2023-08-20"),
    //     createBy: 103,
    //     lastUpdateDate: null, // Not updated yet
    //     lastUpdateBy: null,
    //     maTtmv: "TTMV003",
    //     tenTtmv: "Barcode System 3",
    //     ksd: true,
    //     isMacDinhNhapMua: null, // Not set
    //     maCty: "CTY03",
    //   },
    // ]);
  }, [pagination.pageSize, pagination.pageIndex, deleteViewStatus]);

  return (
    <>
      <div
        ref={headerRef}
        style={{
          width: "100%",
          border: "1px solid #dee2e6",
          padding: "10px",
        }}
      >
        <Flex
          w={"100%"}
          align={"center"}
          justify={"space-between"}
          direction="row"
          wrap="wrap"
          mb={"sm"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify="flex-end"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                createItem();
              }}
            >
              Thêm mới
            </Button>
            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                deleteItem(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa (đã chọn)
            </Button>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập thông tin..."
              onChange={(e) => setKeySearch(e.target.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
      <div>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
