import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Grid,
  GridCol,
  Input,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  IconBrandCodesandbox,
  IconBuildingWarehouse,
  IconMapPin,
  IconSearch,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { repositoryPos } from "../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { SelectListItemModel } from "../../../model/SelectListItem";
import { modals } from "@mantine/modals";
import ChildrenModal from "./ChildrenModal";

const ProductSeparation = () => {
  const navigate = useNavigate();
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  //chi nhánh
  const [dataBranchCode, setDataBranchCode] = useState<SelectListItemModel[]>(
    []
  );
  //Kho
  const [dataInventory, setDataInventory] = useState<SelectListItemModel[]>([]);

  // form search
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
    },
  });

  const [search, setSearch] = useState({
    Cnids: [], //chi nhánh
    KhoIds: [], //kho
  });
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }
    if (search.Cnids.length > 0) {
      url += `&BranchId=${search.Cnids}`;
    }
    if (search.KhoIds.length > 0) {
      url += `&SubInvId=${search.KhoIds}`;
    }
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblMfgProductOrder/get-list-item-split${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setRowCount(result.length);
        setData(result);
        table.resetRowSelection();
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    } finally {
      setIsRefetching(false);
      setIsLoading(false);
    }
  };

  // Lấy danh sách mã chi nhánh
  const DataBranchCode = async () => {
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDmInventory/get-select-branch?auth=true`
      );

      if (response && response.success) {
        const transformedData = response.data
          .filter(
            (branch: any) => branch.att2 !== null && branch.text !== "Tất cả"
          )
          .map((item: any) => ({
            value: item.value,
            label: item.att2 + " - " + item.text,
          }));
        setDataBranchCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  // Lấy danh sách kho
  const fetchDataKho = async () => {
    try {
      if (search.Cnids.length > 0) {
        const response = await repositoryPos.get(
          `/api/v1/TblDmInventory/get-select-by-branch?branchId=${search?.Cnids[0]}`
        );
        if (response && response.success) {
          const transformedData = response.data.map((item: any) => ({
            value: String(item.inventoryId),
            label: item.inventoryCode + " - " + item.inventoryName,
          }));
          setDataInventory(transformedData);
        }
      } else {
        const response = await repositoryPos.get(
          `/api/v1/TblDmInventory/get-all`
        );
        if (response && response.success) {
          const transformedData = response.data.map((item: any) => ({
            value: item.value,
            label: item.text,
          }));
          setDataInventory(transformedData);
        }
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "branchCode",
        header: "Mã chi nhánh",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "branchName",
        header: "Chi nhánh",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "subInvCode",
        header: "Mã kho",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "subInvName",
        header: " kho",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "dvt",
        header: "Đơn vị tính",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "serial",
        header: "Serial",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "serialStatus",
        header: "Trạng thái",
        size: 40,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "listSparepartSerial",
        header: "danh sách serial thành phần",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "trangThaiRaBo",
        header: "Trạng thái rã bộ",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Rã bộ">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="violet"
                onClick={() => onhandleRaBo(row.original.serial)}
              >
                <IconBrandCodesandbox size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const handleSusscess = async () => {
    await fetchData();
    modals.closeAll();
  };

  const onhandleRaBo = (serial: string) => {
    modals.openConfirmModal({
      title: "Rã bộ",
      children: (
        <ChildrenModal
          serial={serial}
          onSuccess={() => {
            handleSusscess();
          }}
        />
      ),
      size: "1500px",
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  const table = useMantineReactTable({
    columns,
    data: data ? data : [],
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id?.toString(),

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "borrowCode"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableColumnActions: false,
    enableSorting: false,
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 500,
        fontSize: "12.5px",
        padding: "5px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    fetchData();
  }, [pagination?.pageIndex, pagination?.pageSize]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (195 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Flex
        justify="space-between"
        align={"center"}
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <form
          onSubmit={formSeachInput.onSubmit(async () => await fetchData())}
          style={{ flex: 1 }}
        >
          <Grid mt={10}>
            <GridCol span={{ base: 6, sm: 4, md: 2 }}>
              <Input
                w={"100%"}
                placeholder="Từ khoá"
                type="text"
                leftSection={<IconSearch color="#15aabf" />}
                key={formSeachInput.key("keySearch")}
                {...formSeachInput.getInputProps("keySearch")}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    await fetchData();
                  }
                }}
              />
            </GridCol>
            <GridCol span={{ base: 6, sm: 4, md: 2 }}>
              <Select
                size="sm"
                placeholder="Chi nhánh"
                searchable
                clearable
                data={dataBranchCode}
                leftSection={<IconMapPin size={20} color="#15aabf" />}
                onClick={() => {
                  if (dataBranchCode.length === 0) {
                    DataBranchCode();
                  }
                }}
                onChange={(value) =>
                  handleChangeSearchValue(value ? [value] : null, "Cnids")
                } // Cập nhật Cnids
              />
            </GridCol>
            <GridCol span={{ base: 6, sm: 4, md: 2 }}>
              <Select
                size="sm"
                placeholder="Kho"
                searchable
                clearable
                leftSection={
                  <IconBuildingWarehouse size={20} color="#15aabf" />
                }
                // disabled={search.Cnids.length === 0}
                onClick={() => {
                  fetchDataKho();
                }}
                data={dataInventory}
                onChange={(value) =>
                  handleChangeSearchValue(value ? [value] : null, "KhoIds")
                } // Cập nhật KhoIds
              />
            </GridCol>
            <GridCol span={{ base: 4, sm: 2, md: 1 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                type="submit"
              >
                Tìm kiếm
              </Button>
            </GridCol>
          </Grid>
        </form>
      </Flex>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ProductSeparation;
