import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Fieldset,
  Flex,
  Grid,
  Group,
  Menu,
  NumberInput,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useClickOutside, useDebouncedState } from "@mantine/hooks";
import { IconArrowLeft, IconPlus, IconTrash } from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import { tblItem } from "../../../model/TblItem";
import {
  TblMfgBomDetailCommand,
  TblMfgBomHeader,
} from "../../../model/TblMfgBom";
import { modals } from "@mantine/modals";
import ModalChooseBom from "./ModalChooseBom";

interface TableSelectProps {
  // dataProduct: tblItem[];
  // onSelectProduct: (product: itemSearchTrasferModel) => void;
  rowIndex: any;
}

const CreateMfgBom = () => {
  const navigate = useNavigate();
  const localtion = useLocation();

  const formOrderHeader = () => {
    return (
      // <ScrollArea offsetScrollbars scrollbars="y" h={height + 77}>
      <Grid pl={10}>
        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.5 }}>
          {/* <TextInput
            label="Mã sản phẩm"
            placeholder="Nhập mã"
            size="xs"
            // disabled
            // value={dataMfgBom.itemCode || ""}
            // onChange={(e) =>
            //   handleChangeValueInput(e.currentTarget.value, "itemCode")
            // }
          /> */}
          <Menu
            trapFocus={false}
            trigger="hover"
            shadow="md"
            width={"800"}
            position="bottom-start"
          >
            <Menu.Target>
              <TextInput
                label="Mã sản phẩm"
                placeholder="Nhập mã"
                size="xs"
                value={keySearch || ""}
                onChange={async (e) => {
                  const _key = e.target.value ?? "";
                  setKeySearch(_key);
                }}
              />
            </Menu.Target>
            <Menu.Dropdown>
              <TableSelect />
            </Menu.Dropdown>
          </Menu>
        </Grid.Col>
        <Grid.Col span={{ base: 6, xs: 8, md: 4, lg: 3 }}>
          <TextInput
            label="Tên sản phẩm"
            size="xs"
            placeholder="Tên sản phẩm"
            disabled
            value={dataMfgBom.iteamNameHeader || ""}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.5 }}>
          <TextInput
            label="Đơn vị tính"
            size="xs"
            placeholder="UOM"
            disabled
            value={dataMfgBom.uomId || ""}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.5 }}>
          <TextInput
            label="Luân phiên"
            size="xs"
            disabled
            type="number"
            value={dataMfgBom.bomAlternate || 0}
            onChange={(e) =>
              handleChangeValueInput(e.currentTarget.value, "bomAlternate")
            }
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.5 }}>
          <DateTimePicker
            label="Ngày lập"
            size="xs"
            disabled
            value={dataMfgBom.bomDate ? new Date(dataMfgBom.bomDate) : null}
            onChange={(value) => {
              handleChangeValueInput(
                moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || "",
                "requestDate"
              );
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.5 }}>
          <Checkbox
            label={"Thực hiện"}
            mt={"lg"}
            checked={dataMfgBom.implementedOnly}
            onChange={(e) => {
              handleChangeValueInput(
                e.currentTarget.checked,
                "implementedOnly"
              );
            }}
          />
        </Grid.Col>
      </Grid>
      // </ScrollArea>
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const entityDataBom = {
    id: 0,
    iteamIdHeader: null,
    itemCode: null,
    iteamNameHeader: null,
    bomDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
    bomAlternate: 0,
    note: null,
    uomId: null,
    implementedOnly: false,
    tblMfgBomDetailCommands: [],
  };

  const [dataMfgBom, setDataMfgBom] = useState<TblMfgBomHeader>(entityDataBom);
  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [serial, setSerial] = useState<string>("");
  const [keySearch, setKeySearch] = useState("");
  const [searchItem, setSearchItem] = useDebouncedState("", 500);
  const [dataItem, setDataItem] = useState<tblItem[]>([]);
  const [dataProducts, setDataProducts] = useState<tblItem[]>([]);

  const [checkDuplicateCode, setCheckDuplicateCode] = useState<number[]>([]);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <_breadcrumb></_breadcrumb>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              size="xs"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
            <Button
              leftSection={<IconPlus size={14} />}
              variant="outline"
              color="blue"
              size="xs"
              onClick={() => {
                handleCreateBom();
              }}
            >
              Tạo mới
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} mah={250} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>

            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItem.map((item, index) => (
            <Table.Tr
              key={item.itemId}
              bg={
                checkDuplicateCode.some((select) => select === item.itemId)
                  ? "var(--mantine-color-blue-light)"
                  : undefined
              }
            >
              <Table.Td>
                <Button
                  size="xs"
                  variant="filled"
                  onClick={() => {
                    handleSelectItemHeader(item);
                  }}
                  disabled={checkDuplicateCode.includes(item.itemId || 0)}
                >
                  {checkDuplicateCode.includes(item.itemId || 0)
                    ? "Đã chọn"
                    : "Chọn"}
                </Button>
              </Table.Td>
              <Table.Td>{item.itemCode}</Table.Td>
              <Table.Td>
                <Tooltip label={item.itemName}>
                  <Text size="sm" w={250} lineClamp={2} truncate="end">
                    {item.itemName}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>
                {/* <Tooltip label={item.subInvName}>
                  <Text size="sm" w={125} truncate="end">
                    {item.subInvName}
                  </Text>
                </Tooltip> */}
              </Table.Td>
              <Table.Td>{item.primaryUomCodeId}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
        {dataItem.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  const TableSelectItem: React.FC<TableSelectProps> = ({
    // onSelectProduct,
    // dataProduct,
    rowIndex,
  }) => {
    return (
      <Table.ScrollContainer mah={250} minWidth={300} type="native">
        <Table striped withTableBorder highlightOnHover stickyHeader>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Chọn</Table.Th>
              {/* <Table.Th>ID</Table.Th> */}
              <Table.Th>Mã sản phẩm</Table.Th>
              <Table.Th>Tên sản phẩm</Table.Th>

              <Table.Th>Kho</Table.Th>
              <Table.Th>Đơn vị tính</Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {dataProducts?.map((product, index) => (
              <Table.Tr key={product.itemId}>
                <Table.Td>
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      handleSelectProduct(product, rowIndex);
                      table.setCreatingRow(null);
                    }}
                    disabled={selectedProducts.includes(product.itemId || 0)}
                  >
                    {selectedProducts.includes(product.itemId || 0)
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                </Table.Td>
                <Table.Td>{product.itemCode}</Table.Td>
                <Table.Td>
                  <Tooltip label={product.itemName}>
                    <Text size="sm" w={250} lineClamp={2} truncate="end">
                      {product.itemName}
                    </Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td>
                  {/* <Tooltip label={product.subInvName}>
                  <Text size="sm" w={125} truncate="end">
                    {product.subInvName}
                  </Text>
                </Tooltip> */}
                </Table.Td>
                <Table.Td>{product.primaryUomCodeId}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataProducts.length < 1 && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text>Hãy nhập ID hoặc mã sản phẩm!</Text>
            </Box>
          )}
        </Table>
      </Table.ScrollContainer>
    );
  };

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleChangeValueInput = (value: string | boolean, key: string) => {
    setDataMfgBom((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSelectItemHeader = (item: tblItem) => {
    setDataMfgBom((prevData) => ({
      ...prevData,
      iteamIdHeader: item.itemId,
      itemCode: item.itemCode,
      iteamNameHeader: item.itemName,
      uomId: item.primaryUomCodeId,
    }));
    setKeySearch(item.itemCode || "");
    setCheckDuplicateCode([item.itemId || 0]);
    if (item.itemId) {
      fetchDataCheckAlternate(item.itemId);
    }
  };

  const handleChooseBom = (dataBom: TblMfgBomHeader, count: number) => {
    const { tblMfgBomDetailModels, ...restData } = dataBom;
    const data = {
      ...restData,
      tblMfgBomDetailCommands: dataBom.tblMfgBomDetailModels,
      bomAlternate: count,
      bomDate: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
    };
    setDataMfgBom(data);
    modals.closeAll();
  };

  const handleSelectProduct = (item: tblItem, rowIndex: number) => {
    const addData = {
      id: 0,
      idBomHeader: null,
      itemId: item.itemId || 0,
      quantity: 1,
      uomId: item.primaryUomCodeId,
      operationSeqId: null,
      subinventoryId: null,
      itemCode: item.itemCode,
      itemName: item.itemName,
    };

    setDataMfgBom((prevList) => {
      const updatedList = [...(prevList.tblMfgBomDetailCommands || [])];

      const isProductExist = updatedList.some(
        (item, index) => index === rowIndex
      );

      setSelectedProducts((prevSelectedProducts) => {
        const newSelectedProducts = new Set(prevSelectedProducts);
        newSelectedProducts.delete(updatedList[rowIndex]?.itemId);
        newSelectedProducts.add(item.itemId || 0);
        return [...newSelectedProducts]; // Chuyển Set thành mảng bằng cách sử dụng toán tử spread
      });

      if (isProductExist) {
        updatedList[rowIndex] = addData;
      } else {
        updatedList.unshift(addData);
      }

      return { ...prevList, tblMfgBomDetailCommands: updatedList };
    });
  };

  const handleDeleteItem = (itemId: number) => {
    setDataMfgBom((prevData) => ({
      ...prevData,
      tblMfgBomDetailCommands: prevData.tblMfgBomDetailCommands?.filter(
        (item) => item.itemId !== itemId
      ),
    }));
    setSelectedProducts((prevData) =>
      prevData.filter((item) => item !== itemId)
    );
  };

  const handleCreateBom = async () => {
    try {
      const response = await repositoryMdm.post<MessageResponse<any>>(
        `/api/v1/TblMfgBomHeader/create`,
        dataMfgBom
      );
      if (response?.success) {
        NotificationExtension.Success(
          "Tạo định mức nguyên vật liệu thành công"
        );
        navigate("/config-system/config-bom");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModalChooseBom = (dataBoms: TblMfgBomHeader[]) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chọn định mức !</Title>
        </>
      ),

      size: "700px",
      children: (
        <ModalChooseBom
          dataBoms={dataBoms || []}
          handleChooseBom={handleChooseBom}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const fetchDataBom = async () => {
    const url = `/api/v1/TblMfgBomHeader/create`;
    try {
      const response = await repositoryMdm.get<MessageResponse<any>>(url);
      if (response?.success) {
      } else {
        NotificationExtension.Fails("Bạn không có quyền !");
        navigate(-1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataCheckAlternate = async (idItem: number) => {
    const url = `/api/v1/TblMfgBomHeader/Check?id=${idItem}`;
    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblMfgBomHeader[]>
      >(url);

      if (response && response.success) {
        if (response.data.length > 0) {
          openModalChooseBom(response.data);
        } else {
          setDataMfgBom((prevData) => ({
            ...prevData,
            id: 0,
            bomAlternate: 0,
            note: null,
            implementedOnly: false,
            tblMfgBomDetailCommands: [],
          }));
        }
      }
    } catch (error) {
      setDataItem([]);
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataItemHeader = async () => {
    const search = keySearch.toLowerCase();
    const url = `/api/v1/TblItem/get-list?Ship=0&Take=30&KeySearch=${search}`;
    try {
      const response = await repositoryPos.get<MessageResponse<tblItem[]>>(url);
      if (response?.success) {
        setDataItem(response.data);
      } else {
        setDataItem([]);
      }
    } catch (error) {
      setDataItem([]);
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataItem = async () => {
    const search = searchItem.toLowerCase();
    const url = `/api/v1/TblItem/get-list?Ship=0&Take=30&KeySearch=${search}`;
    try {
      const response = await repositoryPos.get<MessageResponse<tblItem[]>>(url);
      if (response?.success) {
        setDataProducts(response.data);
      } else {
        setDataProducts([]);
      }
    } catch (error) {
      setDataProducts([]);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItemHeader();
    } else setDataItem([]);
  }, [keySearch]);

  useEffect(() => {
    if (searchItem && searchItem.length > 2) {
      fetchDataItem();
    } else setDataProducts([]);
  }, [searchItem]);

  useEffect(() => {
    fetchDataBom();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (220 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<TblMfgBomDetailCommand>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
        Edit: ({ cell, row, table, column }) => {
          const [keepRef, setKeepRef] = useState(false);
          const ref = useClickOutside(() => {
            if (!keepRef) {
              table.setEditingCell(null);
            }
          });

          return (
            <div ref={ref}>
              <Menu
                trapFocus={false}
                trigger="hover"
                shadow="md"
                width={"800"}
                position="bottom-start"
              >
                <Menu.Target>
                  <TextInput
                    autoFocus
                    onChange={async (e) => {
                      const _key = e.target.value ?? "";
                      setSearchItem(_key);
                    }}
                    onFocus={() => setKeepRef(true)}
                    onBlur={() => setKeepRef(false)}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <TableSelectItem
                    rowIndex={row.index}
                    // dataProduct={dataProducts}
                  />
                </Menu.Dropdown>
              </Menu>
            </div>
          );
        },
      },
      {
        accessorKey: "itemName",
        header: "Tên hàng",
        enableEditing: false,
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "uomName",
        header: "Đơn vị tính",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        size: 100,
        Edit: ({ row, column, table }) => {
          return (
            <NumberInput
              // autoFocus
              placeholder="Nhập số lượng"
              hideControls
              // max={row.original.tonao}
              value={row._valuesCache[column.id]}
              onChange={(e) => {
                setDataMfgBom((prevData) => {
                  return {
                    ...prevData,
                    tblMfgBomDetailCommands:
                      prevData.tblMfgBomDetailCommands?.map((item, i) =>
                        i === row.index
                          ? {
                              ...item,
                              quantity: e ? Number(e) : null,
                            }
                          : item
                      ),
                  };
                });
              }}
              onBlur={() => {
                table.setEditingCell(null);
                if (
                  Number(row.original.quantity) < 1 ||
                  Number(row.original.quantity) === null
                ) {
                  NotificationExtension.Fails(
                    `Số lượng sản phẩm ${row.original.itemName} không được nhỏ hơn 1 !`
                  );
                }
              }}
            />
          );
        },
      },
      {
        accessorKey: "subinventoryId",
        header: "Kho",
        enableEditing: false,
        size: 100,
      },
    ],
    [dataProducts, selectedProducts]
  );

  const table = useMantineReactTable<TblMfgBomDetailCommand>({
    columns,
    data: dataMfgBom.tblMfgBomDetailCommands || [],
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: true,
    enableRowActions: true,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableBatchRowSelection: true,
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    positionActionsColumn: "last",

    renderRowActions: ({ row, table }) => (
      <Flex gap="4">
        {/* <Tooltip label="Thao tác">
          <ActionIcon
            variant="outline"
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <IconEdit />
          </ActionIcon>
        </Tooltip> */}
        <Tooltip label="Xoá sản phẩm">
          <ActionIcon variant="outline" color="red">
            <IconTrash onClick={() => handleDeleteItem(row.original.itemId)} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),

    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          // style={{ opacity: 0 }}
        >
          <Button
            leftSection={<IconPlus size={14} />}
            variant="outline"
            color="blue"
            size="xs"
            onClick={() => {
              table.setCreatingRow(true);
            }}
          >
            Thêm sản phẩm
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.itemId?.toString(),
    mantineTableContainerProps: {
      style: { maxHeight: height - 60, minHeight: height - 60 },
    },
    initialState: {
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },

    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualSorting: false,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    enablePagination: false,
    enableBottomToolbar: false,

    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,

      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>{titleSell()}</div>
      <Fieldset legend={"Thông tin chung"} p={5}>
        {formOrderHeader()}
      </Fieldset>
      <Fieldset legend={"Thông tin chi tiết"} pb={10}>
        <MantineReactTable table={table} />
      </Fieldset>
    </Box>
  );
};
export default CreateMfgBom;
