import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMSaleChannel } from "../../../../model/TblDMSaleChannel";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";

const CreateDataView = ({ onClose }: CreateDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    name: null,
    active: false,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMSaleChannel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      code: (value: any | null) => {
        const invalidChars =
          /[âăáàảãạêếềệễểíìịĩỉóòỏõọôốồộổỗơớờợỡởúùủũụưứừựữửýỳỷỹỵđ]/i;
        if (invalidChars.test(value)) {
          return "Mã kênh bán hàng chứa những kí tự không hợp lệ !";
        }
        if (!value) {
          return "Vui lòng nhập mã kênh bán hàng !";
        }
        return hasLength(
          { max: 30 },
          "Mã kênh bán hàng không được dài hơn 30 ký tự !"
        )(value as string);
      },
      name: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên kênh bán hàng !";
        }
        return hasLength(
          { max: 225, min: 5 },
          "Tên kênh bán hàng phải nằm trong khoảng từ 5 đến 225 ký tự !"
        )(value as string);
      },
    },
  });

  const handleCreateTblDMSaleChannel = async (dataSubmit: TblDMSaleChannel) => {
    open();
    const dataApi = await repositoryMdm.post<MessageResponse<TblDMSaleChannel>>(
      "/api/v1/TblDMSaleChanel/create",
      dataSubmit
    );
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit((e: TblDMSaleChannel) => {
        handleCreateTblDMSaleChannel(e);
      })}
      style={{ position: "relative" }}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <TextInput
            label={"Mã"}
            placeholder={"Nhập mã"}
            type="text"
            onKeyDown={handleKeyDown}
            withAsterisk
            {...form.getInputProps("code")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label={"Tên"}
            placeholder={"Nhập tên"}
            type="text"
            withAsterisk
            {...form.getInputProps("name")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Checkbox
            label={"Sử dụng"}
            {...form.getInputProps("active")}
            mt={27.5}
          />
        </Grid.Col>
      </Grid>

      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  onClose: any;
};
