import { useDisclosure } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { TblDMApproveProposal } from "../../../model/TblDMApproveProposal";
import { useForm } from "@mantine/form";
import "./customFileView.css";
import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Divider,
  Fieldset,
  Flex,
  Grid,
  InputLabel,
  LoadingOverlay,
  ScrollArea,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import {
  IconArrowLeft,
  IconDownload,
  IconEye,
  IconFile,
  IconTrash,
} from "@tabler/icons-react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { modals } from "@mantine/modals";
import { sky_blue } from "../../../const/variables";

export const DetailProposalView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const headerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [visible, { open, close }] = useDisclosure(false);
  const entity = {
    id: "",
    code: "",
    idDepeartment: 0,
    idWorkAddress: "",
    idPart: 0,
    idRole: 0,
    idEmployee: 0,
    note: "",
    urlFile: "Không có file",
    priorityLevel: 0,
    active: false,
    respond: "",
    createDate: new Date(),
    createBy: 0,
    lastUpdateBy: 0,
    lastUpdateDate: new Date(),
    nameDepeartment: "",
    nameEmployee: "",
    nameRole: "",
    nmaePart: "",
    idDepeartmentReceive: 0,
    idPartReceive: 0,
    idRoleReceive: 0,
    idEmployeeReceive: 0,
    nameDepeartmentReceive: "",
    namePartReceive: "",
    nameRoleReceive: "",
    nameEmployeeReceive: "",
    fileNameExtension: null,
  };
  const form = useForm<TblDMApproveProposal>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {},
  });
  // fetch
  const fetchData = async () => {
    open();
    try {
      const callApi = await repositoryMdm.get(
        "/api/v1/TblApproposal/detail?id=" + location?.state?.proposalId
      );
      if (callApi?.success) {
        const dataApi = callApi?.data;
        if (dataApi !== null) {
          form.setValues(dataApi);
          form.resetDirty(dataApi);
        }
      } else {
        NotificationExtension.Fails(
          "Không thể lấy dữ liệu chi tiết về đề xuất này!!!"
        );
        navigate("/proposal/approve-proposal");
      }
    } catch (error) {
      NotificationExtension.Fails(
        "Không thể lấy dữ liệu chi tiết về đề xuất này!!!"
      );
      navigate("/proposal/approve-proposal");
    } finally {
      close();
    }
  };

  function formatDate(isoDateString: string | Date, add?: boolean): string {
    const date: Date = new Date(isoDateString);

    // Cộng thêm 7 tiếng
    if (add) date.setHours(date.getHours() + 7);
    // Lấy các thành phần ngày giờ sau khi cộng thêm số giờ
    const hours: string = date.getHours().toString().padStart(2, "0");
    const minutes: string = date.getMinutes().toString().padStart(2, "0");
    const seconds: string = date.getSeconds().toString().padStart(2, "0");
    const day: string = date.getDate().toString().padStart(2, "0");
    const month: string = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0
    const year: number = date.getFullYear();

    // Định dạng thành chuỗi "giờ:phút:giây ngày-tháng-năm"
    return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
  }
  // useEffect
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (230 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);
  const openFile = () => {
    modals.openConfirmModal({
      size: "auto",
      title: <Title order={5}>{form.values.code}</Title>,
      fullScreen: true,
      children: (
        <DocViewer
          style={{
            width: "fit-content",
            height: "fit-content",
            maxHeight: "80vh",
            minWidth: "60vh",
          }}
          documents={[
            `https://files.hacom.vn/hacom/${form.values.urlFile}`,
          ].map((file) => ({
            uri: file,
          }))}
          config={{
            header: {
              disableHeader: true,
            },
            pdfZoom: {
              zoomJump: 0.1,
              defaultZoom: 2,
            },
          }}
          pluginRenderers={DocViewerRenderers}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  function getTextPriority(level: number) {
    switch (level) {
      case 0:
        return "Thấp";
      default:
        return "Cao";
    }
  }
  return (
    <>
      <Box component="div">
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex justify={"end"} style={{ position: "sticky" }} mb={10} gap={8}>
          <Button
            variant="outline"
            color={sky_blue.light}
            leftSection={<IconArrowLeft size={16} />}
            onClick={() => {
              navigate("/proposal/approve-proposal");
            }}
          >
            Trở lại
          </Button>
        </Flex>

        <Box p={15} bg={"#f8f8f8"}>
          <Grid gutter={"xs"}>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p={0}
                style={{
                  maxHeight: height,
                  minHeight: height,
                }}
              >
                <Badge variant="gradient" size="lg" m={12}>
                  Phiếu chi tiết đề xuất
                </Badge>
                <Table
                  striped
                  horizontalSpacing="sm"
                  withColumnBorders
                  withTableBorder
                >
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th w={"25%"}></Table.Th>
                      <Table.Th w={"25%"}>
                        <Center>
                          <Text fw={700}>Người lập</Text>
                        </Center>
                      </Table.Th>
                      <Table.Th w={"25%"}>
                        <Center>
                          <Text fw={700}>Người nhận</Text>
                        </Center>
                      </Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Thời gian tạo</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{formatDate(form.values.createDate)}</Text>
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Mã đề xuất</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.getValues().code}</Text>
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                    {/* <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Trạng thái</Text>
                      </Table.Td>
                      <Table.Td>
                        <Badge
                          variant="dot"
                          size="lg"
                          style={{
                            borderRadius: "4px",
                          }}
                          color={getColorStatus(form.values.active)}
                        >
                          {form.values.active ? "Đã duyệt" : "Chưa duyệt"}
                        </Badge>
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr> */}
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Ngày duyệt</Text>
                      </Table.Td>
                      <Table.Td>
                        {form.values.active !== null &&
                          form.values.lastUpdateDate && (
                            <Text>
                              {formatDate(form.values.lastUpdateDate)}
                            </Text>
                          )}
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Họ và tên</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>
                          {form.values.nameEmployee +
                            " - " +
                            form.values.codeEmployee}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>
                          {form.values.nameEmployeeReceive +
                            " - " +
                            form.values.codeEmployeeReceive}
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Chức danh</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.values.nameRole}</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.values.nameRoleReceive}</Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Bộ phận</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.values.nmaePart ?? ""}</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.values.namePartReceive}</Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Phòng ban</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.values.nameDepeartment ?? ""}</Text>
                      </Table.Td>
                      <Table.Td>{form.values.nameDepeartmentReceive}</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Địa điểm làm việc</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.values.idWorkAddress ?? ""}</Text>
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Mức độ ưu tiên</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>
                          {getTextPriority(form.values.priorityLevel)}
                        </Text>
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                  </Table.Tbody>
                </Table>
              </Card>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="lg"
                style={{
                  maxHeight: height,
                  minHeight: height,
                  overflow: "auto",
                }}
              >
                <Center>
                  <Text fw={700} size={"xl"}>
                    Nội dung phiếu đề xuất
                  </Text>
                </Center>
                <Grid>
                  <Grid.Col span={{ base: 12 }}>
                    <Textarea
                      placeholder="Không có nội dung"
                      size="lg"
                      minRows={4}
                      maxRows={4}
                      autosize
                      variant="filled"
                      readOnly
                      {...form.getInputProps("note")}
                    />
                  </Grid.Col>
                  <Grid.Col pb={0} span={{ base: 12 }}>
                    <Divider
                      my="xs"
                      label={
                        <Text size="lg" fw={600} c="#000">
                          File đính kèm
                        </Text>
                      }
                      labelPosition="left"
                    />
                  </Grid.Col>
                  {form.values.urlFile !== "Không có file" ? (
                    <>
                      <Grid.Col>
                        <Flex gap={12} justify={"start"}>
                          {[
                            "jpg",
                            "jpeg",
                            "xls",
                            "png",
                            "pdf",
                            "doc",
                            "docx",
                            "xlsx",
                          ].includes(
                            form.values.urlFile?.split(".").pop() ?? ""
                          ) && (
                            <Button
                              leftSection={<IconEye></IconEye>}
                              onClick={openFile}
                            >
                              Xem file đính kèm
                            </Button>
                          )}
                          <a
                            href={`https://files.hacom.vn/hacom/${form.values.urlFile}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button leftSection={<IconDownload />}>
                              Tải xuống file
                            </Button>
                          </a>
                        </Flex>
                      </Grid.Col>
                    </>
                  ) : (
                    <Grid.Col>
                      <Text size="sm">Không có file đính kèm</Text>
                    </Grid.Col>
                  )}
                  <Grid.Col span={{ base: 12 }}>
                    <Center>
                      <Text fw={700} size={"xl"}>
                        Phản hồi của người duyệt
                      </Text>
                    </Center>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12 }}>
                    <Textarea
                      placeholder="Chưa có phản hồi"
                      size="lg"
                      minRows={4}
                      maxRows={4}
                      autosize
                      readOnly
                      variant="filled"
                      {...form.getInputProps("respond")}
                    />
                  </Grid.Col>
                </Grid>
              </Card>
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
