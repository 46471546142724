import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Flex,
  Input,
  Menu,
  rem,
  Select,
  Tooltip,
} from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import * as xlsx from "xlsx";

import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  IconBuildingWarehouse,
  IconCaretDown,
  IconFileExport,
  IconFileUpload,
  IconMapPin,
  IconSearch,
  IconTimeDuration15,
  IconUsers,
} from "@tabler/icons-react";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import { reportItemSales } from "../../../../model/TblOrderHeader";
import { toast } from "react-toastify";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
const GeneralSalesStatement = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<reportItemSales[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //chi nhánh
  // const [dataBranchCode, setDataBranchCode] = useState<SelectListItemModel[]>(
  //   []
  // );
  const userLogin = JSON.parse(localStorage.getItem("userLogin") || "{}");
  //Kho
  const [dataInventory, setDataInventory] = useState<SelectListItemModel[]>([]);
  //Bộ phận
  const [dataDepartment, setDataDepartment] = useState<SelectListItemModel[]>(
    []
  );
  //Khách hàng
  const [dataCustomer, setDataCustomer] = useState<SelectListItemModel[]>([]);

  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      StartTime: "",
      EndTime: "",
    },
  });

  const [search, setSearch] = useState({
    Cnids: [], //chi nhánh
    KhoIds: [], //kho
    Bpids: [], //bộ phận
    KHids: [], //khách hàng
  });
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }
    if (formSeachInput.getValues().StartTime) {
      url += `&StartTime=${formSeachInput.getValues().StartTime}`;
    }

    if (formSeachInput.getValues().EndTime) {
      url += `&EndTime=${formSeachInput.getValues().EndTime}`;
    }
    if (userLogin) {
      url += `&Cnids=${userLogin.branchId}`;
    }
    if (search.KhoIds && search.KhoIds.length > 0) {
      search.KhoIds.forEach((KhoIds) => {
        url += `&KhoIds=${KhoIds}`;
      });
    }
    if (search.Bpids && search.Bpids.length > 0) {
      search.Bpids.forEach((Bpids) => {
        url += `&Bpids=${Bpids}`;
      });
    }
    if (search.KHids && search.KHids.length > 0) {
      search.KHids.forEach((KHids) => {
        url += `&KHids=${KHids}`;
      });
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/report-item-sales${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<reportItemSales>[]>(
    () => [
      {
        accessorKey: "maCt",
        header: "Mã chứng từ",
        size: 170,

        Cell: ({ renderedCellValue }) => (
          <>
            <Badge variant="dot" color="green" radius="xs">
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "ngayCt",
        header: "Ngày chứng từ",
        size: 50,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      //   {
      //     accessorKey: "ttHd",
      //     header: "Trạng thái hóa đơn",
      //     size: 30,
      //     Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      //   },
      {
        accessorKey: "maBp",
        header: "Mã bộ phận",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenCn",
        header: "Tên chi nhánh",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maKho",
        header: "Mã kho",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKho",
        header: "Tên kho",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maNvkd",
        header: "Mã nhân viên",
        size: 200,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenNvkd",
        header: "Tên nhân viên kinh doanh",
        size: 230,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maQl",
        header: "Mã quản lý",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenQl",
        header: "Tên quản lý",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maKt",
        header: "Mã kế toán",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKeToan",
        header: "Tên kế toán",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maKenh",
        header: "Mã kênh bán",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKenh",
        header: "Tên kênh bán",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maKh",
        header: "Mã khách hàng",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKh",
        header: "Tên khách hàng",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "diaChiKh",
        header: "Địa chỉ khách hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "sdt",
        header: "Số điện thoại",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maThueKh",
        header: "Mã thuế khách hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maTinh",
        header: "Mã tỉnh",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenTinh",
        header: "Tên tỉnh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maQuan",
        header: "Mã quận",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenQuan",
        header: "Tên quận",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maPhuong",
        header: "Mã phường",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenPhuong",
        header: "Tên phường",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "shippingCode",
        header: "Mã giao hàng",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "shipngType",
        header: "Thể loại giao hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "shippingStatus",
        header: "Trạng thái giao hàng",
        size: 200,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "shippingFinishDate",
        header: "Ngày hoàn thành giao hàng",
        size: 230,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tTienDh",
        header: "Tổng tiền đơn hàng",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tienCk",
        header: "Tiền chiết khấu",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "dtSauCk",
        header: "Doanh thu sau chiết khấu",
        size: 220,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "tGg",
        header: "Tổng giảm giá",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "tTienVoucher",
        header: "Tổng tiền voucher",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "tTienTra",
        header: "Tổng tiền trả",
        size: 100,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "tTienCon",
        size: 190,
        header: "Tổng tiền còn phải trả",
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },
      {
        accessorKey: "ngayDuyet",
        header: "Ngày duyệt giảm giá",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "nguoiDuyetGg",
        header: "Người duyệt giảm giá",
        size: 190,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maItem",
        header: "Mã hàng",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "Uom",
        header: "Đơn vị tính",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "PriceOnPriceList",
        header: "Đơn giá sản phẩm",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "giaVon",
        header: "Giá vốn",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {formatCurrencyVND(renderedCellValue) == 0
              ? ""
              : formatCurrencyVND(renderedCellValue)}
          </>
        ),
      },

      {
        accessorKey: "tgNguoiSua",
        header: "Thời gian người sửa",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "maNguoiSua",
        header: "Mã người sửa",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["maCt", "ngayCt"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //xuất excel
  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-bao-cao-ban-hang-theo-ngay.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  // // Lấy danh sách mã chi nhánh
  // const DataBranchCode = async () => {
  //   try {
  //     const response = await repositoryDelivery.get(
  //       `api/v1/TblDmMasterOrg/get-select`
  //     );

  //     if (response && response.success) {
  //       const transformedData = response.data.map((item: any) => ({
  //         value: item.value,
  //         label: item.value + " - " + item.text,
  //       }));
  //       setDataBranchCode(transformedData);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return;
  //   }
  // };
  // Lấy danh sách kho
  const fetchDataKho = async () => {
    try {
      if (userLogin) {
        const response = await repositoryPos.get(
          `/api/v1/TblDmInventory/get-select-by-branch?branchId=${userLogin?.branchId}`
        );
        if (response && response.success) {
          const transformedData = response.data.map((item: any) => ({
            value: String(item.inventoryId),
            label: item.inventoryCode + " - " + item.inventoryName,
          }));
          setDataInventory(transformedData);
        } else {
          setDataInventory([]);
        }
      } else {
        const response = await repositoryPos.get(
          `/api/v1/TblDmInventory/get-all`
        );
        if (response && response.success) {
          const transformedData = response.data.map((item: any) => ({
            value: item.value,
            label: item.text,
          }));
          setDataInventory(transformedData);
        } else {
          setDataInventory([]);
        }
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  // Lấy danh sách mã bộ phận
  const fetchDataBp = async () => {
    try {
      if (userLogin) {
        const response = await repositoryPos.get(
          `/api/v1/TblDepartment/get-all?branchId=${userLogin?.branchId}`
        );
        if (response && response.success) {
          const transformedData = response.data.map((item: any) => ({
            value: String(item.departmentId),
            label: item.deparmentCode + " - " + item.name,
          }));
          setDataDepartment(transformedData);
        } else {
          setDataDepartment([]);
        }
      } else {
        const response = await repositoryPos.get(
          `/api/v1/TblDepartment/get-all`
        );
        if (response && response.success) {
          const transformedData = response.data.map((item: any) => ({
            value: String(item.departmentId),
            label: item.departmentId + " - " + item.name,
          }));
          setDataDepartment(transformedData);
        } else {
          setDataDepartment([]);
        }
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  // Lấy danh sách mã khách hàng
  const fetchDataCustomer = async () => {
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDmCustomer/get-list?Skip=0&Take=100`
      );
      if (response && response.success) {
        const transformedData = response.data
          .filter((item: any) => item.name !== null)
          .map((item: any) => ({
            value: String(item.id),
            label: String(item.code) + " - " + item.name,
          }));
        setDataCustomer(transformedData);
      } else {
        setDataCustomer([]);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  useEffect(() => {
    fetchData();
  }, [pagination]);

  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (190 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Flex
        gap="md"
        justify={"end"}
        align="center"
        direction="row"
        wrap="wrap"
        w={"100%"}
        mt={5}
      >
        <Flex gap="md" align="center" direction="row" wrap="wrap">
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleExport}
              >
                Export Excel
              </Menu.Item>
              <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Flex>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          // marginTop: "10px",
        }}
      >
        <Input
          style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
          placeholder="Từ khoá"
          type="text"
          leftSection={<IconSearch color="#15aabf" />}
          key={formSeachInput.key("keySearch")}
          {...formSeachInput.getInputProps("keySearch")}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              fetchData();
            }
          }}
        />

        <DatePickerInput
          type="range"
          size="sm"
          allowSingleDateInRange
          placeholder="Chọn khoảng ngày"
          leftSection={<IconTimeDuration15 color="#15aabf" />}
          locale="vi"
          valueFormat="DD/MM/YYYY"
          onChange={(e) => {
            formSeachInput.setFieldValue(
              "StartTime",
              formatDateNotTimeZone(e[0]) || "" // Định dạng StartTime
            );
            formSeachInput.setFieldValue(
              "EndTime",
              formatDateNotTimeZone(e[1]) || "" // Định dạng EndTime
            );
          }}
          clearable
        />

        {/* <Select
          size="sm"
          placeholder="Chi nhánh"
          searchable
          clearable
          data={dataBranchCode}
          leftSection={<IconMapPin size={20} color="#15aabf" />}
          onClick={() => {
            if (dataBranchCode.length === 0) {
              DataBranchCode();
            }
          }}
          onChange={(value) =>
            handleChangeSearchValue(value ? [value] : null, "Cnids")
          } // Cập nhật Cnids
        /> */}
        <Select
          size="sm"
          placeholder="Kho"
          searchable
          clearable
          leftSection={<IconBuildingWarehouse size={20} color="#15aabf" />}
          // disabled={search.Cnids.length === 0}
          onClick={() => {
            if (dataInventory.length === 0) {
              fetchDataKho();
            }
          }}
          data={dataInventory}
          onChange={(value) =>
            handleChangeSearchValue(value ? [value] : null, "KhoIds")
          } // Cập nhật KhoIds
        />
        <Select
          size="sm"
          placeholder="Bộ phận"
          searchable
          clearable
          leftSection={<IconUsers size={20} color="#15aabf" />}
          data={dataDepartment}
          onClick={() => {
            if (dataDepartment.length === 0) {
              fetchDataBp();
            }
          }}
          onChange={(value) =>
            handleChangeSearchValue(value ? [value] : null, "Bpids")
          } // Cập nhật Bpids
        />
        <Select
          size="sm"
          placeholder="Khách hàng"
          searchable
          clearable
          leftSection={<IconUsers size={20} color="#15aabf" />}
          data={dataCustomer}
          onClick={() => {
            if (dataCustomer.length === 0) {
              fetchDataCustomer();
            }
          }}
          onChange={(value) =>
            handleChangeSearchValue(value ? [value] : null, "KHids")
          } // Cập nhật KHids
        />
        <Button
          type="submit"
          leftSection={<IconSearch size={14} />}
          color="blue"
          variant="outline"
          onClick={async () => {
            await fetchData();
          }}
        >
          Tìm kiếm
        </Button>
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default GeneralSalesStatement;
