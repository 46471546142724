import { Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconCopy, IconX } from "@tabler/icons-react";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

const CloneView = ({
  id,
  fetchData,
}: {
  id: number;
  fetchData: () => Promise<void>;
}) => {
  const handleDeleteBranch = async () => {
    const res = await repositoryPos.get<MessageResponse<boolean>>(
      "/api/v1/tblPromotion/clone?id=" + id
    );
    if (res)
      if (res.success) {
        await fetchData();
        NotificationExtension.Success("Thành công !");
      } else NotificationExtension.Fails("Thất bại !");
    modals.closeAll();
  };

  return (
    <div>
      <Text size="20px" mt={5}>
        Bạn có chắc chắn muốn sao chép chính sách khuyến mãi này ?
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color="blue"
          onClick={handleDeleteBranch}
          leftSection={<IconCopy size={18} />}
        >
          Sao chép
        </Button>
      </Group>
    </div>
  );
};

export default CloneView;
