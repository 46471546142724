import React, { useState } from "react";

import {
  Box,
  Button,
  Group,
  LoadingOverlay,
  Text,
  Textarea,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { TblDMApproveProposal } from "../../../model/TblDMApproveProposal";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { useDisclosure } from "@mantine/hooks";
import { SocketExtension } from "../../../_base/socket/socket";
export const ApproveWithResponseView = ({
  row,
  onClose,
}: ApproveWithResponseViewProps) => {
  const [response, setResponse] = useState("");
  const [visible, { open, close }] = useDisclosure(false);
  const handleSubmit = async () => {
    open();
    const formData = new FormData();
    formData.append("active", "true");
    formData.append("respond", response);
    (Object.keys(row) as (keyof TblDMApproveProposal)[]).forEach((key) => {
      const value = row[key];
      if (value !== null && value !== undefined) {
        formData.append(key, String(value)); // Chuyển đổi thành string
      }
    });

    try {
      const response = await repositoryMdm.post(
        "/api/v1/TblApproposal/update",
        formData
      );

      if (response && response.success) {
        onClose((prev: any) => !prev);
        NotificationExtension.Success("Duyệt thành công!");
        SocketExtension.SendMessageToEmp(Number(row.idEmployee), {
          message: "Có đề xuất mới gửi tới bạn!",
        });
        modals.closeAll();
      }
    } catch (error) {
      NotificationExtension.Fails("Duyệt thất bại!");
    }
    close();
  };
  return (
    <Box size={"auto"}>
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Text size="20px" mt={5}>
        Bạn có chắc chẵn duyệt đề xuất này?
      </Text>
      <Textarea
        mt={8}
        label="Phản hồi"
        minRows={8}
        maxRows={8}
        value={response}
        onChange={(e) => setResponse(e.target.value)}
        autosize
      />
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          color="green"
          onClick={async () => {
            await handleSubmit();
          }}
          leftSection={<IconCheck size={18} />}
        >
          Duyệt
        </Button>
      </Group>
    </Box>
  );
};

type ApproveWithResponseViewProps = {
  row: TblDMApproveProposal;
  onClose: any;
};
