import {
  Box,
  Checkbox,
  ComboboxItem,
  Grid,
  NumberInput,
  Select,
  TextInput,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDeliveryAssignment } from "../../../model/TblDeliveryAssignment";
import {
  getTblLocationSelect,
  getDeliveryWorkSelect,
  getTblDmEmployeeSelect,
} from "../../../service/getSelectApi";
import { handleKeyDown } from "../../../_base/helper/FunctionHelper";
import DeliveryWarranty from "./DeliveryWarranty";

const DetailDataView = ({ id, onClose }: DetailDeliveryAssignmentProps) => {
  const entity = {
    id: "0",
    code: "",
    assignmentDate: new Date().toString(),
    fromExpectedDate: new Date().toString(),
    toExpectedDate: new Date().toString(),
    deliveryWorkId: null,
    employee1: null,
    employee2: null,
    mainResponsibility1: false,
    mainResponsibility2: false,
    payroll1: false,
    payroll2: false,
    vehicleCode: null,
    driverId: null,
    driverName: null,
    driverMobileNumber: null,
    fromLocationId: null,
    toLocationId: null,
    distance: null,
    distanceLevel: null,
    distanceTotal: null,
    partnerId: null,
    partnerEmployeeName: null,
    description: null,
    deliveryIds: null,
    tblDeliveryModels: [],
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [dataEmployeedSelect, setDataEmployeedSelect] = useState<
    ComboboxItem[]
  >([]);

  const [dataWorkSelect, setDataWorkSelect] = useState<ComboboxItem[]>([]);

  const [dataLocationSelect, setDataLocationSelect] = useState<ComboboxItem[]>(
    []
  );

  const [startTime, setStartTime] = useState<Date | null>(new Date());

  const form = useForm<TblDeliveryAssignment>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {},
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryDelivery.get<
      MessageResponse<TblDeliveryAssignment>
    >(`/api/v1/TblDeliveryAssignment/detail?id=${id}`);
    if (!callapi?.success) {
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        Promise.all([
          dataEmployeeSelect(),
          dataDeliveryWorkSelect(),
          fetchLocationSelect(),
        ]);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const dataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeedSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchLocationSelect = async () => {
    const get = await getTblLocationSelect();
    setDataLocationSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataDeliveryWorkSelect = async () => {
    const get = await getDeliveryWorkSelect();
    setDataWorkSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) callApiGetData();
  }, [id]);

  return (
    <Box
      component="form"
      maw={1350}
      w={"80vw"}
      style={{ position: "relative" }}
    >
      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Mã phân công giao vận"
            size="xs"
            readOnly
            onKeyDown={handleKeyDown}
            {...form.getInputProps("code")}
            withAsterisk
            variant="filled"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <DatePickerInput
            label="Ngày phân công"
            size="xs"
            readOnly
            value={new Date(form.getValues().assignmentDate ?? "")}
            valueFormat="DD/MM/YYYY"
            variant="filled"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Khoảng cách"
            variant="filled"
            readOnly
            size="xs"
            value={`${Number(form.getValues().distance ?? 0) / 1000} Km`}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <TextInput
            label="Ghi chú"
            size="xs"
            w={"100%"}
            readOnly
            {...form.getInputProps("description")}
            variant="filled"
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Select
            label="Nhân viên GH"
            data={dataEmployeedSelect}
            searchable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            size="xs"
            readOnly
            value={form.getValues().employee1?.toString()}
            variant="filled"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Checkbox
            mt={27.5}
            label="Người chịu trách nhiệm"
            size="xs"
            readOnly
            checked={form.getValues().mainResponsibility1}
            {...form.getInputProps("mainResponsibility1")}
            onChange={() => {}}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Checkbox
            mt={27.5}
            label="Tính lương"
            size="xs"
            readOnly
            checked={form.getValues().payroll1}
            {...form.getInputProps("payroll1")}
            onChange={() => {}}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Select
            label="Nhân viên HT"
            data={dataEmployeedSelect}
            searchable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            size="xs"
            readOnly
            value={form.getValues().employee2?.toString()}
            onChange={() => {}}
            variant="filled"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Checkbox
            mt={27.5}
            label="Người chịu trách nhiệm"
            size="xs"
            readOnly
            checked={form.getValues().mainResponsibility2}
            {...form.getInputProps("mainResponsibility2")}
            onChange={() => {}}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Checkbox
            mt={27.5}
            label="Tính lương"
            size="xs"
            readOnly
            checked={form.getValues().payroll2}
            {...form.getInputProps("payroll2")}
            onChange={() => {}}
          />
        </Grid.Col>
      </Grid>
      <Grid align="center">
        <Grid.Col span={12}>
          <Text size="12.5px" fw={"bold"} my={6}>
            Danh sách đơn hàng
          </Text>
          <DeliveryWarranty
            dataDetail={form.getValues().warrantyServiceRequestFormModels}
          />
        </Grid.Col>
      </Grid>
    </Box>
  );
};

type DetailDeliveryAssignmentProps = {
  id: string | number;
  onClose: any;
};

export default DetailDataView;
