export const dataStatusOption = [
  { value: "OPEN", label: "Lưu tạm" },
  { value: "LOCK", label: "Đã xuất" },
];

export const search = {
  keySearch: "",
  fromTransationDate: "",
  toTransationDate: "",
  sourceType: "",
  invId: "",
  status: "",
  empId: "",
};
