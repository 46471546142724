import React from "react";
import { Table, Checkbox, ScrollArea, Radio } from "@mantine/core";
import {
  itemGiftPromotionModel,
  TblItemPromotionModel,
} from "../../../../../model/TblItem";

interface GiftTableProps {
  selectedPromotion: TblItemPromotionModel;
  setListSelectedGift: React.Dispatch<
    React.SetStateAction<itemGiftPromotionModel[]>
  >;
  listSelectedGift: itemGiftPromotionModel[];
}

const GiftTable: React.FC<GiftTableProps> = ({
  selectedPromotion,
  listSelectedGift,
  setListSelectedGift,
}) => {
  return (
    <ScrollArea h={500}>
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
            <Table.Th>Tồn kho</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {selectedPromotion?.tblPromotionItemGift?.map((promotionItem) => (
            <Table.Tr key={promotionItem?.idItemGift}>
              <Table.Td>
                {promotionItem?.itemForSellModel?.productCode}
              </Table.Td>
              <Table.Td>
                {promotionItem?.itemForSellModel?.productName}
              </Table.Td>
              <Table.Td>{promotionItem?.itemForSellModel?.unit}</Table.Td>
              <Table.Td>{promotionItem?.itemForSellModel?.tonao}</Table.Td>
              <Table.Td>{promotionItem?.itemForSellModel?.warehouse}</Table.Td>
              <Table.Td>
                <Radio
                  checked={listSelectedGift[0]?.id === promotionItem.id}
                  onChange={() => setListSelectedGift([promotionItem])}
                />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default GiftTable;
