import { Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { repositoryPos } from "../../_base/_const/_constVar";
type DeleteProduct = {
  idItems: (number | string)[];
  onClose: any;
};
const DeleteDepartmen = ({ onClose, idItems }: DeleteProduct) => {
  const handleDelete = async () => {
    const res = await repositoryPos.post(
      "/api/v1/TblDepartment/delete",
      idItems
    );
    if (res)
      if (res.success) {
        NotificationExtension.Success("Xóa thành công !");
        onClose((prev: any) => !prev);
      } else NotificationExtension.Fails("Xóa thất bại !");
    modals.closeAll();
  };
  return (
    <div>
      <Text size="24px">Bạn có chắc chắn muốn xóa không ?</Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color="red"
          onClick={handleDelete}
          leftSection={<IconCheck size={18} />}
        >
          Xóa
        </Button>
      </Group>
    </div>
  );
};

export default DeleteDepartmen;
