import "./tab.component.css";

import { Paper, Tabs } from "@mantine/core";
import { useState } from "react";
import RequestWarehouseTransfer from "./RequestWarehouseTransfer/RequestWarehouseTransfer";

const RequestTransferData = () => {
  const [activeTab, setActiveTab] = useState<string | null>(
    "RequestWarehouseTransfer"
  );

  return (
    <>
      <RequestWarehouseTransfer />
    </>
  );
};

export default RequestTransferData;
