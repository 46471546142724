import { useDisclosure } from "@mantine/hooks";
import { TblDMBarcodeStatus } from "../../../model/BarcodeStatus";
import { useForm } from "@mantine/form";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { handleKeyDown } from "../../../_base/helper/FunctionHelper";
import {IconWindow } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { useEffect } from "react";

type DetailItemViewProps = { id: string };
export const DetailItemView = ({ id }: DetailItemViewProps) => {
  const entity: Partial<TblDMBarcodeStatus> = {
    id: "0",
    maTtmv: "",
    tenTtmv: "",
    ksd: false,
    isMacDinhNhapMua: false,
    maCty: "",
  };
  const [visible, { toggle, close, open }] = useDisclosure(false);
  const form = useForm<Partial<TblDMBarcodeStatus>>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });
  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<
      MessageResponse<TblDMBarcodeStatus>
    >(`/api/v1/BarcodeStatus/get-by-id?id=${id}`);
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);
  return (
    <>
      <Box
        component="form"
        mx="auto"
        onSubmit={() => {}}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={12}>
            <TextInput
              label={"Mã trạng thái mã vạch"}
              placeholder={"Nhập mã trạng thái mã vạch"}
              type="text"
              withAsterisk
              onKeyDown={handleKeyDown}
              {...form.getInputProps("maTtmv")}
              readOnly
              variant="filled"
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label={"Tên trạng thái mã vạch"}
              placeholder={"Nhập tên mã trạng thái mã vạch"}
              type="text"
              withAsterisk
              onKeyDown={handleKeyDown}
              {...form.getInputProps("tenTtmv")}
              readOnly
              variant="filled"
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Checkbox
              label={"Không sử dụng"}
              checked={form.values.ksd ?? false}
              readOnly
              variant="filled"
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Checkbox
              label={"Mặc định nhập/mua"}
              checked={form.values.isMacDinhNhapMua ?? false}
              readOnly
              variant="filled"
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label={"Mã công ty"}
              placeholder={"Nhập mã công ty"}
              type="text"
              withAsterisk
              onKeyDown={handleKeyDown}
              {...form.getInputProps("maCty")}
              readOnly
              variant="filled"
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
        </Group>
      </Box>
    </>
  );
};
