import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Input,
  NumberFormatter,
  Select,
  SelectProps,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandVisa,
  IconCheck,
  IconCoin,
  IconDownload,
  IconEdit,
  IconEye,
  IconMenuOrder,
  IconPlus,
  IconSearch,
  IconSquareXFilled,
  IconStatusChange,
  IconTransformFilled,
  IconUserCode,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { DatePickerInput } from "@mantine/dates";
import { modals } from "@mantine/modals";
import {
  IconBrandBootstrap,
  IconReceiptRefund,
  IconTimeDuration15,
} from "@tabler/icons-react";
import { SelectListItem } from "../../../../../model/SelectListItem";
import { MessageResponse } from "../../../../../model/MessageResponse";
import { green, olive_green, sky_blue } from "../../../../../const/variables";
import { AvatarUtils } from "../../../../../common/ColorByName/AvatarUtils";
import { formatDateTransfer } from "../../../../../common/FormatDate/FormatDate";
import { OrderDetail } from "../../../../../model/TblCreateSellItem";
import { _localization } from "../../../../../config/location";
import { SocketExtension } from "../../../../../_base/socket/socket";
import { CREATEORDERSUCCESS } from "../../../../../_base/socket/ToplicList";
import { repositoryPos } from "../../../../../_base/_const/_constVar";
import { notifications } from "@mantine/notifications";
import { DebtData } from "../../../PaymentReceipt/InternalReceipt/InternalReceiptModel";

const ReturnOrderChildren = ({
  handleChooseOrderReturn,
  fieldName,
}: {
  handleChooseOrderReturn: Function;
  fieldName?: DebtData[];
  // data: de
}) => {
  //#region  table
  //data and fetching state
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const [dataListCustomer, setDataListCustomer] = useState<SelectListItem[]>(
    []
  );
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);

  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);
  const debounceTimeoutRef = React.useRef<number | null>(null);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [search, setSearch] = useState({
    invId: "",
    invOutwardId: "",
    customerId: "",
    startDate: "",
    endDate: "",
    orderStatus: "",
    orderType: "",
    orderNumber: "",
    paymentStatus: "",
    industry: "",
    keySearch: "",
    OutwardStatus: "",
  });

  const [searchReturn, setSearchReturn] = useState({
    customerName: "",
    customerCode: "",
    customerTaxCode: "",
    serial: "",
    orderNumber: "",
  });

  console.log(fieldName, "fsfesdfsdf");

  const sizeGrid = { base: 6, sm: 4, md: 3, lg: 3, xl: 2 };

  const getTextStatus = (row: any) => {
    return orderStatuses.find(
      (status) => status.value === row.original.orderStatus
    )?.label;
  };

  const getShippingVendorText = (shippingVendor: string) => {
    switch (shippingVendor) {
      case "KHONG":
        return "Nhận tại cửa hàng";
      case "HACOM":
        return "Nội bộ";
      default:
        return shippingVendor;
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        InvId: search.invId,
        InvOutwardId: search.invOutwardId,
        CustomerId: search.customerId,
        StartDate: search.startDate,
        EndDate: search.endDate,
        OrderStatus: search.orderStatus,
        OrderType: search.orderType ? Number.parseInt(search.orderType) : 0,
        OrderNumber: search.orderNumber,
        PaymentStatus: search.paymentStatus,
        industry: search.industry,
        OutwardStatus: search?.OutwardStatus,
        KeySearch: search.keySearch,
      };

      const queryParamsReturn = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        CustomerName: searchReturn.customerName,
        CustomerCode: searchReturn.customerCode,
        CustomerTaxCode: searchReturn.customerTaxCode,
        Serial: searchReturn.serial,
        OrderNumber: searchReturn.orderNumber,
      };

      const isQueryParamsReturnEmpty =
        !searchReturn.customerName &&
        !searchReturn.customerCode &&
        !searchReturn.customerTaxCode &&
        !searchReturn.serial &&
        !searchReturn.orderNumber;

      const response = await repositoryPos.get<MessageResponse<any[]>>(
        "/api/v1/CreateSellItem/get-list-return-sale-1?" +
          Object.entries({
            ...queryParamsReturn,
            Skip: pagination.pageIndex * pagination.pageSize,
            Take: pagination.pageSize,
          })
            .filter(([key, value]) => {
              // Bỏ qua các trường rỗng, trừ Skip và Take
              return (
                (value !== undefined && value !== "") ||
                key === "Skip" ||
                key === "Take"
              );
            })
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&")
      );

      if (response && response.success) {
        const filteredData = response.data.filter(
          (item) =>
            !fieldName?.some(
              (x) => x.originalDocumentNumber === item.orderNumber
            )
        );
        setData(filteredData);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    const trimmedValue = value.trim();

    if (key === "customerId") {
      setSelectedCustomer(trimmedValue);
    }

    if (key === "OutwardStatus") {
      setSearch((prevData) => ({
        ...prevData,
        [key]: trimmedValue,
        orderStatus: "DON_HANG_HOAN_THANH",
      }));
    }

    setSearch((prevData) => ({ ...prevData, [key]: trimmedValue }));
  };

  //hàm hủy đơn hàng
  const orderTypes = [
    { value: "1", label: "Online", color: "blue" },
    { value: "2", label: "Bán lẻ", color: "indigo" },
    { value: "3", label: "Đơn đổi trả", color: "green" },
  ];

  const orderStatuses = [
    { value: "DON_HANG_TAM", label: "Đơn hàng tạm" },
    { value: "DON_HANG_CHOT", label: "Đơn hàng chốt" },
    { value: "DON_HANG_CHO_DUYET_GIAM", label: "Đơn chờ duyệt giảm" },
    { value: "DON_HANG_DA_DUYET_GIAM", label: "Đơn đã duyệt giảm" },
    { value: "DON_HANG_HUY", label: "Đơn hàng hủy" },
    { value: "DON_HANG_HOAN_THANH", label: "Đơn hàng hoàn thành" },
  ];

  const warehouseStatus = [
    { value: "DA_YEU_CAU", label: "Đã yêu cầu" },
    { value: "DANG_XUAT", label: "Đang xuất" },
    { value: "DA_XUAT", label: "Đã xuất" },
    { value: "DANG_NHAP", label: "Đang nhập" },
    { value: "DA_NHAP", label: "Đã nhập" },
  ];

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 1,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilters: false,
      },
      {
        accessorKey: "orderNumber",
        header: "Số đơn hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "orderStatus",
        header: "Trạng thái đơn hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const orderStatus = row.original.orderStatus;
          let badgeColor;
          switch (orderStatus) {
            case "DON_HANG_TAM":
              badgeColor = "cyan";
              break;
            case "DON_HANG_CHOT":
              badgeColor = "violet";
              break;
            case "DON_HANG_DOI_TRA_TAM":
              badgeColor = "cyan";
              break;
            case "DON_HANG_HOAN_THANH":
              badgeColor = "green";
              break;
            case "DON_HANG_DOI_TRA":
              badgeColor = "green";
              break;
            case "THANH_TOAN_CHUA_HOAN_THANH":
              badgeColor = "red";
              break;
            case "pending":
              badgeColor = "yellow";
              break;
            case "DON_HANG_BAN_TAI_SHOP":
              badgeColor = "green";
              break;
            case "DON_HANG_CHO_DUYET_GIAM":
              badgeColor = olive_green.light;
              break;
            case "DON_HANG_DA_DUYET_GIAM":
              badgeColor = olive_green.medium;
              break;
            case "KHO_XUAT_HANG":
              badgeColor = sky_blue.medium;
              break;
            default:
              badgeColor = "gray";
          }
          return (
            <Badge color={badgeColor} fs={"12,5px"} fw={"bold"} radius="sm">
              {row.original.orderStatus !== null
                ? getTextStatus(row)
                : "Không HỢP LỆ"}
            </Badge>
          );
        },
      },
      {
        accessorKey: "attribute3",
        header: "Trạng thái kho",
        size: 150,
        Cell: ({ renderedCellValue, row }) => {
          const _status = warehouseStatus.find(
            (status) => status.value === row?.original?.attribute3?.toString()
          );

          return (
            <Badge size="sm" color="cyan">
              {_status?.label ? _status.label : "Chưa yêu cầu"}
            </Badge>
          );
        },
      },
      {
        accessorKey: "orderType",
        header: "Loại đơn hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 150,
        Cell: ({ row }) => {
          const orderType = orderTypes.find(
            (type) => type.value === row.original.orderType?.toString()
          );

          return (
            <Badge color={orderType?.color || "gray"}>
              {orderType?.label || "KHÔNG HỢP LỆ"}
            </Badge>
          );
        },
      },

      {
        accessorKey: "invOrgName",
        header: "Kho hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row, column }) => (
          <>{`[${row.original.invOrgCode}] ${row.original.invOrgName}`}</>
        ),
      },

      {
        accessorKey: "customerPhone",
        header: "Điện thoại KH",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 150,
      },
      {
        accessorKey: "attribute4",
        header: "Số tiền hoàn",
        size: 150,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix=" ₫"
            thousandSeparator=","
          />
        ),
      },
      {
        accessorKey: "amount",
        header: "Số tiền",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 150,
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix=" ₫"
            thousandSeparator=","
          />
        ),
      },
      {
        accessorKey: "paid",
        header: "Đã trả",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 150,

        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "remaining",
        header: "Còn nợ",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 150,

        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix=" ₫"
            thousandSeparator=","
          />
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: () => null,
    mantineTopToolbarProps: {
      style: {
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: false, // Enables row selection via checkboxes
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "mrt-row-select", "mrt-row-expand"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection, // Tracks selected rows
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "650",
        fontSize: "12px",
        padding: "10px 10px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 1, // Adjust size of the selection column
      },
      "mrt-row-expand": {
        size: 1,
      },
    },

    mantineTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        if (
          row.original.orderNumber === "" ||
          row.original.attribute4 === "0"
        ) {
          notifications.show({
            title: "Thất bại",
            message: "Mã đơn hàng hoặc số tiền không hợp lệ!",
            autoClose: 5000,
            color: "red",
            icon: <IconX />,
          });
          return;
        }
        handleChooseOrderReturn(row.original);
        modals.closeAll();
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),

    renderTopToolbar: ({ table }) => (
      <Group p="5px 10px" ref={headerRef} style={{ width: "100%" }}>
        <Flex gap={10}>
          <Input
            placeholder="Mã đơn hàng"
            type="text"
            leftSection={<IconSearch color="#15aabf" />}
            value={searchReturn.orderNumber}
            onChange={(e) => {
              setSearchReturn((prev) => ({
                ...prev,
                orderNumber: e.target.value,
              }));
            }}
          />
          <Button
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Flex>
      </Group>
    ),
  });

  // Hàm lấy dữ liệu khách hàng

  const fetchDataCustomer = async (searchTerm: string) => {
    const query = searchTerm ? `KeySearch=${searchTerm}&` : "";
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmCustomer/get-select?${query}Skip=${1}&Take=${30}`
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.filter(
        (item: any) => item.text !== null
      );
      setDataListCustomer(filteredData);
    }
  };

  const debouncedFetchDataCustomer = React.useCallback((value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(() => {
      fetchDataCustomer(value);
    }, 300);
  }, []);

  useEffect(() => {
    debouncedFetchDataCustomer(searchTerm);
  }, [searchTerm, debouncedFetchDataCustomer]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting, deleteViewStatus]);

  useEffect(() => {
    SocketExtension.On(CREATEORDERSUCCESS, (arg: any) => {
      fetchData();
    });
    return () => {
      SocketExtension.Off(CREATEORDERSUCCESS, () => {});
    };
  }, []);

  useEffect(() => {
    fetchData();
    console.log(fieldName, "asdasdadad");
  }, [fieldName]);

  console.log(fieldName, "đây là chiến");
  useEffect(() => {
    if (value && value.length > 0) {
      if (value[0]) {
        const startDate = value[0];
        const startDateISO = startDate
          ? new Date(
              startDate.getTime() - startDate.getTimezoneOffset() * 60000
            ).toISOString()
          : "";
        handleChangeSearchValue(startDateISO, "startDate");
      } else {
        handleChangeSearchValue("", "startDate");
      }
      if (value[1]) {
        const endDate = value[1];
        const endDateISO = endDate
          ? new Date(
              endDate.getTime() - endDate.getTimezoneOffset() * 60000
            ).toISOString()
          : "";

        handleChangeSearchValue(endDateISO, "endDate");
      } else {
        handleChangeSearchValue("", "endDate");
      }
    }
  }, [value]);

  return (
    <>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ReturnOrderChildren;
