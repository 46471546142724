import {
  Box,
  Checkbox,
  Group,
  LoadingOverlay,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMTaxCode } from "../../../../model/TblDMTaxCode";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    name: null,
    note: null,
    active: false,
    value: null,
    createBy: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMTaxCode>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<TblDMTaxCode>>(
      `/api/v1/TblDmTaxCode/detail?id=${id}`
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "200px", md: "240px", lg: "320px" }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Group grow wrap="nowrap" mt={10} gap={"lg"}>
          <TextInput
            readOnly
            variant="filled"
            label={"Mã biểu mẫu thuế"}
            type="text"
            {...form.getInputProps("code")}
          />
          <TextInput
            readOnly
            variant="filled"
            label={"Tên biểu mẫu thuế"}
            type="text"
            {...form.getInputProps("name")}
          />
        </Group>

        <Group grow wrap="nowrap" mt={10} gap={"lg"}>
          <TextInput
            readOnly
            variant="filled"
            label={"Giá trị"}
            {...form.getInputProps("value")}
          />
        </Group>

        <Group grow wrap="nowrap" mt={10} mb={"md"} gap={"lg"} align="center">
          <Textarea
            readOnly
            variant="filled"
            label={"Ghi chú"}
            {...form.getInputProps("note")}
          />
        </Group>
        <Checkbox
          label={"Sử dụng"}
          checked={form.values.active || false}
          readOnly
          {...form.getInputProps("active")}
          onChange={() => {}}
        />
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
