import {
  repositoryDelivery,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import {
  GetListOutwardParams,
  GetListSerialOutwardParams,
} from "../types/GetListOutwardParams";
import {
  InventoryItem,
  TblWarehouseOutwardGetList,
} from "../types/TblWarehouseOutward";

export const getListOutward = async (
  params: GetListOutwardParams
): Promise<MessageResponse<TblWarehouseOutwardGetList[]> | undefined> => {
  const baseUrl = "/api/v1/TblItemTransaction/get-list?";

  const url = Object.entries(params)
    .reduce((acc, [key, value]) => {
      if (value !== undefined && value !== "" && value !== null) {
        return `${acc}&${key}=${encodeURIComponent(value)}`;
      }
      return acc;
    }, baseUrl)
    .replace("&", "");

  return repositoryPos.get<MessageResponse<TblWarehouseOutwardGetList[]>>(url);
};

export const getListSerialOutward = async (
  params: GetListSerialOutwardParams
): Promise<MessageResponse<TblWarehouseOutwardGetList[]> | undefined> => {
  const baseUrl =
    "/api/v1/TblItemTransaction/search-list-serial-item-transaction?";

  const url = Object.entries(params)
    .reduce((acc, [key, value]) => {
      if (value !== undefined && value !== "" && value !== null) {
        return `${acc}&${key}=${encodeURIComponent(value)}`;
      }
      return acc;
    }, baseUrl)
    .replace("&", "");

  return repositoryPos.get<MessageResponse<TblWarehouseOutwardGetList[]>>(url);
};

export const getSelectItemPosition = async (
  invId: string | number
): Promise<MessageResponse<InventoryItem[]> | undefined> => {
  const baseUrl = `api/v1/TblDmItemPosition/get-select-by-subInvId?subInvId=${invId}`;
  return repositoryDelivery.get<MessageResponse<InventoryItem[]> | undefined>(
    baseUrl
  );
};
