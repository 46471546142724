import {
  Box,
  ComboboxItem,
  Divider,
  Grid,
  Select,
  TextInput,
} from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { DateTimePicker } from "@mantine/dates";
import {
  getDeliveryDepartmentSelect,
  getTblDmEmployeeSelect,
  getTransferStatusSelect,
  getBranchSelect,
  getInventorySelectMdm,
} from "../../../service/getSelectApi";
import { useResizeObserver } from "@mantine/hooks";
import DetailProduct from "./DetailProduct";

const DeliveryDetailByRequestTransfer = ({
  sourceCode,
}: {
  sourceCode: string;
}) => {
  console.log(sourceCode);
  const [dataRequestTransfer, setDataRequestTransfer] = useState<any>();
  const [dataTblDeliveryDepartmentSelect, setDataTblDeliveryDepartmentSelect] =
    useState<ComboboxItem[]>([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataTransferStatusSelect, setDataTransferStatusSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataInventorySelect, setDataInventorySelect] = useState<
    ComboboxItem[]
  >([]);

  const [scrolled, setScrolled] = useState(false);
  const heightRes = useRef<HTMLDivElement | null>(null);

  const [spanLg, setSpanLg] = useState(1.5);
  const [spanNote, setSpanNote] = useState(12);
  const [ref, { width }] = useResizeObserver();

  const handleResize = useCallback(() => {
    if (width > 1528 && width < 1910) {
      setSpanLg(3);
      setSpanNote(12);
    } else if (width > 1910) {
      setSpanLg(4);
      setSpanNote(8);
    } else {
      setSpanLg(1.5);
      setSpanNote(12);
    }
  }, [width]);

  const getDataRequestTransfer = async () => {
    try {
      const callApi = await repositoryMdm.get<MessageResponse<any>>(
        `api/v1/TblRequestTransfer/detail-by-code?code=${sourceCode}`
      );
      if (callApi) {
        const dataApi = callApi.data;

        if (dataApi != null) {
          setDataRequestTransfer(dataApi);
          Promise.all([
            dataDeliveryDepartmentSelect(),
            fetchDataEmployeeSelect(),
            fetchDataTransferStatusSelect(),
            fetchDataBranchSelect(),
            fetchDataInventorySelect(),
          ]);
        } else {
          NotificationExtension.Fails("Dữ liệu không tồn tại");
        }
      }
    } catch (error) {
      console.error(error);
      NotificationExtension.Fails("An error occurred while fetching data");
    }
  };

  const dataDeliveryDepartmentSelect = async () => {
    const getData = await getDeliveryDepartmentSelect();
    setDataTblDeliveryDepartmentSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataEmployeeSelect = async () => {
    const getData = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataTransferStatusSelect = async () => {
    const getData = await getTransferStatusSelect();
    setDataTransferStatusSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataBranchSelect = async () => {
    const getData = await getBranchSelect();
    setDataBranchSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataInventorySelect = async () => {
    const getData = await getInventorySelectMdm();
    setDataInventorySelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (sourceCode) {
      getDataRequestTransfer();
    }
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    const updateHeight = () => {
      if (heightRes.current) {
        const height = window.innerHeight - 610;
        heightRes.current.style.height = `${height}px`;
      }
    };
    const timeoutId = setTimeout(updateHeight, 100);
    window.addEventListener("resize", updateHeight);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <>
      <Box w={"100%"}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              w={"100%"}
              label="Số phiếu điều chuyển"
              value={dataRequestTransfer?.code ?? ""}
              variant="filled"
              size="xs"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <DateTimePicker
              label="Thời gian lập phiếu"
              value={
                dataRequestTransfer?.createDate
                  ? new Date(dataRequestTransfer?.createDate)
                  : null
              }
              valueFormat="DD-MM-YYYY HH:mm"
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <DateTimePicker
              label="Thời gian hoàn thành"
              value={
                dataRequestTransfer?.finishDate
                  ? new Date(dataRequestTransfer?.finishDate)
                  : null
              }
              valueFormat="DD-MM-YYYY HH:mm"
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Người cập nhật cuối cùng"
              value={dataRequestTransfer?.lastUpdateByName}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Select
              label="Loại phiếu điều chuyển"
              data={[
                {
                  label: "Điều chuyển chi nhánh",
                  value: "DIEU_CHUYEN_CHI_NHANH",
                },
                {
                  label: "Điều chuyển chi nhánh serial",
                  value: "DIEU_CHUYEN_CHI_NHANH_SERIAL",
                },
                {
                  label: "Điều chuyển nội bộ",
                  value: "DIEU_CHUYEN_NOI_BO",
                },
                {
                  label: "Điều chuyển nội bộ serial",
                  value: "DIEU_CHUYEN_NOI_BO_SERIAL",
                },
                {
                  label: "Xuất kho hủy",
                  value: "XUAT_HUY",
                },
                {
                  label: "Xuất kho điều chỉnh",
                  value: "XUAT_DIEU_CHINH",
                },
                {
                  label: "Xuất trả nhà cung cấp",
                  value: "XUAT_TRA_NCC",
                },
              ]}
              value={dataRequestTransfer?.type}
              variant="filled"
              readOnly
              size="xs"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Select
              label="Trạng thái"
              data={[
                {
                  value: "PHIEU_TAO_MOI",
                  label: "Phiếu tạo mới",
                },
                {
                  value: "XUAT_KHO",
                  label: "Xuất kho",
                },
                // {
                //   value: "GIAO_VAN",
                //   label: "Giao vận",
                // },
                {
                  value: "NHAP_KHO",
                  label: "Nhập kho",
                },
                {
                  value: "KHOA",
                  label: "Khóa",
                },
              ]}
              value={dataRequestTransfer?.statusCode}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Trạng thái xuất/nhập"
              value={dataRequestTransfer?.attribute10}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Select
              label="Phương thức giao hàng"
              data={[
                { label: "Không", value: "KHONG" },
                { label: "Nội bộ", value: "NOI_BO" },
                { label: "GHTK", value: "GHTK" },
                { label: "Nhất tín", value: "NHAT_TIN" },
                { label: "SCA", value: "SCA" },
                { label: "Tín thành", value: "TIN_THANH" },
                { label: "Viettel", value: "VIETTEL" },
                { label: "VN Post", value: "VN_POST" },
              ]}
              value={dataRequestTransfer?.attribute1}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Chi nhánh xuất"
              value={dataRequestTransfer?.fromBranchName}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Kho xuất"
              value={dataRequestTransfer?.fromInvName}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Chi nhánh nhận"
              value={dataRequestTransfer?.toBranchName}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Kho nhận"
              value={dataRequestTransfer?.toInvName}
              size="xs"
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Divider my="xs" label="Danh sách sản phẩm" labelPosition="center" />
        <DetailProduct
          id={null}
          sourceCode={sourceCode}
          sourceType={2}
          isDetail={true}
        />
        {/* <ScrollArea
          mt={15}
          ref={heightRes}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped highlightOnHover withTableBorder withColumnBorders>
            <Table.Thead
              className={cx(classes.header, {
                [classes.scrolled]: scrolled,
              })}
            >
              <Table.Tr>
                <Table.Th>Tên sản phẩm</Table.Th>
                <Table.Th>Số lượng</Table.Th>
                <Table.Th>Đơn vị tính</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {dataRequestTransfer?.tblRequestTransferDetailModels?.map(
                (value: any, index: any) => (
                  <Table.Tr key={index}>
                    <Table.Th>{value.itemName}</Table.Th>
                    <Table.Th>{value.primaryQuantity}</Table.Th>
                    <Table.Th>{value.uom}</Table.Th>
                  </Table.Tr>
                )
              )}
            </Table.Tbody>
          </Table>
        </ScrollArea> */}
      </Box>
    </>
  );
};

export default DeliveryDetailByRequestTransfer;
