import { Flex, Image } from "@mantine/core";
import { IconMail, IconPhone, IconWorldWww } from "@tabler/icons-react";
import styles from "../styles/PrintStyle.module.css";
interface PrintedInvoiceProps {
  dataPrint: any;
  typePrint: string;
  userInfo: any;
  currentDate: any;
  componentRef: any;
}

const PrintWarehouseOutWard = ({
  dataPrint,
  typePrint,
  userInfo,
  currentDate,
  componentRef,
}: PrintedInvoiceProps) => {
  const dataTransaction = dataPrint?.tblInventoryTransactionModel;
  const typeOfDebtPayment = JSON.parse(dataTransaction?.attribute2 || "{}");
  const dataSerials = dataPrint?.inventoryTransactionSerialModels;

  const totalQuantity = dataSerials?.reduce(
    (total: number, item: any) => total + item.primaryQuantity,
    0
  );

  const totalAmount = dataSerials?.reduce(
    (total: number, item: any) => total + item.soPrice * item.primaryQuantity,
    0
  );
  const formatCurrency = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };

  return (
    <div ref={componentRef}>
      <div className={styles.containerPrint}>
        <div className={styles.contentMain} style={{ paddingTop: "5px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1.5fr 2fr",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h6 style={{ margin: 0, fontSize: "9px", fontWeight: 600 }}>
                Công Ty Cổ Phần Đầu Tư Công Nghệ HACOM
              </h6>
              <p style={{ margin: 0, fontSize: "8px" }}>
                CN: 129 Lê Thanh Nghị, P. Đồng Tâm, Q. Hai Bà Trưng, TP. Hà Nội
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "3px",
                  alignItems: "top",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "3px",
                  }}
                >
                  <IconPhone
                    stroke={2}
                    style={{ width: "8px", paddingBottom: "13px" }}
                  />
                  <p
                    style={{ fontSize: "8px", margin: 0 }}
                    className={styles.text}
                  >
                    19001903{" |"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "3px",
                  }}
                  className={styles.text}
                >
                  <IconMail
                    stroke={2}
                    style={{ width: "8px", paddingBottom: "13px" }}
                  />
                  <p style={{ margin: 0, fontSize: "8px" }}>
                    dichvukhachhang@hacom.vn{" |"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "3px",
                  }}
                  className={styles.text}
                >
                  <IconWorldWww
                    stroke={2}
                    style={{ width: "8px", paddingBottom: "13px" }}
                  />
                  <p style={{ margin: 0, fontSize: "7px" }}>Hacom.vn</p>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "10px" }}>
              <h1
                className={styles.text}
                style={{
                  fontWeight: "700",
                  lineHeight: "1",
                  fontSize: "18px",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                PHIẾU XUẤT KHO
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  fontSize: "8px",
                }}
                className={styles.warehouseCode}
              >
                <p style={{ marginRight: "2px" }}>Mã Xuất Kho: </p>{" "}
                <p>{dataTransaction?.transactionCode}</p>
              </div>
              <div
                className={styles.warehouseCode}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  fontSize: "8px",
                }}
              >
                <p style={{ marginRight: "2px" }}>Mã Yêu Cầu Xuất Kho: </p>
                <p>{dataTransaction?.sourceCode}</p>
              </div>
            </div>
          </div>
          {/* bảng thông tin bên xuất, bên nhận */}
          <div className={styles.customerInformation}>
            <table>
              <thead>
                <tr>
                  <th colSpan={2} style={{ width: "50%" }}>
                    BÊN XUẤT
                  </th>
                  <th colSpan={2} style={{ width: "50%" }}>
                    BÊN NHẬN
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      width: "16%",
                      maxWidth: "30%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Phòng ban
                  </td>
                  <td>{dataTransaction?.fromBranchName}</td>
                  {typePrint === "XUAT_BAN" ? (
                    <>
                      {" "}
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Họ và tên
                      </td>
                      <td> {dataTransaction?.cusName}</td>
                    </>
                  ) : (
                    <>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Tên Nhà Cung Cấp
                      </td>
                      <td>{dataTransaction?.cusName}</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      width: "16%",
                      maxWidth: "30%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Địa điểm làm việc
                  </td>
                  <td>{dataTransaction?.fromBranchAddress}</td>
                  {typePrint === "XUAT_BAN" ? (
                    <>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Số điện thoại
                      </td>
                      <td> {dataTransaction?.cusTelephone}</td>
                    </>
                  ) : (
                    <>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Mã Nhà Cung Cấp
                      </td>
                      <td>{dataTransaction?.cusCode}</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      width: "16%",
                      maxWidth: "30%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Bộ phận
                  </td>
                  <td>{userInfo?.depName}</td>
                  {typePrint === "XUAT_BAN" ? (
                    <>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Tỉnh / Thành
                      </td>
                      <td>{dataTransaction?.cusProvinceName}</td>
                    </>
                  ) : (
                    <>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Người nhận
                      </td>
                      <td>{dataTransaction?.toEmpName}</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      width: "16%",
                      maxWidth: "30%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Chức danh
                  </td>
                  <td>{userInfo?.roleName}</td>
                  {typePrint === "XUAT_BAN" ? (
                    <>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Quận / Huyện
                      </td>
                      <td>{dataTransaction?.cusDestrictName}</td>
                    </>
                  ) : (
                    <>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Số điện thoại
                      </td>
                      <td>{dataTransaction?.cusTelephone}</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      width: "16%",
                      maxWidth: "30%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Họ và tên
                  </td>
                  <td>{userInfo?.fullName}</td>
                  {typePrint === "XUAT_BAN" ? (
                    <>
                      {" "}
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Phường / Xã
                      </td>
                      <td>{dataTransaction?.cusCommuneName}</td>
                    </>
                  ) : (
                    <>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {""}
                      </td>
                      <td>{""}</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      width: "16%",
                      maxWidth: "30%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Kho
                  </td>
                  <td>{dataTransaction?.fromInvName}</td>
                  {typePrint === "XUAT_BAN" ? (
                    <>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Địa chỉ cụ thể
                      </td>
                      <td>{dataTransaction?.cusAddress}</td>
                    </>
                  ) : (
                    <>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {""}
                      </td>
                      <td>{""}</td>
                    </>
                  )}
                </tr>
                {typePrint === "XUAT_BAN" ? (
                  <>
                    <tr>
                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Hình thức thanh toán
                      </td>
                      <td>{typeOfDebtPayment?.payLaterMethod}</td>

                      <td
                        style={{
                          width: "16%",
                          maxWidth: "30%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Thời gian công nợ
                      </td>
                      <td>
                        {typeOfDebtPayment?.debtInfo
                          ? `${typeOfDebtPayment?.debtInfo.dueDate} ngày`
                          : "0"}
                      </td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </div>

          {/* lý do */}
          <div style={{ margin: "5px 0" }}>
            <Flex direction="row">
              <p style={{ margin: 0, marginRight: "5px" }}>Lý do:</p>
              <p style={{ margin: 0 }}>
                {typePrint === "XUAT_BAN"
                  ? "Xuất Bán"
                  : "Xuất trả Nhà Cung Cấp"}
              </p>
            </Flex>
            <div>
              <p style={{ margin: 0 }}>Ghi chú:</p>
            </div>
          </div>
          {/* table sản phẩm */}
          <div className={styles.ProductMain}>
            <table>
              <thead>
                <tr>
                  <th>STT</th>
                  <th
                    style={{
                      width: "40px",
                    }}
                  >
                    Mã Hàng
                  </th>
                  <th
                    style={{
                      width: "20px",
                    }}
                  >
                    Serial
                  </th>
                  <th className={styles.thNameProduct}>Tên sản phẩm</th>
                  <th>ĐVT</th>
                  <th>SL</th>
                  {typePrint === "XUAT_TRA_NCC" ? (
                    <>
                      <th>ĐVT2</th>
                      <th>SL2</th>
                    </>
                  ) : (
                    <>
                      <th>Giá bán lẻ</th>
                      <th>Thành tiền</th>
                    </>
                  )}

                  {/* <th>Tình trạng</th> */}
                  <th
                    style={{
                      width: "100px",
                    }}
                  >
                    Ghi chú
                  </th>
                </tr>
              </thead>
              <tbody>
                <>
                  <tr>
                    <td
                      colSpan={6}
                      style={{
                        textAlign: "end",
                        marginRight: "3px",
                        border: "none",
                      }}
                    >
                      {totalQuantity}
                    </td>
                    <td style={{ border: "none" }}></td>
                    {typePrint === "XUAT_TRA_NCC" ? (
                      <>
                        <td style={{ border: "none" }}></td>
                      </>
                    ) : (
                      <>
                        <td style={{ textAlign: "end", border: "none" }}>
                          {formatCurrency(totalAmount)}
                        </td>
                      </>
                    )}
                    <td style={{ border: "none" }} colSpan={2}></td>
                  </tr>
                </>

                {dataSerials?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td> {item?.itemCode}</td>
                      <td
                        style={{
                          width: "20px",
                          wordWrap: "break-word",
                        }}
                      >
                        {item?.serial}
                      </td>
                      <td
                        style={{
                          width: "400px",
                          textAlign: "start",
                        }}
                      >
                        {item?.itemName}
                      </td>
                      <td>{item?.primaryUomCode} </td>
                      <td style={{ textAlign: "center" }}>
                        {item?.primaryQuantity}
                      </td>
                      {typePrint === "XUAT_TRA_NCC" ? (
                        <>
                          <td>{item?.subUomCode}</td>
                          <td>{item?.subQuantity}</td>
                        </>
                      ) : (
                        <>
                          <td style={{ textAlign: "end" }}>
                            {formatCurrency(item?.soPrice)}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {formatCurrency(
                              item?.soPrice * item?.primaryQuantity
                            )}
                          </td>
                        </>
                      )}

                      {/* <td>{item.serialStatus}</td> */}
                      {/* <td>Mới 100%</td> */}
                      <td>{item?.note}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/*  */}
          <div style={{ marginTop: "5px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
                fontSize: "14px",
              }}
            >
              <div>
                Hà Nội ngày{" "}
                <strong style={{ fontStyle: "italic" }}>{currentDate}</strong>{" "}
              </div>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                width: "100%",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <p style={{ fontWeight: "700" }}>BÊN XUẤT</p>
                <div
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {userInfo?.fullName}
                  </p>
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <p style={{ fontWeight: "700" }}>GIAO VẬN</p>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <p style={{ fontWeight: "700" }}>BIÊN NHẬN</p>
              </div>
            </div>
          </div>
          <div className={styles.backgroudHacom}>
            <Image
              radius="md"
              src="https://hanoicomputercdn.com/media/lib/17-10-2024/logo-hacom-since-2001_new.png"
              className={styles.logo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintWarehouseOutWard;
