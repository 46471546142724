import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMDeliveryDepartment } from "../../../../model/TblDMDeliveryDepartment";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { useEffect, useState } from "react";
import {
  getBranchSelectMdm,
  getTblDmEmployeeSelect,
} from "../../../../service/getSelectApi";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";

const CreateDataView = ({ onClose }: CreateDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    companyCode: null,
    ddName: null,
    note: null,
    active: false,
    branch: null,
    managerId: null,
  };

  const [dataBranchdSelect, setDataBranchdSelect] = useState<ComboboxItem[]>(
    []
  );

  const [dataEmployeedSelect, setDataEmployeedSelect] = useState<
    ComboboxItem[]
  >([]);

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [selectedBranches, setSelectedBranches] = useState([]);

  const handleBranchChange = (values: any) => {
    setSelectedBranches(values);
    form.setValues({ branch: values.join(",") });
  };

  const form = useForm<TblDMDeliveryDepartment>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      ddName: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên bộ phận giao hàng !";
        }
        return hasLength(
          { min: 5, max: 200 },
          "Tên bộ phận giao hàng phải nằm trong khoảng từ 5 đến 200 kí tự!"
        )(value as string);
      },
      code: (value: any | null) => {
        const invalidChars =
          /[âăáàảãạêếềệễểíìịĩỉóòỏõọôốồộổỗơớờợỡởúùủũụưứừựữửýỳỷỹỵđ]/i;
        if (invalidChars.test(value)) {
          return "Mã bộ phân giao hàng chứa những kí tự không hợp lệ !";
        }
        if (!value) {
          return "Vui lòng nhập mã bộ phận giao hàng !";
        }
        return hasLength(
          { max: 20 },
          "Mã bộ phận giao hàng không được dài hơn 20 ký tự !"
        )(value as string);
      },
      companyCode: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập mã công ty !";
        }
        value as number;
      },
      note: (value: string | null) => {
        if (value && hasLength({ max: 800 })(value)) {
          return "Ghi chú không quá 800 kí tự!";
        }
      },
      branch: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn chi nhánh !";
        }
      },
      managerId: (value: number | string | null) => {
        if (!value) {
          return "Vui lòng chọn người quản lý !";
        }
        value as number;
      },
    },
  });

  const handleCreateTblDMDeliveryDepartment = async (
    dataSubmit: TblDMDeliveryDepartment
  ) => {
    open();
    const dataApi = await repositoryMdm.post<
      MessageResponse<TblDMDeliveryDepartment>
    >("/api/v1/TblDMDeliveryDepartment/create", dataSubmit);
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  const dataBranchSelect = async () => {
    const get = await getBranchSelectMdm();
    setDataBranchdSelect(
      get
        .filter((item) => item?.text != null && item?.value != null)
        .map((item) => ({
          value: item.value,
          // value: item.text,
          label: item.text,
        }))
    );
  };

  const dataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeedSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          // value: item.text,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    Promise.all([dataBranchSelect(), dataEmployeeSelect()]);
  }, []);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "250px", md: "300px", lg: "400px" }}
        onSubmit={form.onSubmit((e: TblDMDeliveryDepartment) => {
          handleCreateTblDMDeliveryDepartment(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã"}
              placeholder={"Nhập mã"}
              type="text"
              onKeyDown={handleKeyDown}
              withAsterisk
              {...form.getInputProps("code")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <TextInput
              label={"Tên"}
              placeholder={"Nhập tên"}
              type="text"
              withAsterisk
              {...form.getInputProps("ddName")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <MultiSelect
              label={"Chi nhánh"}
              placeholder={"Nhập tên chi nhánh"}
              searchable
              clearable
              data={dataBranchdSelect}
              hidePickedOptions
              nothingFoundMessage="Không có dữ liệu !!!"
              {...form.getInputProps("branch")}
              value={selectedBranches}
              onChange={handleBranchChange}
              withAsterisk
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label={"Người quản lý"}
              placeholder={"Nhập tên người quản lý"}
              withAsterisk
              searchable
              clearable
              data={dataEmployeedSelect}
              {...form.getInputProps("managerId")}
              nothingFoundMessage="Không có dữ liệu !!!"
            />
          </Grid.Col>
        </Grid>

        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <TextInput
              label={"Mã công ty"}
              placeholder={"Nhập mã công ty"}
              {...form.getInputProps("companyCode")}
              type="number"
              withAsterisk
            />
          </Grid.Col>
        </Grid>
        <Textarea
          label={"Ghi chú"}
          placeholder="Nhập ghi chú"
          {...form.getInputProps("note")}
        />
        <Checkbox label={"Sử dụng"} {...form.getInputProps("active")} mt={10} />

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  onClose: any;
};
