import { Badge, ComboboxItem, Text } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { _timerDelayClick } from "../../../_base/_const/_constVar";
import { _localization } from "../../../config/location";
import "../../../Styles/tab.component.css";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import { green } from "../../../const/variables";

const DeliveryWarranty = ({ dataDetail }: { dataDetail: any }) => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [, setDataTemp] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [listCustId, setListCustId] = useState<any[]>([]);
  const [listCustomer, setListCustomer] = useState<any[]>([]);
  const [dataTblBranch, setDataTblBranch] = useState<ComboboxItem[]>([]);
  // const [listItemId, setListItemId] = useState<any[]>([]);
  const [listItem, setListItem] = useState<any[]>([]);
  const [dataEmployee, setDataEmployee] = useState<any[]>([]);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [selectProcessingMethod, setSelectProcessingMethod] = useState<
    string | null
  >(null);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "requestNum",
        header: "Số phiếu yêu cầu",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "errorNumber",
        header: "Số phiếu nhận hàng lỗi",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Chưa lập phiếu" : renderedCellValue}
          </Badge>
        ),
        size: 225,
      },
      {
        accessorKey: "requestStatusName",
        header: "Trạng thái",
        Cell: ({ row }) => (
          <Badge
            radius={"sm"}
            color={getColorStatus(row.original.requestStatusName?.toString())}
          >
            {row.original.requestStatusName}
          </Badge>
        ),
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: [
            { label: "Chờ phân công", value: "Chờ phân công" },
            { label: "Hủy phân công", value: "Hủy phân công" },
            { label: "Đã phân công", value: "Đã phân công" },
            { label: "Kỹ thuật hủy", value: "Kỹ thuật hủy" },
            { label: "Đã xử lý", value: "Đã xử lý" },
            { label: "Xử lý lại", value: "Xử lý lại" },
          ],
        },
        filterVariant: "select",
      },
      {
        accessorKey: "priority",
        header: "Độ ưu tiên",
        Cell: ({ row }) => (
          <Badge color={getColorPriority(row.original.priority)} radius={"sm"}>
            {row.original.priority}
          </Badge>
        ),
      },
      {
        accessorKey: "custMeeting",
        header: "Khách hàng hẹn gặp",
        Cell: ({ row }) => <AvatarUtils value={row.original.custMeeting} />,
      },
      {
        accessorKey: "custPhone",
        header: "SĐT",
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ hẹn gặp",
        Cell: ({ renderedCellValue, row }: any) => (
          <Text fw={"500"} size="12.5px">
            {row.original.additionalAddress !== null
              ? `${row.original.additionalAddress} - ${renderedCellValue}`
                  .replace(/\s*-\s*-\s*/g, " - ")
                  .replace(/^\s*-\s*/, "")
              : `${renderedCellValue}`.replace(/^\s*-\s*/, "")}{" "}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "processingMethodName",
        header: "Hình thức xử lý",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => (
          <Badge
            color={getColorProcessingMethod(row.original.processingMethodName)}
            radius={"sm"}
          >
            {row.original.processingMethodName}
          </Badge>
        ),
        filterFn: "equals",
        mantineFilterSelectProps: {
          data: [
            { label: "Xử lý tại nhà", value: "Xử lý tại nhà" },
            { label: "Xử lý tại CH/CN", value: "Xử lý tại CH/CN" },
            { label: "Hãng xử lý", value: "Hãng xử lý" },
            { label: "Xử lý trực tiếp", value: "Xử lý trực tiếp" },
            { label: "Xử lý từ xa", value: "Xử lý từ xa" },
          ],
        },
        filterVariant: "select",
        size: 175,
      },
      {
        accessorKey: "empnameincharge",
        header: "Người nhận phân công",
        Cell: ({ row }) => <AvatarUtils value={row.original.empnameincharge} />,
        size: 225,
      },
      {
        accessorKey: "Đánh giá mức độ",
        header: "Đánh giá mức độ",
      },
      {
        accessorKey: "Điểm",
        header: "Điểm",
      },
      {
        accessorKey: "branchName",
        header: "Nơi nhận yêu cầu",
      },
      //   {
      //     accessorKey: "meetingDate",
      //     header: "Ngày hẹn",
      //     Cell: ({ renderedCellValue }: any) => (
      //       <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
      //     ),
      //     enableColumnFilter: false,
      //   },
      //   {
      //     accessorKey: "createDate",
      //     header: "Ngày tiếp nhận",
      //     Cell: ({ renderedCellValue }: any) => (
      //       <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
      //     ),
      //     enableColumnFilter: false,
      //   },
      {
        accessorKey: "content",
        header: "Nội dung",
      },
    ],
    [listItem, listCustomer, selectProcessingMethod, dataTblBranch, dataDetail]
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  function getColorProcessingMethod(value: string) {
    switch (value) {
      case "Xử lý tại nhà":
        return "lime";
      case "Hãng xử lý":
        return "green";
      case "Xử lý trực tiếp":
        return "teal";
      case "Xử lý tại CH/CN":
        return "cyan";
      case "Xử lý từ xa":
        return "orange";
      case "":
      case null:
        return "rgba(255, 255, 255, 0)";
    }
  }

  function getColorStatus(status: string) {
    switch (status) {
      case "PHAN_CONG":
      case "Đã phân công":
      case "NHAP_HANG_CHUYEN_KHO":
      case "Đã xử lý":
      case "HANG_DA_XU_LY":
        return "#388E3C";

      case "Chờ phân công":
      case "NHAP_KHO_BH":
      case "DA_LAP_PHIEU_BN":
      case "TIEP_NHAN":
      case "Xử lý lại":
      case "KI_THUAT_DA_XU_LY":
      case "Hãng đang xử lý":
      case "Chưa xử lý":
        return "#FBC02D";

      case "Hủy phân công":
      case "Kỹ thuật hủy":
        return "#C62828";

      case "CHO_PHIEU_BN":
        return "#424242";

      case "":
      case null:
        return "rgba(255, 255, 255, 0)";

      default:
        return "#424242";
    }
  }

  function getColorPriority(value: string | null) {
    switch (value) {
      case "Cao":
        return "#1E5287";
      case "Thấp":
        return "#D0F66A";
      case null:
      case "":
        return "rgba(255, 255, 255, 0)";
    }
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const updateListCustId = () => {
      const uniqueItemId = Array.from(
        new Set(
          data.map((item) => item.custId).filter((custId) => custId !== null)
        )
      ).map(Number);
      setListCustId(uniqueItemId);
    };

    updateListCustId();
  }, [data]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (170 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data: dataDetail ? dataDetail : [],
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    enableBottomToolbar: false,
    mantineSelectAllCheckboxProps: {
      style: {
        display: "none",
      },
    },
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "requestNum"],
        right: ["processingMethodName", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 500, minHeight: 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default DeliveryWarranty;
