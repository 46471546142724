import {
  Box,
  Checkbox,
  ComboboxItem,
  Fieldset,
  Grid,
  LoadingOverlay,
  ScrollArea,
  Select,
  Table,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { DateTimePicker } from "@mantine/dates";
import { getTblDmEmployeeSelect } from "../../../../service/getSelectApi";
import cx from "clsx";
import classes from "../../../../Styles/TableScrollArea.module.css";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [scrolled, setScrolled] = useState(false);
  const [visible, { toggle, close, open }] = useDisclosure(false);

  const entity = {
    id: "0",
    code: null,
    cycleCountPeriodId: null,
    cycleCountDate: null,
    employeeId: null,
    isCustomerInv: false,
    status: null,
    autoDetectSubInvOrg: null,
    note: null,
    subInvCode: null,
    tblInventoryCycleCountDetailModels: [
      {
        id: null,
        invCycleCountId: null,
        serialCode: null,
        serialStatus: null,
        quantity: null,
        note: null,
      },
    ],
  };

  const form = useForm<any>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblInventoryCycleCount/detail?id=${id}`
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        Promise.all([fetchDataEmployee()]);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const fetchDataEmployee = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
      console.log(form.values);
    }
  }, [id]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "350px", md: "400px", lg: "575px" }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label="Số phiếu kiểm kê"
              {...form.getInputProps("code")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label="Đợt kiểm kê"
              {...form.getInputProps("cycleCountPeriodId")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <Select
              label="Người kiểm kê"
              data={dataEmployeeSelect}
              value={form.values.employeeId?.toString()}
              rightSection={" "}
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <DateTimePicker
              label="Ngày thực hiện"
              valueFormat="DD/MM/YYYY"
              value={
                form.values.cycleCountDate
                  ? new Date(form.values.cycleCountDate)
                  : null
              }
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <TextInput
              label="Kho thực hiện"
              {...form.getInputProps("subInvCode")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label="Trạng thái"
              {...form.getInputProps("status")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <TextInput
              label="Diễn giải"
              {...form.getInputProps("note")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Checkbox
          label="Kho khách"
          checked={form.values.isCustomerInv ?? false}
          onChange={(event) => event.preventDefault()}
        />
        <Fieldset legend="Danh sách mã serial" mt={10}>
          <ScrollArea
            mt={10}
            h={135}
            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
          >
            <Table striped highlightOnHover withColumnBorders withTableBorder>
              <Table.Thead
                className={cx(classes.header, { [classes.scrolled]: scrolled })}
              >
                <Table.Tr>
                  <Table.Th>Mã Serial</Table.Th>
                  <Table.Th>Trạng thái Serial</Table.Th>
                  <Table.Th>Số lượng sản phẩm</Table.Th>
                  <Table.Th>Diễn giải</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {form.values.tblInventoryCycleCountDetailModels?.map(
                  (item: any) => (
                    <Table.Tr>
                      <Table.Th>{item.serialCode}</Table.Th>
                      <Table.Th>{item.serialStatus}</Table.Th>
                      <Table.Th>{item.quantity}</Table.Th>
                      <Table.Th>{item.note}</Table.Th>
                    </Table.Tr>
                  )
                )}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        </Fieldset>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
