import { Paper, Tabs } from "@mantine/core";
import { useState } from "react";
import ExportWarrantyVendor from "./ExportWarrantyVendor/ExportWarrantyVendor";
import VendorReceiveStatus from "./VendorReceiveStatus/VendorReceiveStatus";

const SupplierWarrantyExport = () => {
  const [activeTab, setActiveTab] = useState<string | null>(
    "exportWarrantyVendor"
  );

  return (
    <>
      <Paper shadow="md" radius="lg">
        <Tabs p={"xs"} value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="exportWarrantyVendor">
              Phiếu xuất hàng lỗi bảo hành nhà cung cấp
            </Tabs.Tab>
            <Tabs.Tab value="vendorReceiveStatus">
              Phiếu nhận bảo hành nhà cung cấp
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="exportWarrantyVendor">
            {activeTab === "exportWarrantyVendor" ? (
              <ExportWarrantyVendor />
            ) : (
              <></>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="vendorReceiveStatus">
            {activeTab === "vendorReceiveStatus" ? (
              <VendorReceiveStatus />
            ) : (
              <></>
            )}
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </>
  );
};

export default SupplierWarrantyExport;
