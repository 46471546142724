import {
  Box,
  Button,
  Flex,
  ScrollArea,
  Table,
  Title,
  Text,
  Input,
  Checkbox,
  ComboboxItem,
  NumberInput,
  Tooltip,
} from "@mantine/core";
import { IconPlus, IconRecycle, IconTrash, IconX } from "@tabler/icons-react";
import { useState, useEffect, useCallback } from "react";
import cx from "clsx";
import classes from "../../Styles/ScrollTable.module.css";
import { modals } from "@mantine/modals";
import ModalSuperMarket from "../../Modals/SuperMarket";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import { UseFormReturnType } from "@mantine/form";
import {
  listBranch,
  tblPromotion,
  tblPromotionCommand,
} from "../../../../../../model/TblPromotion";
import { NavLink } from "react-router-dom";
import { red, teal } from "../../../../../../const/variables";
import { getMasterOfSelect } from "../../../../../../service/getSelectApi";
import React from "react";
import { useDebouncedCallback, useDebouncedState } from "@mantine/hooks";
import { TblDmCustomerCategory } from "../../../../../../model/TblDmCustomerCategory";
import {
  CustomerGroup,
  TblDmCustomer,
} from "../../../../../../model/TblDmCustomer";

const Customers = ({ data }: { data: CustomerGroup[] }) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 450);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <Box mt={10}>
      <ScrollArea h={height}>
        <Table style={{ overflowY: "auto", height: "30px" }}>
          <Table.Thead className={cx(classes.header)}>
            <Table.Tr>
              <Table.Th>Nhóm khách hàng</Table.Th>
              <Table.Th>Mã</Table.Th>
              <Table.Th>Mô tả</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody mih={100}>
            {data?.map((branch, index) => (
              <Table.Tr key={index}>
                <Table.Td>{branch.name}</Table.Td>
                <Table.Td>{branch.code}</Table.Td>
                <Table.Td>{branch.description}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {data?.length < 1 && <Table.Caption>Không có dữ liệu</Table.Caption>}
        </Table>
      </ScrollArea>
    </Box>
  );
};

export default React.memo(Customers);
