import {
  Box,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Textarea,
  TextInput,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { UpdateVendorReceiveStatus } from "../../../../model/UpdateVendorReceiveStatus";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";
import { sky_blue } from "../../../../const/variables";

const EditDataView = ({
  id,
  setData,
  setListVendorReceiveStatus,
  minDate,
}: EditDataViewProps) => {
  const entity = {
    id: Number(id),
    dateVendorReceive: null,
    orderCode: null,
    noteByVendor: null,
  };

  const [visible] = useDisclosure(false);

  const form = useForm<UpdateVendorReceiveStatus>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      dateVendorReceive: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập ngày nhà cung cấp nhận !";
        }
      },
      orderCode: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập số phiếu nhà cung cấp nhận !";
        }
      },
      noteByVendor: (value: string | null) => {
        if (value) {
          return hasLength(
            { max: 200 },
            "Ghi chú không được dài quá 200 kí tự !"
          )(value as string);
        }
      },
    },
  });

  const updateStateWithNewObject = (newObject: any) => {
    setListVendorReceiveStatus((prevState: any) => {
      const updatedState = prevState.filter(
        (obj: any) => obj.id !== newObject.id
      );
      return [...updatedState, newObject];
    });

    setData((prevData: any) => {
      return prevData.map((item: any) =>
        item.id === newObject.id ? { ...item, ...newObject } : item
      );
    });
  };

  return (
    <Box
      component="form"
      mx="auto"
      w={"50vw"}
      maw={500}
      onSubmit={form.onSubmit((e: any) => {
        updateStateWithNewObject(e);
        modals.closeAll();
      })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label="Số phiếu nhà cung cấp nhận"
            placeholder="Nhập số phiếu"
            withAsterisk
            {...form.getInputProps("orderCode")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <DatePickerInput
            label="Ngày nhà cung cấp nhận"
            placeholder="Chọn ngày nhà cung cấp nhận"
            valueFormat="DD/MM/YYYY"
            minDate={new Date(minDate)}
            onChange={(value) =>
              form.setValues((prev) => ({
                ...prev,
                dateVendorReceive: value
                  ? new Date(value ?? "").toDateString()
                  : null,
              }))
            }
            clearable
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Textarea
            label="Ghi chú"
            placeholder="Ghi chú"
            {...form.getInputProps("noteByVendor")}
          />
        </Grid.Col>
      </Grid>
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
        >
          Quay lại
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default EditDataView;

type EditDataViewProps = {
  id: string | number;
  onClose: any;
  setData: any;
  setListVendorReceiveStatus: any;
  minDate: any;
};
