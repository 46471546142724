import { Badge, Box, Button, Flex, Input, Select } from "@mantine/core";
import {
  IconActivity,
  IconHandStop,
  IconUpload,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { _localization } from "../../config/location";
import { ListRoleModel } from "../../model/ListRoleModel ";
import { MessageResponse } from "../../model/MessageResponse";
import "../../Styles/tab.component.css";
import {
  _timerDelayClick,
  repositoryMaster,
} from "../../_base/_const/_constVar";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { useDebouncedState } from "@mantine/hooks";

const AuthListRoleView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  //data and fetching state
  const [data, setData] = useState<ListRoleModel[]>([]);
  const [dataTemp, setDataTemp] = useState<ListRoleModel[]>([]);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //

  const [dateSelect, setDataSelect] = useState<string[]>([]);
  const [dateSelectValue, setDataSelectValue] = useState<string>();
  const [keySearch, setKeySearch] = useDebouncedState("", 300);
  const [selectIds, setSelectIds] = useState<string[]>([]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);
  //count
  const [timeLeft, setTimeLeft] = useState(1);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const [isFrist, SetIsFrist] = useState<boolean>(false);
  useEffect(() => {
    fetchData();
    SetIsFrist(true);
  }, []);

  useEffect(() => {
    let searchResult: ListRoleModel[] = dataTemp;
    if (keySearch.length > 0) {
      searchResult = dataTemp.filter(
        (product) =>
          // Chuyển đổi các giá trị trong các trường thành chữ thường và so sánh với từ khóa tìm kiếm
          product.createBy?.toLowerCase().includes(keySearch) ||
          product.name?.toLowerCase().includes(keySearch) ||
          product.key?.toLowerCase().includes(keySearch) ||
          product.app?.toLowerCase().includes(keySearch) ||
          product.route?.toLowerCase().includes(keySearch)
      );
    }
    if (dateSelectValue && dateSelectValue.length > 0) {
      searchResult = searchResult.filter(
        (product) => product.app === dateSelectValue
      );
    }
    setData(searchResult);
  }, [dateSelectValue, keySearch]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    try {
      const json = await repositoryMaster.get<MessageResponse<ListRoleModel[]>>(
        "/api/v1/ListRole/keys-tree"
      );
      if (json && json.data) {
        const treeData = buildTree(json.data);
        setData(treeData);
        setRowCount(json.totalCount);
        setDataTemp(treeData);
        const uniqueApps = Array.from(
          new Set(json.data.map((item) => item.app ?? ""))
        );
        if (uniqueApps && uniqueApps.length > 0) setDataSelect(uniqueApps);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const activeData = async (status: boolean) => {
    if (selectIds.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryMaster.post<MessageResponse<boolean>>(
          "/api/v1/ListRole/actives",
          {
            ids: selectIds,
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const activesData = async (status: boolean, id: string) => {
    if (!id || id.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryMaster.post<MessageResponse<boolean>>(
          "/api/v1/ListRole/actives",
          {
            ids: [id],
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const syncData = async () => {
    try {
      const res = await repositoryMaster.get<MessageResponse<boolean>>(
        "/api/v1/RoleHandler/sync-keys-to-app"
      );
      if (res && res.success) {
        NotificationExtension.Success("Đồng bộ thành công !");
        await fetchData();
      } else NotificationExtension.Fails("Đồng bộ thất bại !");
    } catch (error) {
      console.error(error);
      return;
    }
  };

  function buildTree(
    data: ListRoleModel[],
    parentId: string | null = null
  ): ListRoleModel[] {
    return data
      .filter((item) => item.parentId === parentId)
      .map((item) => ({
        ...item,
        subRows: buildTree(data, item.id),
      }));
  }
  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  const columns = React.useMemo<MRT_ColumnDef<ListRoleModel>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },

      {
        accessorKey: "name",
        header: "Tên chức năng",
        size: 390,
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row && row.original?.parentId && row.original?.parentId?.length > 0
              ? "----- " + renderedCellValue
              : renderedCellValue}
          </>
        ),
      },
      {
        accessorKey: "key",
        header: "Mã chức năng",
        size: 450,
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row && row.original?.parentId && row.original?.parentId?.length > 0
              ? "----- " + renderedCellValue
              : renderedCellValue}
          </>
        ),
      },
      {
        accessorKey: "inActive",
        header: "Trạng thái",
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row.original.parentId && (
              <Badge color={getColorStatus(row.original.inActive ?? false)}>
                {row.original.inActive ? "Đang hoạt động" : "Dừng hoạt động"}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "app",
        header: "Service",
      },
      {
        accessorKey: "route",
        header: "Router",
      },

      {
        accessorKey: "httpMethod",
        header: "Phương thức",
      },
      {
        accessorKey: "createBy",
        header: "Người tạo",
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",

        Cell: ({ renderedCellValue, row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            {row.original.parentId && (
              <Button
                leftSection={
                  row.original.inActive === true ? (
                    <IconHandStop size={14} />
                  ) : (
                    <IconActivity size={14} />
                  )
                }
                color={row.original.inActive === true ? "red" : "green"}
                variant="outline"
                onClick={async () => {
                  await activesData(!row.original.inActive, row.original.id);
                }}
              >
                {row.original.inActive === true
                  ? "Dừng hoạt động"
                  : "Hoạt động"}
              </Button>
            )}
          </Box>
        ),
      },
    ],
    []
  );
  const [openedMenu, setOpenedMenu] = useState(false);

  const initialExpandedRootRows = useMemo<MRT_ExpandedState>(
    () =>
      data
        .map((originalRow) => originalRow.id) //get all the root row ids, use recursion for additional levels
        .reduce((a, v) => ({ ...a, [v]: true }), {}), //convert to an object with all the ids as keys and `true` as values
    []
  );
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enablePagination: false,
    enableRowVirtualization: true,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setKeySearch(e.target.value.toLowerCase() ?? "");
            }}
          />
          {dateSelect && (
            <Select
              placeholder="Lọc theo ứng dụng"
              data={dateSelect}
              clearable
              onChange={(e) => {
                setDataSelectValue(e ?? "");
              }}
            />
          )}

          {/* <Button
                        leftSection={<IconSearch size={14} />}
                        color="blue"
                        size="sm"
                        variant="outline"
                        onClick={async () => {
                            await fetchData();
                        }}
                    >
                        Tìm kiếm
                    </Button> */}
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconActivity size={14} />}
            color="green"
            variant="outline"
            onClick={async () => {
              await activeData(true);
            }}
            disabled={selectIds.length < 1}
          >
            Hoạt động
          </Button>
          <Button
            leftSection={<IconHandStop size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await activeData(false);
            }}
            disabled={selectIds.length < 1}
          >
            Dừng hoạt động
          </Button>

          <Button
            leftSection={<IconZoomReset size={14} />}
            color="violet"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
            disabled={isCounting}
          >
            {timeLeft > 0 && timeLeft} Load data
          </Button>
          <Button
            leftSection={<IconUpload size={14} />}
            color=""
            variant="outline"
            onClick={async () => {
              await syncData();
            }}
            disabled={isCounting}
          >
            {timeLeft > 0 && timeLeft} Đồng bộ chức năng
          </Button>
        </Flex>
      </>
    ),
    //  manualPagination: true,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    enableExpanding: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand", "name"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      expanded: initialExpandedRootRows,
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount: data.length,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
        //   backgroundColor: row.original.route !==undefined && row.original.route?.length>0 ? "white" : "#f1f3f5",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["50", "100", "150"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    rowVirtualizerOptions: {
      overscan: 5,
    },
  });

  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default AuthListRoleView;
