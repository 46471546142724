import { Box, Button, Group, Select, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import React from "react";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { sky_blue } from "../../../const/variables";

const ModalApprovePromotion = ({
  ids,
  fetchData,
}: {
  ids: string[];
  fetchData: () => Promise<void>;
}) => {
  const [visible, { toggle, close, open }] = useDisclosure(false);
  const dataOption = [
    { value: "1", label: "Chưa duyệt" },
    { value: "2", label: "Đã duyệt" },
    { value: "3", label: "Tạm dừng" },
    { value: "4", label: "Từ chối" },
    { value: "5", label: "Hủy duyệt" },

  ];
  const entity = {
    status: null,
    note: null,
  };

  const form = useForm({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      status: (value) => (!value ? "Vui lòng chọn trạng thái duyệt" : null),
    },
  });

  const handleApprovePromotion = async (dataSubmit: any) => {
    open();
    const data = { ids: ids, ...dataSubmit };
    const dataApi = await repositoryPos.post(
      `/api/v1/TblPromotion/approves`,
      data
    );

    if (dataApi && dataApi.success) {
      await fetchData();
      NotificationExtension.Success("Duyệt chính sách thành công");
      modals.closeAll();
    } else {
      NotificationExtension.Fails("Duyệt chính sách thất bại");
    }
    close();
  };

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit(async (e) => {
        handleApprovePromotion(e);
      })}
      style={{ position: "relative" }}
    >
      <Select
        label="Trạng thái"
        placeholder="Chọn..."
        mt={"xs"}
        clearable
        withAsterisk
        data={dataOption}
        {...form.getInputProps("status")}
      />
      <Textarea
        label="Ghi chú"
        placeholder="Nhập ghi chú"
        mt={"xs"}
        {...form.getInputProps("note")}
      />

      <Group justify="end" mt="xs">
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

export default ModalApprovePromotion;
