import {
  ActionIcon,
  Box,
  Grid,
  LoadingOverlay,
  Select,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowBigRightFilled,
  IconMenu2,
  IconSearch,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../../../../_base/model/_base/BaseTable";
import { SelectListItemBase } from "../../../../../../_base/model/_base/SelectListItemBase";
import { _localization } from "../../../../../../config/location";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import { useDisclosure } from "@mantine/hooks";

export interface ItemSegmentModel {
  code: string; // Mã định danh (chuỗi)
  id: number; // Số nguyên
  name: string; // Tên hoặc mô tả
  type: number; // Số nguyên đại diện cho loại
}

const TableSegment = ({
  handleCheckboxChange,
  handleFetchItemFormCat,
}: TableSegmentProps) => {
  const columns = React.useMemo<MRT_ColumnDef<ItemSegmentModel>[]>(
    () => [
      {
        accessorKey: "id",
        enableHiding: false,
        header: "Mã",
      },
      {
        accessorKey: "code",
        header: "Mã",
        enableColumnFilter: false,
        enableColumnActions: false,
        size: 30,
      },
      {
        accessorKey: "name",
        header: "Tên",
        enableColumnFilter: false,
        enableColumnActions: false,
      },
    ],
    []
  );

  const [data, setData] = useState<ItemSegmentModel[]>([]);
  const [, setDataTemp] = useState<ItemSegmentModel[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<number[]>([]);

  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [visible, { toggle, close, open }] = useDisclosure(false);

  // Search:
  const [search, setSearch] = useState({
    type: "",
    keySearch: "",
  });

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearch = (value: string, key: string) => {
    setSearch((prev) => ({ ...prev, [key]: value }));
  };

  const fetchData = React.useCallback(async () => {
    open();
    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination?.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search?.keySearch?.trim().toLowerCase()}`;
    }

    if (search.type) {
      url += `&Type=${search.type}`;
    }

    const dataApi = await repositoryPos.get<
      MessageResponse<ItemSegmentModel[]>
    >(`/api/v1/Category/get-list${url}`);

    try {
      if (dataApi && dataApi.success) {
        setData(dataApi.data);
        setDataTemp(dataApi.data);
        setRowCount(dataApi.totalCount);
        // setSelectIds([]);
        //  table.resetRowSelection();

        // const updatedRowSelection = data?.reduce((acc, _, index) => {
        //   acc[index] = true;
        //   return acc;
        // }, {});

        // table.setRowSelection(updatedRowSelection);
      } else {
        setData([]);
        setDataTemp([]);
        setRowCount(0);
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      close();
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  }, [pagination, search]);

  const deepCompare = (obj1: any, obj2: any) => {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return obj1 === obj2;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  // useEffect(() => {
  //   const selectedRowIds = Object.keys(rowSelection);

  //   const selectedRows = table.getSelectedRowModel().rows;
  //   const selectedOriginalData = selectedRows.map((row) => row.original);

  //   setSelectIds(selectedRowIds);

  //   selectedOriginalData.forEach((row) => {
  //     handleCheckboxChange(row.code, row.type, true);
  //   });

  //   if (selectedRowIds.length < 1) {
  //     setSelectIds([]);
  //   }
  // }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination, search.type]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: {
      ..._localization,
      selectedCountOfRowCountRowsSelected: "",
      clearSelection: "",
    },
    manualPagination: true,
    enableRowSelection: true,
    enableTopToolbar: false,
    getRowId: (row) => row.code,
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 380, minHeight: 380 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      pagination,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: false,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <Box mt={10} w={"95%"}>
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
            <Select
              leftSection={<IconMenu2 size={14} />}
              w={"100%"}
              placeholder="Lọc theo"
              data={[
                // { label: "Lĩnh vực", value: "1" },
                // {
                //   label: "Ngành",
                //   value: "2",
                // },
                {
                  label: "Loại",
                  value: "3",
                },
                {
                  label: "Chủng",
                  value: "4",
                },
                {
                  label: "Nhóm",
                  value: "5",
                },
                // {
                //   label: "Model",
                //   value: "6",
                // },
              ]}
              onChange={(e) => {
                handleChangeSearch(e ?? "", "type");
              }}
            ></Select>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
            <TextInput
              value={search.keySearch}
              placeholder="Nhập tên"
              onChange={(e) =>
                handleChangeSearch(e.currentTarget.value, "keySearch")
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  fetchData();
                }
              }}
              rightSection={
                <ActionIcon variant="light" onClick={fetchData}>
                  <IconSearch size={14}></IconSearch>
                </ActionIcon>
              }
            />
          </Grid.Col>
        </Grid>
        <Box p={"10px 0px"}>
          <MantineReactTable table={table} />
        </Box>
        <Tooltip label="Lấy sản phẩm">
          <ActionIcon
            variant="light"
            pos={"absolute"}
            top={"50%"}
            right={"10px"}
            onClick={async () => {
              const selectedRowIds = Object.keys(rowSelection);
              const selectedRows = table.getSelectedRowModel().rows;
              const selectedOriginalData = selectedRows.map(
                (row) => row.original
              );

              // selectedOriginalData.forEach((row) => {
              //   handleCheckboxChange(row.code, row.type, true);
              // });

              // if (selectedRowIds.length < 1) {
              //   setSelectIds([]);
              // }
              //  console.log(selectedOriginalData);
              // if (selectedOriginalData.length <= 0) {
              //   NotificationExtension.Warn(
              //     "Chưa chọn lĩnh vực/ngành/loại/chủng/nhóm hàng để lấy sản phẩm!"
              //   );
              //   return;
              // }
              setSelectIds(selectedOriginalData.map((row) => row.id));

              await handleFetchItemFormCat(selectedOriginalData);
            }}
          >
            <IconArrowBigRightFilled />
          </ActionIcon>
        </Tooltip>
      </Box>
    </>
  );
};

type TableSegmentProps = {
  handleCheckboxChange?: any;
  handleFetchItemFormCat: any;
};

export default TableSegment;
