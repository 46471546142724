import React, { useEffect, useState } from "react";
import { MantineReactTable, MRT_ColumnDef } from "mantine-react-table";
import { Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";

interface TblShipCompany {
  id: number;
  chon: string;
  DoiTacVanChuyen: string;
  DaChon: number;
  DaTiepNhan: number;
  ChuaTiepNhan: number;
  TyLeTiepNhan: string;
  Xong: number;
  DangVanChuyen: number;
  TyLeHoanThanh: string;
}

interface VendorQuantity {
  vendor: string;
  quantity: number;
}

interface ShipCompanyTableProps {
  onSelect: (selectedData: TblShipCompany) => void;
}

const ShipCompanyTable: React.FC<ShipCompanyTableProps> = ({ onSelect }) => {
  const [data, setData] = useState<TblShipCompany[]>([
    {
      id: 1,
      chon: "Chọn",
      DoiTacVanChuyen: "VIETTEL",
      DaChon: 0,
      DaTiepNhan: 0,
      ChuaTiepNhan: 0,
      TyLeTiepNhan: "0%",
      Xong: 0,
      DangVanChuyen: 0,
      TyLeHoanThanh: "0%",
    },
    // {
    //   id: 2,
    //   chon: "Chọn",
    //   DoiTacVanChuyen: "GHTK",
    //   DaChon: 0,
    //   DaTiepNhan: 0,
    //   ChuaTiepNhan: 0,
    //   TyLeTiepNhan: "0%",
    //   Xong: 0,
    //   DangVanChuyen: 0,
    //   TyLeHoanThanh: "0%",
    // },
    // {
    //   id: 3,
    //   chon: "Chọn",
    //   DoiTacVanChuyen: "SCA",
    //   DaChon: 0,
    //   DaTiepNhan: 0,
    //   ChuaTiepNhan: 0,
    //   TyLeTiepNhan: "0%",
    //   Xong: 0,
    //   DangVanChuyen: 0,
    //   TyLeHoanThanh: "0%",
    // },
  ]);

  const columns = React.useMemo<MRT_ColumnDef<TblShipCompany>[]>(
    () => [
      {
        accessorKey: "chon",
        header: "Chọn",
        Cell: ({ row }) => (
          <Button
            size="xs"
            onClick={() => {
              onSelect(row.original);
              modals.closeAll();
            }}
          >
            Chọn
          </Button>
        ),
        size: 50,
      },
      {
        accessorKey: "DoiTacVanChuyen",
        header: "Đối tác vận chuyển",
        size: 150,
      },
      {
        accessorKey: "DaChon",
        header: "Đã chọn",
        size: 80,
      },
      {
        accessorKey: "DaTiepNhan",
        header: "Đã tiếp nhận",
        size: 80,
      },
      {
        accessorKey: "ChuaTiepNhan",
        header: "Chưa tiếp nhận",
        size: 100,
      },
      {
        accessorKey: "TyLeTiepNhan",
        header: "Tỷ lệ tiếp nhận",
        size: 100,
      },
      {
        accessorKey: "Xong",
        header: "Xong",
        size: 100,
      },
      {
        accessorKey: "DangVanChuyen",
        header: "Đang vận chuyển",
        size: 100,
      },
      {
        accessorKey: "TyLeHoanThanh",
        header: "Tỷ lệ hoàn thành",
        size: 100,
      },
    ],
    [onSelect]
  );

  useEffect(() => {
    const fetchDataCreate = async () => {
      const url = `/api/v1/CreateSellItem/shipping-vendor-quantity`;
      try {
        const response = await repositoryPos.get<
          MessageResponse<VendorQuantity[]>
        >(url);
        if (response && response.success && response.data) {
          // Cập nhật DaChon của mảng B
          setData((prevData) =>
            prevData.map((item) => {
              const match = response.data.find(
                (vendor) => vendor.vendor === item.DoiTacVanChuyen
              );
              return match ? { ...item, DaChon: match.quantity } : item;
            })
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataCreate();
  }, []);

  return (
    <MantineReactTable
      columns={columns}
      data={data}
      enableColumnFilters={false}
      enableSorting={false}
      enablePagination={false}
      enableGlobalFilter={false}
      enableRowActions={false}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      mantinePaperProps={{
        shadow: "sm",
        radius: "md",
      }}
    />
  );
};

export default ShipCompanyTable;
