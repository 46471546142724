import {
  Box,
  Button,
  Flex,
  Group,
  Image,
  Loader,
  LoadingOverlay,
  Paper,
  Select,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryPayment } from "../../../../_base/_const/_constVar";
import LogoVNPay from "../../../../assets/images/Logo_vnpay.png";
import FormatInputValueNumber from "../../../../common/formatInputValueNumber";
import { sky_blue } from "../../../../const/variables";
import "../../../../Styles/tab.component.css";

const dataPayment = [
  // {
  //   id: 1,
  //   icon: LogoMB,
  //   title: "Thanh toán QR MB",
  // },
  {
    id: 2,
    icon: LogoVNPay,
    title: "Thanh toán VN Pay",
  },
];

const bankOptions = [
  { value: "", label: "Không chọn" },
  // { value: "QRONLY", label: "Thanh toan QRONLY" },
  // { value: "MBAPP", label: "Ứng dụng MobileBanking" },
  // { value: "VNPAYQR", label: "VNPAYQR" },
  // { value: "VNBANK", label: "LOCAL BANK" },
  // { value: "IB", label: "INTERNET BANKING" },
  // { value: "ATM", label: "ATM CARD" },
  // { value: "INTCARD", label: "INTERNATIONAL CARD" },
  // { value: "VISA", label: "VISA" },
  // { value: "MASTERCARD", label: "MASTERCARD" },
  // { value: "JCB", label: "JCB" },
  // { value: "UPI", label: "UPI" },
  // { value: "VIB", label: "VIB" },
  // { value: "VIETCAPITALBANK", label: "VIETCAPITALBANK" },
  // { value: "SCB", label: "Ngân hàng SCB" },
  { value: "NCB", label: "Ngân hàng NCB" },
  { value: "SACOMBANK", label: "Ngân hàng SacomBank" },
  { value: "EXIMBANK", label: "Ngân hàng EximBank" },
  { value: "MSBANK", label: "Ngân hàng MSBANK" },
  { value: "NAMABANK", label: "Ngân hàng NamABank" },
  { value: "VNMART", label: "Ví điện tử VnMart" },
  { value: "VIETINBANK", label: "Ngân hàng Vietinbank" },
  { value: "VIETCOMBANK", label: "Ngân hàng VCB" },
  { value: "HDBANK", label: "Ngân hàng HDBank" },
  { value: "DONGABANK", label: "Ngân hàng Dong A" },
  { value: "TPBANK", label: "Ngân hàng TPBank" },
  { value: "OJB", label: "Ngân hàng OceanBank" },
  { value: "BIDV", label: "Ngân hàng BIDV" },
  { value: "TECHCOMBANK", label: "Ngân hàng Techcombank" },
  { value: "VPBANK", label: "Ngân hàng VPBank" },
  { value: "AGRIBANK", label: "Ngân hàng Agribank" },
  { value: "MBBANK", label: "Ngân hàng MBBank" },
  { value: "ACB", label: "Ngân hàng ACB" },
  { value: "OCB", label: "Ngân hàng OCB" },
  { value: "IVB", label: "Ngân hàng IVB" },
  { value: "SHB", label: "Ngân hàng SHB" },
  { value: "APPLEPAY", label: "Apple Pay" },
  { value: "GOOGLEPAY", label: "Google Pay" },
];

const submitVNPayPayment = async (dataVNPAY: any, setIsError: Function) => {
  try {
    const response = await repositoryPayment.post(
      `api/VNPAY/payment`,
      dataVNPAY
    );
    if (response && response.success) {
      return response;
    }
  } catch (error) {
    setIsError(true);
    console.error(error);
  }
};

export const PaymentMethod = ({
  price,
  productCode,
}: {
  price: number;
  productCode: string;
}) => {
  const [method, setMethod] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [redirectLink, setRedirectLink] = useState(null);
  const [isLoadingCricle, setIsLoadingCricle] = useState(true);
  const form = useForm({
    initialValues: {
      amount: price,
      orderDescription: "",
      bankCode: "",
      language: "VN",
    },
    validate: {
      amount: (value) => (value ? null : "Vui lòng nhập số tiền"),
      bankCode: (value) => (value ? null : "Vui lòng chọn ngân hàng"),
    },
  });

  const handleSubmitVNPay = async () => {
    const dataVNPAY = {
      ...form.values,
      amount: price,
      urlRedirect: "http://client-pos-dev.hacom.vn/sell/screen-payment",
      // urlRedirect: "http://localhost:3000/sell/screen-payment",
      createdDate: new Date().toISOString(),
      ipCurrent: "string",
      orderDescription: productCode,
    };
    const data = await submitVNPayPayment(dataVNPAY, setIsError);
    return data;
  };

  const handleVNPaySubmit = async () => {
    const isValid = form.validate();
    if (!isValid.hasErrors) {
      const data = await handleSubmitVNPay();

      setRedirectLink(data?.data);
    }
  };

  useEffect(() => {
    if (redirectLink) {
      const timer = setTimeout(() => {
        setIsLoadingCricle(false);
        window.open(redirectLink, "_blank");
      }, 3000);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [redirectLink]);

  return (
    <Box component="form" mx="auto" style={{ position: "relative" }}>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Flex justify="center" gap={10} mt={20}>
        {dataPayment.map((item) => (
          <Flex
            key={item.id}
            style={{ width: "Calc(50% - 10px)" }}
            className={`method ${item.id === method && "selectedMethod"}`}
            direction="column"
            align="center"
          >
            <Image
              src={item.icon}
              w="80"
              h="80"
              style={{
                border: "1px solid rgb(211, 208, 208)",
                borderRadius: "5px",
              }}
            />
            <Text>{item.title}</Text>
          </Flex>
        ))}
      </Flex>
      <Paper
        withBorder
        shadow="md"
        p="lg"
        mt={15}
        mr={5}
        ml={5}
        mb={20}
        radius="md"
      >
        <FormatInputValueNumber
          // vnd
          disabled
          label="Số tiền"
          placeholder="Nhập số tiền"
          value={20000000}
          {...form.getInputProps("amount")}
        />
        <Select
          label="Ngân hàng"
          placeholder="Chọn ngân hàng"
          data={bankOptions}
          {...form.getInputProps("bankCode")}
          mb="xs"
        />
        <Select
          label="Ngôn ngữ"
          placeholder="Chọn ngôn ngữ"
          data={[
            { value: "VN", label: "Tiếng Việt" },
            { value: "EN", label: "English" },
          ]}
          {...form.getInputProps("language")}
        />
      </Paper>

      {redirectLink && (
        <Group
          align="center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
            padding: "20px",
          }}
        >
          <Paper
            withBorder
            shadow="md"
            p="lg"
            radius="md"
            style={{
              backgroundColor: "white",
              maxWidth: "500px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Text fw={500} size="lg" mb="md">
              Bạn sẽ được chuyển hướng đến trang thanh toán.
            </Text>
            {isLoadingCricle && <Loader color="blue" />}
            <Group mt={10} justify="center">
              <Button
                variant="outline"
                color="gray"
                onClick={() => setRedirectLink(null)}
              >
                Đóng
              </Button>
            </Group>
          </Paper>
        </Group>
      )}

      <Group
        justify="end"
        mt="md"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          color={sky_blue.base}
          onClick={handleVNPaySubmit}
          leftSection={<IconCheck size={18} />}
        >
          Xác nhận
        </Button>
      </Group>
    </Box>
  );
};
