import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMDeliveryJob } from "../../../../model/TblDeliveryJob";
import {
  getBranchSelectMdm,
  getTblDmEmployeeSelect,
} from "../../../../service/getSelectApi";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";

const EditDataView = ({ id, onClose }: EditDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    companyCode: null,
    name: null,
    note: null,
    active: false,
    branchId: null,
    managerId: null,
  };

  const [dataBranchdSelect, setDataBranchdSelect] = useState<ComboboxItem[]>(
    []
  );

  const [dataEmployeedSelect, setDataEmployeedSelect] = useState<
    ComboboxItem[]
  >([]);

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [enable, setEnable] = useState(false);

  const form = useForm<TblDMDeliveryJob>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      name: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên công việc giao hàng !";
        }
        return hasLength(
          { max: 200 },
          "Tên công việc giao hàng không được dài hơn 200 ký tự !"
        )(value as string);
      },
      code: (value: any | null) => {
        const invalidChars =
          /[âăáàảãạêếềệễểíìịĩỉóòỏõọôốồộổỗơớờợỡởúùủũụưứừựữửýỳỷỹỵđ]/i;
        if (invalidChars.test(value)) {
          return "Mã công việc giao hàng chứa những kí tự không hợp lệ !";
        }
        if (!value) {
          return "Vui lòng nhập mã công việc giao hàng !";
        }
        return hasLength(
          { max: 20 },
          "Mã bộ phận giao hàng không được dài hơn 20 ký tự !"
        )(value as string);
      },
      note: (value: string | null) => {
        if (value && hasLength({ max: 800 })(value)) {
          return "Ghi chú không quá 800 kí tự!";
        }
      },
      branchId: (value: number | string | null) => {
        if (!value) {
          return "Vui lòng chọn chi nhánh !";
        }
      },
    },
  });

  const handleEditTblDMDeliveryJob = async (dataSubmit: TblDMDeliveryJob) => {
    open();
    const dataApi = await repositoryMdm.post<MessageResponse<TblDMDeliveryJob>>(
      "/api/v1/TblDMDeliveryJob/update",
      dataSubmit
    );
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Cập nhật thành công!!!");
    }

    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<TblDMDeliveryJob>>(
      `/api/v1/TblDMDeliveryJob/update?id=${id}`
    );
    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues({
          ...dataApi,
          managerId: dataApi.managerId?.toString(),
          branchId: dataApi.branchId?.toString(),
        });
        form.resetDirty(dataApi);
        Promise.all([dataBranchSelect(), dataEmployeeSelect()]);
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  const dataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeedSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          // value: item.text,
          label: item.text,
        }))
    );
  };

  const dataBranchSelect = async () => {
    const get = await getBranchSelectMdm();
    setDataBranchdSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          // value: item.text,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "250px", md: "300px", lg: "400px" }}
        onSubmit={form.onSubmit((e: TblDMDeliveryJob) => {
          handleEditTblDMDeliveryJob(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã CVGH"}
              placeholder={"Nhập mã CVGH"}
              type="text"
              onKeyDown={handleKeyDown}
              withAsterisk
              {...form.getInputProps("code")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <TextInput
              label={"Tên CVGH"}
              placeholder={"Nhập tên CVGH"}
              type="text"
              withAsterisk
              {...form.getInputProps("name")}
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label={"Chi nhánh"}
              placeholder={"Nhập tên chi nhánh"}
              withAsterisk
              searchable
              clearable
              data={dataBranchdSelect}
              {...form.getInputProps("branchId")}
              nothingFoundMessage="Không có dữ liệu !!!"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label={"Người quản lý"}
              placeholder={"Nhập tên người quản lý"}
              searchable
              clearable
              data={dataEmployeedSelect}
              {...form.getInputProps("managerId")}
              nothingFoundMessage="Không có dữ liệu !!!"
            />
          </Grid.Col>
        </Grid>

        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Textarea
              label={"Ghi chú"}
              placeholder="Nhập ghi chú"
              {...form.getInputProps("note")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Checkbox
              label={"Sử dụng"}
              checked={form.values.active}
              {...form.getInputProps("active")}
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            disabled={!enable}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default EditDataView;

type EditDataViewProps = {
  id: string | number;
  onClose: any;
};
