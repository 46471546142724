import {
  Badge,
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Divider,
  Flex,
  Grid,
  Input,
  Menu,
  NumberFormatter,
  Paper,
  rem,
  Select,
  Switch,
  Table,
  Tabs,
  Textarea,
  TextInput,
  Text,
  Group,
  Tooltip,
  ActionIcon,
  Title,
  SelectProps,
  Avatar,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconBrandOkRu,
  IconBrandProducthunt,
  IconBuildingSkyscraper,
  IconCalendar,
  IconCheck,
  IconCoinPound,
  IconCopy,
  IconCreditCardPay,
  IconDownload,
  IconDroplet,
  IconEdit,
  IconEye,
  IconFilter,
  IconList,
  IconMenuOrder,
  IconMinus,
  IconPhoto,
  IconPlus,
  IconPoint,
  IconPrinter,
  IconPrism,
  IconReceipt,
  IconSearch,
  IconShip,
  IconSquareXFilled,
  IconTicket,
  IconTimeDuration15,
  IconTrash,
  IconUser,
  IconUserCode,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { DateInput, DatePickerInput } from "@mantine/dates";
import { useDisclosure, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import { SelectListItem } from "../../../model/SelectListItem";
import {
  getOrderTypeSelect,
  getTblDMInventory,
  getTblItemSelect,
} from "../../../service/getSelectApi";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";

import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { SocketExtension } from "../../../_base/socket/socket";
import { CREATEORDERSUCCESS } from "../../../_base/socket/ToplicList";
import { OrderDetail } from "../../../model/SaleOrderModel";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import moment from "moment";

import { green, orange, purple } from "../../../const/variables";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import useWindowSize from "../../../common/WindownSize/WindownSize";

interface DataList {
  lists: any[];
  count: number;
}

interface InvoiceReceiptsPaymentModel {
  orderNumber: string;
  orderDate: string; // Use string if the date is in ISO format, or use Date if you parse it.
  totalAmount: number;
  amountPaid: number;
  amountRe: number;
  allocatedAmount: number;
  notes: string | null;
}

const ListSerial = () => {
  const navigate = useNavigate();
  //#region  table

  //data and fetching state
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const [dataFetch, setDataFetch] = useState<SelectListItem[]>([]);
  const [dataWarehouse, setDataWarehouse] = useState<SelectListItem[]>([]);
  const [dataIndustry, setDataIndustry] = useState<SelectListItem[]>([]);
  const [dataOrderType, setOrderType] = useState<SelectListItem[]>([]);
  const [listDataFundAccout, setListDataFundAccout] = useState<any[]>([]);

  const [dataListCustomer, setDataListCustomer] = useState<SelectListItem[]>(
    []
  );
  const [paymentMethod, setPaymentMethod] = useState<TblDMPaymentMethod[]>();
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(1);
  const [take, setTake] = useState<number>(30);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);

  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);
  //
  const [dateUsers, setUsers] = useState<SelectListItem[]>([]);
  const [dataTblOrderTypeSelect, setDataTblOrderTypeSelect] = useState<
    ComboboxItem[]
  >([]);
  const debounceTimeoutRef = React.useRef<number | null>(null);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const size = useWindowSize();
  const [dataTblItemSelect, setDataTblItemSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataTblIventorySelect, setDataTblIventorySelect] = useState<
    ComboboxItem[]
  >([]);

  const [search, setSearch] = useState({
    keySearch: "",
    customerId: "",
    startDate: "",
    endDate: "",
    codefund: "",
    accountfund: "",
    deartment: "",
    branch: "",
    type: "",
    workplace: "",
    creator: "",
    ItemCode: "",
    InvId: "",
  });
  useEffect(() => {
    //  getUser();
  }, []);

  const [keySearch, setKeySearch] = useState<string>("");
  const [createdById, setcreatedById] = useState<number>(0);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const sizeGrid = { base: 6, sm: 2.4, md: 2.4, lg: 2.4, xl: 2.4 };

  const dataOrderTypeSelect = async () => {
    const getData = await getOrderTypeSelect();
    setDataTblOrderTypeSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        CustomerId: search.customerId,
        FromDate: search.startDate,
        ToDate: search.endDate,
        ItemCode: search.ItemCode,
        InvId: search.InvId,
        KeySearch: search.keySearch.toLowerCase() || keySearch.toLowerCase(),
      };

      const url = Object.entries(queryParams)
        .reduce((acc, [key, value]) => {
          if (value !== undefined && value !== "") {
            return `${acc}&${key}=${encodeURIComponent(value)}`;
          }
          return acc;
        }, "/api/v1/TblInventorySerialOnHand/get-list?")
        .replace("&", "");

      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        const dataList = response?.data;
        setData(dataList);
        console.log(dataList);
        setRowCount(dataList?.count);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  console.log(data);
  // Hàm gọi API phòng ban

  const handleChangeSearchValue = (value: string, key: string) => {
    if (key === "customerId") {
      setSelectedCustomer(value);
    }
    setSearch((prevData) => ({ ...prevData, [key]: value }));
    setSelectedValue(value);
  };

  const fetchDataTblItem = async () => {
    const response = await getTblItemSelect(30);
    setDataTblItemSelect(
      response.map((employee) => ({
        value: employee.value,
        label: employee.text,
      }))
    );
  };

  const fetchDataTblIventory = async () => {
    const response = await getTblDMInventory();
    setDataTblIventorySelect(
      response.map((employee) => ({
        value: employee.value,
        label: employee.text,
      }))
    );
  };

  useEffect(() => {
    fetchDataTblItem();
    fetchDataTblIventory();
  }, []);

  const handleRangeChange = (dates: [Date | null, Date | null]) => {
    setValue(dates);
    const [startDate, endDate] = dates;
    const startDateISO = startDate ? startDate.toLocaleDateString() : null;
    const endDateISO = endDate ? endDate.toLocaleDateString() : null;

    handleChangeSearchValue(startDateISO ?? "", "startDate");

    handleChangeSearchValue(endDateISO ?? "", "endDate");
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "idKho",
        header: "Id kho",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "maSp",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }) => {
          return renderedCellValue ? (
            <Badge color="green" radius="sm">
              {renderedCellValue}
            </Badge>
          ) : null;
        },
      },
      {
        accessorKey: "maBarcode",
        header: "Mã Barcode",
        Cell: ({ renderedCellValue }) => {
          return renderedCellValue ? (
            <Badge color="yellow" radius="sm">
              {renderedCellValue}
            </Badge>
          ) : null;
        },
      },
      {
        accessorKey: "tenKho",
        header: "Tên kho",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "tenSp",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>{renderedCellValue}</Text>
        ),
      },

      {
        accessorKey: "dvt",
        header: "Đơn vị tính",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>
            {renderedCellValue === null ? null : renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>
            {renderedCellValue === null ? null : renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "ngayNhap",
        header: "Ngày nhập",
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Text tt="capitalize" fw={600}>
              {formatDateTransfer(String(renderedCellValue))}
            </Text>
          );
        },
      },
      {
        accessorKey: "soPhieuNhap",
        header: "Số phiếu nhập",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={600}>{renderedCellValue}</Text>
        ),
      },
    ],
    [dataTblOrderTypeSelect]
  );

  const table = useMantineReactTable<any>({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: false,
    // getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["idKho", "mrt-row-select", "receiptNumber"],
        right: ["ngayNhap"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    // enableExpanding: true,
    // getSubRows: (originalRow) => originalRow.orderDetail,

    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <_breadcrumb></_breadcrumb>
        <Grid>
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col span={sizeGrid}>
                <Input
                  placeholder="Từ khóa"
                  type="text"
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  }}
                  leftSection={<IconSearch color="#15aabf" />}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <DatePickerInput
                  type="range"
                  allowSingleDateInRange
                  leftSection={<IconTimeDuration15 color="#15aabf" />}
                  size="sm"
                  placeholder="Chọn ngày"
                  locale="vi"
                  flex={1}
                  value={value}
                  valueFormat="DD/MM/YYYY"
                  onChange={handleRangeChange}
                  clearable
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  placeholder={"Chọn sản phẩm"}
                  searchable
                  clearable
                  data={dataTblItemSelect}
                  leftSection={<IconBrandProducthunt color="#15aabf" />}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  nothingFoundMessage={"Không có dữ liệu"}
                  onChange={(value) =>
                    handleChangeSearchValue(value || "", "ItemCode")
                  }
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  placeholder={"Chọn phòng kho"}
                  searchable
                  clearable
                  data={dataTblIventorySelect}
                  leftSection={<IconBrandProducthunt color="#15aabf" />}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  nothingFoundMessage={"Không có dữ liệu"}
                  onChange={(value) =>
                    handleChangeSearchValue(value || "", "InvId")
                  }
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default ListSerial;
