import { Box, Button, Grid, Group, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { SocketExtension } from "../../_base/socket/socket";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { sky_blue } from "../../const/variables";
import { MessageResponse } from "../../model/MessageResponse";
import { TblAssignTestingTechniqueModel } from "../../model/TblTestingTechniqueHeader";

const ModalEditTestingTechnique = ({
  id,
  onClose,
}: {
  id: string | number;
  onClose: any;
}) => {
  const entity = {
    testingTechniqueHeaderId: Number(id),
    processingDate: null,
    priorityId: null,
    difficultyLevelId: null,
    note: null,
    responsibleEmployeeId: null,
    assignToTblTestingTechniqueHeaderCommandModels: null,
  };

  const form = useForm<any>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const [visible, { close, open }] = useDisclosure(false);

  const [searchEmployee, setSearchEmployee] = useDebouncedState<string>(
    "",
    300
  );
  const [searchEmployeeSupport, setSearchEmployeeSupport] =
    useDebouncedState<string>("", 300);
  const [dataEmployeeOption, setDataEmployeeOption] = useState<any[]>([]);
  const [dataEmployeeOptionSupport, setDataEmployeeOptionSupport] = useState<
    any[]
  >([]);

  const getDetail = async () => {
    const res = await repositoryDelivery.get<MessageResponse<any>>(
      `/api/v1/TblTestingTechniqueHeader/get-detail?id=${id}`
    );

    if (res && res?.success) {
      const dataApi = res.data;
      form.setValues(dataApi);
    }
  };

  const handleUpdateAssign = async (dataSubmit: any) => {
    const url = `/api/v1/TblTestingTechniqueHeader/assign`;
    open();

    try {
      console.log(dataSubmit);
      // const response = await repositoryDelivery.post<
      //   MessageResponse<TblAssignTestingTechniqueModel>
      // >(url, dataSubmit);
      // if (response?.success) {
      //   onClose((prev: any) => !prev);
      //   NotificationExtension.Success("Phân công kiểm tra thành công");
      //   modals.closeAll();
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    close();
  };

  const getDataEmployeeReponsible = async () => {
    const search =
      form?.getValues()?.responsibleEmployeeName?.toString() ??
      searchEmployee.toLowerCase();
    let url = `/api/v1/TblDmEmployee/get-list?Take=100`;
    if (search) {
      url += `&KeySearch=${search}`;
    }
    try {
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);
      if (response?.success && response?.data) {
        const data = response.data.filter((item: any) =>
          item.roleName.toLowerCase().includes("kỹ thuật")
        );
        const mappedData = Array.from(
          new Map(
            data.map((value) => [
              value?.id,
              {
                value: value?.id?.toString(),
                label: `${value?.code?.toString()} - ${value?.fullname?.toString()}`,
              },
            ])
          ).values()
        );
        setDataEmployeeOption(mappedData);
      } else {
        setDataEmployeeOption([]);
      }
    } catch (error) {
      setDataEmployeeOption([]);
      console.error("Error fetching data:", error);
    }
  };

  const getDataEmployeeSupport = async () => {
    const search =
      form
        ?.getValues()
        ?.tblTestingTechniqueAssignmentModels?.find(
          (e: any) => e?.emloyeeId !== form?.getValues().responsibleEmployeeId
        )?.emloyeeName ?? searchEmployee.toLowerCase();
    let url = `/api/v1/TblDmEmployee/get-list?Take=100`;
    if (search) {
      url += `&KeySearch=${search}`;
    }
    try {
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);
      if (response?.success && response?.data) {
        const data = response.data.filter((item: any) =>
          item.roleName.toLowerCase().includes("kỹ thuật")
        );
        const mappedData = Array.from(
          new Map(
            data.map((value) => [
              value?.id,
              {
                value: value?.id?.toString(),
                label: `${value?.code?.toString()} - ${value?.fullname?.toString()}`,
              },
            ])
          ).values()
        );
        setDataEmployeeOptionSupport(mappedData);
      } else {
        setDataEmployeeOptionSupport([]);
      }
    } catch (error) {
      setDataEmployeeOptionSupport([]);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const _searchEmployeeReponsible =
      form?.getValues()?.responsibleEmployeeName?.toString() ??
      searchEmployee.toLowerCase();

    const _searchEmployeeSupport =
      form
        ?.getValues()
        ?.tblTestingTechniqueAssignmentModels?.find(
          (e: any) =>
            e?.emloyeeId?.toString() !==
            form?.getValues().responsibleEmployeeId?.toString()
        )
        ?.emloyeeName?.toLowerCase() ?? searchEmployeeSupport?.toLowerCase();
    if (_searchEmployeeReponsible && _searchEmployeeReponsible.length > 2) {
      getDataEmployeeReponsible();
    } else {
      setDataEmployeeOption([]);
    }

    if (_searchEmployeeSupport && _searchEmployeeSupport.length > 2) {
      getDataEmployeeSupport();
    } else {
      setDataEmployeeOptionSupport([]);
    }
  }, [searchEmployee, form?.getValues()?.responsibleEmployeeName]);

  useEffect(() => {
    if (id) getDetail();
  }, [id]);

  return (
    <Box
      component="form"
      mx="auto"
      w={"60vw"}
      maw={500}
      onSubmit={form.onSubmit((e) => {
        handleUpdateAssign(e);
      })}
    >
      <Grid mt={"10px"}>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }} mb={0}>
          <TextInput
            readOnly
            variant="filled"
            label="Số phân công"
            value={form.getValues()?.code}
          ></TextInput>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <TextInput
            readOnly
            variant="filled"
            label="Ngày phân công"
            value={formatDateTransfer(
              form.getValues()?.assignmentDate ?? new Date()
            )}
          ></TextInput>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <TextInput
            readOnly
            variant="filled"
            label="Người phân công"
            value={form.getValues()?.assignerName}
          ></TextInput>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <Select
            searchable
            label="Người nhận phân công"
            type="text"
            placeholder="Chọn người nhận"
            value={form.getValues().responsibleEmployeeId?.toString() ?? null}
            data={dataEmployeeOption}
            onSearchChange={(e) => setSearchEmployee(e)}
            searchValue={searchEmployee}
            {...form.getInputProps("responsibleEmployeeId")}
            onChange={(value) => {
              form.setValues((prev) => ({
                ...prev,
                responsibleEmployeeId: value?.toString(),
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <Select
            searchable
            label="Người hỗ trợ"
            type="text"
            placeholder="Chọn người hỗ trợ"
            value={
              form
                ?.getValues()
                ?.tblTestingTechniqueAssignmentModels?.find(
                  (e: any) =>
                    e?.emloyeeId?.toString() !==
                    form?.getValues()?.responsibleEmployeeId?.toString()
                )
                ?.emloyeeId?.toLowerCase() ?? null
            }
            data={dataEmployeeOptionSupport}
            onSearchChange={(e) => setSearchEmployeeSupport(e)}
            searchValue={searchEmployeeSupport}
            onChange={(value) => {
              form.setValues((prev) => ({
                ...prev,
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <TextInput
            label="Nội dung phân công"
            placeholder="Vui lòng nhập nội dung phân công"
            {...form.getInputProps("description")}
          ></TextInput>
        </Grid.Col>
      </Grid>

      <Group
        justify="end"
        mt="md"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

export default ModalEditTestingTechnique;
