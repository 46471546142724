import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
  Text,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { handleKeyDown } from "../../../_base/helper/FunctionHelper";
import { TblDMCustomer } from "../../../model/TblDMCustomerSell";
import { DateInput } from "@mantine/dates";
import { sky_blue } from "../../../const/variables";

const CreateCustomer = ({ onClose }: CreateDataViewProps) => {
  const entity = {
    customer: {
      id: "0",
      parentId: null,
      code: null,
      name: null,
      contact: null,
      address: null,
      telephoneNumber: null,
      fax: null,
      email: null,
      taxCode: null,
      type: null,
      active: false,
      description: null,
      sex: null,
      birthOfDate: null,
      groupId: null,
    },
    customerSite: {
      customerSiteId: null,
      customerId: null,
      customerSiteType: null,
      address: null,
      customerSiteNumber: null,
      customerSiteName: null,
      telephoneNumber: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      createdBy: null,
      lastUpdateDate: null,
      lastUpdatedBy: null,
      lastUpdateLogin: null,
      creationDate: null,
      wardId: null,
      districtId: null,
      provinceId: null,
      telephone: null,
      isMain: null,
      email: null,
    },
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMCustomer>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    transformValues: (values) => ({
      ...values,
      customer: {
        ...values.customer,
        parentId: Number(values.customer.parentId),
        groupId: Number(values.customer.groupId),
        type: Number(values.customer.type),
        sex: Boolean(values.customer.sex),
      },
      customerSite: {
        ...values.customerSite,
        customerId: Number(values.customerSite.customerId),
        customerSiteId: Number(values.customerSite.customerSiteId),
        provinceId: Number(values.customerSite.provinceId),
        districtId: Number(values.customerSite.districtId),
        wardId: Number(values.customerSite.wardId),
      },
    }),

    validate: {
      customer: {
        parentId: (value: number | null) => {
          if (!value) {
            return "Vui lòng nhập mã doanh nghiệp !";
          }
          return hasLength(
            { max: 20 },
            "Mã khách hàng doanh nghiệp không được vượt quá 20 kí tự !"
          )(value as number);
        },
        code: (value: string | null) => {
          if (!value) {
            return "Vui lòng nhập mã khách hàng !";
          }
          return hasLength(
            { max: 20 },
            "Mã khách hàng không được vượt quá 20 kí tự !"
          )(value as string);
        },
        name: (value: string | null) => {
          if (!value) {
            return "Vui lòng nhập tên khách hàng !";
          }
          return hasLength(
            { max: 30, min: 2 },
            "Tên khách hàng phải nằm trong khoảng từ 2 đến 30 kí tự !"
          )(value as string);
        },
        sex: (value: boolean | null) => {
          if (!value) {
            return "Vui lòng chọn giới tính khách hàng !";
          }
        },
        email: (value: string | null) => {
          if (value) {
            return /^\S+@\S+$/.test(value ?? "")
              ? null
              : "Email chưa đúng định dạng !";
          }
          return null;
        },
        telephoneNumber: (value: string | null) => {
          if (value) {
            return hasLength(
              { max: 10, min: 10 },
              "Số điện thoại chưa đúng địng dạng !"
            )(value as string);
          }
        },
        type: (value: number | null) => {
          if (!value) {
            return "Vui lòng chọn loại khách hàng !";
          }
          return hasLength(
            { max: 20 },
            "Loại khách hàng không được vượt quá 20 kí tự !"
          )(value as number);
        },
        groupId: (value: number | null) => {
          if (!value) {
            return "Vui lòng chọn nhóm khách hàng !";
          }
          return hasLength(
            { max: 20 },
            "Nhóm khách hàng không được vượt quá 20 kí tự !"
          )(value.toString() as string);
        },
      },
    },
  });

  const handleCreateTblDmCustomer = async (dataSubmit: TblDMCustomer) => {
    open();
    const dataApi = await repositoryPos.post<MessageResponse<TblDMCustomer>>(
      "/api/v1/TblDmCustomer/create",
      dataSubmit
    );
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    } else NotificationExtension.Fails("Thêm thất bại !");
    close();
  };

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "300px", md: "500px", lg: "675px" }}
        onSubmit={form.onSubmit((e: TblDMCustomer) => {
          handleCreateTblDmCustomer(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Grid mt={12}>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Mã khách hàng"
              placeholder="Nhập mã khách hàng"
              onKeyDown={handleKeyDown}
              {...form.getInputProps("customer.code")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Tên khách hàng"
              placeholder="Nhập tên khách hàng"
              {...form.getInputProps("customer.name")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <Select
              label="Giới tính"
              placeholder="Chọn giới tính"
              data={[
                { label: "Nam", value: "true" },
                { label: "Nữ", value: "false" },
              ]}
              {...form.getInputProps("customer.sex")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <DateInput
              label="Ngày sinh"
              valueFormat="DD/MM/YYYY"
              clearable
              placeholder="Nhập ngày sinh"
              {...form.getInputProps("customer.birthOfDate")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Địa chỉ"
              placeholder="Nhập địa chỉ"
              {...form.getInputProps("customer.address")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Email"
              placeholder="Nhập email"
              {...form.getInputProps("customer.email")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Số điện thoại"
              type="number"
              placeholder="Nhập số điện thoại"
              {...form.getInputProps("customer.telephoneNumber")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Liên hệ"
              placeholder="Nhập liên hệ"
              {...form.getInputProps("customer.contact")}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <TextInput
              label="Mô tả"
              placeholder="Nhập mô tả"
              {...form.getInputProps("customer.description")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Checkbox
              label="Sử dụng"
              {...form.getInputProps("customer.active")}
            />
          </Grid.Col>
        </Grid>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default CreateCustomer;

type CreateDataViewProps = {
  onClose: any;
};
