import { Button, Flex, Input } from "@mantine/core";
import { IconPlus, IconSearch, IconTrash } from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import { _localization } from "../../../config/location";
import { tblItem } from "../../../model/TblItem";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import { isNullOrUndefinedArry } from "../../../_base/extension/StringExtension";
import { getListItem } from "../../../api/apiItem";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { toast } from "react-toastify";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { TblPriceListDetailCommand } from "../../../model/TblPriceListDetailCommand";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";

type ModalAddProductProps = {
  handleAddProduct: React.Dispatch<
    React.SetStateAction<TblPriceListDetailCommand[]>
  >;
  listIdItem: (number | null)[];
  id?: number;
};

const ModelAddProductV2 = ({
  listIdItem,
  handleAddProduct,
  id,
}: ModalAddProductProps) => {
  const columnsListProduct = useMemo<MRT_ColumnDef<tblItem>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
      },

      {
        accessorKey: "categoryId",
        header: "Id danh mục",
      },

      {
        accessorKey: "uomCodeId",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "warranty",
        header: "Bảo hành",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue} Tháng</>,
      },
      {
        accessorKey: "description",
        header: "Mô tả",
      },
    ],
    []
  );

  const [data, setData] = useState<tblItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [fullScreen, SetFullScreen] = useState<boolean>(true);
  const [keySearch, setKeySearch] = useState<string>("");

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] =
    useState<MRT_PaginationState>(paginationBase);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<(number | null)[]>([]);

  useEffect(() => {
    setSelectIds(listIdItem);
  }, [listIdItem]);

  const fetchDataItem = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setData([]);
    setRowCount(0);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch) {
      url += `&KeySearch=${keySearch}`;
    }
    if (id && id > 0) {
      url += `&Id=${id}`;
    }
    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
    }
    url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
    // if (listIdItem) url = url + "&listId=" + listIdItem.join(",");

    try {
      let callapi = await repositoryPos.get(`/api/v1/TblItem/get-list-price-details${url}`);
      if (isNullOrUndefined(callapi) || isNullOrUndefinedArry(callapi?.data)) {
        setRowCount(0);
        setData([]);
      } else {
        setData(callapi?.data ?? []);
        // setPagination({
        //   ...pagination,
        //   totalItemCount: callapi?.data.count ?? 0,
        // });
        setRowCount(callapi?.totalCount ?? 0);
        // setSelectIds([]);
        table.resetRowSelection();
      }
      return callapi?.data;
    } catch (error: any) {
      setIsError(true);
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  useEffect(() => {
    fetchDataItem();
  }, [pagination, sorting]);

  const table = useMantineReactTable<tblItem>({
    columns: columnsListProduct,
    data: data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
        >
          <Input
            placeholder="Tìm kiếm tên, mã sản phẩm"
            onChange={(e) => {
              if (e && e?.target?.value) {
                setKeySearch(e.target.value.toLowerCase()); // Chuyển đổi từ khóa tìm kiếm thành chữ thường
              } else setKeySearch("");
            }}
          />
          <Button
            leftSection={<IconSearch size={14} />}
            variant="outline"
            size="xs"
            onClick={() => {
              fetchDataItem();
            }}
          >
            Tìm kiếm
          </Button>
        </Flex>
      </>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            variant="outline"
            size="sm"
            onClick={async () => {
              if (table.getSelectedRowModel().rows.length > 0) {
                // handleAddProduct(
                //   table.getSelectedRowModel().rows?.map((row) => row.original),
                //   productId
                // );
                const getData = table.getSelectedRowModel().rows?.map((row) => {
                  const item = row.original as tblItem;
                  // Mapping tblItem to TblPriceListDetailCommand
                  const mappedData: TblPriceListDetailCommand = {
                    id: 0, // Gán mặc định, cần logic nếu có
                    idPriceLis: 0, // Gán mặc định, cần logic nếu có
                    itemId: item.itemId ?? 0,
                    itemCode: item.itemCode ?? "",
                    itemName: item.itemName ?? "",
                    priceNoVat: item.poPrice ?? 0,
                    priceIncludeVat: null,
                    discountPercent: null,
                    discountAmount: item.discount ?? null,
                    vatRate: item.vatRate ?? null,
                    attribute1: "",
                    attribute2: "",
                    attribute3: "",
                    attribute4: "",
                    attribute5: "",
                    attribute6: "",
                    attribute7: "",
                    attribute8: "",
                    attribute9: "",
                    attribute10: "",
                    attribute11: "",
                    attribute12: "",
                    attribute13: "",
                    attribute14: "",
                    attribute15: "",
                  };

                  return mappedData;
                });

                for (let index = 0; index < getData.length; index++) {
                  const element = getData[index];
                  const check = listIdItem.includes(element.itemId);
                  if (check) {
                    NotificationExtension.Fails(
                      "Mã sản phẩm " +
                        element.itemCode +
                        " đã tồn tại ở bảng giá !"
                    );
                    return;
                  }
                }
                if (
                  getData &&
                  getData.length > 0 &&
                  getData.find((x) => x != undefined)
                ) {
                  const _add = getData.filter((x) => x != undefined);
                  if (_add.length > 0) {
                    handleAddProduct((x) => [..._add, ...(x ?? [])]);
                    NotificationExtension.Success("Thêm sản phẩm thành công!");
                    listIdItem.push(..._add.map((x) => x.itemId));
                    table.resetRowSelection();
                    //  await fetchDataItem();
                  }
                }
              } else NotificationExtension.Info("Vui lòng chọn sản phẩm");
            }}
          >
            Thêm mới
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: (row) => !selectIds.includes(row.original.itemId ?? 0),
    getRowId: (row) => row.itemId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: "60vh", minHeight: "300px" },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableMultiRowSelection: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold", //conditional styling
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return <MantineReactTable table={table} />;
};

export default ModelAddProductV2;
