import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
  Box,
  Flex,
} from "@mantine/core";
import classes from "./AuthenticationTitle.module.css";
import {
  readLocalStorageValue,
  useDisclosure,
  useLocalStorage,
  useOs,
} from "@mantine/hooks";
import { hasLength, isEmail, useForm } from "@mantine/form";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoginModel } from "../../../model/LoginModel";

import { isNullOrEmpty } from "../../extension/StringExtension";
import { AuthProvider } from "../../helper/IAuthProvider";
import { IconArrowNarrowRight, IconHttpPost } from "@tabler/icons-react";
import style from "./AuthenticationTitle.module.css";
import { NotificationExtension } from "../../extension/NotificationExtension";
import { SocketExtension } from "../../socket/socket";
import { usePostHog } from "posthog-js/react";
type Props = {
  setIsLogin: (value: boolean) => void;
  dataUserSign: { name: string; pass: string };
};
export function AuthenticationTitle({ setIsLogin, dataUserSign }: Props) {
  //#region  state
  const entity = {
    // username: "tester01",
    // password: "aA@123456",
    username: "",
    password: "",
    remember: false,
    os: "",
    location: "",
  };
  const form = useForm<LoginModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      username: (value) =>
        value.length < 5 || value.length > 25
          ? "Tên đăng nhập phải từ 5-25 kí tự!"
          : null,
      password: hasLength(
        { min: 6, max: 19 },
        "Mật khẩu phải lớn hớn 5 và nhỏ hơn 20 kí tự !"
      ),
    },
  });
  const navigate = useNavigate();

  const [visible, { toggle, open, close }] = useDisclosure(false);
  const [visible1, setvisible1] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const callbackParam = queryParams.get("callback");
  const [value, setValue] = useLocalStorage({
    key: "user-login",
    defaultValue: false,
  });
  const posthog = usePostHog();
  const [locationVC, setLocation] = useState("");

  //#endregion
  function success(position: { coords: { latitude: any; longitude: any } }) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation("latitude: " + latitude + "-" + " longitude: " + longitude);
  }

  function error1() {
    console.log("Unable to retrieve your location");
  }
  //#region use
  //#region  auth
  const isAuthenticated = AuthProvider.isAuthenticated();
  const os = useOs();
  const handleLogin = async (dataSubmit: LoginModel) => {
    open();
    setvisible1(true);
    dataSubmit.os = os;
    dataSubmit.location = locationVC;

    const resLogin = await AuthProvider.signin(dataSubmit);
    if (
      resLogin !== undefined &&
      !isNullOrEmpty(resLogin.data?.jwt) &&
      resLogin.success
    ) {
      NotificationExtension.Success(
        resLogin?.message ?? "Đăng nhập thành công !"
      );

      posthog?.identify(resLogin.data.userName, {
        userName: resLogin.data.userName,
        empId: resLogin.data.empId,
        clientId: resLogin.data.clientId,
      });
      posthog?.group("hacom-login", "Hacom");
      if (callbackParam?.includes("login")) navigate("/");
      else navigate(callbackParam ?? "/");
      setValue(true);
      // này là push trực tiếp với điều kiện là app không tắt
      const registrationReady = await navigator.serviceWorker.ready;
      if (registrationReady && registrationReady.active)
        if (AuthProvider.isAuthenticated()) {
          const getUser = resLogin.data.userName;
          const empId = resLogin.data.empId;
          if (getUser && empId) {
            const postData = {
              userName: getUser,
              empId: empId,
              type: "listen",
              typeHandler: "login",
            };
            registrationReady.active?.postMessage(postData);
          }
        }
    } else if (resLogin?.httpStatusCode === 200)
      NotificationExtension.Fails(resLogin?.message ?? "Đăng nhập thất bại !");
    setvisible1(false);
    close();
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate(callbackParam ?? "/");
    }
  });
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error1);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);
  useEffect(() => {
    if (dataUserSign && dataUserSign.name !== "" && dataUserSign.pass !== "") {
      form.values.username = dataUserSign.name;
      form.values.password = dataUserSign.pass;
    }
  }, [dataUserSign]);
  return (
    <>
      <Box
        component="form"
        m={0}
        p={0}
        className={style.form}
        onSubmit={form.onSubmit(async (e: LoginModel) => {
          posthog?.capture("clicked_log_in");
          await handleLogin(e);
        })}
      >
        <Flex mb={40} direction="column">
          <Title c="#342e79" fw={500} order={3}>
            Chào mừng trở lại!
          </Title>
          <Text c="#8e8e8e" fw="400" size="lg">
            Xin hãy đăng nhập vào tài khoản của bạn
          </Text>
        </Flex>
        <LoadingOverlay
          visible={visible1}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Paper pb={50} bg={"transparent"}>
          <TextInput
            label={
              <Text c="#342e79" mb={5} size="sm" fw="bold">
                Tên người dùng
              </Text>
            }
            size="lg"
            placeholder="Tên người dùng"
            mt="md"
            {...form.getInputProps("username")}
          />
          <PasswordInput
            label={
              <Text c="#342e79" mb={5} size="sm" fw="bold">
                Mật khẩu
              </Text>
            }
            size="lg"
            placeholder="Mật khẩu của bạn"
            mt="md"
            {...form.getInputProps("password")}
          />
          {/* <Group justify="space-between" mt="lg">
            <Checkbox
              label={
                <Text c="#8e8e8e" size="sm" fw="400">
                  Ghi nhớ
                </Text>
              }
              radius="xl"
              size="md"
              color={"#342e79"}
              onChange={(e) => {
                form.values.remember = e.target.checked;
              }}
            />
            <Anchor component="button" size="sm">
                Quên mật khẩu?
              </Anchor>
          </Group> */}

          <Button
            rightSection={<IconArrowNarrowRight color="#fff" size={24} />}
            type="submit"
            fullWidth
            bg="#342e79"
            mt="xl"
            className={style.button_submit}
            size="lg"
          >
            <Text c={"#fff"} fw={500} size="lg" tt="capitalize">
              Đăng nhập
            </Text>
          </Button>
          <Group justify="end" mt="lg">
            <Anchor size="md" onClick={() => setIsLogin(true)}>
              Đăng ký tài khoản?
            </Anchor>
          </Group>
        </Paper>
        <Group justify="end">
          <Flex gap={4}>
            Copyright © 2024{" "}
            <Text fw={600} c="#000">
              Hacom.
            </Text>{" "}
            All rights reserved.
          </Flex>
        </Group>
      </Box>
    </>
  );
}
