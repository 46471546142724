import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconEdit,
  IconFunction,
  IconHandStop,
  IconManualGearbox,
  IconPlus,
  IconSearch,
  IconShoppingBag,
  IconTrash,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { _localization } from "../../config/location";
import { MessageResponse } from "../../model/MessageResponse";
import { SelectListItem } from "../../model/SelectListItem";
import GroupAddRole from "./GroupAddRoleAuthozire";
import GroupRoleEdit from "./GroupRoleEdit";
import GroupsRoleAdd from "./GroupsRoleAdd";
import "../../Styles/tab.component.css";
import {
  _timerDelayClick,
  _timerDelaySearch,
  repositoryMaster,
} from "../../_base/_const/_constVar";
import UserCreate from "./UserCreate";
import { UserMasterModel } from "../../model/UserMasterModel";
import UserEdit from "./UserEdit";
import UserAddGroup from "./UserAddGroup";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { useDebouncedState } from "@mantine/hooks";

const UserListView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<UserMasterModel[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [keySearch, setKeySearch] = useDebouncedState("", 200);
  const [selectIds, setSelectIds] = useState<string[]>([]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [sorting, pagination.pageIndex, pagination.pageSize]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    try {
      let urlGet =
        "/api/v1/auth/get-list?skip=" +
        pagination.pageIndex * pagination.pageSize +
        "&take=" +
        pagination.pageSize;

      if (keySearch && keySearch.length > 0)
        urlGet = urlGet + "&keySearch=" + keySearch;
      let _softing = "";
      let isOrder = false;
      if (sorting && sorting.length > 0) {
        let _q = sorting[0];
        if (_q) {
          _softing = _q.id;
          isOrder = _q.desc;
        }
      }
      urlGet = urlGet + "&sort=" + _softing + "&isOrder=" + isOrder;

      const response = await repositoryMaster.get<
        MessageResponse<UserMasterModel[]>
      >(urlGet);
      if (response && response.success) {
        setData(response.data ?? []);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const activeData = async (status: boolean) => {
    if (selectIds.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryMaster.post<MessageResponse<boolean>>(
          "/api/v1/auth/actives",
          {
            ids: selectIds,
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const activesData = async (status: boolean, id: string) => {
    if (!id || id.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryMaster.post<MessageResponse<boolean>>(
          "/api/v1/auth/actives",
          {
            ids: [id],
            status: status,
          }
        );
        if (res && res.success) {
          await fetchData();
          NotificationExtension.Success("Cập nhật thành công !");
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const deletesData = async (ids: string[]) => {
    if (ids.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Bạn có thực sự muốn xóa!</Title>
            </>
          ),

          size: "400px",
          // children: <>Bạn có thực sự muốn xóa ?</>,
          labels: { confirm: "Xác nhận", cancel: "Hủy bỏ" },
          onConfirm: async () => {
            const res = await repositoryMaster.post<MessageResponse<boolean>>(
              "/api/v1/auth/delete",
              ids
            );
            if (res)
              if (res.success) {
                await fetchData();
                NotificationExtension.Success("Xóa thành công !");
              } else NotificationExtension.Fails("Xóa thất bại !");
          },
          classNames: {
            title: "custom-modal-title", // Class cho title
            body: "custom-modal-body", // Class cho nội dung
          },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };
  function createItem() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thêm mới người dùng !</Title>
        </>
      ),

      size: "550px",
      children: <UserCreate onLoad={fetchData} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function editItem(id: string) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa !</Title>
        </>
      ),

      size: "550px",
      children: <UserEdit id={id} onLoad={fetchData} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const groupAddRoleModal = (idAuthozire: string) => {
    modals.closeAll();

    modals.openConfirmModal({
      title: "Danh sách nhóm",

      size: "100%",
      fullScreen: false,
      children: <UserAddGroup idAuthozire={idAuthozire}></UserAddGroup>,
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  const columns = React.useMemo<MRT_ColumnDef<UserMasterModel>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "userName",
        header: "Tên đăng nhập",
      },
      {
        accessorKey: "fullName",
        header: "Tên đầy đủ",
      },
      {
        accessorKey: "phone",
        header: "Số điện thoại",
      },
      {
        accessorKey: "inActive",
        header: "Trạng thái",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(row.original.inActive ?? false)}>
              {row.original.inActive ? "Đang hoạt động" : "Dừng hoạt động"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "createBy",
        header: "Người tạo",
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo",
      },

      {
        accessorKey: "action",
        header: "Phân quyền và thao tác",
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Tooltip label="Nhóm">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={() => {
                  groupAddRoleModal(row.original.id.toString());
                }}
              >
                <IconFunction size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editItem(row.original.userName);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip
              label={
                row.original.inActive === true ? "Dừng hoạt động" : "Hoạt động"
              }
            >
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color={row.original.inActive === true ? "violet" : "green"}
                onClick={async () => {
                  await activesData(
                    !row.original.inActive,
                    row.original.id.toString()
                  );
                }}
              >
                {row.original.inActive === true ? (
                  <IconHandStop size={20} />
                ) : (
                  <IconActivity size={20} />
                )}
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deletesData([row.original.id.toString()]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (200 + headerHeight));
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              //const k = e.target.value ?? "";
              setKeySearch(e.target.value);
            }}
          />

          <Button
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              createItem();
            }}
          >
            Thêm mới
          </Button>

          <Button
            leftSection={<IconActivity size={14} />}
            color="green"
            variant="outline"
            onClick={async () => {
              await activeData(true);
            }}
            disabled={selectIds.length < 1}
          >
            Hoạt động
          </Button>
          <Button
            leftSection={<IconHandStop size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await activeData(false);
            }}
            disabled={selectIds.length < 1}
          >
            Dừng hoạt động
          </Button>
          <Button
            leftSection={<IconTrash size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await deletesData(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Xóa nhóm (đã chọn)
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableRowNumbers: true,
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row?.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "userName"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
        //  backgroundColor: row.original.userName.length>0 ? "white" : "gray",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "75"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default UserListView;
