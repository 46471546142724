import {
  Badge,
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Divider,
  Flex,
  Grid,
  Input,
  Menu,
  NumberFormatter,
  Paper,
  rem,
  Select,
  Switch,
  Table,
  Tabs,
  Textarea,
  TextInput,
  Text,
  Group,
  Tooltip,
  ActionIcon,
  Title,
  SelectProps,
  Modal,
} from "@mantine/core";
import {
  IconAlignCenter,
  IconArrowsRandom,
  IconBrandAdobe,
  IconBrandAirbnb,
  IconBrandBackbone,
  IconBrandChrome,
  IconBrandCSharp,
  IconBrandOkRu,
  IconBrandProducthunt,
  IconBrandZhihu,
  IconBuildingWarehouse,
  IconButterfly,
  IconCalendar,
  IconCheck,
  IconCopy,
  IconCreditCardPay,
  IconDownload,
  IconDroplet,
  IconEdit,
  IconEye,
  IconFilter,
  IconFocusCentered,
  IconList,
  IconMapPin,
  IconMenuOrder,
  IconMinus,
  IconPhone,
  IconPhoneCall,
  IconPhoto,
  IconPigMoney,
  IconPlus,
  IconPoint,
  IconPrinter,
  IconPrism,
  IconRadar,
  IconRectangle,
  IconSearch,
  IconShip,
  IconSquareXFilled,
  IconStatusChange,
  IconTimeline,
  IconTimelineEventMinus,
  IconTrash,
  IconUser,
  IconUserCode,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { DateInput, DatePickerInput } from "@mantine/dates";
import { useDisclosure, useHotkeys, useIsFirstRender } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";

import { IconBrandD3 } from "@tabler/icons-react";
import { IconBrandTinder } from "@tabler/icons-react";
import { IconBrandBootstrap } from "@tabler/icons-react";
import { IconTimeDuration0 } from "@tabler/icons-react";
import { IconTimeDuration15 } from "@tabler/icons-react";
import { SelectListItem } from "../../../model/SelectListItem";
import {
  getOrderTypeSelect,
  getTblItemSelect,
  getTblVendorSelect,
} from "../../../service/getSelectApi";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import SalesDeleteOrder from "../../Sell/SalesDeleteOrder";
import { MessageResponse } from "../../../model/MessageResponse";
import { green, red, sky_blue } from "../../../const/variables";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { OrderDetail } from "../../../model/SaleOrderModel";
import { _localization } from "../../../config/location";
import { SocketExtension } from "../../../_base/socket/socket";
import { CREATEORDERSUCCESS } from "../../../_base/socket/ToplicList";
import { useNavigate } from "react-router-dom";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";

interface ResponseData {
  count: number;
  lists: any[];
}

interface DocumentType {
  value: number;
  text: string;
}

interface WarehouseOrDepartment {
  id: number;
  name: string;
}

interface Contact {
  id: number;
  name: string;
  phone: string;
}

interface DeliveryLocation {
  id: number;
  name: string;
  address: string;
}

interface PaymentLocation {
  id: number;
  name: string;
  address: string;
}

const newWarehouseOrDepartments: WarehouseOrDepartment[] = [
  { id: 501, name: "Kho tổng Đà Nẵng" },
  { id: 502, name: "Kho tổng Nha Trang" },
  { id: 503, name: "Kho tổng Cần Thơ" },
  { id: 504, name: "Kho tổng Biên Hòa" },
  { id: 505, name: "Kho tổng Bắc Ninh" },
  { id: 506, name: "Phòng Kỹ thuật Sản xuất" },
  { id: 507, name: "Phòng Quản lý Dự án" },
  { id: 508, name: "Phòng Giao nhận" },
  { id: 509, name: "Phòng Hành chính Tổng hợp" },
  { id: 510, name: "Phòng Kinh doanh Trực tiếp" },
  { id: 511, name: "Tổng Kho Đại lý" },
  { id: 512, name: "Kho tổng Bình Thạnh" },
  { id: 513, name: "Kho tổng Thủ Đức" },
  { id: 514, name: "Kho tổng Vũng Tàu" },
  { id: 515, name: "Kho tổng Hạ Long" },
  { id: 516, name: "Kho tổng Mỹ Đình" },
  { id: 517, name: "Kho tổng Hải Dương" },
  { id: 518, name: "Kho tổng Nghệ An" },
  { id: 519, name: "Kho tổng Đồng Nai" },
  { id: 520, name: "Kho tổng Hà Nam" },
  { id: 521, name: "Kho tổng Điện Biên" },
];

const contacts: Contact[] = [
  { id: 601, name: "Nguyễn Văn A", phone: "0123-456-789" },
  { id: 602, name: "Trần Thị B", phone: "0123-456-788" },
  { id: 603, name: "Lê Văn C", phone: "0123-456-787" },
  { id: 604, name: "Phạm Thị D", phone: "0123-456-786" },
  { id: 605, name: "Nguyễn Thị E", phone: "0123-456-785" },
  { id: 606, name: "Vũ Văn F", phone: "0123-456-784" },
  { id: 607, name: "Hoàng Thị G", phone: "0123-456-783" },
  { id: 608, name: "Nguyễn Văn H", phone: "0123-456-782" },
  { id: 609, name: "Trần Văn I", phone: "0123-456-781" },
  { id: 610, name: "Lê Thị J", phone: "0123-456-780" },
  { id: 611, name: "Phạm Văn K", phone: "0123-456-779" },
  { id: 612, name: "Vũ Thị L", phone: "0123-456-778" },
  { id: 613, name: "Nguyễn Văn M", phone: "0123-456-777" },
  { id: 614, name: "Trần Thị N", phone: "0123-456-776" },
  { id: 615, name: "Lê Văn O", phone: "0123-456-775" },
  { id: 616, name: "Nguyễn Thị P", phone: "0123-456-774" },
  { id: 617, name: "Hoàng Văn Q", phone: "0123-456-773" },
  { id: 618, name: "Phạm Văn R", phone: "0123-456-772" },
  { id: 619, name: "Vũ Thị S", phone: "0123-456-771" },
  { id: 620, name: "Nguyễn Văn T", phone: "0123-456-770" },
  { id: 621, name: "Trần Thị U", phone: "0123-456-769" },
];

const deliveryLocations: DeliveryLocation[] = [
  { id: 700, name: "Địa chỉ Giao hàng A", address: "124 Minh Khai" },
  { id: 701, name: "Địa chỉ Giao hàng A", address: "123 Đường ABC, Đà Nẵng" },
  { id: 702, name: "Địa chỉ Giao hàng B", address: "456 Đường DEF, Nha Trang" },
  { id: 703, name: "Địa chỉ Giao hàng C", address: "789 Đường GHI, Cần Thơ" },
  { id: 704, name: "Địa chỉ Giao hàng D", address: "321 Đường JKL, Biên Hòa" },
  { id: 705, name: "Địa chỉ Giao hàng E", address: "654 Đường MNO, Bắc Ninh" },
  { id: 706, name: "Địa chỉ Giao hàng F", address: "987 Đường PQR, Hạ Long" },
  { id: 707, name: "Địa chỉ Giao hàng G", address: "159 Đường STU, Vũng Tàu" },
  { id: 708, name: "Địa chỉ Giao hàng H", address: "753 Đường VWX, Thủ Đức" },
  { id: 709, name: "Địa chỉ Giao hàng I", address: "852 Đường YZ, Hải Dương" },
  { id: 710, name: "Địa chỉ Giao hàng J", address: "951 Đường AAB, Nghệ An" },
  { id: 711, name: "Địa chỉ Giao hàng K", address: "357 Đường CCD, Đồng Nai" },
  { id: 712, name: "Địa chỉ Giao hàng L", address: "159 Đường DDE, Hà Nam" },
  { id: 713, name: "Địa chỉ Giao hàng M", address: "753 Đường FFG, Điện Biên" },
  {
    id: 714,
    name: "Địa chỉ Giao hàng N",
    address: "951 Đường HHI, Bình Thạnh",
  },
  { id: 715, name: "Địa chỉ Giao hàng O", address: "357 Đường JJK, Long Biên" },
  { id: 716, name: "Địa chỉ Giao hàng P", address: "159 Đường KKL, Từ Sơn" },
  { id: 717, name: "Địa chỉ Giao hàng Q", address: "753 Đường LLM, Thanh Trì" },
  { id: 718, name: "Địa chỉ Giao hàng R", address: "951 Đường MNN, Đông Anh" },
  { id: 719, name: "Địa chỉ Giao hàng S", address: "357 Đường OOO, Bạc Liêu" },
  { id: 720, name: "Địa chỉ Giao hàng T", address: "159 Đường PPP, Hậu Giang" },
];

const paymentLocations: PaymentLocation[] = [
  { id: 800, name: "Địa điểm Thanh toán A", address: "131 Lê Thanh Nghị" },

  { id: 801, name: "Địa điểm Thanh toán A", address: "123 Đường ZYX, Hà Nội" },
  { id: 802, name: "Địa điểm Thanh toán B", address: "456 Đường QWE, Đà Nẵng" },
  { id: 803, name: "Địa điểm Thanh toán C", address: "789 Đường RTY, TP.HCM" },
  {
    id: 804,
    name: "Địa điểm Thanh toán D",
    address: "321 Đường UIO, Hải Phòng",
  },
  { id: 805, name: "Địa điểm Thanh toán E", address: "654 Đường PAS, Cần Thơ" },
  {
    id: 806,
    name: "Địa điểm Thanh toán F",
    address: "987 Đường DFG, Nha Trang",
  },
  {
    id: 807,
    name: "Địa điểm Thanh toán G",
    address: "159 Đường HJK, Thái Nguyên",
  },
  {
    id: 808,
    name: "Địa điểm Thanh toán H",
    address: "753 Đường LOP, Bắc Ninh",
  },
  {
    id: 809,
    name: "Địa điểm Thanh toán I",
    address: "852 Đường QRS, Biên Hòa",
  },
  {
    id: 810,
    name: "Địa điểm Thanh toán J",
    address: "951 Đường TUV, Long Biên",
  },
  { id: 811, name: "Địa điểm Thanh toán K", address: "357 Đường WXY, Vinh" },
  { id: 812, name: "Địa điểm Thanh toán L", address: "159 Đường ZZZ, Hạ Long" },
  { id: 813, name: "Địa điểm Thanh toán M", address: "753 Đường AAA, Đà Lạt" },
  {
    id: 814,
    name: "Địa điểm Thanh toán N",
    address: "951 Đường BBB, Bạc Liêu",
  },
  {
    id: 815,
    name: "Địa điểm Thanh toán O",
    address: "357 Đường CCC, Thái Bình",
  },
  {
    id: 816,
    name: "Địa điểm Thanh toán P",
    address: "159 Đường DDD, Hưng Yên",
  },
  {
    id: 817,
    name: "Địa điểm Thanh toán Q",
    address: "753 Đường EEE, Bình Dương",
  },
  {
    id: 818,
    name: "Địa điểm Thanh toán R",
    address: "951 Đường FFF, Quảng Ninh",
  },
  {
    id: 819,
    name: "Địa điểm Thanh toán S",
    address: "357 Đường GGG, Ninh Bình",
  },
  { id: 820, name: "Địa điểm Thanh toán T", address: "159 Đường HHH, Nghệ An" },
];

const orderTypes = [
  { value: "1", label: "Tạo mới", color: "blue" },
  { value: "2", label: "Đã duyệt", color: "green" },
  { value: "3", label: "Đóng đơn", color: "yellow" },
  { value: "4", label: "Hủy đơn", color: "red" },
];

const blanketNumberSelect: DocumentType[] = [
  { value: 0, text: "Chấp thuận" },
  { value: 1, text: "Từ Chối" },
];

const approvedFlagSelect: DocumentType[] = [
  { value: 0, text: "Đã phê duyệt" },
  { value: 1, text: "Chưa phê duyệt" },
  { value: 2, text: "Đang chờ phê duyệt" },
];

const ImportPurchasesView = () => {
  const navigate = useNavigate();

  //#region  table
  //data and fetching state
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const [dataFetch, setDataFetch] = useState<SelectListItem[]>([]);
  const [dataWarehouse, setDataWarehouse] = useState<SelectListItem[]>([]);
  const [dataIndustry, setDataIndustry] = useState<SelectListItem[]>([]);
  const [dataOrderType, setOrderType] = useState<SelectListItem[]>([]);
  const [dataListCustomer, setDataListCustomer] = useState<SelectListItem[]>(
    []
  );
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(1);
  const [take, setTake] = useState<number>(30);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [dataListVendor, setDataListVendor] = useState<any[]>([]);
  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);
  //
  const [dateUsers, setUsers] = useState<SelectListItem[]>([]);
  const [dataTblOrderTypeSelect, setDataTblOrderTypeSelect] = useState<
    ComboboxItem[]
  >([]);
  const debounceTimeoutRef = React.useRef<number | null>(null);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    keySearch: "",
    BuyerId: "",
    VendorId: "",
    ShipToLocationId: "",
    BillToLocationId: "",
    OrgId: "",
  });

  const [keySearch, setKeySearch] = useState<string>("");
  const [createdById, setcreatedById] = useState<number>(0);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const [detailOpened, { open: openDetail, close: closeDetail }] =
    useDisclosure(false);
  const [dataTblVendoSelect, setTblVendorData] = useState<ComboboxItem[]>([]);
  const [dataTblBillTolocation, setTblBillToLocation] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblBillDeliveryLocations, setTblDeliveryLocations] = useState<
    ComboboxItem[]
  >([]);
  const [datatblDSupplyLocation, setDataTblDSupplyLocation] = useState<
    ComboboxItem[]
  >([]);

  const sizeGrid = { base: 6, sm: 4, md: 3, lg: 3, xl: 2 };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        StartTime: search.startDate,
        EndTime: search.endDate,
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        BuyerId: search.BuyerId,
        VendorId: search.VendorId,
        ShipToLocationId: search.ShipToLocationId,
        BillToLocationId: search.BillToLocationId,
        OrgId: search.BuyerId,
        KeySearch: search.keySearch || keySearch,
      };

      const url = Object.entries(queryParams)
        .reduce((acc, [key, value]) => {
          if (value !== undefined && value !== "") {
            return `${acc}&${key}=${encodeURIComponent(value)}`;
          }
          return acc;
        }, "/api/v1/TblPoHeader/get-list?")
        .replace("&", "");

      const response = await repositoryMdm.get<MessageResponse<any[]>>(url);

      if (response && response.success) {
        const data = response?.data;
        setData(data);
        setRowCount(response.totalCount);
        //  await dataOrderTypeSelect();
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (key === "customerId") {
      setSelectedCustomer(value);
    }
    setSearch((prevData) => ({ ...prevData, [key]: value }));
    setSelectedValue(value);
  };

  const handleRangeChange = (dates: [Date | null, Date | null]) => {
    setValue(dates);
    const [startDate, endDate] = dates;
    const startDateISO = startDate ? startDate.toLocaleDateString() : null;
    const endDateISO = endDate ? endDate.toLocaleDateString() : null;

    handleChangeSearchValue(startDateISO ?? "", "startDate");

    handleChangeSearchValue(endDateISO ?? "", "endDate");
  };

  const orderTypes = [
    { value: "1", label: "Tạo mới", color: "blue" },
    { value: "2", label: "Đã duyệt", color: "green" },
    { value: "3", label: "Đóng đơn", color: "yellow" },
    { value: "4", label: "Hủy đơn", color: "red" },
  ];

  const fetchDataListVendor = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/TblVendor/get-all");

    if (response && response.success) {
      let result = response.data;
      setDataListVendor(result);
    }
  };

  useEffect(() => {
    fetchDataListVendor();
  }, []);

  useEffect(() => {
    if (value && value.length > 0) {
      if (value[0]) {
        const startDate = value[0];
        const startDateISO = startDate
          ? new Date(
              startDate.getTime() - startDate.getTimezoneOffset() * 60000
            ).toISOString()
          : "";
        handleChangeSearchValue(startDateISO, "startDate");
      }
      if (value[1]) {
        const endDate = value[1];
        const endDateISO = endDate
          ? new Date(
              endDate.getTime() - endDate.getTimezoneOffset() * 60000
            ).toISOString()
          : "";

        handleChangeSearchValue(endDateISO, "endDate");
      }
    }
  }, [value]);
  const orderStatuses = [
    { value: "DON_HANG_TRANG", label: "Đơn hàng trống" },
    { value: "DON_HANG_CHUA_LUU", label: "Đơn hàng chưa lưu" },
    { value: "DON_HANG_TAM", label: "Đơn hàng tạm" },
    { value: "DUYET_GIAM_GIA_TIEP_NHAN", label: "Duyệt giảm giá tiếp nhận" },
    { value: "DUYET_GIAM_GIA_XONG", label: "Duyệt giảm giá xong" },
    { value: "DON_HANG_KHOA", label: "Đơn hàng khóa" },
    { value: "KHO_XUAT_HANG", label: "Kho xuất hàng" },
    { value: "KY_THUAT_TIEP_NHAN", label: "Kỹ thuật tiếp nhận" },
    { value: "KY_THUAT_XONG", label: "Kỹ thuật xong" },
    { value: "DON_HANG_HUY", label: "Đơn hàng hủy" },
    { value: "DON_HANG_THANH_CONG", label: "Đơn hàng thành công" },
    { value: "GHNB_TIEP_NHAN", label: "GHNB tiếp nhận" },
    { value: "DHNB_XONG", label: "DHNB xong" },
    { value: "GHNB_THAT_BAI_L1", label: "GHNB thất bại lần 1" },
    { value: "DHNB_THAT_BAI", label: "DHNB thất bại" },
    { value: "DON_HANG_DOI_TRA", label: "Đơn đổi trả" },
  ];
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "poNumber",
        header: "Số đơn hàng",
        Cell: ({ renderedCellValue, cell, row }) => (
          <Tooltip label="Chi tiết đơn">
            <Badge
              radius="xs"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/warehouse-config/purchase-order-detail", {
                  state: {
                    value: row.original.poHeaderId || null,
                  },
                })
              }
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue === null ? "KHÔNG HỢP LỆ" : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "poHeaderId",
        header: "Mã đơn hàng",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge color="green">{renderedCellValue}</Badge>
        ),
      },
      {
        accessorKey: "vendorId",
        header: "Nhà cung cấp",
        Cell: ({ renderedCellValue, row }) => {
          const toLocations =
            dataListVendor?.find(
              (item: any) => item.id === row.original.vendorId
            )?.vendorName || "";
          return (
            <Text tt="capitalize" fw={600}>
              {toLocations}
            </Text>
          );
        },
      },
      {
        accessorKey: "creationDate",
        header: "Ngày tạo",
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Text tt="capitalize" fw={600}>
              {formatDateTransfer(String(renderedCellValue))}
            </Text>
          );
        },
      },

      {
        accessorKey: "status",
        header: "Trạng thái",
        Cell: ({ renderedCellValue }) => {
          const badgeColor = orderTypes.find(
            (x) => x.label === renderedCellValue
          )?.color;
          return renderedCellValue ? (
            <Badge variant="light" color={badgeColor} radius="sm">
              {renderedCellValue}
            </Badge>
          ) : null;
        },
      },
      {
        accessorKey: "currencyCode",
        header: "Loại tiền",
        Cell: ({ renderedCellValue }) => {
          return renderedCellValue !== "" && renderedCellValue !== null ? (
            <Badge variant="filled" color="yellow">
              {renderedCellValue}
            </Badge>
          ) : null;
        },
      },
      {
        accessorKey: "vendorContactId",
        header: "Người liên hệ",
        Cell: ({ renderedCellValue, row }) => {
          const contact =
            contacts?.find((item: any) => item.id === renderedCellValue)
              ?.name || "";
          return <AvatarUtils value={contact} />;
        },
      },
      {
        accessorKey: "shipToLocationId",
        header: "Địa điểm giao hàng",
        Cell: ({ renderedCellValue }) => {
          const toLocations =
            deliveryLocations?.find(
              (item: any) => item.id === renderedCellValue
            )?.address || "";
          return <Text fw={600}>{toLocations}</Text>;
        },
      },
      {
        accessorKey: "vendorSiteId",
        header: "Địa điểm cung cấp",
        Cell: ({ renderedCellValue }) => {
          const vendorSite =
            newWarehouseOrDepartments?.find(
              (item: any) => item.id === renderedCellValue
            )?.name || "";
          return <Text fw={600}>{vendorSite}</Text>;
        },
      },
      {
        accessorKey: "billToLocationId",
        header: "Địa điểm thanh toán",
        Cell: ({ renderedCellValue }) => {
          const billToLocation =
            paymentLocations?.find((item: any) => item.id === renderedCellValue)
              ?.address || "";
          return <Text fw={600}>{billToLocation}</Text>;
        },
      },
      {
        accessorKey: "type",
        header: "Loại đơn đặt hàng",
      },
      {
        accessorKey: "noteToReceiver",
        header: "Ghi chú người nhận",
      },
      {
        accessorKey: "comments",
        header: "Ghi chú chung",
      },
      {
        accessorKey: "blanketTotalAmount",
        header: "Tổng tiền",
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={
              typeof renderedCellValue === "number" ||
              typeof renderedCellValue === "string"
                ? renderedCellValue
                : 0
            }
            thousandSeparator
          />
        ),
      },
    ],
    [dataTblOrderTypeSelect, dataListVendor]
  );

  const columnsDetail = React.useMemo<MRT_ColumnDef<any[]>[]>(
    () => [
      {
        accessorKey: "itemId",
        header: "Mã hàng",
        Cell: ({ renderedCellValue }) => {
          return (
            <Text size="sm" fw={600}>
              {renderedCellValue}
            </Text>
          );
        },
      },
      {
        accessorKey: "itemDescription",
        header: "Tên vật tư",
        Cell: ({ renderedCellValue }) => {
          return (
            <Text size="sm" fw={600}>
              {renderedCellValue}
            </Text>
          );
        },
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        Cell: ({ renderedCellValue }) => {
          return (
            <Text size="sm" fw={600}>
              {renderedCellValue}
            </Text>
          );
        },
      },
      {
        accessorKey: "createdDate",
        header: "Ngày tạo",
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return (
              <>
                <Text size="sm" fw={600}>
                  {formatDateTransfer(renderedCellValue)}
                </Text>
              </>
            );
          }
          return <>{formatDateTransfer(undefined)}</>;
        },
      },
      {
        accessorKey: "noteToVendor",
        header: "Ghi chú",
        Cell: ({ renderedCellValue }) => {
          return (
            <Text size="sm" fw={600}>
              {renderedCellValue}
            </Text>
          );
        },
      },
    ],
    []
  );

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  // console.log(data);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: [
          "poNumber",
          "mrt-row-expand",
          "orderNumber",
          "orderStatus",
          // "orderType",
        ],
        right: ["shippingStatus", "action", "blanketTotalAmount"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    enableExpanding: false,
    // getSubRows: (originalRow) => originalRow.orderDetail,
    // renderDetailPanel: ({ row }) => (
    //   <MantineReactTable
    //     columns={columnsDetail}
    //     data={row.original.tblPoLineModels}
    //     mantineTableProps={{
    //       striped: false,
    //     }}
    //     enableTopToolbar={false}
    //     enableBottomToolbar={false}
    //     enableColumnPinning={true}
    //     initialState={{
    //       showColumnFilters: false,
    //       columnPinning: {
    //         left: ["itemCode"],
    //         // right: ["shippingStatus", "orderStatus", "action"],
    //       },
    //       columnVisibility: { id: false },
    //       density: "xs",
    //       pagination: {
    //         pageSize: 50,
    //         pageIndex: 0,
    //       },
    //     }}
    //   />
    // ),
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "650", //conditional styling
        fontSize: "12px",
        padding: "3px 10px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  const fetchDataTblItem = async () => {
    const response = await getTblVendorSelect();
    setTblVendorData(
      response.map((employee) => ({
        value: employee.value,
        label: employee.text,
      }))
    );
  };

  const fetchDataBillToLocation = async () => {
    setTblBillToLocation(
      paymentLocations.map((employee) => ({
        value: String(employee.id),
        label: employee.address,
      }))
    );
  };

  const fetchDataDeliveryLocation = async () => {
    setTblDeliveryLocations(
      deliveryLocations.map((employee) => ({
        value: String(employee.id),
        label: employee.address,
      }))
    );
  };

  const fetchDataSupplyLocation = async () => {
    setDataTblDSupplyLocation(
      newWarehouseOrDepartments.map((employee) => ({
        value: String(employee.id),
        label: employee.name,
      }))
    );
  };

  useEffect(() => {
    fetchDataTblItem();
    fetchDataBillToLocation();
    fetchDataDeliveryLocation();
    fetchDataSupplyLocation();
  }, []);
  //hàm xem chi tiết danh sách đơn
  const fetchDataCustomer = async (searchTerm: string) => {
    setLoading(true);
    const query = searchTerm ? `KeySearch=${searchTerm}&` : "";
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmCustomer/get-select?${query}Skip=${1}&Take=${30}`
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.filter(
        (item: any) => item.text !== null
      );
      setDataListCustomer(filteredData);
      setSkip((prevSkip) => prevSkip + 1);
    }
    setLoading(false);
  };

  const debouncedFetchDataCustomer = React.useCallback((value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(() => {
      fetchDataCustomer(value);
    }, 300);
  }, []);

  // useEffect(() => {
  //   fetchDataCustomer("");
  // }, []);

  useEffect(() => {
    debouncedFetchDataCustomer(searchTerm);
  }, [searchTerm, debouncedFetchDataCustomer]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting, deleteViewStatus]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    SocketExtension.On(CREATEORDERSUCCESS, (arg: any) => {
      // NotificationExtension.Info(arg.b);
      fetchData();
    });
    return () => {
      SocketExtension.Off(CREATEORDERSUCCESS, () => {});
    };
  }, []);
  //#endregion
  // Icon color mappings based on value

  // Render function to customize the option appearance
  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={orderTypes.find((x) => x.value === option.value)?.color}
        radius="sm"
        variant="filled"
      ></Badge>
      {option.label}{" "}
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );
  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <div>
            <Button
              leftSection={<IconPlus size={14} />}
              variant="outline"
              onClick={() => {
                navigate("/warehouse-config/import-purchases");
              }}
              mr={"xs"}
            >
              Thêm mới (F11)
            </Button>
          </div>
        </Flex>

        <Grid mt={"10px"}>
          <Grid.Col span={sizeGrid}>
            <Input
              placeholder="Từ khóa"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              min={0}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              type="range"
              allowSingleDateInRange
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              size="sm"
              placeholder="Chọn ngày"
              locale="vi"
              flex={1}
              value={value}
              valueFormat="DD/MM/YYYY"
              onChange={handleRangeChange}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              placeholder={"Chọn nhà cung cấp"}
              searchable
              clearable
              data={dataTblVendoSelect}
              leftSection={<IconBuildingWarehouse color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Không có dữ liệu"}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "VendorId")
              }
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              placeholder={"Chọn địa điểm thanh toán"}
              searchable
              clearable
              data={dataTblBillTolocation}
              leftSection={<IconRadar color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Không có dữ liệu"}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "BillToLocationId")
              }
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              placeholder={"Chọn địa điểm giao hàng"}
              searchable
              clearable
              data={dataTblBillDeliveryLocations}
              leftSection={<IconMapPin color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Không có dữ liệu"}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "ShipToLocationId")
              }
            />
          </Grid.Col>

          {/* <Grid.Col span={sizeGrid}>
            <Select
              placeholder={"Chọn địa điểm cung cấp"}
              searchable
              clearable
              data={datatblDSupplyLocation}
              leftSection={<IconMapPin color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              nothingFoundMessage={"Không có dữ liệu"}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "vendorSiteId")
              }
            />
          </Grid.Col> */}
          <Grid.Col span={sizeGrid}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default ImportPurchasesView;
