import {
  Box,
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { ListAuthorizeCommand } from "../../model/ListAuthorizeCommand";
import { MessageResponse } from "../../model/MessageResponse";
import { _validate } from "../../validate/ListAuthorizeCommandvalidator";
import { ListAuthozireModel } from "../../model/ListAuthozireModel";
import { repositoryMaster } from "../../_base/_const/_constVar";
import { sky_blue } from "../../const/variables";

const GroupRoleEdit = function ({
  id,
  onClose,
  load,
}: {
  id: number;
  onClose: any;
  load: number;
}) {
  const entity: ListAuthorizeCommand = {
    id: 0,
    name: "",
  };
  const form = useForm<ListAuthorizeCommand>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: _validate,
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataAllSelect, setDataAllSelect] = useState<ListAuthozireModel[]>([]);

  const callApiGetData = async () => {
    open();
    let urlCreate = `/api/v1/ListAuthozire/edit?id=${id}`;
    const callapi = await repositoryMaster.get<
      MessageResponse<ListAuthorizeCommand>
    >(urlCreate);
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        await apiGetParent();
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      modals.closeAll();
    }
  };
  const apiGetParent = async () => {
    const response = await repositoryMaster.get<
      MessageResponse<ListAuthozireModel[]>
    >("/api/v1/ListAuthozire/get-list");
    if (response && response.success) {
      setDataAllSelect(response.data);
    }
  };
  const apiCreate = async (data: ListAuthorizeCommand) => {
    open();

    let urlCreate = `/api/v1/ListAuthozire/edit`;
    const callapi = await repositoryMaster.post(urlCreate, data);
    if (callapi)
      if (callapi?.success) {
        onClose(load + 1);
        NotificationExtension.Success("Chỉnh sửa thành công !");
        modals.closeAll();
      } else if (callapi != null)
        NotificationExtension.Fails("Chỉnh sửa thất bại !");
    close();
  };

  useEffect(() => {
    callApiGetData();
  }, []);
  const handleConverDataOptionParent = (data: ListAuthozireModel[]) => {
    return data?.map((item: ListAuthozireModel) => {
      return {
        value: item.id.toString(),
        label: item.name,
      };
    });
  };
  const handleChangeDistrict = (e: string | null) => {
    form.getInputProps("parentId").onChange(e);
  };
  return (
    <>
      <Box
        component="form"
        onSubmit={form.onSubmit(async (e: ListAuthorizeCommand) => {
          await apiCreate(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Box className="flex-none" component="form" mx="auto">
          <TextInput
            key={form.key("name")}
            label="Tên nhóm: "
            placeholder="Nhập..."
            withAsterisk
            {...form.getInputProps("name")}
          />
          <TextInput
            mt={"lg"}
            key={form.key("description")}
            label="Mô tả: "
            placeholder="Nhập..."
            {...form.getInputProps("description")}
          />
          <Select
            key={form.key("parentId")}
            mt={"lg"}
            label="Thuộc nhóm"
            placeholder="Nhóm người dùng"
            nothingFoundMessage="Không có dữ liệu"
            data={handleConverDataOptionParent(dataAllSelect)}
            defaultValue={form.getValues().parentId}
            searchable
            withAsterisk
            mb={"lg"}
            {...form.getInputProps("parentId")}
          />
          <Checkbox
            mt={"xs"}
            key={form.key("inActive")}
            label="Trạng thái"
            defaultValue={form.getValues().inActive ?? false}
            defaultChecked={form.getValues().inActive ?? false}
            {...form.getInputProps("inActive")}
          />
        </Box>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default GroupRoleEdit;
