import {
  Box,
  Checkbox,
  Group,
  LoadingOverlay,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect } from "react";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { getDetailDmBranch } from "../../../../api/apiDMBranch";
import { TblDMBranch } from "../../../../model/TblDMBranch";
import { repositoryDelivery } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    name: null,
    address: null,
    phone: null,
    fax: null,
    email: null,
    note: null,
    location: null,
    road: null,
    province: null,
    country: null,
    channelCode: null,
    isOnline: 0,
    masterInventoryOrg: null,
    guarantee: null,
    orgId: null,
    townName: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMBranch>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryDelivery.get<MessageResponse<TblDMBranch>>(
      `/api/v1/TblDMMasterOrg/detail?id=${id}`
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  return (
    <>
      <Box component="form" mx="auto" style={{ position: "relative" }}>
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Group grow wrap="nowrap" mt={10} gap={"lg"}>
          <TextInput
            readOnly
            variant="filled"
            label={"Mã trung tâm"}
            disabled
            {...form.getInputProps("code")}
          />
          <TextInput
            readOnly
            variant="filled"
            label={"Tên trung tâm"}
            {...form.getInputProps("name")}
          />
        </Group>

        <Group grow wrap="nowrap" gap={"lg"}>
          <TextInput
            readOnly
            variant="filled"
            label={"Địa chỉ"}
            {...form.getInputProps("address")}
          />
          <TextInput
            readOnly
            variant="filled"
            label={"Vị trí"}
            {...form.getInputProps("location")}
          />
        </Group>

        <Group grow wrap="nowrap" gap={"lg"}>
          <TextInput
            readOnly
            variant="filled"
            label={"Đường"}
            {...form.getInputProps("road")}
          />
        </Group>

        <Group grow wrap="nowrap" gap={"lg"}>
          <TextInput
            readOnly
            variant="filled"
            label={"Tỉnh/Thành phố"}
            {...form.getInputProps("province")}
          />
          <TextInput
            readOnly
            variant="filled"
            label={"Quốc gia"}
            {...form.getInputProps("country")}
          />
        </Group>

        <Group grow wrap="nowrap" gap={"lg"}>
          <TextInput
            readOnly
            variant="filled"
            label={"Điện thoại"}
            type="number"
            {...form.getInputProps("phone")}
          />
          <TextInput
            readOnly
            variant="filled"
            label={"Fax"}
            {...form.getInputProps("fax")}
          />
        </Group>

        <Group grow wrap="nowrap" gap={"lg"}>
          <TextInput
            readOnly
            variant="filled"
            label={"Email"}
            {...form.getInputProps("email")}
          />
        </Group>

        <Group grow wrap="nowrap" gap={"lg"}>
          <Textarea
            label={"Ghi chú"}
            readOnly
            variant="filled"
            {...form.getInputProps("note")}
          />
        </Group>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
