import "../../../Styles/tab.component.css";

import { Paper, Tabs } from "@mantine/core";
import { useState } from "react";
import DMLocation from "./DMLocation/DMLocation";
import DMDeliveryDepartment from "./DMDeliveryDepartment/DMDeliveryDepartment";
import DMDeliveryJob from "./DMDeliveryJob/DMDeliveryJob";
import DMDeliveryWork from "./DMDeliveryWork/DMDeliveryWork";
import DMDeliverPriority from "./DMDeliverPriority/DMDeliverPriority";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";

const Transport = () => {
  const [activeTab, setActiveTab] = useState<string | null>(
    "deliveryDepartment"
  );
  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Paper shadow="md" radius="lg">
        <Tabs p={"xs"} value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="deliveryDepartment">
              Danh mục bộ phận giao hàng
            </Tabs.Tab>
            <Tabs.Tab value="location">Danh mục địa điểm</Tabs.Tab>
            <Tabs.Tab value="deliveryJob">
              Danh mục công việc giao hàng
            </Tabs.Tab>
            <Tabs.Tab value="deliverywork">
              Danh mục công việc giao vận
            </Tabs.Tab>
            <Tabs.Tab value="deliverpriority">
              Danh mục mức độ ưu tiên giao hàng
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="deliveryDepartment">
            {activeTab === "deliveryDepartment" ? (
              <DMDeliveryDepartment />
            ) : (
              <></>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="location">
            {activeTab === "location" ? <DMLocation /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="deliveryJob">
            {activeTab === "deliveryJob" ? <DMDeliveryJob /> : <></>}
          </Tabs.Panel>

          <Tabs.Panel value="deliverywork">
            {activeTab === "deliverywork" ? <DMDeliveryWork /> : <></>}
          </Tabs.Panel>

          <Tabs.Panel value="deliverpriority">
            {activeTab === "deliverpriority" ? <DMDeliverPriority /> : <></>}
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </>
  );
};

export default Transport;
