import {
  Box,
  Checkbox,
  Grid,
  LoadingOverlay,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMSerialStatus } from "../../../../model/TblDMSerialStatus";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    name: null,
    note: null,
    active: null,
    modifiDate: null,
    createBy: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMSerialStatus>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<TblDMSerialStatus>>(
      `/api/v1/TblDMSerialStatus/detail?id=${id}`
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "250px", md: "300px", lg: "400px" }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã"}
              placeholder={"Nhập mã"}
              type="text"
              variant="filled"
              {...form.getInputProps("code")}
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <TextInput
              label={"Tên"}
              placeholder={"Nhập tên"}
              variant="filled"
              type="text"
              {...form.getInputProps("name")}
              readOnly
            />
          </Grid.Col>
        </Grid>

        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Textarea
              label={"Ghi chú"}
              placeholder="Nhập ghi chú"
              variant="filled"
              {...form.getInputProps("note")}
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Checkbox
              label={"Sử dụng"}
              checked={form.values.active}
              readOnly
              {...form.getInputProps("active")}
              onChange={() => {}}
            />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
