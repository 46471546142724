import { Box, Flex, Grid, Group, Image, Text } from "@mantine/core";
import React, { useState } from "react";
import { AuthenticationTitle } from "./_login";
import style from "./AuthenticationTitle.module.css";
import { IconHttpPost } from "@tabler/icons-react";
import imageLogin from "../../../assets/images/login-pana.png";
import Register from "../_register/_register";
const LayoutLogin = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [dataUserSign, setDataUserSign] = useState<{
    name: string;
    pass: string;
  }>({ name: "", pass: "" });
  return (
    <Box className={style.container}>
      <Box className={style.logo}>
        <Image
          w={140}
          h={50}
          src="https://hanoicomputercdn.com/media/lib/09-08-2023/logo-hacom-since-2001.png"
        />
      </Box>
      <Box className={style.imageUser}>
        <Image src={imageLogin} />
      </Box>
      <Box className={style.images}>
        <svg
          width="100%"
          height="100vh"
          preserveAspectRatio="xMidYMid slice"
          viewBox="10 10 80 80"
        >
          <path
            fill="#5a2392e0"
            className={style.outTop}
            d="M37-5C25.1-14.7,5.7-19.1-9.2-10-28.5,1.8-32.7,31.1-19.8,49c15.5,21.5,52.6,22,67.2,2.3C59.4,35,53.7,8.5,37-5Z"
          />
        </svg>
      </Box>
      <Box className={style.content_login}>
        <Text c="#342e79" className={style.title} tt="uppercase" fw={700}>
          Hệ thống quản lý bán hàng hacom
        </Text>
        <Box className={style.content}>
          {isLogin === false ? (
            <AuthenticationTitle
              setIsLogin={setIsLogin}
              dataUserSign={dataUserSign}
            />
          ) : (
            <Register
              setIsLogin={setIsLogin}
              setDataUserSign={setDataUserSign}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutLogin;
