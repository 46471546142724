import { Flex } from "@mantine/core";
import { Outlet } from "react-router-dom";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";

export const ProposalView = () => {
  return (
    <>
      <div style={{ border: "1px solid #dee2e6", padding: "5px" }}>
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
        </Flex>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <Outlet></Outlet>
      </div>
    </>
  );
};
