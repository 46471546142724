import {
  Button,
  ComboboxItem,
  Flex,
  Menu,
  Select,
  TextInput,
  Title,
  Tooltip,
  rem,
  Text,
  Grid,
  Badge,
  Box,
  ActionIcon,
  SelectProps,
  Group,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { modals } from "@mantine/modals";
import {
  IconBriefcase2,
  IconCalendar,
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconSearch,
  IconStatusChange,
  IconTruckDelivery,
  IconUserCode,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryDelivery,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { TblDeliveryAssignment } from "../../../model/TblDeliveryAssignment";
import CancelAssignmentView from "./CancelAssignmentView";
import EditAssignment from "./EditAssignment";
import {
  getBranchSelectMdm,
  getDeliveryStatusSelectMdm,
  getDeliveryWorkSelect,
  getTblDmEmployeeSelect,
} from "../../../service/getSelectApi";
import DeliveryList from "./DeliveryList";
import DetailDeliveryAssignment from "./DetailDeliveryAssignment";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import DeliveryWarranty from "./DeliveryWarranty";
import { green } from "../../../const/variables";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import { useDebouncedState } from "@mantine/hooks";
import { getValueById } from "../../../_base/helper/FunctionHelper";

const DeliveryDispatch = () => {
  // Reference data from another table
  const [dataTblBranchSelect, setDataTblBranchSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblDeliveryStatusSelect, setDataTblDeliveryStatusSelect] =
    useState<ComboboxItem[]>([]);
  const [employeeData, setEmployeeData] = useState<ComboboxItem[]>([]);
  const [dataWorkSelect, setDataWorkSelect] = useState<ComboboxItem[]>([]);
  const [dataStatus, setDataStatus] = useState<ComboboxItem[]>([
    { label: "Chưa giao", value: "CHUA_GIAO" },
    { label: "Đang giao", value: "DANG_GIAO" },
    { label: "Hoàn thành", value: "HOAN_THANH" },
  ]);

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDeliveryAssignment[]>([]);
  const [dataTemp, setDataTemp] = useState<TblDeliveryAssignment[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const [search, setSearch] = useState({
    code: "",
    startDate: "",
    endDate: "",
    employeeId: "",
    vehicleCode: "",
    deliveryWorkId: "",
    status: "",
  });

  const [keySearch, setKeySearch] = useDebouncedState("", 500);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Số vận đơn",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailAssignment(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        size: 30,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "status",
        header: "Trạng thái vận đơn",
        Cell: ({ row }) => (
          <Badge
            radius={"sm"}
            color={colorStatus(row.original.status)}
            bd={`1px solid ${colorStatus(row.original.status)}`}
            variant="light"
          >
            {getValueById(row.original.status, dataStatus, "label")}
          </Badge>
        ),
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      // {
      //   accessorKey: "fromExpectedDate",
      //   header: "Dự kiến giao từ",
      //   Cell: ({ renderedCellValue }: any) => (
      //     <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
      //   ),
      //   enableColumnFilter: false,
      //   enableColumnActions: false,
      //   enableSorting: false,
      // },
      // {
      //   accessorKey: "toExpectedDate",
      //   header: "Dự kiến giao đến",
      //   size: 30,
      //   Cell: ({ renderedCellValue }: any) => (
      //     <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
      //   ),
      //   enableColumnFilter: false,
      //   enableColumnActions: false,
      //   enableSorting: false,
      // },
      {
        accessorKey: "deliveryWorkName",
        header: "Công việc",
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "distance",
        header: "Khoảng cách",
        Cell: ({ row }) => (
          <Text size="12.5px" fw={500}>
            {row.original.distance / 1000} Km
          </Text>
        ),
        size: 30,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "employee1Name",
        header: "Nhân viên giao hàng",
        Cell: ({ row }) => <AvatarUtils value={row.original.employee1Name} />,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "employee2Name",
        header: "Nhân viên hỗ trợ",
        Cell: ({ row }) => <AvatarUtils value={row.original.employee2Name} />,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "empDriverName",
        header: "Nhân viên lái xe",
        Cell: ({ row }) => <AvatarUtils value={row.original.empDriverName} />,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "vehicleCode",
        header: "Phương tiện giao hàng",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.original.vehicleCode === "XE_MAY" ? "Xe máy" : "Ô tô"}
          </Text>
        ),
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        size: 200,
      },
      {
        accessorKey: "assignmentDate",
        header: "Ngày phân công",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 50,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editAssignment(
                    row.original.id,
                    row.original.tblDeliveryModels[0]?.branchId
                  );
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailAssignment(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Hủy phân công">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                disabled={!row.original.canDelete}
                onClick={async () => {
                  await cancelAssignment([row.original.id]);
                }}
              >
                <IconX size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataTblBranchSelect, dataStatus]
  );

  const colorStatus = (value: string) => {
    switch (value) {
      case "CHUA_GIAO":
        return "red";
      case "DANG_GIAO":
        return "yellow";
      case "HOAN_THANH":
        return "green";
    }
  };

  const optionColorStatus: Record<
    "Chưa giao" | "Đang giao" | "Hoàn thành",
    string
  > = {
    "Chưa giao": "red",
    "Đang giao": "yellow",
    "Hoàn thành": "green",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorStatus[
            option.label as "Chưa giao" | "Đang giao" | "Hoàn thành"
          ]
        }
        radius="sm"
        variant="light"
        bd={`1px solid ${
          optionColorStatus[
            option.label as "Chưa giao" | "Đang giao" | "Hoàn thành"
          ]
        }`}
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-don-giao-hang.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const editAssignment = async (id: string | number, branchId: number) => {
    try {
      modals.openConfirmModal({
        title: (
          <>
            <Title order={5}>Chỉnh sửa phân công giao vận!</Title>
          </>
        ),
        size: "auto",
        children: (
          <EditAssignment
            id={id}
            onClose={setDeleteViewStatus}
            branchId={branchId}
          />
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const detailAssignment = async (id: string | number) => {
    try {
      modals.openConfirmModal({
        title: (
          <>
            <Title order={5}>Chi tiết phân công!</Title>
          </>
        ),
        size: "auto",
        children: (
          <DetailDeliveryAssignment id={id} onClose={setDeleteViewStatus} />
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
      });
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const cancelAssignment = async (ids: (string | number)[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Hủy phân công!</Title>
            </>
          ),
          size: "550px",
          children: (
            <CancelAssignmentView idItems={ids} onClose={setDeleteViewStatus} />
          ),
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const dataLocationSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataTblBranchSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setEmployeeData(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataDeliveryStatusSelect = async () => {
    try {
      const getData = await getDeliveryStatusSelectMdm();
      const filteredData = getData.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblDeliveryStatusSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const dataDeliveryWorkSelect = async () => {
    const get = await getDeliveryWorkSelect();
    setDataWorkSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.employeeId) {
      url += `&EmployeeId=${search.employeeId}`;
    }

    if (search.vehicleCode) {
      url += `&VehicleCode=${search.vehicleCode}`;
    }

    if (search.deliveryWorkId) {
      url += `&DeliveryWorkId=${search.deliveryWorkId}`;
    }

    if (search.status) {
      url += `&Status=${search.status}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblDeliveryAssignment/get-list?${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        Promise.all([
          dataLocationSelect(),
          dataEmployeeSelect(),
          dataDeliveryStatusSelect(),
          dataDeliveryWorkSelect(),
        ]);
        setRowCount(response.length);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (170 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length !== 0) fetchData();
  }, [pagination, deleteViewStatus]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify={{ sm: "flex-end", lg: "flex-end" }}
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={
                <IconX style={{ width: rem(14), height: rem(14) }} />
              }
              disabled={selectIds.length < 1}
              onClick={() => cancelAssignment(selectIds)}
              color="red"
            >
              Hủy phân công
            </Button>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Flex gap={"md"} align={"center"} wrap={"wrap"} mt={10}>
          <Grid gutter={"xs"} w={"100%"}>
            <Grid.Col span={{ base: 12, md: 12, lg: 2.4 }}>
              <DatePickerInput
                type="range"
                size="sm"
                placeholder="Từ ngày - Đến ngày"
                locale="vi"
                valueFormat="DD/MM/YYYY"
                onChange={(e) => {
                  handleChangeSearchValue(
                    formatDateNotTimeZone(e[0]) ?? "",
                    "startDate"
                  );
                  handleChangeSearchValue(
                    formatDateNotTimeZone(e[1]) ?? "",
                    "endDate"
                  );
                }}
                leftSection={<IconCalendar color="#15aabf" />}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 2.4 }}>
              <Select
                placeholder="Trạng thái vận đơn"
                data={dataStatus}
                leftSection={<IconStatusChange color="#15aabf" />}
                searchable
                clearable
                nothingFoundMessage="Không tìm thấy dữ liệu !"
                renderOption={renderSelectOptionStatus}
                onChange={(e) => handleChangeSearchValue(e ?? "", "status")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 2.4 }}>
              <Select
                placeholder="Nhân viên"
                data={employeeData}
                leftSection={<IconUserCode color="#15aabf" />}
                searchable
                clearable
                onChange={(e) => {
                  handleChangeSearchValue(e ?? "", "employeeId");
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 2.4 }}>
              <Select
                placeholder="Phương tiện giao hàng"
                data={[
                  { label: "Xe máy", value: "XE_MAY" },
                  { label: "Ô tô", value: "O_TO" },
                ]}
                clearable
                leftSection={<IconTruckDelivery color="#15aabf" />}
                onChange={(e) => {
                  handleChangeSearchValue(e ?? "", "vehicleCode");
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 2.4 }}>
              <Select
                placeholder="Công việc"
                data={dataWorkSelect}
                clearable
                leftSection={<IconBriefcase2 color="#15aabf" />}
                onChange={(e) => {
                  handleChangeSearchValue(e ?? "", "deliveryWorkId");
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 2.4 }}>
              <TextInput
                placeholder="Nhập từ khóa"
                leftSection={<IconSearch color="#15aabf" />}
                defaultValue={keySearch ?? ""}
                onChange={(e) => {
                  setKeySearch(e.currentTarget.value);
                }}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    await fetchData();
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 1.25 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={async () => {
                  await fetchData();
                }}
                w={"100%"}
              >
                Tìm kiếm
              </Button>
            </Grid.Col>
          </Grid>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    renderDetailPanel: ({ row }) =>
      row.original.warrantyServiceRequestFormModels === null ? (
        <DeliveryList
          dataDetail={row.original?.tblDeliveryModels}
          isSetHeight={false}
        />
      ) : (
        <DeliveryWarranty
          dataDetail={row.original?.warrantyServiceRequestFormModels}
        />
      ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      sorting: sorting,
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
  });

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default DeliveryDispatch;
