import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  ComboboxItem,
  Flex,
  Group,
  Menu,
  NumberInput,
  Select,
  Table,
  Text,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
import {
  getHotkeyHandler,
  useClickOutside,
  useDebouncedState,
  useHotkeys,
} from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCheck,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import { _localizationSales } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDmInventorySelectByBranch } from "../../../model/TblDMInventory";
import { itemSearchTrasferModel } from "../../../model/TblDMItem";
import { TblDmEmployeeModel } from "../../../model/TblDmEmployeeModel";
import {
  tblDmRequestTransferDetailCommands,
  tblRequestTransfer,
} from "../../../model/TblRequestTransfer";
import {
  getBranchSelect,
  getTblInventoryTransactionType3,
  getSerialStatusSelect,
} from "../../../service/getSelectApi";
import { RequestTransferStatus } from "../requestTransferStatus";
import "./tab.component.css";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { hasLength, useForm } from "@mantine/form";
import { getValueById } from "../../../_base/helper/FunctionHelper";

interface TableSelectProps {
  dataProducts: itemSearchTrasferModel[];
  onSelectProduct: (product: itemSearchTrasferModel) => void;
  rowIndex: any;
}

interface TableSelectCustomerProps {
  dataCustomer: TblDmEmployeeModel[];
  onSelectCustomer: (customer: TblDmEmployeeModel) => void;
  rowIndex: any;
  // onEditingRowSave: MRT_TableOptions<dataOrderDetailTable>["onEditingRowSave"];
  selectEmployee: number;
}

export interface dataOrderDetailTable
  extends tblDmRequestTransferDetailCommands {
  itemName: string;
  requestByName: string;
  itemCode: string;
  tonao?: number;
  virtualQuantity?: number;
  actualQuantity?: number;
  fromInvName: string | null;
  poPrice: number;
}

const EditRequestTransfer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;
  const type = location.state && location.state.type;

  const mainRequestTransfer = () => {
    const tbHeader = (
      <Table.Tr>
        <Table.Th style={{ width: "25%" }}></Table.Th>
        <Table.Th style={{ width: "25%" }}>
          <Center>Yêu cầu xuất kho</Center>{" "}
        </Table.Th>
        <Table.Th style={{ width: "25%" }}>
          <Center>Bên xuất</Center>{" "}
        </Table.Th>
        <Table.Th style={{ width: "25%" }}>
          <Center>Bên nhận</Center>
        </Table.Th>
      </Table.Tr>
    );

    const tbBody = (
      <>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian tạo
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="xs">
              {formatDateTransfer(dataRequestTransfer?.createDate)}
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td
            rowSpan={dataRequestTransfer.type !== "XUAT_TRA_NCC" ? 1 : 10}
          >
            {dataRequestTransfer.type === "XUAT_TRA_NCC" ? (
              <>
                <TextInput
                  label="Mã nhà cung cấp"
                  placeholder="Mã nhà cung cấp"
                  value={dataRequestTransfer.attribute2 ?? ""}
                  size="xs"
                  readOnly
                  variant="filled"
                  mt={-60}
                />
                <Select
                  label="Tên nhà cung cấp"
                  placeholder="Nhập tên nhà cung cấp"
                  data={dataVendorSelect}
                  value={
                    form.getValues().attribute2
                      ? form.getValues().attribute2
                      : null
                  }
                  searchable
                  clearable
                  nothingFoundMessage="Không tìm thấy dữ liệu !"
                  size="xs"
                  mt={30}
                  {...form.getInputProps("attribute2")}
                  onChange={(e) => {
                    handleChangeValueInput(e, "attribute2");
                    handleChangeValueInput(
                      getValueById(e ?? "", dataVendorSelect, "label"),
                      "attribute6"
                    );
                    handleChangeValueInput(
                      Number(getValueById(e ?? "", dataVendorId, "label")),
                      "attribute11"
                    );
                    form.setValues((prev) => ({
                      ...prev,
                      attribute2: e ? e?.toString() : null,
                      attribute6: e
                        ? getValueById(e, dataVendorSelect, "label")
                        : null,
                      attribute11: e
                        ? Number(getValueById(e, dataVendorId, "label"))
                        : null,
                    }));
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Thời gian hoàn thành
            </Text>
          </Table.Td>
          <Table.Td>
            {dataRequestTransfer.finishDate && (
              <Text size="xs">
                {formatDateTransfer(
                  new Date(dataRequestTransfer.finishDate ?? "")
                )}
              </Text>
            )}
          </Table.Td>
          <Table.Td></Table.Td>
          {dataRequestTransfer.type !== "XUAT_TRA_NCC" ? (
            <Table.Td></Table.Td>
          ) : (
            <></>
          )}
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Mã yêu cầu xuất kho
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="xs">{dataRequestTransfer.code}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
          {dataRequestTransfer.type !== "XUAT_TRA_NCC" ? (
            <Table.Td></Table.Td>
          ) : (
            <></>
          )}
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chi nhánh
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>
            <Select
              searchable
              clearable
              disabled={listAdd.length > 0}
              size="xs"
              placeholder="Chọn chi nhánh"
              withAsterisk
              nothingFoundMessage={"Không có dữ liệu"}
              data={
                dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO" &&
                dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO_SERIAL"
                  ? dataBranch?.filter(
                      (item: any) =>
                        item.value !== form.getValues().toBranchId?.toString()
                    )
                  : dataBranch
              }
              comboboxProps={{ width: 200 }}
              value={dataRequestTransfer.fromBranchId?.toString() || null}
              {...form.getInputProps("fromBranchId")}
              onChange={(value, option) => {
                handleChangeValueInput(Number(value) || "", "fromBranchId");
                handleChangeValueInput("", "fromInvId");
                form.setValues((prev) => ({
                  ...prev,
                  fromBranchId: Number(value),
                }));
                if (
                  dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO" ||
                  dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO_SERIAL"
                ) {
                  handleChangeValueInput(value || "", "toBranchId");
                  form.setValues((prev) => ({
                    ...prev,
                    toBranchId: Number(value),
                    toEmpId: null,
                  }));
                }
              }}
            />
          </Table.Td>
          {dataRequestTransfer.type !== "XUAT_TRA_NCC" ? (
            <Table.Td>
              <Select
                searchable
                clearable
                size="xs"
                placeholder="Chọn chi nhánh"
                withAsterisk
                nothingFoundMessage={"Không có dữ liệu"}
                data={
                  dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO" &&
                  dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO_SERIAL"
                    ? dataBranch?.filter(
                        (item: any) =>
                          item.value !==
                          form.getValues().fromBranchId?.toString()
                      )
                    : dataBranch
                }
                disabled={
                  dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO" ||
                  dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO_SERIAL"
                }
                comboboxProps={{ width: 200 }}
                value={dataRequestTransfer.toBranchId?.toString() || null}
                {...form.getInputProps("toBranchId")}
                onChange={(value, option) => {
                  handleChangeValueInput(Number(value) || "", "toBranchId");
                  handleChangeValueInput("", "toInvId");
                  handleChangeValueInput(null, "toEmpId");
                  form.setValues((prev) => ({
                    ...prev,
                    toBranchId: Number(value),
                    toEmpId: null,
                  }));
                  if (
                    dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO" ||
                    dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO_SERIAL"
                  ) {
                    handleChangeValueInput(value || "", "fromBranchId");
                    form.setValues((prev) => ({
                      ...prev,
                      fromBranchId: Number(value),
                      toEmpId: null,
                    }));
                  }
                }}
              />
            </Table.Td>
          ) : (
            <></>
          )}
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              ĐĐ làm việc
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>
            <Text size="xs">
              {detailFromBranch !== null ? detailFromBranch : ""}
            </Text>
          </Table.Td>
          {dataRequestTransfer.type !== "XUAT_TRA_NCC" ? (
            <Table.Td>
              <Text size="xs">
                {detailToBranch !== null ? detailToBranch : ""}
              </Text>
            </Table.Td>
          ) : (
            <></>
          )}
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Kho
            </Text>
          </Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>
            <Select
              searchable
              clearable
              size="xs"
              placeholder="Chọn kho"
              nothingFoundMessage={"Không có dữ liệu"}
              disabled={!dataRequestTransfer.fromBranchId || listAdd.length > 0}
              data={
                dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO" ||
                dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO_SERIAL"
                  ? dataInventory?.formInv?.filter(
                      (item: any) =>
                        item.value !== dataRequestTransfer.toInvId?.toString()
                    )
                  : dataInventory?.formInv
              }
              comboboxProps={{ width: 200 }}
              value={dataRequestTransfer.fromInvId?.toString() || null}
              {...form.getInputProps("fromInvId")}
              onChange={(value, option) => {
                handleChangeValueInput(Number(value) || "", "fromInvId");
                form.setValues((prev) => ({
                  ...prev,
                  fromInvId: Number(value),
                }));
                if (
                  dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO" &&
                  dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO_SERIAL"
                ) {
                  const label = getValueById(
                    value?.toString() ?? "",
                    dataInventory?.formInv,
                    "label"
                  );

                  const underscoreIndex = label?.indexOf("_");

                  const extractedValue =
                    underscoreIndex !== -1 && label.length > underscoreIndex + 2
                      ? label.substring(
                          underscoreIndex + 1,
                          underscoreIndex + 3
                        )
                      : "";

                  setTypeInv(extractedValue);
                  handleChangeValueInput(null, "attribute7");
                } else {
                  const label = getValueById(
                    value?.toString() ?? "",
                    dataInventory?.formInv,
                    "label"
                  );
                  setTypeInv("");
                  handleChangeValueInput(
                    label?.match(/\((.*?)\)/)?.[1] ?? "",
                    "attribute7"
                  );
                }
              }}
            />
          </Table.Td>
          {dataRequestTransfer.type !== "XUAT_TRA_NCC" ? (
            <Table.Td>
              {dataRequestTransfer.type !== "XUAT_HUY" &&
              dataRequestTransfer.type !== "XUAT_DIEU_CHINH" ? (
                <Select
                  searchable
                  clearable
                  size="xs"
                  placeholder="Chọn kho"
                  withAsterisk
                  nothingFoundMessage={"Không có dữ liệu"}
                  disabled={
                    !dataRequestTransfer.toBranchId ||
                    (dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO" &&
                      dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO_SERIAL")
                  }
                  data={
                    dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO" ||
                    dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO_SERIAL"
                      ? dataInventory?.toInv?.filter(
                          (item: any) =>
                            item.value !==
                            dataRequestTransfer.fromInvId?.toString()
                        )
                      : dataInventory?.toInv
                  }
                  value={dataRequestTransfer.toInvId?.toString() || null}
                  comboboxProps={{ width: 200 }}
                  {...form.getInputProps("toInvId")}
                  onChange={(value, option) => {
                    handleChangeValueInput(Number(value) || "", "toInvId");
                    form.setValues((prev) => ({
                      ...prev,
                      toInvId: Number(value),
                    }));
                    if (
                      dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO" ||
                      dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO_SERIAL"
                    ) {
                      const label = getValueById(
                        value?.toString() ?? "",
                        dataInventory?.toInv,
                        "label"
                      );
                      handleChangeValueInput(
                        label?.match(/\((.*?)\)/)?.[1] ?? "",
                        "attribute8"
                      );
                    } else {
                      handleChangeValueInput(null, "attribute8");
                    }
                  }}
                />
              ) : (
                <></>
              )}
            </Table.Td>
          ) : (
            <></>
          )}
        </Table.Tr>
        {/* {dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO" ||
        dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO_SERIAL" ? (
          <Table.Tr>
            <Table.Td>
              <Text fw={700} size="sm">
                Vị trí
              </Text>
            </Table.Td>
            <Table.Td></Table.Td>
            <Table.Td>
              <Select
                placeholder="Chọn vị trí bên xuất"
                data={dataItemPosition?.formItemPosition}
                value={
                  form.getValues().attribute4
                    ? form.getValues().attribute4?.toString()
                    : null
                }
                disabled={!dataRequestTransfer.attribute7}
                withAsterisk
                nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                {...form.getInputProps("attribute4")}
                onChange={(e) => {
                  handleChangeValueInput(e || "", "attribute4");
                  form.setValues((prev) => ({
                    ...prev,
                    attribute4: e ? e?.toString() : null,
                  }));
                }}
                size="xs"
              />
            </Table.Td>
            <Table.Td>
              <Select
                placeholder="Chọn vị trí bên nhận"
                data={dataItemPosition?.toItemPosition}
                value={
                  form.getValues().attribute5
                    ? form.getValues().attribute5?.toString()
                    : null
                }
                disabled={!dataRequestTransfer.attribute8}
                withAsterisk
                nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                {...form.getInputProps("attribute5")}
                onChange={(e) => {
                  handleChangeValueInput(e || "", "attribute5");
                  form.setValues((prev) => ({
                    ...prev,
                    attribute5: e ? e?.toString() : null,
                  }));
                }}
                size="xs"
              />
            </Table.Td>
          </Table.Tr>
        ) : (
          <></>
        )} */}
        {dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO" ||
        dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH" ? (
          <Table.Tr>
            <Table.Td>
              <Text fw={700} size="sm">
                Tình trạng
              </Text>
            </Table.Td>
            <Table.Td>
              <Select
                placeholder="Chọn tình trạng"
                size="xs"
                data={[
                  { label: "Tất cả", value: "TAT_CA" },
                  { label: "Mới 100%", value: "MOI" },
                  { label: "Đã qua sử dụng", value: "DA_QUA_SU_DUNG" },
                ]}
                value={
                  dataRequestTransfer.attribute3
                    ? dataRequestTransfer.attribute3
                    : "TAT_CA"
                }
                onChange={(e) => handleChangeValueInput(e, "attribute3")}
              />
            </Table.Td>
            <Table.Td></Table.Td>
            <Table.Td></Table.Td>
          </Table.Tr>
        ) : (
          <></>
        )}
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Họ và tên
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="xs">{dataRequestTransfer.createByName}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
          {dataRequestTransfer.type !== "XUAT_TRA_NCC" ? (
            <Table.Td>
              {dataRequestTransfer.type === "XUAT_HUY" ||
              dataRequestTransfer.type === "XUAT_DIEU_CHINH" ? (
                <Select
                  placeholder="Nhập tên nhân viên bên nhận"
                  data={dataEmpToBranch}
                  value={
                    form.getValues().toEmpId
                      ? form.getValues().toEmpId?.toString()
                      : null
                  }
                  size="xs"
                  searchable
                  clearable
                  disabled={
                    !dataRequestTransfer.toBranchId ||
                    dataRequestTransfer.toBranchId === 0
                  }
                  {...form.getInputProps("toEmpId")}
                  onChange={(e) => {
                    form.setValues((prev) => ({
                      ...prev,
                      toEmpId: e ? Number(e) : null,
                    }));
                    handleChangeValueInput(e ? Number(e) : null, "toEmpId");
                  }}
                  nothingFoundMessage={
                    dataRequestTransfer.toBranchId &&
                    dataRequestTransfer.toBranchId !== 0
                      ? "Không tìm thấy dữ liệu !"
                      : "Vui lòng chọn chi nhánh bên nhận trước !"
                  }
                />
              ) : (
                <></>
              )}
            </Table.Td>
          ) : (
            <></>
          )}
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Chức danh
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="xs">{dataRequestTransfer.empRoleName}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
          {dataRequestTransfer.type !== "XUAT_TRA_NCC" ? (
            <Table.Td>
              {dataRequestTransfer.type === "XUAT_HUY" ||
              dataRequestTransfer.type === "XUAT_DIEU_CHINH" ? (
                <Text size="xs">
                  {getValueById(
                    dataRequestTransfer.toEmpId?.toString() ?? "",
                    dataDepAndRole,
                    "roleName"
                  )}
                </Text>
              ) : (
                <></>
              )}
            </Table.Td>
          ) : (
            <></>
          )}
        </Table.Tr>
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Bộ phận
            </Text>
          </Table.Td>
          <Table.Td>
            <Text size="xs">{dataRequestTransfer.empDepartmentName}</Text>
          </Table.Td>
          <Table.Td></Table.Td>
          {dataRequestTransfer.type !== "XUAT_TRA_NCC" ? (
            <Table.Td>
              {dataRequestTransfer.type === "XUAT_HUY" ||
              dataRequestTransfer.type === "XUAT_DIEU_CHINH" ? (
                <Text size="xs">
                  {getValueById(
                    dataRequestTransfer.toEmpId?.toString() ?? "",
                    dataDepAndRole,
                    "departmentName"
                  )}
                </Text>
              ) : (
                <></>
              )}
            </Table.Td>
          ) : (
            <></>
          )}
        </Table.Tr>
        {dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH" ||
        dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH_SERIAL" ||
        dataRequestTransfer.type === "XUAT_TRA_NCC" ? (
          <Table.Tr>
            <Table.Td>
              <Text fw={700} size="sm">
                Giao hàng
              </Text>
            </Table.Td>
            <Table.Td>
              <Select
                placeholder="Nhập phương thức giao hàng"
                size="xs"
                data={[
                  { label: "Không", value: "KHONG" },
                  { label: "Nội bộ", value: "NOI_BO" },
                  { label: "GHTK", value: "GHTK" },
                  { label: "Nhất tín", value: "NHAT_TIN" },
                  { label: "SCA", value: "SCA" },
                  { label: "Tín thành", value: "TIN_THANH" },
                  { label: "Viettel", value: "VIETTEL" },
                  { label: "VN Post", value: "VN_POST" },
                ]}
                value={
                  dataRequestTransfer.attribute1
                    ? dataRequestTransfer.attribute1?.toString()
                    : "KHONG"
                }
                {...form.getInputProps("attribute1")}
                onChange={(e) => {
                  handleChangeValueInput(e?.toString() ?? "", "attribute1");
                  form.setValues((prev) => ({ ...prev, attribute1: e }));
                }}
              />
            </Table.Td>
            <Table.Td></Table.Td>
            {dataRequestTransfer.type !== "XUAT_TRA_NCC" ? (
              <Table.Td></Table.Td>
            ) : (
              <></>
            )}
          </Table.Tr>
        ) : (
          <></>
        )}
        <Table.Tr>
          <Table.Td>
            <Text fw={700} size="sm">
              Ghi chú
            </Text>
          </Table.Td>
          <Table.Td colSpan={3}>
            <Textarea
              placeholder="Vui lòng nhập"
              size="xs"
              rows={6}
              value={dataRequestTransfer.note}
              {...form.getInputProps("note")}
              onChange={(e) => {
                handleChangeValueInput(e.currentTarget.value, "note");
                form.setValues((prev) => ({
                  ...prev,
                  note: e.currentTarget.value,
                }));
              }}
            />
          </Table.Td>
          {/* {dataRequestTransfer.type !== "XUAT_HUY" ? (
            <Table.Td></Table.Td>
          ) : (
            <></>
          )} */}
        </Table.Tr>
      </>
    );

    return (
      <Box
        component="form"
        onSubmit={form.onSubmit(() => {
          handleEditRequestTransfer(true);
        })}
      >
        <Group m={"16px 16px 0px 16px"} justify="start">
          <Text c={"red"} fw={700}>
            Loại phiếu
          </Text>
          <Select
            disabled
            clearable
            size="xs"
            placeholder="Chọn loại"
            // data={dataTblInventoryTransactionType}
            data={[
              {
                label: "Điều chuyển chi nhánh",
                value: "DIEU_CHUYEN_CHI_NHANH",
              },
              {
                label: "Điều chuyển chi nhánh serial",
                value: "DIEU_CHUYEN_CHI_NHANH_SERIAL",
              },
              {
                label: "Điều chuyển nội bộ",
                value: "DIEU_CHUYEN_NOI_BO",
              },
              {
                label: "Điều chuyển nội bộ serial",
                value: "DIEU_CHUYEN_NOI_BO_SERIAL",
              },
              {
                label: "Xuất kho hủy",
                value: "XUAT_HUY",
              },
              {
                label: "Xuất kho điều chỉnh",
                value: "XUAT_DIEU_CHINH",
              },
              {
                label: "Xuất trả nhà cung cấp",
                value: "XUAT_TRA_NCC",
              },
            ]}
            value={dataRequestTransfer?.type}
            onChange={(value) => {
              handleChangeValueInput(value || "", "type");
            }}
            w={200}
          />
        </Group>
        <Table
          striped
          horizontalSpacing="sm"
          withColumnBorders
          withTableBorder
          mt={5}
        >
          <Table.Thead>{tbHeader}</Table.Thead>
          <Table.Tbody>{tbBody}</Table.Tbody>
        </Table>
      </Box>
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const [dataRequestTransfer, setDataRequestTransfer] =
    useState<tblRequestTransfer>({
      id: 0,
      code: "",
      note: "",
      deliveryPriorityLevel: "",
      deliveryTime: "",
      deliveryDepartmentId: 0,
      deliveryPersonId: 0,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      approve: false,
      branchId: 0,
      fromBranchId: 0,
      fromBranchName: null,
      fromInvId: 0,
      fromInvName: null,
      toBranchId: 0,
      toBranchName: null,
      toInvId: 0,
      toInvName: null,
      statusCode: null,
      statusid: null,
      requestDate: null,
      finishDate: null,
      createDate: null,
      createBy: null,
      lastUpdatedDate: null,
      updatedBy: null,
      addedDate: null,
      active: null,
      type: null,
      empRoleId: null,
      empRoleName: null,
      empDepartmentId: null,
      empDepartmentName: null,
      fromEmpId: null,
      fromDepartmentId: null,
      fromRoleId: null,
      toEmpId: null,
      toDepartmentId: null,
      toRoleId: null,
      deliveryMethodCode: null,
      totalAmount: null,
      totalQuantity: null,
      tblDmRequestTransferDetailCommands: [],
    });
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);

  //table state
  const [detailFromBranch, setDetailFromBranch] = useState<string | null>(null);
  const [detailToBranch, setDetailToBranch] = useState<string | null>(null);
  const [listAdd, setListAdd] = useState<any[]>([]);
  const [isExceedQuantity, setIsExceedQuantity] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Set<any>>(new Set());
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataProducts, setDataProducts] = useState<itemSearchTrasferModel[]>(
    []
  );
  const [dataCustomer, setDataCustomer] = useState<TblDmEmployeeModel[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    requestBy: 0,
    requestByName: "",
  });
  const [dataTblInventoryTransactionType, setDataTblInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataInventory, setDataInventory] = useState<{
    formInv?: ComboboxItem[];
    toInv?: ComboboxItem[];
  }>();
  const [dataItemPosition, setDataitemPosition] = useState<{
    formItemPosition?: ComboboxItem[];
    toItemPosition?: ComboboxItem[];
  }>();
  const [dataBranch, setDataBranch] = useState<ComboboxItem[]>([]);
  const [dataSerialStatus, setDataSerialStatus] = useState<ComboboxItem[]>([]);

  const [dataCustomerByKey, setDataCustomerByKey] = useState<
    TblDmEmployeeModel[]
  >([]);
  const [dataEmpToBranch, setDataEmpToBranch] = useState<ComboboxItem[]>([]);
  const [dataDepAndRole, setDataDepAndRole] = useState<any[]>([]);
  const [dataVendorSelect, setDataVendorSelect] = useState<ComboboxItem[]>([]);
  const [dataVendorId, setDataVendorId] = useState<any[]>([]);
  const [finish, setFinish] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [typeInv, setTypeInv] = useState("");

  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [opened, setOpened] = useState(true);

  const form = useForm<tblRequestTransfer>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...dataRequestTransfer,
    },

    validate: {
      fromBranchId: (value: number | null, values) => {
        if (!value) {
          return "Vui lòng chọn chi nhánh !";
        }

        if (
          value === values.toBranchId &&
          values.type !== "DIEU_CHUYEN_NOI_BO" &&
          values.type !== "DIEU_CHUYEN_NOI_BO_SERIAL"
        ) {
          return "Vui lòng chọn hai chi nhánh khác nhau!";
        }
      },
      fromInvId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn kho !";
        }
      },
      toBranchId: (value: number | null, values) => {
        if (!value && values.type !== "XUAT_TRA_NCC") {
          return "Vui lòng chọn chi nhánh !";
        }

        if (
          value === values.fromBranchId &&
          values.type !== "DIEU_CHUYEN_NOI_BO" &&
          values.type !== "DIEU_CHUYEN_NOI_BO_SERIAL"
        ) {
          return "Vui lòng chọn hai chi nhánh khác nhau!";
        }
      },
      toInvId: (value: number | null, values) => {
        if (
          !value &&
          values.type !== "XUAT_HUY" &&
          values.type !== "XUAT_DIEU_CHINH" &&
          values.type !== "XUAT_TRA_NCC"
        ) {
          return "Vui lòng chọn kho !";
        }
      },
      attribute2: (value: string | null | undefined, values) => {
        if (!value && values.type === "XUAT_TRA_NCC") {
          return "Vui lòng chọn nhà cung cấp !";
        }
      },
      // attribute4: (value: string | null | undefined, values) => {
      //   if (
      //     !value &&
      //     (values.type === "DIEU_CHUYEN_NOI_BO" ||
      //       values.type === "DIEU_CHUYEN_NOI_BO_SERIAL")
      //   ) {
      //     return "Vui lòng chọn vị trí trong kho !";
      //   }
      // },
      // attribute5: (value: string | null | undefined, values) => {
      //   if (
      //     !value &&
      //     (values.type === "DIEU_CHUYEN_NOI_BO" ||
      //       values.type === "DIEU_CHUYEN_NOI_BO_SERIAL")
      //   ) {
      //     return "Vui lòng chọn vị trí trong kho !";
      //   }
      // },
      note: (value: string | null) => {
        if (value) {
          return hasLength(
            { max: 300 },
            "Ghi chú không được vượt quá 300 kí tự"
          )(value as string);
        }
      },
    },
  });

  const titleSell = () => {
    return (
      <Box
        component="form"
        onSubmit={form.onSubmit(() => {
          if (finish) {
            setTimeout(() => {
              handleEditRequestTransfer(true);
            }, 100);
          } else {
            setTimeout(() => {
              handleEditRequestTransfer(false);
            }, 100);
          }
        })}
      >
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
            mb={"xs"}
          >
            <RequestTransferStatus
              status={dataRequestTransfer.statusCode?.toString() || ""}
            />
          </Flex>

          <Flex gap={10} justify={"flex-end"}>
            <Button
              my={"10px"}
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              size="xs"
              onClick={() => handleReturnRequestTransfer()}
            >
              Quay lại
            </Button>
            <Button
              my={"10px"}
              leftSection={<IconPlus size={14} />}
              disabled={
                isExceedQuantity ||
                disabledButton ||
                dataRequestTransfer.type === null
              }
              color="blue"
              size="xs"
              onClick={() => setFinish(false)}
              type="submit"
            >
              Lưu (Crtl + S)
            </Button>
            <Button
              my={"10px"}
              leftSection={<IconCheck size={14} />}
              disabled={
                isExceedQuantity ||
                disabledButton ||
                dataRequestTransfer.type === null
              }
              color="green"
              size="xs"
              type="submit"
              onClick={() => setFinish(true)}
            >
              Hoàn thành (Crtl + Q)
            </Button>
          </Flex>
        </Group>
      </Box>
    );
  };

  //table state
  const [valueDebounced, setValueDebounced] = useDebouncedState("", 500);
  const [valueDebouncedCustomer, setValueDebouncedCustomer] = useDebouncedState(
    "",
    500
  );

  const getDataEmpToBranch = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${dataRequestTransfer.toBranchId}&Take=200`
    );

    if (res && res?.success) {
      const dataApi = res.data;
      setDataEmpToBranch(
        dataApi
          .filter((item: any) => item.fullname != null && item.id != null)
          .map((item: any) => ({
            value: item.id?.toString(),
            label: item.fullname ?? "",
          }))
      );
      setDataDepAndRole(
        dataApi.map((item: any) => ({
          id: item.id?.toString(),
          departmentId: item.departmentId,
          departmentName: item.departmentName,
          roleId: item.roleId,
          roleName: item.roleName,
        }))
      );
    }
  };

  const getDataVendor = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblVendor/get-all`
    );

    if (res && res?.success) {
      const dataApi = res.data;
      const uniqueVendorCodes = new Set();
      const filteredData = dataApi
        .filter(
          (item: any) => item.vendorName != null && item.vendorCode != null
        )
        .filter((item: any) => {
          const vendorCode = item.vendorCode?.split(" - ")[0];
          if (uniqueVendorCodes.has(vendorCode)) {
            return false;
          } else {
            uniqueVendorCodes.add(vendorCode);
            return true;
          }
        })
        .map((item: any) => ({
          value: item.vendorCode?.split(" - ")[0],
          label: item.vendorName ?? "",
        }));
      const filteredDataId = dataApi
        .filter((item: any) => item.id != null && item.vendorCode != null)
        .map((item: any) => ({
          value: item.vendorCode?.split(" - ")[0],
          label: item.id?.toString() ?? "",
        }));

      setDataVendorId(filteredDataId);
      setDataVendorSelect(filteredData);
    }
  };

  const fetchItemSell = async () => {
    const keySearch = valueDebounced.toLowerCase();
    const url = `/api/v1/RequestInventoryTransaction/search-item-transaction-request?Key=${keySearch}&Page=30&InvId=${dataRequestTransfer?.fromInvId}&Type=${dataRequestTransfer?.type}`;
    try {
      if (dataRequestTransfer.fromInvId) {
        const searchResult = await repositoryPos.get<
          MessageResponse<itemSearchTrasferModel[]>
        >(url);
        if (searchResult?.data) {
          setDataProducts(searchResult.data);
          // table.setCreatingRow(null);
        } else {
          setDataProducts([]);
        }
      } else {
        setDataProducts([]);
        table.setCreatingRow(null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataProducts([]);
    }
  };

  const fetchDataEditRequestTransfer = async (type: string) => {
    const response = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblRequestTransfer/${type}?id=${id}`
    );

    if (response?.success && response.data) {
      setDataRequestTransfer(response.data);
      setListAdd(response.data?.tblRequestTransferDetailModels);
      form.setValues(response.data);
      await Promise.all([dataInventoryTransactionType()]);
    }
  };

  const fetchDataSerialStatus = async () => {
    const getDataSerialStatus = await getSerialStatusSelect();

    const mappedData = getDataSerialStatus
      .filter(
        (item: any) =>
          item.value != null && item.value !== "-1" && item.text != null
      )
      .map((item: any) => ({
        value: item.text.toString(),
        label: `${item.text}`,
      }));
    setDataSerialStatus(mappedData);
  };

  const fetchDataBranch = async () => {
    const getDataBranch = await getBranchSelect();

    const mappedData = getDataBranch
      .filter(
        (item) => item.value != null && item.text != null && item.value !== "-1"
      )
      .map((item) => ({
        value: item.value,
        label: `${item.text}`,
      }));
    setDataBranch(mappedData);
  };

  const getDetailBranch = async (idBranch: number, typeBranch: string) => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmInventory/get-detail-branch?id=${idBranch}`
    );

    if (res && res?.success) {
      const result = res.data;
      if (typeBranch === "Bên xuất") {
        setDetailFromBranch(result?.road);
      } else {
        setDetailToBranch(result?.road);
      }
    }
  };

  const dataInventoryTransactionType = async () => {
    try {
      const getData = await getTblInventoryTransactionType3();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblInventoryTransactionType(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const fetchdataInventory = async (idInvOrg: string | number) => {
    const url = `/api/v1/TblDmInventory/get-select-by-branch?branchId=${idInvOrg}`;
    try {
      const getDataInventory = await repositoryPos.get<
        MessageResponse<TblDmInventorySelectByBranch[]>
      >(url);
      if (getDataInventory?.data) {
        const filteredData = getDataInventory?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.inventoryId.toString(),
          label: `(${item.inventoryCode}) ${item.inventoryName}`,
        }));
        return mappedData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchDataItemPosition = async (codeInv: string) => {
    const url = `/api/v1/TblDmItemPosition/get-select?SubInvCode=${codeInv}`;
    try {
      const getDataItemPos = await repositoryDelivery.get<MessageResponse<any>>(
        url
      );
      if (getDataItemPos?.data) {
        const filteredData = getDataItemPos?.data;
        const mappedData = filteredData?.map((item: any) => ({
          value: item.id?.toString(),
          label: item.positionName?.toString(),
        }));
        return mappedData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const handleEditRequestTransfer = async (isFinish: boolean) => {
    try {
      let url = "";
      if (
        type === "DIEU_CHUYEN_CHI_NHANH" ||
        type === "DIEU_CHUYEN_CHI_NHANH_SERIAL"
      ) {
        url = `update-request-transfer?IsFinish=${isFinish}`;
      } else if (type === "XUAT_HUY") {
        url = `update-request-cancellation?IsFinish=${isFinish}`;
      } else if (
        type === "DIEU_CHUYEN_NOI_BO" ||
        type === "DIEU_CHUYEN_NOI_BO_SERIAL"
      ) {
        url = `update-request-internal-transfer?IsFinish=${isFinish}`;
      } else if (type === "XUAT_DIEU_CHINH") {
        url = `update-request-adjustment?IsFinish=${isFinish}`;
      } else if (type === "XUAT_TRA_NCC") {
        url = `update-request-return-vendor?IsFinish=${isFinish}`;
      }
      const { tblRequestTransferDetailModels, ...restData } =
        dataRequestTransfer;
      const dataCreate = {
        ...restData,
        tblDmRequestTransferDetailCommands: listAdd || [],
      };

      const response = await repositoryMdm.post<MessageResponse<any>>(
        `/api/v1/TblRequestTransfer/${url}`,
        dataCreate
      );

      if (response && response?.success) {
        setDisabledButton(true);
        NotificationExtension.Success("Chỉnh sửa phiếu chuyển kho thành công");
        setTimeout(() => {
          navigate("/warehouse-config/request-transfer");
        }, 1000);
      } else {
        NotificationExtension.Fails("Chỉnh sửa thất bại !");
      }
    } catch (error) {}
  };

  const handleChangeValueInput = (
    value: string | boolean | null | number,
    key: string
  ) => {
    setDataRequestTransfer((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleSelectProduct = async (
    product: itemSearchTrasferModel,
    rowIndex: number
  ) => {
    const addData: dataOrderDetailTable = {
      id: 0,
      itemName: product.itemName || "",
      fromBranchId: dataRequestTransfer.fromBranchId || 0,
      fromInvId: dataRequestTransfer.fromInvId || 0,
      fromInvName: product.invName || "",
      itemId: product.itemId,
      itemCode: product.itemCode || "",
      requestId: 0,
      requestBy: selectedCustomer.requestBy || 0,
      toBranchId: dataRequestTransfer.toBranchId || 0,
      toInvId: dataRequestTransfer.toInvId || 0,
      primaryQuantity: 1,
      primaryUom: product.primaryUomCode || "",
      requestByName: selectedCustomer.requestByName || "",
      virtualQuantity: product.virtualQuantity || 0,
      actualQuantity: product.actualQuantity || 0,
      poPrice: product?.poPrice || 0,
      serial: product?.serial || "",
      status:
        dataRequestTransfer.type === "XUAT_HUY" ? product.status || "" : "",
    };

    const handleApiCallAndUpdate = async () => {
      setListAdd((prevList) => {
        const updatedList = [...prevList];

        const isProductExist = updatedList.some(
          (item, index) => index === rowIndex
        );

        if (
          dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH" ||
          dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO"
        ) {
          setSelectedProducts((prevSelectedProducts) => {
            const newSelectedProducts = new Set(prevSelectedProducts);
            newSelectedProducts.delete(prevList[rowIndex]?.itemId);
            newSelectedProducts.add(product.itemId);
            return newSelectedProducts;
          });
        } else {
          setSelectedProducts((prevSelectedProducts) => {
            const newSelectedProducts = new Set(prevSelectedProducts);
            newSelectedProducts.delete(prevList[rowIndex]?.serial);
            newSelectedProducts.add(product.serial);
            return newSelectedProducts;
          });
        }

        if (isProductExist) {
          updatedList[rowIndex] = addData;
        } else {
          updatedList.unshift(addData);
        }

        return updatedList;
      });
    };

    if (listAdd.length > 0) {
      // if (selectedCustomer.requestBy) {
      await handleApiCallAndUpdate();
      // } else {
      //   NotificationExtension.Warn("Bạn chưa chọn nhân viên yêu cầu");
      // }
    } else {
      await handleApiCallAndUpdate();
    }

    table.setEditingRow(null);
    table.setEditingCell(null);
  };

  const handleReturnRequestTransfer = () => {
    navigate("/warehouse-config/request-transfer");
  };

  const handleSaveRow: MRT_TableOptions<dataOrderDetailTable>["onEditingRowSave"] =
    async ({ table, row, values }) => {
      const newListAdd = {
        ...values,
      };
      listAdd[row.index] = { ...listAdd[row.index], ...newListAdd };

      //send/receive api updates here
      setListAdd([...listAdd]);
      table.setEditingRow(null); //exit editing mode
    };

  const TableSelect: React.FC<TableSelectProps> = ({
    dataProducts,
    onSelectProduct,
    rowIndex,
  }: any) => {
    return (
      <Table.ScrollContainer mah={300} minWidth={300} type="native">
        <Table striped withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Chọn</Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th>Mã sản phẩm</Table.Th>
              <Table.Th>Tên sản phẩm</Table.Th>
              <Table.Th>Đơn vị</Table.Th>
              {dataRequestTransfer.type === "XUAT_HUY" ||
              dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO_SERIAL" ||
              dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH_SERIAL" ||
              dataRequestTransfer.type === "XUAT_DIEU_CHINH" ||
              dataRequestTransfer.type === "XUAT_TRA_NCC" ? (
                <>
                  <Table.Th>Serial</Table.Th>
                  <Table.Th>Trạng thái</Table.Th>
                </>
              ) : (
                <>
                  <Table.Th>Tồn thật</Table.Th>
                  <Table.Th>Sẵn hàng</Table.Th>
                </>
              )}
              <Table.Th>Mã kho</Table.Th>
              <Table.Th>Kho</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {dataProducts?.length > 0 ? (
            <Table.Tbody>
              {dataProducts?.map((product: any, index: any) => (
                <Table.Tr key={product.itemId + index}>
                  <Table.Td>
                    <Button
                      size="xs"
                      variant="filled"
                      onClick={() => {
                        onSelectProduct(product, rowIndex);
                      }}
                      disabled={
                        dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH" ||
                        dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO"
                          ? selectedProducts.has(product.itemId)
                          : selectedProducts.has(product.serial)
                      }
                    >
                      {dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH" ||
                      dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO"
                        ? selectedProducts.has(product.itemId)
                          ? "Đã chọn"
                          : "Chọn"
                        : selectedProducts.has(product.serial)
                        ? "Đã chọn"
                        : "Chọn"}
                    </Button>
                  </Table.Td>
                  <Table.Td>{product.itemId}</Table.Td>
                  <Table.Td>{product.itemCode}</Table.Td>
                  <Table.Td>{product.itemName}</Table.Td>
                  <Table.Td>{product.primaryUomCode}</Table.Td>
                  {dataRequestTransfer.type === "XUAT_HUY" ||
                  dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO_SERIAL" ||
                  dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH_SERIAL" ||
                  dataRequestTransfer.type === "XUAT_DIEU_CHINH" ||
                  dataRequestTransfer.type === "XUAT_TRA_NCC" ? (
                    <>
                      <Table.Td>{product.serial}</Table.Td>
                      <Table.Td>{product.status}</Table.Td>
                    </>
                  ) : (
                    <>
                      <Table.Td>{product.actualQuantity}</Table.Td>
                      <Table.Td>{product.virtualQuantity}</Table.Td>
                    </>
                  )}
                  <Table.Td>{product.invCode}</Table.Td>
                  <Table.Td>{product.invName}</Table.Td>
                  {/* <Table.Td>{product.oldvirua}</Table.Td>
                <Table.Td>{product.oldton}</Table.Td> */}
                </Table.Tr>
              ))}
            </Table.Tbody>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text>Hãy nhập ID hoặc mã sản phẩm!</Text>
            </Box>
          )}
        </Table>
      </Table.ScrollContainer>
    );
  };

  const TableSelectCustomer: React.FC<TableSelectCustomerProps> = ({
    dataCustomer,
    onSelectCustomer,
    rowIndex,
    selectEmployee,
  }: // onEditingRowSave,
  any) => {
    return (
      <Table.ScrollContainer mah={300} minWidth={200} type="native">
        <Table striped withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Mã nhân viên</Table.Th>
              <Table.Th>Tên nhân viên</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {dataCustomer?.length > 0 ? (
            <Table.Tbody>
              {dataCustomer?.map((customer: any, index: any) => (
                <Table.Tr key={customer.id + index}>
                  <Table.Td>
                    <Button
                      size="xs"
                      variant="filled"
                      disabled={selectEmployee === customer.id}
                      onClick={() => {
                        onSelectCustomer(customer, rowIndex);
                        table.setEditingRow(null);
                      }}
                    >
                      {selectEmployee === customer.id ? "Đã chọn" : "Chọn"}
                    </Button>
                  </Table.Td>
                  <Table.Td>{customer.fullname}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text>Hãy nhập tên hoặc mã nhân viên!</Text>
            </Box>
          )}
        </Table>
      </Table.ScrollContainer>
    );
  };

  const columns = React.useMemo<MRT_ColumnDef<dataOrderDetailTable>[]>(
    () => [
      {
        header: "STT",
        size: 30,
        enableEditing: false,
        Cell: ({ row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
        enableEditing: false,
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        enableEditing: true,
        size: 100,
        Edit: ({ cell, row, table, column }) => {
          const [keepRef, setKeepRef] = useState(false);
          const ref = useClickOutside(() => {
            if (!keepRef) {
              table.setEditingCell(null);
            }
          });

          return (
            <div ref={ref}>
              <Menu
                opened={opened}
                onChange={setOpened}
                shadow="md"
                width={"800"}
                position="bottom-start"
              >
                <Menu.Target>
                  <TextInput
                    autoFocus
                    onChange={async (e) => {
                      const _key = e.target.value ?? null;
                      setValueDebounced(_key);
                    }}
                    onFocus={() => setKeepRef(true)}
                    onBlur={() => setKeepRef(false)}
                    onKeyDown={getHotkeyHandler([
                      [
                        "Enter",
                        () => {
                          if (dataProducts.length === 1) {
                            handleSelectProduct(dataProducts[0], row.index);
                            setValueDebounced("");
                            table.setCreatingRow(null);
                          } else {
                            return;
                          }
                        },
                      ],
                    ])}
                  />
                </Menu.Target>
                <Menu.Dropdown>
                  <TableSelect
                    dataProducts={dataProducts}
                    onSelectProduct={(product) =>
                      handleSelectProduct(product, row.index)
                    }
                    rowIndex={row.index}
                  />
                </Menu.Dropdown>
              </Menu>
            </div>
          );
        },
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableEditing: false,
        Cell: ({ renderedCellValue }) => {
          return renderedCellValue ? (
            <Tooltip label={renderedCellValue}>
              <Text size="11px" truncate="end" h={12} w={280}>
                {renderedCellValue}
              </Text>
            </Tooltip>
          ) : (
            <></>
          );
        },
      },
      {
        accessorKey: "primaryUom",
        header: "DVT1",
        size: 100,
        Edit: ({ row, column, table }) => {
          return (
            <TextInput
              // autoFocus
              placeholder="Nhập đơn vị"
              // max={row.original.tonao}
              min={1}
              value={row._valuesCache[column.id]}
              onChange={(e) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? {
                          ...item,
                          primaryUom: e.target.value,
                        }
                      : item
                  );
                });
              }}
              onBlur={(e) => {
                table.setEditingCell(null);
              }}
            />
          );
        },
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        enableEditing:
          dataRequestTransfer.type !== "DIEU_CHUYEN_CHI_NHANH_SERIAL" &&
          dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO_SERIAL" &&
          dataRequestTransfer.type !== "XUAT_HUY" &&
          dataRequestTransfer.type !== "XUAT_DIEU_CHINH" &&
          dataRequestTransfer.type !== "XUAT_TRA_NCC",
        size: 100,
        Edit: ({ row, column, table }) => {
          return (
            <NumberInput
              color={
                row._valuesCache[column.id] >
                (row.original.virtualQuantity || 0)
                  ? "red"
                  : "green"
              }
              // autoFocus
              placeholder="Nhập số lượng"
              hideControls
              // max={row.original.tonao}
              value={row._valuesCache[column.id]}
              onChange={(e) => {
                setListAdd((prevData: any) => {
                  return prevData.map((item: any, i: any) =>
                    i === row.index
                      ? {
                          ...item,
                          primaryQuantity:
                            e === undefined || e === null || e === ""
                              ? null
                              : e <= item.virtualQuantity
                              ? Number(e)
                              : (NotificationExtension.Fails(
                                  "Số lượng sản phẩm yêu cầu vượt quá số lượng sẵn hàng !"
                                ),
                                1),
                        }
                      : item
                  );
                });
              }}
              onBlur={(e) => {
                table.setEditingCell(null);
                if (
                  Number(row.original.primaryQuantity) < 1 ||
                  Number(row.original.primaryQuantity) === null
                ) {
                  setListAdd((prevData: any) => {
                    return prevData.map((item: any, i: any) =>
                      i === row.index
                        ? {
                            ...item,
                            primaryQuantity: 1,
                          }
                        : item
                    );
                  });
                } else {
                  setDisabledButton(false);
                }
              }}
            />
          );
        },
      },
      {
        accessorKey: "subUom",
        header: "DVT2",
        size: 100,
        Edit: ({ row, column, table }) => {
          return (
            <TextInput
              // autoFocus
              placeholder="Nhập đơn vị"
              // max={row.original.tonao}
              min={1}
              value={row._valuesCache[column.id]}
              onChange={(e) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? {
                          ...item,
                          subUom: e.target.value,
                        }
                      : item
                  );
                });
              }}
              onBlur={(e) => {
                table.setEditingCell(null);
              }}
            />
          );
        },
      },
      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 100,
        Edit: ({ row, column, table }) => {
          return (
            <NumberInput
              // autoFocus
              placeholder="Nhập số lượng"
              hideControls
              // max={row.original.tonao}
              value={row._valuesCache[column.id]}
              onChange={(e) => {
                setListAdd((prevData: any) => {
                  return prevData.map((item: any, i: any) =>
                    i === row.index
                      ? {
                          ...item,
                          subQuantity: e ? Number(e) : null,
                        }
                      : item
                  );
                });
              }}
              onBlur={(e) => {
                table.setEditingCell(null);
              }}
            />
          );
        },
      },
      {
        accessorKey: "poPrice",
        header: "Thành tiền",
        size: 200,
        enableEditing: false,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 200,
        enableEditing: false,
        // Edit: ({ row, column, table }) => {
        //   return (
        //     <Select
        //       // autoFocus
        //       placeholder="Chọn tình trạng"
        //       data={dataSerialStatus}
        //       value={row._valuesCache[column.id]?.toString() || ""}
        //       onChange={(e) => {
        //         setListAdd((prevData) => {
        //           return prevData.map((item, i) =>
        //             i === row.index
        //               ? {
        //                   ...item,
        //                   status: e?.toString(),
        //                 }
        //               : item
        //           );
        //         });
        //       }}
        //       onBlur={(e) => {
        //         table.setEditingCell(null);
        //       }}
        //     />
        //   );
        // },
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 100,
        Edit: ({ row, column, table }) => {
          return (
            <TextInput
              // autoFocus
              placeholder="Nhập ghi chú"
              // max={row.original.tonao}
              min={1}
              value={row._valuesCache[column.id]}
              onChange={(e) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? {
                          ...item,
                          note: e.target.value,
                        }
                      : item
                  );
                });
              }}
              onBlur={(e) => {
                table.setEditingCell(null);
              }}
            />
          );
        },
      },
    ],
    [
      listAdd,
      dataProducts,
      selectedProducts,
      dataCustomerByKey,
      dataRequestTransfer,
    ]
  );

  const getColumnVisibility = (type: string) => {
    if (type === "DIEU_CHUYEN_CHI_NHANH" || type === "DIEU_CHUYEN_NOI_BO") {
      return {
        serial: false,
        poPrice: false,
      };
    } else if (
      type === "XUAT_HUY" ||
      type === "DIEU_CHUYEN_NOI_BO_SERIAL" ||
      type === "DIEU_CHUYEN_CHI_NHANH_SERIAL" ||
      type === "XUAT_DIEU_CHINH"
    ) {
      return {
        serial: true,
        poPrice: true,
      };
    }
    return {
      serial: true,
      poPrice: true,
    };
  };

  const openDeleteConfirmModal = (row: MRT_Row<dataOrderDetailTable>) => {
    return modals.openConfirmModal({
      title: "Bạn có chắc chắn xóa sản phẩm này?",
      children: (
        <Text size="lg" mt={15}>
          Hành động này có thể được hoàn tác khi được thêm mới.
        </Text>
      ),
      labels: { confirm: "Xóa", cancel: "Thoát" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        const data = listAdd.filter((value) => {
          if (
            dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH" ||
            dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO"
          ) {
            return value.itemId !== row.original.itemId;
          } else {
            return value.serial !== row.original.serial;
          }
        });
        setListAdd(data);
        if (
          dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH" ||
          dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO"
        ) {
          setSelectedProducts((prevSelectedProducts) => {
            const newSelectedProducts = new Set(prevSelectedProducts);
            newSelectedProducts.delete(row.original.itemId);
            return newSelectedProducts;
          });
        } else {
          setSelectedProducts((prevSelectedProducts) => {
            const newSelectedProducts = new Set(prevSelectedProducts);
            newSelectedProducts.delete(row.original.serial);
            return newSelectedProducts;
          });
        }
      },
    });
  };

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (dataRequestTransfer.type === "XUAT_TRA_NCC") {
      getDataVendor();
    } else {
      setDataVendorSelect([]);
      setDataVendorId([]);
    }
  }, [dataRequestTransfer.type]);

  useEffect(() => {
    if (dataRequestTransfer.toEmpId && dataRequestTransfer.toEmpId !== 0) {
      handleChangeValueInput(
        getValueById(
          dataRequestTransfer.toEmpId?.toString() ?? "",
          dataDepAndRole,
          "departmentId"
        ),
        "toDepartmentId"
      );
      handleChangeValueInput(
        getValueById(
          dataRequestTransfer.toEmpId?.toString() ?? "",
          dataDepAndRole,
          "roleId"
        ),
        "toRoleId"
      );
    } else {
      handleChangeValueInput(null, "toDepartmentId");
      handleChangeValueInput(null, "toRoleId");
    }
  }, [dataRequestTransfer.toEmpId]);

  useEffect(() => {
    if (
      dataRequestTransfer.toBranchId &&
      dataRequestTransfer.toBranchId !== 0
    ) {
      getDataEmpToBranch();
    } else {
      setDataEmpToBranch([]);
      setDataDepAndRole([]);
    }
  }, [dataRequestTransfer.toBranchId]);

  useEffect(() => {
    if (
      dataRequestTransfer.fromBranchId &&
      dataRequestTransfer.fromBranchId !== null
    ) {
      getDetailBranch(dataRequestTransfer.fromBranchId, "Bên xuất");
    } else {
      setDetailFromBranch(null);
    }
  }, [dataRequestTransfer.fromBranchId]);

  useEffect(() => {
    if (
      dataRequestTransfer.toBranchId &&
      dataRequestTransfer.toBranchId !== null
    ) {
      getDetailBranch(dataRequestTransfer.toBranchId, "Bên nhập");
    } else {
      setDetailToBranch(null);
    }
  }, [dataRequestTransfer.toBranchId]);

  useEffect(() => {
    if (dataRequestTransfer.fromInvId || valueDebounced.length > 2) {
      fetchItemSell();
    } else {
      table.setCreatingRow(null);
    }
  }, [dataRequestTransfer?.fromInvId, valueDebounced]);

  useEffect(() => {
    const searchCustomer = (query: string) => {
      return dataCustomer.filter((customer) =>
        customer.fullname.toLowerCase().includes(query.toLowerCase())
      );
    };

    if (valueDebouncedCustomer && valueDebouncedCustomer.length > 1) {
      const results = searchCustomer(valueDebouncedCustomer);
      setDataCustomerByKey(results);
    } else {
      setDataCustomerByKey([]);
    }
  }, [valueDebouncedCustomer]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([fetchDataBranch(), fetchDataSerialStatus()]);

        if (
          type === "DIEU_CHUYEN_CHI_NHANH" ||
          type === "DIEU_CHUYEN_CHI_NHANH_SERIAL"
        ) {
          fetchDataEditRequestTransfer("update-request-transfer");
        } else if (type === "XUAT_HUY") {
          fetchDataEditRequestTransfer("update-request-cancellation");
        } else if (
          type === "DIEU_CHUYEN_NOI_BO" ||
          type === "DIEU_CHUYEN_NOI_BO_SERIAL"
        ) {
          fetchDataEditRequestTransfer("update-request-internal-transfer");
        } else if (type === "XUAT_DIEU_CHINH") {
          fetchDataEditRequestTransfer("update-request-adjustment");
        } else if (type === "XUAT_TRA_NCC") {
          fetchDataEditRequestTransfer("update-request-return-vendor");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    const fectDataInv = async () => {
      if (dataRequestTransfer.toBranchId) {
        const toInv = await fetchdataInventory(dataRequestTransfer.toBranchId);
        setDataInventory((prev) => ({ ...prev, toInv: toInv }));
      } else {
        setDataInventory((prev) => ({ ...prev, toInv: [] }));
      }
      if (dataRequestTransfer.fromBranchId) {
        const formInv = await fetchdataInventory(
          dataRequestTransfer.fromBranchId
        );
        setDataInventory((prev) => ({ ...prev, formInv: formInv }));
      } else {
        setDataInventory((prev) => ({ ...prev, formInv: [] }));
      }
    };
    fectDataInv();
  }, [dataRequestTransfer.toBranchId, dataRequestTransfer.fromBranchId]);

  useEffect(() => {
    const updateItemId = () => {
      if (
        dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH" ||
        dataRequestTransfer.type === "DIEU_CHUYEN_NOI_BO"
      ) {
        const uniqueItemId = new Set(
          listAdd.map((item) => item.itemId).filter((itemId) => itemId !== null)
        );
        setSelectedProducts(uniqueItemId);
      } else {
        const uniqueItemId = new Set(
          listAdd.map((item) => item.serial).filter((serial) => serial !== null)
        );
        setSelectedProducts(uniqueItemId);
      }
    };

    updateItemId();
  }, [listAdd]);

  useEffect(() => {
    if (dataRequestTransfer && dataRequestTransfer.deliveryPriorityLevel) {
      let deliveryTime = "";

      switch (dataRequestTransfer.deliveryPriorityLevel) {
        case "Giao hàng trong ngày":
          deliveryTime = dayjs().add(1, "day").format("YYYY-MM-DD[T]HH:mm:ss");
          break;
        case "Giao hàng nhanh 2h":
          deliveryTime = dayjs(new Date())
            .add(2, "hours")
            .format("YYYY-MM-DD[T]HH:mm:ss");
          break;
        case "Giao hàng tiêu chuẩn 3h":
          deliveryTime = dayjs(new Date())
            .add(3, "hours")
            .format("YYYY-MM-DD[T]HH:mm:ss");
          break;
        case "Khác":
          deliveryTime = dayjs().format("YYYY-MM-DD[T]HH:mm:ss");
          break;
        default:
          break;
      }

      setDataRequestTransfer((prevData) => ({
        ...prevData,
        deliveryTime: deliveryTime,
      }));
    }
  }, [dataRequestTransfer.deliveryPriorityLevel]);

  useEffect(() => {
    setDataRequestTransfer((prev) => ({
      ...prev,
      totalQuantity:
        listAdd && listAdd.length > 0
          ? listAdd.reduce((sum, item) => sum + item.primaryQuantity, 0)
          : 0,
      totalAmount:
        listAdd && listAdd.length > 0
          ? listAdd.reduce(
              (sum, item) => sum + item?.poPrice * item?.primaryQuantity,
              0
            )
          : 0,
    }));
  }, [listAdd]);

  useEffect(() => {
    const invalidItems = listAdd.filter(
      (item) =>
        item.actualQuantity !== undefined &&
        item.actualQuantity !== null &&
        item.primaryQuantity > (item?.virtualQuantity ?? 0)
    );

    if (
      invalidItems.length > 0 &&
      dataRequestTransfer.type !== "XUAT_HUY" &&
      dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO_SERIAL" &&
      dataRequestTransfer.type !== "DIEU_CHUYEN_CHI_NHANH_SERIAL" &&
      dataRequestTransfer.type !== "XUAT_DIEU_CHINH" &&
      dataRequestTransfer.type !== "XUAT_TRA_NCC"
    ) {
      setIsExceedQuantity(true);
      NotificationExtension.Fails(
        <>
          <Text>Số lượng sản phẩm yêu cầu vượt quá số lượng sẵn hàng.</Text>
          {invalidItems.map((item) => (
            <Text>
              Sản phẩm {item.itemCode} - {item.itemName}
            </Text>
          ))}
        </>
        // `Số lượng sản phẩm yêu cầu vượt quá số lượng tồn kho. Sản phẩm ${invalidItems[0].itemCode} - ${invalidItems[0].itemName}`
      );
    } else {
      setIsExceedQuantity(false);
    }
  }, [listAdd]);

  useEffect(() => {
    const fetchDataItemPos = async () => {
      if (dataRequestTransfer.attribute7) {
        const fromItemPos = await fetchDataItemPosition(
          dataRequestTransfer.attribute7
        );
        setDataitemPosition((prev) => ({
          ...prev,
          formItemPosition: fromItemPos,
        }));
      } else {
        setDataitemPosition((prev) => ({
          ...prev,
          formItemPosition: [],
        }));
      }

      if (dataRequestTransfer.attribute8) {
        const toItemPos = await fetchDataItemPosition(
          dataRequestTransfer.attribute8
        );
        setDataitemPosition((prev) => ({
          ...prev,
          toItemPosition: toItemPos,
        }));
      } else {
        setDataitemPosition((prev) => ({
          ...prev,
          toItemPosition: [],
        }));
      }
    };

    fetchDataItemPos();
  }, [dataRequestTransfer.attribute7, dataRequestTransfer.attribute8]);

  useEffect(() => {
    if (
      typeInv.length > 0 &&
      dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO" &&
      dataRequestTransfer.type !== "DIEU_CHUYEN_NOI_BO_SERIAL"
    ) {
      if (dataInventory?.toInv && Array.isArray(dataInventory.toInv)) {
        const foundItem = dataInventory.toInv.find((item) =>
          item.label.includes(typeInv)
        );
        if (foundItem) {
          handleChangeValueInput(foundItem.value || "", "toInvId");
          form.setValues((prev) => ({
            ...prev,
            toInvId: Number(foundItem.value),
          }));
        } else {
          handleChangeValueInput(null, "toInvId");
          form.setValues((prev) => ({
            ...prev,
            toInvId: 0,
          }));
        }
      }
    }
  }, [typeInv, dataInventory]);

  useEffect(() => {
    if (
      dataRequestTransfer.toBranchId !== null &&
      dataRequestTransfer.toBranchId !== 0 &&
      dataRequestTransfer.fromInvId !== null &&
      dataRequestTransfer.fromInvId !== 0 &&
      dataInventory?.toInv?.length === 0 &&
      (dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH" ||
        dataRequestTransfer.type === "DIEU_CHUYEN_CHI_NHANH_SERIAL")
    ) {
      NotificationExtension.Fails("Không tìm thấy kho tương ứng bên xuất !");
    }
  }, [
    dataRequestTransfer.fromInvId,
    dataRequestTransfer.toBranchId,
    dataInventory?.toInv,
  ]);

  useEffect(() => {
    const updatedState1 = listAdd.map((item1) => {
      const matchingItem = dataProducts.find(
        (item2) => item2.itemId === item1.itemId
      );

      return matchingItem
        ? { ...item1, virtualQuantity: matchingItem.virtualQuantity }
        : item1;
    });

    setListAdd(updatedState1);
  }, [dataProducts]);

  useHotkeys([
    [
      "Enter",
      () => {
        if (dataProducts.length === 1) {
          handleSelectProduct(dataProducts[0], rowIndex);
          setValueDebounced("");
          table.setCreatingRow(null);
          setRowIndex((prevIndex) => prevIndex + 1);
        } else {
          return;
        }
      },
    ],
    [
      "Ctrl + S",
      () => {
        handleEditRequestTransfer(false);
      },
    ],
    [
      "Ctrl + Q",
      () => {
        handleEditRequestTransfer(true);
      },
    ],
  ]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (75 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable<dataOrderDetailTable>({
    columns,
    data: listAdd,
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: true,
    enableRowActions: true,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableBatchRowSelection: true,
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    positionActionsColumn: "last",

    renderRowActions: ({ row, table }) => (
      <Flex gap="4">
        {/* <Tooltip label="Thao tác">
          <ActionIcon
            variant="outline"
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <IconEdit />
          </ActionIcon>
        </Tooltip> */}
        <Tooltip label="Xoá sản phẩm">
          <ActionIcon variant="outline" color="red">
            <IconTrash onClick={() => openDeleteConfirmModal(row)} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),

    renderTopToolbar: () => (
      <Flex
        gap="md"
        justify={{ sm: "flex-start" }}
        wrap="wrap"
        align="center"
        direction="row"
        m={"16px 16px 0px 16px"}
      >
        <Button
          leftSection={<IconPlus size={14} />}
          variant="outline"
          color="blue"
          size="xs"
          onClick={() => {
            setOpened(true);
            if (
              dataRequestTransfer.type === "XUAT_HUY" ||
              dataRequestTransfer.type === "XUAT_DIEU_CHINH"
            ) {
              if (
                dataRequestTransfer.fromBranchId &&
                dataRequestTransfer.fromBranchId !== 0 &&
                dataRequestTransfer.fromInvId &&
                dataRequestTransfer.fromInvId !== 0 &&
                dataRequestTransfer.toBranchId &&
                dataRequestTransfer.toBranchId !== 0
              ) {
                table.setCreatingRow(true);
              } else {
                NotificationExtension.Fails(
                  "Vui lòng chọn đầy đủ thông tin kho và chi nhánh bên xuất và bên nhận"
                );
              }
            } else if (dataRequestTransfer.type === "XUAT_TRA_NCC") {
              if (
                dataRequestTransfer.fromBranchId &&
                dataRequestTransfer.fromBranchId !== 0 &&
                dataRequestTransfer.fromInvId &&
                dataRequestTransfer.fromInvId !== 0
              ) {
                table.setCreatingRow(true);
              } else {
                NotificationExtension.Fails(
                  "Vui lòng chọn đầy đủ thông tin kho và chi nhánh bên xuất"
                );
              }
            } else {
              if (
                dataRequestTransfer.fromBranchId &&
                dataRequestTransfer.fromBranchId !== 0 &&
                dataRequestTransfer.fromInvId &&
                dataRequestTransfer.fromInvId !== 0 &&
                dataRequestTransfer.toBranchId &&
                dataRequestTransfer.toBranchId !== 0 &&
                dataRequestTransfer.toInvId &&
                dataRequestTransfer.toInvId !== 0
              ) {
                table.setCreatingRow(true);
              } else {
                NotificationExtension.Fails(
                  "Vui lòng chọn đầy đủ thông tin kho và chi nhánh bên xuất và bên nhận"
                );
              }
            }
          }}
        >
          Thêm sản phẩm (F11)
        </Button>
      </Flex>
    ),

    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    // localization: _localizationSales,
    getRowId: (row) => row?.itemId?.toString(),
    mantineTableContainerProps: {
      style: { maxHeight: height - 150, minHeight: height - 150 },
    },
    initialState: {
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: getColumnVisibility(dataRequestTransfer.type ?? ""),
      density: "xs",
    },
    onEditingRowSave: handleSaveRow,
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualSorting: false,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      onDoubleClick: () => {
        table.setCreatingRow(null);
      },
      onClick: () => setOpened(true),
      style: styleCellTable(row),
    }),
    enablePagination: false,
    enableBottomToolbar: false,

    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,

    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  React.useEffect(() => {
    const newColumnVisibility = getColumnVisibility(
      dataRequestTransfer.type ?? ""
    );
    if (table) {
      table.setColumnVisibility(newColumnVisibility);
    }
  }, [dataRequestTransfer.type, table]);

  React.useEffect(() => {
    if (!opened) {
      table.setCreatingRow(null);
    }
  }, [opened]);

  useHotkeys([
    ["mod+J", () => console.log("Toggle color scheme")],
    [
      "ctrl+F5",
      () => {
        //  openNew();
      },
    ],
    [
      "F5",
      () => {
        //  openNew();
      },
    ],
    ["alt+mod+shift+X", () => console.log("Rick roll")],
    [
      "F11",
      () => {
        // openNew(true);
      },
    ],
  ]);
  //#endregion
  return (
    <Box>
      <div ref={headerRef}>{titleSell()}</div>
      <PanelGroup direction="horizontal" id="group">
        <Panel defaultSize={35} minSize={25} maxSize={75}>
          <Card h={height} p={0} shadow="sm" radius="md" withBorder>
            {mainRequestTransfer()}
          </Card>
        </Panel>
        <PanelResizeHandle id="resize-handle" style={{ width: "5px" }} />
        <Panel defaultSize={65} minSize={25} maxSize={75}>
          <MantineReactTable table={table} />
          <Flex justify={"start"} gap={"xs"} h={100} mt={5}>
            <Flex
              direction={"column"}
              align={"center"}
              justify={"center"}
              bd={"1px solid #DEE2E6"}
              style={{ borderRadius: "10px" }}
              gap={"md"}
              w={150}
            >
              <Text size="14px" fw={"bold"}>
                Tổng số lượng
              </Text>
              <Text size="13px" fw={"bold"} c={"red"}>
                {listAdd && listAdd.length > 0
                  ? listAdd.reduce(
                      (sum, item) => sum + item?.primaryQuantity,
                      0
                    )
                  : 0}
              </Text>
            </Flex>
            <Flex
              direction={"column"}
              align={"center"}
              justify={"center"}
              bd={"1px solid #DEE2E6"}
              style={{ borderRadius: "10px" }}
              gap={"md"}
              w={150}
            >
              <Text size="14px" fw={"bold"}>
                Tổng thành tiền
              </Text>
              <Text size="13px" fw={"bold"} c={"red"}>
                {listAdd && listAdd.length > 0
                  ? listAdd
                      .reduce(
                        (sum, item) =>
                          sum + item?.poPrice * item?.primaryQuantity,
                        0
                      )
                      .toLocaleString()
                  : 0}{" "}
                đ
              </Text>
            </Flex>
          </Flex>
        </Panel>
      </PanelGroup>
    </Box>
  );
};
export default EditRequestTransfer;
