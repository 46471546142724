import { Button, LoadingOverlay, Text } from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";
import { useDisclosure } from "@mantine/hooks";
type Props = {
  serial: string;
  onSuccess: () => void;
};
const ChildrenModal = ({ serial, onSuccess }: Props) => {
  const [height, setHeight] = useState(0);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [visible, { toggle, open, close }] = useDisclosure(false);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [serialRaBo, setSerialRaBo] = useState("");
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblMfgProductOrder/get-child-serial?Serial=${serial}`
      );
      if (response && response.success) {
        setData(response.data);
        setSerialRaBo(response.data[0].parentSerial);
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      setIsLoading(false);
    } finally {
      setIsRefetching(false);
      setIsLoading(false);
    }
  };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "parentSerial",
        header: "Serial cha",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "uomCode",
        header: "Đơn vị tính",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      // {
      //   accessorKey: "quantity",
      //   header: "Số lượng",
      //   size: 100,
      //   Cell: ({ renderedCellValue }) => (
      //     <Text size="sm">{renderedCellValue}</Text>
      //   ),
      // },
    ],
    []
  );
  const table = useMantineReactTable({
    columns,
    data: data ? data : [],
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: true,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id?.toString(),

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "borrowCode"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableColumnActions: false,
    enableSorting: false,
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    rowCount,
    onPaginationChange: setPagination,
    renderToolbarInternalActions: () => <></>,
    renderTopToolbarCustomActions: ({ table }) => (
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          variant="outline"
          color="blue"
          size="sm"
          onClick={() => fetchDataRaBo(serialRaBo)}
        >
          Rã bộ
        </Button>
      </div>
    ),
    enableToolbarInternalActions: false,

    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: 500,
        fontSize: "12.5px",
        padding: "5px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  const fetchDataRaBo = async (serial: string) => {
    open();
    try {
      const response = await repositoryPos.get(
        `/api/v1/TblMfgProductOrder/item-split?serial=${serial}`
      );
      if (response && response.success) {
        NotificationExtension.Success("Rã bộ thành công");
        close();
        setTimeout(() => {
          onSuccess();
        }, 1000);
      } else {
        NotificationExtension.Fails("Rã bộ thất bại");
      }
    } catch (error) {
      NotificationExtension.Fails("Rã bộ thất bại");
      console.log("error", error);
    }
    close();
  };
  useEffect(() => {
    fetchData();
  }, [serial]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (300 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);
  return (
    <div>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <div className="mt-5" style={{ marginTop: "10px" }}>
          <MantineReactTable table={table} />
        </div>
      </div>
    </div>
  );
};

export default ChildrenModal;
