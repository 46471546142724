import { Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";

const DeleteView = ({ idItem, sourceType, onClose }: DeleteProduct) => {
  const handleDeleteBranch = async () => {
    const url = getOutwardApiUrl(sourceType);
    const res = await repositoryPos.post(url);
    if (res)
      if (res.success) {
        NotificationExtension.Success("Xóa thành công !");
        onClose((prev: any) => !prev);
      } else NotificationExtension.Fails("Xóa thất bại !");
    modals.closeAll();
  };

  const getOutwardApiUrl = (sourceType: string | null): string => {
    const _action = "delete";
    switch (sourceType) {
      case `NHAP_HANG_NCC`:
        return `/api/v1/Inward/${_action}-in-ward-po?Ids=${idItem}&SourceType=${sourceType}`;
      case `NHAP_DIEU_CHUYEN_CHI_NHANH`:
        return `/api/v1/Inward/${_action}-in-ward-request-transfer?Ids=${idItem}&SourceType=${sourceType}`;
      case `NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL`:
        return `/api/v1/Inward/${_action}-request-transfer-internal-inward-serial?Ids=${idItem}&SourceType=${sourceType}`;
      case `NHAP_DIEU_CHUYEN_NOI_BO`:
        return `/api/v1/Inward/${_action}-request-transfer-internal-inward?Ids=${idItem}&SourceType=${sourceType}`;
      case `NHAP_DIEU_CHUYEN_NOI_BO_SERIAL`:
        return `/api/v1/Inward/${_action}-request-transfer-internal-inward-serial?Ids=${idItem}&SourceType=${sourceType}`;
      case `NHAP_HANG_DOI_TRA`:
        return `/api/v1/Inward/${_action}-in-ward-return?Ids=${idItem}&SourceType=${sourceType}`;
      case `NHAP_THANH_PHAM_SAN_XUAT`:
        return `/api/v1/Inward/${_action}-in-ward-mfg-finished-goods?Ids=${idItem}&SourceType=${sourceType}`;
      case `NHAP_KHO_BAO_HANH`:
        return `/api/v1/Outward/${_action}-warranty-out-ward?Ids=${idItem}&SourceType=${sourceType}`;
      default:
        throw new Error("Unknown source type");
    }
  };

  return (
    <div>
      <Text size="20px" mt={5}>
        Bạn có chắc chắn muốn xóa phiếu xuất kho này?
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color="red"
          onClick={handleDeleteBranch}
          leftSection={<IconCheck size={18} />}
        >
          Xóa
        </Button>
      </Group>
    </div>
  );
};

type DeleteProduct = {
  idItem: number | string;
  onClose: any;
  sourceType: string;
};

export default DeleteView;
