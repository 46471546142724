import { useLocation, useNavigate } from "react-router-dom";
import classes from "../../_base/_style/NavbarLinksGroup.module.css";
import { repositoryMaster } from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { Box, Button, Flex, rem, ThemeIcon } from "@mantine/core";
import { IconArrowBack, IconFolders, IconX } from "@tabler/icons-react";
import { useEffect } from "react";

const MenuView = () => {
  const location = useLocation();
  const menuItem = location.state && location.state?.menuItem;
  const label = location.state && location.state?.label;
  const pathname = location?.pathname;
  const navigate = useNavigate();
  const formatLink = (link: any) => {
    return link.startsWith("/") ? link : `/${link}`;
  };
  const userName = localStorage.getItem("userName");
  useEffect(() => {
    if (!menuItem) {
      navigate(-1);
    }
  }, []);

  return (
    <>
      <Flex align={"center"}>
        <ThemeIcon variant="transparent" size={25}>
          <IconFolders style={{ width: rem(25), height: rem(25) }} />
        </ThemeIcon>
        <Box m="md" fw={"bold"}>
          {label}
        </Box>
      </Flex>
      {menuItem?.map((link: any) => (
        <div
          className={classes.link}
          key={link.label}
          onClick={async (event) => {
            event.preventDefault();
            if (userName === "hopnk1" || userName === "vinhnv")
              navigate(link.link);
            else {
              var resCheck = await repositoryMaster.get<
                MessageResponse<Array<string>>
              >("/api/v1/menugroup/check");
              if (resCheck && resCheck?.data && resCheck.success) {
                if (resCheck.data && resCheck.data.length > 0) {
                  //  console.log(resCheck.data.indexOf(link.link) >= 0);
                  if (
                    resCheck.data.findIndex((x) =>
                      link.link.toLowerCase().startsWith(x.toLowerCase())
                    ) >= 0
                  )
                    navigate(link.link);
                  else navigate("/");
                } else navigate("/");
              } else navigate("/");
            }
          }}
          //  data-active={activeLink === link.label || undefined}
          style={{
            color: formatLink(link.link) === pathname ? "#006bb8" : "black",
            backgroundColor:
              formatLink(link.link) === pathname
                ? "rgba(0,119,204,0.2)"
                : "initial",
          }}
        >
          {link.label}
        </div>
      ))}
      <Button
        mt={"md"}
        onClick={() => {
          navigate(-1);
        }}
        color={"red"}
        leftSection={<IconArrowBack size={20}></IconArrowBack>}
      >
        Quay lại
      </Button>
    </>
  );
};

export default MenuView;
