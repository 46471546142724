import { Box, Button, Divider, Flex, Table, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { IconCheck, IconX } from "@tabler/icons-react";

const ModalCheckSerial = ({
  index,
  sourceCode,
  branchId,
  subInvId,
  listSerial,
  handleInWard,
}: {
  index: number;
  sourceCode: string;
  branchId: number;
  subInvId: number;
  listSerial: any[];
  handleInWard: any;
}) => {
  const [dataScanSerial, setDataScanSerial] = useState<string[]>([]);
  const [checkSubmit, setCheckSubmit] = useState<boolean>(false);

  useEffect(() => {
    const serialsInState1 = listSerial
      ?.filter((item) => item.type === false)
      ?.map((item) => item.serial);

    const allMatch = dataScanSerial.every((serial) =>
      serialsInState1.includes(serial)
    );

    setCheckSubmit(allMatch);
  }, [listSerial, dataScanSerial]);

  return (
    <Box mt={10} w={"70vw"} maw={1000}>
      <Divider label="Sản phẩm xuất mượn" labelPosition="center" />
      <Table striped withTableBorder withColumnBorders mt={10}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Serial</Table.Th>
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {listSerial &&
            listSerial.length > 0 &&
            listSerial
              ?.filter((item) => item.type === false)
              ?.map((item, index) => (
                <Table.Tr key={index}>
                  <Table.Td>{item.serial}</Table.Td>
                  <Table.Td>{item.itemCode}</Table.Td>
                  <Table.Td>{item.itemName}</Table.Td>
                </Table.Tr>
              ))}
        </Table.Tbody>
      </Table>
      <Divider mt={10} label="Sản phẩm khách hàng trả" labelPosition="center" />
      <Table striped withTableBorder withColumnBorders mt={15}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Serial</Table.Th>
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {listSerial && listSerial.length > 0 ? (
            listSerial
              ?.filter((item) => item.type === false)
              ?.map((item, index) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    <TextInput
                      placeholder="Quét mã serial"
                      value={dataScanSerial[index]}
                      onChange={(e) => {
                        const newData = [...dataScanSerial];
                        newData[index] = e.currentTarget.value.toUpperCase();
                        setDataScanSerial(newData);
                      }}
                      rightSection={
                        dataScanSerial[index] ? (
                          dataScanSerial[index] === item.serial ? (
                            <IconCheck color="green" />
                          ) : (
                            <IconX color="red" />
                          )
                        ) : (
                          <></>
                        )
                      }
                    />
                  </Table.Td>
                  <Table.Td>{item.itemCode}</Table.Td>
                  <Table.Td>{item.itemName}</Table.Td>
                </Table.Tr>
              ))
          ) : (
            <Table.Tr>
              <Table.Td colSpan={4} ta={"center"}>
                Quét mã serial của sản phẩm để hiển thị
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
      <TextInput label="Ghi chú" placeholder="Nhập ghi chú" mt={5} />
      <Flex w={"100%"} justify={"end"} mt={20}>
        <Button
          color="teal"
          leftSection={<IconCheck size={"14px"} />}
          onClick={() =>
            handleInWard(index, sourceCode, branchId, subInvId, listSerial)
          }
          disabled={!checkSubmit || dataScanSerial.length === 0}
        >
          Xác nhận
        </Button>
      </Flex>
    </Box>
  );
};

export default ModalCheckSerial;
