import {
  Box,
  Button,
  Flex,
  ScrollArea,
  Table,
  Title,
  Text,
  Input,
  Checkbox,
  ComboboxItem,
  NumberInput,
  Tooltip,
} from "@mantine/core";
import { IconPlus, IconRecycle, IconTrash, IconX } from "@tabler/icons-react";
import { useState, useEffect, useCallback } from "react";
import cx from "clsx";
import classes from "../../Styles/ScrollTable.module.css";
import { modals } from "@mantine/modals";
import ModalSuperMarket from "../../Modals/SuperMarket";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import { UseFormReturnType } from "@mantine/form";
import {
  listBranch,
  tblPromotion,
  tblPromotionCommand,
} from "../../../../../../model/TblPromotion";
import { NavLink } from "react-router-dom";
import { red, teal } from "../../../../../../const/variables";
import { getMasterOfSelect } from "../../../../../../service/getSelectApi";
import React from "react";
import { useDebouncedCallback, useDebouncedState } from "@mantine/hooks";

const SuperMarket = ({
  form,
  _data,
}: {
  form: UseFormReturnType<
    tblPromotionCommand,
    (values: tblPromotionCommand) => tblPromotionCommand
  >;
  _data: listBranch[];
}) => {
  const [dataTable, setDataTable] = useState<listBranch[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [height, setHeight] = useState(0);
  const [value, setValue] = useDebouncedState("", 500);

  useEffect(() => {
    const getData = async () => {
      const _dataApi = await getMasterOfSelect();
      if (_dataApi) {
        const _dataTable = _dataApi
          .filter((item) => item.text && item.text !== "Tất cả")
          .map((item, index) => {
            return {
              id: Number.parseInt(item.value),
              code: item.value,
              branchName: item.text,
              orderQuantity: 0,
              check: true,
            } as listBranch;
          });
        if (_dataTable.length > 0) {
          const _api = _dataTable.map((item) => ({
            ...item,
            check: _data.find((x) => x.id === item.id)?.check,
            orderQuantity: _data.find((x) => x.id === item.id)?.orderQuantity??0
          }));

          setDataTable((prev) => {
            // Sắp xếp lại danh sách sau khi cập nhật
            const sortedData = [..._api].sort((a, b) => {
              if (a.check && !b.check) return -1;
              if (!a.check && b.check) return 1;
              return 0;
            });

            return sortedData;
          });
        }
      }
    };
    getData();
  }, [_data]);

  const handleSearch = useDebouncedCallback(() => {
    form.setFieldValue(
      "listBranch",
      dataTable.filter((item) => item.check === true)
    );
    console.log("update", dataTable);
  }, 500);

  useEffect(() => {
    handleSearch();
  }, [dataTable]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 450);
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const handleCheckboxChange = useCallback(
    (index: number, checked: boolean | undefined) => {
      setDataTable((prev) => {
        // Cập nhật giá trị `check` cho phần tử tương ứng
        const updatedData = prev.map((item, i) =>
          i === index ? { ...item, check: checked } : item
        );

        // Sắp xếp lại danh sách sau khi cập nhật
        const sortedData = [...updatedData].sort((a, b) => {
          if (a.check && !b.check) return -1;
          if (!a.check && b.check) return 1;
          return 0;
        });

        // Trả về danh sách đã sắp xếp
        return sortedData;
      });
    },
    []
  );
  const handleSelectAll = useCallback(() => {
    setSelectAll((prev) => !prev);
    setDataTable((prev) =>
      prev.map((item) => ({ ...item, check: !selectAll }))
    );
  }, [selectAll]);

  const handleReset = useCallback((index: number) => {
    setDataTable((prev) => {
      // Cập nhật giá trị orderQuantity và check của phần tử tương ứng
      const updatedData = prev.map((item, i) =>
        i === index ? { ...item, orderQuantity: 0, check: false } : item
      );

      // Sắp xếp lại danh sách sau khi cập nhật
      const sortedData = [...updatedData].sort((a, b) => {
        if (a.check && !b.check) return -1;
        if (!a.check && b.check) return 1;
        return 0;
      });

      return sortedData;
    });
  }, []);

  const handleUpdateQuantity = useCallback((index: number, value: string) => {
    setDataTable((prev) => {
      // Cập nhật giá trị orderQuantity của phần tử tương ứng
      const updatedData = prev.map((item, i) =>
        i === index ? { ...item, orderQuantity: Number(value) } : item
      );

      // Sắp xếp lại danh sách sau khi cập nhật
      const sortedData = [...updatedData].sort((a, b) => {
        if (a.check && !b.check) return -1;
        if (!a.check && b.check) return 1;
        return 0;
      });

      return sortedData;
    });
  }, []);

  return (
    <Box mt={10}>
      <ScrollArea h={height}>
        <Table style={{ overflowY: "auto", height: "30px" }}>
          <Table.Thead className={cx(classes.header)}>
            <Table.Tr>
              <Table.Th w={30}>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </Table.Th>
              <Table.Th>Tên chi nhánh</Table.Th>
              <Table.Th w={"30%"}>SL đơn hàng</Table.Th>
              <Table.Th w={70}>Thao tác</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody mih={100}>
            {dataTable?.map((branch, index) => (
              <Table.Tr key={index}>
                <Table.Td>
                  <Checkbox
                    checked={branch.check ?? false}
                    onChange={(event) =>
                      handleCheckboxChange(index, event.target.checked)
                    }
                  />
                </Table.Td>
                <Table.Td>{branch.branchName}</Table.Td>

                <Table.Td
                // onDoubleClick={() => {
                //   setIsEditOrderQuantity(true);
                //   setIndexEditOrderQuantity(index);
                // }}
                >
                  <NumberInput
                    autoFocus
                    size="xs"
                    placeholder={"Nhập số tiền"}
                    hideControls
                    value={branch.orderQuantity || 0}
                    thousandSeparator="."
                    decimalSeparator=","
                    onFocus={(event) => event.target.select()}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        e.currentTarget.value.trim() !== ""
                      ) {
                        handleUpdateQuantity(index, e.currentTarget.value);
                      }
                    }}
                    onBlur={(e) => {
                      handleUpdateQuantity(index, e.currentTarget.value);
                    }}
                  />
                </Table.Td>
                <Table.Td>
                  <Tooltip label="Làm mới">
                    <IconRecycle
                      cursor={"pointer"}
                      color="red"
                      onClick={() => handleReset(index)}
                    />
                  </Tooltip>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
          {dataTable.length < 1 && (
            <Table.Caption>Không có dữ liệu</Table.Caption>
          )}
        </Table>
      </ScrollArea>
    </Box>
  );
};

export default React.memo(SuperMarket);
