import { Box, Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { repositoryPos } from "../../_base/_const/_constVar";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { useEffect, useState } from "react";

interface MessageResponse<T> {
  success: boolean;
  data: T;
}

interface OrderStatus {
  id: number;
  code: string;
  description: string;
  active: boolean;
  createDate: string;
  createBy: number;
  lastUpdateDate: string | null;
  lastUpdateBy: number | null;
}

interface DeleteProduct {
  idItems: number[];
  onSearch: () => void;
  onClose: any;
  status: OrderStatus;
}

const SalesDeleteOrder = ({
  idItems,
  onSearch,
  onClose,
  status,
}: DeleteProduct) => {
  //   const [listStatus, setListStatus] = useState<OrderStatus[] | undefined>();
  //   const getStatus = async () => {
  //     const res = await repositoryPos.get<MessageResponse<OrderStatus[]>>(
  //       "/api/v1/OrderStatus/get-all"
  //     );
  //     if (res?.success) {
  //       setListStatus(res?.data);
  //     }
  //     modals.closeAll();
  //     onSearch();
  //   };

  //   console.log(listStatus);

  const handleDeleteOrder = async () => {
    const res = await repositoryPos.get<MessageResponse<boolean>>(
      `/api/v1/CreateSellItem/delete?orderNumber=${idItems}`
    );
    if (res?.success) {
      NotificationExtension.Success("Xóa thành công !");
      onSearch();
      onClose((prev: any) => !prev);
    } else NotificationExtension.Fails("Xóa thất bại !");
    modals.closeAll();
  };

  //   useEffect(() => {
  //     getStatus();
  //   }, []);

  return (
    <Box size={"auto"}>
      <Text size="20px" mt={5}>
        Bạn có chắc chắn muốn hủy đơn này ?
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Thoát
        </Button>
        <Button
          type="button"
          color="red"
          onClick={handleDeleteOrder}
          leftSection={<IconCheck size={18} />}
        >
          Hủy
        </Button>
      </Group>
    </Box>
  );
};

export default SalesDeleteOrder;
