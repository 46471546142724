import { Badge, Box, Button, Grid, GridCol } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryPos } from "../../../_base/_const/_constVar";
import InputSearch from "../../../common/InputSearch/InputSearch";
import { _localization } from "../../../config/location";
import "./tab.component.css";

interface Item {
  itemCode: string; // Example: "PWJE0077"
  itemName: string; // Example: "Nguồn Jetek X375W Plus"
  lineId: number; // Example: 853
  quantity: number; // Example: 1
  quantityLimit: number; // Example: 1
  uom: string;
}

interface DetailWipViewProps {
  idItems: number;
}

const DetailWipView: React.FC<DetailWipViewProps> = ({ idItems }) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<Item[]>([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const [search, setSearch] = useState({
    keySearch: "",
    StartDate: "",
    BranchId: "",
    EndDate: "",
    status: "",
  });

  const sizeGrid = { base: 6, sm: 2, md: 2, lg: 2, xl: 2 };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (idItems) {
      url += `&HeaderId=${idItems}`;
    }

    if (search.keySearch) {
      url += `&KeySearch=${encodeURIComponent(search.keySearch)}`;
    }

    if (sorting && sorting.length > 0) {
      const { id, desc } = sorting[0] || {};
      if (id) {
        url += `&Sort=${encodeURIComponent(id)}&isOrder=${desc}`;
      }
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblMfgProductOrder/get-list-detail${url}`
      );

      if (response && response.success) {
        const result = response.data || [];
        setData(result);
        setRowCount(result.length === 0 ? 0 : response.totalCount);
        table.resetRowSelection();
      } else {
        setData([]);
        setRowCount(0);
      }
    } catch (error) {
      setIsError(true);
      setData([]);
      setRowCount(0);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  useEffect(() => {
    if (pagination.pageIndex === 0 && data.length < pagination.pageSize) {
      fetchData();
    } else {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting, idItems]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<Item>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        enableColumnActions: false,
        enableSorting: false,
        size: 100,
        Cell: ({ renderedCellValue, row }: any) => (
          <Badge variant="light">
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableColumnActions: false,
        enableSorting: false,
        size: 350,
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "uom",
        header: "Đơn vị tính",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "quantityLimit",
        header: "Số lượng giới hạn",
        enableColumnActions: false,
        enableSorting: false,
      },
    ],
    []
  );

  const table = useMantineReactTable<Item>({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["maLsx", "id"],
        right: ["trangThai", "action"],
      },
      columnVisibility: { id: true },
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "550", //conditional styling
        fontSize: "12.5px",
        padding: "7px 15px",
      },
    }),
    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  return (
    <Box>
      <div ref={headerRef}>
        <Box my={"10px"}>
          <Grid mt={10}>
            <GridCol span={sizeGrid}>
              <InputSearch
                width="100%"
                placeholder="Từ khoá"
                onSearch={fetchData}
                onChange={(value) => {
                  handleChangeSearchValue(value, "keySearch");
                }}
              />
            </GridCol>
            <GridCol span={sizeGrid}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={async () => {
                  await fetchData();
                }}
              >
                Tìm kiếm
              </Button>
            </GridCol>
          </Grid>
        </Box>
      </div>
      <Box>
        <MantineReactTable table={table} />
      </Box>
    </Box>
  );
};

export default DetailWipView;
