import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  NumberInput,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput, DateTimePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { TblDeliveryAssignment } from "../../../model/TblDeliveryAssignment";
import {
  getTblDmEmployeeSelect,
  getDeliveryWorkSelect,
  getBranchSelectMdm,
} from "../../../service/getSelectApi";
import { useEffect, useState } from "react";
import { hasLength, useForm } from "@mantine/form";
import dayjs from "dayjs";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";
import { handleKeyDown } from "../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../const/variables";

const ModalCombineOrders = ({
  selectIds,
}: {
  selectIds: string[] | number[];
}) => {
  const entity = {
    id: "0",
    code: null,
    assignmentDate: new Date().toISOString(),
    fromExpectedDate: null,
    toExpectedDate: null,
    deliveryWorkId: null,
    employee1: null,
    employee2: null,
    mainResponsibility1: false,
    mainResponsibility2: false,
    payroll1: false,
    payroll2: false,
    vehicleCode: null,
    driverId: null,
    driverName: null,
    driverMobileNumber: null,
    fromLocationId: null,
    toLocationId: null,
    distance: null,
    distanceLevel: null,
    distanceTotal: null,
    partnerId: null,
    partnerEmployeeName: null,
    description: null,
    deliveryIds: selectIds,
    estimatedTime: null,
    tblDeliveryModels: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [dataEmployeedSelect, setDataEmployeedSelect] = useState<
    ComboboxItem[]
  >([]);

  const [dataWorkSelect, setDataWorkSelect] = useState<ComboboxItem[]>([]);

  const [dataBranchdSelect, setDataBranchdSelect] = useState<ComboboxItem[]>(
    []
  );

  const [codeEmployeeSelect, setCodeEmployeeSelect] = useState<string | null>(
    ""
  );

  const [fromLocation, setFromLocation] = useState<string | null>("");

  const [toLocation, setToLocation] = useState<string | null>("");

  const [distanLevel, setDistanLevel] = useState<number | null>(0);

  const [startTime, setStartTime] = useState<Date | null>(new Date());

  const [endDate, setEndDate] = useState<Date | null>(null);

  const form = useForm<TblDeliveryAssignment>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      code: (value: any | null) => {
        const invalidChars =
          /[âăáàảãạêếềệễểíìịĩỉóòỏõọôốồộổỗơớờợỡởúùủũụưứừựữửýỳỷỹỵđ]/i;
        if (invalidChars.test(value)) {
          return "Mã phân công giao vận chứa những kí tự không hợp lệ !";
        }
        if (!value) {
          return "Vui lòng nhập mã phân công giao vận !";
        }
      },
      driverMobileNumber: (value: string | null | undefined) => {
        if (value) {
          return hasLength(
            { max: 10, min: 10 },
            "Số điện thoại phải đủ 10 ký tự !"
          )(value as string);
        }
      },
      employee1: (value: number | null | undefined) => {
        if (!value) {
          return "Vui lòng nhập tên nhân viên giao hàng !";
        }
      },
    },
  });

  const handleCreateTblDeliveryAssignment = async (
    dataSubmit: TblDeliveryAssignment
  ) => {
    open();
    const dataApi = await repositoryDelivery.post<
      MessageResponse<TblDeliveryAssignment>
    >("/api/v1/TblDeliveryAssignment/create-by-combine-delivery", dataSubmit);
    if (dataApi?.success) {
      modals.closeAll();
      NotificationExtension.Success("Ghép đơn thành công !");
    }
    close();
  };

  const dataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeedSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataBranchSelect = async () => {
    const get = await getBranchSelectMdm();
    setDataBranchdSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const dataDeliveryWorkSelect = async () => {
    const get = await getDeliveryWorkSelect();
    setDataWorkSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    Promise.all([
      dataEmployeeSelect(),
      dataDeliveryWorkSelect(),
      dataBranchSelect(),
    ]);
  }, []);

  useEffect(() => {
    form.setValues((prev) => ({
      ...prev,
      toExpectedDate: new Date(endDate ?? "").toDateString(),
    }));
  }, [endDate]);

  return (
    <Box
      component="form"
      m={"10px 0px"}
      w={{ base: "360px", md: "400px", lg: "600px" }}
      onSubmit={form.onSubmit((e: TblDeliveryAssignment) => {
        handleCreateTblDeliveryAssignment(e);
      })}
      style={{ position: "relative" }}
    >
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 2.7 }}>
          <TextInput
            label="Mã phân công giao vận"
            placeholder="Nhập mã phân công giao vận"
            size="xs"
            onKeyDown={handleKeyDown}
            {...form.getInputProps("code")}
            withAsterisk
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
          <DatePickerInput
            label="Ngày phân công"
            placeholder="Nhập ngày phân công"
            size="xs"
            value={new Date()}
            valueFormat="DD/MM/YYYY"
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3.4 }}>
          <DatePickerInput
            label="Dự kiến giao từ"
            placeholder="Nhập ngày dự kiến giao hàng"
            size="xs"
            defaultValue={startTime}
            valueFormat="DD/MM/YYYY"
            minDate={new Date()}
            clearable
            {...form.getInputProps("fromExpectedDate")}
            onChange={(value: any) => {
              setStartTime(value);
              form.setValues((prev) => ({
                ...prev,
                fromExpectedDate: new Date(value ?? "").toDateString(),
              }));
              form.setValues({ toExpectedDate: "" });
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3.4 }}>
          <DatePickerInput
            label="Dự kiến giao đến"
            placeholder="Nhập ngày dự kiến hoàn tất"
            size="xs"
            valueFormat="DD/MM/YYYY"
            minDate={new Date(dayjs(startTime).add(1, "day").toDate())}
            clearable
            value={endDate}
            key={form.key("toExpectedDate")}
            onChange={(value) => setEndDate(value)}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Select
            label="Nhân viên GH"
            placeholder="Nhập tên nhân viên GH"
            data={dataEmployeedSelect}
            {...form.getInputProps("employee1")}
            searchable
            clearable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            size="xs"
            withAsterisk
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Checkbox
            mt={25}
            label="Người chịu trách nhiệm"
            size="xs"
            {...form.getInputProps("mainResponsibility1")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Checkbox
            mt={25}
            label="Tính lương"
            size="xs"
            {...form.getInputProps("payroll1")}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Select
            label="Nhân viên HT"
            placeholder="Nhập tên nhân viên HT"
            data={dataEmployeedSelect}
            searchable
            clearable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            size="xs"
            {...form.getInputProps("employee2")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Checkbox
            mt={25}
            label="Người chịu trách nhiệm"
            size="xs"
            {...form.getInputProps("mainResponsibility2")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Checkbox
            mt={25}
            label="Tính lương"
            size="xs"
            {...form.getInputProps("payroll2")}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Select
            label="Phương tiện giao hàng"
            placeholder="Chọn phương tiện giao hàng"
            clearable
            size="xs"
            data={[
              { label: "Xe máy", value: "Xe máy" },
              { label: "Ô tô", value: "Ô tô" },
            ]}
            {...form.getInputProps("vehicleCode")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Lái xe"
            placeholder="Nhập tên nhân viên lái xe"
            size="xs"
            {...form.getInputProps("driverName")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput
            label="Số điện thoại người lái"
            placeholder="Nhập số điện thoại người lái"
            size="xs"
            {...form.getInputProps("driverMobileNumber")}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Select
            label="Công việc"
            placeholder="Chọn loại công việc"
            clearable
            size="xs"
            data={dataWorkSelect}
            onChange={(value) =>
              form.setFieldValue(
                "deliveryWorkId",
                value !== null ? Number(value) : null
              )
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Select
            label="Điểm đi"
            placeholder="Nhập điểm đi"
            data={dataBranchdSelect}
            value={fromLocation}
            searchable
            clearable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            size="xs"
            onChange={(e) => {
              setFromLocation(e);
              form.setFieldValue("fromLocationId", Number(e));
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Select
            label="Điểm đến"
            placeholder="Nhập điểm đến"
            data={dataBranchdSelect}
            searchable
            clearable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            size="xs"
            onChange={(e) => {
              setToLocation(e);
              form.setFieldValue("toLocationId", Number(e));
            }}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 3.5 }}>
          <NumberInput
            label="Khoảng cách"
            hideControls
            variant="filled"
            value={0}
            readOnly
            size="xs"
            {...form.getInputProps("distance")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3.5 }}>
          <NumberInput
            label="Khoảng cách PC"
            hideControls
            variant="filled"
            value={0}
            readOnly
            size="xs"
            {...form.getInputProps("distanceTotal")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3.5 }}>
          <Select
            label="+/- khoảng cách"
            placeholder="Chọn mức khoảng cách"
            data={[
              { label: "Mức 0", value: "0" },
              { label: "Mức 1", value: "1" },
              { label: "Mức 2", value: "2" },
              { label: "Mức 3", value: "3" },
            ]}
            onChange={(value) =>
              form.setFieldValue(
                "distanceLevel",
                value !== null ? Number(value) : null
              )
            }
            clearable
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 1.5 }}>
          <TextInput
            label="Km"
            variant="filled"
            value={Number(distanLevel)}
            readOnly
            size="xs"
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label="Đối tác"
            placeholder="Nhập đối tác"
            size="xs"
            onChange={(event) => {
              const value = event.target.value;
              const numberValue = value === "" ? 0 : Number(value);
              form.setFieldValue("partnerId", numberValue);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label="Nhân viên đối tác"
            placeholder="Nhập tên nhân viên đối tác"
            size="xs"
            {...form.getInputProps("partnerEmployeeName")}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={12}>
          <Textarea
            label="Ghi chú"
            placeholder="Ghi chú"
            size="xs"
            {...form.getInputProps("description")}
          />
        </Grid.Col>
      </Grid>
      <Group
        justify="end"
        mt="md"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

export default ModalCombineOrders;
