import {
  Box,
  Button,
  Fieldset,
  Flex,
  Grid,
  Tabs,
  Title,
  Text,
  TextInput,
  Tooltip,
  Select,
  Checkbox,
  PillsInput,
  Pill,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconCalendarMonth, IconCheck, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  repositoryPos,
  RequiedCompont,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import {
  listBranch,
  listCustGroup,
  tblPromotion,
  tblPromotionCommand,
  tblPromotionItemGiftCommand,
} from "../../../../model/TblPromotion";
import GeneralInformation from "./Components/GeneralInformation/GeneralInformation";
import OrtherConditions from "./Components/SetupPromotionPolicy/OtherConditions";
import Pay from "./Components/SetupPromotionPolicy/Pay";
import SuperMarket from "./Components/SetupPromotionPolicy/SuperMarket";
import TimeApplication from "./Components/SetupPromotionPolicy/TimeApplication";
import TableItemAddOnPromotion from "./Components/TableItemPromotion/TableItemAddOnPromotion";
import TableItemGiftPromotion from "./Components/TableItemPromotion/TableItemGiftPromotion";
import TableItemPrimaryPromotion from "./Components/TableItemPromotion/TableItemPrimaryPromotion";
import TypePromotionPolicy from "./Modals/TypePromotionPolicy";
import TableItemDeletePromotion from "./Components/TableItemPromotion/TableItemDeletePromotion";
import { useTimeout } from "@mantine/hooks";
import { validateCreatePromotion } from "../../../../validate/UserCreateModelValidator";
import { DateTimePicker } from "@mantine/dates";
import moment from "moment";
import dayjs from "dayjs";
import { SelectListItem } from "../../../../model/SelectListItem";
import {
  getTblDmEmployeeSelect,
  getUsersSelect,
} from "../../../../service/getSelectApi";
import { SelectListItemBase } from "../../../../_base/model/_base/SelectListItemBase";
import CustomersV2 from "./Components/SetupPromotionPolicy/CustomersV2";
import { CustomerGroup } from "../../../../model/TblDmCustomer";
import PromotionSelectView from "../CreatePromotionPolicy/Components/TableItemPromotion/PromotionSelectView";
import { formatToVietnamTz } from "../../../../_base/extension/DateExtension";

const EditPromotionPolicyV2 = () => {
  const navigate = useNavigate();
  let params = useParams();
  const [effDate, setEffDate] = useState<string>("");
  const [expDate, setExpDate] = useState<string>("");
  const [selectedPolicyType, setSelectedPolicyType] = useState<string>("0");
  const [dataDeletePromotion, setDataDeletePromotion] = useState<
    tblPromotionItemGiftCommand[]
  >([]);
  const [userSelect, setUserSelect] = useState<SelectListItemBase[]>([]);

  const [entity, setEntity] = useState<tblPromotion>({
    tblPromotionCommand: {
      id: 0,
      code: null,
      type: "0",
      createDate: null,
      createBy: null,
      stopTriggerRule: null,
      note: null,
      approveDate: null,
      approveBy: null,
      approveStatus: null,
      approveNote: null,
      priority: null,
      checkRewardPoint: null,
      checkNomalPromote: null,
      checkMixPromote: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      listCustGroup: [],
      lisItemPrimary: [],
      listBranch: [],
      ismultiple: false,
    },

    tblPromotionItemPrimaryCommands: [],
    tblPromotionItemAddOnCommands: [],
    tblPromotionItemGiftCommands: [],
  });

  const form = useForm<tblPromotion>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {},
  });

  const formHeader = useForm<tblPromotionCommand>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity.tblPromotionCommand,
    },
    validate: validateCreatePromotion,
  });

  const backHome = () => {
    navigate("/config-system/config-promotion-policy");
    localStorage.removeItem("policyType");
  };

  const openTypePP = () => {
    modals.openConfirmModal({
      size: "auto",
      centered: true,
      title: (
        <>
          <Title order={5}>Chọn loại chính sách khuyến mại</Title>
        </>
      ),
      children: (
        <TypePromotionPolicy
          setSelectedPolicyType={setSelectedPolicyType}
          backHome={backHome}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleCreatePromotion = async (dataSubmit: tblPromotion) => {
    try {
      if (
        !dataSubmit.tblPromotionCommand?.effDate ||
        !dataSubmit.tblPromotionCommand?.expDate
      ) {
        NotificationExtension.Warn("Vui lòng chọn khoảng thời gian hiệu lực !");
        return;
      }
      const dataApi = await repositoryPos.post<MessageResponse<boolean>>(
        "/api/v1/TblPromotion/edit",
        dataSubmit
      );
      if (dataApi && dataApi.success) {
        NotificationExtension.Success("Thành công");
        setTimeout(() => {
          navigate("/config-system/config-promotion-policy");
        }, 2000);
      } else NotificationExtension.Fails(dataApi?.message || "Thất bại");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("params", params);
    if (!params.id || Number.parseInt(params.id) < 1) {
      navigate("/process-defs/flow-model");
    } else {
      fetchDataCreatePromotion(Number.parseInt(params.id));
    }
  }, []);

  // useEffect(() => {
  //   if (!localStorage.getItem("policyType")) {
  //     openTypePP();
  //   }
  // }, []);

  useEffect(() => {
    if (selectedPolicyType) {
      form.setFieldValue("tblPromotionCommand.type", selectedPolicyType);
    }
  }, [selectedPolicyType]);

  const [dataInittt, setDataInittt] = useState<tblPromotion>(entity);

  const fetchDataCreatePromotion = async (id: number) => {
    const dataApi = await repositoryPos.get<MessageResponse<tblPromotion>>(
      "/api/v1/TblPromotion/edit?promotionId=" + id
    );
    if (dataApi && dataApi.success) {
      setEntity(dataApi.data);
      //  form.setFieldValue("tblPromotionCommand.code", dataApi.data?.tblPromotionCommand.c);
      formHeader.setValues(dataApi.data.tblPromotionCommand);
      formHeader.resetDirty(dataApi.data.tblPromotionCommand);
      formHeader.initialize(dataApi.data.tblPromotionCommand);
      dataApi.data.tblPromotionCommand.listCustGroup = JSON.parse(
        dataApi.data.tblPromotionCommand.listCustGroup as string
      ) as CustomerGroup[];
      dataApi.data.tblPromotionCommand.listBranch = JSON.parse(
        dataApi.data.tblPromotionCommand.listBranch as string
      ) as listBranch[];

      setDataInittt(dataApi.data);
      if (dataApi.data.tblPromotionCommand.effDate) {
        formHeader.setFieldValue(
          "effDate",
          new Date(dataApi.data.tblPromotionCommand.effDate)
        );
      }
      if (dataApi.data.tblPromotionCommand.expDate) {
        formHeader.setFieldValue(
          "expDate",
          new Date(dataApi.data.tblPromotionCommand.expDate)
        );
      }
      if (dataApi.data.tblPromotionCommand.createBy)
        formHeader.setFieldValue(
          "createBy",
          dataApi.data.tblPromotionCommand.createBy.toString()
        );

      if (dataApi.data.tblPromotionCommand.attribute1) {
        try {
          setListPromotion(
            dataApi.data.tblPromotionCommand.attribute1.split(",")
          );
        } catch (error) {}
      }

      // console.log(22, dataApi.data);
    } else {
      NotificationExtension.Fails("Bạn không có quyền thực hiện thao tác");
      navigate("/config-system/config-promotion-policy");
    }
  };
  const [selectType, setTypeSelect] = useState<SelectListItemBase[]>([]);

  useEffect(() => {
    fetchUser();
    getTypeSelect();
    // fetchPromotionCode();
  }, []);

  const getTypeSelect = async () => {
    var resType = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblPromotion/type");
    if (resType?.httpStatusCode === 200 && resType.data) {
      setTypeSelect(resType.data);
    }
  };

  const fetchUser = async () => {
    const dataseApi = await getTblDmEmployeeSelect();
    setUserSelect(dataseApi);
  };

  const _styleGrid = { base: 6, md: 3, lg: 2 };
  const _styleGrid1 = { base: 6, md: 6, lg: 4 };

  const [listPromotion, setListPromotion] = useState<string[]>([]);

  return (
    <Box
      component="form"
      onSubmit={formHeader.onSubmit(async (e) => {
        if (formHeader.validate().hasErrors) {
          NotificationExtension.Fails("Vui lòng điền đầy đủ thông tin !");
          return;
        }

        if (formHeader.getValues().listBranch.length < 1) {
          NotificationExtension.Fails("Vui lòng chọn chi nhánh áp dụng !");
          return;
        }
        let _data = form.getValues();

        if (
          !_data ||
          !_data.tblPromotionItemPrimaryCommands ||
          _data.tblPromotionItemPrimaryCommands.length < 1
        ) {
          NotificationExtension.Fails("Vui lòng chọn mặt hàng áp dụng !");
          return;
        }
        _data.tblPromotionCommand = formHeader.getValues();
        _data.tblPromotionCommand = formHeader.getValues();
        _data.tblPromotionCommand.effDate = formatToVietnamTz(
          _data.tblPromotionCommand.effDate
        );
        _data.tblPromotionCommand.expDate = formatToVietnamTz(
          _data.tblPromotionCommand.expDate
        );
        if (listPromotion.length > 0) {
          _data.tblPromotionCommand.ismultiple = listPromotion.length > 0;
          _data.tblPromotionCommand.attribute1 = listPromotion.join(",");
        }
        await handleCreatePromotion(_data);
      })}
    >
      <Flex
        align={"center"}
        gap={"md"}
        justify={"space-between"}
        px={"3px"}
        wrap={"wrap"}
      >
        <Text size={"16px"} tt={"uppercase"} fw={"bold"}>
          Chỉnh sửa chính sách khuyến mãi
        </Text>
        <Flex gap={"md"}>
          <Button
            variant="outline"
            color="red"
            leftSection={<IconX size={14} />}
            // size="xs"
            onClick={backHome}
          >
            Đóng
          </Button>
          {/* <Button
            onClick={() => {
              modals.openConfirmModal({
                size: "70vw",
                centered: true,
                title: <Title order={5}>Tìm kiếm CTKM</Title>,
                children: (
                  <>
                    <PromotionSelectView
                      listCode={listPromotion}
                      setListPromotion={setListPromotion}
                    ></PromotionSelectView>
                  </>
                ),
                confirmProps: { display: "none" },
                cancelProps: { display: "none" },
              });
            }}
          >
            Thêm CTKM đồng thời
          </Button> */}
          <Button
            type="submit"
            variant="outline"
            color="teal"
            leftSection={<IconCheck size={14} />}
            // size="xs"
            // onClick={handleCreatePromotion}
          >
            Lưu chính sách
          </Button>
        </Flex>
      </Flex>
      <Grid>
        <Grid.Col
          // span={{ base: 12, md: 6, lg: 5 }}
          span={12}
          h={"max-content"}
        >
          {/* <GeneralInformation
            selectedPolicyType={selectedPolicyType}
            form={form}
            effDate={effDate}
            setEffDate={setEffDate}
            expDate={expDate}
            setExpDate={setExpDate}
          /> */}

          <Box h={"max-content"}>
            <Fieldset
              legend="Thông tin chung"
              // display={"flex"}
              // style={{ flexDirection: "column", gap: "10px" }}
            >
              <Grid>
                <Grid.Col span={_styleGrid}>
                  <TextInput
                    label="Số chính sách"
                    placeholder="Nhập số chính sách"
                    readOnly
                    withAsterisk
                    key={formHeader.key("code")}
                    {...formHeader.getInputProps("code")}
                  />
                </Grid.Col>
                <Grid.Col span={_styleGrid}>
                  <Select
                    key={formHeader.key("createBy")}
                    label="Người lập:"
                    placeholder="Vui lòng chọn..."
                    nothingFoundMessage="Không có dữ liệu"
                    readOnly
                    limit={1}
                    data={userSelect.map((doctype) => ({
                      value: doctype.value,
                      label: doctype.text,
                    }))}
                    searchable
                    withAsterisk
                    {...formHeader.getInputProps("createBy")}
                  />
                </Grid.Col>
                <Grid.Col span={_styleGrid}>
                  <Select
                    key={formHeader.key("type")}
                    label="Chính sách khuyến mại:"
                    placeholder="Vui lòng chọn..."
                    nothingFoundMessage="Không có dữ liệu"
                    data={selectType.map((x) => ({
                      value: x.value,
                      label: x.text,
                    }))}
                    searchable
                    withAsterisk
                    {...formHeader.getInputProps("type")}
                  />
                </Grid.Col>

                <Grid.Col span={_styleGrid}>
                  <DateTimePicker
                    label="Hiệu lực từ ngày"
                    placeholder="Hiệu lực từ ngày"
                    minDate={new Date()}
                    locale="vi"
                    rightSection={<IconCalendarMonth size={14} />}
                    withAsterisk
                    clearable
                    key={formHeader.key("effDate")}
                    {...formHeader.getInputProps("effDate")}
                    // value={effDate ? new Date(effDate) : null}
                    // onChange={(value) => {
                    //   setEffDate(
                    //     moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || ""
                    //   );
                    //   formHeader
                    //     .getInputProps("effDate")
                    //     .onChange(
                    //       moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || ""
                    //     );
                    // }}
                  />
                </Grid.Col>

                <Grid.Col span={_styleGrid}>
                  <DateTimePicker
                    label="Đến ngày"
                    placeholder="Đến ngày"
                    locale="vi"
                    withAsterisk
                    clearable
                    minDate={
                      formHeader.getValues().effDate
                        ? new Date(
                            dayjs(formHeader.getValues().effDate)
                              .add(0, "day")
                              .toDate()
                          )
                        : new Date()
                    }
                    rightSection={<IconCalendarMonth size={14} />}
                    key={formHeader.key("expDate")}
                    {...formHeader.getInputProps("expDate")}
                    // value={expDate ? new Date(expDate) : null}
                    // onChange={(value) => {
                    //   setExpDate(
                    //     moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || ""
                    //   );
                    //   formHeader
                    //     .getInputProps("expDate")
                    //     .onChange(
                    //       moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || ""
                    //     );
                    // }}
                  />
                </Grid.Col>
                <Grid.Col mt={"30px"} span={_styleGrid}>
                  <Checkbox
                    key={formHeader.key("ismultiple")}
                    label="Áp dụng đồng thời CTKM khác"
                    {...formHeader.getInputProps("ismultiple", {
                      type: "checkbox",
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <TextInput
                    label="Tên CTKM"
                    placeholder="Tên CTKM"
                    withAsterisk
                    key={formHeader.key("note")}
                    {...formHeader.getInputProps("note")}
                  />
                </Grid.Col>

                {/* <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
                  {listPromotion && (
                    <PillsInput label="Áp dụng đồng thời CTKM khác">
                      <Pill.Group>
                        {listPromotion.map((promotion, index) => (
                          <Pill key={index}>{promotion.trim()}</Pill>
                        ))}
                        <PillsInput.Field placeholder="..." />
                      </Pill.Group>
                    </PillsInput>
                  )}
                </Grid.Col> */}
              </Grid>
            </Fieldset>
          </Box>
        </Grid.Col>

        <Grid.Col span={12}>
          <Tabs
            defaultValue={"primary"}
            styles={{
              tabLabel: { fontWeight: 700, fontSize: 13 },
              panel: {
                overflowX: "visible",
              },
            }}
          >
            <Tabs.List>
              {/* <Tabs.Tab value="otherConditions">Điều kiện khác</Tabs.Tab> */}
              <Tabs.Tab value="primary">
                <RequiedCompont title="Mặt hàng chính" />
              </Tabs.Tab>
              <Tabs.Tab value="gift">Mặt hàng khuyến mãi</Tabs.Tab>
              <Tabs.Tab disabled={selectedPolicyType === "2"} value="addOn">
                Mặt hàng mua kèm
              </Tabs.Tab>
              {/* <Tabs.Tab value="delete">
                Danh sách mặt hàng không áp dụng
              </Tabs.Tab> */}
              <Tabs.Tab value="branch&customer">
                <RequiedCompont title="Chi nhánh áp dụng và khách hàng" />
              </Tabs.Tab>
              {/* <Tabs.Tab value="time&pay">
                Thời gian và phương thức thanh toán
              </Tabs.Tab> */}
            </Tabs.List>

            <Tabs.Panel value="otherConditions">
              <OrtherConditions />
            </Tabs.Panel>

            <Tabs.Panel value="primary">
              <TableItemPrimaryPromotion
                form={form}
                dataInit={entity.tblPromotionItemPrimaryCommands ?? []}
                setDeleteData={setDataDeletePromotion}
              />
            </Tabs.Panel>
            <Tabs.Panel value="gift">
              <TableItemGiftPromotion
                form={form}
                setDeleteData={setDataDeletePromotion}
                dataInit={entity.tblPromotionItemGiftCommands ?? []}
              />
            </Tabs.Panel>
            <Tabs.Panel value="addOn">
              <TableItemAddOnPromotion
                dataInit={entity.tblPromotionItemAddOnCommands ?? []}
                form={form}
                setDeleteData={setDataDeletePromotion}
              />
            </Tabs.Panel>
            {/* <Tabs.Panel value="delete">
              <TableItemDeletePromotion
                form={form}
                dataDelete={dataDeletePromotion}
              />
            </Tabs.Panel> */}
            {/* <Tabs.Panel value="flashSale">
              <TableItemAddOnPromotion
                dataInit={entity.tblPromotionItemAddOnCommands ?? []}
                form={form}
                setDeleteData={setDataDeletePromotion}
              />
            </Tabs.Panel> */}

            <Tabs.Panel value="branch&customer">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset
                    legend={<RequiedCompont title="Chi nhánh áp dụng" />}
                    mt={10}
                  >
                    <SuperMarket
                      form={formHeader}
                      _data={dataInittt.tblPromotionCommand.listBranch as []}
                    />
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Đối tượng khách hàng" mt={10}>
                    <CustomersV2
                      form={formHeader}
                      _data={dataInittt.tblPromotionCommand.listCustGroup as []}
                    />
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
            {/* 
            <Tabs.Panel value="time&pay">
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Thời gian áp dụng" mt={10}>
                    <TimeApplication
                      form={form}
                      effDate={effDate}
                      expDate={expDate}
                    />
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <Fieldset legend="Thanh toán" mt={10}>
                    <Pay form={form} />
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </Tabs.Panel> */}
          </Tabs>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default EditPromotionPolicyV2;
