import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  TextInput,
  Textarea,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMUomCode } from "../../../../model/TblDMUomCode";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";

const CreateDataView = ({ onClose }: CreateDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    name: null,
    note: null,
    active: false,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMUomCode>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      name: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên đơn vị tính !";
        }
        return hasLength(
          { max: 50 },
          "Tên đơn vị tính không được dài hơn 50 ký tự !"
        )(value as string);
      },
      code: (value: any | null) => {
        const invalidChars =
          /[âăáàảãạêếềệễểíìịĩỉóòỏõọôốồộổỗơớờợỡởúùủũụưứừựữửýỳỷỹỵđ]/i;
        if (invalidChars.test(value)) {
          return "Mã đơn vị tính chứa những kí tự không hợp lệ !";
        }
        if (!value) {
          return "Vui lòng nhập mã đơn vị tính !";
        }
        return hasLength(
          { max: 10 },
          "Mã đơn vị tính không được dài hơn 10 ký tự !"
        )(value as string);
      },
      note: (value: string | null) => {
        if (value && hasLength({ max: 250 })(value)) {
          return "Ghi chú không quá 250 kí tự!";
        }
      },
    },
  });

  const handleCreateTblDMUomCode = async (dataSubmit: TblDMUomCode) => {
    open();
    const dataApi = await repositoryMdm.post<MessageResponse<TblDMUomCode>>(
      "/api/v1/TblDMUomCode/create",
      dataSubmit
    );
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "250px", md: "300px", lg: "400px" }}
        onSubmit={form.onSubmit((e: TblDMUomCode) => {
          handleCreateTblDMUomCode(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã"}
              placeholder={"Nhập mã"}
              type="text"
              onKeyDown={handleKeyDown}
              withAsterisk
              {...form.getInputProps("code")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <TextInput
              label={"Tên"}
              placeholder={"Nhập tên"}
              type="text"
              withAsterisk
              {...form.getInputProps("name")}
            />
          </Grid.Col>
        </Grid>

        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Textarea
              label={"Ghi chú"}
              placeholder="Nhập ghi chú"
              {...form.getInputProps("note")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Checkbox label={"Sử dụng"} {...form.getInputProps("active")} />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  onClose: any;
};
