import { Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconLogout, IconX } from "@tabler/icons-react";

export const LogoutUserView = ({ ids, onClose }: LogoutUserViewProps) => {
  return (
    <div>
      <Text size="20px" mt={5}>
        {`Bạn có chắc chắn đăng xuất ${
          ids.length === 1 ? "những " : " "
        }người dùng này`}
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color="yellow"
          // onClick={handleDeleteBranch}
          leftSection={<IconLogout size={18} />}
        >
          Đăng xuất
        </Button>
      </Group>
    </div>
  );
};

type LogoutUserViewProps = {
  ids: string[] | number[];
  onClose: any;
};
