import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  Menu,
  Modal,
  Select,
  SelectProps,
  Text,
  TextInput,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconCaretDown,
  IconCheck,
  IconDots,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconSection,
  IconStatusChange,
  IconTimeDuration15,
  IconTransferIn,
  IconTransferOut,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";

import { DatePickerInput } from "@mantine/dates";
import { IconFilePlus } from "@tabler/icons-react";
import { useNavigate } from "react-router";
import {
  _timerDelayClick,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { green } from "../../../const/variables";
import { TblMfgWip } from "../../../model/TblMfgWip";
import { MessageResponse } from "../../../model/MessageResponse";
import { SelectListItem } from "../../../model/SelectListItem";
import { notifications } from "@mantine/notifications";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import InputSearch from "../../../common/InputSearch/InputSearch";
import { modals } from "@mantine/modals";
import DetailWipView from "./DetailView";
import AccessoryView from "./AccessoryView";

interface ProductionOrder {
  id: string;
  maLsx: string; // Mã lệnh sản xuất
  ngayDksx: string; // Ngày đăng ký sản xuất (ISO format)
  maSp: string; // Mã sản phẩm
  idLsx: number;
  tenSp: string; // Tên sản phẩm
  maCn: string; // Mã chi nhánh
  tenCn: string; // Tên chi nhánh
  attribute1: string | null; // Thông số 1 (có thể null)
  attribute12: string | null; // Thông số 12 (có thể null)
  slSx: number; // Số lượng sản xuất
  maPx: string | null; // Mã phiếu xuất (có thể null)
  ngayXuat: string | null; // Ngày xuất (có thể null)
  ngLpx: string | null; // Người lập phiếu xuất (có thể null)
  maKh: string; // Mã khách hàng
  tenKh: string; // Tên khách hàng
  trangThai: string | null; // Trạng thái (có thể null)
}

function getColorStatus(text: any) {
  switch (text) {
    case "1":
      return "green";
    case "2":
      return "orange";
    case "NHAP_DIEU_CHUYEN":
      return "violet";
    default:
      return "gray";
  }
}
const dataStatusOption = [
  { value: "false", label: "Thực hiện" },
  { value: "true", label: "Hoàn thành" },
];
const optionColorsAccount: Record<"Đang thực hiện" | "Hoàn thành", string> = {
  "Đang thực hiện": "lime",
  "Hoàn thành": "green",
};
const renderSelectOptionStatus: SelectProps["renderOption"] = ({
  option,
  checked,
}) => (
  <Group flex="1" gap="xs">
    <Badge
      color={
        optionColorsAccount[option.label as "Đang thực hiện" | "Hoàn thành"]
      }
      radius="sm"
      variant="filled"
    >
      {option.label}
    </Badge>
    {checked && (
      <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
    )}
  </Group>
);
const MfgWip = () => {
  const navigate = useNavigate();
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<ProductionOrder[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const sizeGrid = { base: 6, sm: 2, md: 2, lg: 2, xl: 2 };
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  // const [typeKey, settypeKey] = useState<number | null>(null);
  const [globalFilter, setGlobalFilter] = useState("");

  const [search, setSearch] = useState({
    keySearch: "",
    StartDate: "",
    BranchId: "",
    EndDate: "",
    status: "",
  });

  const detailDataOut = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean
  ) => {
    navigate("/warehouse-config/detail-warehouse-out-ward", {
      state: { transactionCode: id, type: type, isLocked: isLocked },
    });
  };

  const detailDataIn = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean
  ) => {
    navigate("/warehouse-config/detail-warehouse-in-ward", {
      state: { transactionCode: id, sourceType: type, isLocked: isLocked },
    });
  };

  const openDeleteModal = (id: number) => () => {
    modals.open({
      title: <Title order={5}>Chi tiết lệnh sản xuất</Title>,
      size: "90%",
      children: <DetailWipView idItems={id} />,
    });
  };

  const openAccessory = (id: string) => () => {
    modals.open({
      title: <Title order={5}>Danh sách các linh kiện </Title>,
      size: "90%",
      children: <AccessoryView idItems={id} />,
    });
  };

  const columns = React.useMemo<MRT_ColumnDef<ProductionOrder>[]>(
    () => [
      {
        accessorKey: "maLsx",
        enableColumnActions: false,
        header: "Mã lệnh sản xuất",
        size: 130,
        Cell: ({ renderedCellValue, row }: any) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              style={{ cursor: "pointer" }}
              size="lg"
              color={renderedCellValue === null ? "red" : green.base}
              onClick={openDeleteModal(row.original.idLsx)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "ngayDksx",
        enableColumnActions: false,
        header: "Ngày đăng ký sx",
        size: 180,
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
      },
      {
        accessorKey: "maSp",
        enableColumnActions: false,
        header: "Mã sản phẩm",
        size: 30,
        Cell: ({ renderedCellValue, row }: any) => (
          <Badge variant="light">
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },

      {
        accessorKey: "tenSp",
        enableColumnActions: false,
        header: "Tên sản phẩm",
        size: 250,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maCn",
        enableColumnActions: false,
        header: "Mã chi nhánh",
        size: 70,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenCn",
        enableColumnActions: false,
        header: "Tên chi nhánh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "slSx",
        enableColumnActions: false,
        header: "Số lượng YC sản xuất",
        Cell: ({ renderedCellValue }) => (
          <>{renderedCellValue === null ? 0 : renderedCellValue}</>
        ),
      },

      // {
      //   accessorKey: "transferQuantity",
      //   enableColumnActions: false,
      //   header: "Số lượng đã lắp ráp",
      //   size: 80,
      //   Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      // },
      {
        accessorKey: "attribute1",
        enableColumnActions: false,
        header: "Số lượng đã lắp ráp",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <>{renderedCellValue === null ? 0 : renderedCellValue}</>
        ),
      },
      {
        accessorKey: "attribute12",
        enableColumnActions: false,
        header: "Số lượng nhập thành phẩm",
        Cell: ({ renderedCellValue }) => (
          <>{renderedCellValue === null ? 0 : renderedCellValue}</>
        ),
      },

      {
        accessorKey: "khoXuatAsia",
        enableColumnActions: false,
        header: "Mã kho xuất Asia",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maPx",
        enableColumnActions: false,
        header: "Mã PX kho Asia",
        Cell: ({ cell, renderedCellValue }) => {
          const formattedValue = renderedCellValue
            ? renderedCellValue.toString().split(",").join(", ")
            : "";
          return (
            <Tooltip label={formattedValue}>
              <Box w={200}>
                <Text fw={600} size="sm" truncate="end">
                  {formattedValue}
                </Text>
              </Box>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "attribute8",
        enableColumnActions: false,
        header: "Mã Kho xuất ĐC",
        Cell: ({ cell, renderedCellValue }) => {
          const formattedValue = renderedCellValue
            ? renderedCellValue.toString().split(",").join(", ")
            : "";
          return (
            <Tooltip label={formattedValue}>
              <Box w={200}>
                <Text fw={600} size="sm" truncate="end">
                  {formattedValue}
                </Text>
              </Box>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "attribute3",
        enableColumnActions: false,
        header: "Mã phiếu xuất ĐC",
        Cell: ({ cell, renderedCellValue }) => {
          const [opened, setOpened] = useState(false);
          const [searchTerm, setSearchTerm] = useState("");
          const values = renderedCellValue
            ? renderedCellValue
                .toString()
                .split(",")
                .map((value) => value.trim())
            : [];

          const filteredValues = values.filter((value) =>
            value.toLowerCase().includes(searchTerm.toLowerCase())
          );

          return (
            <>
              <Box display="flex">
                <Flex align="center" variant="filled" gap={5}>
                  {values.slice(0, 1).map((value, index) => (
                    <Badge
                      key={index}
                      size="lg"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataOut(
                          value,
                          "XUAT_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT",
                          true // status === "LOCK"
                        );
                      }}
                      variant="default"
                      fw={600}
                    >
                      {value}
                    </Badge>
                  ))}
                  {values.length > 1 && (
                    <ActionIcon
                      onClick={() => setOpened(true)}
                      color="blue"
                      radius="xl"
                      variant="light"
                      aria-label="Settings"
                    >
                      <IconDots size="1.2rem" />
                    </ActionIcon>
                  )}
                </Flex>
              </Box>
              <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                size="550"
                title={<Title order={5}>Danh sách mã phiếu</Title>}
              >
                <TextInput
                  placeholder="Tìm kiếm mã phiếu..."
                  value={searchTerm}
                  radius="xl"
                  mt={10}
                  leftSection={<IconSearch size="1rem" />}
                  onChange={(event) => setSearchTerm(event.currentTarget.value)}
                  mb={10}
                  w={170}
                />
                <Flex gap={10} mt={20} wrap="wrap">
                  {filteredValues.map((value, index) => (
                    <Badge
                      size="lg"
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataOut(
                          value,
                          "XUAT_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT",
                          true
                        );
                        setOpened(false);
                      }}
                      variant="default"
                      fw={600}
                    >
                      {value}
                    </Badge>
                  ))}
                  {filteredValues.length === 0 && (
                    <Text>Không tìm thấy kết quả</Text>
                  )}
                </Flex>
              </Modal>
            </>
          );
        },
      },
     
      {
        accessorKey: "attribute9",
        enableColumnActions: false,
        header: "Mã kho nhập ĐC",
        Cell: ({ cell, renderedCellValue }) => {
          const formattedValue = renderedCellValue
            ? renderedCellValue.toString().split(",").join(", ")
            : "";
          return (
            <Tooltip label={formattedValue}>
              <Box w={200}>
                <Text fw={600} size="sm" truncate="end">
                  {formattedValue}
                </Text>
              </Box>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "attribute4",
        enableColumnActions: false,
        header: "Mã phiếu nhập ĐC",
        Cell: ({ cell, renderedCellValue }) => {
          const [opened, setOpened] = useState(false);
          const [searchTerm, setSearchTerm] = useState("");
          const values = renderedCellValue
            ? renderedCellValue
                .toString()
                .split(",")
                .map((value) => value.trim())
            : [];

          const filteredValues = values.filter((value) =>
            value.toLowerCase().includes(searchTerm.toLowerCase())
          );

          return (
            <>
              <Box display="flex">
                <Flex align="center" variant="filled" gap={5}>
                  {values.slice(0, 1).map((value, index) => (
                    <Badge
                      key={index}
                      size="lg"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataIn(
                          value,
                          "NHAP_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT",
                          true // status === "LOCK"
                        );
                      }}
                      variant="default"
                      fw={600}
                    >
                      {value}
                    </Badge>
                  ))}
                  {values.length > 1 && (
                    <ActionIcon
                      onClick={() => setOpened(true)}
                      color="blue"
                      radius="xl"
                      variant="light"
                      aria-label="Settings"
                    >
                      <IconDots size="1.2rem" />
                    </ActionIcon>
                  )}
                </Flex>
              </Box>
              <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                size="550"
                title={<Title order={5}>Danh sách mã phiếu</Title>}
              >
                <TextInput
                  placeholder="Tìm kiếm mã phiếu..."
                  value={searchTerm}
                  radius="xl"
                  mt={10}
                  leftSection={<IconSearch size="1rem" />}
                  onChange={(event) => setSearchTerm(event.currentTarget.value)}
                  mb={10}
                  w={170}
                />
                <Flex gap={10} mt={20} wrap="wrap">
                  {filteredValues.map((value, index) => (
                    <Badge
                      size="lg"
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataIn(
                          value,
                          "NHAP_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT",
                          true
                        );
                        setOpened(false);
                      }}
                      variant="default"
                      fw={600}
                    >
                      {value}
                    </Badge>
                  ))}
                  {filteredValues.length === 0 && (
                    <Text>Không tìm thấy kết quả</Text>
                  )}
                </Flex>
              </Modal>
            </>
          );
        },
      },
      {
        accessorKey: "attribute5",
        enableColumnActions: false,
        header: "Mã phiếu xuất linh kiện",
        Cell: ({ cell, renderedCellValue }) => {
          const [opened, setOpened] = useState(false);
          const [searchTerm, setSearchTerm] = useState("");
          const values = renderedCellValue
            ? renderedCellValue
                .toString()
                .split(",")
                .map((value) => value.trim())
            : [];

          const filteredValues = values.filter((value) =>
            value.toLowerCase().includes(searchTerm.toLowerCase())
          );

          return (
            <>
              <Box display="flex">
                <Flex align="center" variant="filled" gap={5}>
                  {values.slice(0, 1).map((value, index) => (
                    <Badge
                      key={index}
                      size="lg"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataOut(
                          value,
                          "XUAT_LINH_KIEN_SAN_XUAT",
                          true // status === "LOCK"
                        );
                      }}
                      variant="default"
                      fw={600}
                    >
                      {value}
                    </Badge>
                  ))}
                  {values.length > 1 && (
                    <ActionIcon
                      onClick={() => setOpened(true)}
                      color="blue"
                      radius="xl"
                      variant="light"
                      aria-label="Settings"
                    >
                      <IconDots size="1.2rem" />
                    </ActionIcon>
                  )}
                </Flex>
              </Box>
              <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                size="550"
                title={<Title order={5}>Danh sách mã phiếu </Title>}
              >
                <TextInput
                  placeholder="Tìm kiếm mã phiếu..."
                  value={searchTerm}
                  radius="xl"
                  mt={10}
                  leftSection={<IconSearch size="1rem" />}
                  onChange={(event) => setSearchTerm(event.currentTarget.value)}
                  mb={10}
                  w={170}
                />
                <Flex gap={10} mt={20} wrap="wrap">
                  {filteredValues.map((value, index) => (
                    <Badge
                      size="lg"
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataOut(value, "XUAT_LINH_KIEN_SAN_XUAT", true);
                        setOpened(false);
                      }}
                      variant="default"
                      fw={600}
                    >
                      {value}
                    </Badge>
                  ))}
                  {filteredValues.length === 0 && (
                    <Text>Không tìm thấy kết quả</Text>
                  )}
                </Flex>
              </Modal>
            </>
          );
        },
      },
      {
        accessorKey: "attribute11",
        enableColumnActions: false,
        header: "Mã Kho nhập TP",
        Cell: ({ cell, renderedCellValue }) => {
          const formattedValue = renderedCellValue
            ? renderedCellValue.toString().split(",").join(", ")
            : "";
          return (
            <Tooltip label={formattedValue}>
              <Box w={100}>
                <Text fw={600} size="sm" truncate="end">
                  {formattedValue}
                </Text>
              </Box>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "attribute6",
        enableColumnActions: false,
        header: "Mã phiếu nhập TP",
        size: 150,
        Cell: ({ cell, renderedCellValue }) => {
          const [opened, setOpened] = useState(false);
          const [searchTerm, setSearchTerm] = useState("");
          const values = renderedCellValue
            ? renderedCellValue
                .toString()
                .split(",")
                .map((value) => value.trim())
            : [];

          const filteredValues = values.filter((value) =>
            value.toLowerCase().includes(searchTerm.toLowerCase())
          );

          return (
            <>
              <Box display="flex">
                <Flex align="center" variant="filled" gap={5}>
                  {values.slice(0, 1).map((value, index) => (
                    <Badge
                      key={index}
                      size="lg"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataIn(
                          value,
                          "NHAP_THANH_PHAM_SAN_XUAT",
                          true // status === "LOCK"
                        );
                      }}
                      variant="default"
                      fw={600}
                    >
                      {value}
                    </Badge>
                  ))}
                  {values.length > 1 && (
                    <ActionIcon
                      onClick={() => setOpened(true)}
                      color="blue"
                      radius="xl"
                      variant="light"
                      aria-label="Settings"
                    >
                      <IconDots size="1.2rem" />
                    </ActionIcon>
                  )}
                </Flex>
              </Box>
              <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                size="550"
                title={<Title order={5}>Danh sách mã phiếu </Title>}
              >
                <TextInput
                  placeholder="Tìm kiếm mã phiếu..."
                  value={searchTerm}
                  radius="xl"
                  mt={10}
                  leftSection={<IconSearch size="1rem" />}
                  onChange={(event) => setSearchTerm(event.currentTarget.value)}
                  mb={10}
                  w={170}
                />
                <Flex gap={10} mt={20} wrap="wrap">
                  {filteredValues.map((value, index) => (
                    <Badge
                      size="lg"
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        detailDataIn(value, "NHAP_THANH_PHAM_SAN_XUAT", true);
                        setOpened(false);
                      }}
                      variant="default"
                      fw={600}
                    >
                      {value}
                    </Badge>
                  ))}
                  {filteredValues.length === 0 && (
                    <Text>Không tìm thấy kết quả</Text>
                  )}
                </Flex>
              </Modal>
            </>
          );
        },
      },
      // {
      //   accessorKey: "makhach",
      //   enableColumnActions: false,
      //   header: "",
      //   Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      // },
      {
        accessorKey: "ngayXuat",
        enableColumnActions: false,
        header: "Ngày xuất",
        size: 150,
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
      },
      {
        accessorKey: "ngLpx",
        enableColumnActions: false,
        header: "Người lập PX",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maKh",
        enableColumnActions: false,
        header: "Mã khách",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenKh",
        enableColumnActions: false,
        header: "Tên khách",
        size: 300,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "trangThai",
        enableColumnActions: false,
        header: "Trạng thái",
        minSize: 240,
        maxSize: 240,
        Cell: ({ renderedCellValue }) => {
          const displayValue = (() => {
            switch (renderedCellValue) {
              case "DIEU_CHUYEN_HOAN_THANH":
                return "Điều chuyển hoàn thành";
              case "DANG_LAP_RAP":
                return "Đang lắp ráp";
              case "XUAT_DIEU_CHUYEN":
                return "Xuất điều chuyển";
              case "DANG_XUAT_LINH_KIEN":
                return "Đang xuất linh kiện";
              case "HOAN_THANH":
                return "Hoàn thành";
              case "DA_XUAT_LINH_KIEN":
                return "Đã xuất linh kiện";
              case "TAO_MOI":
                return "Tạo mới";
              case "NHAP_DIEU_CHUYEN":
                return "Nhập điều chuyển";
              default:
                return null;
            }
          })();

          const badgeColor = (() => {
            switch (displayValue) {
              case "Điều chuyển hoàn thành":
                return "cyan";
              case "Đang lắp ráp":
                return "#15aabf";
              case "Xuất điều chuyển":
                return "orange";
              case "Đang xuất linh kiện":
                return "yellow";
              case "Đã xuất linh kiện":
                return "blue";
              case "Tạo mới":
                return "gray";
              case "Nhập điều chuyển":
                return "violet";
              case "Hoàn thành":
                return "green";
              default:
                return "gray";
            }
          })();

          return (
            <>
              {displayValue === null ? null : (
                <Badge color={badgeColor}>{displayValue}</Badge>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "action",
        enableColumnActions: false,
        header: "Thao tác",
        size: 170,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {/* <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={openDeleteModal(row.original.idLsx)}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip> */}
            <Tooltip label="Đổi linh kiện">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="#a9e34b"
                bd="1px solid #a9e34b"
                onClick={openAccessory(row.original.maLsx)}
              >
                <IconSection stroke={2} color="#000" size={20} />
              </ActionIcon>
            </Tooltip>
            <Divider size="sm" orientation="vertical" />
            <Tooltip
              label={
                !row.original.maPx
                  ? "Chưa có mã phiếu xuất kho"
                  : "Xuất điều chuyển linh kiện sản xuất"
              }
            >
              <ActionIcon
                variant="light"
                disabled={
                  !row.original.maPx ||
                  row.original.trangThai === "NHAP_DIEU_CHUYEN" ||
                  row.original.trangThai === "DANG_XUAT_LINH_KIEN" ||
                  row.original.trangThai === "DANG_LAP_RAP" ||
                  row.original.trangThai === "DIEU_CHUYEN_HOAN_THANH" ||
                  row.original.trangThai === "DA_XUAT_LINH_KIEN" || 
                  row.original.trangThai === "HOAN_THANH"

                }
                onClick={() => {
                  if (row.original.maPx) {
                    createOutward(row?.original?.maPx);
                  } else {
                    NotificationExtension.Fails("Chưa có mã phiếu xuất kho");
                  }
                }}
              >
                <IconFilePlus size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xuất linh kiện sản xuất">
              <ActionIcon
                variant="light"
                color="orange"
                disabled={
                  !row.original.maPx ||
                  row.original.trangThai === "XUAT_DIEU_CHUYEN" ||
                  row.original.trangThai === "DA_XUAT_LINH_KIEN" || 
                   row.original.trangThai === "HOAN_THANH"
                }
                onClick={() => {
                  if (
                    row.original.maLsx !== null &&
                    row.original.maLsx !== undefined
                  ) {
                    createOutwarDaccessory(row.original.maLsx);
                  }
                }}
              >
                <IconTransferOut size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label={"Nhập thành phẩm"}>
              <ActionIcon
                variant="light"
                color="green"
                disabled={
                  !row.original.maLsx ||
                  !row.original.maPx ||
                  row.original.trangThai === "XUAT_DIEU_CHUYEN" ||
                  row.original.trangThai === "NHAP_DIEU_CHUYEN" ||
                  row.original.trangThai === "HOAN_THANH" ||
                  row.original.trangThai === "DIEU_CHUYEN_HOAN_THANH" || 
                  String(row?.original?.attribute1) === 
                    String(row.original.attribute12) 
                }
                onClick={() => {
                  if (row.original.maLsx) {
                    createInward(row?.original?.maLsx);
                  } else {
                    NotificationExtension.Fails("Chưa có mã lệnh sản xuất");
                  }
                }}
              >
                <IconTransferIn size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const detailItem = (id: string | number) => {
    navigate("/warehouse-config/detail-wip", { state: { id } });
  };
  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-lenh-san-xuat.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const createOutward = (sourceCode: string | null) => {
    navigate("/warehouse-config/create-warehouse-out-ward", {
      state: {
        sourceCode: sourceCode,
        sourceType: "XUAT_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT",
      },
    });
  };

  const createInward = (sourceCode: string | null) => {
    navigate("/warehouse-config/create-warehouse-in-ward", {
      state: {
        sourceCode: sourceCode,
        sourceType: "NHAP_THANH_PHAM_SAN_XUAT",
      },
    });
  };

  const createOutwarDaccessory = (sourceCode: string | null) => {
    if (!sourceCode) {
      return;
    }
    navigate("/warehouse-config/create-warehouse-out-ward", {
      state: {
        sourceCode: sourceCode,
        sourceType: "XUAT_LINH_KIEN_SAN_XUAT",
      },
    });
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${encodeURIComponent(search.keySearch)}`;
    }
    if (search.StartDate) {
      url += `&FromDate=${encodeURIComponent(search.StartDate)}`;
    }
    if (search.BranchId) {
      url += `&BranchId=${encodeURIComponent(search.BranchId)}`;
    }
    if (search.EndDate) {
      url += `&ToDate=${encodeURIComponent(search.EndDate)}`;
    }
    if (search.status) {
      url += `&Active=${search.status}`;
    }

    let sortingQuery = "";
    if (sorting && sorting.length > 0) {
      const { id, desc } = sorting[0] || {};
      if (id) {
        sortingQuery = `&Sort=${encodeURIComponent(id)}&isOrder=${desc}`;
      }
    }
    url = url.replace("?&", "?");
    url += sortingQuery;

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblMfgProductOrder/get-list${url}`
      );
      if (response && response.success) {
        const result = response.data || [];
        setData(result);

        if (result.length === 0) {
          setRowCount(0);
        } else {
          setRowCount(response.totalCount);
        }
        setSelectIds([]);
        table.resetRowSelection();
      } else {
        setData([]);
        setRowCount(0);
      }
    } catch (error) {
      setIsError(true);
      setData([]);
      setRowCount(0);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  // Hàm gọi API chi nhánh
  const fetchDataBranch = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmInventory/get-select-branch"
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.slice(1);
      setDataBranch(filteredData);
    }
  };

  useEffect(() => {
    fetchDataBranch();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  const table = useMantineReactTable<ProductionOrder>({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id?.toString(),

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["maLsx", "id"],
        right: ["trangThai", "action"],
      },
      columnVisibility: { id: true },
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height + 50, minHeight: height + 50 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "550", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    if (pagination.pageIndex === 0 && data.length < pagination.pageSize) {
      fetchData();
    } else {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          {/* <Flex gap="md" align="center" direction="row" wrap="wrap">
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex> */}
        </Flex>

        <Grid mt={10}>
          <GridCol span={sizeGrid}>
            {/* <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            /> */}
            <InputSearch
              width="100%"
              placeholder="Từ khoá"
              onSearch={fetchData}
              onChange={(value) => {
                handleChangeSearchValue(value, "keySearch");
              }}
            />
          </GridCol>

          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              type="default"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              size="sm"
              placeholder="Từ ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                const startDateISO = e ? e.toLocaleDateString() : null;
                handleChangeSearchValue(startDateISO ?? "", "StartDate");
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              size="sm"
              placeholder="Đến ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                const startDateISO = e ? e.toLocaleDateString() : null;
                handleChangeSearchValue(startDateISO ?? "", "EndDate");
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              searchable
              clearable
              placeholder="Chi nhánh"
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              limit={50}
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              data={dataBranch.map((item) => {
                return {
                  label: item.text ?? "",
                  value: String(item.value ?? ""),
                };
              })}
              onClick={async () => {
                if (!dataBranch || dataBranch.length < 1) {
                  await fetchDataBranch();
                }
                return;
              }}
              onChange={(value) => {
                handleChangeSearchValue(value ?? "", "BranchId");
              }}
            />
          </Grid.Col>
          <GridCol span={sizeGrid}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default MfgWip;
