import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  rem,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconEye, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { MessageResponse } from "../../model/MessageResponse";
import { repositoryMaster } from "../../_base/_const/_constVar";
import {
  _validateMenuValidator,
  _validateUserMasterModelValidator,
} from "../../validate/UserCreateModelValidator";
import { ListAuthozireModel } from "../../model/ListAuthozireModel";
import { getTblDmEmployeeSelect } from "../../service/getSelectApi";
import { cobalt_blue, gray, sky_blue } from "../../const/variables";
import { UserMenuGroup } from "../../model/UserMenuGroup";

const UserCreate = function ({ onLoad }: { onLoad: () => Promise<void> }) {
  const entity: UserMenuGroup = {
    id: 0,
    name: "",
  };
  const form = useForm<UserMenuGroup>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: _validateMenuValidator,
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const apiCreate = async (data: UserMenuGroup) => {
    console.log(data);
    open();
    data.id = 0;
    let urlCreate = `/api/v1/menugroup/create`;
    const callapi = await repositoryMaster.post(urlCreate, data);
    if (callapi)
      if (callapi?.success) {
        modals.closeAll();
        await onLoad();
        NotificationExtension.Success("Thêm mới thành công !");
      } else if (callapi != null)
        NotificationExtension.Fails("Thêm mới thất bại !");
    close();
  };
  useEffect(() => {
    callApiGetData();
  }, []);
  const [showPass, SetShowPass] = useState<boolean>(false);
  let typePass = showPass ? "text" : "password";
  let colorEye = showPass ? cobalt_blue.base : gray.base;
  const icon = (
    <IconEye
      onClick={() => {
        SetShowPass(!showPass);
      }}
      style={{ width: rem(20), height: rem(20), color: colorEye }}
    />
  );

  const callApiGetData = async () => {
    open();
    let urlCreate = `/api/v1/menugroup/create`;
    const callapi = await repositoryMaster.get<MessageResponse<UserMenuGroup>>(
      urlCreate
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };
  return (
    <>
      <Box
        component="form"
        onSubmit={form.onSubmit(async (e: UserMenuGroup) => {
          await apiCreate(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Box className="flex-none" component="form" mx="auto">
          <Grid>
            <Grid.Col span={{ base: 12 }}>
              <TextInput
                label="Tên nhóm: "
                placeholder="Nhập..."
                withAsterisk
                {...form.getInputProps("name")}
              />
            </Grid.Col>
          </Grid>
          <Checkbox
            mt={"xs"}
            label="Trạng thái"
            defaultValue={form.getValues().active ?? false}
            defaultChecked={form.getValues().active ?? false}
            {...form.getInputProps("inActive")}
          />
        </Box>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default UserCreate;
