import "./tab.component.css";

import { Paper, Tabs } from "@mantine/core";
import { useState } from "react";
import DMPaymentMethod from "./DMPaymentMethod/DMPaymentMethod";
import DMTaxCode from "./DMTaxCode/DMTaxCode";
import DMReturnRate from "./DMReturnRate/DMReturnRate";
import DMExpense from "./DMExpense/DMExpense";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";

const Payment = () => {
  const [activeTab, setActiveTab] = useState<string | null>("paymentmethod");

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Paper shadow="md" radius="lg">
        <Tabs p={"xs"} value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="paymentmethod">
              Danh mục hình thức thanh toán
            </Tabs.Tab>
            <Tabs.Tab value="taxcode">Danh mục biểu mẫu thuế</Tabs.Tab>
            <Tabs.Tab value="returnrate">Danh mục định mức nhập lại</Tabs.Tab>
            <Tabs.Tab value="expense">Danh mục chi phí</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="paymentmethod">
            {activeTab === "paymentmethod" ? <DMPaymentMethod /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="taxcode">
            {activeTab === "taxcode" ? <DMTaxCode /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="returnrate">
            {activeTab === "returnrate" ? <DMReturnRate /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="expense">
            {activeTab === "expense" ? <DMExpense /> : <></>}
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </>
  );
};

export default Payment;
