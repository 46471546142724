import { FormValidateInput } from "@mantine/form/lib/types";
import { hasLength, isNotEmpty } from "@mantine/form";
import { ListAuthorizeCommand } from "../model/ListAuthorizeCommand";
import { TblUserGroupCommand } from "../model/TblUserGroupCommand";

export const _validate: FormValidateInput<ListAuthorizeCommand> = {
  name: (value: string | null) => {
    if (!value) {
      return "Vui Lòng Nhập Tên nhóm!";
    }
    return hasLength(
      { min: 2, max: 150 },
      "Tên phải từ 2-150 kí tự!"
    )(value as string);
  }
};


export const _validateGroupuser: FormValidateInput<TblUserGroupCommand> = {
  name: (value: string | null) => {
    if (!value) {
      return "Vui Lòng Nhập Tên nhóm!";
    }
    return hasLength(
      { min: 2, max: 150 },
      "Tên phải từ 2-150 kí tự!"
    )(value as string);
  }
};