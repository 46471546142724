import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Input,
  Menu,
  Select,
  SelectProps,
  Text,
  Tooltip,
  rem,
} from "@mantine/core";
import {
  IconBuildingStore,
  IconCalendar,
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconLocationFilled,
  IconPlus,
  IconSearch,
  IconStarFilled,
  IconUsers,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { DatePickerInput } from "@mantine/dates";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { green, teal } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDMExpense } from "../../../model/TblDMExpense";
import {
  getBranchSelectMdm,
  getTblDmProcessingMethodSelect,
  getTblDmSaleChanelSelect,
} from "../../../service/getSelectApi";
import CreateDataView from "./CreateDataView";
import DetailDataView from "./DetailDataView";
import EditDataView from "./EditDataView";
import { getValueById } from "../../../_base/helper/FunctionHelper";

const ReceptionList = () => {
  //#region State
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMExpense[]>([]);
  const [dataTemp, setDataTemp] = useState<TblDMExpense[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [idEdit, setIdEdit] = useState(0);
  const [idDetail, setIdDetail] = useState(0);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [dataTblBranch, setDataTblBranch] = useState<ComboboxItem[]>([]);
  const [dataWarrantyCustomerGroup, setDataWarrantyCustomerGroup] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblDmRequestStatus, setDataTblDmRequestStatus] = useState<{
    isLoad: boolean;
    data: any[];
  }>({
    isLoad: false,
    data: [
      { label: "Chờ phân công", value: "Chờ phân công" },
      { label: "Đã phân công", value: "Đã phân công" },
      { label: "Hủy phân công", value: "Hủy phân công" },
      { label: "Đang xử lý", value: "Đang xử lý" },
      { label: "Đã xử lý", value: "Đã xử lý" },
      { label: "Hãng xử lý", value: "Hãng xử lý" },
      { label: "Hãng đang xử lý", value: "Hãng đang xử lý" },
      { label: "Hãng đã xử lý", value: "Hãng đã xử lý" },
      { label: "Xử lý lại", value: "Xử lý lại" },
      { label: "Kỹ thuật đã xử lý", value: "Kỹ thuật đã xử lý" },
      { label: "Chờ phiếu BN", value: "Chờ phiếu BN" },
      { label: "Nhập kho BH", value: "Nhập kho BH" },
      { label: "Trả khách", value: "Trả khách" },
    ],
  });

  const [dataTblProcessingMethod, setDataTblProcessingMethod] = useState<{
    isLoad: boolean;
    data: any[];
  }>({
    isLoad: false,
    data: [],
  });

  const [dataTblSaleChannel, setDataTblSaleChannel] = useState<{
    isLoad: boolean;
    data: any[];
  }>({
    isLoad: false,
    data: [],
  });

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  // Search
  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    status: "",
    keySearch: "",
    processingMethod: "",
    channel: "",
    customerGroup: "",
  });

  // Grid span
  const gridSpan = { base: 12, sm: 6, md: 4, lg: 4, xl: 4 };

  //viewState
  const [actionView, setActionView] = useState("");

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "createDate",
        header: "Ngày tiếp nhận",
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px" fw={"500"}>
            {renderedCellValue && formatDateTransfer(renderedCellValue)}
          </Text>
        ),
        size: 30,
        enableColumnFilter: false,
        enableColumnActions: false,
      },
      {
        accessorKey: "requestNum",
        header: "Mã phiếu tiếp nhận yêu cầu",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailItem(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        size: 30,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute10",
        header: "Số phiếu tiếp nhận yêu cầu",
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null
              ? "Không có số phiếu"
              : renderedCellValue}
          </Badge>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "createByName",
        header: "Người tiếp nhận",
        Cell: ({ row }: any) => (
          <AvatarUtils value={row.original.createByName} />
        ),
        size: 30,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "channelName",
        header: "Kênh",
        size: 50,
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px" fw={"500"}>
            {renderedCellValue}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "customerGroupName",
        header: "Nhóm khách hàng",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "processingMethodName",
        header: "Hình thức xử lý",
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Badge
            color={getColorProcessingMethod(row.original?.processingMethodName)}
          >
            {renderedCellValue}
          </Badge>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "meetingAddress",
        header: "Địa chỉ",
        size: 100,
        Cell: ({ renderedCellValue, row }: any) => (
          <Text fw={"500"} size="12.5px">
            {row.original.additionalAddress !== null
              ? `${row.original.additionalAddress} - ${renderedCellValue}`
                  .replace(/\s*-\s*-\s*/g, " - ")
                  .replace(/^\s*-\s*/, "")
              : `${renderedCellValue}`.replace(/^\s*-\s*/, "")}{" "}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "requestStatusName",
        header: "Trạng thái",
        Cell: ({ row, renderedCellValue }) => (
          <>
            <Badge
              radius={"sm"}
              color={getColorStatus(row.original.requestStatusName?.toString())}
              variant="light"
              bd={`1px solid ${getColorStatus(
                row.original.requestStatusName?.toString()
              )}`}
            >
              {renderedCellValue ?? "Không xác định"}
            </Badge>
          </>
        ),
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "branchId",
        header: "Nơi nhận yêu cầu",
        Cell: ({ row }: any) => (
          <Text size="12.5px" variant="light" fw={"700"}>
            {getValueById(
              row.original.branchId?.toString() ?? "",
              dataTblBranch,
              "label"
            )}
          </Text>
        ),
        size: 30,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "content",
        header: "Nội dung",
        size: 250,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={() => {
                  editItem(row.original.id);
                }}
                disabled={
                  row.original.requestStatusName !== "Chờ phân công" &&
                  row.original.requestStatusName !== "Đã phân công"
                }
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xem chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="blue"
                onClick={() => detailItem(row.original.id)}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataTblBranch]
  );

  function getColorStatus(status: string) {
    switch (status) {
      case "Đã phân công":
      case "Đã xử lý":
      case "Hãng đã xử lý":
      case "Trả khách":
        return "#4CAF50";

      case "Chờ phân công":
      case "Xử lý lại":
      case "Hãng đang xử lý":
      case "Chưa xử lý":
        return "#d9d02f";

      case "Hủy phân công":
      case "Kỹ thuật hủy":
        return "#F44336";

      case "Hãng xử lý":
        return "#2196F3";

      case "Đang xử lý":
        return "#FF9800";

      case "":
      case null:
        return "rgba(255, 255, 255, 0)";

      default:
        return "#424242";
    }
  }

  function getColorProcessingMethod(value: string) {
    switch (value) {
      case "Xử lý tại nhà":
        return "lime";
      case "Hãng xử lý":
        return "green";
      case "Xử ý trực tiếp":
        return "teal";
      case "Xử lý tại CH/CN":
        return "cyan";
      case "Xử lý từ xa":
        return "orange";
    }
  }

  const handleRangeChange = (dates: [Date | null, Date | null]) => {
    setDateRange(dates);
    const [startDate, endDate] = dates;
    const startDateISO = startDate ? startDate.toISOString() : null;
    const endDateISO = endDate ? endDate.toISOString() : null;
    if (startDateISO) {
      handleChangeSearchValue(startDateISO, "startDate");
    } else {
      handleChangeSearchValue("", "startDate");
    }
    if (endDateISO) {
      handleChangeSearchValue(endDateISO, "endDate");
    } else {
      handleChangeSearchValue("", "endDate");
    }
  };

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-tiep-nhan-bao-hanh.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  function getNextDate(dateString: string) {
    const parsedDate = new Date(dateString || new Date().toUTCString());
    const nextDate = new Date(parsedDate.setDate(parsedDate.getDate() + 1));
    return nextDate;
  }

  const getAllRequestStatus = async () => {
    const url = "api/v1/TblDmRequestStatus/get-all";
    const callapi = await repositoryMdm.get<MessageResponse<any[]>>(url);
    if (callapi?.success) return callapi?.data;
    return [];
  };

  // const dataRequestStatusSelect = async () => {
  //   try {
  //     const getData = await getAllRequestStatus();
  //     const filteredData = getData?.filter(
  //       (item) => item.name != null && item.name != null
  //     );
  //     const mappedData = filteredData?.map((item) => ({
  //       value: item.name.toString(),
  //       label: item.name.toString(),
  //     }));
  //     setDataTblDmRequestStatus((prev) => ({
  //       ...prev,
  //       data: mappedData,
  //     }));
  //   } catch (error) {
  //     console.error("Error in dataDeliveryStatusSelect: ", error);
  //   }
  // };

  const dataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataTblBranch(
      getData
        ?.filter(
          (item) =>
            item.value != null && item.text != null && item.value != "-1"
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataWarrantyCustomerGroup = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblWarrantyCustomerGroup/get-all"
    );

    if (res && res?.success) {
      const getData = res.data;
      const filteredData = getData?.filter(
        (item: any) => item.text != null && item.value != null
      );
      const mappedData = filteredData?.map((item: any) => ({
        value: item.value?.toString(),
        label: item.text,
      }));
      setDataWarrantyCustomerGroup(mappedData);
    }
  };

  const saleChannelSelect = async () => {
    try {
      const getData = await getTblDmSaleChanelSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.text?.toString(),
        label: item.text?.toString(),
      }));
      setDataTblSaleChannel((prev) => ({
        ...prev,
        data: mappedData,
      }));
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const dataProcessingMethodSelect = async () => {
    try {
      const getData = await getTblDmProcessingMethodSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblProcessingMethod((prev) => ({
        ...prev,
        data: mappedData,
      }));
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.startDate) {
      url += `&StartDate=${encodeURIComponent(
        getNextDate(search.startDate).toISOString()
      )}`;
    }

    if (search.endDate) {
      url += `&EndDate=${encodeURIComponent(
        getNextDate(search.endDate).toISOString()
      )}`;
    }

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim()}`;
    }

    if (search.status) {
      url += `&RequestStatus=${search.status}`;
    }

    if (search.channel) {
      url += `&Channel=${search.channel}`;
    }

    if (search.processingMethod) {
      url += `&ProcessingMethod=${search.processingMethod}`;
    }

    if (search.customerGroup) {
      url += `&CustomerGroup=${search.customerGroup}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyServiceRequestForm/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        await Promise.all([dataBranchSelect()]);
        setData(result);
        setDataTemp(result);
        setRowCount(response.totalCount ?? 0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  const ActionViews = React.useCallback(() => {
    switch (actionView) {
      case "CREATE":
        return (
          <Box h={window.innerHeight - 180}>
            <CreateDataView
              onClose={setActionView}
              onSuccess={setDeleteViewStatus}
            />
          </Box>
        );
      case "EDIT":
        return (
          <Box h={window.innerHeight - 180}>
            <EditDataView
              id={idEdit}
              onClose={setActionView}
              onSuccess={setDeleteViewStatus}
            />
          </Box>
        );
      case "DETAIL":
        return (
          <Box h={window.innerHeight - 180}>
            <DetailDataView
              id={idDetail}
              onClose={setActionView}
              actionView={actionView}
            />
          </Box>
        );
      default:
        return null;
    }
  }, [actionView]);

  const createItem = React.useCallback(() => {
    setActionView("CREATE");
  }, []);

  const editItem = React.useCallback((id: string | number) => {
    setActionView("EDIT");
    setIdEdit(Number(id));
  }, []);

  const detailItem = React.useCallback((id: string | number) => {
    setActionView("DETAIL");
    setIdDetail(Number(id));
  }, []);

  const optionColorsAccount: Record<
    | "Xử lý tại nhà"
    | "Hãng xử lý"
    | "Xử lý tại chỗ"
    | "Xử lý tại CH/CN"
    | "Xử lý từ xa",
    string
  > = {
    "Xử lý tại nhà": "lime",
    "Hãng xử lý": "green",
    "Xử lý tại chỗ": "teal",
    "Xử lý tại CH/CN": "cyan",
    "Xử lý từ xa": "orange",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsAccount[
            option.label as
              | "Xử lý tại nhà"
              | "Hãng xử lý"
              | "Xử lý tại chỗ"
              | "Xử lý tại CH/CN"
              | "Xử lý từ xa"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination, deleteViewStatus]);

  // useEffect(() => {
  //   if (
  //     dataTblDmRequestStatus.isLoad &&
  //     dataTblDmRequestStatus.data?.length === 0
  //   )
  //     dataRequestStatusSelect();
  // }, [dataTblDmRequestStatus.isLoad]);

  useEffect(() => {
    if (
      dataTblProcessingMethod.isLoad &&
      dataTblProcessingMethod.data?.length === 0
    )
      dataProcessingMethodSelect();
  }, [dataTblProcessingMethod.isLoad]);

  useEffect(() => {
    if (dataTblSaleChannel.isLoad && dataTblSaleChannel.data?.length === 0)
      saleChannelSelect();
  }, [dataTblSaleChannel.isLoad]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (140 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },

    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "requestNum"],
        right: ["attribute10", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      onDoubleClick: () => {
        detailItem(row?.original?.id);
      },
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion
  return (
    <>
      {(!actionView.isNullOrEmpty() && <ActionViews />) || (
        <>
          <div
            ref={headerRef}
            style={{
              width: "100%",
              border: "1px solid #dee2e6",
              padding: "10px",
            }}
          >
            <Flex
              w={"100%"}
              align={"center"}
              justify={"space-between"}
              direction="row"
              wrap="wrap"
              mb={"sm"}
            >
              <_breadcrumb></_breadcrumb>
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  leftSection={<IconPlus size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    createItem();
                  }}
                >
                  Thêm mới
                </Button>
                {/* <Button
                  leftSection={<IconEdit size={14} />}
                  px={"5 10"}
                  color="orange"
                  variant="outline"
                  onClick={async () => {
                    await editItem(selectIds[0]);
                  }}
                  disabled={selectIds.length !== 1}
                >
                  Sửa (đã chọn)
                </Button> */}
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Button
                      // size="xs"
                      rightSection={
                        <IconCaretDown
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                    >
                      Chức năng
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      leftSection={
                        <IconFileExport
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                      onClick={handleExport}
                    >
                      Export Excel
                    </Menu.Item>
                    <Tooltip label={"Chức năng đang update"}>
                      <Menu.Item
                        leftSection={
                          <IconFileUpload
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                      >
                        Import Excel
                      </Menu.Item>
                    </Tooltip>
                  </Menu.Dropdown>
                </Menu>
              </Flex>
            </Flex>
            <Grid w={"100%"}>
              <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
                <DatePickerInput
                  type="range"
                  size="sm"
                  placeholder="Chọn khoảng ngày"
                  locale="vi"
                  value={dateRange}
                  w={"100%"}
                  leftSection={
                    <IconCalendar
                      size={20}
                      style={{ color: teal.base }}
                    ></IconCalendar>
                  }
                  valueFormat="DD/MM/YYYY"
                  onChange={handleRangeChange}
                  clearable
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
                <Select
                  placeholder="Trạng thái"
                  searchable
                  clearable
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  leftSection={
                    <IconStarFilled
                      size={20}
                      style={{ color: teal.base }}
                    ></IconStarFilled>
                  }
                  w={"100%"}
                  data={dataTblDmRequestStatus.data}
                  onClick={() =>
                    setDataTblDmRequestStatus((prev) => ({
                      ...prev,
                      isLoad: true,
                    }))
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e?.toString() ?? "", "status");
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
                <Select
                  placeholder="Hình thức xử lý"
                  type="text"
                  w={"100%"}
                  clearable
                  searchable
                  leftSection={
                    <IconLocationFilled
                      size={20}
                      style={{ color: teal.base }}
                    ></IconLocationFilled>
                  }
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={dataTblProcessingMethod.data}
                  onClick={() =>
                    setDataTblProcessingMethod((prev) => ({
                      ...prev,
                      isLoad: true,
                    }))
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(
                      e?.toString() ?? "",
                      "processingMethod"
                    );
                  }}
                  renderOption={renderSelectOptionStatus}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
                <Select
                  placeholder="Kênh"
                  type="text"
                  w={"100%"}
                  clearable
                  searchable
                  leftSection={
                    <IconBuildingStore
                      size={20}
                      style={{ color: teal.base }}
                    ></IconBuildingStore>
                  }
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={dataTblSaleChannel.data}
                  onClick={() =>
                    setDataTblSaleChannel((prev) => ({
                      ...prev,
                      isLoad: true,
                    }))
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e?.toString() ?? "", "channel");
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
                <Select
                  placeholder="Nhóm khách hàng"
                  data={dataWarrantyCustomerGroup}
                  searchable
                  clearable
                  leftSection={
                    <IconUsers size={20} style={{ color: teal.base }} />
                  }
                  onClick={() => {
                    if (dataWarrantyCustomerGroup.length === 0) {
                      getDataWarrantyCustomerGroup();
                    }
                  }}
                  onChange={(e) => {
                    handleChangeSearchValue(
                      e?.toString() ?? "",
                      "customerGroup"
                    );
                  }}
                  nothingFoundMessage="Không tìm thấy dữ liệu !"
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
                <Input
                  placeholder="Nhập từ khóa tìm kiếm"
                  type="text"
                  w={"100%"}
                  leftSection={
                    <IconSearch
                      size={20}
                      style={{ color: teal.base }}
                    ></IconSearch>
                  }
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      fetchData();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4, lg: 2.4 }}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </div>
          <div>
            <MantineReactTable table={table} />
          </div>
        </>
      )}
    </>
  );
};
export default ReceptionList;
