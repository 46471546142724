import { ActionIcon, Badge, Box, Flex, Tooltip } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import { _timerDelayClick, repositoryMdm } from "../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../_base/component/_layout/_breadcrumb";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { _localization } from "../../config/location";
import { green } from "../../const/variables";
import { SelectListItem } from "../../model/SelectListItem";
import { PaginationState } from "@ag-grid-community/core";

interface Vendor {
  id: number;
  orgId: number | null;
  vendorName: string;
  vendorCode: string;
  enabledFlag: string;
  startDateActive: string; // ISO date string
  endDateActive: string | null; // ISO date string or null
  vendorTypeLookupCode: string | null;
  employeeId: number | null;
  email: string | null;
  phone: string | null;
  addr: string;
  creditLimit: number | null;
  vatRegistrationNum: string;
  createdBy: number | null;
  creationDate: string | null; // ISO date string or null
}

const ListDebtVendor = () => {
  const navigate = useNavigate();
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<Vendor[]>([]);
  const [allData, setAllData] = useState<Vendor[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  //count

  const [search, setSearch] = useState({
    keySearch: "",
    StartDate: "",
    BranchId: "",
    EndDate: "",
    status: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<Vendor>[]>(
    () => [
      {
        accessorKey: "vendorCode",
        enableColumnActions: false,
        header: "Mã nhóm công nợ",
        size: 130,
        Cell: ({ renderedCellValue, row }: any) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "vendorName",
        enableColumnActions: false,
        header: "Tên nhóm công nợ",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "startDateActive",
        enableColumnActions: false,
        header: "Ngày bắt đầu",
        size: 100,
        Cell: ({ renderedCellValue }) => (
          <>
            {renderedCellValue &&
              formatDateTransfer(renderedCellValue as string)}
          </>
        ),
      },
      {
        accessorKey: "addr",
        enableColumnActions: false,
        header: "Địa điểm",
        Cell: ({ renderedCellValue }) => (
          <>{renderedCellValue && renderedCellValue}</>
        ),
      },
      {
        accessorKey: "phone",
        enableColumnActions: false,
        header: "Số điện thoại",
        Cell: ({ renderedCellValue, row }: any) => {
          return <>{renderedCellValue === null ? null : renderedCellValue}</>;
        },
      },
      // {
      //   accessorKey: "action",
      //   enableColumnActions: false,
      //   header: "Thao tác",
      //   size: 50,
      //   Cell: ({ row }) => (
      //     <Box
      //       style={{
      //         display: "flex",
      //         alignItems: "center",
      //         gap: "10px",
      //       }}
      //     >
      //       <Tooltip label="Chi tiết">
      //         <ActionIcon
      //           variant="light"
      //           aria-label="Settings"
      //           color="cyan"
      //           // onClick={async () => {
      //           //   detailItem(row.original.id!);
      //           // }}
      //         >
      //           <IconEye size={20} stroke={1.5} />
      //         </ActionIcon>
      //       </Tooltip>
      //     </Box>
      //   ),
      // },
    ],
    []
  );
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await repositoryMdm.get(`/api/v1/TblVendor/get-all`);
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(paginate(result));
        setAllData(result);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      return;
    } finally {
      setIsRefetching(false);
    }
  };

  const paginate = (allData: any[]) => {
    const start = pagination.pageIndex * pagination.pageSize;
    const end = start + pagination.pageSize;
    return allData.slice(start, end);
  };

  useEffect(() => {
    if (allData.length > 0) {
      setData(paginate(allData)); //
    }
  }, [pagination.pageIndex, pagination.pageSize, allData]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (210 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    fetchData();
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: true,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableGlobalFilterModes: true,
    positionGlobalFilter: "left",
    localization: _localization,
    paginationDisplayMode: "pages",
    mantinePaginationProps: {
      withEdges: false,
      showRowsPerPage: false,
    },
    getRowId: (row) => row.id?.toString(),
    initialState: {
      columnPinning: {
        left: ["id"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
      showGlobalFilter: true,
    },
    mantineSearchTextInputProps: {
      placeholder: `Vui lòng tìm kiếm`,
      variant: "filled",
    },
    enablePagination: true,
    enableRowVirtualization: true,
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onPaginationChange: setPagination,
    state: {
      pagination,
      isLoading,
    },
    rowCount: allData.length,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "550",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: isLoading
      ? { color: "blue", children: "Đang tải dữ liệu..." }
      : undefined,
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <BreadcrumbLayout1></BreadcrumbLayout1>
        </Flex>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ListDebtVendor;
