import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  Menu,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useDebouncedState } from "@mantine/hooks";
import {
  IconArrowLeft,
  IconCheck,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useScanDetection from "use-scan-detection";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { _localization } from "../../../config/location";
import { sky_blue } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { OrderDetailOutWard } from "../../../model/SaleOrderModel";
import {
  TblDMInventory,
  TblDmInventorySelectByBranch,
} from "../../../model/TblDMInventory";
import { TblInventorySerialOrderDetailOnHand } from "../../../model/TblInventorySerialOnHand";
import { TblInwardDetail } from "../../../model/TblInWard";
import {
  tblInventorySerialOnhandPO,
  TblInWardPO,
  TblInwardPODetail,
} from "../../../model/TblInWardPO";
import { tblItem } from "../../../model/TblItem";
import {
  getBranchSelect,
  getTblDmUomCodeSelect,
  getTblInventoryTransactionTypeInWard,
} from "../../../service/getSelectApi";
import "./tab.component.css";
import { anyAsciiCode } from "../../../_base/helper/AnyAscii";

// const validateBarcode = (value: string): boolean => {
//   // Regex to match non-English characters, spaces, or any whitespace
//   const regex = /^[a-zA-Z0-9]*$/;
//   return regex.test(value);
// };

const normalizeToEnglish = (value: string): string => {
  return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const CreateWarehousePurchaseInWard = () => {
  const navigate = useNavigate();
  const localtion = useLocation();

  const sourceCode = localtion.state && localtion.state.sourceCode;
  const sourceType = localtion.state && localtion.state.sourceType;

  const formOrderHeader = () => {
    return (
      // <ScrollArea offsetScrollbars scrollbars="y" h={height + 77}>
      <Grid pl={10}>
        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1 }}>
          <TextInput
            label="Mã phiếu"
            placeholder="Nhập mã"
            size="xs"
            disabled
            value={anyAsciiCode(
              dataInWardPo.tblItemTransactionHeaderCommand.transactionCode || ""
            )}
            onChange={(e) =>
              handleChangeValueInput(e.currentTarget.value, "transactionCode")
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1 }}>
          <DateTimePicker
            label="Ngày lập"
            size="xs"
            disabled
            value={
              dataInWardPo.tblItemTransactionHeaderCommand.createDate
                ? new Date(
                    dataInWardPo.tblItemTransactionHeaderCommand.createDate
                  )
                : null
            }
            onChange={(value) => {
              handleChangeValueInput(
                moment(value).format("YYYY-MM-DD[T]HH:mm:ss") || "",
                "requestDate"
              );
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1 }}>
          <TextInput
            label="Người lập"
            size="xs"
            disabled
            value={userInfo?.fullName || ""}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1 }}>
          <TextInput
            label="Bộ phận"
            size="xs"
            disabled
            value={userInfo?.depName || ""}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.3 }}>
          <Select
            searchable
            clearable
            size="xs"
            label="Loại phiếu"
            placeholder="Chọn loại phiếu"
            disabled
            data={dataInventoryTransactionType}
            value={
              dataInWardPo.tblItemTransactionHeaderCommand.sourceType?.toString() ||
              null
            }
            onChange={(value, option) =>
              handleChangeValueInput(value || "", "sourceType")
            }
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.3 }}>
          <TextInput
            label="Chứng từ gốc/Số phiếu YC"
            size="xs"
            disabled={
              dataInWardPo?.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            value={
              dataInWardPo?.tblItemTransactionHeaderCommand.sourceCode || ""
            }
            onChange={(e) => {
              handleChangeValueInput(e.currentTarget.value, "sourceCode");
            }}
            rightSection={
              <IconCheck
                cursor={"pointer"}
                color="green"
                onClick={() => fetchDataOrderDetail()}
              ></IconCheck>
            }
          />
          {/* <Select
            label="Chứng từ gốc/Số phiếu YC"
            placeholder="Chứng từ gốc/Số phiếu YC"
            data={dataSearchSourceCodeOption}
            size="xs"
            disabled={
              !dataInWardPo.tblItemTransactionHeaderCommand.sourceType ||
              dataInWardPo?.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            searchable
            clearable
            // searchValue={searchSourceCode ?? ""}
            onSearchChange={setSearchSourceCode}
            onKeyDown={handleKeyDownSourceCode}
            withAsterisk
            value={
              dataInWardPo?.tblItemTransactionHeaderCommand.sourceCode || ""
            }
            onChange={(value) => {
              handleChangeValueInput(value || "", "sourceCode");
              setDataInWardPo((prevData) => ({
                ...prevData,
                sourceCode: value,
              }));
            }}
            nothingFoundMessage={
              searchSourceCode && searchSourceCode?.length > 3
                ? "Không tìm thấy dự liệu !"
                : "Vui lòng nhập tối thiểu 4 kí tự !"
            }
          /> */}
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.3 }}>
          <Select
            searchable
            clearable
            size="xs"
            label="Chi nhánh"
            placeholder="Chọn chi nhánh"
            disabled={
              dataInWardPo?.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            withAsterisk
            data={dataBranch}
            value={
              dataInWardPo.tblItemTransactionHeaderCommand.inventoryId?.toString() ||
              null
            }
            onChange={(value, option) => {
              handleChangeValueInput(value || "", "inventoryId");
              handleChangeValueInput(option?.label || "", "inventoryName");
              handleChangeValueInput("", "subInvId");
              handleChangeValueInput("", "subInvName");
            }}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.4 }}>
          <Select
            searchable
            clearable
            size="xs"
            label="Kho"
            placeholder="Chọn "
            withAsterisk
            disabled={
              !dataInWardPo.tblItemTransactionHeaderCommand.inventoryId ||
              dataInWardPo?.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            data={dataInventory}
            value={
              dataInWardPo.tblItemTransactionHeaderCommand.subInvId?.toString() ||
              null
            }
            onChange={(value, option) => {
              handleChangeValueInput(value || "", "subInvId");
              handleChangeValueInput(option?.label || "", "subInvName");
            }}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 6, xs: 4, md: 2, lg: 1.3 }}>
          <Select
            searchable
            clearable
            size="xs"
            label="Trạng thái"
            placeholder="Chọn trạng thái"
            disabled
            data={statusOption}
            value={
              dataInWardPo?.tblItemTransactionHeaderCommand.status?.toString() ||
              null
            }
            onChange={(value, option) =>
              handleChangeValueInput(value || "", "status")
            }
          />
        </Grid.Col>

        <Grid.Col span={{ base: 10, xs: 8, md: 6, lg: 6 }}>
          <TextInput
            label="Diễn giải"
            size="xs"
            disabled={
              dataInWardPo?.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            value={
              dataInWardPo?.tblItemTransactionHeaderCommand.description || ""
            }
            onChange={(e) =>
              handleChangeValueInput(e.currentTarget.value, "description")
            }
          />
        </Grid.Col>
      </Grid>
      // </ScrollArea>
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const [dataInWardPo, setDataInWardPo] = useState<TblInWardPO>({
    sourceCode: sourceCode || "",
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: sourceCode || "",
      sourceType: "PO",
      inventoryId: null,
      subInvId: null,
      departmentId: 0,
      status: "NEW",
      description: "",
      // atribute1: "",
      // atribute2: "",
      // atribute3: "",
      // atribute4: "",
      // atribute5: "",
      // atribute6: "",
      // atribute7: "",
      // atribute8: "",
      // atribute9: "",
      // atribute10: "",
      createBy: 0,
      createDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
      createName: "",
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    tblInventorySerialOnhands: [],
  });
  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [userInfo, setUserInfo] = useState<any>();
  const [error, setError] = useState<string | null>(null);

  const statusOption = [
    { value: "NEW", label: "Tạo mới" },
    { value: "OPEN", label: "Lưu tạm" },
    { value: "LOCK", label: "Khoá" },
  ];
  //table state

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataBranch, setDataBranch] = useState<ComboboxItem[]>([]);
  const [dataAllInventory, setDataAllInventory] = useState<ComboboxItem[]>([]);
  const [dataInventory, setDataInventory] = useState<ComboboxItem[]>([]);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataUomCode, setDataUomCode] = useState<ComboboxItem[]>([]);

  const [dataOrderDetail, setDataOrderDetail] =
    useState<OrderDetailOutWard | null>(null);
  const [serial, setSerial] = useState<string>("");
  const [keySearch, setKeySearch] = useState("");
  const [searchSourceCode, setSearchSourceCode] = useDebouncedState("", 500);
  const [dataItem, setDataItem] = useState<tblItem[]>([]);
  const [dataSearchSourceCodeOption, setDataSearchSourceCodeOption] = useState<
    any[]
  >([]);

  const [selectedItem, setSelectedItem] = useState<tblItem | null>(null);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<string[]>([]);
  const [isExceedQuantity, setIsExceedQuantity] = useState(false);

  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  // useScanDetection({
  //   onComplete: (code) => {
  //     console.log("code:", code);
  //     setSerial(code.toString());
  //   },
  //   minLength: 5,
  // });

  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <_breadcrumb></_breadcrumb>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              size="xs"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
            <Button
              leftSection={<IconPlus size={14} />}
              variant="outline"
              color="blue"
              size="xs"
              onClick={() => {
                handleCreateInWard();
              }}
            >
              Tạo phiếu nhập kho
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItem.map((item, index) => (
            <Table.Tr
              key={item.itemId}
              bg={
                selectedItem?.itemId === item.itemId
                  ? "var(--mantine-color-blue-light)"
                  : undefined
              }
            >
              <Table.Td>
                <Button
                  size="xs"
                  variant="filled"
                  onClick={() => {
                    setSelectedItem(item);
                    setKeySearch(item.itemCode || "");
                  }}
                  disabled={selectedItem?.itemId === item.itemId}
                >
                  {selectedItem?.itemId === item.itemId ? "Đang chọn" : "Chọn"}
                </Button>
              </Table.Td>
              <Table.Td>{item.itemCode}</Table.Td>
              <Table.Td>
                <Tooltip label={item.itemName}>
                  <Text size="sm" w={250} lineClamp={2} truncate="end">
                    {item.itemName}
                  </Text>
                </Tooltip>
              </Table.Td>

              <Table.Td>{item.primaryUomCodeId}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
        {dataItem.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleKeyDownSourceCode = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      // Kiểm tra nếu nhấn Enter
      if (dataSearchSourceCodeOption.length === 1) {
        handleChangeValueInput(searchSourceCode || "", "sourceCode");
      }
    }
  };

  const handleChangeValueInput = (value: string | boolean, key: string) => {
    setDataInWardPo((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        [key]: value,
      },
      ...(key === "sourceCode" && { sourceCode: value as string }),
    }));
  };

  const handleChangeSerial = (value: string) => {
    // // Update the serial state regardless of validity
    // setSerial(value);

    // // Validate and set the error message if necessary
    // if (!validateBarcode(value)) {
    //   setError("Mã barcode không được có ký tự đặc biết hoặc dấu cách.");
    // } else {
    //   setError(null);
    // }
    const normalizedValue = normalizeToEnglish(value);
    setSerial(normalizedValue);
  };

  const handleAddProduct = async () => {
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/InwardPO/check-serial?Serial=${serial}&SourceCode=${dataInWardPo.tblItemTransactionHeaderCommand.sourceCode}`
      );

      if (response?.success) {
        const addData: tblInventorySerialOnhandPO = {
          id: 0,
          orgId: null,
          orgCode: null,
          invOrgId: dataInWardPo.tblItemTransactionHeaderCommand.inventoryId,
          invOrgCode: null,
          invOrgName:
            dataInWardPo.tblItemTransactionHeaderCommand.inventoryName || "",
          subInvId: dataInWardPo.tblItemTransactionHeaderCommand.subInvId,
          subInvCode: null,
          subInvName:
            dataInWardPo.tblItemTransactionHeaderCommand.subInvName || "",
          itemId: selectedItem?.itemId || null,
          itemCode: selectedItem?.itemCode || null,
          itemName: selectedItem?.itemName || null,
          uomCode: selectedItem?.primaryUomCodeId || null,
          serial: serial,
          serialStatus: null,
          serialNote: null,
          quantity: 1,
          transferCount: null,
          lastTransferDate: null,
          transferStorageDate: null,
          transshipment: null,
          entryDate: null,
          poQuantity: null,
          receiveNum: null,
          sourceDocument: null,
          supplier: null,
          industry: null,
          sector: null,
          receiveType: null,
          transactionType: null,
          itemAge: null,
          createDate: null,
          createBy: null,
          lastUpdateDate: null,
          lastUpdateBy: null,
          importDate: null,
          subUomCode: null,
          subQuantity: null,
        };

        setDataInWardPo((prevData) => ({
          ...prevData,
          tblInventorySerialOnhands: [
            addData,
            ...prevData.tblInventorySerialOnhands,
          ],
        }));
      }
    } catch (error) {}
  };

  const handleScannedProduct = () => {
    if (dataInWardPo.tblItemTransactionHeaderCommand.sourceCode) {
      if (dataInWardPo.tblItemTransactionHeaderCommand.inventoryId) {
        if (dataInWardPo.tblItemTransactionHeaderCommand.subInvId) {
          if (checkDuplicateCode.length === 0) {
            handleAddProduct();
            setCheckDuplicateCode((prev) => [...prev, serial]);
            setSerial("");
          } else if (checkDuplicateCode.length > 0) {
            if (checkDuplicateCode.includes(serial)) {
              NotificationExtension.Fails("Mã Serial đã được quét !");
            } else {
              setCheckDuplicateCode((prev) => [...prev, serial]);
              handleAddProduct();
              setSerial("");
            }
          }
        } else NotificationExtension.Fails("Vui lòng chọn kho");
      } else NotificationExtension.Fails("Vui lòng chọn chi nhánh");
    } else {
      NotificationExtension.Fails("Chưa nhập số chứng từ");
    }
  };

  const deleteItemSerial = (serial: string) => {
    setDataInWardPo((prevData) => ({
      ...prevData,
      tblInventorySerialOnhands: [
        ...prevData.tblInventorySerialOnhands.filter(
          (item) => item.serial !== serial
        ),
      ],
    }));

    setCheckDuplicateCode((prevData) =>
      prevData.filter((item) => item !== serial)
    );
  };

  const getTypeName = (type: string) => {
    return dataInventoryTransactionType.find(
      (tradType) => tradType.value === type
    )?.label;
  };

  const getInventoryName = (id: number | null) => {
    return dataAllInventory.find(
      (inventory) => inventory.value === id?.toString()
    )?.label;
  };

  const handleCreateInWard = async (status?: string) => {
    if (dataInWardPo.tblItemTransactionHeaderCommand.subInvId) {
      if (!isExceedQuantity) {
        try {
          const dataCreate: TblInWardPO = {
            ...dataInWardPo,
            tblInventorySerialOnhands:
              dataInWardPo.tblInventorySerialOnhands.filter(
                (item) => !item.type
              ),
          };
          const response = await repositoryPos.post<MessageResponse<any>>(
            "/api/v1/InwardPO/in-ward-po",
            dataCreate
          );
          if (response?.httpStatusCode === 200) {
            if (response?.success) {
              if (!status) {
                NotificationExtension.Success("Tạo phiếu nhập kho thành công");
                navigate("/warehouse-config/create-warehouse-purchase-in-ward");
              } else if (status === "OPEN") {
                NotificationExtension.Success("Lưu phiếu nhập kho thành công");
                // navigate("/warehouse-config/warehouse-purchase-in-ward");
                // setDataInWardPo((prevData) => ({
                //   ...prevData,
                //   tblItemTransactionHeaderCommand: {
                //     ...prevData.tblItemTransactionHeaderCommand,
                //     transactionId: response.data,
                //   },
                // }));

                await fetchDataEdit(response.data);
              } else if (status === "LOCK") {
                await handleLockInWard(response.data);
                await fetchDataEdit(response.data);
              }
            } else {
              NotificationExtension.Fails("Tạo thất bại");
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        // }
      } else {
        NotificationExtension.Fails("Có sản phẩm không hợp lệ");
      }
    } else {
      NotificationExtension.Fails("Vui lòng chọn kho");
    }
  };

  const handleLockInWard = async (invTransactionId?: string) => {
    try {
      const response = await repositoryPos.post<MessageResponse<any>>(
        `/api/v1/Inward/lock?InvTransactionId=${
          invTransactionId ||
          dataInWardPo.tblItemTransactionHeaderCommand.transactionId
        }`
      );
      if (response?.success) {
        handleChangeValueInput("LOCK", "status");
        NotificationExtension.Success("Khoá phiếu xuất kho thành công");

        // var res = SocketExtension.SendMessageToUserName<any>("kieuhv", {
        //   message: "Phiếu nhập kho",

        //   //  type: TypePushSocket.NotSelf,
        // });
        // console.log(res);
      }
    } catch (error) {}
  };

  const fetchDataInWardPo = async () => {
    const url = `/api/v1/Inward/in-ward?prefix=PN`;
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response?.success) {
        setDataInWardPo((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: {
            ...prevData.tblItemTransactionHeaderCommand,
            transactionCode: response?.data.code,
          },
        }));
      } else {
        NotificationExtension.Fails("Bạn không có quyền !");
        navigate(-1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataBranch = async () => {
    const getDataBranch = await getBranchSelect();

    const mappedData = getDataBranch
      .filter(
        (item) => item.value != null && item.text != null && item.value !== "-1"
      )
      .map((item) => ({
        value: item.value,
        label: `${item.text}`,
      }));
    setDataBranch(mappedData);
  };

  const fetchdataAllInventory = async () => {
    const url = `/api/v1/TblDmInventory/get-all`;
    try {
      const getData = await repositoryDelivery.get<
        MessageResponse<TblDMInventory[]>
      >(url);
      if (getData?.data) {
        const filteredData = getData?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.id.toString(),
          label: `(${item.code}) ${item.name}`,
        }));
        setDataAllInventory(mappedData);
      } else {
        setDataAllInventory([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataAllInventory([]);
    }
  };

  const fetchdataInventory = async () => {
    const url = `/api/v1/TblDmInventory/get-select-by-branch?branchId=${dataInWardPo.tblItemTransactionHeaderCommand.inventoryId}`;
    try {
      const getDataInventory = await repositoryPos.get<
        MessageResponse<TblDmInventorySelectByBranch[]>
      >(url);
      if (getDataInventory?.data) {
        const filteredData = getDataInventory?.data;
        const mappedData = filteredData?.map((item) => ({
          value: item.inventoryId.toString(),
          label: `(${item.inventoryCode}) ${item.inventoryName}`,
        }));
        setDataInventory(mappedData);
      } else {
        setDataInventory([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataInventory([]);
    }
  };

  const fetchdataInventoryTransactionType = async () => {
    const response = await getTblInventoryTransactionTypeInWard();

    setDataInventoryTransactionType(
      response.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  const fetchdataUomCodeType = async () => {
    const response = await getTblDmUomCodeSelect();

    setDataUomCode(
      response.map((type: any) => ({ value: type.value, label: type.text }))
    );
  };

  const fetchDataSourceCode = async () => {
    try {
      let url = `api/v1/RequestInventoryTransaction/search-request`;
      if (searchSourceCode || sourceCode) {
        url += `?Key=${searchSourceCode || sourceCode}`;
      }
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);

      if (response?.success) {
        setDataSearchSourceCodeOption(response.data.map((data) => data) || []);
      }
    } catch (error) {
      setDataSearchSourceCodeOption([]);
    }
  };

  const fetchDataItem = async () => {
    if (keySearch) {
      try {
        const response = await repositoryPos.get<MessageResponse<tblItem[]>>(
          `api/v1/TblItem/get-list?Ship=0&Take=30&KeySearch=${keySearch}`
        );
        if (response && response.success) {
          setDataItem(response.data || []);
        } else setDataItem([]);
      } catch (error) {
        setDataItem([]);
      }
    }
  };

  const fetchDataOrderDetail = async () => {
    const url = `/api/v1/TblPoHeader/get-detail-for-in-ward?SourceCode=${dataInWardPo.tblItemTransactionHeaderCommand.sourceCode}`;
    try {
      const response = await repositoryPos.get<
        MessageResponse<OrderDetailOutWard>
      >(url);
      if (response?.success && response?.data) {
        setDataOrderDetail(response.data);
        if (sourceCode) {
          setDataInWardPo((prevData) => ({
            ...prevData,
            tblItemTransactionHeaderCommand: {
              ...prevData.tblItemTransactionHeaderCommand,
              transactionCode: response?.data.sourceCode,
            },
          }));
        }
      } else {
        setDataOrderDetail(null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderDetail(null);
    }
  };

  const fetchDataEdit = async (id: string | number) => {
    const url = `/api/v1/InwardPO/detail?TransactionId=${id}`;
    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInwardPODetail>
      >(url);
      if (response?.success && response?.data) {
        setDataInWardPo((prevData) => ({
          ...prevData,
          sourceCode: response.data.tblInventoryTransactionModel.sourceCode,
          tblItemTransactionHeaderCommand:
            response?.data.tblInventoryTransactionModel,
          tblInventorySerialOnhands:
            response.data.tblInventorySerialOnHandModels.map((item) => ({
              ...item,
              type: "scanned",
            })) || [],
        }));
        setDataOrderDetail((prevData) =>
          prevData
            ? {
                ...prevData,
                requestInventoryTransactionDetailModels:
                  response?.data.requestInventoryTransactionDetailModels.map(
                    (order) => ({
                      ...order,
                      scannedQuantity: 0,
                      remainQuantity:
                        (order.primaryQuantity || 0) -
                        (order.transactionQuantity || 0),
                    })
                  ),
              }
            : null
        );

        setCheckDuplicateCode((prev) => [
          ...prev,
          ...response.data.tblInventorySerialOnHandModels
            .map((item) => item.serial)
            .filter((serial): serial is string => serial !== null),
        ]);
      } else {
        setDataOrderDetail(null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderDetail(null);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([
          fetchDataInWardPo(),
          fetchDataBranch(),
          fetchdataAllInventory(),
          fetchdataInventoryTransactionType(),
          fetchdataUomCodeType(),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) setUserInfo(JSON.parse(userLogin));

    if (sourceCode) {
      fetchDataSourceCode();
      if (sourceType === "PO") {
        fetchDataOrderDetail();
      }
    }

    fetchAllData();
  }, []);

  useEffect(() => {
    if (dataInWardPo.tblItemTransactionHeaderCommand.inventoryId) {
      fetchdataInventory();
    } else {
      setDataInventory([]);
    }
  }, [dataInWardPo.tblItemTransactionHeaderCommand.inventoryId]);

  useEffect(() => {
    setDataInWardPo((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        departmentId: userInfo?.departmentId || "",
      },
    }));
  }, [userInfo]);

  useEffect(() => {
    const exceedQuantityFound =
      dataOrderDetail?.requestInventoryTransactionDetailModels.some((order) => {
        const matchingOrder = dataInWardPo.tblInventorySerialOnhands
          .filter((item) => item.itemId === order.itemId && !item.type)
          .map((item) => item.quantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

        if (matchingOrder) {
          const totalQuantity = matchingOrder.reduce(
            (acc, curr) => acc + curr,
            0
          ); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const orderTransferQuantity = order.transactionQuantity ?? 0;
          const remainQuantity =
            orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

          return remainQuantity < 0;
        }

        return false;
      });

    setDataOrderDetail((prevData) =>
      prevData
        ? {
            ...prevData,
            requestInventoryTransactionDetailModels:
              prevData.requestInventoryTransactionDetailModels.map((order) => {
                const quantities = dataInWardPo.tblInventorySerialOnhands
                  .filter((item) => item.itemId === order.itemId && !item.type)
                  .map((item) => item.quantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

                const totalQuantity = quantities.reduce(
                  (acc, curr) => acc + curr,
                  0
                ); // Tính tổng số lượng

                const orderQuantity = order.primaryQuantity ?? 0;
                const orderTransferQuantity = order.transactionQuantity ?? 0;
                const remainQuantity =
                  orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

                return {
                  ...order,
                  scannedQuantity: totalQuantity,
                  remainQuantity: remainQuantity,
                };
              }),
          }
        : null
    );

    // Ensure the state is set after the mapping is complete
    if (exceedQuantityFound) {
      NotificationExtension.Fails("Sản phẩm vượt quá số lượng yêu cầu xuất");
    }

    setIsExceedQuantity(exceedQuantityFound || false);
  }, [dataInWardPo.tblInventorySerialOnhands]);

  useEffect(() => {
    if (dataInWardPo.tblItemTransactionHeaderCommand.sourceType) {
      if (
        dataInWardPo.tblItemTransactionHeaderCommand.sourceCode &&
        dataInWardPo.tblItemTransactionHeaderCommand.sourceCode?.length > 3
      ) {
        if (
          dataInWardPo.tblItemTransactionHeaderCommand.sourceType === "PO" &&
          dataInWardPo.tblItemTransactionHeaderCommand.sourceCode?.length >= 17
        ) {
          fetchDataOrderDetail();
        }
      }
    }
  }, [
    dataInWardPo.tblItemTransactionHeaderCommand.sourceCode,
    dataInWardPo.tblItemTransactionHeaderCommand.sourceType,
  ]);

  useEffect(() => {
    if (
      searchSourceCode?.length > 3
      // && dataInWardPo.tblItemTransactionHeaderCommand.sourceType === "PO"
    ) {
      fetchDataSourceCode();
    } else if (!searchSourceCode) {
      fetchDataSourceCode();
    }
  }, [searchSourceCode]);

  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItem();
    } else {
      setDataItem([]);
    }
  }, [keySearch]);

  useEffect(() => {
    if (dataInWardPo.tblItemTransactionHeaderCommand.sourceType === "PO") {
      fetchDataSourceCode();
    }
  }, [dataInWardPo.tblItemTransactionHeaderCommand.sourceType]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên hàng",
        enableEditing: false,
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "serial",
        header: "Số serial",
        size: 100,
        Edit: ({ cell, row, table, column }) => {
          return (
            <TextInput
              placeholder="Số serial"
              readOnly={!!row.original.type}
              value={row._valuesCache[column.id]?.toString() || null}
              // defaultValue={row._valuesCache[column.id] || null}
              onChange={(e) => {
                const updateData = [...dataInWardPo.tblInventorySerialOnhands];
                // console.log("Row index:", row.index);
                // console.log("Update Data:", updateData);
                // console.log("Value:", e.currentTarget.value);
                updateData[row.index].serial = e.currentTarget.value;
                setDataInWardPo({
                  ...dataInWardPo,
                  tblInventorySerialOnhands: updateData,
                });
              }}
              onBlur={() => table.setEditingCell(null)}
            />
          );
        },
      },
      {
        accessorKey: "serialStatus",
        header: "Trạng thái serial",
        size: 100,
        Edit: ({ cell, row, table, column }) => {
          return (
            <TextInput
              placeholder="Trạng thái serial"
              readOnly={!!row.original.type}
              value={row._valuesCache[column.id]?.toString() || null}
              // defaultValue={row._valuesCache[column.id] || null}
              onChange={(e) => {
                const updateData = [...dataInWardPo.tblInventorySerialOnhands];
                updateData[row.index].serialStatus = e.currentTarget.value;
                setDataInWardPo({
                  ...dataInWardPo,
                  tblInventorySerialOnhands: updateData,
                });
              }}
              onBlur={() => table.setEditingCell(null)}
            />
          );
        },
      },
      {
        accessorKey: "serialNote",
        header: "Ghi chú serial",
        size: 100,
        Edit: ({ cell, row, table, column }) => {
          return (
            <TextInput
              placeholder="Ghi chú serial"
              readOnly={!!row.original.type}
              value={row._valuesCache[column.id]?.toString() || null}
              // defaultValue={row._valuesCache[column.id] || null}
              onChange={(e) => {
                const updateData = [...dataInWardPo.tblInventorySerialOnhands];
                updateData[row.index].serialNote = e.currentTarget.value;
                setDataInWardPo({
                  ...dataInWardPo,
                  tblInventorySerialOnhands: updateData,
                });
              }}
              onBlur={() => table.setEditingCell(null)}
            />
          );
        },
      },
      {
        accessorKey: "uomCode",
        header: "Đơn vị tính",
        size: 100,
        Cell: ({ row, cell }) => (
          <>
            {dataUomCode.find(
              (uom) => uom.value === row.original.uomCode.toString()
            )?.label || ""}
          </>
        ),
        Edit: ({ cell, row, table, column }) => {
          return (
            <Select
              placeholder="Chọn đơn vị tính"
              data={dataUomCode}
              readOnly={!!row.original.type}
              allowDeselect={false}
              value={row._valuesCache[column.id].toString() || null}
              // defaultValue={row._valuesCache[column.id] || null}
              onChange={(value) => {
                const updateData = [...dataInWardPo.tblInventorySerialOnhands];
                updateData[row.index].uomCode = value;
                setDataInWardPo({
                  ...dataInWardPo,
                  tblInventorySerialOnhands: updateData,
                });
                table.setEditingCell(null);
                // row._valuesCache[column.id] = value;
                // table.setEditingRow(row);
              }}
              onBlur={() => table.setEditingCell(null)}
            />
          );
        },
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        size: 100,
        enableEditing: false,
      },
      {
        accessorKey: "invOrgName",
        header: "Chi nhánh",
        size: 100,
        enableEditing: false,
      },
      {
        accessorKey: "subInvName",
        header: "Kho",
        size: 100,
        enableEditing: false,
      },

      // {
      //   accessorKey: "warning",
      //   header: "Cảnh báo",
      //   Cell: ({ row }) =>
      //     !row.original.isMapping ? (
      //       <Tooltip label={row.original.warning}>
      //         <Text size="sm" w={300} c={"red"}>
      //           {row.original.warning}
      //         </Text>
      //       </Tooltip>
      //     ) : null,
      // },

      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {!row.original.type && (
              <Tooltip label="Xóa">
                <ActionIcon
                  variant="light"
                  aria-label="Settings"
                  color="red"
                  onClick={async () => {
                    deleteItemSerial(row.original.serial);
                  }}
                >
                  <IconTrash size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
          </Box>
        ),
      },
    ],
    [dataUomCode, dataInWardPo.tblInventorySerialOnhands]
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "sourceCode",
        header: "Mã số",
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue, row }) =>
          renderedCellValue
            ? moment(renderedCellValue as string).format("HH:mm DD-MM-YYYY")
            : "",
      },
      {
        accessorKey: "employeeName",
        header: "Người lập",
      },
      {
        accessorKey: "type",
        header: "Loại chứng từ",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{getTypeName(row.original.type)}</Text>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
      },

      {
        accessorKey: "toSubInvId",
        header: "Kho đến",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{getInventoryName(row.original.toSubInvId)}</Text>
        ),
      },

      {
        accessorKey: "uom",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
      },
      {
        accessorKey: "scannedQuantity",
        header: "Số lượng đang quét",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{renderedCellValue || 0}</Text>
        ),
      },
      {
        accessorKey: "transferQuantity",
        header: "Số lượng đã nhập",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">{renderedCellValue || 0}</Text>
        ),
      },
      {
        accessorKey: "remainQuantity",
        header: "Số lượng còn lại",
        Cell: ({ renderedCellValue, row }) => (
          <Text size="sm">
            {renderedCellValue ??
              (row.original.quantity || 0) -
                (row.original.transferQuantity || 0)}{" "}
            {renderedCellValue && Number(renderedCellValue) < 0 ? (
              <Text span c={"red"}>
                (Vượt quá số lượng cần nhập)
              </Text>
            ) : null}{" "}
          </Text>
        ),
      },
    ],
    [dataInventoryTransactionType, dataInWardPo.tblInventorySerialOnhands]
  );

  const table = useMantineReactTable({
    columns,
    data: dataInWardPo.tblInventorySerialOnhands,
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    enableEditing: true,
    editDisplayMode: "cell",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "70%" }}>
        <Grid>
          <Grid.Col span={{ base: 6, md: 4, lg: 2 }}>
            <Menu
              trapFocus={false}
              trigger="hover"
              shadow="md"
              width={"800"}
              position="bottom-start"
            >
              <Menu.Target>
                <TextInput
                  placeholder="Chọn sản phẩm"
                  size="xs"
                  disabled={
                    dataInWardPo?.tblItemTransactionHeaderCommand.status ===
                    "LOCK"
                  }
                  value={keySearch}
                  onChange={async (e) => {
                    const _key = e.target.value ?? "";
                    setKeySearch(_key);
                  }}
                />
              </Menu.Target>
              <Menu.Dropdown>
                <TableSelect />
              </Menu.Dropdown>
            </Menu>
          </Grid.Col>

          <Grid.Col span={{ base: 6, md: 4, lg: 2 }}>
            <TextInput
              flex={3}
              placeholder="Nhập mã vạch"
              size="xs"
              disabled={
                dataInWardPo?.tblItemTransactionHeaderCommand.status === "LOCK"
              }
              value={anyAsciiCode(serial)}
              onChange={(e) => handleChangeSerial(e.currentTarget.value)}
              error={error}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 2, lg: 1.5 }}>
            <Button
              flex={1.5}
              leftSection={<IconPlus size={14} />}
              size="xs"
              w={"fit-content"}
              onClick={handleScannedProduct}
              disabled={
                !selectedItem ||
                !!error ||
                serial?.length < 7 ||
                dataInWardPo?.tblItemTransactionHeaderCommand.status === "LOCK"
              }
            >
              Thêm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "itemCode"],
        right: ["action"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height * 0.55 - 20, minHeight: height * 0.55 - 20 },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableOrderDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data: dataOrderDetail?.requestInventoryTransactionDetailModels || [],
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.35,
        minHeight: height * 0.35,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>{titleSell()}</div>
      <Fieldset legend={"Thông tin chung"} p={5}>
        {formOrderHeader()}
      </Fieldset>
      <Fieldset legend={"Thông tin chi tiết"} pb={10}>
        <MantineReactTable table={table} />
        <Group justify="end" mt="xs">
          <Button
            type="submit"
            color={sky_blue.base}
            disabled={
              dataInWardPo?.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            onClick={() => {
              // handleChangeValueInput("OPEN", "status");
              handleCreateInWard("OPEN");
            }}
          >
            Lưu tạm
          </Button>
          <Button
            type="button"
            color="red"
            disabled={
              dataInWardPo?.tblItemTransactionHeaderCommand.status === "LOCK"
            }
            onClick={() => {
              // handleChangeValueInput("LOCK", "status");
              handleCreateInWard("LOCK");
              // handleLockInWard();
            }}
          >
            Khoá
          </Button>
        </Group>
      </Fieldset>
      <Fieldset legend={"Thông tin chứng từ gốc"} pb={10}>
        <MantineReactTable table={tableOrderDetail} />
      </Fieldset>
    </Box>
  );
};
export default CreateWarehousePurchaseInWard;
