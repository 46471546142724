import { useEffect, useMemo, useRef, useState } from "react";
import { TblDMInventoryGroup } from "../../../../model/TblDMInventoryGroup";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { _localization } from "../../../../config/location";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Menu,
  rem,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconCaretDown,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { CreateDataView } from "./CreateDataView";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { DeleteDataView } from "./DeleteDataView";
import DetailDataView from "./DetailDataView";
import EditDataView from "./EditDataView";
import { green } from "../../../../const/variables";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";

export const DMInventoryGroup = () => {
  //column definitions
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "invGroupCode",
        header: "Mã loại",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailItem(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        size: 130,
      },
      {
        accessorKey: "invGroupName",
        header: "Tên loại",
        Cell: ({ renderedCellValue }: any) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "dienGiai",
        header: "Diễn giải",
        Cell: ({ renderedCellValue }) => (
          <Text truncate maw={300}>
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "chucNangBan",
        header: "Chức năng bán",
        Cell: ({ row }) => (
          <Badge color={getColorStatus(row.original.chucNangBan ?? false)}>
            {row.original.chucNangBan ? "Có" : "Không"}
          </Badge>
        ),
      },
      {
        accessorKey: "empNameQuanLy",
        header: "Người quản lý",
      },
      {
        header: "Liên chi nhánh",
        Header: () => (
          <Title order={4} w={"100%"} h={"100%"}>
            Liên chi nhánh
          </Title>
        ),
        columns: [
          {
            accessorKey: "dieuChuyenLcn",
            header: "Điều chuyển",
            Cell: ({ row }) => (
              <Badge
                color={getColorStatus(row.original.dieuChuyenLcn ?? false)}
              >
                {row.original.dieuChuyenLcn ? "Có" : "Không"}
              </Badge>
            ),
          },
          {
            accessorKey: "empNameLienchinhanh",
            header: "Người điều chuyển",
          },
        ],
      },
      {
        header: "Nội bộ chi nhánh",
        Header: () => (
          <Title order={4} w={"100%"} h={"100%"}>
            Nội bộ chi nhánh
          </Title>
        ),
        columns: [
          {
            accessorKey: "dieuChuyenNoibo",
            header: "Điều chuyển",
            Cell: ({ row }) => (
              <Badge
                color={getColorStatus(row.original.dieuChuyenNoibo ?? false)}
              >
                {row.original.dieuChuyenNoibo ? "Có" : "Không"}
              </Badge>
            ),
          },
          {
            accessorKey: "empNameNoibochinhanh",
            header: "Người điều chuyển",
          },
        ],
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={() => {
                  editItem(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={() => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={() => {
                  deletesData([row.original.id]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );
  //data fetching and state
  const headerRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMInventoryGroup[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //state of table
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  //function
  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-danh-muc-kho.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    // if (formSeachInput.getValues().keySearch) {
    //   url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    // }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblDMInventoryGroup[]>
      >(`/api/v1/TblDmInventoryGroup/get-list${url}`);

      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  // open modal
  function createItem() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thêm phân loại kho mới!</Title>
        </>
      ),
      size: "auto",
      children: <CreateDataView onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function editItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa phân loại kho!</Title>
        </>
      ),
      size: "550px",
      children: <EditDataView id={id} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const deletesData = async (ids: (string | number)[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa phân loại kho!</Title>
            </>
          ),

          size: "550px",
          children: (
            <DeleteDataView idItems={ids} onClose={setDeleteViewStatus} />
          ),
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  function detailItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chi tiết phân loại kho!</Title>
        </>
      ),
      size: "550px",
      children: <DetailDataView id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  //useEffect
  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
    // setData([
    //   {
    //     id: 1,
    //     maKho: "KH001",
    //     loaiKho: "Kho chính",
    //     dienGiai:
    //       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi aspernatur labore nihil voluptatum natus accusamus enim doloribus, itaque autem perferendis animi ad omnis consequatur obcaecati fuga, error blanditiis quisquam eaque?",
    //     chucNangBan: true,
    //     nguoiQuanLy: "Nguyễn Văn A",
    //     dieuChuyenLienchinhanh: false,
    //     nguoiDieuChuyenLienchinhanh: null,
    //     dieuChuyenNoibo: true,
    //     nguoiDieuChuyenNoibo: "Trần Thị B",
    //     createBy: 1001,
    //     createDate: new Date("2023-01-01T10:00:00"),
    //     lastUpdateBy: 1002,
    //     lastUpdateDate: new Date("2024-01-15T14:30:00"),
    //   },
    //   {
    //     id: 2,
    //     maKho: "KH002",
    //     loaiKho: "Kho phụ",
    //     dienGiai: "Kho phụ lưu trữ hàng hóa không quan trọng",
    //     chucNangBan: false,
    //     nguoiQuanLy: "Lê Văn C",
    //     dieuChuyenLienchinhanh: true,
    //     nguoiDieuChuyenLienchinhanh: "Nguyễn Văn D",
    //     dieuChuyenNoibo: false,
    //     nguoiDieuChuyenNoibo: null,
    //     createBy: 1003,
    //     createDate: new Date("2023-02-01T09:30:00"),
    //     lastUpdateBy: 1004,
    //     lastUpdateDate: new Date("2024-02-10T11:00:00"),
    //   },
    //   {
    //     id: 3,
    //     maKho: null,
    //     loaiKho: "Kho đặc biệt",
    //     dienGiai: "Kho chứa hàng hóa nhạy cảm",
    //     chucNangBan: true,
    //     nguoiQuanLy: "Phạm Văn E",
    //     dieuChuyenLienchinhanh: true,
    //     nguoiDieuChuyenLienchinhanh: "Lê Thị F",
    //     dieuChuyenNoibo: true,
    //     nguoiDieuChuyenNoibo: "Nguyễn Văn G",
    //     createBy: null,
    //     createDate: null,
    //     lastUpdateBy: 1005,
    //     lastUpdateDate: new Date("2024-03-01T16:45:00"),
    //   },
    //   {
    //     id: 4,
    //     maKho: "KH004",
    //     loaiKho: "Kho chờ",
    //     dienGiai: "Kho dành cho hàng hóa chờ xử lý",
    //     chucNangBan: false,
    //     nguoiQuanLy: null,
    //     dieuChuyenLienchinhanh: false,
    //     nguoiDieuChuyenLienchinhanh: null,
    //     dieuChuyenNoibo: false,
    //     nguoiDieuChuyenNoibo: null,
    //     createBy: 1006,
    //     createDate: new Date("2024-04-01T08:00:00"),
    //     lastUpdateBy: null,
    //     lastUpdateDate: null,
    //   },
    // ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (300 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  //table definition
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        {/* <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input placeholder="Nhập từ khóa ..." onChange={handleOnChange} />
        </Flex> */}
      </div>
    ),
    onPaginationChange: setPagination,
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={() => {
              createItem();
            }}
          >
            Thêm mới
          </Button>

          <Button
            leftSection={<IconTrash size={14} />}
            color="red"
            variant="outline"
            onClick={() => {
              deletesData(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Xóa (đã chọn)
          </Button>
          {/* <Button
            leftSection={<IconZoomReset size={14} />}
            color="violet"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
            disabled={isCounting}
          >
            {timeLeft > 0 && timeLeft} Load data
          </Button> */}
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                // size="xs"
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Application</Menu.Label>
              <Menu.Item
                leftSection={
                  <IconFileExport style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={handleExport}
              >
                Export Excel
              </Menu.Item>
              <Tooltip label={"Chức năng đang update"}>
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>

              <Menu.Item
                leftSection={
                  <IconX style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Đóng
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row: any) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "invGroupCode", "invGroupName"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    // onPaginationChange: setPagination,

    mantineTableBodyCellProps: () => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};
