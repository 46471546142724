import {
  Badge,
  Button,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  NumberFormatter,
  Select,
  SelectProps,
  Text,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  IconCheck,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryMdm } from "../../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../../config/location";
import { green } from "../../../../../const/variables";
import { BorrowTicket } from "../../../../../model/TblWarrantyItemBorrowTicket";
import { notifications } from "@mantine/notifications";
import { DebtData } from "../../CreditAdvice/model/model";

function getColorStatus(text: any) {
  switch (text) {
    case "Yêu cầu xuất mượn":
      return "blue";
    case "Đã thu tiền":
      return "purple";
    case "Đã xuất kho":
      return "yellow";
    case "Đã nhập hàng":
      return "green";
    case "Đã thu chi":
      return "orange";
    default:
      return "gray";
  }
}

const dataStatusOption = [
  { value: "1", label: "Yêu cầu xuất mượn" },
  { value: "2", label: "Đã thu tiền" },
  { value: "3", label: "Đã xuất kho" },
  { value: "4", label: "Đã nhập hàng" },
  { value: "5", label: "Đã thu chi" },
];

const BorrowedReceipt = ({
  handleChooseOrderReturn,
  fieldName,
  typeBill,
}: {
  handleChooseOrderReturn: Function;
  fieldName?: DebtData[];
  typeBill?: string;
}) => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<BorrowTicket[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [status, setStatus] = useState<number | null>(null); // Lưu trữ status
  const [id, setId] = useState<number | null>(null); // Lưu trữ id
  const [idPNHL, setIdPNHL] = useState<string[]>([]); // Lưu trữ id

  const columns = React.useMemo<MRT_ColumnDef<BorrowTicket>[]>(
    () => [
      {
        header: "STT",
        size: 30,
        Cell: ({ row }) => (
          <>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {row.index + 1}
            </span>
          </>
        ),
      },
      {
        accessorKey: "borrowCode",
        header: "Số phiếu yêu cầu hàng mượn",
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            {renderedCellValue && (
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                style={{ cursor: "pointer" }}
                color={renderedCellValue === null ? "" : green.base}
              >
                {renderedCellValue}
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "status",
        header: "Trạng thái",
        size: 30,
        Cell: ({ renderedCellValue }) => {
          const displayValue = (() => {
            switch (renderedCellValue) {
              case "YEU_CAU_XUAT_MUON":
                return "Yêu cầu xuất mượn";
              case "DA_NHAP_VE":
                return "Đã nhập về";
              default:
                return null;
            }
          })();

          const badgeColor = (() => {
            switch (displayValue) {
              case "Yêu cầu xuất mượn":
                return "orange";
              case "Đã nhập về":
                return "green";
              default:
                return "gray";
            }
          })();

          return (
            <>
              {displayValue === null ? null : (
                <Badge color={badgeColor}>{displayValue}</Badge>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "branchName",
        header: "Chi nhánh",
      },
      {
        accessorKey: "branchAddress",
        header: "Địa chỉ chi nhánh",
      },
      {
        accessorKey: "departmentName",
        header: "Tên phòng ban",
      },
      {
        accessorKey: "roleName",
        header: "Nhân viên",
      },
      // {
      //   accessorKey: "borrowCode",
      //   header: "Số phiếu xuất hàng mượn",
      //   Cell: ({ renderedCellValue, row }: any) => (
      //     <>
      //       <Badge
      //         radius="sm"
      //         variant="dot"
      //         size="lg"
      //         style={{ cursor: "pointer" }}
      //         color={renderedCellValue === null ? "" : green.base}
      //       >
      //         {renderedCellValue}
      //       </Badge>
      //     </>
      //   ),
      // },
      // {
      //   accessorKey: "attribute3",
      //   header: "Số phiếu nhập hàng mượn",
      //   Cell: ({ renderedCellValue, row }: any) => (
      //     <>
      //       <Badge
      //         radius="sm"
      //         variant="dot"
      //         size="lg"
      //         style={{ cursor: "pointer" }}
      //         color={renderedCellValue === null ? "" : green.base}
      //       >
      //         {renderedCellValue}
      //       </Badge>
      //     </>
      //   ),
      // },

      {
        accessorKey: "createDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue, row }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "cusAddress",
        header: "Địa chỉ khách hàng",
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 30,
      },
      {
        accessorKey: "totalDeposit",
        header: "Tổng số tiền",
        size: 30,
        Cell: ({ renderedCellValue, row }: any) => (
          <>
            <Text fw={"bold"}>
              <NumberFormatter value={renderedCellValue} thousandSeparator />
            </Text>
          </>
        ),
      },
    ],
    [data]
  );
  // form search
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      startDate: "",
      endDate: "",
      status: "",
    },
  });
  // navigate to detail
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }

    if (typeBill === "TH") {
      url += `&Status=YEU_CAU_XUAT_MUON`;
    } else if (typeBill === "CH") {
      url += `&Status=DA_NHAP_VE`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyExportItemBorrowTicket/get-list${url}&IsOrder=true`
      );
      if (response && response.success === true) {
        let result = response.data;
        const filteredData = result?.filter(
          (item: any) =>
            !fieldName?.some(
              (x) => x.originalDocumentNumber === item.borrowCode
            )
        );
        setIsLoading(false);
        setRowCount(result.length);
        setData(filteredData);
        setIdPNHL(filteredData?.map((item: any) => item.requestCode));
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      setIsLoading(false);
      console.error(error);
      return;
    } finally {
      setIsRefetching(false);
      setIsLoading(false);
    }
  };
  const table = useMantineReactTable({
    columns,
    data: data ? data : [],
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id?.toString(),

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["STT", "borrowCode"],
        right: ["totalDeposit"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableColumnActions: false,
    enableSorting: false,
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        if (
          row.original.borrowCode === "" ||
          row.original.borrowCode === null ||
          row.original.totalDeposit === 0
        ) {
          notifications.show({
            title: "Thất bại",
            message:
              "Thông tin từ số phiếu nhập hàng mượn hoặc số tiền hiển thị không hợp lệ!",
            autoClose: 5000,
            color: "red",
            icon: <IconX />,
          });
          return;
        }
        handleChooseOrderReturn(row.original);
        modals.closeAll();
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),

    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  useEffect(() => {
    fetchData();
  }, [pagination?.pageIndex, pagination?.pageSize, status, id]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (235 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      ></div>
      <Flex
        justify="space-between"
        align={"center"}
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <form
          onSubmit={formSeachInput.onSubmit(async () => await fetchData())}
          style={{ flex: 1 }}
        >
          <Grid mt={10}>
            <GridCol span={{ base: 6, sm: 4, md: 2.5 }}>
              <Input
                w={"100%"}
                placeholder="Từ khoá"
                type="text"
                leftSection={<IconSearch color="#15aabf" />}
                key={formSeachInput.key("keySearch")}
                {...formSeachInput.getInputProps("keySearch")}
              />
            </GridCol>
            <GridCol span={{ base: 4, sm: 2, md: 1 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                type="submit"
              >
                Tìm kiếm
              </Button>
            </GridCol>
          </Grid>
        </form>
      </Flex>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default BorrowedReceipt;
