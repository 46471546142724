import "../../../Styles/tab.component.css";

import { Paper, Tabs } from "@mantine/core";
import { useState } from "react";

import InventoryCycleCountPeriod from "./InventoryCycleCountPeriod/InventoryCycleCountPeriod";
import InventoryCycleCount from "./InventoryCycleCount/InventoryCycleCount";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";

const WarehouseManagement = () => {
  const [activeTab, setActiveTab] = useState<string | null>("inventoryRound");
  console.log(activeTab);

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Paper shadow="md" radius="lg">
        <Tabs p={"xs"} value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="inventoryRound">Danh sách đợt kiểm kê</Tabs.Tab>
            <Tabs.Tab value="inventorySheet">
              Danh sách phiếu kiểm kê kho
            </Tabs.Tab>
            {/* <Tabs.Tab value="warehouseConsumption">
              Danh sách phiếu đề nghị nhập tiêu hao kho
            </Tabs.Tab> */}
          </Tabs.List>

          <Tabs.Panel value="inventoryRound">
            {activeTab === "inventoryRound" ? (
              <InventoryCycleCountPeriod />
            ) : (
              <></>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="inventorySheet">
            {activeTab === "inventorySheet" ? <InventoryCycleCount /> : <></>}
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </>
  );
};

export default WarehouseManagement;
