import {
  Badge,
  Box,
  Button,
  Group,
  Input,
  MultiSelect,
  MultiSelectProps,
  Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import {
  IconBuildingWarehouse,
  IconCalendar,
  IconMap2,
  IconMapPin,
  IconSearch,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { SelectListItemModel } from "../../../model/SelectListItem";
const InventoryTransactionsReport = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<Department[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranchCode, setDataBranchCode] = useState<any>([]);
  const [dataInvCode, setDataInvCode] = useState<SelectListItemModel[]>([]);
  const [dataItemCode, setDataItemCode] = useState<any>([]);
  const [change, setchange] = useState<{ maCn: boolean; maKho: boolean }>({
    maCn: true,
    maKho: true,
  });
  const [search, setSearch] = useState({
    keySearch: "",
    maViTriList: [],
    maKhoList: [],
    maCn: [],
  });
  const handleChangeSearchValue = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };
  const handleChangeValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
    // setOnchange(value);
  };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "chungTuGoc",
        header: "Chứng từ gốc",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "maGd",
        header: "Mã giao dịch",
      },
      {
        accessorKey: "ngayGd",
        header: "Ngày giao dịch",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "loaiGd",
        header: "Loại giao dịch",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "dienGiai",
        header: "Diễn giải",
      },
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
      },
      {
        accessorKey: "tenCn",
        header: "Tên chi nhánh",
      },
      {
        accessorKey: "maKho",
        header: "Mã kho",
      },
      {
        accessorKey: "maViTri",
        header: "Mã vị trí",
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
      },
      {
        accessorKey: "serialNumber",
        header: "Serial",
      },
      {
        accessorKey: "serialStatus",
        header: "Trạng thái serial",
      },
      {
        accessorKey: "soLuongNhap",
        header: "Số lượng nhập",
        // Cell: ({ renderedCellValue, row }) => (
        //   <>{row.original.soLuongNhap === 1 ? "Đã Nhập" : "Chưa Nhập"}</>
        // ),
      },
      {
        accessorKey: "soLuongXuat",
        header: "Số lượng xuất",
        // Cell: ({ renderedCellValue, row }) => (
        //   <>{row.original.soLuongXuat === 1 ? "Đã Xuất" : "Chưa Xuất"}</>
        // ),
      },
      {
        accessorKey: "maNv",
        header: "Mã nhân viên",
      },
      {
        accessorKey: "tenNv",
        header: "Tên nhân viên",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <AvatarUtils value={row.original.tenNv?.toString().split("-")[0]} />
          </>
        ),
      },
      {
        accessorKey: "phongBanGd",
        header: "Phòng ban giao dịch",
        size: 30,
      },
      // {
      //   accessorKey: "tenCnGd",
      //   header: "Tên chi nhánh giao dịch",
      //   size: 200,
      // },
    ],
    []
  );

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    // if (search.maKhoList && search.maKhoList.length > 0) {
    //   url += `&MaKhoList=${search.maKhoList.join(",")}`;
    // }
    if (search.maKhoList && search.maKhoList.length > 0) {
      search.maKhoList.forEach((maKho) => {
        url += `&MaKhoList=${maKho}`;
      });
    }
    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblItemTransaction/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const DataInvCode = async () => {
    try {
      const response = await repositoryDelivery.get(
        `api/v1/TblItemTransaction/get-Select-inventory`
      );
      if (response && response.success) {
        const transformedData = response.data.map((branch: any) => ({
          value: branch.maKho,
          label: branch.maKho + " - " + branch.tenKho,
        }));
        setDataInvCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  // const DataItemCode = async () => {
  //   try {
  //     // let url = "";
  //     // if (search.maKhoList) {
  //     //   url = `MaKhoList=${search.maKhoList.join(",").trim()}`;
  //     // }
  //     const response = await repositoryDelivery.get(
  //       `api/v1/TblItemTransaction/get-Select-location?skip=0&take=50`
  //     );
  //     if (response && response.success) {
  //       const transformedData = response.data.lists
  //         .map((branch: any) => ({
  //           value: branch.maViTri ?? "",
  //           label: branch.text || branch.maViTri,
  //         }))
  //         .filter((item: any) => item.value !== "");
  //       setDataItemCode(transformedData);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return;
  //   }
  // };
  // useEffect(() => {
  //   DataInvCode();
  // }, [change]);
  // useEffect(() => {
  //   DataItemCode();
  // }, [search.maKhoList]);
  // const handleSelectChange = (values: string[], data: any[], key: string) => {
  //   // Chỉ lấy phần trong dấu [] cho các giá trị đã chọn
  //   const trimmedValues = values.map((value) => {
  //     const match = data
  //       .find((item: any) => item.value === value)
  //       ?.label.match(/\[(.*?)\]/);
  //     return match ? match[0] : value;
  //   });
  //   handleChangeValue(trimmedValues, key);
  // };
  const table = useMantineReactTable({
    columns,
    data,
    enableColumnFilters: false,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["transactionNumber", "chungTuGoc"],
        right: ["statusName"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập từ khóa"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "keySearch");
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />
          {/* <DatePickerInput
            type="range"
            size="sm"
            placeholder="Từ ngày - Đến ngày"
            locale="vi"
            valueFormat="DD/MM/YYYY"
            onChange={(e) => {
              handleChangeSearchValue(
                formatDateNotTimeZone(e[0]) ?? "",
                "startDate"
              );
              handleChangeSearchValue(
                formatDateNotTimeZone(e[1]) ?? "",
                "endDate"
              );
            }}
            leftSection={<IconCalendar color="#15aabf" />}
            w={250}
            clearable
          /> */}
          {/* <MultiSelect
            size="sm"
            placeholder="Mã chi nhánh"
            searchable
            clearable
            data={dataBranchCode}
            value={search.maCn}
            leftSection={<IconMapPin size={20} color="#15aabf" />}
            onClick={() => {
              if (dataBranchCode.length === 0) {
                DataBranchCode();
              }
            }}
            onChange={(value) => {
              handleSelectChange(value, dataBranchCode, "maCn");
              setchange((prev) => ({
                ...prev,
                maCn: true,
              }));
            }}
          /> */}

          <MultiSelect
            size="sm"
            placeholder="Mã kho"
            searchable
            clearable
            data={dataInvCode.map((x) => x.value)}
            value={search.maKhoList}
            leftSection={<IconBuildingWarehouse size={20} color="#15aabf" />}
            onClick={() => {
              //   if (change.maCn) {
              DataInvCode();
              //   }
            }}
            renderOption={renderMultiSelectOptionMaKho}
            onChange={(value) => handleChangeValue(value, "maKhoList")}
          />

          {/* <MultiSelect
            size="sm"
            placeholder="Mã vị trí"
            searchable
            clearable
            data={dataItemCode}
            value={search.maViTriList}
            leftSection={<IconMap2 size={20} color="#15aabf" />}
            onClick={() => {
              if (change.maKho) {
                DataItemCode();
                setchange((prev) => ({
                  ...prev,
                  maKho: false,
                }));
              }
            }}
            // onChange={(value) =>
            //   handleSelectChange(value, dataItemCode, "maViTriList")
            // }
          /> */}
          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (210 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    fetchData();
  }, [pagination]);
  const renderMultiSelectOptionMaKho: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>{dataInvCode.find((x) => x.value === option.value)?.label}</Text>
    </Group>
  );
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default InventoryTransactionsReport;
type Department = {
  loaiGdNhapXuat: number;
  maGd: string;
  ngayGd: string;
  loaiGd: string;
  chungTuGoc: string;
  dienGiai: string;
  idChiNhanh: number;
  maCn: string;
  tenCn: string;
  idKho: string;
  maKho: string;
  locatorId: number | null;
  maViTri: string | null;
  transactionId: number;
  itemId: number;
  itemCode: string;
  itemName: string;
  serialNumber: string;
  serialStatus: string;
  dvt: string | null;
  soLuong: number;
  soLuongNhap: number;
  soLuongXuat: number;
  donGia: number;
  maNv: string;
  tenNv: string;
  phongBanGd: string;
  idCnGd: number;
  tenCnGd: string;
  maNhomSp: string;
};
