import { createContext, useContext } from "react";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { isNullOrUndefined } from "../extension/StringExtension";
// import { NotificationExtension } from "../extension/NotificationExtension";
import { LoginModel } from "../../model/LoginModel";
import { MessageResponse } from "../../model/MessageResponse";
import { ResponseLogin } from "../../model/ResponseLogin";
import { repositoryMaster, repositoryPos } from "../_const/_constVar";
import { IAuthProvider } from "../model/_base/AuthProvider";
import { SocketExtension } from "../socket/socket";

export const AuthProvider: IAuthProvider = {
  username: "",
  async signin(
    loginModel: LoginModel
  ): Promise<MessageResponse<ResponseLogin> | undefined> {
    const repository = repositoryMaster;
    let urlCreate = `/api/v1/Auth/login`;
    let callapi = await repository.post<MessageResponse<ResponseLogin>>(
      urlCreate,
      loginModel
    );
    if (
      !isNullOrUndefined(callapi) &&
      !isNullOrUndefined(callapi?.data) &&
      callapi?.success === true
    ) {
      localStorage.setItem("token", callapi?.data.jwt);
      localStorage.setItem("userName", callapi?.data.userName);
      localStorage.setItem("fullName", callapi?.data.fullName);
      localStorage.setItem("empId", callapi?.data?.empId);
      localStorage.setItem("clientId", callapi?.data?.clientId);
      const empId = localStorage.getItem("empId");
      const response = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/detail-employee?id=${empId}`
      );

      if (response && response.success) {
        let result = response.data;
        localStorage.setItem("userLogin", JSON.stringify(result));
        SocketExtension.Connect();
      }
      if (callapi?.data.dateExpires)
        localStorage.setItem(
          "dateExpires",
          callapi?.data.dateExpires.toString()
        );
      AuthProvider.username = callapi?.data.userName;
      //  NotificationExtension.Success("Đăng nhập thành công !");
      // await PubNotication(callapi?.data.userName + " vừa đăng nhập !");
      // return callapi;
    }
    // if (!isNullOrEmpty(callapi?.message))
    //   NotificationExtension.Fails(callapi?.message ?? "Đăng nhập thất bại !");
    return callapi;
  },
  signout(): boolean {
    SocketExtension.Disconnect();
    localStorage.setItem("token", "");
    localStorage.removeItem("dateExpires");
    localStorage.setItem("userName", "");
    localStorage.removeItem("fullName");
    localStorage.setItem("empId", "");
    localStorage.removeItem("userLogin");
    localStorage.removeItem("clientId");
    AuthProvider.username = "";
    //  await PubNotication(user + " vừa đăng xuất !");
    return !localStorage.getItem("token");
  },
  isAuthenticated(): boolean {
    // Kiểm tra xem người dùng đã đăng nhập chưa

    const _token = localStorage.getItem("token");
    const _dateString = localStorage.getItem("dateExpires");
    if (_dateString) {
      const dateExpires = new Date(_dateString);
      const _check =
        _token !== undefined &&
        _token !== null &&
        _token?.length > 10 &&
        dateExpires >= new Date();
      return _check;
    }
    return (
      _token !== undefined &&
      _token !== null &&
      _token?.length > 10 &&
      _token.split(".")?.length === 3
    );
  },
};

export function protectedLoader({ request }: LoaderFunctionArgs) {
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  if (!AuthProvider.isAuthenticated()) {
    let params = new URLSearchParams();
    params.set("callback", new URL(request.url).pathname);
    return redirect("/auth/login?" + params.toString());
  }
  return null;
}

export const authContext = createContext<boolean>(false);

export function ProvideAuth({
  children,
}: {
  children: React.ReactNode;
}): React.JSX.Element {
  const auth = useProvideAuth();
  const currentURL = window.location.pathname;
  if (!auth) {
    let params = new URLSearchParams();
    params.set("callback", currentURL);
    redirect("/auth/login?" + params.toString());
  }
  return (
    <authContext.Provider value={auth.isAuthenticated}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const isAuthenticated = AuthProvider.isAuthenticated();
  return {
    isAuthenticated,
  };
}
