import {
  Box,
  Button,
  Group,
  Input,
  MultiSelect,
  MultiSelectProps,
  Text,
} from "@mantine/core";
import {
  IconBarcode,
  IconBuildingWarehouse,
  IconMapPin,
  IconSearch,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { SelectListItemModel } from "../../../model/SelectListItem";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
const InventoryReportByBarcode = () => {
  // Khởi tạo component InventoryReportList
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<InventoryItem[]>([]);
  const [dataInvCode, setDataInvCode] = useState<SelectListItemModel[]>([]);
  const [dataItemCode, setDataItemCode] = useState<SelectListItemModel[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState({
    keySearch: "",
    branchCode: [],
    invCode: [],
    itemCode: [],
  });

  type InventoryItem = {
    branchCode: string;
    branchName: string;
    countSoLuong: number;
    dvt: string | null;
    idBranch: number;
    idKho: number;
    maKho: string;
    maSp: string;
    soLuong: number | null;
    tenKho: string;
    tenSp: string;
    maBarcode: string;
    ngayNhap: string;
    soPhieuNhap: string;
    donGiaNhap: string;
  };
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };
  const handleChangeSearch = (value: string | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : 0,
    }));
  };
  const calculateDaysDifference = (date: string | Date) => {
    const currentDate = new Date(); // Lấy ngày hiện tại
    const enteredDate = new Date(date); // Chuyển đổi 'ngayNhap' thành dạng Date
    const timeDifference = currentDate.getTime() - enteredDate.getTime(); // Sự chênh lệch tính bằng milliseconds
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Chuyển đổi sang số ngày
    return daysDifference;
  };
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "branchCode",
        header: "Mã chi nhánh",
        size: 30,
      },
      {
        accessorKey: "branchName",
        header: "Tên chi nhánh",
        size: 30,
      },
      {
        accessorKey: "maKho",
        header: "Mã kho",
        size: 30,
      },
      {
        accessorKey: "tenKho",
        header: "Tên kho",
        size: 30,
      },
      {
        accessorKey: "maSp",
        header: "Mã sản phẩm",
        size: 30,
      },
      {
        accessorKey: "tenSp",
        header: "Tên sản phẩm",
        size: 30,
      },
      {
        accessorKey: "maBarcode",
        header: "Mã vạch",
        size: 30,
      },
      {
        accessorKey: "dvt",
        header: "Đơn vị tính",
        size: 30,
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng",
        size: 30,
      },
      {
        accessorKey: "ngayNhap",
        header: "Ngày nhập",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "ngayNhap",
        header: "Tuổi tồn",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {renderedCellValue
              ? calculateDaysDifference(renderedCellValue)
              : "N/A"}
          </>
        ),
      },
      {
        accessorKey: "soPhieuNhap",
        header: "Số phiếu nhập",
        size: 30,
      },
      {
        accessorKey: "donGiaNhap",
        header: "Đơn giá nhập",
        size: 30,
      },
    ],
    []
  );

  // Hàm lấy dữ liệu
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (search.invCode && search.invCode.length > 0) {
      search.invCode.forEach((invCode) => {
        url += `&InvCode=${invCode}`;
      });
    }
    if (search.itemCode && search.itemCode.length > 0) {
      search.itemCode.forEach((itemCode) => {
        url += `&ItemCode=${itemCode}`;
      });
    }
    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch.trim().toLowerCase()}`;
    }
    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblInventorySerialOnhand/get-list-serial${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const DataInvCode = async () => {
    const user = JSON.parse(localStorage.getItem("userLogin") || "{}");
    const Invcode = user?.branchId;
    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblDmInventory/get-list-by-branchs?BranchId=${Invcode}&Take=500`
      );
      if (response && response.httpStatusCode === 200) {
        const transformedData = response.data.map((item: any) => ({
          value: item.code,
          label: item.code + " - " + item.name,
        }));
        setDataInvCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const DataItemCode = async (keySearch: string) => {
    try {
      let url = "";
      if (keySearch && keySearch.length > 0) {
        url += `&ItemCodes=${keySearch}`;
      }
      const response = await repositoryDelivery.get(
        `api/v1/TblInventorySerialOnhand/get-list-item-by-inv?Skip=0&Take=50` +
          url
      );
      if (response && response.success) {
        const transformedData = response.data?.map((branch: any) => ({
          value: branch.maSp,
          label: branch.maSp + " - " + branch.tenSp,
        }));
        const uniqueData = Array.from(
          new Set(transformedData.map((item: any) => item.value))
        ).map((value) =>
          transformedData.find((item: any) => item.value === value)
        );
        setDataItemCode(uniqueData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["transactionNumber", "sourceCode"],
        right: ["statusName"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập tìm kiếm"
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            onChange={(e) => {
              handleChangeSearch(e.target.value ?? "", "keySearch");
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />

          <MultiSelect
            size="sm"
            placeholder="Mã kho"
            searchable
            clearable
            data={dataInvCode.map((x) => x.value)}
            value={search.invCode}
            renderOption={renderMultiSelectOptionInvCode}
            leftSection={<IconBuildingWarehouse size={20} color="#15aabf" />}
            onClick={() => {
              DataInvCode();
            }}
            onChange={(value) => {
              handleChangeSearchValue(value, "invCode");

              // const _key = value.target.value ?? ""; // Nếu value là mảng
              // setKeySearch(_key);
            }}
          />

          <MultiSelect
            size="sm"
            placeholder="Mã sản phẩm"
            clearable
            searchable
            data={dataItemCode.map((x) => x.value)} // Hiển thị kết quả từ API
            value={search.itemCode}
            renderOption={renderMultiSelectOption}
            leftSection={<IconBarcode size={20} color="#15aabf" />}
            onSearchChange={(value) => {
              if (value.length >= 3) {
                DataItemCode(value); // Gọi API với từ khóa tìm kiếm
              }
            }}
            onChange={(value) => {
              handleChangeSearchValue(value, "itemCode");
            }}
          />

          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (240 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    fetchData();
  }, [pagination]);
  const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>{dataItemCode.find((x) => x.value === option.value)?.label}</Text>
    </Group>
  );
  const renderMultiSelectOptionInvCode: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>{dataInvCode.find((x) => x.value === option.value)?.label}</Text>
    </Group>
  );
  return (
    <Box p="md">
      <MantineReactTable table={table} />
    </Box>
  );
};

export default InventoryReportByBarcode;
