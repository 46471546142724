import SelectOption from "./SelectOption";
import CreateCustomerSite from "./CreateCustomerSite";
import CreateCustomer from "./CreateCustomer";
import { useState } from "react";

const CreateDataView = ({ onClose }: CreateDataViewProps) => {
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const handleSelectedOption = (e: number) => {
    setSelectedOption(e);
  };

  return (
    <>
      {selectedOption === 0 ? (
        <SelectOption handleSelectedOption={handleSelectedOption} />
      ) : (
        <></>
      )}
      {selectedOption === 1 ? <CreateCustomer onClose={onClose} /> : <></>}
      {selectedOption === 2 ? <CreateCustomerSite onClose={onClose} /> : <></>}
    </>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  onClose: any;
};
