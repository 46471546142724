import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  ActionIcon,
  Popover,
  TextInput,
  Tooltip,
  NumberInput,
  Button,
  Select,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";

interface SelectBuildPcProps {
  label: string;
  value: { machineCode: string; quantity: number };
  onChange: (data: { machineCode: string; quantity: number }) => void;
  color?: string;
  width?: string;
  required?: boolean;
  readOnly?: boolean;
}

const SelectBuildPc: React.FC<SelectBuildPcProps> = ({
  label,
  value,
  onChange,
  required = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localMachineCode, setLocalMachineCode] = useState(value.machineCode);
  const [localQuantity, setLocalQuantity] = useState(value.quantity);

  // Đồng bộ giá trị local với value từ props khi nó thay đổi
  useEffect(() => {
    setLocalMachineCode(value.machineCode);
    setLocalQuantity(value.quantity);
  }, [value]);

  const handleEditClick = () => {
    setIsEditing((prev) => !prev);
  };

  const handleSave = () => {
    onChange({ machineCode: localMachineCode, quantity: localQuantity });
    setIsEditing(false);
  };

  return (
    <Flex align="center" gap="xs" w={"350px"}>
      {label && (
        <Text style={{ flex: "0 0 100px" }}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <Tooltip
        label={`Mã: ${value.machineCode}, Số lượng: ${value.quantity}`}
        arrowOffset={10}
        arrowSize={4}
        withArrow
        color="blue"
      >
        <Text fw={500} style={{ whiteSpace: "nowrap" }} truncate>
          Mã: {value.machineCode}, SL: {value.quantity}
        </Text>
      </Tooltip>
      <Popover
        opened={isEditing}
        onClose={() => setIsEditing(false)}
        width={300}
        position="bottom"
        withArrow
        withinPortal
      >
        <Popover.Target>
          <ActionIcon size={18} variant="white" onClick={handleEditClick}>
            <IconEdit size={18} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Select
            label="Mã dàn máy"
            placeholder="Chọn mã dàn máy..."
            data={[
              { value: "DAND005", label: "DAND005" },
              { value: "DAND004", label: "DAND004" },
            ]}
            value={localMachineCode}
            onChange={(value) => setLocalMachineCode(value || "")}
            mb={8}
          />
          <NumberInput
            label="Số lượng"
            placeholder="Nhập số lượng..."
            value={localQuantity}
            onChange={(value) => setLocalQuantity(Number(value) || 0)}
            mb={8}
            min={0}
          />
          <Flex justify="end">
            <Button color="blue" variant="filled" onClick={handleSave}>
              Lưu
            </Button>
          </Flex>
        </Popover.Dropdown>
      </Popover>
    </Flex>
  );
};

export default SelectBuildPc;
