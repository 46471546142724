import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  NumberFormatter,
  Tabs,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryPos } from "../../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../../_base/extension/NotificationExtension";
import { orange, peach, sky_blue } from "../../../../../const/variables";
import { MessageResponse } from "../../../../../model/MessageResponse";
import {
  itemAddOnPromotionModel,
  itemGiftPromotionModel,
  TblItemPromotionModel,
} from "../../../../../model/TblItem";
import AddOnTable from "./AddOnTable";
import GiftTable from "./GiftTable";
import "./voucher.css";
import VoucherList from "./VoucherList";

interface VoucherProps {
  branchId: number;
  idItem: string;
  price: number;
  handleSelectProductAddOn: (
    product: itemAddOnPromotionModel,
    idItem: string
  ) => Promise<void>;
  handleSelectProductGift: (
    product: itemGiftPromotionModel,
    idItem: string
  ) => Promise<void>;
  handleSelectPromotionMain: any;
}

const VoucherRefactor = ({
  idItem,
  branchId,
  price,
  handleSelectProductAddOn,
  handleSelectProductGift,
  handleSelectPromotionMain,
}: VoucherProps) => {
  const [visible, { open, close }] = useDisclosure(false);
  const [dataPromotion, setDataPromotion] = useState<TblItemPromotionModel[]>(
    []
  );
  const [selectedPromotion, setSelectedPromotion] =
    useState<TblItemPromotionModel | null>(null);

  const [listSelectedAddOn, setListSelectedAddOn] = useState<
    itemAddOnPromotionModel[]
  >([]);

  const [listSelectedGift, setListSelectedGift] = useState<
    itemGiftPromotionModel[]
  >([]);

  const handleOptionChange = (option: TblItemPromotionModel) => {
    setSelectedPromotion(option);
  };

  // const handleChangeItemGiftPromotion = (
  //   promotionItem: itemGiftPromotionModel
  // ) => {
  //   setSelectedItemGiftPromotion((prevData) => {
  //     return prevData.includes(promotionItem)
  //       ? prevData.filter((item) => item !== promotionItem)
  //       : [...prevData, promotionItem];
  //   });
  // };

  const handleSaveVoucher = () => {
    if (listSelectedAddOn[0]) {
      handleSelectProductAddOn(listSelectedAddOn[0], idItem);
    }
    if (listSelectedGift[0]) {
      handleSelectProductGift(listSelectedGift[0], idItem);
    }

    handleSelectPromotionMain(selectedPromotion);

    modals.closeAll();
  };

  useEffect(() => {
    const handleFetchDataVoucher = async () => {
      open();
      const response = await repositoryPos.post<MessageResponse<any>>(
        "/api/v1/TblItem/item-promotion",
        {
          id: idItem,
          price: price,
          branch: branchId,
        }
      );

      if (response && response.success) {
        let result = response.data;
        setDataPromotion(result || []);
        if (result?.length > 0) {
          setSelectedPromotion(result[0]);
        }
      } else {
        NotificationExtension.Fails("Lấy thông tin khuyến mại thất bại !");
      }
      close();
    };

    if (idItem) {
      handleFetchDataVoucher();
    }
  }, [idItem, price, close, open, branchId]);

  return (
    <Box mih={550}>
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Grid>
        <Grid.Col span={{ base: 5, md: 4, xs: 4 }}>
          <VoucherList
            dataVoucher={dataPromotion}
            selectedPromotion={selectedPromotion}
            handleOptionChange={handleOptionChange}
            peach={peach}
            orange={orange}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 7, md: 8, xs: 8 }}>
          {selectedPromotion ? (
            <Tabs defaultValue="gift">
              <Tabs.List>
                <Tabs.Tab value="gift">Mặt hàng quà tặng</Tabs.Tab>
                <Tabs.Tab value="addOn">Mặt hàng mua kèm</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="gift">
                <GiftTable
                  selectedPromotion={selectedPromotion}
                  listSelectedGift={listSelectedGift}
                  setListSelectedGift={setListSelectedGift}
                />
              </Tabs.Panel>

              <Tabs.Panel value="addOn">
                <AddOnTable
                  setListSelectedAddOn={setListSelectedAddOn}
                  listSelectedAddOn={listSelectedAddOn}
                  selectedPromotion={selectedPromotion}
                />
              </Tabs.Panel>
            </Tabs>
          ) : (
            <Box>
              <Center mt={"lg"}>Vui lòng chọn 1 chính sách khuyến mãi</Center>
            </Box>
          )}
        </Grid.Col>
      </Grid>

      <Flex align={"center"} justify={"space-between"}>
        <Group mt="xs">
          <Text fw={700} mb="5" size="md">
            Tổng tiền được giảm:{" "}
            <Text span c={"red"} fw={700}>
              <NumberFormatter
                value={
                  selectedPromotion?.tblPromotionItemPrimary[0]
                    ? price -
                        (selectedPromotion?.tblPromotionItemPrimary[0]
                          ?.priceDiscount || 0) || 0
                    : 0
                }
                thousandSeparator="."
                decimalSeparator=","
                suffix="₫"
              />
            </Text>
          </Text>
        </Group>
        <Group
          justify="end"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={<IconWindow size={18} />}
          >
            Đóng
          </Button>
          <Button
            color={sky_blue.base}
            leftSection={<IconCheck size={18} />}
            onClick={() => handleSaveVoucher()}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Flex>
    </Box>
  );
};

export default VoucherRefactor;
