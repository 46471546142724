import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Modal,
  NumberFormatter,
  NumberInput,
  ScrollArea,
  Select,
  Switch,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import {
  useDebouncedValue,
  useDisclosure,
  useHotkeys,
  useMediaQuery,
} from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconBellCheck,
  IconBellQuestion,
  IconBellRinging,
  IconBellX,
  IconBrandCashapp,
  IconCheck,
  IconGripVertical,
  IconMaximize,
  IconMinus,
  IconPlus,
  IconSearch,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import cx from "clsx";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import classes from "../../../_base/component/_layout/TableScrollArea.module.css";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import EditableTextWithOptions from "../../../common/selectValue";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDebtGroup } from "../../../model/TblDebtGroup";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import { SelectListItemBase } from "../../../_base/model/_base/SelectListItemBase";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { IconMaximizeOff } from "@tabler/icons-react";
import classesButton from "../Bill/buttonDisible.module.css";
import {
  AccountingCenter,
  CreateReceiptParentRequest,
  DepartmentUser,
  DetailsOrderHeader,
  FundDetails,
  Receipt,
  SearchParams,
  UserData,
} from "./model/modelPaymentReceipt";
import { green } from "../../../const/variables";

export interface UserDataInterface {
  id: number;
  code: string;
  fullname: string;
  dateOfBirth: string;
  gender: number | null;
  departmentId: number | null;
  departmentCode: string | null;
  departmentName: string;
  roleId: number | null;
  roleName: string;
  managerName: string | null;
  address: string | null;
  phoneNumber: string;
  active: boolean;
  email: string;
  description: string | null;
  accountingCenterId: number | null;
  managerId: number | null;
  isCrm: boolean | null;
  currentCardId: number | null;
  partCode: string | null;
  createDate: string;
  listBranch: any[] | null;
  listInventory: any[] | null;
  listGroup: any[] | null;
  branchId: number | null;
  brachName: string | null;
  workAddress: string | null;
  listDepartment: any[] | null;
  attribute1: any;
  attribute2: any;
  attribute3: any;
  attribute4: any;
  attribute5: any;
  attribute6: any;
  attribute7: any;
  attribute8: any;
  attribute9: any;
  attribute10: any;
  isAccountant: boolean | null;
  isInventory: boolean | null;
}

export const PaymentReceipt = () => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [height, setHeight] = useState(0);
  const [rowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [heightContent, setHeightContent] = useState(0);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [options, setOptions] = useState("488");
  const [branchAll, setBranchAll] = useState<DepartmentUser[]>([]);
  const [dataListBranchOrder, setDataListBranchOrder] = useState<any>();
  const [dataAlloation, setDataAlloation] = useState(false);
  const [totalAllocatedAmount, setTotalAllocatedAmount] = useState<any>(0);
  const [totalAmountRemaining, setTotalAmountRemaining] = useState<number>(0);
  const [dataListReceipt, setDataListReceipt] = useState<Receipt[]>([]);
  const [dataListBranch, setDataListBranch] = useState<Receipt[]>([]);

  const [tempSelectedItems, setTempSelectedItems] = useState<Receipt[]>([]);
  const [finalSelectedItems, setFinalSelectedItems] = useState<Receipt[]>([]);

  const [searchKey, setSearchKey] = useState<any>();
  const [searchTO, setSearchTo] = useState<any>();
  const [searchFrom, setSearchFrom] = useState<any>();
  const [completeTime, setCompleteTime] = useState<Date | null>(null);

  const [handelKey, setHandelKey] = useState<any>();
  const [paymenBankAccount, setPaymenBankAccount] = useState<any[]>();
  const [selectPayertype, setSelectPayertype] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<TblDMPaymentMethod[]>();
  const [paymentTypes, setPaymentTypes] = useState<any[]>();
  const [listBranch, setListBranch] = useState<any[]>([]);
  const [listEmployee, setListEmployee] = useState<AccountingCenter[]>([]);
  const [valueTotalAmount, setValueTotalAmout] = useState<any>(null);
  const [valueTotalAmountPaid, setValueTotalAmoutPaid] = useState<any>(null);
  const [valueTotalRemaining, setValueTotalRemaining] = useState<any>(null);
  const [handleState, setHandleState] = useState<boolean>(false);
  const [changeValueTotal, setChangeValueTatal] = useState<any>(0);
  const [dataGetReceiptParent, setDataGetReceiptParent] = useState<any>();
  const [listdata, setListData] = useState<FundDetails>();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [badgeContent, setBadgeContent] = useState<string>(
    "Số tiền không xác định"
  );
  const [badgeColor, setBadgeColor] = useState<string>("gray");
  const [badIcon, setBadIcon] = useState<React.ReactNode>(null);
  const today = new Date();
  const [isError] = useState(false);
  const [isLoading] = useState(false);
  const [isRefetching] = useState(false);
  const [sorting] = useState<MRT_SortingState>([]);
  const userName = localStorage.getItem("userLogin") || "";
  const [statusReponse, setStatusReponse] = useState<any>();
  const [listEmployeeID, setListEmployeeID] = useState<UserDataInterface[]>([]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [scrolled, setScrolled] = useState(false);
  const [inputValues, setInputValues] = useState<{ [key: number]: any }>({});
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isRunning, setIsRunning] = useState<boolean>(true);
  const [stoppedTime, setStoppedTime] = useState<Date | null>();
  const [branchDeparment, setBranchDeparment] = useState<DepartmentUser>();
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [handeDateStart, setHanDateStart] = useState<any>();
  const [handeDateEnd, setHanDateEnd] = useState<any>();
  const [debouncedChangeValueTotal] = useDebouncedValue(changeValueTotal, 500);
  const [debouncedChangeValueAmout] = useDebouncedValue(
    totalAllocatedAmount,
    500
  );
  const [showBadge, setShowBadge] = useState<boolean>(true);
  const [handelDateCurrent, setHandelDateCurrent] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const [groupData, setGroupData] = useState({
    id: dataGetReceiptParent?.attribute2?.id || "",
    groupName: dataGetReceiptParent?.attribute2?.groupName || "",
    groupCreateByName: dataGetReceiptParent?.attribute2?.createByName || "",
  });
  const [dataBranch, setDataBranch] = useState<SelectListItemBase[]>([]);
  const isSmallScreen = useMediaQuery("(max-width: 900px)", true, {
    getInitialValueInEffect: false,
  });
  const [currentTime, setCurrentTime] = useState<Date | null>();
  const [inputValue, setInputValue] = useState("");
  const [isCollapse, { toggle: toggleCollapse }] = useDisclosure(false);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    searchKey: "",
    searchFrom: "",
    searchTo: "",
    handeDateStart: null,
    handeDateEnd: null,
    searchFromFull: "",
    searchToFull: "",
  });

  const [formErrors, setFormErrors] = useState({
    payerfullname: "",
    accountfund: "VNĐ",
    codefund: "",
    createby: 0,
    anothername: "",
    createdDate: "",
    creatorbranch: "",
    creatordepartment: "424",
    creatorfullname: "",
    creatorsalesbranch: "",
    creatorworkplace: "",
    fundbranch: "",
    funddepartment: "",
    fundsalesbranch: "",
    fundworkplace: "",
    funfullname: "",
    id: 0,
    payerdescription: "",
    payernote: "",
    payertype: "",
    receiptNumber: "",
    totalAmount: 0,
    type: 0,
  });

  console.log(dataBranch, "dataBranch");

  const columns = React.useMemo<MRT_ColumnDef<DetailsOrderHeader>[]>(
    () => [
      {
        accessorKey: "STT",
        header: "STT",
        enableColumnActions: false,
        enableEditing: false,
        enableSorting: false,
        size: 50,
        Cell: ({ renderedCellValue, row }: any) => (
          <Text fw={600}>{row.index + 1}</Text>
        ),
      },
      {
        accessorKey: "orderNumber",
        header: "Số đơn hàng",
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, cell, row }) => (
          <Tooltip label="Xem chi tiết (Chức năng đăng cập nhật)">
            <Badge
              radius="sm"
              variant="dot"
              size="md"
              // onDoubleClick={() => handleCheckView(row)}
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "attribute2",
        header: "Mã đối tượng công nợ",
        size: 200,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => {
          const value = row?.original?.attribute16 ?? "";
          return (
            <Text fw={600} w={150}>
              {value}
            </Text>
          );
        },
        // Cell: ({ cell, row, table, column }) => {
        //   return (
        //     <TextInput
        //       placeholder="Vui lòng nhập"
        //       flex={1}
        //       value={
        //         inputValues[row.index]?.attribute1 ?? row?.original?.attribute1
        //       }
        //       disabled={statusReponse}
        //       w={185}
        //       onBlur={(e) => {
        //         handleInputChange(row, "attribute1", e.currentTarget.value);
        //         handleInputChange(
        //           inputValues[row.index]?.attribute2 ??
        //             row?.original?.attribute2,
        //           "attribute2",
        //           e
        //         );
        //       }}
        //       onDoubleClick={() => openFormDebt("attribute1", row)}
        //     />
        //   );
        // },
      },
      {
        accessorKey: "attribute7",
        header: "Tên đối tượng công nợ",
        size: 200,
        enableEditing: false,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => {
          const value = row?.original?.attribute8 ?? "";
          const code = value.split("||")[0] || "";
          const text = code === "null" ? "" : code;
          return (
            <Text fw={600} w={150}>
              {text}
            </Text>
          );
        },
        // Cell: ({ row }) => {
        //   return (
        //     <Text>
        //       {inputValues[row.index]?.attribute2 ?? row?.original?.attribute2}
        //     </Text>
        //   );
        // },
      },
      {
        accessorKey: "amountTotal",
        header: "Tổng tiền hóa đơn",
        enableEditing: false,
        enableSorting: false,

        enableColumnActions: false,

        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              style={{ textAlign: "center" }}
              thousandSeparator
              value={row.original.amountTotal}
            ></NumberFormatter>
          </>
        ),
      },
      {
        accessorKey: "amountPaid",
        header: "Số đã thanh toán",
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              thousandSeparator
              value={row.original.amountPaid}
            ></NumberFormatter>
          </>
        ),
      },
      {
        accessorKey: "amountRemaining",
        header: "Số còn phải TT",
        enableColumnActions: false,
        enableSorting: false,
        size: 100,
        enableEditing: false,
        Cell: ({ row }) => {
          const allocatedAmount = row.original.allocatedAmount;
          const amountRemaining = row.original.amountRemaining;
          const displayAmount =
            allocatedAmount >= amountRemaining
              ? 0
              : amountRemaining - allocatedAmount;

          return (
            <NumberFormatter
              thousandSeparator
              value={!dataAlloation ? amountRemaining : displayAmount}
            />
          );
        },
      },
      {
        accessorKey: "key",
        header: "Số tiền phân bổ",
        enableSorting: false,
        enableColumnActions: false,
        size: 150,
        enableEditing: true,
        Cell: ({ cell, row }) => {
          return (
            <NumberInput
              hideControls
              readOnly={statusReponse}
              disabled={!dataAlloation}
              placeholder="Nhập số tiền..."
              value={
                dataAlloation &&
                row.original.allocatedAmount <= row.original.amountRemaining
                  ? row.original.allocatedAmount
                  : ""
              }
              thousandSeparator
              onChange={(value) =>
                handleInputChange(
                  row.index,
                  "allocatedAmount",
                  typeof value === "number" &&
                    value <= row.original.amountRemaining
                    ? value.toString()
                    : ""
                )
              }
            />
          );
        },
        Edit: ({ cell, row }) => {
          return (
            <NumberInput
              hideControls
              disabled={statusReponse ? statusReponse : !dataAlloation}
              placeholder="Nhập số tiền..."
              value={
                dataAlloation &&
                row.original.allocatedAmount <= row.original.amountRemaining
                  ? row.original.allocatedAmount
                  : ""
              }
              thousandSeparator
              onChange={(value) =>
                handleInputChange(
                  row.index,
                  "allocatedAmount",
                  typeof value === "number" &&
                    value <= row.original.amountRemaining
                    ? value.toString()
                    : ""
                )
              }
            />
          );
        },
      },
      {
        accessorKey: "note",
        header: "Diễn giải",
        enableSorting: false,
        enableColumnActions: false,
        enableEditing: true,
        Edit: ({ row }) => {
          const initialValue = row.original.orderNumber;
          return (
            <TextInput
              defaultValue={initialValue ?? ""}
              readOnly={statusReponse}
              placeholder="Nhập ghi chú..."
              onChange={(event) =>
                handleInputChange(row.index, "note", event.target.value)
              }
            />
          );
        },
        Cell: ({ row }) => {
          const initialValue = row.original.orderNumber;
          return (
            <TextInput
              defaultValue={initialValue ?? ""}
              readOnly={statusReponse}
              placeholder="Nhập ghi chú..."
              onChange={(event) =>
                handleInputChange(row.index, "note", event.target.value)
              }
            />
          );
        },
      },
      {
        accessorKey: "action",
        header: "Xóa",
        enablePinning: false,
        size: 60,
        enableSorting: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Flex justify="center">
            <Tooltip label="Xóa đơn">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                disabled={statusReponse}
                onClick={() => deleteInvoice(row.original.orderNumber)}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ),
      },
    ],
    [
      validationErrors,
      finalSelectedItems,
      dataAlloation,
      inputValues,
      statusReponse,
    ]
  );

  const table = useMantineReactTable<any>({
    columns,
    data: finalSelectedItems,
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: true,
    enableBatchRowSelection: true,
    enableBottomToolbar: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        display: "none",
      },
    },

    mantineBottomToolbarProps: {
      style: {
        display: "none",
      },
    },

    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id?.toString(),
    mantineTableContainerProps: {
      style: {
        maxHeight: height - 95,
        minHeight: height - 95,
        // borderTop: `5px solid #ec1c24`,
      },
    },
    mantineTableProps: {
      striped: true,
    },
    enablePinning: true,
    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-actions"],
        right: ["amountRemaining", "key", "action"],
      },
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    positionActionsColumn: "last",
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: false,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    onCreatingRowCancel: () => setValidationErrors({}),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    enableColumnPinning: true,
  });

  const handleCheckView = async (row: any) => {
    if (!row || !row.original) {
      console.error("Row or row.original is null or undefined");
      return;
    }

    const orderNumber = row.original.orderNumber;
    if (!orderNumber) {
      console.error("Số thứ tự là null hoặc không xác định");
      return;
    }
    const url = `/api/v1/CreateSellItem/details?orderNumber=${orderNumber}`;
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response && response.success) {
        navigate("/sell/view-order", {
          state: {
            id: row.original.id,
            orderNumber: row.original.orderNumber,
            orderType: row.original.orderType,
            actionType: "view",
          },
        });
      } else {
        console.error("Lỗi khi lấy thông tin chi tiết đơn hàng", response);
      }
    } catch (error) {
      console.error("Lỗi khi lấy thông tin chi tiết đơn hàng", error);
    }
  };

  const getDataFromLocalStorage = (key: string): UserData | null => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const managerOrder = () => (
    <>
      <ScrollArea pr={16} scrollbars="y" h={height + 60} offsetScrollbars>
        <Grid>
          <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex mt={10} align="center" gap="xs">
              <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                Mã phiếu thu
              </Text>
              <Text fw={500}>{dataGetReceiptParent?.receiptNumber || ""}</Text>
            </Flex>
          </GridCol>
          <GridCol pb={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex mt={10} align="center" gap="xs">
              <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                Thời gian tạo
              </Text>
              <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                <>{moment(currentTime).format("DD-MM-YYYY HH:mm:ss")}</>
              </Text>
            </Flex>
          </GridCol>
          <GridCol
            pb={0}
            offset={{ base: 0, md: 0, lg: 6 }}
            span={{ base: 12, md: 12, lg: 6 }}
          >
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px", whiteSpace: "nowrap" }}>
                Thời gian hoàn thành
              </Text>
              <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                {handelDateCurrent && statusReponse ? (
                  <>{moment(completeTime).format("DD-MM-YYYY HH:mm:ss")}</>
                ) : null}
              </Text>
            </Flex>
          </GridCol>
          <Grid.Col pt={0} pb={0} span={12}>
            <Divider
              my="xs"
              label={
                <Text size="lg" fw={600} c="#000">
                  Người lập phiếu thu
                </Text>
              }
              labelPosition="left"
            />
          </Grid.Col>
          <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Chi nhánh
              </Text>
              <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                {dataBranch.find(
                  (item) =>
                    String(item.value) === String(listdata?.creatorbranch)
                )?.text ?? ""}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Họ và tên
              </Text>
              <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                {`${userData?.fullName ?? ""}`}
              </Text>
            </Flex>
          </Grid.Col>

          <GridCol pt={0} pb={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                ĐĐ làm việc
              </Text>
              <Text fw="600">{branchDeparment?.address ?? ""}</Text>
            </Flex>
          </GridCol>
          <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Chức danh
              </Text>
              <Text fw="600">{userData?.roleName}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Phòng ban
              </Text>
              <Text fw={600}>
                {branchAll?.find(
                  (item) =>
                    String(item.departmentId) ===
                    String(listdata?.creatordepartment)
                )?.name ?? ""}
              </Text>
            </Flex>
          </Grid.Col>
          <GridCol pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex align="center">
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                CN bán <span style={{ color: "red" }}>*</span>
              </Text>
              <Select
                key={formData.payertype}
                searchable
                readOnly={statusReponse}
                flex={1}
                placeholder="Vui lòng chọn CN bán"
                data={listBranch.map((e) => e.name) ?? []}
                value={
                  listBranch?.find(
                    (item) =>
                      String(item.id) === String(formData.creatorsalesbranch)
                  )?.name ?? ""
                }
                onChange={(selectedDescription) => {
                  const selectedOption = listBranch?.find(
                    (item) => item.name === selectedDescription
                  );
                  if (selectedOption) {
                    handleChange("creatorsalesbranch", selectedOption.id);
                  }
                }}
                allowDeselect={false}
              />
            </Flex>
          </GridCol>
          <Grid.Col pb={0} pt={0} span={12}>
            <Divider
              my="xs"
              label={
                <Text size="lg" fw={600} c="#000">
                  Người nộp tiền
                </Text>
              }
              labelPosition="left"
            />
          </Grid.Col>
          <GridCol
            pt={0}
            style={{ display: "flex", alignItems: "center" }}
            span={{ base: 12, md: 12, lg: 6 }}
          >
            <EditableTextWithOptions
              required
              label="Loại"
              readOnly={statusReponse ? true : false}
              value={formData.payertype}
              options={["Khách hàng", "Nhân viên"]}
              onChange={(value) => {
                handleChange("payertype", value);
                handleChange("creatorbranch", "");
                handleChange("creatorsalesbranch", "");
                setInputValue("");
                handleChange("payerdescription", null);
                setChangeValueTatal(0);
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  funfullname: "",
                  payerdescription: "",
                }));
              }}
              width="100px"
            />
          </GridCol>

          <GridCol pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Group style={{ flexWrap: "nowrap" }}>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Họ và tên <span style={{ color: "red" }}>*</span>
              </Text>
              {selectPayertype ? (
                <TextInput
                  readOnly={statusReponse}
                  size="sm"
                  flex={1}
                  placeholder="Họ và tên"
                  value={inputValue}
                  error={inputValue ? null : formErrors.payertype}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    handleChange("anothername", e.target.value);
                  }}
                  rightSection={<IconMinus size={"12px"} />}
                />
              ) : (
                <Select
                  searchable
                  clearable
                  flex={1}
                  readOnly={statusReponse}
                  placeholder="Vui lòng chọn tên"
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  limit={50}
                  data={listEmployeeID.slice(1).map((item) => {
                    return {
                      label: item.fullname + " - " + item.code,
                      value: String(item.id ?? null),
                    };
                  })}
                  error={formErrors.funfullname}
                  onChange={(selectedDescription) => {
                    if (!selectedDescription) {
                      handleChange("funfullname", "");
                    } else {
                      const selectedOption = listEmployeeID.find(
                        (item) =>
                          String(item.id) === String(selectedDescription)
                      );
                      if (selectedOption) {
                        handleChange("funfullname", selectedOption.id);
                        handleChange("fundbranch", selectedOption.branchId);
                      }
                    }
                  }}
                />
              )}
            </Group>
          </GridCol>

          <GridCol pt={0} span={{ base: 12, md: 6, lg: 6 }}></GridCol>
          <GridCol span={{ base: 12, md: 12, lx: 12, lg: 12 }}>
            <Group>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Nội dung
              </Text>
              <Textarea
                size="sm"
                flex={1}
                rows={3}
                readOnly={statusReponse}
                value={formData.payerdescription ?? ""}
                placeholder="Nhập nội dung"
                onChange={(e) =>
                  handleChange("payerdescription", e.currentTarget.value)
                }
              />
            </Group>
          </GridCol>
          <GridCol>
            <Group>
              <Text
                style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                fw={600}
                c="red"
              >
                Tổng tiền <span style={{ color: "red" }}>*</span>
              </Text>
              <NumberInput
                size="sm"
                readOnly={statusReponse}
                width={300}
                allowNegative={false}
                thousandSeparator=","
                suffix=" VND"
                placeholder="Nhập số tiền"
                hideControls
                value={changeValueTotal}
                rightSection={<IconMinus size={12} />}
                onChange={(value) => {
                  if (value !== undefined && value !== null) {
                    const numericValue = Number(value);

                    if (numericValue < 1) {
                      handleChangeValueTotal(0); // Set to 0 if less than 1
                    } else {
                      handleChangeValueTotal(numericValue); // Accept values between 1 and 9
                    }
                  } else {
                    handleChangeValueTotal(0); // Default to 0 if input is empty or undefined
                  }
                }}
              />
            </Group>
          </GridCol>
        </Grid>
        <Flex mt={20} align="end" gap={15}>
          <Button
            className={classesButton.button}
            variant="outline"
            color="green"
            disabled={
              statusReponse ||
              (selectPayertype //true là khác hàng, false là nhân viên
                ? changeValueTotal !== totalAllocatedAmount ||
                  dataAlloation !== true ||
                  totalAllocatedAmount === 0 ||
                  changeValueTotal === 0
                : changeValueTotal !== totalAllocatedAmount ||
                  dataAlloation !== true ||
                  totalAllocatedAmount === 0 ||
                  changeValueTotal === 0)
            }
            onClick={() => {
              handleComplete();
              fetchCreateReceiptParent(true);
              onChangeTimeCurrent();
            }}
            leftSection={<IconCheck size={14} />}
          >
            Hoàn thành
          </Button>
        </Flex>
      </ScrollArea>
    </>
  );

  const updateSearchParams = (
    key: keyof SearchParams,
    value: string | Date | null
  ) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      [key]: value,
    }));
  };

  //search Chọn hóa đơn
  const handleSearchKey = (value: string) =>
    updateSearchParams("searchKey", value);

  const [searchFromDisplay, setSearchFromDisplay] = useState(
    searchParams.searchFromFull || searchParams.searchFrom || ""
  );
  const [searchToDisplay, setSearchToDisplay] = useState(
    searchParams.searchToFull || searchParams.searchTo || ""
  );

  const handleSearchFrom = (value: string) => {
    const result = value.match(/\d+$/);
    const extractedValue = result ? result[0] : value;

    setSearchFromDisplay(value);
    updateSearchParams("searchFromFull", value);
    updateSearchParams("searchFrom", extractedValue);
  };

  const handleSearchTo = (value: string) => {
    const result = value.match(/\d+$/);
    const extractedValue = result ? result[0] : value;

    setSearchToDisplay(value);
    updateSearchParams("searchToFull", value);
    updateSearchParams("searchTo", extractedValue);
  };

  const handleDateChange = (date: Date | null, type: "start" | "end") => {
    if (type === "start") {
      setStartDate(date);
      if (endDate && date && endDate < date) {
        setEndDate(date);
        updateSearchParams("handeDateEnd", date);
      }
      updateSearchParams("handeDateStart", date);
    } else {
      if (startDate && date && date < startDate) {
        return;
      }
      setEndDate(date);
      updateSearchParams("handeDateEnd", date);
    }
  };

  const fetchData = async () => {
    await fetchDataListReceipt();
  };

  const allSum = () => {
    const totalAmount = finalSelectedItems.reduce((sum, item) => {
      return sum + (item.amountTotal || 0);
    }, 0);

    const totalAmountPaid = finalSelectedItems.reduce((sum, item) => {
      return sum + (item.amountPaid || 0);
    }, 0);

    const totalAmountRemaining = finalSelectedItems.reduce((sum, item) => {
      return (
        sum +
        (!dataAlloation ? item.amountRemaining || 0 : item.computedField || 0)
      );
    }, 0);
    setValueTotalAmout(totalAmount);
    setValueTotalAmoutPaid(totalAmountPaid);
    setValueTotalRemaining(totalAmountRemaining);
  };

  useEffect(() => {
    allSum();
  }, [finalSelectedItems, dataAlloation]);

  const Alloation = () => {
    setDataAlloation(true);
  };

  const handleChangeValueTotal = (val: any) => {
    setChangeValueTatal(val);
  };

  const handleComplete = () => {
    setCompleteTime(new Date());
  };

  const StateHandleSave = () => {
    setHandleState(true);
  };
  const fetchDataEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployee(result);
    }
  };

  const fetchDataGetAll = async () => {
    try {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentUser[]>
      >(`/api/v1/TblDepartment/get-all`);

      if (response && response.success) {
        const result = response.data;
        setBranchAll(result);
      } else {
        console.error("Failed to fetch departmentFund details");
      }
    } catch (error) {
      console.error("Error fetching departmentFund details", error);
    }
  };

  const fetchDataEmployeeBranchID = async () => {
    const response = await repositoryPos.get<
      MessageResponse<UserDataInterface[]>
    >(`/api/v1/TblDmEmployee/get-all`);

    if (response && response.success) {
      let result = response.data;
      setListEmployeeID(result);
    }
  };

  const validateData = (): boolean => {
    return finalSelectedItems.every((item: any) => item.allocatedAmount > 0);
  };

  const deleteInvoice = (value: any) => {
    const filterItems = (items: any[]) =>
      items.filter((item) => item.orderNumber !== value);

    const updatedFinalItems = filterItems(finalSelectedItems);
    const updatedTempItems = filterItems(tempSelectedItems);

    setFinalSelectedItems(updatedFinalItems);
    setTempSelectedItems(updatedTempItems);

    const newTotal = updatedFinalItems.reduce(
      (acc, item) => acc + (item.allocatedAmount || 0),
      0
    );
    setTotalAllocatedAmount(newTotal);
  };

  const onChangeTimeCurrent = () => {
    setHandelDateCurrent(true);
  };

  const handleInputChange = (index: number, field: string, value: any) => {
    const updatedItems = [...finalSelectedItems];

    // Convert value to a numeric format if the field is `allocatedAmount`
    const numericValue =
      field === "allocatedAmount" && typeof value === "string"
        ? parseFloat(value.replace(/,/g, "")) || 0
        : value || 0;

    updatedItems[index] = {
      ...updatedItems[index],
      [field]: numericValue,
    };

    updatedItems[index].computedField = !dataAlloation
      ? updatedItems[index].amountRemaining
      : Math.max(
          0,
          updatedItems[index].amountRemaining -
            (updatedItems[index].allocatedAmount || 0)
        );

    setFinalSelectedItems(updatedItems);

    const newTotal = updatedItems.reduce(
      (acc, item) => acc + (item.allocatedAmount || 0),
      0
    );
    setTotalAllocatedAmount(newTotal);
  };

  const [formData, setFormData] = useState({
    accountfund: 0,
    codefund: "111101",
    createby: 0,
    createdDate: moment(stoppedTime, "DD-MM-YYYY HH:mm:ss")
      .add(7, "hours")
      .toISOString(),
    completiontime: moment(currentTime, "DD-MM-YYYY HH:mm:ss")
      .add(7, "hours")
      .toISOString(),
    creatorbranch: "",
    creatordepartment: 424,
    creatorfullname: `${userName} - H567`,
    creatorsalesbranch: "",
    creatorworkplace: `${branchDeparment?.address ?? ""}`,
    fundbranch: "",
    funddepartment: "",
    anothername: "",
    fundsalesbranch: "",
    fundworkplace: "",
    payerdescription: "",
    payerfullname: "",
    funfullname: "",
    payernote: "",
    payertype: "Nhân viên",
    receiptNumber: dataGetReceiptParent?.receiptNumber,
    totalAmount: changeValueTotal,
    type: 1,
    typeReceipt: dataGetReceiptParent?.typeReceipt,
  });

  const formatDate = (date: Date | null): string => {
    if (date === null) return "";
    return moment(date).format("YYYY-MM-DD");
  };

  const validateForm = () => {
    const hasErrors = Object.values(validationErrors).some(
      (error) => error !== undefined
    );
    setIsFormValid(!hasErrors);
  };

  const handleChange = (
    field: string,
    value: string | number | Date | null
  ) => {
    if (field === "funfullname" && formErrors.funfullname) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        funfullname: value ? "" : "Vui lòng nhập họ và tên",
      }));
    }

    if (field === "anothername" && formErrors.anothername) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        anothername: value ? "" : "Vui lòng nhập họ và tên",
      }));
    }

    if (field === "creatorsalesbranch" && value) {
      open();
      setDataListBranchOrder(value);
    }

    if (field === "payertype") {
      if (value === "Khách hàng") {
        setSelectPayertype(true);
      } else {
        setSelectPayertype(false);
      }
    }

    if (field === "branch") {
      const matchedObject = dataListBranch.find(
        (element: any) => element.text === value
      );
      if (matchedObject) {
        setOptions(matchedObject.value);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: value instanceof Date ? value.toISOString() : value,
    }));
    validateForm();
  };

  const filteredDataList = useMemo(
    () =>
      dataListReceipt.filter((receipt) =>
        receipt.orderNumber.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [searchQuery, dataListReceipt]
  );

  const handleCheckboxChange = (item: Receipt) => {
    setTempSelectedItems((prevSelectedItems) => {
      if (selectPayertype) {
        return [item];
      } else {
        const isItemSelected = prevSelectedItems.some(
          (selectedItem) => selectedItem.id === item.id
        );
        return isItemSelected
          ? prevSelectedItems.filter(
              (selectedItem) => selectedItem.id !== item.id
            )
          : [...prevSelectedItems, item];
      }
    });
  };

  const handleConfirm = () => {
    const updatedItems = tempSelectedItems.map((item) => ({
      ...item,
      allocatedAmount: item.amountRemaining,
      attribute1: groupData.id,
      attribute2: groupData.groupName,
    }));

    setInputValue(
      tempSelectedItems.length > 0 ? tempSelectedItems[0].attribute1 ?? "" : ""
    );
    setFinalSelectedItems(updatedItems);

    const newTotal = tempSelectedItems.reduce(
      (acc, item) => acc + (item.amountRemaining || 0),
      0
    );
    setTotalAllocatedAmount(newTotal);
    // setTempSelectedItems([]);
    close();
  };

  const fetchCreateReceiptParent = async (handleSave?: boolean) => {
    let isValid = true;
    const errors = {
      payerfullname: "",
      accountfund: "VND",
      codefund: "",
      createby: 0,
      anothername: "",
      createdDate: "",
      creatorbranch: "",
      creatordepartment: "",
      creatorfullname: "",
      creatorsalesbranch: "",
      creatorworkplace: "",
      fundbranch: "",
      funddepartment: "",
      fundsalesbranch: "",
      fundworkplace: "",
      funfullname: "",
      id: 0,
      payerdescription: "",
      payernote: "",
      payertype: "",
      receiptNumber: "",
      totalAmount: 0,
      type: 0,
    };

    if (formData.payertype === "Khách hàng") {
      if (!formData.anothername) {
        errors.anothername = "Vui lòng nhập họ và tên!";
        isValid = false;
      }
    } else {
      if (!formData.funfullname) {
        errors.funfullname = "Vui lòng nhập họ và tên!";
        isValid = false;
      }
    }

    setFormErrors(errors);

    if (!isValid) {
      notifications.show({
        title: "Thông báo",
        message: "Vui lòng nhập đầy đủ thông tin.",
        autoClose: 3000,
        color: "red",
        icon: <IconX />,
      });
      return;
    }

    if (!validateData()) {
      NotificationExtension.Fails(
        "Vui lòng nhập số tiền phân bổ cho tất cả các mục."
      );
      return;
    }

    notifications.show({
      title: "Thông báo",
      message: "Lưu phiếu thu thành công",
      autoClose: 3000,
      color: "green",
      icon: <IconCheck />,
    });

    setIsRunning(false);
    const requestData: CreateReceiptParentRequest = {
      receiptCommand: Object.values(finalSelectedItems).map((e: any) => ({
        payType: "string",
        casherId: 0,
        payeeId: 0,
        note: e.note ?? e.orderNumber,
        payAmount: e.allocatedAmount,
        orderId: e.id,
        attribute1: e.attribute1,
        attribute2: e.attribute2,
      })),
      paymentReceiptDetailCommand: {
        id: 0,
        accountfund: "VND",
        codefund: formData.codefund,
        createdDate: formData.createdDate,
        createby: listdata?.createby ?? 0,
        completiontime: moment(new Date(), "DD-MM-YYYY HH:mm:ss")
          .add(7, "hours")
          .toISOString(),
        status:
          debouncedChangeValueTotal === debouncedChangeValueAmout
            ? "Đủ tiền"
            : debouncedChangeValueTotal < debouncedChangeValueAmout
            ? "Thiếu tiền"
            : undefined,
        creatorbranch: listdata?.creatorbranch,
        creatordepartment: listdata?.creatordepartment,
        creatorfullname: listdata?.creatorfullname ?? "",
        createrolename: userData?.roleId,
        creatorsalesbranch: formData.creatorsalesbranch,
        creatorworkplace: String(branchDeparment?.address),
        fundbranch: formData.fundbranch ? formData.fundbranch?.toString() : "",
        funddepartment: formData.funddepartment,
        fundsalesbranch: formData.fundbranch
          ? formData.fundbranch?.toString()
          : "",
        anothername: formData?.anothername,
        fundworkplace: formData.fundworkplace,
        funfullname: formData.funfullname,
        payerdescription: formData.payerdescription,
        payerfullname: formData.payerfullname,
        attribute6: "Tiền mặt VND tại CN",
        codeCustomer:
          dataBranch.find(
            (e) => String(e.value) === String(formData.creatorsalesbranch)
          )?.att2 ?? "",
        payernote: formData.payernote,
        payertype: formData.payertype,
        receiptNumber: dataGetReceiptParent?.receiptNumber,
        totalAmount: changeValueTotal,
        attribute2: dataGetReceiptParent?.attribute2?.id,
        type: formData.type,
        typeReceipt: dataGetReceiptParent?.typeReceipt,
      },
      save: handleSave,
    };

    // convert data về model chuẩn của be
    const dataConvent: Array<DetailsOrderHeader> = [];
    finalSelectedItems.forEach((item, index) => {
      if (item.orderNumber && item.amountTotal > 0) {
        const model: DetailsOrderHeader = {
          stt: index,
          orderNumber: item?.orderNumber ?? "",
          attribute2: item?.attribute16 ?? "",
          attribute7: item?.attribute8?.split("||")[0] ?? "",
          amountTotal: item.amountTotal ?? "",
          amountPaid: item.amountPaid ?? "",
          amountRemaining:
            item.computedField === undefined || item.computedField === null
              ? 0
              : Number(item.computedField) ?? 0,
          // allocationAmount: Number(item.allocationAmount) ?? null,
          allocatedAmount:
            item.computedField === undefined || item.computedField === null
              ? item.amountRemaining
              : Number(item.allocatedAmount) ?? 0,
          note: item.note ?? item.orderNumber,
          codeBranch:
            dataBranch.find(
              (e) => String(e.value) === String(formData.creatorsalesbranch)
            )?.att2 ?? "",
        };
        dataConvent.push(model);
      }
    });

    requestData.paymentReceiptDetailCommand.attribute5 =
      JSON.stringify(dataConvent);
    try {
      const response = await repositoryPos.post<
        MessageResponse<CreateReceiptParentRequest>
      >("/api/v1/CreateSellItem/create-receipt-parent", requestData);

      if (response && response.success) {
        if (handleSave) {
          notifications.show({
            title: "Thành công",
            message: "Phiếu thu đã được xác nhận thành công!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
        } else {
          notifications.show({
            title: "Thành công",
            message: "Phiếu thu đã được lưu thành công!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
        }
        setStatusReponse(response.success);
      } else {
        notifications.show({
          title: "Thất bại",
          message: "Lưu phiếu thu không thành công. Vui lòng thử lại!",
          autoClose: 3000,
          color: "red",
          icon: <IconX />,
        });
      }
    } catch (error) {
      console.error("Error creating receipt:", error);
    }
  };
  const fetchDataDepartments = async () => {
    try {
      const response = await repositoryPos.post<
        MessageResponse<DepartmentUser>
      >(`/api/v1/TblDepartment/detail?id=${listdata?.creatordepartment}`);

      if (response && response.success) {
        const result = response.data;
        setBranchDeparment(result);
      } else {
        console.error("Failed to fetch department details", response?.message);
      }
    } catch (error) {
      console.error("Error fetching department details", error);
    }
  };

  const fetchDataBranch = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmInventory/get-select-branch?auth=false");

    if (response && response.success) {
      let result = response.data;
      setDataBranch(result);
    }
  };

  useEffect(() => {
    if (listdata?.creatordepartment) {
      fetchDataDepartments();
    }
  }, [listdata?.creatordepartment]);

  useEffect(() => {
    if (formData.payertype) {
      setFormData((prevData) => ({
        ...prevData,
        funfullname: "",
      }));
    }
  }, [formData.payertype]);

  useEffect(() => {
    if (finalSelectedItems.length === 0) {
      setDataAlloation(false);
    }
  }, [finalSelectedItems]);

  useEffect(() => {
    if (formData.payertype !== "") {
      handleChange("creatorbranch", "");
    }
  }, [formData.payertype]);

  useEffect(() => {
    validateForm();
  }, [totalAllocatedAmount]);

  useEffect(() => {
    const total = finalSelectedItems.reduce(
      (acc, row) => acc + row.amountRemaining,
      0
    );
    setTotalAmountRemaining(total);
  }, [finalSelectedItems]);

  useEffect(() => {
    const badgeConfig = {
      content: "",
      color: "",
      icon: null as React.ReactNode,
    };

    // Update badgeConfig based on conditions
    if (debouncedChangeValueTotal > totalAllocatedAmount) {
      badgeConfig.content = "Số tiền nộp lớn hơn số tiền trong đơn hàng!";
      badgeConfig.color = "#fb4f4f";
      badgeConfig.icon = <IconBellX size={20} />;
      setShowBadge(true); // Show badge
    } else if (debouncedChangeValueTotal === totalAllocatedAmount) {
      badgeConfig.content = "Nộp đủ so với số với số tiền đã phân bổ.";
      badgeConfig.color = "green";
      badgeConfig.icon = <IconBellCheck size={20} />;
      setShowBadge(false); // Hide badge
    } else if (
      debouncedChangeValueTotal < totalAllocatedAmount &&
      changeValueTotal !== 0
    ) {
      badgeConfig.content = "Số tiền nộp nhỏ hơn số tiền trong đơn hàng!";
      badgeConfig.color = "#fb4f4f";
      badgeConfig.icon = <IconBellRinging size={20} />;
      setShowBadge(true); // Show badge
    } else if (debouncedChangeValueTotal === 0) {
      badgeConfig.content = "Số tiền không xác định!";
      badgeConfig.color = "#fb4f4f";
      badgeConfig.icon = <IconBellQuestion size={20} />;
      setShowBadge(true); // Show badge
    }

    setBadgeContent(badgeConfig.content);
    setBadgeColor(badgeConfig.color);
    setBadIcon(badgeConfig.icon);
  }, [debouncedChangeValueTotal, dataAlloation, totalAllocatedAmount]);
  const fetchDataListReceipt = async () => {
    if (dataListBranchOrder) {
      let url = `/api/v1/CreateSellItem/get-list-receipt?invId=${dataListBranchOrder}`;

      if (searchParams.searchKey) {
        url += `&key=${searchParams.searchKey}`;
      }

      if (searchParams.handeDateStart) {
        url += `&start=${formatDate(searchParams.handeDateStart)}`;
      }

      if (searchParams.handeDateEnd) {
        url += `&end=${formatDate(searchParams.handeDateEnd)}`;
      }

      if (searchParams.searchTo) {
        url += `&To=${searchParams.searchTo}`;
      }

      if (searchParams.searchFrom) {
        url += `&From=${searchParams.searchFrom}`;
      }

      try {
        const response = await repositoryPos.get<MessageResponse<Receipt[]>>(
          url
        );

        if (response && response.success) {
          setDataListReceipt(response.data);
        }
      } catch (error) {
        console.error("Error fetching data list receipt:", error);
      }
    }
  };

  const handleChooseDebt = (
    value: TblDebtGroup,
    fieldName: string,
    row: any
  ) => {
    if (fieldName === "attribute2") {
      setDataGetReceiptParent((prevState: any) => ({
        ...prevState,
        [fieldName]: {
          id: value?.id || "",
          groupCode: value?.groupCode || "",
          groupName: value?.groupName || "",
          taxCode: value?.taxCode || "",
          address: value?.address || "",
          phone: value?.phone || "",
          contactPerson: value?.contactPerson || "",
          classification: value?.classification || "",
          classificationName: value?.classificationName || "",
          debtTerm: value?.debtTerm || "",
          debtLimit: value?.debtLimit || "",
          createBy: value?.createBy || "",
          createByName: value?.createByName || "",
          createDate: value?.createDate || "",
          lastUpdateBy: value?.lastUpdateBy || "",
          lastCreateByName: value?.lastCreateByName || "",
          lastUpdateDate: value?.lastUpdateDate || "",
          type: value?.type || "",
          typeName: value?.typeName || "",
        },
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [row.index]: {
          attribute1: value?.id,
          attribute2: value?.groupName,
        },
      }));
    }
  };

  //#region openConfirmModal
  useEffect(() => {
    setSearchKey("");
    setSearchFrom("");
    setSearchTo("");
    setHanDateEnd(null);
    setHanDateStart(new Date());
    setFinalSelectedItems([]);
    setTempSelectedItems([]);
    setSearchToDisplay("");
    setSearchFromDisplay("");
  }, [dataListBranchOrder]);

  useEffect(() => {
    setTempSelectedItems([]);
    setFinalSelectedItems([]);
  }, [selectPayertype]);

  useEffect(() => {
    setCurrentTime(new Date());
    fetchDataBranch();
    fetchDataGetAll();
    fetchDataEmployeeBranchID();
  }, []);

  useEffect(() => {
    setGroupData({
      id: dataGetReceiptParent?.attribute2?.id || "",
      groupName: dataGetReceiptParent?.attribute2?.groupName || "",
      groupCreateByName: dataGetReceiptParent?.attribute2?.createByName || "",
    });
  }, [dataGetReceiptParent]);

  useEffect(() => {
    setFinalSelectedItems((prevData) =>
      prevData.map((item) => ({
        ...item,
        attribute2: groupData.groupName,
        attribute1: groupData.id,
      }))
    );
  }, [groupData]);

  useEffect(() => {
    fetchDataListReceipt();
  }, [dataListBranchOrder, handelKey, handeDateStart, handeDateEnd]);

  useEffect(() => {
    setSearchParams({
      searchKey: "",
      searchFrom: "",
      searchTo: "",
      handeDateStart: null,
      handeDateEnd: null,
      searchToFull: "",
      searchFromFull: "",
    });

    setDataListReceipt([]);
  }, [dataListBranchOrder]);

  useEffect(() => {
    if (formData.payertype === "Khách hàng") {
      setFormData((prev) => ({
        ...prev,
        anothername: inputValue,
      }));
    }
  }, [inputValue, formData.payertype]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      createdDate: moment(new Date(), "DD-MM-YYYY HH:mm:ss")
        .add(7, "hours")
        .toISOString(),
    }));
  }, []);

  useEffect(() => {
    const fetchDataListBranch = async () => {
      const response = await repositoryDelivery.get<MessageResponse<Receipt[]>>(
        "/api/v1/TblDmMasterOrg/get-select"
      );

      if (response && response.success) {
        let result = response.data;
        setDataListBranch(result);
      }
    };

    const fetchDataPaymentMethod = async () => {
      const response = await repositoryMdm.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmPaymentMethod/get-all");

      if (response && response.success) {
        let result = response.data;
        setPaymentMethod(result);
      }
    };

    const fetchDataPaymentType = async () => {
      const response = await repositoryMdm.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmPaymentType/get-select");

      if (response && response.success) {
        let result = response.data;
        setPaymentTypes(result);
      }
    };

    const fetchDataBranch = async () => {
      const response = await repositoryDelivery.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmMasterOrg/get-all");

      if (response && response.success) {
        let result = response.data;
        setListBranch(result);
      }
    };

    Promise.all([
      fetchDataPaymentMethod(),
      fetchDataPaymentType(),
      fetchDataListBranch(),
      fetchDataBranch(),
    ]);
  }, []);

  const fetchDataReceiptParent = async () => {
    const response = await repositoryPos.get<MessageResponse<FundDetails>>(
      "/api/v1/CreateSellItem/create-receipt-parent?typeReceipt=TH"
    );

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptParent(result);
      setListData(result);
    }
  };

  useEffect(() => {
    const fetchDataBankAccount = async () => {
      const response = await repositoryPos.get<
        MessageResponse<TblDMPaymentMethod[]>
      >(`/api/v1/TblDmBranchBankAccount/get-select?branchId=${options}`);

      if (response && response.success) {
        let result = response.data;
        setPaymenBankAccount(result);
      }
    };
    fetchDataBankAccount();
  }, [options]);

  useEffect(() => {
    fetchDataReceiptParent();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (145 + headerHeight));
      setHeightContent(window.innerHeight - (105 + contentHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [heightContent, height]);

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  const isAllSelected =
    filteredDataList.length > 0 &&
    tempSelectedItems.length === filteredDataList.length;

  const handleSelectAllChange = () => {
    if (selectPayertype) {
      return;
    }
    if (isAllSelected) {
      setTempSelectedItems([]);
    } else {
      setTempSelectedItems(filteredDataList);
    }
  };

  useHotkeys([["ctrl+shift+Z", () => toggleCollapse()]]);

  return (
    <>
      <header ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Grid pb={15}>
          <GridCol span={{ base: 12, md: 12, lg: 4 }}>
            <Flex align="center" gap={20}>
              <Button
                radius="lg"
                color="blue"
                leftSection={<IconPlus size={14} />}
                variant="filled"
                onClick={() => {
                  navigate("/bill/payment-receipt");
                  window.location.reload();
                }}
              >
                Tạo mới
              </Button>
              <Flex align="center" gap={10}>
                <Switch
                  size="lg"
                  color={isCollapse ? "red" : "green"}
                  checked={!isCollapse}
                  onLabel="Bật"
                  offLabel="Tắt"
                  onChange={toggleCollapse}
                  thumbIcon={
                    !isCollapse ? (
                      <IconMaximize
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    ) : (
                      <IconMaximizeOff
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    )
                  }
                />
                {
                  <Text size="md">
                    {isCollapse
                      ? "Mở rộng (Ctrl + Shift + Z)"
                      : "Thu gọn (Ctrl + Shift + Z)"}
                  </Text>
                }
              </Flex>
            </Flex>
          </GridCol>
          <GridCol
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            span={{ base: 12, md: 6, lg: 4 }}
          >
            {dataAlloation && showBadge && (
              <Badge
                p={20}
                pt={15}
                pb={15}
                leftSection={badIcon}
                color={badgeColor}
              >
                {badgeContent}
              </Badge>
            )}
          </GridCol>
          <GridCol
            style={{ display: "flex", justifyContent: "end" }}
            span={{ base: 12, md: 6, lg: 4 }}
          >
            <Group style={{ flexWrap: "nowrap" }} align="center">
              <Tooltip
                label={`${
                  changeValueTotal > 0
                    ? "Kích hoạt"
                    : "Vui lòng chọn chi nhánh và nhập tổng tiền"
                }`}
              >
                <Button
                  variant="filled"
                  className={classesButton.button}
                  leftSection={<IconBrandCashapp size={14} />}
                  disabled={
                    !dataAlloation &&
                    finalSelectedItems.length > 0 &&
                    changeValueTotal > 0
                      ? false
                      : true
                  }
                  onClick={() => Alloation()}
                  size="sm"
                >
                  Phân bổ
                </Button>
              </Tooltip>
            </Group>
          </GridCol>
        </Grid>
      </header>
      <Modal
        size="55rem"
        opened={opened}
        onClose={close}
        p={0}
        title={<Title order={5}>Chọn hóa đơn</Title>}
      >
        <Grid align="center" mt={10}>
          <GridCol span={{ base: 12, md: 8, lg: 10 }}>
            {/* <TextInput
              w={200}
              label="Từ khóa"
              placeholder="Từ khóa"
              onChange={(event) => handleSearchKey(event.currentTarget.value)}
            /> */}
            <Group grow wrap="nowrap">
              <Grid>
                <GridCol span={{ base: 6, md: 6, lg: 3 }}>
                  <DatePickerInput
                    locale="vi"
                    flex={1}
                    size="sm"
                    label="Từ ngày"
                    clearable
                    value={searchParams.handeDateStart}
                    valueFormat="DD-MM-YYYY"
                    placeholder="Vui lòng chọn ngày"
                    maxDate={endDate || undefined}
                    onChange={(date) => handleDateChange(date, "start")}
                  />
                </GridCol>
                <GridCol span={{ base: 6, md: 6, lg: 3 }}>
                  <DatePickerInput
                    locale="vi"
                    flex={1}
                    size="sm"
                    label="Đến ngày"
                    clearable
                    value={searchParams.handeDateEnd}
                    valueFormat="DD-MM-YYYY"
                    placeholder="Vui lòng chọn ngày"
                    minDate={startDate || undefined}
                    maxDate={today} // hận em
                    onChange={(date) => handleDateChange(date, "end")}
                  />
                </GridCol>
                <GridCol span={{ base: 6, md: 6, lg: 3 }}>
                  <TextInput
                    value={searchFromDisplay}
                    flex={1}
                    label="Số hóa đơn từ"
                    placeholder="Nhập mã hóa đơn"
                    onChange={(event) =>
                      handleSearchFrom(event.currentTarget.value)
                    }
                  />
                </GridCol>
                <GridCol span={{ base: 6, md: 6, lg: 3 }}>
                  <TextInput
                    value={searchToDisplay}
                    flex={1}
                    label="Số hóa đơn đến"
                    placeholder="Nhập mã hóa đơn"
                    onChange={(event) =>
                      handleSearchTo(event.currentTarget.value)
                    }
                  />
                </GridCol>
              </Grid>
            </Group>
          </GridCol>
          <GridCol span={{ base: 12, md: 4, lg: 2 }}>
            <Group align="flex-end" justify="flex-end" mt={10}>
              <Button
                leftSection={<IconSearch size={12} />}
                onClick={fetchData}
              >
                Tìm kiếm
              </Button>
            </Group>
          </GridCol>
        </Grid>
        <ScrollArea
          h={filteredDataList.length > 0 ? 350 : 0}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped horizontalSpacing="sm" withColumnBorders>
            {filteredDataList.length > 0 ? null : (
              <>
                <Table.Caption mt={40} mb={20}>
                  <Text size="md">Chưa có đơn để hiển thị</Text>
                </Table.Caption>
              </>
            )}
            <Table.Thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <Table.Tr>
                <Table.Th ta="center">
                  <Checkbox
                    checked={isAllSelected}
                    onChange={handleSelectAllChange}
                  />
                </Table.Th>
                <Table.Th ta="center">Số đơn hàng</Table.Th>
                <Table.Th ta="center">Tổng tiền (VND)</Table.Th>
                <Table.Th ta="center">Đã thanh toán (VND)</Table.Th>
                <Table.Th ta="center">Số tiền còn lại (VND)</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {filteredDataList?.map((value) => (
                <Table.Tr key={value.orderNumber}>
                  <Table.Td>
                    <Checkbox
                      checked={tempSelectedItems.some(
                        (selectedItem) => selectedItem.id === value.id
                      )}
                      onChange={() => handleCheckboxChange(value)}
                    />
                  </Table.Td>
                  <Table.Td ta="start">{value.orderNumber}</Table.Td>
                  {/* <Table.Td  ta="center">{formatDateTransfer(value.orderDate)}</Table.Td> */}
                  <Table.Td ta="end">
                    <NumberFormatter
                      thousandSeparator
                      value={value.amountTotal}
                    ></NumberFormatter>
                  </Table.Td>
                  <Table.Td ta="end">
                    <NumberFormatter
                      thousandSeparator
                      value={value.amountPaid}
                    ></NumberFormatter>
                  </Table.Td>
                  <Table.Td ta="end">
                    <NumberFormatter
                      thousandSeparator
                      value={value.amountRemaining}
                    ></NumberFormatter>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </ScrollArea>
        <Group mt={15} justify="flex-end">
          <Button
            size="sm"
            variant="filled"
            key="confirm"
            onClick={handleConfirm}
            // disabled={tempSelectedItems.length === 0 || !validateForm()}
          >
            Tiếp tục
          </Button>
        </Group>
      </Modal>
      <PanelGroup
        style={{ height: !isSmallScreen ? "100%" : `${height * 2.5}px` }}
        autoSaveId="conditional"
        direction={isSmallScreen ? "vertical" : "horizontal"}
      >
        <Panel
          defaultSize={isSmallScreen ? 40 : 35}
          minSize={isSmallScreen ? 40 : isCollapse ? 0 : 35}
          maxSize={isSmallScreen ? 40 : 50}
          collapsible={!isSmallScreen}
          style={{
            width: isSmallScreen ? "100%" : "auto",
            transform: isCollapse ? "translateX(-10%)" : "translateX(0)",
            display: isCollapse ? "none" : "block",
          }}
        >
          <Card
            shadow="sm"
            radius="md"
            withBorder
            pr={0}
            pt={0}
            p="md"
            style={{
              maxHeight: height + 60,
              minHeight: height + 60,
            }}
          >
            {managerOrder()}
          </Card>
        </Panel>
        {!isCollapse && !isSmallScreen && (
          <PanelResizeHandle
            style={{ position: "relative" }}
            id="resize-handle"
          >
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: "100",
              }}
            >
              <ActionIcon
                size="sm"
                radius="md"
                variant="default"
                aria-label="Settings"
              >
                <IconGripVertical size={16} />
              </ActionIcon>
            </Box>
          </PanelResizeHandle>
        )}
        <Panel
          defaultSize={isSmallScreen ? 50 : isCollapse ? 100 : 55}
          minSize={isSmallScreen ? 50 : 35}
          maxSize={isSmallScreen ? 50 : isCollapse ? 100 : 65}
          style={{
            width: isSmallScreen ? "100%" : "auto",
          }}
        >
          <MantineReactTable table={table} />
          <Card
            shadow="sm"
            radius="md"
            withBorder
            p="md"
            mt={5}
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Divider
              pb={20}
              mt={0}
              my="xs"
              label={<Text size="sm">Tổng Kết Số Tiền</Text>}
              labelPosition="left"
              variant="dashed"
            />
            <Grid w="100%">
              <GridCol
                span={{ base: 5.5, md: 6, lg: 2.5 }}
                style={orderInfoStyle}
              >
                <Text
                  size="lg"
                  c="#000"
                  fw={500}
                  lineClamp={1}
                  style={infoLabelStyle}
                >
                  Tổng tiền hóa đơn
                </Text>
                <Text fw={600} size="lg">
                  <NumberFormatter
                    value={valueTotalAmount}
                    thousandSeparator
                    style={infoValueStyle}
                  />
                </Text>
              </GridCol>
              <GridCol
                span={{ base: 5.5, md: 6, lg: 2.5 }}
                style={orderInfoStyle}
              >
                <Text fw={500} lineClamp={1} style={infoLabelStyle}>
                  Số đã thanh toán
                </Text>
                <Text fw={600} size="lg">
                  <NumberFormatter
                    value={valueTotalAmountPaid}
                    thousandSeparator
                    style={infoValueStyle}
                  />
                </Text>
              </GridCol>
              <GridCol
                span={{ base: 5.5, md: 6, lg: 2.5 }}
                style={orderInfoStyle}
              >
                <Text fw={500} lineClamp={1} style={infoLabelStyle}>
                  Số còn phải TT
                </Text>
                <Text fw={600} size="lg">
                  <NumberFormatter
                    value={valueTotalRemaining}
                    thousandSeparator
                    style={infoValueStyle}
                  />
                </Text>
              </GridCol>
              <GridCol
                span={{ base: 5.5, md: 6, lg: 2.5 }}
                style={orderInfoStyle}
              >
                <Flex direction="column">
                  <Text lineClamp={1} style={infoLabelStyle} fw={500}>
                    Tổng tiền phân bổ
                  </Text>
                </Flex>
                <Text c={"red"} fw={600} size="lg">
                  <NumberFormatter
                    value={dataAlloation ? totalAllocatedAmount : 0}
                    thousandSeparator
                    style={infoValueStyle}
                  />
                </Text>
              </GridCol>
            </Grid>
          </Card>
        </Panel>
      </PanelGroup>
    </>
  );
};

const orderInfoStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center" as "center",
  padding: "10px",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  margin: "5px",
};

const infoLabelStyle = {
  fontSize: "14px",
  color: "#555",
  marginBottom: "5px",
};

const infoValueStyle = {
  fontSize: "14px",
  fontWeight: "bold" as "bold",
};
