import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  LoadingOverlay,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowBack, IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { DateTimePicker } from "@mantine/dates";
import ProductWarrantyList from "./ProductWarrantyList";
import { getItemPositionSelect } from "../../../service/getSelectApi";
import { IconPrinter } from "@tabler/icons-react";
import React from "react";
import { useReactToPrint } from "react-to-print";
import PrinterReceiveTicket from "./PrinterReceiveTicket";

const DetailDataView = ({
  id,
  onClose,
  actionView,
}: DetailReceiptFormProps) => {
  const entity = {
    id: "0",
    empId: null,
    requestId: null,
    errorNumber: null,
    dateReceive: new Date().toString(),
    inventoryCode: null,
    note: null,
    idItemPosition: null,
    branchId: null,
    idCustomerGroup: null,
    inventoryId: null,
    tblWarrantyReceiveTicketDetailCommands: null,
  };

  const form = useForm<any>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const [dataInventorySelect, setDataInventorySelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataItemPositionSelect, setDataItemPositionSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataEmployeeDetail, setDataEmployeeDetail] = useState<any>();
  const [dataDepartment, setDataDepartment] = useState<ComboboxItem[]>([]);
  const [dataRole, setDataRole] = useState<ComboboxItem[]>([]);
  const [dataRequestFormSelected, setDataRequestFormSelected] = useState<any>();
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: `
        @page {
          size:auto;
          margin: 5mm 0;
      }`,
  });

  const [editAndSave, setEditAndSave] = useState(false);
  const [isEdit, setIsEdit] = useState({
    amount: false,
    acessory: false,
    testResolution: false,
    serviceType: false,
    scheduleTime: false,
    daysReturn: false,
    note: false,
  });
  const [visible, { toggle, close, open }] = useDisclosure(false);

  const warrantyReceiveTicketDetail =
    form.getValues().tblWarrantyReceiveTicketDetailCommands ?? [];

  const handleChangeValue = (key: string, value: any, index: number) => {
    form.setValues((prevData: any) => {
      const prevDataSubmit = Array.isArray(
        prevData.tblWarrantyReceiveTicketDetailCommands
      )
        ? prevData.tblWarrantyReceiveTicketDetailCommands
        : [];

      if (prevDataSubmit[index]?.[key] === value) {
        return prevData;
      }

      const updatedModels = [...prevDataSubmit];
      updatedModels[index] = {
        ...updatedModels[index],
        [key]: value,
      };

      return {
        ...prevData,
        tblWarrantyReceiveTicketDetailCommands: updatedModels,
      };
    });
  };

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblWarrantyReceiveTicket/detail?id=${id}`
    );
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        await Promise.all([
          getDataEmployeeForDep(),
          getDataDepartment(),
          getDataRole(),
        ]);
      }
      close();
    } else {
      onClose("");
    }
  };

  const getDetailRequestForm = async () => {
    if (form.getValues().requestId) {
      const res = await repositoryMdm.get<MessageResponse<any>>(
        `/api/v1/TblWarrantyServiceRequestForm/detail?id=${
          form.getValues().requestId
        }`
      );

      if (res && res?.success) {
        const dataApi = res.data;
        setDataRequestFormSelected(dataApi);
      } else {
        setDataRequestFormSelected([]);
      }
    }
  };

  const getInventorySelect = async () => {
    if (dataRequestFormSelected?.branchId) {
      const res = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmInventory/get-select-by-branch?branchId=${dataRequestFormSelected?.branchId}`
      );

      if (res && res?.success) {
        const dataApi = res.data;
        setDataInventorySelect(
          dataApi
            ?.filter(
              (item: any) =>
                item.inventoryId != null &&
                item.inventoryName != null &&
                (item.inventoryName.toLowerCase().includes("lỗi") ||
                  item.inventoryName.toLowerCase().includes("bảo hành"))
            )
            ?.map((item: any) => ({
              value: item.inventoryCode?.toString(),
              label: `${item.inventoryName} - ${item.inventoryCode}`,
            }))
        );
      }
    } else {
      setDataInventorySelect([]);
    }
  };

  const getDataItemPosition = async () => {
    if (form.getValues().inventoryCode) {
      const getData = await getItemPositionSelect(
        form.getValues().inventoryCode
      );
      setDataItemPositionSelect(
        getData
          ?.filter((item: any) => item.id != null && item.positionName != null)
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.positionName,
          }))
      );
    }
  };

  const getDataEmployeeForDep = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?Take=500`
    );

    if (res && res?.success) {
      setDataEmployeeSelect(
        res.data
          ?.filter(
            (item: any) =>
              item.id != null &&
              item.fullname != null &&
              item.roleName.toLowerCase().includes("bảo hành")
          )
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.fullname,
          }))
      );
    }
  };

  const getDataDepartment = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDepartment/get-all"
    );

    if (res && res.success) {
      const result = res.data;
      setDataDepartment(
        result
          ?.filter((item: any) => item.name != null)
          ?.map((item: any) => ({
            id: item.departmentId,
            departmentName: item.name,
          }))
      );
    }
  };

  const getDataRole = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblRole/get-all"
    );

    if (res && res.success) {
      const result = res.data;
      setDataRole(
        result
          ?.filter((item: any) => item.name != null)
          ?.map((item: any) => ({
            id: item.id,
            roleName: item.name,
          }))
      );
    }
  };

  const getEmployeeDetail = async () => {
    if (dataRequestFormSelected?.empId) {
      const res = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/detail?id=${dataRequestFormSelected?.empId}`
      );

      if (res && res.success) {
        const result = res.data;
        setDataEmployeeDetail(result);
      }
    } else {
      setDataEmployeeDetail(undefined);
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (
      dataRequestFormSelected?.empId !== null &&
      dataRequestFormSelected?.empId !== 0
    ) {
      getEmployeeDetail();
    } else {
      setDataEmployeeDetail(undefined);
    }
  }, [dataRequestFormSelected?.empId]);

  useEffect(() => {
    if (dataRequestFormSelected && dataRequestFormSelected.length > 0) {
      form.setValues((prev) => ({
        ...prev,
        requestId: dataRequestFormSelected?.id,
        empId: dataRequestFormSelected?.empId,
        branchId: dataRequestFormSelected?.branchId,
        idCustomerGroup: dataRequestFormSelected?.idCustomerGroup,
      }));
    }
  }, [dataRequestFormSelected]);

  useEffect(() => {
    getInventorySelect();
  }, [dataRequestFormSelected?.branchId]);

  useEffect(() => {
    if (form.getValues().requestId) {
      getDetailRequestForm();
    } else {
      setDataRequestFormSelected([]);
    }
  }, [form.getValues().requestId]);

  useEffect(() => {
    if (form.getValues().inventoryCode) {
      getDataItemPosition();
    } else {
      setDataItemPositionSelect([]);
    }
  }, [form.getValues().inventoryCode]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        p={"0px 10px"}
        onMouseDown={() => {
          setIsEdit({
            amount: false,
            acessory: false,
            testResolution: false,
            serviceType: false,
            scheduleTime: false,
            daysReturn: false,
            note: false,
          });
          setEditAndSave(false);
        }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex justify={"space-between"} align={"center"}>
          <Badge
            variant="gradient"
            gradient={{ from: "blue", to: "cyan", deg: 90 }}
            w={275}
          >
            Chi tiết phiếu nhận hàng lỗi
          </Badge>
          <Flex w={"100%"} justify={"flex-end"} gap={10}>
            <Button
              type="button"
              color="red"
              loading={visible}
              onClick={() => {
                onClose("");
              }}
              leftSection={!visible ? <IconArrowBack size={18} /> : undefined}
            >
              Thoát
            </Button>
            <Button
              color={"blue"}
              leftSection={<IconPrinter size={18} />}
              onClick={() => handlePrint()}
            >
              In phiếu
            </Button>
          </Flex>
        </Flex>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <Fieldset h={"100%"} legend="Thông tin khách hàng hẹn gặp">
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <TextInput
                    label="Tên khách hàng"
                    placeholder="Tìm kiếm thông qua SĐT"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.custMeeting
                        : null
                    }
                    readOnly
                    variant="filled"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <TextInput
                    label="Số điện thoại"
                    placeholder="Số điện thoại"
                    value={
                      form.getValues().custPhone
                        ? form.getValues().custPhone
                        : null
                    }
                    readOnly
                    variant="filled"
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <TextInput
                    label="Nhóm khách hàng"
                    placeholder="Tìm kiếm thông qua SĐT"
                    value={
                      form.getValues().nameCustomerGroup
                        ? form.getValues().nameCustomerGroup
                        : null
                    }
                    readOnly
                    variant="filled"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <TextInput
                    label="Kênh mua hàng"
                    placeholder="Chọn kênh mua hàng"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.channelName
                        : null
                    }
                    {...form.getInputProps("channel")}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label="Địa chỉ"
                    placeholder="Tìm kiếm thông qua SĐT"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.meetingAddress
                        : null
                    }
                    readOnly
                    variant="filled"
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <Fieldset legend="Thông tin phiếu tiếp nhận hàng lỗi" h={"100%"}>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Số phiếu tiếp nhận hàng lỗi"
                    value={form.getValues().errorNumber ?? ""}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Mã phiếu tiếp nhận yêu cầu"
                    placeholder="Mã phiếu tiếp nhận yêu cầu"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.requestNum
                        : null
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Số phiếu tiếp nhận yêu cầu"
                    placeholder="Số phiếu tiếp nhận yêu cầu"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.attribute10
                        : null
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <DateTimePicker
                    label="Ngày tạo phiếu"
                    valueFormat="DD-MM-YYYY hh:mm A"
                    value={new Date()}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Hình thức xử lý"
                    placeholder="Hình thức xử lý"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.processingMethodName
                        : null
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Nhân viên tiếp nhận"
                    placeholder="Nhập tên nhân viên tiếp nhận"
                    value={dataRequestFormSelected?.empnameincharge}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Chức danh"
                    placeholder="Chức danh của nhân viên tiếp nhận"
                    value={
                      dataEmployeeDetail !== undefined
                        ? getValueById(
                            dataEmployeeDetail?.roleId,
                            dataRole,
                            "roleName"
                          )
                        : ""
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Bộ phận/Phòng bạn"
                    placeholder="Bộ phận/Phòng ban của nhân viên tiếp nhận"
                    value={
                      dataEmployeeDetail !== undefined
                        ? getValueById(
                            dataEmployeeDetail?.departmentId,
                            dataDepartment,
                            "departmentName"
                          )
                        : ""
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <Select
                    label="Kho"
                    placeholder="Nhập mã hoặc tên kho"
                    data={dataInventorySelect}
                    value={
                      form.getValues().inventoryCode
                        ? form.getValues().inventoryCode?.toString()
                        : null
                    }
                    readOnly
                    variant="filled"
                    searchable
                    clearable
                    comboboxProps={{ width: 325 }}
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    {...form.getInputProps("inventoryCode")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <Select
                    label="Vị trí kho"
                    placeholder="Nhập vị trí kho"
                    data={dataItemPositionSelect}
                    value={
                      form.getValues().idItemPosition
                        ? form.getValues().idItemPosition?.toString()
                        : null
                    }
                    readOnly
                    variant="filled"
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <TextInput
                    label="Ghi chú"
                    placeholder="Nhập ghi chú"
                    readOnly
                    variant="filled"
                    {...form.getInputProps("note")}
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col span={12}>
            <Fieldset legend="Thông tin sản phẩm">
              <ProductWarrantyList
                dataRequestFormSelectedDetail={
                  form.getValues()?.tblWarrantyReceiveTicketDetailModels
                }
                warrantyReceiveTicketDetail={warrantyReceiveTicketDetail}
                editAndSave={editAndSave}
                setEditAndSave={setEditAndSave}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                handleChangeValue={handleChangeValue}
                actionView={"DETAIL"}
                typeProduct={dataRequestFormSelected?.attribute6}
              />
            </Fieldset>
          </Grid.Col>
        </Grid>
      </Box>
      <Box display={"none"}>
        <PrinterReceiveTicket
          innerRef={componentRef}
          dataPrint={form.getValues()}
        />
      </Box>
    </>
  );
};

type DetailReceiptFormProps = {
  id?: any;
  onClose?: any;
  actionView?: any;
};

export default DetailDataView;
