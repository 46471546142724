import {
  Box,
  Checkbox,
  Grid,
  LoadingOverlay,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMLocation } from "../../../../model/TblDMLocation";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    cityId: null,
    districtId: null,
    active: false,
    communeId: null,
    code: null,
    name: null,
    modifiedAt: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMLocation>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<TblDMLocation>>(
      `/api/v1/TblDmLocation/detail?id=${id}`
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "250px", md: "300px", lg: "400px" }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã"}
              placeholder={"Nhập mã"}
              type="text"
              {...form.getInputProps("code")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <TextInput
              label={"Tên"}
              placeholder={"Nhập tên"}
              type="text"
              {...form.getInputProps("name")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã tỉnh/thành phố"}
              placeholder={"Nhập mã"}
              type="text"
              {...form.getInputProps("cityName")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã quận/huyện"}
              placeholder={"Nhập mã"}
              type="text"
              {...form.getInputProps("districtName")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã phường/xã"}
              placeholder={"Nhập mã"}
              type="text"
              {...form.getInputProps("communeName")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>

        <Checkbox
          label={"Sử dụng"}
          checked={form.values.active}
          readOnly
          {...form.getInputProps("active")}
          onChange={() => {}}
          mt={10}
        />
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
