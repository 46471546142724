import React, { useEffect, useState } from "react";
import { paginationBase } from "../../_base/model/_base/BaseTable";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import { _localization } from "../../config/location";
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  TextInput,
  Tooltip,
  Text,
} from "@mantine/core";
import { IconChecklist, IconSearch, IconX } from "@tabler/icons-react";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { LineType } from "../Sell/orderStatus";
import { SocketExtension } from "../../_base/socket/socket";

const ReducedApproval = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [dataTemp, setDataTemp] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination] = useState(paginationBase);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [keySearch, setKeySearch] = useState<string | null>("");

  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () =>
      [
        {
          header: "STT",
          Cell: ({ row }) => (
            <Text fw={500} size="12.5px">
              {row.index === -1 ? "" : row.index + 1}
            </Text>
          ),
          size: 10,
        },
        {
          accessorKey: "lineTyle",
          header: "Loại",
          Cell: ({ row }) => (
            <Flex justify={"start"}>
              <LineType status={row?.original?.lineTyle} />
            </Flex>
          ),
          size: 50,
        },
        {
          accessorKey: "itemName",
          header: "Tên sản phẩm",
        },
        {
          accessorKey: "itemCode",
          header: "Mã hàng",
          size: 50,
        },
        {
          accessorKey: "uomCode",
          header: "Đơn vị tính",
          size: 50,
        },
        {
          accessorKey: "quality",
          header: "Số lượng",
          size: 50,
        },
        {
          accessorKey: "price",
          header: "Giá bán lẻ",
          Cell: ({ row }) => (
            <Text fw={500} size={"12.5px"}>
              {row.original.price?.toLocaleString()}
            </Text>
          ),
          size: 50,
        },
        {
          accessorKey: "promotionDiscountPercent",
          header: "Khuyến mãi",
          Cell: ({ row }) => (
            <Text fw={500} size="12.5px" c={"red"}>
              -{row.original.promotionDiscountPercent}%
            </Text>
          ),
          size: 50,
        },
        {
          accessorKey: "amountReduction",
          header: "Xin giảm",
          Cell: ({ row }) => (
            <Text fw={500} size="12.5px" c={"red"}>
              -{Number(row.original.amountReduction)?.toLocaleString()}
            </Text>
          ),
          size: 50,
        },
        {
          accessorKey: "priceFinal",
          header: "Thành tiền",
          Cell: ({ row }) => (
            <Text fw={500} size={"12.5px"}>
              {row.original.priceFinal?.toLocaleString()}
            </Text>
          ),
          size: 50,
        },
        {
          accessorKey: "note",
          header: "Ghi chú",
        },
        {
          accessorKey: "action",
          header: "Thao tác",
          enableSorting: false,
          enableColumnActions: false,
          enableColumnFilter: false,
          Cell: ({ row }) => (
            <Flex gap="xs">
              <Tooltip label="Duyệt">
                <ActionIcon
                  variant="light"
                  color="teal"
                  onClick={() => {
                    SocketExtension.SendMessageToTopic<any>({
                      data: {
                        codeItem: row.original.itemCode,
                        idRequest: row.original.idRequest,
                        status: "SUCCESS",
                      },
                      topic: `response-reduction-channel`,
                    });
                  }}
                >
                  <IconChecklist size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Từ chối">
                <ActionIcon
                  variant="light"
                  color="red"
                  onClick={() => {
                    SocketExtension.SendMessageToTopic<any>({
                      data: {
                        codeItem: row.original.itemCode,
                        idRequest: row.original.idRequest,
                        status: "CANCELED",
                      },
                      topic: `response-reduction-channel`,
                    });
                  }}
                >
                  <IconX size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          ),
          size: 50,
        },
      ] as MRT_ColumnDef<(typeof data)[0]>[],
    []
  );

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    SocketExtension.On("request-reduction-channel", (response: any) => {
      console.log(response);
      setData((prevData) => {
        return Array.from(new Set([...prevData, response]));
      });
    });
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar

    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "55%" }}>
        <_breadcrumb></_breadcrumb>
        <Flex gap={"md"}>
          <TextInput
            placeholder="Nhập từ khóa"
            // onChange={(e) =>
            //   handleChangeSearchValue(e.currentTarget.value, "keySearch")
            // }
          />
          <Button
            variant="outline"
            color="blue"
            leftSection={<IconSearch size={14} />}
            // onClick={fetchData}
          >
            Tìm kiếm
          </Button>
        </Flex>
      </div>
    ),

    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return <MantineReactTable table={table} />;
};

export default ReducedApproval;
