import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";
import { TblDMInventoryGroup } from "../../../../model/TblDMInventoryGroup";

const EditDataView = ({ id, onClose }: EditDataViewProps) => {
  const entity = {
    id: 0,
    invGroupCode: null,
    invGroupName: null,
    dienGiai: null,
    chucNangBan: null,
    idNguoiQuanLy: null,
    dieuChuyenLcn: null,
    idNguoiDieuChuyenLcn: null,
    dieuChuyenNoibo: null,
    idNguoiDieuChuyenNbcn: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
    createBy: null,
    empNameQuanLy: null,
    empNameLienchinhanh: null,
    empNameNoibochinhanh: null,
    text: null,
  };

  const [visible, { close, open }] = useDisclosure(false);
  const [enable, setEnable] = useState(false);

  const form = useForm<TblDMInventoryGroup>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      invGroupCode: (value: string | null) => {
        const invalidChars =
          /[âăáàảãạêếềệễểíìịĩỉóòỏõọôốồộổỗơớờợỡởúùủũụưứừựữửýỳỷỹỵđ]/i;

        if (!value) {
          return "Vui lòng nhập mã kho !";
        }
        if (invalidChars.test(value ?? "")) {
          return "Mã kho chứa những kí tự không hợp lệ !";
        }
        return hasLength(
          { max: 20 },
          "Mã kho không được dài hơn 20 ký tự !"
        )(value as string);
      },
      invGroupName: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập loại kho!";
        }
        return hasLength(
          { max: 200 },
          "Loi kho phải nh hơn 10 kí tự!"
        )(value as string);
      },
      dienGiai: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập diễn giải!";
        }
        return hasLength(
          { max: 1000 },
          "Diễn giải không quá 1000 kí tự!"
        )(value as string);
      },
      idNguoiQuanLy: (value: number | null) => {
        if (value === null) {
          return "Vui lòng chọn người quản lý!";
        }
        return null;
      },
      idNguoiDieuChuyenLcn: (value: number | null) => {
        if (value === null) {
          return "Vui lòng chọn người điều chuyển liên chi nhánh!";
        }
        return null;
      },
      idNguoiDieuChuyenNbcn: (value: number | null) => {
        if (value === null) {
          return "Vui lòng chọn người điều chuyển nội bộ!";
        }
        return null;
      },
    },
  });
  const [selectedSubInvLabel, setSelectedSubInvLabel] = useState<string | null>(
    null
  );
  const handleEditTblDMBranch = async (dataSubmit: TblDMInventoryGroup) => {
    open();
    const dataApi = await repositoryMdm.post<
      MessageResponse<TblDMInventoryGroup>
    >("/api/v1/TblDmInventoryGroup/update", dataSubmit);

    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
    }
    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<
      MessageResponse<TblDMInventoryGroup>
    >(`/api/v1/TblDmInventoryGroup/update?id=${id}`);

    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();

      return;
    }

    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        const selectedWarehouse = listData.find(
          (item) =>
            Number(item.value) === callapi?.data?.idNguoiQuanLy ||
            Number(item.value) === callapi?.data?.idNguoiDieuChuyenLcn ||
            Number(item.value) === callapi?.data?.idNguoiDieuChuyenNbcn
        );
        setSelectedSubInvLabel(
          selectedWarehouse ? selectedWarehouse.label : null
        );
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const [listData, setListData] = useState<{ value: string; label: string }[]>(
    []
  );
  const fetchDataNV = async () => {
    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmEmployee/get-select`
      );
      if (response && response.success) {
        const data = response.data;
        const formattedData = data.map((item: any) => ({
          value: item.value,
          label: item.text,
        }));
        setListData(formattedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  useEffect(() => {
    fetchDataNV();
  }, []);
  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        onSubmit={form.onSubmit((e: TblDMInventoryGroup) => {
          handleEditTblDMBranch(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Grid mt={10}>
          <Grid.Col span={4}>
            <TextInput
              label={"Mã loại"}
              placeholder={"Nhập mã loại"}
              type="text"
              withAsterisk
              onKeyDown={handleKeyDown}
              {...form.getInputProps("invGroupCode")}
            />
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              label={"Tên loại"}
              placeholder={"Nhập tên loại"}
              type="text"
              withAsterisk
              onKeyDown={handleKeyDown}
              w={"100%"}
              {...form.getInputProps("invGroupName")}
            />
          </Grid.Col>
        </Grid>

        <Textarea
          label={"Diễn giải"}
          placeholder={"Nhập diễn giải"}
          autosize
          withAsterisk
          onKeyDown={handleKeyDown}
          {...form.getInputProps("dienGiai")}
        />

        <Grid>
          <Grid.Col>
            <Checkbox
              label={"Chức năng bán"}
              onKeyDown={handleKeyDown}
              checked={form.values.chucNangBan}
              {...form.getInputProps("chucNangBan")}
              mt={16}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col>
            {/* <TextInput
              label={"Người quản lý"}
              placeholder={"Nhập người quản lý"}
              type="text"
              onKeyDown={handleKeyDown}
              withAsterisk
              {...form.getInputProps("nguoiQuanLy")}
            /> */}{" "}
            <Select
              id="idNguoiQuanLy"
              key={form.key("idNguoiQuanLy")}
              label={"Người quản lý"}
              placeholder={"Nhập người quản lý"}
              withAsterisk
              data={listData}
              value={form.getValues().idNguoiQuanLy?.toString()}
              clearable
              onChange={(e) => {
                form.setValues((prev) => ({
                  ...prev,
                  idNguoiQuanLy: e ? Number(e) : null,
                }));

                const selectedWarehouse = listData.find(
                  (item) => item.value === e
                );
                setSelectedSubInvLabel(
                  selectedWarehouse ? selectedWarehouse.label : null
                );
              }}
            />
          </Grid.Col>
        </Grid>
        <Grid mt={8}>
          <Grid.Col>
            <Text size="md" fw="bold" ta="center">
              Điều chuyển liên chi nhánh
            </Text>
          </Grid.Col>
          <Grid.Col>
            <Grid.Col>
              <Checkbox
                label={"Điều chuyển"}
                onKeyDown={handleKeyDown}
                checked={form.values.dieuChuyenLcn}
                {...form.getInputProps("dieuChuyenLcn")}
              />
            </Grid.Col>
            {/* <TextInput
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              type="text"
              onKeyDown={handleKeyDown}
              {...form.getInputProps("nguoiDieuChuyenLienchinhanh")}
            /> */}
            <Select
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              withAsterisk
              data={listData}
              value={form.getValues().idNguoiDieuChuyenLcn?.toString()}
              clearable
              onChange={(e) => {
                form.setValues((prev) => ({
                  ...prev,
                  idNguoiDieuChuyenLcn: e ? Number(e) : null,
                }));

                const selectedWarehouse = listData.find(
                  (item) => item.value === e
                );
                setSelectedSubInvLabel(
                  selectedWarehouse ? selectedWarehouse.label : null
                );
              }}
            />
          </Grid.Col>
        </Grid>
        <Grid mt={8}>
          <Grid.Col>
            <Text size="md" fw="bold" ta="center">
              Điều chuyển nội bộ
            </Text>
          </Grid.Col>
          <Grid.Col>
            <Checkbox
              label={"Điều chuyển"}
              onKeyDown={handleKeyDown}
              checked={form.values.dieuChuyenNoibo}
              {...form.getInputProps("dieuChuyenNoibo")}
            />
          </Grid.Col>
          <Grid.Col>
            {/* <TextInput
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              type="text"
              onKeyDown={handleKeyDown}
              {...form.getInputProps("nguoiDieuChuyenNoibo")}
            /> */}
            <Select
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              withAsterisk
              data={listData}
              {...form.getInputProps("idNguoiDieuChuyenNbcn")}
              value={form.getValues().idNguoiDieuChuyenNbcn?.toString()}
              onChange={(e) => {
                form.setValues((prev) => ({
                  ...prev,
                  idNguoiDieuChuyenNbcn: e ? Number(e) : null,
                }));

                const selectedWarehouse = listData.find(
                  (item) => item.value === e
                );
                setSelectedSubInvLabel(
                  selectedWarehouse ? selectedWarehouse.label : null
                );
              }}
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            disabled={!enable}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default EditDataView;

type EditDataViewProps = {
  id: string | number;
  onClose: any;
};
