import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Menu,
  NumberFormatter,
  NumberInput,
  ScrollArea,
  Select,
  Switch,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandLeetcode,
  IconCheck,
  IconEdit,
  IconMinus,
  IconPlus,
  IconReceipt,
  IconSearch,
  IconTrash,
  IconX,
} from "@tabler/icons-react";

import { notifications } from "@mantine/notifications";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import { TblDebtGroup } from "../../../../model/TblDebtGroup";
import { modals } from "@mantine/modals";
import AddFormDebt from "../../AddFormDebt/AddFormDebt";
import { SelectListItemBase } from "../../../../_base/model/_base/SelectListItemBase";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { EmployeeModel } from "../../../../model/Employee";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
import EditableTextWithOptions from "../../../../common/selectValue";
import { TblDMCustomer } from "../../../../model/TblDMCustomerSell";

interface FundDetails {
  accountfund: string | number | null | undefined;
  codefund: string | null;
  createby: number;
  createdDate: string;
  creatorbranch: number | null | undefined;
  creatordepartment: number | null | undefined;
  creatorfullname?: string | null;
  creatorsalesbranch: string | null;
  creatorworkplace: string | null;
  fundbranch: string | null;
  funddepartment: string | null;
  fundsalesbranch: string | null;
  fundworkplace: string | null;
  funfullname: string | null;
  id: number;
  payerdescription: string | null;
  payerfullname: string | null;
  payernote: string | null;
  payertype: string | null;
  receiptNumber: string;
  anotherName: string | null;
  phoneNumber: string | null;
  codeCustomer: string | null;
  totalAmount: number;
  type: number;
  typeReceipt: number;
  completiontime?: string;
  status?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  attribute6?: string | null;
  attribute7?: string | null;
  bankname?: string | null;
  createrolename?: string | null;
  accountnumber?: string | null;
}

interface DepartmentUser {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  departmentCode: string;
  creationDate: string;
  createdBy: string;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
}

interface UserData {
  code: string;
  depName: string;
  branchId: number;
  branchName: string;
  departmentId: number;
  fullName: string;
  id: number;
  managerId: number | null;
  managerName: string | null;
  roleId: number;
  roleName: string;
}

interface User {
  id: number;
  code: string;
  fullname: string;
  dateOfBirth: string;
  gender: number;
  departmentId: number | null;
  departmentName: string | null;
  roleId: number;
  roleName: string;
  managerName: string | null;
  address: string | null;
  phoneNumber: string;
  departmentCode?: string | null;
  active: boolean;
  email: string;
  description: string | null;
  accountingCenterId: number | null;
  managerId: number | null;
  isCrm: boolean | null;
  currentCardId: number;
  partCode: string | null;
  createDate: string;
  listBranch: string | null;
  listInventory: string | null;
  listGroup: string;
  branchId: number;
  branchName: string;
  workAddress: string | null;
}

interface CreateReceiptParentRequest {
  //   receiptCommand: Command[];
  paymentReceiptDetailCommand: FundDetails;
  save: boolean | null | undefined;
}

interface Permission {
  id: number;
  code: string;
  name: string;
  createBy: string | null;
  createDate: string;
  lastUpdateDate: string | null;
  lastUpdateBy: string | null;
}

interface Program {
  id: number;
  code: string;
  name: string;
  createBy: string | null;
  createDate: string;
  lastUpdateDate: string | null;
  lastUpdateBy: string | null;
}

interface DebtData {
  id?: number;
  code?: string;
  codeForFees?: string | null;
  occurringCredit?: number | null;
  debtObjectCode?: string | null;
  debtObjectName?: string | null;
  descriptionFund?: string | null;
  description?: string | null;
  departmentFund?: string | null;
  fee?: number | null;
  contract?: string | null;
}

interface DepartmentCustomerOrder {
  id: number;
  name: string;
  details: {
    fullName: string;
    phoneNumber: string;
  };
}

interface RowErrors {
  occurringCredit?: string;
  debtObjectCode?: string;
  debtObjectName?: string;
  departmentFund?: string;
  contract?: string;
}

const dataExchangeRate = [
  {
    calculation: "Tiền Việt Nam",
    currencyType: "VND",
    value: 1,
    id: 1111,
  },
  {
    calculation: "Tiền mặt ngoại tệ",
    currencyType: "USD",
    value: 24200,
    id: 1112,
  },
  {
    calculation: "Vàng bạc, kim khí quí, đá quí",
    currencyType: "GOLD",
    value: 7800000,
    id: 1113,
  },
];

interface AccountantAccount {
  id: number;
  accountantAccount: string;
  accountantAccountName: string;
  accountLevel: number;
  createBy: string | null;
  createDate: string;
  lastUpdateDate: string | null;
  lastUpdateBy: string | null;
}

const PaymentSlip = () => {
  const navigate = useNavigate();
  const userName = localStorage.getItem("userName") || "";
  const [userData, setUserData] = useState<UserData | null>(null);
  const [low, setLow] = useState<string | null>("");
  const [dataGetReceiptParent, setDataGetReceiptParent] = useState<any>();
  const [listdata, setListData] = useState<FundDetails>();

  const [isRunning, setIsRunning] = useState<boolean>(true);
  const [newDate, setNewDate] = useState<Date | null>(new Date());
  const [height, setHeight] = useState(0);
  const [branchDeparment, setBranchDeparment] = useState<DepartmentUser>();
  const [statusReponse, setStatusReponse] = useState<any>();
  const [changeFielselect, setChangeFielselect] = useState<any>();
  const [changeValueTotal, setChangeValueTatal] = useState<any>();
  const [data, setData] = useState<TblDMCustomer[]>([]);
  const [customerOrders, setCustomerOrders] = useState<
    DepartmentCustomerOrder[]
  >([]);
  const [selectedCalculation, setSelectedCalculation] = useState<string>("VND");
  const [dataGetDepartments, setDataGetDepartments] = useState<
    SelectListItemBase[]
  >([]);
  const [handleFullName, setHandleFullName] = useState<String | null>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [stoppedTime, setStoppedTime] = useState<Date | null>();
  const [valueDataLocation, setValueDataLocation] = useState<string>("");
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [listEmployee, setListEmployee] = useState<EmployeeModel[]>([]);
  const [listTblDmEmployee, setListTblDmEmployee] = useState<User>();
  const [listTblBranch, setListTblBranch] = useState<User>();
  const [handelAttrible, setHandelAttrible] = useState<boolean>(false);
  const [handlePayertype, setHandlePayertype] = useState<boolean>(false);
  const [handleOther, setHandleOther] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<Date | null>();
  const [completeTime, setCompleteTime] = useState<Date | null>(null);
  const [inputValues, setInputValues] = useState<{ [key: number]: any }>({});
  const [handelDateCurrent, setHandelDateCurrent] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>(1);
  const [branchAll, setBranchAll] = useState<DepartmentUser[]>([]);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);
  const [dataDepartments, setDataDepartments] = useState<SelectListItemBase[]>(
    []
  );
  const [dataAcount, setDataAcount] = useState<AccountantAccount[]>([]);

  const [dataGetReceiptContract, setDataGetReceiptContract] = useState<
    Program[]
  >([]);

  const [dataGetReceiptFee, setDataReceiptFee] = useState<Permission[]>([]);

  const [occurringCredits, setOccurringCredits] = useState<number>(0);
  const [dataBranch, setDataBranch] = useState<SelectListItemBase[]>([]);
  const [filteredData, setFilteredData] = useState(dataCodeFund);
  const [dataTblRole, setDataTblRole] = useState<any[]>([]);
  const [fundepartmentId, setFundepartmentId] = useState<number | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const [inputValueDept, setInputValueDept] = useState("");
  const [currentTimeDelay, setCurrentTimeDelay] = useState<string>(
    moment(newDate).format()
  );
  const [groupData, setGroupData] = useState({
    id: dataGetReceiptParent?.attribute2?.id || "",
    groupName: dataGetReceiptParent?.attribute2?.groupName || "",
    groupCode: dataGetReceiptParent?.attribute2?.groupCode || "",
    groupCreateByName: dataGetReceiptParent?.attribute2?.createByName || "",
  });

  const [errors, setErrors] = useState<{ [index: number]: RowErrors }>({});

  const [inputValue, setInputValue] = useState(groupData.groupName);

  const [formErrors, setFormErrors] = useState({
    payerfullname: "",
    accountfund: 1,
    codefund: "",
    createby: 0,
    createdDate: "",
    creatorbranch: "",
    creatordepartment: "",
    creatorfullname: "",
    creatorsalesbranch: "",
    creatorworkplace: "",
    fundbranch: "",
    attribute2: "",
    attribute3: "",
    attribute6: "",
    funddepartment: "",
    fundsalesbranch: "",
    fundworkplace: "",
    funfullname: "",
    id: 0,
    payerdescription: "",
    payernote: "",
    codeCustomer: "",
    anotherName: "",
    phoneNumber: "",
    payertype: "",
    receiptNumber: "",
    totalAmount: "",
    type: 0,
  });

  const [formData, setFormData] = useState({
    accountfund: 1111,
    codefund: "1111",
    createby: 0,
    createdDate: moment(stoppedTime, "DD-MM-YYYY HH:mm:ss")
      .add(7, "hours")
      .toISOString(),
    completiontime: moment(currentTime, "DD-MM-YYYY HH:mm:ss")
      .add(7, "hours")
      .toISOString(),
    creatorbranch: userData?.branchId,
    creatordepartment: userData?.depName,
    creatorsalesbranch: null,
    creatorworkplace: "129 Lê Thanh Nghị",
    fundbranch: "",
    funddepartment: "",
    fundsalesbranch: "",
    occurringCredit: null,
    fundworkplace: valueDataLocation ?? "",
    funfullname: "",
    payerdescription: "",
    payerfullname: "",
    payernote: "",
    payertype: "Nội bộ",
    anotherName: "",
    phoneNumber: "",
    codeCustomer: "",
    receiptNumber: listdata?.receiptNumber,
    totalAmount: 0,
    type: 5,
    attribute2: "",
    attribute3: "",
    attribute4: "",
    attribute6: "",
    typeReceipt: listdata?.typeReceipt,
  });

  const getDataFromLocalStorage = (key: string): UserData | null => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const [debtData, setDebtData] = useState<DebtData[]>([]);

  const formatReceiptNumber = (receiptNumber: string): string => {
    const prefix = receiptNumber.slice(0, 2);
    const year = receiptNumber.slice(2, 4);
    const month = receiptNumber.slice(5, 7);
    const day = receiptNumber.slice(8, 10);
    const suffix = receiptNumber.slice(11);

    return `${prefix}${day}.${month}.${year}.${suffix}`;
  };

  const formatFullName = (value: string) => {
    return value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleChangeRate = (value: any) => {
    setSelectedCalculation(value);
  };

  const handleChange = (
    field: string,
    value: any,
    valueDataLocation?: string | null
  ) => {
    if (field === "attribute3" && formErrors.attribute3) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        attribute3: value ? "" : "Vui lòng chọn chức danh!",
      }));
    }

    if (field === "attribute2" && formErrors.attribute2) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        attribute2: value ? "" : "Vui lòng chọn chức danh!",
      }));
    }

    if (field === "fundworkplace" && formErrors.fundworkplace) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fundworkplace: value ? "" : "Vui lòng chọn địa điểm làm việc!",
      }));
    }

    if (field === "fundbranch" && formErrors.fundbranch) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fundbranch: value ? "" : "Vui lòng chọn chi nhánh!!",
      }));
    }

    if (field === "funfullname" && formErrors.funfullname) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        funfullname: value ? "" : "Vui lòng chọn họ và tên!",
      }));
    }

    if (field === "codefund" && formErrors.codefund) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        codefund: value ? "" : "Vui lòng chọn mã phí!",
      }));
    }

    if (field === "funddepartment" && formErrors.funddepartment) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        funddepartment: value ? "" : "Vui lòng chọn phòng ban!",
      }));
    }

    if (field === "totalAmount" && formErrors.totalAmount) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        totalAmount: value ? "" : "Vui lòng nhập số tiền!",
      }));
    }

    if (field === "payerdescription" && formErrors.payerdescription) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        payerdescription: value ? "" : "Vui lòng nhập thông tin!",
      }));
    }

    if (field === "codeCustomer" && formErrors.codeCustomer) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        codeCustomer: value ? "" : "Vui lòng chọn mã KH/NCC!",
      }));
    }

    if (field === "anotherName" && formErrors.anotherName) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        anotherName: value ? "" : "Vui lòng nhập tên!",
      }));
    }

    if (field === "phoneNumber" && formErrors.phoneNumber) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: value ? "" : "Vui lòng nhập số điện thoại!",
      }));
    }

    if (field === "codeCustomer" && formErrors.codeCustomer) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        codeCustomer: value ? "" : "Vui lòng chọn mã KH/NCC!",
      }));
    }

    if (value) {
      setChangeFielselect(value);
    }

    if (field === "payertype") {
      if (value !== "Nội bộ") {
        setHandlePayertype(true);
      } else {
        setHandlePayertype(false);
      }
    }

    if (field === "payertype") {
      if (value !== "Khác") {
        setHandleOther(false);
      } else {
        setHandleOther(true);
      }
    }

    if (field === "totalAmount") {
      setChangeValueTatal(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: value instanceof Date ? value.toISOString() : value,
    }));
  };

  const handleChooseDebt = (
    value: TblDebtGroup,
    fieldName: string,
    row: any
  ) => {
    if (fieldName === "attribute2") {
      setDataGetReceiptParent((prevState: any) => ({
        ...prevState,
        [fieldName]: {
          id: value?.id || "",
          groupCode: value?.groupCode || "",
          groupName: value?.groupName || "",
          taxCode: value?.taxCode || "",
          address: value?.address || "",
          phone: value?.phone || "",
          contactPerson: value?.contactPerson || "",
          classification: value?.classification || "",
          classificationName: value?.classificationName || "",
          debtTerm: value?.debtTerm || "",
          debtLimit: value?.debtLimit || "",
          createBy: value?.createBy || "",
          createByName: value?.createByName || "",
          createDate: value?.createDate || "",
          lastUpdateBy: value?.lastUpdateBy || "",
          lastCreateByName: value?.lastCreateByName || "",
          lastUpdateDate: value?.lastUpdateDate || "",
          type: value?.type || "",
          typeName: value?.typeName || "",
        },
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [row.original.codeForFees]: {
          debtObjectCode: value.id,
          debtObjectName: value.groupName,
          debtObjectCodeGroup: value.groupCode,
        },
      }));
    }
  };
  // useEffect(() => {
  //   setInputValueDept(
  //     dataGetReceiptParent?.groupName
  //       ? dataGetReceiptParent?.id + " - " + dataGetReceiptParent?.groupName
  //       : ""
  //   );
  // }, [dataGetReceiptParent]);
  function openFormDebt(fieldName: string, row?: any) {
    modals.openConfirmModal({
      title: <Title order={5}>Danh Sách Đối Tượng Công Nợ</Title>,
      size: "90vw",
      children: (
        <AddFormDebt
          handleChooseDebt={(value: any) =>
            handleChooseDebt(value, fieldName, row)
          }
          navigate={navigate}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const formInternal = () => (
    <>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Chi nhánh <span style={{ color: "red" }}>*</span>
          </Text>
          <Select
            searchable
            flex={1}
            clearable
            error={
              !handlePayertype && formErrors.fundbranch
                ? formErrors.fundbranch
                : undefined
            }
            disabled={
              statusReponse
                ? statusReponse
                : !handlePayertype && changeFielselect !== "-1"
                ? false
                : true
            }
            value={
              dataBranch?.find(
                (item: SelectListItemBase) => item.value === formData.fundbranch
              )?.value || null
            }
            data={dataBranch
              .map((ele, index) => {
                return {
                  label: ele.text,
                  value: ele.value,
                };
              })
              .slice(1)}
            placeholder="Vui lòng chọn chi nhánh"
            onChange={(selectedDescription) => {
              if (!selectedDescription) {
                handleChange("fundbranch", null);
              } else {
                const selectedOption = dataBranch?.find(
                  (item: SelectListItemBase) =>
                    item.value === selectedDescription
                );
                if (selectedOption) {
                  handleChange("fundbranch", selectedOption.value);
                  handleChange("fundworkplace", selectedOption.att1);
                  handleChange("funddepartment", "");
                  handleChange("attribute3", "");
                  handleChange("funfullname", null);
                  setFundepartmentId(Number(selectedOption?.value));
                }
              }
            }}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Họ và tên <span style={{ color: "red" }}>*</span>
          </Text>
          <Select
            searchable
            clearable
            flex={1}
            error={
              !handlePayertype && formErrors.funfullname
                ? formErrors.funfullname
                : undefined
            }
            disabled={statusReponse || handlePayertype || !formData?.fundbranch}
            placeholder="Vui lòng chọn họ và tên"
            comboboxProps={{
              transitionProps: {
                transition: "pop",
                duration: 200,
              },
            }}
            limit={50}
            value={
              listEmployee
                ?.find(
                  (item: any) =>
                    String(item.id) === String(formData.funfullname)
                )
                ?.id.toString() || null
            }
            data={listEmployee.map((item) => {
              const formatUnder = formatFullName(item.fullname);
              return {
                label: formatUnder + " - " + item.code,
                value: String(item.id ?? null),
              };
            })}
            onChange={(selectedValue) => {
              setHandleFullName(selectedValue);
              if (!selectedValue) {
                handleChange("attribute3", "");
                handleChange("funfullname", null);
                handleChange("funddepartment", "");
              } else {
                const selectedOption = listEmployee.find(
                  (item) => String(item.id) === selectedValue
                );
                if (selectedOption) {
                  handleChange("funfullname", selectedOption?.id);
                  handleChange("attribute3", listTblDmEmployee?.roleName);
                  handleChange("funddepartment", listTblBranch?.departmentId);
                }
              }
            }}
          />
        </Flex>
      </Grid.Col>

      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            ĐĐ Làm việc <span style={{ color: "red" }}>*</span>
          </Text>
          <Tooltip label={formData.fundworkplace ?? ""}>
            <TextInput
              flex={1}
              error={
                !handlePayertype && formErrors.fundworkplace
                  ? formErrors.fundworkplace
                  : undefined
              }
              disabled={true}
              value={formData.fundworkplace ?? ""}
              placeholder="Vui lòng chọn ĐĐ làm việc"
            />
          </Tooltip>
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Chức danh <span style={{ color: "red" }}>*</span>
          </Text>
          {/* <TextInput disabled>{listTblDmEmployee?.roleName ?? ""}</TextInput> */}
          {/* <Select
            flex={1}
            disabled={
              statusReponse
                ? statusReponse
                : !handlePayertype && changeFielselect !== "-1"
                ? false
                : true
            }
            error={
              !handlePayertype && formErrors.attribute3
                ? formErrors.attribute3
                : undefined
            }
            placeholder="Vui lòng chọn chức danh"
            data={[
              {
                value: String(listTblDmEmployee?.roleId) ?? "",
                label: listTblDmEmployee?.roleName ?? "",
              },
            ]}
            onChange={(selectedValue) => {
              const selectedOption = listTblDmEmployee && {
                value: String(listTblDmEmployee.roleId),
                label: listTblDmEmployee.roleName,
              };

              if (!selectedValue) {
                handleChange("attribute3", null);
              } else if (selectedOption?.value === selectedValue) {
                handleChange("attribute3", selectedOption.label);
              }
            }}
          /> */}
          <TextInput
            flex={1}
            disabled={true}
            error={
              !handlePayertype && formErrors.attribute3
                ? formErrors.attribute3
                : undefined
            }
            placeholder="Vui lòng chọn chức danh"
            value={formData.attribute3 ?? ""}
          />
          {/* <Select
            searchable
            flex={1}
            clearable
            error={
              !handlePayertype && formErrors.fundbranch
                ? formErrors.fundbranch
                : undefined
            }
            disabled={!!handlePayertype}
            value={
              salesPositions?.find(
                (item: any) => item.id === formData.fundbranch
              )?.name || null
            }
            placeholder="Vui lòng chọn chức danh"
            data={
              salesPositions
                ?.map((e: any) => e.name)
                .filter((name): name is string => !!name) ?? []
            }
            onChange={(selectedDescription) => {
              if (!selectedDescription) {
                handleChange("fundbranch", null);
              } else {
                const selectedOption = salesPositions?.find(
                  (item: any) => item.name === selectedDescription
                );
                if (selectedOption) {
                  handleChange("fundbranch", selectedOption.id);
                }
              }
            }}
          /> */}
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Phòng ban <span style={{ color: "red" }}>*</span>
          </Text>
          <TextInput
            flex={1}
            disabled={true}
            error={
              !handlePayertype && formErrors.funddepartment
                ? formErrors.funddepartment
                : undefined
            }
            value={
              formData.funddepartment
                ? listTblBranch?.departmentCode +
                  " - " +
                  listTblBranch?.departmentName
                : ""
            }
            placeholder="Vui lòng chọn phòng ban"
          />
        </Flex>
      </Grid.Col>
    </>
  );

  const formOther = () => (
    <>
      <Grid.Col pt={0} span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Mã KH/NCC <span style={{ color: "red" }}>*</span>
          </Text>
          <Select
            searchable
            clearable
            flex={1}
            error={
              handlePayertype && !handleOther && formErrors.codeCustomer
                ? formErrors.codeCustomer
                : undefined
            }
            disabled={
              statusReponse ? statusReponse : !handlePayertype || handleOther
            }
            value={
              customerOrders?.find(
                (item: any) => item.id === formData.codeCustomer
              )?.name || null
            }
            placeholder="Vui lòng chọn Mã KH/NCC"
            data={
              customerOrders
                ?.map((e: any) => e.name)
                .filter((name: string): name is string => !!name)
                .filter((name, index, self) => self.indexOf(name) === index) ??
              []
            }
            onClick={async () => await fetchData()}
            onChange={(selectedDescription) => {
              if (!selectedDescription) {
                handleChange("codeCustomer", null);
                handleChange("anotherName", null);
                handleChange("phoneNumber", null);
              } else {
                const selectedOption = customerOrders?.find(
                  (item: any) => item.name === selectedDescription
                );
                if (selectedOption) {
                  handleChange("codeCustomer", selectedOption.id);
                  handleChange("anotherName", selectedOption.details.fullName);
                  handleChange(
                    "phoneNumber",
                    selectedOption.details.phoneNumber
                  );
                }
              }
            }}
          />
        </Flex>
      </Grid.Col>
      <Grid.Col pt={0} span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}>
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Tên KH/NCC <span style={{ color: "red" }}>*</span>
          </Text>
          <TextInput
            flex={1}
            disabled={
              statusReponse ? statusReponse : !handlePayertype || !handleOther
            }
            error={
              handlePayertype && formErrors.anotherName
                ? formErrors.anotherName
                : undefined
            }
            value={formData.anotherName || ""}
            onChange={(e) => {
              const input = e.target.value;
              if (/\d/.test(input)) {
              } else if (!input) {
                handleChange("anotherName", null);
              } else {
                handleChange("anotherName", input);
              }
            }}
            w={185}
            placeholder="Vui lòng nhập tên"
          />
        </Flex>
      </Grid.Col>

      <Grid.Col
        offset={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
        pt={0}
        span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
      >
        <Flex align="center">
          <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
            Số điện thoại <span style={{ color: "red" }}>*</span>
          </Text>
          <TextInput
            flex={1}
            w={185}
            error={
              handlePayertype && formErrors.phoneNumber
                ? formErrors.phoneNumber
                : undefined
            }
            disabled={
              statusReponse ? statusReponse : !handlePayertype || !handleOther
            }
            value={formData.phoneNumber || ""}
            placeholder="Nhấp số điện thoại"
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value) && value.length <= 11) {
                handleChange("phoneNumber", value);
              }
              if (!e) {
                handleChange("phoneNumber", null);
              }
            }}
          />
        </Flex>
      </Grid.Col>
    </>
  );
  const fetchCreateReceiptParent = async (handleSave?: boolean) => {
    let isValid = true;
    const newErrors: { [index: number]: RowErrors } = {};
    const errors = {
      payerfullname: "",
      accountfund: 0,
      codefund: "",
      createby: 0,
      createdDate: "",
      creatorbranch: "",
      creatordepartment: "",
      creatorfullname: "",
      creatorsalesbranch: "",
      creatorworkplace: "",
      occurringCredit: "",
      fundbranch: "",
      attribute3: "",
      attribute2: "",
      attribute4: "",
      attribute6: "",
      funddepartment: "",
      fundsalesbranch: "",
      fundworkplace: "",
      funfullname: "",
      id: 0,
      payerdescription: "",
      codeCustomer: "",
      anotherName: "",
      phoneNumber: "",
      payernote: "",
      payertype: "",
      receiptNumber: "",
      totalAmount: "",
      type: 0,
    };

    if (!handlePayertype) {
      if (!formData.attribute3) {
        errors.attribute3 = "Vui lòng chọn chức danh!";
        isValid = false;
        notifications.show({
          title: <Title order={5}>Thông báo</Title>,
          autoClose: 4000,
          message: "Vui lòng chọn chức danh!",
          color: "red",
          icon: <IconX />,
        });
      }

      if (!formData.funfullname) {
        errors.funfullname = "Vui lòng chọn họ và tên!";
        isValid = false;
        notifications.show({
          title: <Title order={5}>Thông báo</Title>,
          autoClose: 4000,
          message: "Vui lòng chọn họ và tên!",
          color: "red",
          icon: <IconX />,
        });
      }

      if (!formData.fundbranch) {
        errors.fundbranch = "Vui lòng chọn chi nhánh!";
        isValid = false;
        notifications.show({
          title: <Title order={5}>Thông báo</Title>,
          autoClose: 4000,
          message: "Vui lòng chọn chi nhánh!",
          color: "red",
          icon: <IconX />,
        });
      }

      if (!formData.fundworkplace) {
        errors.fundworkplace = "Vui lòng chọn địa điểm làm việc!";
        isValid = false;
        notifications.show({
          title: <Title order={5}>Thông báo</Title>,
          autoClose: 4000,
          message: "Vui lòng chọn địa điểm làm việc!",
          color: "red",
          icon: <IconX />,
        });
      }

      if (!formData.totalAmount) {
        errors.totalAmount = "Tổng tiền chưa được hiển thị!";
        isValid = false;
        notifications.show({
          title: <Title order={5}>Thông báo</Title>,
          autoClose: 4000,
          message: "Tổng tiền chưa được hiển thị!",
          color: "red",
          icon: <IconX />,
        });
      }

      if (!formData.funddepartment) {
        errors.funddepartment = "Vui lòng chọn phòng ban!";
        isValid = false;
        notifications.show({
          title: <Title order={5}>Thông báo</Title>,
          autoClose: 4000,
          message: "Vui lòng chọn phòng ban!",
          color: "red",
          icon: <IconX />,
        });
      }

      if (!formData.attribute2) {
        errors.attribute2 = "Vui lòng chọn mã đối tượng công nợ!";
        isValid = false;
        notifications.show({
          title: <Title order={5}>Thông báo</Title>,
          autoClose: 4000,
          message: "Vui lòng chọn mã đối tượng công nợ!",
          color: "red",
          icon: <IconX />,
        });
      }
    } else {
      if (!handleOther) {
        if (!formData.codeCustomer) {
          errors.codeCustomer = "Vui lòng chọn mã KH/NCC!";
          isValid = false;
          notifications.show({
            title: <Title order={5}>Thông báo</Title>,
            autoClose: 4000,
            message: "Vui lòng chọn mã KH/NCC!",
            color: "red",
            icon: <IconX />,
          });
        }

        if (!formData.attribute2) {
          errors.attribute2 = "Vui lòng chọn mã đối tượng công nợ!";
          isValid = false;
          notifications.show({
            title: <Title order={5}>Thông báo</Title>,
            autoClose: 4000,
            message: "Vui lòng chọn mã đối tượng công nợ!",
            color: "red",
            icon: <IconX />,
          });
        }
        if (!formData.totalAmount) {
          errors.totalAmount = "Tổng tiền chưa được hiển thị!";
          isValid = false;
          notifications.show({
            title: <Title order={5}>Thông báo</Title>,
            autoClose: 4000,
            message: "Tổng tiền chưa được hiển thị!",
            color: "red",
            icon: <IconX />,
          });
        }
      }

      if (formData.payertype !== "NCC" && formData.payertype !== "Khách hàng") {
        if (!formData.anotherName) {
          errors.anotherName = "Vui lòng nhập tên!";
          isValid = false;
          notifications.show({
            title: <Title order={5}>Thông báo</Title>,
            autoClose: 4000,
            message: "Vui lòng nhập tên!",
            color: "red",
            icon: <IconX />,
          });
        }

        if (!formData.phoneNumber) {
          errors.phoneNumber = "Vui lòng nhập số điện thoại!";
          isValid = false;
          notifications.show({
            title: <Title order={5}>Thông báo</Title>,
            autoClose: 4000,
            message: "Vui lòng nhập số điện thoại!",
            color: "red",
            icon: <IconX />,
          });
        }

        if (!formData.totalAmount) {
          errors.totalAmount = "Tổng tiền chưa được hiển thị!";
          isValid = false;
          notifications.show({
            title: <Title order={5}>Thông báo</Title>,
            autoClose: 4000,
            message: "Tổng tiền chưa được hiển thị!",
            color: "red",
            icon: <IconX />,
          });
        }

        if (!formData.attribute2) {
          errors.attribute2 = "Vui lòng chọn mã đối tượng công nợ!";
          isValid = false;
          notifications.show({
            title: <Title order={5}>Thông báo</Title>,
            autoClose: 4000,
            message: "Vui lòng chọn mã đối tượng công nợ!",
            color: "red",
            icon: <IconX />,
          });
        }
      }
    }

    debtData.forEach((row: any, index: number) => {
      if (!row.occurringCredit) {
        newErrors[index] = {
          ...newErrors[index],
          occurringCredit: "Vui lòng nhập phí phát sinh!",
        };
        notifications.show({
          title: <Title order={5}>Thông báo</Title>,
          autoClose: 5000,
          message: `Vui lòng nhập phí phát sinh!`,
          color: "red",
          icon: <IconX />,
        });
      }
    });

    setErrors(newErrors);
    setFormErrors(errors);

    if (!isValid || Object.keys(newErrors).length > 0) {
      return;
    }

    setIsRunning(false);

    const requestData: CreateReceiptParentRequest = {
      paymentReceiptDetailCommand: {
        id: 0,
        accountfund:
          dataExchangeRate.find((x) => x.id === formData.accountfund)
            ?.currencyType ?? "",
        codefund: formData.codefund, //Mã tài khoản nợ
        createby: formData.createby,
        createdDate: formData.createdDate,
        completiontime: moment(new Date(), "DD-MM-YYYY HH:mm:ss")
          .add(7, "hours")
          .toISOString(),
        createrolename: String(userData?.roleId),
        creatorbranch: Number(userData?.branchId),
        creatordepartment: userData?.departmentId,
        creatorworkplace: String(branchDeparment?.address),
        fundbranch: formData.fundbranch,
        funddepartment: formData.funddepartment,
        creatorsalesbranch: userData?.branchId.toString() ?? "",
        fundsalesbranch: !handlePayertype
          ? userData?.branchId.toString() ?? ""
          : null,
        fundworkplace: formData.fundworkplace,
        funfullname: formData.funfullname,
        payerdescription: formData.payerdescription,
        payerfullname: formData.payerfullname,
        payernote: formData.payernote,
        anotherName: formData.anotherName,
        phoneNumber: formData.phoneNumber,
        codeCustomer: formData.codeCustomer,
        payertype: formData.payertype,
        receiptNumber: listdata?.receiptNumber.toString() ?? "",
        totalAmount: occurringCredits,
        type: formData.type,
        attribute2: formData.attribute2, // Mã nhóm công nợ
        attribute3: formData.attribute3, //Bộ phận
        attribute4: formData.attribute4,
        attribute5: JSON.stringify(debtData), //Mã phí
        attribute6:
          dataAcount.find(
            (ele) => String(ele.accountantAccount) === String(formData.codefund)
          )?.accountantAccountName ?? "", //Tên tài khoản nợ
        attribute7: groupData.groupName, //Tên Mã nhóm công nợ
        typeReceipt: 2,
      },
      save: handleSave,
    };

    try {
      const response = await repositoryPos.post<
        MessageResponse<CreateReceiptParentRequest>
      >("/api/v1/CreateSellItem/create-receipt-parent", requestData);

      if (response && response.success) {
        if (handleSave) {
          notifications.show({
            title: "Thành công",
            message: "Phiếu chi đã được xác nhận thành công!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
        } else {
          notifications.show({
            title: "Thành công",
            message: "Phiếu chi đã được lưu thành công!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
        }
        setStatusReponse(response.success);
      } else {
        notifications.show({
          title: "Thất bại",
          message: "Lưu phiếu chi không thành công. Vui lòng thử lại!",
          autoClose: 3000,
          color: "red",
          icon: <IconX />,
        });
      }
    } catch (error) {
      console.error("Error creating receipt:", error);
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
        enableEditing: false,
        enableSorting: false,
      },
      {
        accessorKey: "codeForFees",
        header: "Mã Phí",
        enableEditing: isCreating,
        enableSorting: false,
        size: 200,
        Cell: ({ cell, row, table, column }) => {
          return (
            <Text style={{ whiteSpace: "normal" }} w={250}>
              {row.original.codeForFees}
            </Text>
          );
        },
        Edit: ({ cell, row, table, column }) => {
          return (
            <Menu
              position="bottom-start"
              trigger="hover"
              shadow="md"
              width={"700"}
            >
              <Menu.Target>
                <Tooltip
                  label={`${
                    !groupData.id
                      ? "Vui lòng chọn mã nhóm công nợ"
                      : "Chọn mã phí"
                  }`}
                >
                  <TextInput
                    autoFocus
                    placeholder="Hoặc tìm kiếm theo mã phí"
                    size="xs"
                    disabled={statusReponse}
                    w={300}
                    rightSection={<IconSearch size={14} />}
                    // value={searchValue}
                    onChange={handleSearch}
                  />
                </Tooltip>
              </Menu.Target>
              <Menu.Dropdown>
                <Table.ScrollContainer minWidth={600} h={250} type="native">
                  <Table striped highlightOnHover stickyHeader>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th w={70}>Thao tác</Table.Th>
                        <Table.Th>Mã phí</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {filteredData?.length > 0 ? (
                        filteredData?.map((item: any, index: number) => (
                          <Table.Tr key={index}>
                            <Table.Td>
                              <Button
                                onClick={() => {
                                  toggleSelectItem(item);
                                  table.setCreatingRow(null);
                                }}
                                size="xs"
                                variant="filled"
                                disabled={selectedItems.includes(item.value)}
                              >
                                {selectedItems.includes(item.value)
                                  ? "Đã chọn"
                                  : "Chọn"}
                              </Button>
                            </Table.Td>
                            <Table.Td>
                              <Text size="sm">{item.text}</Text>
                            </Table.Td>
                          </Table.Tr>
                        ))
                      ) : (
                        <Table.Tr>
                          <Table.Td colSpan={2}>
                            <Text fw={600} ta="center" size="sm">
                              Không có dữ liệu
                            </Text>
                          </Table.Td>
                        </Table.Tr>
                      )}
                    </Table.Tbody>
                  </Table>
                </Table.ScrollContainer>
              </Menu.Dropdown>
            </Menu>
          );
        },
      },
      {
        accessorKey: "occurringCredit",
        header: "Phát Sinh nợ",
        enableSorting: false,
        size: 150,
        Cell: ({ cell, row, table, column }) => {
          return (
            <NumberInput
              disabled={statusReponse}
              placeholder="Vui lòng nhập"
              allowNegative={false}
              thousandSeparator=","
              hideControls
              style={{ whiteSpace: "normal" }}
              rightSection={<IconMinus size={12} />}
              onChange={(e) => {
                handleInputChange(e, row.index, "occurringCredit");
                handleChangeCodeFund(e, row, "occurringCredit");
              }}
              error={errors[row.index]?.occurringCredit}
              w={200}
            />
          );
        },
      },
      {
        accessorKey: "debtObjectCode",
        header: "Mã Đối Tượng Công Nợ",
        enableSorting: false,
        size: 200,
        Cell: ({ cell, row, table, column }) => {
          return (
            <TextInput
              placeholder="Vui lòng nhập"
              flex={1}
              value={
                inputValues[row.original.codeForFees]?.debtObjectCodeGroup &&
                inputValues[row.original.codeForFees]?.debtObjectCode
                  ? `${
                      inputValues[row.original.codeForFees].debtObjectCodeGroup
                    }`
                  : `${groupData.groupCode || ""} `
              }
              disabled={statusReponse}
              w={185}
              rightSection={
                statusReponse ? null : inputValues[row.index] ? (
                  <IconX
                    onClick={() => {
                      deleteRow(row.original.codeForFees);
                      setHandelAttrible(true);
                    }}
                    size={16}
                  />
                ) : null
              }
              onBlur={(e) => {
                const debtObjectCode =
                  inputValues[row.original.codeForFees]?.debtObjectCodeGroup ||
                  "";
                handleChangeCodeFund(debtObjectCode, row, "debtObjectCode");
                handleChangeCodeFund(
                  inputValues[row.original.codeForFees]?.debtObjectName ??
                    row?.original.debtObjectCodeGroup,
                  row,
                  "debtObjectName"
                );
              }}
              onClick={() => openFormDebt("debtObjectCode", row)}
            />
          );
        },
      },
      {
        accessorKey: "debtObjectName",
        header: "Tên Đối Tượng Công Nợ",
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <TextInput
              fw={700}
              disabled
              value={
                inputValues[row.original.codeForFees]?.debtObjectName ??
                row?.original.debtObjectName
              }
              placeholder="Vui lòng nhập"
              style={{ whiteSpace: "normal" }}
              onBlur={(e) => {
                handleChangeCodeFund(e.target.value, row, "debtObjectName");
              }}
              w={300}
            />
          );
        },
      },
      {
        accessorKey: "description",
        header: "Nội Dung",
        enableSorting: false,
        Cell: ({ cell, row, table, column }) => {
          return (
            <TextInput
              disabled={statusReponse}
              placeholder="Vui lòng nhập"
              value={row?.original.description}
              style={{ whiteSpace: "normal" }}
              w={300}
              onChange={(e) => {
                handleChangeCodeFund(e.target.value, row, "description");
              }}
            ></TextInput>
          );
        },
      },
      {
        accessorKey: "departmentFund",
        header: "Bộ Phận",
        enableSorting: false,
        size: 100,
        Cell: ({ cell, row, table, column }) => {
          return (
            <Select
              searchable
              flex={1}
              w={250}
              clearable
              value={
                !handlePayertype
                  ? dataGetDepartments?.find(
                      (item) =>
                        String(item.value) ===
                        String(row.original.departmentFund)
                    )?.value || ""
                  : branchAll
                      ?.find(
                        (item) =>
                          String(item.departmentId) ===
                          String(row.original.departmentFund)
                      )
                      ?.departmentId.toString() || ""
              }
              data={
                !handlePayertype
                  ? dataGetDepartments.map((item) => ({
                      value: item.value,
                      label: item.text,
                    }))
                  : branchAll.map((item) => ({
                      value: item.departmentId.toString(),
                      label: item.name,
                    }))
              }
              disabled={statusReponse || !handlePayertype}
              placeholder="Vui lòng nhập"
              onChange={(e) => {
                !handlePayertype
                  ? handleInputChange(e, row.index, "departmentFund")
                  : handleChangeCodeFund(e, row, "departmentFund");
              }}
              // error={errors[row.index]?.departmentFund}
            />
          );
        },
        Edit: ({ cell, row, table, column }) => {
          return (
            <Text>
              {dataGetDepartments?.find(
                (item: SelectListItemBase) =>
                  String(item.value) === String(row.original.departmentFund)
              )?.text || null}
            </Text>
          );
        },
      },

      {
        accessorKey: "fee",
        header: "CTKM",
        enableSorting: false,
        size: 100,
        Cell: ({ cell, row, table, column }) => {
          return (
            // <TextInput
            //   placeholder="Vui lòng nhập"
            //   style={{ whiteSpace: "normal" }}
            //   w={200}
            //   disabled={statusReponse}
            //   onChange={(e) => {
            //     handleChangeCodeFund(e.target.value, row, "fee");
            //   }}
            // ></TextInput>
            <Select
              searchable
              flex={1}
              w={250}
              clearable
              // value={
              //   dataGetDepartments?.find(
              //     (item: any) => item.name === formData.funddepartment
              //   )?.name || null
              // }
              data={dataGetReceiptFee.map((ele) => {
                return {
                  label: ele.code + " - " + ele.name,
                  value: ele.id.toString(),
                };
              })}
              disabled={statusReponse}
              placeholder="Vui lòng nhập"
              onClick={async () => await fetchDataReceiptFee()}
              onChange={(e) => {
                // handleInputChange(e, row.index, "fee");
                handleChangeCodeFund(e, row, "fee");
              }}
              // error={errors[row.index]?.fee}
            />
          );
        },
      },
      {
        accessorKey: "contract",
        header: "Hợp Đồng",
        enableSorting: false,
        size: 100,
        Cell: ({ cell, row, table, column }) => {
          return (
            <Select
              searchable
              flex={1}
              w={250}
              clearable
              // value={
              //   dataGetDepartments?.find(
              //     (item: any) => item.name === formData.funddepartment
              //   )?.name || null
              // }
              data={dataGetReceiptContract.map((ele) => {
                return {
                  label: ele.code + " - " + ele.name,
                  value: ele.id.toString(),
                };
              })}
              disabled={statusReponse}
              placeholder="Vui lòng nhập"
              onClick={async () => await fetchDataReceiptContract()}
              onChange={(e) => {
                // handleInputChange(e, row.index, "contract");
                handleChangeCodeFund(e, row, "contract");
              }}
              // error={errors[row.index]?.contract}
            />
          );
        },
      },
    ],
    [
      dataCodeFund,
      dataGetReceiptParent,
      inputValues,
      dataDepartments,
      statusReponse,
      groupData,
      inputValue,
      errors,
      dataGetDepartments,
      branchAll,
      isCreating,
      filteredData,
      selectedItems,
      dataGetReceiptContract,
      dataGetReceiptFee,
    ]
  );

  const fetchDataDepart = async () => {
    try {
      const response = await repositoryPos.post<
        MessageResponse<DepartmentUser>
      >(`/api/v1/TblDepartment/detail?id=${listdata?.creatordepartment}`);

      if (response && response.success) {
        const result = response.data;
        setBranchDeparment(result);
      } else {
        console.error(
          "Failed to fetch departmentFund details",
          response?.message
        );
      }
    } catch (error) {
      console.error("Error fetching departmentFund details", error);
    }
  };

  const fetchDataGetAll = async () => {
    try {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentUser[]>
      >(`/api/v1/TblDepartment/get-all`);

      if (response && response.success) {
        const result = response.data;
        setBranchAll(result);
      } else {
        console.error("Failed to fetch departmentFund details");
      }
    } catch (error) {
      console.error("Error fetching departmentFund details", error);
    }
  };

  const fetchDataReceiptAcount = async () => {
    const response = await repositoryPos.get<
      MessageResponse<AccountantAccount[]>
    >("/api/v1/TblDmAccount/get-list?Skip=0&Take=99999");

    if (response && response.success) {
      let result = response.data;
      setDataAcount(result);
    }
  };

  useEffect(() => {
    if (listdata?.creatordepartment) {
      fetchDataDepart();
    }
  }, [listdata?.creatordepartment]);

  const fetchData = async () => {
    let url = `?Skip=0&Take=50`;

    // if (search.keySearch) {
    //   url += `&KeySearch=${search.keySearch}`;
    // }

    try {
      const repository =
        formData.payertype === "NCC" ? repositoryMdm : repositoryPos;
      const response = await repository.get(
        formData.payertype === "NCC"
          ? "/api/v1/TblVendor/get-all"
          : `/api/v1/TblDmCustomer/get-list${url}`
      );
      if (response && response.success) {
        const result = response.data;

        const mappedData: DepartmentCustomerOrder[] = result.map(
          formData.payertype === "NCC"
            ? (customer: any) => ({
                id: customer.id,
                name: customer.vendorCode,
                details: {
                  fullName: customer.vendorName,
                  phoneNumber: customer.phone ?? "",
                },
              })
            : (customer: any) => ({
                id: customer.id,
                name: customer.code + " - " + customer.name,
                details: {
                  fullName: customer.name,
                  phoneNumber: customer.telephoneNumber,
                },
              })
        );

        setCustomerOrders(mappedData);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const handleGroupNameChange = (e: any) => {
    const newValue = e.currentTarget.value;
    setInputValueDept(newValue);
  };

  const fetchDataDepartments = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >(`/api/v1/TblDepartment/get-select?branchId=${fundepartmentId}`);

    if (response && response.success) {
      let result = response.data;

      // const newDepartment = {
      //   att1: null,
      //   att2: null,
      //   att3: 0,
      //   att4: 0,
      //   att5: null,
      //   disabled: false,
      //   group: null,
      //   selected: false,
      //   text: "Khác",
      //   value: "-1",
      // };

      // const updatedDepartments = [...result, newDepartment];
      setDataGetDepartments(result);
    }
  };

  const fetchDataReceiptParent = async () => {
    const response = await repositoryPos.get<MessageResponse<FundDetails>>(
      "/api/v1/CreateSellItem/create-receipt-parent?typeReceipt=CH"
    );

    if (response && response.success) {
      let result = response.data;
      setListData(result);
      // setDataGetReceiptParent(result);
    }
  };

  const fetchDataReceiptContract = async () => {
    const response = await repositoryPos.get<MessageResponse<Program[]>>(
      "/api/v1/TblDmContract/get-list?Skip=0&Take=99999"
    );

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptContract(result);
    }
  };

  const fetchDataReceiptFee = async () => {
    const response = await repositoryPos.get<MessageResponse<Permission[]>>(
      "/api/v1/TblDmFee/get-list?Skip=0&Take=9999"
    );

    if (response && response.success) {
      let result = response.data;
      setDataReceiptFee(result);
    }
  };

  const fetchDataBranch = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmInventory/get-select-branch?auth=false");

    if (response && response.success) {
      let result = response.data;
      setDataBranch(result);
    }
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue.trim().length > 0) {
        setFilteredData(
          dataCodeFund.filter((item) =>
            item.text.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      } else {
        setFilteredData(dataCodeFund);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, dataCodeFund]);

  // useEffect(() => {
  //   if (formData?.codefund) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       codefund: formData.codefund,
  //       attribute6:
  //         accounts.find((ele, index) => ele?.id === formData?.accountfund)
  //           ?.description ?? "",
  //     }));
  //   }
  // }, [formData?.codefund]);

  const getTblDmExpense = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmExpense/get-select");

    if (response && response.success) {
      let result = response.data;
      setDataCodeFunch(result);
      setFilteredData(result);
    }
  };

  const getSelectTblDepartment = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >(`/api/v1/TblDepartment/get-select?branchId=${userData?.branchId}`);

    if (response && response.success) {
      let result = response.data;
      setDataDepartments(result);
    }
  };

  const fetchDataEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<EmployeeModel[]>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${fundepartmentId}&Skip=0&Take=9999`
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployee(result);
    }
  };

  const fetchDataTblRole = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblRole/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setDataTblRole(result);
    }
  };

  const fetchDataTblDmEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/detail-seft?empId=${handleFullName}`
    );

    if (response && response.success) {
      let result = response.data;
      setListTblDmEmployee(result);
      setListTblBranch(result);

      // setListEmployee(result);
    }
  };
  //#region  Start codefund
  const handleInputChange = (
    value: any,
    rowIndex: number,
    field: keyof RowErrors
  ) => {
    const newErrors = { ...errors };
    if (!value) {
      newErrors[rowIndex] = {
        ...newErrors[rowIndex],
        [field]: "Vui lòng nhập",
      };
    } else if (newErrors[rowIndex]) {
      delete newErrors[rowIndex][field];
      if (Object.keys(newErrors[rowIndex]).length === 0) {
        delete newErrors[rowIndex];
      }
    }
    setErrors(newErrors);
  };

  //Thêm thông tin
  const handleChangeCodeFund = (value: any, row: any, field: string) => {
    if (row !== null) {
      setDebtData((prevData) => {
        const updatedData = prevData.map((item, i) =>
          i === row.index
            ? {
                ...item,
                [field]: value || "",
              }
            : item
        );

        const totalOccurringCredit = updatedData.reduce((total, item) => {
          const occurringCreditValue = Number(item.occurringCredit) || 0;
          return total + occurringCreditValue;
        }, 0);

        setOccurringCredits(totalOccurringCredit);

        return updatedData;
      });
    } else {
      setDebtData((prevData) => {
        const updatedData = prevData.map((item) => ({
          ...item,
          [field]: value || "",
        }));
        return updatedData;
      });
    }
  };

  //Chọn mã phí
  const toggleSelectItem = (itemValue: SelectListItemBase) => {
    setSelectedItems((prev) => {
      const updatedSelected = prev.includes(itemValue.value)
        ? prev.filter((value) => value !== itemValue.value)
        : [...prev, itemValue.value];
      setDebtData((prevDebtData: any) => {
        // Lọc ra những mục cần giữ lại
        const filteredDebtData = prevDebtData.filter((data: DebtData) =>
          updatedSelected.includes(data.codeForFees ?? "")
        );
        // Tạo các mục mới nếu cần thiết
        const newDebtItems = updatedSelected
          .filter(
            (value) =>
              !filteredDebtData.find((data: any) => data.codeForFees === value)
          )
          .map((codeForFees, index) => ({
            id: codeForFees, // dùng mã để đảm bảo key duy nhất, tránh trùng
            code:
              dataCodeFund?.find((item: any) => item.value === codeForFees)
                ?.att1 ?? "",
            codeForFees:
              dataCodeFund?.find((item: any) => item.value === codeForFees)
                ?.text ?? "", // Mã phí
            occurringCredit: prevDebtData.find(
              (ele: any) => ele.id === codeForFees
            )?.occurringCredit, // phát sinh có
            debtObjectCode: groupData.groupCode, // Mã đối tượng công nợ
            debtObjectName: groupData.groupName, // Tên đối tượng công nợ
            descriptionFund: prevDebtData.find(
              (ele: any) => ele.id === codeForFees
            )?.descriptionFund, // Nội dung
            description:
              dataCodeFund?.find(
                (item: any) => Number(item.value) === Number(codeForFees)
              )?.text ?? "",
            departmentFund: formData?.funddepartment, // Bộ phận
            fee: prevDebtData.find((ele: any) => ele.id === codeForFees)?.fee, // Phí
            contract: prevDebtData.find((ele: any) => ele.id === codeForFees)
              ?.contract, // Hợp đồng
          }));

        // Kết hợp dữ liệu hiện có và các mục mới
        return [...filteredDebtData, ...newDebtItems];
      });

      return updatedSelected;
    });
  };

  //Xóa mã phí
  const deleteRow = (id: any, rowCodeForFees?: any) => {
    setInputValues((prevValues) => {
      const updatedValues = { ...prevValues };
      delete updatedValues[rowCodeForFees];
      return updatedValues;
    });

    setDebtData((prevData) => {
      const updatedData = prevData.filter((item, index) => index !== id);

      const newTotalOccurringCredit = updatedData.reduce((total, item) => {
        const occurringCreditValue = Number(item.occurringCredit) || 0;
        return total + occurringCreditValue;
      }, 0);
      setOccurringCredits(newTotalOccurringCredit);

      return updatedData;
    });

    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((selected) => selected !== rowCodeForFees)
    );
  };

  const TableSelectCenterTransfer = ({ filteredData }: any) => (
    <Table.ScrollContainer minWidth={600} h={250} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th w={70}>Thao tác</Table.Th>
            <Table.Th>Mã phí</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {filteredData.length > 0 ? (
            filteredData.map((item: any, index: number) => (
              <Table.Tr key={index}>
                <Table.Td>
                  <Button
                    onClick={() => {
                      toggleSelectItem(item);
                      table.setCreatingRow(null);
                    }}
                    size="xs"
                    variant="filled"
                    disabled={selectedItems.includes(item.value)}
                  >
                    {selectedItems.includes(item.value) ? "Đã chọn" : "Chọn"}
                  </Button>
                </Table.Td>
                <Table.Td>
                  <Text size="sm">{item.text}</Text>
                </Table.Td>
              </Table.Tr>
            ))
          ) : (
            <Table.Tr>
              <Table.Td colSpan={2}>
                <Text fw={600} ta="center" size="sm">
                  Không có dữ liệu
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
  //#region  End codefund
  useEffect(() => {
    getTblDmExpense();
    fetchDataReceiptParent();
    fetchDataReceiptAcount();
    fetchDataBranch();
  }, []);

  useEffect(() => {
    setCurrentTime(new Date());
  }, []);

  const handleComplete = () => {
    setCompleteTime(new Date());
  };

  useEffect(() => {
    setGroupData({
      id: dataGetReceiptParent?.attribute2?.id || "",
      groupName: dataGetReceiptParent?.attribute2?.groupName || "",
      groupCode: dataGetReceiptParent?.attribute2?.groupCode || "",
      groupCreateByName: dataGetReceiptParent?.attribute2?.createByName || "",
    });
  }, [dataGetReceiptParent]);

  useEffect(() => {
    if (listTblBranch?.departmentId) {
      setDebtData((prevData) =>
        prevData.map((item) => ({
          ...item,
          departmentFund: listTblBranch?.departmentId?.toString() ?? "",
        }))
      );
    }
  }, [listTblBranch?.departmentId]);

  useEffect(() => {
    handleChangeCodeFund(groupData.groupName, null, "debtObjectName");
    handleChangeCodeFund(groupData.id, null, "debtObjectCode");
    setDebtData((prevData) =>
      prevData.map((item) => ({
        ...item,
        debtObjectName: groupData.groupName,
        debtObjectCode: groupData.id,
      }))
    );
  }, [groupData]);

  useEffect(() => {
    const _s = JSON.stringify(debtData);
    const parsedData = JSON.parse(_s);
  }, [formData]);

  useEffect(() => {
    if (fundepartmentId) {
      fetchDataEmployee();
      fetchDataDepartments();
    }
  }, [fundepartmentId]);

  useEffect(() => {
    if (!handlePayertype && fundepartmentId) {
      getSelectTblDepartment();
    } else {
      fetchDataGetAll();
    }
  }, [handlePayertype, fundepartmentId]);

  useEffect(() => {
    const defaultItem = dataExchangeRate.find(
      (item) => item.calculation === selectedCalculation
    );
    setSelectedValue(defaultItem?.value ?? 1);
  }, [selectedCalculation]);

  useEffect(() => {
    if (handleFullName) {
      fetchDataTblDmEmployee();
    }
  }, [handleFullName]);

  useEffect(() => {
    handleChange("fundworkplace", valueDataLocation);
  }, [valueDataLocation]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (185 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (dataGetReceiptParent?.attribute2?.groupCode) {
      setFormData((prev) => ({
        ...prev,
        attribute2: handelAttrible
          ? ""
          : dataGetReceiptParent?.attribute2?.groupCode ?? "",
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        attribute2:
          dataGetReceiptParent?.attribute2?.groupCode ?? ""
            ? ""
            : "Vui lòng chọn đối tượng công nợ!",
      }));
    }
  }, [dataGetReceiptParent?.attribute2?.groupCode]);

  useEffect(() => {
    if (listTblDmEmployee?.roleName) {
      setFormData((prev) => ({
        ...prev,
        attribute3: listTblDmEmployee?.roleName ?? "",
      }));

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        attribute3:
          listTblDmEmployee?.roleName ?? "" ? "" : "Vui lòng chọn chức danh!",
      }));
    }
  }, [listTblDmEmployee?.roleName]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      codefund: formData.codefund,
    }));
  }, [formData?.codefund]);

  useEffect(() => {
    if (listTblBranch?.departmentId) {
      setFormData((prev) => ({
        ...prev,
        funddepartment: listTblBranch?.departmentId?.toString() ?? "",
      }));

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        funddepartment: listTblBranch?.departmentId
          ? ""
          : "Vui lòng chọn phòng ban!",
      }));
    }
  }, [listTblBranch?.departmentId]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      createdDate: moment(new Date(), "DD-MM-YYYY HH:mm:ss")
        .add(7, "hours")
        .toISOString(),
    }));
  }, []);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      totalAmount: occurringCredits ?? "",
    }));
  }, [occurringCredits]);

  useEffect(() => {
    if (dataCodeFund.length > 0) {
      setFilteredData(dataCodeFund);
    }
  }, [dataCodeFund]);

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  const table = useMantineReactTable({
    columns,
    data: debtData,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Thao tác",
      },
    },
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    enableEditing: true,
    createDisplayMode: "row",
    editDisplayMode: "modal",
    enableToolbarInternalActions: false,
    enableRowActions: true,
    enableBatchRowSelection: true,
    positionActionsColumn: "last",
    enableExpandAll: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    renderRowActions: ({ row }) => (
      <Flex gap={10}>
        <Tooltip label="Xóa">
          <ActionIcon
            disabled={statusReponse}
            onClick={() =>
              deleteRow(
                row.index,
                dataCodeFund?.find(
                  (item: any) => item.text === row.original.codeForFees
                )?.value
              )
            }
            variant="light"
            color="rgba(255, 0, 0, 1)"
          >
            <IconTrash size={14} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <Group gap={30} align="center" justify="center">
          {/* <Switch
            style={{ cursor: "pointer" }}
            label={
              <Flex align="center" justify="center">
                <Text fw={600} size="md">
                  Mở rộng (Ctrl+M)
                </Text>
              </Flex>
            }
            size="lg"
            onLabel="Mở"
            offLabel="Tắt"
          /> */}

          <Button
            leftSection={<IconPlus size={14} />}
            disabled={!groupData.id || statusReponse}
            variant="outline"
            color="blue"
            size="xs"
            onClick={() => {
              table.setCreatingRow(true);
              setIsCreating(true);
            }}
          >
            Thêm mã phí
          </Button>
        </Group>
      );
    },
    mantineBottomToolbarProps: {
      style: {
        paddingBottom: "20px",
      },
    },
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index"],
        right: ["mrt-row-actions", "deviated"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.45,
        minHeight: height * 0.45,
        alignItems: "center",
      },
    },
    mantineTableProps: {
      striped: true,
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    localization: {
      noRecordsToDisplay: "Chưa có thông tin để hiển thị.",
    },

    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        width: "100%",
        fontWeight: "normal",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),

    enableColumnPinning: true,

    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  console.log("1212", formData.codefund);
  return (
    <>
      <Box>
        <Group justify="space-between" pl={20} pr={0} bg="#fff" ref={headerRef}>
          <header>
            <BreadcrumbLayout1 />
            <>
              <Button
                size="sm"
                w={100}
                color="blue"
                leftSection={<IconPlus size={14} />}
                variant="outline"
                onClick={() => {
                  navigate("/bill/payment-slip");
                  window.location.reload();
                }}
              >
                Tạo mới
              </Button>
            </>
          </header>
        </Group>
        <Divider my="md" mb={0} />
        <Box p={15} bg={"#f8f8f8"}>
          <Grid gutter="xs">
            <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="lg"
                pr={0}
                pt={15}
                style={{
                  boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
                  maxHeight: height + 55,
                  minHeight: height + 55,
                }}
              >
                <ScrollArea w="100%" pr={5} h={height + 600} offsetScrollbars>
                  <Grid>
                    <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Mã phiếu chi
                        </Text>
                        <Text fw={600}>{listdata?.receiptNumber || ""}</Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pb={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Thời gian tạo
                        </Text>
                        <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                          <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                            <>
                              {moment(currentTime).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </>
                          </Text>
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col
                      span={{ base: 12, md: 12, lg: 6 }}
                      offset={{ base: 0, md: 0, lg: 6 }}
                      pb={0}
                    >
                      <Flex>
                        <Text
                          style={{ flex: "0 0 150px", whiteSpace: "nowrap" }}
                        >
                          Thời gian hoàn thành
                        </Text>
                        <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                          {handelDateCurrent && statusReponse ? (
                            <>
                              {moment(completeTime).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )}
                            </>
                          ) : null}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Người lập phiếu chi
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Chi nhánh
                        </Text>
                        <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                          {dataBranch.find(
                            (item) =>
                              String(item.value) ===
                              String(listdata?.creatorbranch)
                          )?.text ?? ""}
                        </Text>
                      </Flex>
                    </Grid.Col>

                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Họ và tên
                        </Text>
                        <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                          {`${userData?.fullName ?? ""}`}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <GridCol
                      pt={0}
                      pb={0}
                      span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
                    >
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          ĐĐ làm việc
                        </Text>
                        <Text fw="600">{branchDeparment?.address ?? ""}</Text>
                      </Flex>
                    </GridCol>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Chức danh
                        </Text>
                        <Text fw="600">{userData?.roleName}</Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
                      <Flex>
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          Phòng ban
                        </Text>
                        <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                          {branchAll?.find(
                            (item) =>
                              String(item.departmentId) ===
                              String(listdata?.creatordepartment)
                          )?.name ?? ""}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Người nhận tiền
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Grid.Col pt={0} pb={0} span={12}>
                      <EditableTextWithOptions
                        readOnly={statusReponse ? true : false}
                        required
                        label="Loại"
                        value={formData.payertype}
                        options={["Nội bộ", "Khách hàng", "NCC", "Khác"]}
                        onChange={(value) => {
                          setDebtData([]);
                          setSelectedItems([]);
                          setInputValues([]);
                          setDataGetReceiptParent([]);
                          table.setCreatingRow(null);
                          setOccurringCredits(0);
                          handleChange("payertype", value);
                          handleChange("funfullname", null);
                          handleChange("funddepartment", null);
                          handleChange("attribute2", null);
                          handleChange("attribute3", null);
                          handleChange("fundbranch", null);
                          handleChange("fundworkplace", null);
                          handleChange("codeCustomer", null);
                          handleChange("anotherName", "");
                          handleChange("phoneNumber", "");
                          setFormErrors((prevErrors) => ({
                            ...prevErrors,
                            anotherName: "",
                            attribute2: "",
                            attribute3: "",
                            fundbranch: "",
                            fundworkplace: "",
                            funfullname: "",
                            funddepartment: "",
                            phoneNumber: "",
                            codeCustomer: "",
                          }));
                        }}
                        width="100px"
                      />
                    </Grid.Col>
                    <Grid.Col pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Nội bộ
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    {formInternal()}
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Khác
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    {formOther()}
                    <Grid.Col pt={0} pb={0} span={12}>
                      <Divider
                        my="xs"
                        label={
                          <Text size="lg" fw={600} c="#000">
                            Đối tượng công nợ
                          </Text>
                        }
                        labelPosition="left"
                      />
                    </Grid.Col>
                    <Grid.Col
                      pt={0}
                      span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6, lx: 6 }}
                    >
                      <Flex align="center">
                        <Text
                          style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}
                        >
                          ĐTCN
                          <span style={{ color: "red" }}>*</span>
                        </Text>
                        <TextInput
                          placeholder="Chọn mã đối tượng công nợ"
                          flex={1}
                          disabled={statusReponse}
                          error={
                            (!handlePayertype ||
                              !handleOther ||
                              (formData.payertype !== "NCC" &&
                                formData.payertype !== "Khách hàng")) &&
                            formErrors.attribute2
                              ? formErrors.attribute2
                              : undefined
                          }
                          value={
                            (groupData.id &&
                              `${groupData.groupCode ?? ""} - ${
                                groupData.groupName ?? ""
                              }`) ??
                            ""
                          }
                          w={185}
                          rightSection={
                            statusReponse ? null : inputValueDept ? (
                              <IconX
                                onClick={() => {
                                  setInputValueDept("");
                                  setHandelAttrible(true);
                                }}
                                size={16}
                              />
                            ) : null
                          }
                          onClick={() => openFormDebt("attribute2")}
                        />
                      </Flex>
                    </Grid.Col>
                  </Grid>
                </ScrollArea>
              </Card>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 7 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="md"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
                  maxHeight: height + 55,
                  minHeight: height + 55,
                }}
              >
                <Box>
                  <Flex align="center">
                    <Text
                      style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}
                      mb={15}
                      fw={600}
                    >
                      Tỷ giá <span style={{ color: "red" }}>*</span>
                    </Text>
                    <Table layout="fixed" withTableBorder withColumnBorders>
                      <Table.Tbody>
                        <Table.Tr>
                          <Table.Td align="left">
                            <Text fw={600}>Đơn vị tính</Text>
                          </Table.Td>
                          <Table.Td align="center">
                            <Text fw={600}>Tỉ giá</Text>
                          </Table.Td>
                          <Table.Td align="center">
                            <Text fw={600}>Thành tiền</Text>
                          </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>
                            <EditableTextWithOptions
                              label=""
                              isCod={true}
                              readOnly={statusReponse ? true : false}
                              value={
                                dataExchangeRate?.find(
                                  (item: any) =>
                                    item.id === formData.accountfund
                                )?.calculation || "VND"
                              }
                              options={
                                dataExchangeRate
                                  ?.map((e: any) => e.calculation)
                                  .filter(
                                    (calculation): calculation is string =>
                                      !!calculation
                                  ) ?? []
                              }
                              onChange={(selectedDescription) => {
                                const selectedOption = dataExchangeRate?.find(
                                  (item: any) =>
                                    item.calculation === selectedDescription
                                );

                                const handelDataAcount = dataAcount.find(
                                  (ele) =>
                                    String(ele.accountantAccount) ===
                                    String(selectedOption?.id)
                                );
                                if (selectedOption) {
                                  handleChange(
                                    "accountfund",
                                    selectedOption.id
                                  );

                                  handleChange(
                                    "codefund",
                                    handelDataAcount?.accountantAccount
                                  );
                                  handleChange(
                                    "attribute6",
                                    handelDataAcount?.accountantAccountName ??
                                      ""
                                  );

                                  handleChangeRate(selectedOption.calculation);
                                }
                              }}
                            />
                          </Table.Td>
                          <Table.Td align="right">
                            <NumberFormatter
                              style={{ fontWeight: "bold" }}
                              value={selectedValue}
                              thousandSeparator
                            ></NumberFormatter>
                          </Table.Td>
                          <Table.Td align="right">
                            <NumberFormatter
                              value={
                                (occurringCredits ?? 0) * (selectedValue ?? 0)
                              }
                              style={{
                                fontSize: "15px",
                                fontWeight: 600,
                                color: "red",
                              }}
                              thousandSeparator
                            />
                          </Table.Td>
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                  </Flex>
                  <Flex mt={20} align="center">
                    <Text
                      style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}
                      fw={600}
                    >
                      Tài khoản có <span style={{ color: "red" }}>*</span>
                    </Text>
                    <Table layout="fixed" withTableBorder withColumnBorders>
                      <Table.Tbody>
                        <Table.Tr>
                          <Table.Td align="right">
                            <NumberFormatter
                              style={{ fontWeight: "bold" }}
                              value={
                                dataAcount.find(
                                  (ele) =>
                                    String(ele.accountantAccount) ===
                                    String(formData.codefund)
                                )?.accountantAccount
                              }
                            ></NumberFormatter>
                          </Table.Td>
                          <Table.Td align="left">
                            <Text fw={600}>
                              {
                                dataAcount.find(
                                  (ele) =>
                                    String(ele.accountantAccount) ===
                                    String(formData.codefund)
                                )?.accountantAccountName
                              }
                            </Text>
                          </Table.Td>
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                  </Flex>
                  <Group mt={20}>
                    <MantineReactTable table={table} />
                  </Group>
                  <Group justify="space-between" mt={20}>
                    <Flex w="60%" align="center" gap={20}>
                      <Text
                        style={{ flex: "0 0 80px", whiteSpace: "nowrap" }}
                        fw={600}
                        c="red"
                      >
                        Tổng tiền:
                      </Text>
                      <Text fw={700} c="red">
                        <NumberFormatter
                          thousandSeparator=","
                          value={occurringCredits}
                        />
                      </Text>
                    </Flex>
                    <Button
                      variant="filled"
                      disabled={statusReponse}
                      onClick={() => {
                        fetchCreateReceiptParent(true);
                        setHandelDateCurrent(true);
                        handleComplete();
                      }}
                      leftSection={<IconCheck size={14} />}
                      color="#36a94b"
                    >
                      Hoàn thành
                    </Button>
                  </Group>
                </Box>
              </Card>
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PaymentSlip;
