import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  TextInput,
  Textarea,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMReasonReturn } from "../../../../model/TblDMReasonReturn";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { useEffect } from "react";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";

const CreateDataView = ({ onClose }: CreateDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    description: null,
    note: "",
    active: false,
    createBy: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMReasonReturn>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      description: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên!";
        }
        return hasLength(
          { max: 150 },
          "Tên phải nhỏ hơn 150 kí tự!"
        )(value as string);
      },
      code: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập mã!";
        }
        return hasLength(
          { max: 15 },
          "Mã phải nhỏ hơn 15 kí tự!"
        )(value as string);
      },
      note: (value?: string | null) => {
        if (value)
          return hasLength(
            { max: 250 },
            "Ghi chú phải nhỏ hơn 250 kí tự!"
          )(value as string);
      },
    },
  });

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<MessageResponse<TblDMReasonReturn>>(
      "/api/v1/TblDMReasonReturn/create"
    );
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Bạn không có quyền tạo!!!");
      modals.closeAll();
    }
  };

  const handleCreateTblDMReasonReturn = async (
    dataSubmit: TblDMReasonReturn
  ) => {
    open();
    const dataApi = await repositoryMdm.post<
      MessageResponse<TblDMReasonReturn>
    >("/api/v1/TblDmReasonReturn/create", dataSubmit);
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  useEffect(() => {
    callApiGetData();
  }, []);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        onSubmit={form.onSubmit((e: TblDMReasonReturn) => {
          handleCreateTblDMReasonReturn(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={6}>
            <TextInput
              label={"Mã lý do trả hàng"}
              placeholder={"Nhập mã lý do trả hàng"}
              withAsterisk
              onKeyDown={handleKeyDown}
              {...form.getInputProps("code")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label={"Tên lý do trả hàng"}
              placeholder={"Nhập tên lý do trả hàng"}
              withAsterisk
              w={"100%"}
              {...form.getInputProps("description")}
            />
          </Grid.Col>
        </Grid>

        <Grid align="center">
          <Grid.Col span={12}>
            <Textarea
              label={"Ghi chú"}
              placeholder="Nhập ghi chú"
              w={"100%"}
              {...form.getInputProps("note")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox label={"Sử dụng"} {...form.getInputProps("active")} />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  onClose: any;
};
