import {
  Box,
  Group,
  LoadingOverlay,
  Paper,
  Select,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";
import {
  repositoryPayment,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { customerCheck } from "../Model/modelRetailOrder";
import TCB999 from "../../../../assets/images/TCB-999-QR.png";
import VCB930 from "../../../../assets/images/VCB-930-QR.png";
import MBB973 from "../../../../assets/images/MBB-973-QR.png";
import VCB762 from "../../../../assets/images/VCB-762-QR.png";
import MBB666 from "../../../../assets/images/MBB-666-QR.png";
import VCB813 from "../../../../assets/images/VCB-813-QR.png";

export const PaymentMethodMb = ({
  price,
  productCode,
  setPaymentDone,
  paymentDone,
  customerCheck,
  orderNumber,
  setSelectedBankReserve,
  setTransactionCodeReserve,
}: {
  setPaymentDone: any;
  paymentDone: any;
  price: number;
  productCode: string;
  orderNumber: string;
  customerCheck: customerCheck;
  setSelectedBankReserve: any;
  setTransactionCodeReserve: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQrCode] = useState<string | null>(null); // QR động
  const [genQrError, setGenQrError] = useState(false); // QR động
  const [transactionCode, setTransactionCode] = useState<string | null>(null); // Mã giao dịch

  const [qrCodeBackup, setQrCodeBackup] = useState<string | null>(null); // QR dự phòng
  const [selectedBankImage, setSelectedBankImage] = useState<string | null>(
    null
  );
  const [selectedBankReserveChildren, setSelectedBankReserveChildren] =
    useState("");

  // Giả lập danh sách ngân hàng
  const bankOptions = [
    { value: "TCB-999", label: "TCB-999", imageQr: TCB999 },
    { value: "VCB-930", label: "VCB-930", imageQr: VCB930 },
    { value: "MBB-973", label: "MBB-973", imageQr: MBB973 },
    { value: "VCB-762", label: "VCB-762", imageQr: VCB762 },
    { value: "MBB-666", label: "MBB-666", imageQr: MBB666 },
    { value: "VCB-813", label: "VCB-813", imageQr: VCB813 },
  ];

  const handleCreateQrCode = async () => {
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/Public/ip`
      );
      const ipAddress = response?.data || "";
      const callapi = await repositoryPayment.post<MessageResponse<any>>(
        `/api/TblMBQRcode/gen-qr-mb-v2`,
        {
          amount: price,
          ipAddress: ipAddress, // Gán ipAddress vừa lấy được
          orderInfo: orderNumber, // Gán orderInfo đã xác định
          orderNumber: orderNumber,
        }
      );

      if (callapi?.success) {
        setQrCode(callapi?.data?.qr_url);
      } else {
        setGenQrError(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Tự động tạo QR động khi tab được mount
  useEffect(() => {
    handleCreateQrCode();
  }, []);

  return (
    <Box component="form" mx="auto" style={{ position: "relative" }} mih={480}>
      <LoadingOverlay visible={isLoading} zIndex={1000} />

      <Tabs defaultValue="dynamicQR">
        <Tabs.List
          grow
          style={{
            borderBottom: "1px solid #e0e0e0", // Tùy chỉnh đường viền dưới
            display: "flex",
            justifyContent: "space-evenly", // Đảm bảo các tab được chia đều
          }}
        >
          <Tabs.Tab
            value="dynamicQR"
            style={{
              fontSize: "16px", // Tăng kích thước chữ
              fontWeight: "bold",
              textAlign: "center",
              padding: "10px 0", // Thêm khoảng cách dọc
            }}
          >
            QR động
          </Tabs.Tab>
          <Tabs.Tab
            value="backupQR"
            style={{
              fontSize: "16px", // Tăng kích thước chữ
              fontWeight: "bold",
              textAlign: "center",
              padding: "10px 0", // Thêm khoảng cách dọc
            }}
          >
            QR tĩnh
          </Tabs.Tab>
        </Tabs.List>

        {/* Tab 1: QR động */}
        <Tabs.Panel value="dynamicQR" pt="xs" mt={46}>
          {genQrError ? (
            <>
              <Text style={{ alignItems: "center" }}>
                Tạo QR code lỗi , vui lòng đóng form và thử lại !
              </Text>
            </>
          ) : qrCode ? (
            <Group
              align="center"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Paper
                withBorder
                shadow="md"
                p="lg"
                radius="md"
                style={{
                  backgroundColor: "white",
                  width: "90%",
                  textAlign: "center",
                }}
              >
                <Text mb={5}>Quý khách vui lòng quét mã để thanh toán</Text>
                <Text fw={"500"}>
                  {`Số tiền thanh toán: `}
                  <Text component="span" color="red" fw={"600"}>
                    {price.toLocaleString("vi-VN")} VND
                  </Text>
                </Text>
                <Text fw={"500"}>{`Nội dung: ${orderNumber}`}</Text>
                <Text fw={"500"} mb={15}>
                  Ngân hàng: MB Bank
                </Text>
                <img src={qrCode} alt="QR Code" width={300} height={300} />
              </Paper>
            </Group>
          ) : (
            <Text style={{ alignItems: "center" }}>Đang tải mã QR...</Text>
          )}
        </Tabs.Panel>

        {/* Tab 2: QR dự phòng */}
        <Tabs.Panel value="backupQR" pt="xs">
          <Group
            align="center"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              gap: "10px", // Khoảng cách giữa các ô
            }}
          >
            <Select
              size="sm"
              placeholder="Chọn ngân hàng"
              data={bankOptions}
              value={selectedBankReserveChildren}
              onChange={(bank) => {
                setSelectedBankReserve(bank);
                setSelectedBankReserveChildren(bank || "");
                const selectedBankDetails = bankOptions.find(
                  (b) => b.value === bank
                );
                setSelectedBankImage(selectedBankDetails?.imageQr || null);
              }}
              style={{ width: "200px" }} // Đặt kích thước cho ô chọn
            />
            <TextInput
              placeholder="Nhập mã giao dịch"
              size="sm"
              value={transactionCode || ""}
              onChange={(e) => {
                setTransactionCode(e.target.value);
                setTransactionCodeReserve(e.target.value);
              }}
              style={{ width: "200px" }} // Đặt kích thước cho ô nhập
            />
          </Group>

          {selectedBankImage && (
            <Group
              align="center"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Paper
                withBorder
                shadow="md"
                p="lg"
                radius="md"
                style={{
                  backgroundColor: "white",
                  width: "90%",
                  textAlign: "center",
                }}
              >
                <Text mb={5}>Quý khách vui lòng quét mã để thanh toán</Text>
                <Text fw={"500"}>
                  {`Số tiền thanh toán: `}
                  <Text component="span" color="red" fw={"600"}>
                    {price.toLocaleString("vi-VN")} VND
                  </Text>
                </Text>
                <Text fw={"500"}>{`Nội dung:${orderNumber}`}</Text>
                <img
                  src={selectedBankImage}
                  alt="QR Code Backup"
                  style={{ width: 300, height: 350 }}
                />
              </Paper>
            </Group>
          )}
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};
