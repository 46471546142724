import {
  Box,
  Button,
  Flex,
  Grid,
  GridCol,
  Group,
  NumberFormatter,
  NumberInput,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";

export const ExchangeRate = () => {
  const [dataGetReceiptParent, setDataGetReceiptParent] = useState<any>();
  const [height, setHeight] = useState(0);
  const headerRef = React.useRef<HTMLDivElement>(null);

  const formatReceiptNumber = (receiptNumber: string): string => {
    const prefix = receiptNumber.slice(0, 2); // "PT"
    const year = receiptNumber.slice(2, 4); // "24"
    const month = receiptNumber.slice(5, 7); // "08"
    const day = receiptNumber.slice(8, 10); // "15"
    const suffix = receiptNumber.slice(11); // "002749"

    // Rearranging to PT + day + month + year + suffix
    return `${prefix}${day}.${month}.${year}.${suffix}`;
  };

  const fetchDataReceiptParent = async () => {
    const response = await repositoryPos.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/CreateSellItem/create-receipt-parent");

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptParent(result);
    }
  };

  useEffect(() => {
    fetchDataReceiptParent();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (300 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  return (
    <>
      <_breadcrumb></_breadcrumb>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Flex w="100%" bg="#ffffff" align="center" justify="center">
          <Grid
            w="40%"
            gutter="lg"
            styles={() => ({
              inner: {
                height: "100%",
              },
            })}
            h={height + 170}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GridCol h="100%" span={{ base: 12, md: 12, lg: 12 }}>
              <Box
                style={{
                  borderRadius: "10px",
                  boxShadow: "0 1px 3px #ccc",
                }}
                bg="#fbfbfb"
                w="100%"
                h="100%"
                p={20}
              >
                <Flex h="100%" justify="space-between" direction="column">
                  <Box>
                    <Flex align="center" gap="xs">
                      <Text
                        ml={10}
                        style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}
                      >
                        Thời gian:
                      </Text>
                      <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                        {moment(new Date()).format("DD-MM-YYYY HH:mm:ss")}
                      </Text>
                    </Flex>
                    <Box mt={20}>
                      <Table verticalSpacing="sm">
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Loại vàng</Table.Th>
                            <Table.Th>Tỉ giá</Table.Th>
                          </Table.Tr>
                          <Table.Tr>
                            <Table.Td w="50%">Vàng SJC 9999</Table.Td>
                            <Table.Td>
                              <NumberInput
                                style={() => ({
                                  inner: {
                                    with: "20px",
                                  },
                                })}
                                allowNegative={false}
                                thousandSeparator=","
                                hideControls
                                value={78000000}
                                // onChange={(value) => handleChange("totalAmount", value)}
                              />
                            </Table.Td>
                          </Table.Tr>
                          <Table.Tr>
                            <Table.Td>Vàng PNJ 9999</Table.Td>
                            <Table.Td>
                              <NumberInput
                                style={() => ({
                                  inner: {
                                    with: "20px",
                                  },
                                })}
                                allowNegative={false}
                                thousandSeparator=","
                                hideControls
                                value={76880000}
                                // onChange={(value) => handleChange("totalAmount", value)}
                              />
                            </Table.Td>
                          </Table.Tr>
                        </Table.Thead>
                      </Table>
                    </Box>
                  </Box>
                  <Group justify="flex-end">
                    <Button variant="filled" size="sm">
                      Hoàn thành
                    </Button>
                    <Tooltip label="Chức năng đang cập nhật">
                      <Button variant="outline" size="sm">
                        Lưu
                      </Button>
                    </Tooltip>
                  </Group>
                </Flex>
              </Box>
            </GridCol>
          </Grid>
        </Flex>
      </Box>
    </>
  );
};
