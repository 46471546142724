import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconDownload,
  IconEdit,
  IconEye,
  IconPlus,
  IconSearch,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { modals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import DeleteView from "./DeleteView";

const MfgBomView = () => {
  const navigate = useNavigate();
  //#region  table

  //data and fetching state
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);
  //
  const [search, setSearch] = useState({
    invId: "",
    invOutwardId: "",
    customerId: "",
    startDate: "",
    endDate: "",
    orderStatus: "",
    orderType: "",
    orderNumber: "",
    paymentStatus: "",
    industry: "",
    keySearch: "",
  });
  useEffect(() => {
    //  getUser();
  }, []);
  const [selectIds, setSelectIds] = useState<string[]>([]);

  const sizeGrid = { base: 6, sm: 4, md: 3, lg: 3, xl: 2 };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
        pagination.pageSize
      }`;

      if (search.keySearch) {
        url += `&KeySearch=${search.keySearch}`;
      }

      let _softing = "";
      let isOrder = false;
      if (sorting && sorting.length > 0) {
        let _q = sorting[0];
        if (_q) {
          _softing = _q.id;
          isOrder = _q.desc;
        }
        url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
      }

      const response = await repositoryMdm.get(
        `/api/v1/TblMfgBomHeader/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        // Promise.all([dataLocation()]);
        setRowCount(response.totalCount);
        setSelectIds([]);
        //   table.resetRowSelection();
      } else setData([]);
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  //console.log(data);
  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm" fw={700}>
            {row.original.itemCode}
          </Text>
        ),
      },
      {
        accessorKey: "iteamNameHeader",
        header: "Tên sản phẩm",
        size: 30,
        Cell: ({ row }) => (
          <Tooltip label={row.original.iteamNameHeader}>
            <Text size="sm" w={250}>
              {row.original.iteamNameHeader}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "uomName",
        header: "Đơn vị tính",
        size: 50,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 30,
        Cell: ({ row }) => (
          <Tooltip label={row.original.note}>
            <Text size="sm" w={150}>
              {row.original.note}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "bomDate",
        header: "Ngày lập",
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <>{formatDateTransfer(undefined)}</>;
        },
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editData(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailData(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deleteData([row.original.id]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const editData = (id: number) => {
    navigate("/config-system/edit-config-bom", { state: { id: id } });
  };

  const detailData = (id: number) => {
    navigate("/config-system/detail-config-bom", { state: { id: id } });
  };

  const deleteData = async (ids: (string | number)[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa định mức nguyên vật liệu!</Title>
            </>
          ),
          centered: true,
          size: "550px",
          children: <DeleteView idItems={ids} fetchData={fetchData} />,
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => <></>,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    // enableExpanding: true,
    // getSubRows: (originalRow) => originalRow.orderDetail,

    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "650", //conditional styling
        fontSize: "12px",
        padding: "3px 10px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (160 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  //#endregion
  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex gap={"sm"}>
            <Button
              leftSection={<IconPlus size={14} />}
              variant="outline"
              onClick={() => {
                navigate("/config-system/create-config-bom");
              }}
            >
              Thêm mới (F11)
            </Button>

            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                await deleteData(selectIds);
              }}
              disabled={selectIds.length < 1}
            >
              Xóa (đã chọn)
            </Button>

            <Button
              leftSection={<IconDownload size={14} />}
              color="violet"
              variant="outline"
            >
              Export Excel
            </Button>
          </Flex>
        </Flex>

        <Grid mt={"10px"}>
          <Grid.Col span={sizeGrid}>
            <Input
              flex={1}
              placeholder="Từ khóa"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              min={0}
              onChange={(e) => {
                handleChangeSearchValue(
                  e.target.value.trim() ?? "",
                  "keySearch"
                );
              }}
            />
          </Grid.Col>

          <Grid.Col span={sizeGrid}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default MfgBomView;
