import {
  Badge,
  Box,
  Button,
  Divider,
  Fieldset,
  Grid,
  Group,
  ScrollArea,
  Table,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowBack, IconCheck } from "@tabler/icons-react";
import cx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { getValueById } from "../../_base/helper/FunctionHelper";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { sky_blue } from "../../const/variables";
import { ComboboxItem } from "../../model/ComboboxItem";
import { MessageResponse } from "../../model/MessageResponse";
import { getTechnicalTestingDifficultySelect } from "../../service/getSelectApi";
import classes from "../../Styles/TableScrollArea.module.css";
import ListProductDetail from "./ListProductDetail";
export interface UpdateStatusDetails {
  headerId: number;
  testDifficulty: string | null;
  updateStatusTblTestingTechniqueHeaderDetailCommandModels: UpdateStatusTblTestingTechniqueHeaderDetailCommandModel[];
}

interface UpdateStatusTblTestingTechniqueHeaderDetailCommandModel {
  id: number;
  testDifficulty: string | null;
  testingStatus: string | null;
  noteByEmployee: string | null;
}

const ModalUpdateTestingTechnique = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location?.state && location.state.id;
  const dataStatusOption = location?.state && location.state.dataStatusOption;
  const dataSourceStatus = location?.state && location.state.dataSourceStatus;

  const entity = {
    testingTechniqueHeaderId: Number(id),
    processingDate: null,
    priorityId: null,
    difficultyLevelId: null,
    note: null,
    responsibleEmployeeId: null,
    assignToTblTestingTechniqueHeaderCommandModels: null,
  };

  const entitySubmit = {
    headerId: 0,
    testDifficulty: null,
    updateStatusTblTestingTechniqueHeaderDetailCommandModels: [],
  };
  const form = useForm<any>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      priorityId: Number(values.priorityId),
      difficultyLevelId: Number(values.difficultyLevelId),
    }),
  });

  const submitFrom = useForm<UpdateStatusDetails>({
    mode: "uncontrolled",
    initialValues: {
      ...entitySubmit,
    },
  });
  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [scrolled, setScrolled] = useState(false);

  const [searchEmployee, setSearchEmployee] = useDebouncedState<string>(
    "",
    300
  );
  const [dataEmployeeOption, setDataEmployeeOption] = useState<any[]>([]);

  const [dataDetail, setDataDetail] = useState<any>({});
  const [
    dataTechnicalTestingDifficultySelect,
    setDataTechnicalTestingDifficultySelect,
  ] = useState<ComboboxItem[]>([]);
  const initialScreenHeightRef = useRef<number>(window.innerHeight);
  const boxTopRef = useRef<number>(0);
  const [bottomBoxHeight, setBottomBoxHeight] = useState(0);
  const [datatestDifficultys, setdatatestDifficultys] = useState<any>();
  const getDetailProduct = async () => {
    const res = await repositoryDelivery.get<MessageResponse<any>>(
      `/api/v1/TblTestingTechniqueHeader/get-detail?id=${id}`
    );

    if (res && res?.success) {
      const dataApi = res.data;
      setDataDetail(dataApi);
      setdatatestDifficultys(dataApi?.testDifficulty);
      form.setValues(dataApi);
      submitFrom.setValues((prev) => ({
        headerId: dataApi?.id,
        testDifficulty: dataApi?.testDifficulty ?? "DE",
        updateStatusTblTestingTechniqueHeaderDetailCommandModels:
          dataApi?.tblTestingTechniqueDetailModels?.map((item: any) => ({
            id: item?.id,
            testDifficulty: item?.testDifficulty,
            testingStatus: item?.testingStatus,
            noteByEmployee: item?.noteByEmployee,
          })),
      }));
    }
  };
  // console.log("form", form.getValues());
  const handleAssignEmployee = async (dataSubmit: any) => {
    if (dataSubmit.testDifficulty == null) {
      return NotificationExtension.Fails("Vui lòng chọn mức độ test của phiếu");
    }
    const url = `/api/v1/TblTestingTechniqueHeader/update-status-detail`;
    open();
    try {
      const response = await repositoryDelivery.post<
        MessageResponse<UpdateStatusDetails>
      >(url, dataSubmit);
      if (response?.success) {
        NotificationExtension.Success("Cập nhật kiểm tra thành công");
        modals.closeAll();
        setTimeout(() => {
          navigate("/testing/testing-technique");
        }, 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    close();
  };

  const getDataTechnicalTestingDifficultySelect = async () => {
    const res = await getTechnicalTestingDifficultySelect();
    setDataTechnicalTestingDifficultySelect(
      res
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataEmployee = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?Take=100&KeySearch=${searchEmployee}`
    );

    if (res && res?.success) {
      const result = res.data;
      setDataEmployeeOption(
        result?.filter((item: any) =>
          item.roleName.toLowerCase().includes("kỹ thuật")
        )
      );
    } else {
      setDataEmployeeOption([]);
    }
  };
  const ViewtechnicalRequired = (value: number) => {
    let result = "";
    switch (value) {
      case 0:
        result = "Không";
        break;
      case 1:
        result = "Lắp máy(mới)";
        break;
      case 2:
        result = "Kiểm tra(mới)";
        break;
      case 3:
        result = "Lắp ráp(mới)+kiểm tra(mới)";
        break;
      default:
        result = "Không yêu cầu";
        break;
    }
    return result;
  };
  useEffect(() => {
    Promise.all([
      // getDataDeliveryPriotitySelect(),
      getDataTechnicalTestingDifficultySelect(),
    ]);
  }, []);

  useEffect(() => {
    if (searchEmployee && searchEmployee.length > 2) {
      getDataEmployee();
    } else {
      setDataEmployeeOption([]);
    }
  }, [searchEmployee]);

  useEffect(() => {
    if (id) getDetailProduct();
  }, [id]);
  useEffect(() => {
    const updateHeight = () => {
      if (boxTopRef.current) {
        const boxTopHeight = boxTopRef.current;
        const totalHeight = window.innerHeight;
        const calculatedBottomHeight = totalHeight - boxTopHeight;
        setBottomBoxHeight(calculatedBottomHeight);
      }
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  return (
    <Box
      component="form"
      mx="auto"
      onSubmit={submitFrom.onSubmit((e) => {
        handleAssignEmployee(e);
      })}
    >
      <Box
        ref={(el) => {
          if (el) boxTopRef.current = el.offsetHeight;
        }}
      >
        <Group
          justify="end"
          mt="md"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="red"
            loading={visible}
            onClick={() => {
              navigate("/testing/testing-technique");
            }}
            leftSection={!visible ? <IconArrowBack size={18} /> : undefined}
          >
            Quay lại
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Cập nhật
          </Button>
        </Group>

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 12, lg: 5 }} mt={0} pt={0} mb={10}>
            <Fieldset legend={<Badge>Thông tin phân công</Badge>}>
              <Grid mt={"10px"}>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }} mb={0}>
                  <TextInput
                    readOnly
                    label="Mã kiểm tra"
                    value={form.getValues()?.code}
                  ></TextInput>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <TextInput
                    readOnly
                    label="Ngày phân công"
                    value={formatDateTransfer(form.getValues()?.processingDate)}
                  ></TextInput>
                </Grid.Col>
                {/* <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
              <TextInput
                readOnly
                label="Người phân công"
                value={form.getValues()?.assignerName}
              ></TextInput>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
              <TextInput
                readOnly
                label="Người nhận phân công"
                type="text"
                value={
                  form.getValues()?.responsibleEmployeeName?.toString() ?? null
                }
                {...form.getInputProps("responsibleEmployeeName")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
              <TextInput
                readOnly
                label="Người hỗ trợ"
                type="text"
                value={
                  form
                    ?.getValues()
                    ?.tblTestingTechniqueAssignmentModels?.find(
                      (e: any) =>
                        e?.emloyeeId?.toString() !==
                        form?.getValues()?.responsibleEmployeeId?.toString()
                    )
                    ?.emloyeeName?.toString() ?? null
                }
              />
            </Grid.Col> */}
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <TextInput
                    readOnly
                    label="Yêu cầu dịch vụ"
                    // value={ViewtechnicalRequired(
                    //   Number(form.getValues().technicalRequired)
                    // )}
                    value={
                      location?.state.typeSourceType == "DON_HANG"
                        ? ViewtechnicalRequired(
                            Number(form.getValues().technicalRequired)
                          )
                        : "Kiểm tra sản phẩm BH NCC"
                    }
                  ></TextInput>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <TextInput
                    readOnly
                    label="Nội dung phân công"
                    value={form.getValues().note}
                  ></TextInput>
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 12, lg: 7 }} mt={0} pt={0} mb={10}>
            <Fieldset
              legend={<Badge color="orange">Thông tin kỹ thuật</Badge>}
              h="calc(100% - 14px)"
            >
              <ScrollArea
                h={150}
                onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                scrollbars="y"
                mt={7.5}
              >
                <Table
                  striped
                  highlightOnHover
                  withTableBorder
                  withColumnBorders
                >
                  <Table.Thead
                    className={cx(classes.header, {
                      [classes.scrolled]: scrolled,
                    })}
                  >
                    <Table.Tr>
                      {/* <Table.Th>Người chịu trách nhiệm</Table.Th> */}
                      <Table.Th>Mã nhân viên</Table.Th>
                      <Table.Th>Tên nhân viên</Table.Th>
                      <Table.Th>Chức danh</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {dataDetail?.tblTestingTechniqueAssignmentModels &&
                      dataDetail?.tblTestingTechniqueAssignmentModels.length >
                        0 &&
                      dataDetail?.tblTestingTechniqueAssignmentModels.map(
                        (emp: any, index: any) => (
                          <Table.Tr key={emp.id}>
                            {/* <Table.Td>
                                <Checkbox
                                  checked={
                                    dataTestingTechnique?.responsibleEmployeeId ===
                                    emp?.emloyeeId
                                  }
                                  readOnly
                                />
                              </Table.Td> */}
                            <Table.Td>{emp.emloyeeCode}</Table.Td>
                            <Table.Td>{emp.emloyeeName}</Table.Td>
                            <Table.Td>{emp.emloyeeRole}</Table.Td>
                          </Table.Tr>
                        )
                      )}
                  </Table.Tbody>
                </Table>
              </ScrollArea>
            </Fieldset>
          </Grid.Col>
        </Grid>
        <Divider
          label={
            <Badge color="green" size="md">
              {location?.state.typeSourceType == "DON_HANG"
                ? " Thông tin chi tiết đơn hàng"
                : "Thông tin nhập BH NCC"}
            </Badge>
          }
          labelPosition="center"
        />
        <Grid mt={10} mb={10}>
          {location?.state.typeSourceType == "DON_HANG" ? (
            <>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Mã đơn hàng"
                  size="xs"
                  readOnly
                  value={dataDetail?.sourceCode || ""}
                />
              </Grid.Col>

              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Chi nhánh"
                  size="xs"
                  readOnly
                  value={dataDetail?.branchName || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Tên khách hàng"
                  size="xs"
                  readOnly
                  value={dataDetail?.customerName || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Số điện thoại"
                  size="xs"
                  readOnly
                  value={dataDetail?.customerTelephone || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Trạng thái kiểm tra"
                  size="xs"
                  readOnly
                  value={getValueById(
                    dataDetail?.testingStatus?.toString(),
                    dataStatusOption,
                    "label"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Ghi chú"
                  size="xs"
                  readOnly
                  value={dataDetail?.description || ""}
                />
              </Grid.Col>
            </>
          ) : (
            <>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <TextInput
                  label="Mã nhập BH NCC"
                  size="xs"
                  readOnly
                  value={dataDetail?.sourceCode || ""}
                />
              </Grid.Col>

              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <TextInput
                  label="Chi nhánh"
                  size="xs"
                  readOnly
                  value={dataDetail?.branchName || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <TextInput
                  label="Kho"
                  size="xs"
                  readOnly
                  value={dataDetail?.subInvName || ""}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2.5 }}>
                <TextInput
                  label="Trạng thái kiểm tra"
                  size="xs"
                  readOnly
                  value={getValueById(
                    dataDetail?.testingStatus?.toString(),
                    dataStatusOption,
                    "label"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                <TextInput
                  label="Ghi chú"
                  size="xs"
                  readOnly
                  value={dataDetail?.description || ""}
                />
              </Grid.Col>
            </>
          )}
        </Grid>
      </Box>
      <ListProductDetail
        typeSourceType={location?.state.typeSourceType}
        form={submitFrom}
        datatestDifficultys={datatestDifficultys}
        dataDetail={dataDetail?.tblTestingTechniqueDetailModels}
        isDetail={true}
        height={bottomBoxHeight - 190}
      />
    </Box>
  );
};

export default ModalUpdateTestingTechnique;
