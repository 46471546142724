import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  rem,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconEye, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { UserMasterCreateModel } from "../../model/UserMasterModel";
import { MessageResponse } from "../../model/MessageResponse";
import { repositoryMaster } from "../../_base/_const/_constVar";
import {
  _validateUserMasterModelValidator,
  _validateUserMasterModelValidatorNoPassword,
} from "../../validate/UserCreateModelValidator";
import { ListAuthozireModel } from "../../model/ListAuthozireModel";
import { getTblDmEmployeeSelect } from "../../service/getSelectApi";
import { cobalt_blue, gray, sky_blue } from "../../const/variables";

const UserEdit = function ({
  id,
  onLoad
}: {
  id: string;
  onLoad: () => Promise<void>;
}) {
  const entity: UserMasterCreateModel = {
    id: 0,
    userName: "",
    password: "",
    empid: 0,
  };
  const form = useForm<UserMasterCreateModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: _validateUserMasterModelValidatorNoPassword,
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataEmployeeSelect, setDataDmEmployeeSelect] = useState<
    ComboboxItem[]
  >([]);
  const apiCreate = async (data: UserMasterCreateModel) => {
    console.log(data);
    open();
    let urlCreate = `/api/v1/auth/edit-pass`;
    const callapi = await repositoryMaster.post(urlCreate, data);
    if (callapi)
      if (callapi?.success) {  
        await onLoad();
        NotificationExtension.Success("Thao tác thành công !");
        modals.closeAll();
      } else if (callapi != null)
        NotificationExtension.Fails("Thao tác thất bại !");
    close();
  };
  const dataDmEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataDmEmployeeSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };
  useEffect(() => {
    callApiGetData();
  }, []);
  const [showPass, SetShowPass] = useState<boolean>(false);
  let typePass = showPass ? "text" : "password";
  let colorEye = showPass ? cobalt_blue.base : gray.base;
  const icon = (
    <IconEye
      onClick={() => {
        SetShowPass(!showPass);
      }}
      style={{ width: rem(20), height: rem(20), color: colorEye }}
    />
  );
  const [dataAllSelect, setDataAllSelect] = useState<ListAuthozireModel[]>([]);

  const callApiGetData = async () => {
    open();
    let urlCreate = `/api/v1/auth/edit-pass?userName=` + id;
    const callapi = await repositoryMaster.get<
      MessageResponse<UserMasterCreateModel>
    >(urlCreate);

    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        await Promise.all([dataDmEmployeeSelect(), apiGetParent()]);
      } else {
        NotificationExtension.Fails(callapi.message ?? "Dữ liệu không tồn tại");
        modals.closeAll();
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  const apiGetParent = async () => {
    const response = await repositoryMaster.get<
      MessageResponse<ListAuthozireModel[]>
    >("/api/v1/ListAuthozire/get-list");
    if (response && response.success) {
      setDataAllSelect(response.data);
    }
  };
  return (
    <>
      <Box
        component="form"
        onSubmit={form.onSubmit(async (e: UserMasterCreateModel) => {
          await apiCreate(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Box className="flex-none" component="form" mx="auto">
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <TextInput
                label="Tên đầy đủ: "
                placeholder="Nhập..."
                withAsterisk
                {...form.getInputProps("fullName")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <TextInput
                label="Tên đăng nhập: "
                placeholder="Nhập..."
                withAsterisk
                {...form.getInputProps("userName")}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <TextInput
                type={typePass}
                leftSection={icon}
                label="Mật khẩu: "
                placeholder="Nhập..."
                withAsterisk
                {...form.getInputProps("password")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <TextInput
                type={typePass}
                leftSection={icon}
                label="Nhập lại mật khẩu: "
                placeholder="Nhập..."
                withAsterisk
                {...form.getInputProps("confirmPassword")}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              {" "}
              <TextInput
                label="Trung tâm: "
                placeholder="Nhập..."
                {...form.getInputProps("center")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              {" "}
              <TextInput
                label="Số điện thoại: "
                placeholder="Nhập..."
                {...form.getInputProps("phone")}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={{ base: 12, xs: 6 }}>
              <Select
                id="managerId"
                key={form.key("managerId")}
                label="Nhân viên: "
                placeholder="Nhập..."
                nothingFoundMessage="Không có dữ liệu"
                data={dataEmployeeSelect}
                value={form.getValues().empid?.toString()}
                searchable
                clearable
                withAsterisk
                {...form.getInputProps("managerId")}
                onChange={(e) => {
                  form.setValues({ empid: Number.parseInt(e ?? "0") });
                }}
              />
            </Grid.Col>
          </Grid>
          <Checkbox
            mt={"xs"}
            key={form.key("inActive")}
            label="Trạng thái"
            defaultValue={form.getValues().inActive ?? false}
            defaultChecked={form.getValues().inActive ?? false}
            {...form.getInputProps("inActive")}
          />
        </Box>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default UserEdit;
