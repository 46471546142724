import { Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconHandStop, IconX } from "@tabler/icons-react";

export const BanUserView = ({ ids, onClose }: BanUserViewProps) => {
  return (
    <div>
      <Text size="20px" mt={5}>
        {`Bạn có chắc chắn cấm ${
          ids.length === 1 ? "những " : " "
        }người dùng này`}
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color="red"
          // onClick={handleDeleteBranch}
          leftSection={<IconHandStop size={18} />}
        >
          Cấm
        </Button>
      </Group>
    </div>
  );
};

type BanUserViewProps = {
  ids: string[] | number[];
  onClose: any;
};
