export const search = {
  keySearch: "",
  fromTransationDate: "",
  toTransationDate: "",
  sourceType: "",
  invId: "",
  status: "",
  empId: "",
};

export const dataStatusOption = [
  { value: "OPEN", label: "Đang nhập" },
  { value: "LOCK", label: "Phiếu khóa" },
];

export const visibleColDetail = new Map([
  [
    "NHAP_THANH_PHAM_SAN_XUAT",
    {
      subQuantity: false,
      subUom: false,
      subPrice: false,
      totalAmount: false,
    },
  ],
]);

export const visibleCol = new Map([
  [
    "NHAP_THANH_PHAM_SAN_XUAT",
    {
      subQuantity: false,
      subUom: false,
      subUomName: false,
      subPrice: false,
    },
  ],
]);
