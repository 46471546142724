import {
  Box,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { repositoryPos } from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { sky_blue } from "../../const/variables";
import { useEffect } from "react";
import { TblDebtGroup } from "../../model/TblDebtGroup";

const CreateDataView = ({ type, onClose }: CreateDataViewProps) => {
  const entity = {
    id: "0",
    groupCode: null,
    groupName: null,
    taxCode: null,
    address: null,
    phone: null,
    contactPerson: null,
    classification: null,
    classificationName: null,
    debtTerm: null,
    debtLimit: null,
    type: null,
    typeName: null,
    createBy: null,
    createByName: null,
    lastUpdateBy: null,
    lastCreateByName: null,
    lastUpdateDate: null,
    createDate: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDebtGroup>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      groupCode: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập mã nhóm công nợ!";
        }
      },
      groupName: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên nhóm công nợ!";
        }
      },
      phone: (value?: any | null) => {
        if (value && !/^\d{8,11}$/.test(value)) {
          return "Số điện thoại phải có từ 8 đến 11 chữ số!";
        }
        return null;
      },
    },
  });

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryPos.get<MessageResponse<TblDebtGroup>>(
      "/api/v1/TblDebtGroup/create"
    );
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      onClose("");
    }
  };

  const handleCreateTblDebtGroup = async (dataSubmit: TblDebtGroup) => {
    open();
    const dataApi = await repositoryPos.post<MessageResponse<TblDebtGroup>>(
      "/api/v1/TblDebtGroup/create",
      { ...dataSubmit, type: type }
    );
    if (dataApi?.success) {
      NotificationExtension.Success("Thêm thành công !");
      onClose((prev: any) => !prev);
      modals.closeAll();
    }
    close();
  };

  useEffect(() => {
    callApiGetData();
  }, []);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{
          base: "300px",
          xs: "400px",
          sm: "500px",
          md: "650px",
          lg: "700px",
        }}
        onSubmit={form.onSubmit((e: any) => {
          handleCreateTblDebtGroup(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              withAsterisk
              label={"Mã nhóm công nợ"}
              placeholder={"Nhập mã nhóm công nợ"}
              {...form.getInputProps("groupCode")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              withAsterisk
              label={"Tên nhóm công nợ"}
              placeholder={"Nhập tên nhóm công nợ"}
              {...form.getInputProps("groupName")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"Mã số thuế"}
              placeholder={"Nhập mã số thuế"}
              {...form.getInputProps("taxCode")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"Người giao dịch"}
              placeholder={"Nhập tên người giao dịch"}
              {...form.getInputProps("contactPerson")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"Điện thoại"}
              placeholder={"Nhập số điện thoại"}
              {...form.getInputProps("phone")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"Địa chỉ"}
              placeholder={"Nhập địa chỉ"}
              {...form.getInputProps("address")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"Phân loại"}
              placeholder={"Nhập phân loại"}
              {...form.getInputProps("classification")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"Tên phân loại"}
              placeholder={"Nhập tên phân loại"}
              {...form.getInputProps("classificationName")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <NumberInput
              label={"Thời hạn nợ"}
              placeholder={"Nhập thời hạn nợ"}
              value={Number(form.getValues()?.debtTerm)}
              {...form.getInputProps("debtTerm")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <NumberInput
              thousandSeparator=","
              hideControls
              label={"Giới hạn nợ"}
              placeholder={"Nhập giới hạn nợ"}
              {...form.getInputProps("debtLimit")}
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  type: number;
  onClose: any;
};
