import {
  Badge,
  Box,
  Button,
  Fieldset,
  Flex,
  Grid,
  LoadingOverlay,
  ScrollArea,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { IconArrowBack, IconPrinter } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

import { useDisclosure } from "@mantine/hooks";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { styleCellTable } from "../../../../_setup/navdata/baseStyleCellTable";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { _localization } from "../../../../config/location";
import { WarrantyExportItemBorrowTicketDetail } from "../../../../model/TblWarrantyItemBorrowTicket";
import PrintExportBorrowedGoods from "./PrintExportBorrowedGoods";
import PrintImportBorrowedGood from "./PrintImportBorrowedGood";

const formatCurrency = (value: any) => {
  if (value == null) {
    return "";
  }
  // Định dạng số theo chuẩn Việt Nam
  return value.toLocaleString("vi-VN", {
    minimumFractionDigits: 0, // Không hiển thị phần thập phân
    maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
  });
};
const DetailExportBorredGood = () => {
  // #region handleData Detail
  const location = useLocation();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [visible, { open, close }] = useDisclosure(false);
  //   data
  const [dataDetail, setDataDetail] = useState<any>();
  // #region Print
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: `
        @page {
          size:auto;
          margin: 5mm 0;
      }`,
  });
  // #endregion end Print

  const columns = React.useMemo<
    MRT_ColumnDef<WarrantyExportItemBorrowTicketDetail>[]
  >(
    () => [
      {
        accessorKey: "type",
        header: "Loại",
        enableEditing: false,
        size: 100,
        Cell: ({ renderedCellValue }: any) => (
          <>
            {renderedCellValue === true ? (
              <Badge color="blue" radius="md">
                Bảo hành
              </Badge>
            ) : (
              <Badge color="green" radius="md">
                Cho mượn
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 100,
      },

      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "uomCode",
        header: "Đơn vị tính",
        enableEditing: false,
        size: 100,
      },

      {
        accessorKey: "attribute4",
        header: "Số lượng",
        size: 100,
      },
      {
        accessorKey: "acessory",
        header: "Phụ kiện theo kèm",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        enableEditing: false,
        size: 100,
      },
      {
        accessorKey: "orgPrice",
        header: "Giá",
        size: 100,
        Cell: ({ renderedCellValue, row }: any) => (
          <>{Number(renderedCellValue)?.toLocaleString("vi-VN")} </>
        ),
      },
      {
        accessorKey: "sellingPrice",
        header: "Giá hiện hành",
        size: 100,
        Cell: ({ renderedCellValue, row }: any) => (
          <>{renderedCellValue?.toLocaleString("vi-VN")} </>
        ),
      },
      {
        accessorKey: "actualPrice",
        header: "Giá trị thực",
        size: 100,
        Cell: ({ renderedCellValue, row }: any) => (
          <>{renderedCellValue?.toLocaleString("vi-VN")} </>
        ),
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        enableEditing: false,
        size: 100,
      },
    ],
    []
  );

  const handleReturnPage = () => {
    navigate(-1);
  };

  const fetchDataDetail = async () => {
    open();
    try {
      if (location?.state.id) {
        const response = await repositoryMdm.get(
          `/api/v1/TblWarrantyExportItemBorrowTicket/detail?id=${location?.state.id}`
        );
        if (response && response.httpStatusCode === 200) {
          const result = response.data;
          setDataDetail(result);
        } else {
          NotificationExtension.Fails("Không tìm thấy dữ liệu");
        }
      }
    } catch (error) {
      NotificationExtension.Fails("Đã xảy ra lỗi");
      console.log("error", error);
    } finally {
      close();
    }
  };

  const table = useMantineReactTable<WarrantyExportItemBorrowTicketDetail>({
    columns,
    data: dataDetail?.tblWarrantyExportItemBorrowTicketDetailModels ?? [],
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: false,
    enableRowActions: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableBatchRowSelection: true,
    // onCreatingRowCancel: () => setValidationErrors({}),
    // onEditingRowCancel: () => setValidationErrors({}),
    // positionActionsColumn: "last",

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableTopToolbar: false,
    localization: _localization,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    initialState: {
      columnPinning: {
        left: ["type"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },

    enableStickyHeader: true,
    // onRowSelectionChange: setRowSelection,
    manualSorting: false,
    // rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    fetchDataDetail();
  }, [location?.state.id]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (187.5 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        p={"0px 10px"}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          zIndex={1000}
          visible={visible}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex
          align={"center"}
          justify={"end"}
          style={{ marginBottom: "10px" }}
          gap="md"
        >
          <Button
            type="button"
            color="red"
            onClick={() => handleReturnPage()}
            leftSection={<IconArrowBack size={18} />}
          >
            Thoát
          </Button>
          {location?.state.status === "DA_THU" ||
          location?.state.status === "DA_NHAP_VE" ? (
            <Button
              leftSection={<IconPrinter size={14} />}
              color="blue"
              onClick={() => handlePrint()}
            >
              {location?.state.status === "DA_THU"
                ? "In phiếu xuất mượn"
                : "In phiếu nhập mượn"}
            </Button>
          ) : (
            <></>
          )}
        </Flex>
        <Grid>
          <Grid.Col span={5}>
            <ScrollArea h={height + 60} scrollbars="y">
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Fieldset legend="Thông tin khách hàng">
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Tên khách hàng"
                          placeholder="Tên khách hàng"
                          value={dataDetail?.cusName ?? ""}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số điện thoại"
                          placeholder="Số điện thoại"
                          value={dataDetail?.cusPhoneNumber ?? ""}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <TextInput
                          label="Địa chỉ"
                          placeholder="Địa chỉ"
                          value={dataDetail?.cusAddress ?? ""}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                    </Grid>
                  </Fieldset>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Fieldset legend="Thông tin phiếu yêu cầu xuất nhập hàng mượn">
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số phiếu yêu cầu hàng mượn"
                          placeholder="Số phiếu yêu cầu hàng mượn"
                          value={dataDetail?.borrowCode ?? ""}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Số phiếu tiếp nhận hàng lỗi"
                          value={dataDetail?.receiveTicketCode ?? ""}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Bộ phận / Phòng ban"
                          placeholder=""
                          value={dataDetail?.departmentName ?? ""}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Người lập"
                          placeholder=""
                          value={dataDetail?.createByName ?? ""}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Ngày lập"
                          placeholder=""
                          value={formatDateTransfer(
                            dataDetail?.createDate ?? ""
                          )}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Kho"
                          placeholder=""
                          value={dataDetail?.inventoryName ?? ""}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Chi nhánh"
                          placeholder=""
                          value={dataDetail?.branchName ?? ""}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                        <TextInput
                          label="Chức danh"
                          placeholder=""
                          value={dataDetail?.roleName ?? ""}
                          readOnly
                          variant="filled"
                        />
                      </Grid.Col>
                      <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                        <Textarea
                          label="Ghi chú"
                          value={dataDetail?.note ?? ""}
                          readOnly
                        />
                      </Grid.Col>
                    </Grid>
                  </Fieldset>
                </Grid.Col>
              </Grid>
            </ScrollArea>
          </Grid.Col>
          <Grid.Col span={7}>
            <Fieldset legend="Thông tin sản phẩm">
              <MantineReactTable table={table} />
            </Fieldset>
            <Flex justify={"start"} gap={"xs"} h={100} mt={5}>
              <Flex
                direction={"row"}
                align={"center"}
                justify={"center"}
                bd={"1px solid #DEE2E6"}
                style={{ borderRadius: "10px" }}
                gap={"md"}
                w={300}
              >
                <Text size="14px" fw={"bold"}>
                  Tổng số tiền cọc :
                </Text>
                <Text size="13px" fw={"bold"} c={"red"}>
                  {formatCurrency(dataDetail?.totalDeposit)} VND
                </Text>
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </Box>
      <Box display={"none"}>
        {location?.state.status === "DA_THU" ? (
          <PrintExportBorrowedGoods
            innerRef={componentRef}
            dataPrint={dataDetail}
          />
        ) : location?.state.status === "DA_NHAP_VE" ? (
          <PrintImportBorrowedGood
            innerRef={componentRef}
            dataPrint={dataDetail}
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default DetailExportBorredGood;
