import {
  Box,
  Button,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { hasLength, isEmail, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryDelivery } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMBranch } from "../../../../model/TblDMBranch";
import { getProvinceSelect } from "../../../../service/getSelectApi";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";

const EditDataView = ({ id, onClose }: EditDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    name: null,
    address: null,
    phone: null,
    fax: null,
    email: null,
    note: null,
    location: null,
    road: null,
    province: null,
    country: null,
    channelCode: null,
    isOnline: 0,
    masterInventoryOrg: null,
    guarantee: null,
    orgId: null,
    townName: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [oldData, setOldData] = useState<TblDMBranch>({ ...entity });
  const [enable, setEnable] = useState(false);
  const [dataTblProvinceSelect, setDataTblProvinceSelect] = useState<
    ComboboxItem[]
  >([]);

  const form = useForm<TblDMBranch>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      name: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên trung tâm!";
        }
        return hasLength(
          { min: 5, max: 50 },
          "Tên phải từ 5-50 kí tự!"
        )(value as string);
      },
      code: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập mã trung tâm!";
        }
        return hasLength(
          { max: 50 },
          "Mã phải nhỏ hơn 10 kí tự!"
        )(value as string);
      },
      address: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập địa chỉ trung tâm!";
        }
      },
      email: (value: string | null) => {
        if (value) {
          return isEmail("Email không đúng định dạng")(value as string);
        }
      },
    },
  });

  const dataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataTblProvinceSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const handleEditTblDMBranch = async (dataSubmit: TblDMBranch) => {
    open();
    const dataApi = await repositoryDelivery.post<MessageResponse<TblDMBranch>>(
      "/api/v1/TblDMMasterOrg/update",
      dataSubmit
    );

    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
    }
    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryDelivery.get<MessageResponse<TblDMBranch>>(
      `/api/v1/TblDMMasterOrg/update?id=${id}`
    );

    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
      return;
    }

    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        await dataProvinceSelect();
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  return (
    <>
      <>
        <Box
          component="form"
          mx="auto"
          onSubmit={form.onSubmit((e: TblDMBranch) => {
            handleEditTblDMBranch(e);
          })}
          style={{ position: "relative" }}
        >
          <LoadingOverlay
            visible={visible}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />

          <Grid mt={10}>
            <Grid.Col span={6}>
              <TextInput
                label={"Mã trung tâm"}
                placeholder={"Nhập mã"}
                type="text"
                withAsterisk
                disabled
                onKeyDown={handleKeyDown}
                {...form.getInputProps("code")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label={"Tên trung tâm"}
                placeholder={"Nhập tên"}
                type="text"
                withAsterisk
                {...form.getInputProps("name")}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label={"Địa chỉ"}
                placeholder={"Nhập địa chỉ"}
                type="text"
                withAsterisk
                {...form.getInputProps("address")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label={"Vị trí"}
                placeholder={"Nhập vị trí"}
                type="text"
                {...form.getInputProps("location")}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col>
              <TextInput
                label={"Đường"}
                placeholder={"Nhập đường"}
                type="text"
                {...form.getInputProps("road")}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <Select
                label={"Tỉnh/Thành phố"}
                placeholder={"Chọn tỉnh/thành phố"}
                clearable
                searchable
                data={dataTblProvinceSelect}
                {...form.getInputProps("province")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label={"Quốc gia"}
                placeholder={"Nhập quốc gia"}
                type="text"
                {...form.getInputProps("country")}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label={"Điện thoại"}
                placeholder={"Nhập số điện thoại"}
                type="number"
                {...form.getInputProps("phone")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label={"Fax"}
                placeholder={"Nhập fax"}
                type="text"
                {...form.getInputProps("fax")}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col>
              <TextInput
                label={"Email"}
                placeholder={"Nhập email"}
                type="text"
                {...form.getInputProps("email")}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col>
              <Textarea
                label={"Ghi chú"}
                placeholder={"Nhập ghi chú"}
                {...form.getInputProps("note")}
              />
            </Grid.Col>
          </Grid>

          <Group
            justify="end"
            mt="xs"
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
            }}
          >
            <Button
              type="button"
              color="gray"
              loading={visible}
              onClick={() => {
                modals.closeAll();
              }}
              leftSection={!visible ? <IconWindow size={18} /> : undefined}
            >
              Đóng
            </Button>
            <Button
              type="submit"
              color={sky_blue.base}
              disabled={!enable}
              loading={visible}
              leftSection={!visible ? <IconCheck size={18} /> : undefined}
            >
              Lưu
            </Button>
            <></>
          </Group>
        </Box>
      </>
    </>
  );
};

export default EditDataView;

type EditDataViewProps = {
  id: string | number;
  onClose: any;
};
