import React from "react";
import {
  ScrollArea,
  Table,
  Radio,
  NumberFormatter,
  Checkbox,
} from "@mantine/core";
import {
  itemAddOnPromotionModel,
  TblItemPromotionModel,
} from "../../../../../model/TblItem";

interface AddOnTableProps {
  setListSelectedAddOn: React.Dispatch<
    React.SetStateAction<itemAddOnPromotionModel[]>
  >;
  listSelectedAddOn: itemAddOnPromotionModel[];

  selectedPromotion: TblItemPromotionModel;
}

const AddOnTable: React.FC<AddOnTableProps> = ({
  selectedPromotion,
  setListSelectedAddOn,
  listSelectedAddOn,
}) => {
  return (
    <ScrollArea h={500}>
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
            <Table.Th>% Chiết khấu</Table.Th>
            <Table.Th>Tiền chiết khấu</Table.Th>
            <Table.Th>Tồn kho</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {selectedPromotion?.tblPromotionItemAddOn
            .filter((promotionItem) => promotionItem?.itemForSellModel) // Kiểm tra tồn tại
            .map((promotionItem) => (
              <Table.Tr key={promotionItem?.idItemAddOn}>
                <Table.Td>
                  {promotionItem?.itemForSellModel?.productCode}
                </Table.Td>
                <Table.Td>
                  {promotionItem?.itemForSellModel?.productName}
                </Table.Td>
                <Table.Td>{promotionItem?.itemForSellModel?.unit}</Table.Td>
                <Table.Td>{promotionItem?.discountPercent}%</Table.Td>
                <Table.Td>
                  <NumberFormatter
                    value={promotionItem.discountAmount || 0}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="₫"
                  />
                </Table.Td>
                <Table.Td>{promotionItem?.itemForSellModel?.tonao}</Table.Td>
                <Table.Td>
                  <Radio
                    checked={listSelectedAddOn[0]?.id === promotionItem.id}
                    onChange={() => setListSelectedAddOn([promotionItem])}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
};

export default AddOnTable;
