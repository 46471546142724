import { useEffect, useState } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { Box, ScrollArea, Table, Checkbox } from "@mantine/core";
import cx from "clsx";
import classes from "../../../../Styles/TableScrollArea.module.css";
import { TblInventoryCycleCountPeriod } from "../../../../model/TblInventoryCycleCountPeriod";
import moment from "moment";

const ModalICCP = ({
  ICCP,
  setICCP,
}: {
  ICCP: string | null;
  setICCP: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const [dataICCP, setDataICCP] = useState<TblInventoryCycleCountPeriod[]>([]);
  const [selectedICCPId, setselectedICCPId] = useState<string | null>(null);
  const [take, setTake] = useState<number>(50);
  const [countFetchList, setCountFetchList] = useState<number>(1);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [keySearch, setKeySearch] = useState<string>("");
  const [hasFetchedDefault, setHasFetchedDefault] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });

  const handleScrollPositionChange = ({ x, y }: { x: number; y: number }) => {
    onScrollPositionChange({ x, y });
    setScrolled(y !== 0);
    if (y >= 3212 * countFetchList) {
      setTake((prevTake) => prevTake + 50);
      setCountFetchList((prevCFL) => prevCFL + 1);
      fetchDataICCP();
    }
  };

  const fetchDataICCP = async (reset = false) => {
    setIsLoading(true);
    setIsRefetching(true);
    let url = `?Skip=0&Take=${take}`;

    if (ICCP) {
      url += `&KeySearch=${ICCP}`;
    }

    try {
      const response = await repositoryMdm.get(
        `api/v1/TblInventoryCycleCountPeriod/get-list${url}`
      );
      if (response && response.success) {
        setDataICCP((prevData) => {
          if (reset || keySearch) {
            return response.data;
          }
          return [...prevData, ...response.data];
        });
        setselectedICCPId(null);
        setHasFetchedDefault(true);
      }
    } catch (error) {
      setDataICCP([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleCheckboxChange = (id: string) => {
    setICCP(id);
  };

  const rows = dataICCP.map((row: any) => (
    <Table.Tr key={row.id}>
      <Table.Td>
        <Checkbox
          checked={ICCP === row.id}
          onChange={() => handleCheckboxChange(row.id)}
        />
      </Table.Td>
      <Table.Td>{row.cycleCountPeriod}</Table.Td>
      <Table.Td>{row.code}</Table.Td>
      <Table.Td>
        {moment(new Date(row.startTime))
          .add(1, "day")
          .format("HH:mm:ss DD-MM-YYYY")}
      </Table.Td>
      <Table.Td>
        {moment(new Date(row.endTime))
          .add(1, "day")
          .format("HH:mm:ss DD-MM-YYYY")}
      </Table.Td>
    </Table.Tr>
  ));

  useEffect(() => {
    if (ICCP) {
      setTake(50);
      setCountFetchList(1);
      fetchDataICCP(true);
    }
  }, [ICCP]);

  return (
    <Box mt={10}>
      <ScrollArea
        mt={10}
        h={ICCP ? 205 : 35}
        offsetScrollbars
        onScrollPositionChange={handleScrollPositionChange}
        scrollbars="y"
      >
        <Table striped highlightOnHover withColumnBorders withTableBorder>
          <Table.Thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <Table.Tr>
              <Table.Th />
              <Table.Th>Kỳ kiểm kê kho</Table.Th>
              <Table.Th>Mã kỳ kiểm kê</Table.Th>
              <Table.Th>Thời gian bắt đầu</Table.Th>
              <Table.Th>Thời gian kết thúc</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </ScrollArea>
    </Box>
  );
};

export default ModalICCP;
