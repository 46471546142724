import { useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  rem,
  Flex,
  Tooltip,
} from "@mantine/core";
import { IconCalendarStats, IconChevronRight } from "@tabler/icons-react";
import classes from "../../_style/NavbarLinksGroup.module.css";
import { LinksGroupProps } from "../../model/_base/LinksGroupProps";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryMaster } from "../../_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  isCollapsed,
  open,
  isPinned,
}: LinksGroupProps) {
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState<string>("Settings");
  const location = useLocation();
  const pathname = location.pathname;
  const formatLink = (link: any) => {
    // Nếu link không chứa "/", thêm "/" vào đầu
    return link.startsWith("/") ? link : `/${link}`;
  };
  const userName = localStorage.getItem("userName");
  const items = (hasLinks ? links : []).map((link) => (
    <div
      className={classes.link}
      key={link.label}
      onClick={async (event) => {
        event.preventDefault();
        if (userName === "hopnk1" || userName === "vinhnv") navigate(link.link);
        else {
          if (!link.link.includes("authozire/user-page")) {
            var resCheck = await repositoryMaster.get<
              MessageResponse<Array<string>>
            >("/api/v1/menugroup/check");
            if (resCheck && resCheck?.data && resCheck.success) {
              if (resCheck.data && resCheck.data.length > 0) {
                //  console.log(resCheck.data.indexOf(link.link) >= 0);
                if (
                  resCheck.data.findIndex((x) =>
                    link.link.toLowerCase().startsWith(x.toLowerCase())
                  ) >= 0
                )
                  navigate(link.link);
                else navigate("/");
              } else navigate("/");
            } else navigate("/");
          } else navigate(link.link);
        }
      }}
      //  data-active={activeLink === link.label || undefined}
      style={{
        color: formatLink(link.link) === pathname ? "#006bb8" : "black",
        backgroundColor:
          formatLink(link.link) === pathname
            ? "rgba(0,119,204,0.2)"
            : "initial",
      }}
    >
      {link.label}
    </div>
  ));

  return (
    <>
      <UnstyledButton
        onClick={() => {
          if (!isCollapsed) setOpened((o) => !o);
        }}
        className={classes.control}
      >
        <Group justify="space-between" gap={0}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              padding: "10px",
            }}
          >
            <Tooltip label={label}>
              <ThemeIcon
                variant="transparent"
                size={25}
                onClick={() => {
                  if (!isPinned) {
                    open();
                    setOpened(true);
                  }
                }}
              >
                <Icon style={{ width: rem(25), height: rem(25) }} />
              </ThemeIcon>
            </Tooltip>
            {!isCollapsed && <Box ml="md">{label}</Box>}
          </Box>
          {hasLinks && !isCollapsed && (
            <IconChevronRight
              className={classes.chevron}
              stroke={1.5}
              style={{
                width: rem(25),
                height: rem(25),
                transform: opened ? "rotate(-90deg)" : "none",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks && !isCollapsed ? (
        <Collapse in={opened && !isCollapsed}>{items}</Collapse>
      ) : null}
    </>
  );
}

// const mockdata = {
//   label: 'Releases',
//   icon: IconCalendarStats,
//   links: [
//     { label: 'Upcoming releases', link: '/' },
//     { label: 'Previous releases', link: '/' },
//     { label: 'Releases schedule', link: '/' },
//   ],
// };

// export function NavbarLinksGroup() {
//   return (
//     <Box mih={220} p="md">
//       <LinksGroup {...mockdata} />
//     </Box>
//   );
// }
