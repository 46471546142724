import {
  Box,
  Checkbox,
  Fieldset,
  Flex,
  Grid,
  NumberInput,
  Textarea,
  TextInput,
} from "@mantine/core";
import { tblPromotionDetailModel } from "../../../../../../model/TblPromotion";
import { IconCalendarMonth } from "@tabler/icons-react";
import { DateTimePicker } from "@mantine/dates";
import { useEffect, useState } from "react";
import { SelectListItemBase } from "../../../../../../_base/model/_base/SelectListItemBase";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../../../model/MessageResponse";

const GeneralInformation = ({
  dataPromotionDetail,
}: {
  dataPromotionDetail: tblPromotionDetailModel | undefined;
}) => {
  useEffect(() => {
    getType11();
  }, [dataPromotionDetail]);
  const [selectType, setTypeSelect] = useState<SelectListItemBase[]>([]);
  const getType11 = async () => {
    var resType = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblPromotion/type");
    if (resType?.httpStatusCode === 200 && resType.data) {
      setTypeSelect(resType.data);
    }
  };
  const _styleGrid = { base: 6, md: 3, lg: 2 };
  const _styleGrid1 = { base: 6, md: 6, lg: 4 };
  return (
    <Box h={"max-content"}>
      <Fieldset
        legend="Thông tin chung"
        display={"flex"}
        style={{ flexDirection: "column", gap: "10px" }}
      >
        <Grid>
          <Grid.Col span={_styleGrid}>
            <TextInput
              label="Số chính sách"
              placeholder="Nhập số chính sách"
              readOnly
              value={dataPromotionDetail?.code || ""}
            />
          </Grid.Col>
          {/* <Grid.Col span={{ base: 6, md: 1.5, lg: 1.5 }}>
            <NumberInput
              label="Thứ tự ưu tiên"
              min={0}
              size="xs"
              readOnly
              value={dataPromotionDetail?.priority || ""}
            />
          </Grid.Col> */}

          <Grid.Col span={_styleGrid}>
            <TextInput
              label="Trạng thái"
              placeholder="Trạng thái"
              w={"100%"}
              readOnly
              value={
                dataPromotionDetail?.approveStatus === "2"
                  ? "Đã duyệt"
                  : dataPromotionDetail?.approveStatus === "3"
                  ? "Dừng duyệt"
                  : dataPromotionDetail?.approveStatus === "4"
                  ? "Từ chối"
                  : dataPromotionDetail?.approveStatus === "5"
                  ? "Hủy duyệt"
                  : "Chưa duyệt"
              }
            />
          </Grid.Col>
          <Grid.Col span={_styleGrid}>
            <TextInput
              label="Người lập"
              readOnly
              value={dataPromotionDetail?.createBy || ""}
              w={"100%"}
            />
          </Grid.Col>
          <Grid.Col span={_styleGrid}>
            <TextInput
              label="Chính sách khuyến mại"
              readOnly
              value={
                selectType.filter(
                  (x) => x.value === dataPromotionDetail?.type
                )[0]?.text || ""
              }
            />
          </Grid.Col>

          <Grid.Col span={_styleGrid}>
            <DateTimePicker
              readOnly
              label="Hiệu lực từ ngày"
              placeholder="Hiệu lực từ ngày"
              minDate={new Date()}
              rightSection={<IconCalendarMonth size={14} />}
              value={
                dataPromotionDetail?.effDate
                  ? new Date(dataPromotionDetail?.effDate)
                  : null
              }
            />
          </Grid.Col>

          <Grid.Col span={_styleGrid}>
            <DateTimePicker
              readOnly
              label="Đến ngày"
              placeholder="Đến ngày"
              rightSection={<IconCalendarMonth size={14} />}
              value={
                dataPromotionDetail?.expDate
                  ? new Date(dataPromotionDetail?.expDate)
                  : null
              }
            />
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 4.5, lg: 4.5 }}>
            <TextInput
              label="Tên CTKM"
              placeholder="Ghi chú"
              readOnly
              withAsterisk
              value={dataPromotionDetail?.note || ""}
            />
          </Grid.Col>
          <Grid.Col mt={"30px"} span={{ base: 12, md: 4.5, lg: 4.5 }}>
            <Checkbox
              label="Áp dụng đồng thời CTKM khác"
              checked={dataPromotionDetail?.ismultiple}
            />
          </Grid.Col>
        </Grid>
      </Fieldset>
    </Box>
  );
};

export default GeneralInformation;
