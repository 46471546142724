import {
  Button,
  Input,
  MantineProvider,
  createTheme,
  rem,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { NavigationProgress } from "@mantine/nprogress";
import "@mantine/nprogress/styles.css";
import "dayjs/locale/vi";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { AgCharts } from "ag-charts-react";
import { AgCharts as AgChartsEnterprise } from "ag-charts-enterprise";
import { PostHogProvider } from "posthog-js/react";
import { Provider } from "react-redux";
import { store } from "./views/Sell/RetailOrder/ReduxToolkit/store";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};
LicenseManager.setLicenseKey(
  "DownloadDevToolsCOM[FULL][BOTH][v31]_NDEwMjM1ODQwMDAwMA==da470cbe32987750dd5b0d948c2b6305"
);
AgChartsEnterprise.setLicenseKey(
  "DownloadDevToolsCOM[FULL][BOTH][v31]_NDEwMjM1ODQwMDAwMA==da470cbe32987750dd5b0d948c2b6305"
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// Registering Syncfusion license key
const theme = createTheme({
  fontSizes: {
    xs: rem(10),
    sm: rem(12),
    md: rem(14),
    lg: rem(16),
    xl: rem(20),
  },
  lineHeights: {
    xs: "1.4",
    sm: "1.45",
    md: "1.55",
    lg: "1.6",
    xl: "1.65",
  },
  components: {
    Button: Button.extend({
      defaultProps: {
        color: "cyan",
        variant: "outline",
        //  size: "xs",
      },
    }),
  },
});

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <Provider store={store}>
        <MantineProvider theme={theme}>
          <ModalsProvider>
            <NavigationProgress />
            <Notifications position="top-center" autoClose={1500} />
            <App />
          </ModalsProvider>
        </MantineProvider>
      </Provider>
    </PostHogProvider>
  </React.StrictMode>
);
reportWebVitals();
