import style from "./PrintStyle.module.css";
import logo from "../../../../assets/images/logo-hacom-2024.png";
import { IconCheck } from "@tabler/icons-react";
import { numberToWords } from "../../../../_base/helper/FunctionHelper";

const PrintExportBorrowedGoods = ({
  innerRef,
  dataPrint,
}: {
  innerRef: any;
  dataPrint: any;
}) => {
  return (
    <div ref={innerRef} className={style.main}>
      <div className={style.header}>
        <img src={logo} alt="logo-hacom-2024" width={150} height={"auto"} />
        <div>
          <div className={style.line}>
            <h3>CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG NGHỆ HACOM</h3>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Trụ sở: </p>
            <p>129+131 Lê Thanh Nghị, Q.Hai Bà Trưng, TP.Hà Nội</p>
          </div>
          <div className={style.line}>
            <div>
              <p className={style.cText}>Tel: </p>
              <p>19001903</p>
            </div>
            <div>
              <p className={style.cText}>Email:</p>
              <p>info@hacom.vn</p>
            </div>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Website:</p>
            <p>www.hacom.vn</p>
          </div>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.title}>
          <h2>BIÊN BẢN BÀN GIAO SẢN PHẨM CHO MƯỢN</h2>
        </div>
        <div className={style.wellFounded}>
          <div
            className={`${style.inline} ${style.indent} ${style.mb} ${style.italic}`}
          >
            <p>Căn cứ Phiếu tiếp nhận – Bàn giao sản phẩm bảo hành: </p>
            <p className={style.fw}>{dataPrint?.receiveTicketCode}</p>
            <p>
              {" "}
              ngày{" "}
              {String(new Date(dataPrint?.receptionDate).getDate()).padStart(
                2,
                "0"
              )}{" "}
              tháng{" "}
              {String(
                new Date(dataPrint?.receptionDate).getMonth() + 1
              ).padStart(2, "0")}{" "}
              năm {new Date(dataPrint?.receptionDate).getFullYear()};
            </p>
          </div>
          <p className={`${style.indent} ${style.mb} ${style.italic}`}>
            Căn cứ nhu cầu và năng lực các Bên.
          </p>
          <p className={`${style.indent} ${style.mb}`}>
            Hôm nay, ngày {String(new Date().getDate()).padStart(2, "0")} tháng{" "}
            {String(new Date().getMonth() + 1).padStart(2, "0")} năm{" "}
            {new Date().getFullYear()}, tại{" "}
            {JSON.parse(localStorage.getItem("userLogin") || "{}")?.branchName},
            chúng tôi gồm:
          </p>
        </div>
      </div>
      <div className={`${style.partyA} ${style.mt}`}>
        <div className={`${style.inline}`}>
          <p className={`${style.fw}`}>
            A.BÊN A {"("}Bên mượn{")"}: Ông/Bà
          </p>
          <p>
            ..................................................................................................................................................
          </p>
        </div>
        <div className={`${style.inline} ${style.italic} ${style.mtl}`}>
          <IconCheck size={13} style={{ marginRight: "5px" }} />
          <p>
            Địa
            chỉ:.........................................................................................................................
          </p>
          <p>Điện thoại:.........................................</p>
        </div>
        <div className={`${style.inline} ${style.italic} ${style.mtl}`}>
          <IconCheck size={13} style={{ marginRight: "5px" }} />
          <p>
            Số
            CCCD:................................................................
          </p>
          <p>Ngày cấp:........................................</p>
          <p>Nơi cấp:.........................................</p>
        </div>
      </div>
      <div className={`${style.partyB} ${style.mtl}`}>
        <div className={`${style.inline}`}>
          <p className={`${style.fw}`}>
            B.BÊN B {"("}Bên cho mượn{")"}: CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG NGHỆ
            HACOM
          </p>
        </div>
        <div className={`${style.spaceBetween}`}>
          <div className={`${style.italic} ${style.mtl}`}>
            <div className={`${style.inline}`}>
              <IconCheck size={13} style={{ marginRight: "5px" }} />
              <p>Địa chỉ: {dataPrint?.branchAddress}</p>
            </div>
            <div className={`${style.inline} ${style.mtl}`}>
              <IconCheck size={13} style={{ marginRight: "5px" }} />
              <p>Đại diện: {dataPrint?.createByName}</p>
            </div>
          </div>
          <div className={`${style.italic} ${style.mtl}`}>
            <div className={`${style.inline}`}>
              <p>Điện thoại:.........................................</p>
            </div>
            <div className={`${style.inline} ${style.mtl}`}>
              <p>Chức vụ: {dataPrint?.roleName}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.clause} ${style.mtt}`}>
        <p className={`${style.mbl}`}>
          Hai Bên thống nhất lập Biên bản bàn giao sản phẩm cho mượn (sau đây
          gọi tắt là “Biên bản”) với các nội dung sau:{" "}
        </p>
        <div className={`${style.inline}`}>
          <p className={`${style.fw}`}>1. Mục đích bàn giao:</p>
          <p>
            {" "}
            Bên A mượn sản phẩm (máy móc, thiết bị, vật tư) để sử dụng tạm thời
            trong quá trình Bên B bảo hành theo nội dung Phiếu tiếp nhận – bàn
            giao sản phẩm:{" "}
          </p>
          <p className={style.fw}>{dataPrint?.receiveTicketCode}</p>
          <p>
            {" "}
            ngày{" "}
            {String(new Date(dataPrint?.receptionDate).getDate()).padStart(
              2,
              "0"
            )}{" "}
            tháng{" "}
            {String(new Date(dataPrint?.receptionDate).getMonth() + 1).padStart(
              2,
              "0"
            )}{" "}
            năm {new Date(dataPrint?.receptionDate).getFullYear()};
          </p>
        </div>
        <div className={`${style.inline}`}>
          <p className={`${style.fw}`}>2. Sản phẩm đã bàn giao:</p>
          <p>
            {" "}
            Bên B đã bàn giao đầy đủ cho Bên A các sản phẩm mà Bên A có nhu cầu
            sử dụng tạm thời, bảo đảm đúng chủng loại, hoạt động tốt, tình trạng
            sản phẩm rõ ràng, minh bạch như sau:
          </p>
        </div>
        <table className={`${style.table}`}>
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên sản phẩm</th>
              <th>Số lượng</th>
              <th>Tình trạng</th>
              <th>Ghi chú</th>
            </tr>
          </thead>
          <tbody>
            {dataPrint?.tblWarrantyExportItemBorrowTicketDetailModels
              ?.filter((product: any) => product.type === false)
              ?.map((item: any, index: any) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.itemName}</td>
                  <td>{item.quantity}</td>
                  <td>{item.status}</td>
                  <td>{item.note}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div>
          <p className={`${style.fw} ${style.mbt2}`}>
            3. Giá trị sản phẩm mượn và số tiền đặt cọc
          </p>
          <p className={`${style.mbt2}`}>
            - Tổng giá trị sản phẩm Bên B cho Bên A mượn ước tính:{" "}
            {dataPrint?.tblWarrantyExportItemBorrowTicketDetailModels
              ?.filter((item: any) => item.type === false)
              ?.reduce((sum: any, item: any) => sum + item.actualPrice, 0)
              .toLocaleString()}{" "}
            VNĐ
          </p>
          <p className={`${style.mbt2}`}>
            Bằng chữ:{" "}
            {numberToWords(
              Number(
                dataPrint?.tblWarrantyExportItemBorrowTicketDetailModels
                  ?.filter((item: any) => item.type === false)
                  ?.reduce((sum: any, item: any) => sum + item.actualPrice, 0)
              )
            )}{" "}
            việt nam đồng
          </p>
          <p className={`${style.mbt2}`}>
            - Số tiền Bên A đặt cọc cho Bên B là:{" "}
            {dataPrint?.totalDeposit?.toLocaleString()} VNĐ
          </p>
          <p className={`${style.mbt2}`}>
            Bằng chữ: {numberToWords(dataPrint?.totalDeposit)} việt nam đồng
          </p>
          <p className={`${style.mbt2}`}>
            - Hình thức đặt
            cọc:.....................................................................................................................................................................
          </p>
          <p className={`${style.mbt2}`}>
            Trong trường hợp Bên A làm mất sản phẩm hoặc làm hư hỏng sản phẩm đã
            mượn của Bên B, Hai Bên đồng ý khoản đặt cọc này sẽ được sử dụng để
            Bên A khắc phục, bồi thường một phần/toàn bộ thiệt hại cho Bên B.
            Nội dung này sẽ được Hai Bên thỏa thuận, xác nhận chi tiết bằng văn
            bản.
          </p>
          <p className={`${style.mbt2}`}>
            Trong trường hợp hết thời hạn mượn sản phẩm theo nội dung Biên bản
            này, nếu Bên A không thực hiện việc hoàn trả sản phẩm đã mượn cho
            Bên B, Bên A sẽ không được Bên B hoàn lại toàn bộ số tiền đã đặt cọc
            nêu trên, trừ trường hợp Bên A có lý do chính đáng được Bên B chấp
            thuận.
          </p>
        </div>
        <div>
          <p className={`${style.fw} ${style.mbt2}`}>
            4. Thời hạn mượn sản phẩm:
          </p>
          <div className={`${style.inline} ${style.mtl2} ${style.mbt2}`}>
            <p className={`${style.mbt2}`}>
              Bên B cho Bên A mượn sản phẩm theo nội dung Biên bản này từ ngày{" "}
              {String(new Date(dataPrint?.receptionDate).getDate()).padStart(
                2,
                "0"
              )}{" "}
              tháng{" "}
              {String(
                new Date(dataPrint?.receptionDate).getMonth() + 1
              ).padStart(2, "0")}{" "}
              năm {new Date(dataPrint?.receptionDate).getFullYear()} đến hết
              ngày ....... tháng ....... năm .............., tương ứng với thời
              gian sửa chữa/bảo hành theo Phiếu tiếp nhận bảo hành:{" "}
            </p>
            <p className={style.fw}>{dataPrint?.receiveTicketCode}</p>
            <p>
              {" "}
              ngày{" "}
              {String(new Date(dataPrint?.receptionDate).getDate()).padStart(
                2,
                "0"
              )}{" "}
              tháng{" "}
              {String(
                new Date(dataPrint?.receptionDate).getMonth() + 1
              ).padStart(2, "0")}{" "}
              năm {new Date(dataPrint?.receptionDate).getFullYear()};
            </p>
          </div>
          <p className={style.mbt2}>
            Trường hợp vì lý do sản phẩm của Bên A cần thời gian bảo hành/sửa
            chữa kéo dài hơn dự kiến so với nội dung đã lập trong Phiếu tiếp
            nhận bảo hành, Bên B có trách nhiệm thông báo cho Bên A về thời gian
            gia hạn bảo hành thông qua một trong các phương tiện liên lạc bằng
            điện thoại hoặc nền tảng ứng dụng trực tuyến/sms (trường hợp Bên A
            không tiếp nhận cuộc gọi) thì việc hoàn trả sản phẩm cho mượn sẽ
            không bị tính là quá hạn cho đến khi việc bảo hành sản phẩm cho Bên
            A hoàn tất.
          </p>
        </div>
        <div className={`${style.mtt3}`}>
          <p className={`${style.fw}`}>5. Trách nhiệm của Hai Bên:</p>
          <div
            className={`${style.inline} ${style.fw} ${style.italic} ${style.mtt3} ${style.mbt2}`}
          >
            <IconCheck size={13} style={{ marginRight: "5px" }} />
            <p>Trách nhiệm của Bên A:</p>
          </div>
          <p className={style.mbt2}>
            - Hoàn trả lại sản phẩm đã mượn của Bên B đúng thời hạn, đúng hiện
            trạng theo nội dung Biên bản này.
          </p>
          <p className={style.mbt2}>
            - Nếu Bên A làm mất sản phẩm, Bên A phải thanh toán toàn bộ giá trị
            sản phẩm cho Bên B theo giá trị ước tính đã xác nhận trong Biên bản
            này.
          </p>
          <p className={style.mbt2}>
            - Nếu Bên A mượn sản phẩm để xảy ra hư hỏng; tình trạng sản phẩm
            không còn được như mô tả tại Biên bản này thì Bên A phải thanh toán,
            khắc phục, bồi thường tài sản, sản phẩm đã mượn cho Bên B ngay sau
            khi Bên B thông báo chi phí sửa chữa, khắc phục cho Bên A.
          </p>
          <p className={style.mbt2}>
            - Với các sản phẩm Bên B cho Bên A mượn là các sản phẩm, vật tư tiêu
            hao như: máy in Laser, máy in màu, giấy in,… thì Bên A phải chi trả
            cho Bên B tiền/vật tư bổ sung lại cho vật tư tiêu hao đó. Số
            tiền/vật tư Bên A trả cho Bên B dựa theo đúng giá trị, đúng vật tư
            của thị trường tại thời điểm hoàn trả sản phẩm.
          </p>
          <p className={style.mbt2}>
            - Trong thời gian mượn sản phẩm, Bên A tự chịu trách nhiệm về các
            thông tin dữ liệu, bản quyền phần mềm Bên A đã cài đặt; tự bảo vệ,
            lưu trữ dữ liệu cá nhân tại sản phẩm đã mượn; chủ động sao lưu
            (backup) tất cả dữ liệu cá nhân ra khỏi sản phẩm đã mượn trước khi
            hoàn trả sản phẩm cho Bên B.
          </p>
          <p className={style.mbt2}>
            - Nếu bên A sử dụng sản phẩm mượn của bên B sai mục đích hoặc vi
            phạm pháp luật, bên A sẽ chịu hoàn toàn trách nhiệm trước Pháp luật.
          </p>
          <div
            className={`${style.inline} ${style.fw} ${style.italic} ${style.mtl2} ${style.mbt2}`}
          >
            <IconCheck size={13} style={{ marginRight: "5px" }} />
            <p>Trách nhiệm của Bên B:</p>
          </div>
          <p className={style.mbt2}>
            - Tư vấn, bàn giao sản phẩm cho Bên A theo đúng nhu cầu của Bên A.
          </p>
          <p className={style.mbt2}>
            - Đảm bảo sản phẩm cho mượn đúng hiện trạng theo nội dung Biên bản
            này.
          </p>
          <p className={style.mbt2}>
            - Hoàn trả lại số tiền Bên A đã đặt cọc sau khi Bên A thanh toán,
            khắc phục, bồi thường tài sản,… do Bên A làm mất hoặc làm hư hỏng
            sản phẩm đã mượn của Bên B (nếu có).
          </p>
        </div>
        <div>
          <p className={`${style.fw} ${style.mbt2}`}>6. Điều khoản chung:</p>
          <p className={style.mbt2}>
            - Hai Bên cam kết và bảo đảm rằng khi một Bên đã thực hiện đầy đủ
            trách nhiệm theo Biên bản này, Bên còn lại sẽ không có bất kì khiếu
            nại hoặc tiến hành bất cứ hành động pháp lý nào khác nhằm chống lại
            hoặc gây ảnh hưởng/gây thiệt hại đến uy tín, danh dự, hình ảnh, tài
            sản… của Bên kia.{" "}
          </p>
          <p className={style.mbt2}>
            - Biên bản có hiệu lực ngay sau khi bàn giao sản phẩm và đủ chữ ký
            của cả Hai Bên. Biên bản này được lập thành 02 bản, Bên A giữ 01
            bản, Bên B giữ 01 bản có giá trị như nhau làm căn cứ thực hiện.
          </p>
        </div>
      </div>
      <div className={`${style.signature}`}>
        <div>
          <p className={`${style.mbt2} ${style.fw}`}>ĐẠI DIỆN BÊN A</p>
          <p className={`${style.italic}`}>
            {"("}Ký và ghi rõ họ tên{")"}
          </p>
        </div>
        <div>
          <p className={`${style.mbt2} ${style.fw}`}>ĐẠI DIỆN BÊN B</p>
          <p className={`${style.italic}`}>
            {"("}Ký và ghi rõ họ tên{")"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrintExportBorrowedGoods;
