import { Button, Table } from "@mantine/core";
import React from "react";
import { TblMfgBomHeader } from "../../../model/TblMfgBom";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";

const ModalChooseBom = ({
  dataBoms,
  handleChooseBom,
}: {
  dataBoms: TblMfgBomHeader[];
  handleChooseBom: (dataBom: TblMfgBomHeader, count: number) => void;
}) => {
  return (
    <div>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Chọn</Table.Th>
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
            <Table.Th>Ghi chú</Table.Th>
            <Table.Th>Ngày lập</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataBoms.map((data) => (
            <Table.Tr key={data.id}>
              <Table.Td>
                <Button
                  size="xs"
                  variant="filled"
                  onClick={() => handleChooseBom(data, dataBoms.length)}
                >
                  Chọn
                </Button>
              </Table.Td>
              <Table.Td>{data.itemCode}</Table.Td>
              <Table.Td>{data.iteamNameHeader}</Table.Td>
              <Table.Td>{data.uomName}</Table.Td>
              <Table.Td>{data.note}</Table.Td>
              <Table.Td>{formatDateTransfer(data.bomDate)}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </div>
  );
};

export default ModalChooseBom;
