import { Image, Container, Title, Text, Button, SimpleGrid } from '@mantine/core';
import image from './image.svg';
import classes from './NotFoundImage.module.css';
import { useNavigate } from 'react-router-dom';
export const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={{ base: 40, sm: 80 }} cols={{ base: 1, sm: 2 }}>
        {/* <Image src={image.src} className={classes.mobileImage} /> */}
        <div>
          <Title className={classes.title}>Trang không tồn tại !</Title>
          <Button variant="outline" size="md" mt="xl" className={classes.control} onClick={() => { navigate(-1) }}>
            Quay lại trang chủ
          </Button>
        </div>
        <Image src="https://www.hacom.vn/media/lib/404-3.png" className={classes.desktopImage} />
      </SimpleGrid>

    </Container>
  );
}