import {
  BASE_API_AUTH_URL,
  BASE_API_DELIVERY,
  BASE_API_Log,
  BASE_API_MDM,
  BASE_API_POS,
  BASE_API_PAYMENT,
} from "../../config";
import Repository from "../helper/HttpHelper";

export const _timerDelayClick: number = 3;
export const _timerDelaySearch: number = 1;
export const repositoryMaster = new Repository(BASE_API_AUTH_URL);
export const repositoryPos = new Repository(BASE_API_POS);
export const repositoryMdm = new Repository(BASE_API_MDM);
export const repositoryLog = new Repository(BASE_API_Log);
export const repositoryDelivery = new Repository(BASE_API_DELIVERY);
export const repositoryPayment = new Repository(BASE_API_PAYMENT);
export const pagination = true;
export const paginationPageSize = 25;
export const paginationPageSizeSelector = [25, 50, 100];

export const dataExchangeRate = [
  {
    calculation: "Tiền mặt VND tại CN",
    currencyType: "VND",
    value: 1,
    id: 111101,
  },
  {
    calculation: "Tiền mặt ngoại tệ tại Cty",
    currencyType: "USD",
    value: 24200,
    id: 111210,
  },
  {
    calculation: "Vàng bạc, kim khí quý, đá quý",
    currencyType: "GOLD",
    value: 7800000,
    id: 111301,
  },
];

export const RequiedCompont = ({ title }: { title: string }) => {
  return (
    <>
      <span>
        {title} <span style={{ color: "red" }}>(*)</span>
      </span>
    </>
  );
};
