import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconActivity,
  IconBuildingWarehouse,
  IconEdit,
  IconFunction,
  IconHandStop,
  IconManualGearbox,
  IconPlus,
  IconShoppingBag,
  IconTrash,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { _localization } from "../../config/location";
import { ListAuthozireModel } from "../../model/ListAuthozireModel";
import { MessageResponse } from "../../model/MessageResponse";
import { SelectListItem } from "../../model/SelectListItem";
import GroupAddRole from "./GroupAddRoleAuthozire";
import GroupRoleEdit from "./GroupRoleEdit";
import GroupsRoleAdd from "./GroupsRoleAdd";
import "../../Styles/tab.component.css";
import {
  _timerDelayClick,
  repositoryMaster,
} from "../../_base/_const/_constVar";
import GroupAddManufacturerAuth from "./GroupAddManufacturerAuth";
import GroupAddInventoryAuth from "./GroupAddInventoryAuth";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { useDebouncedState, useDebouncedValue } from "@mantine/hooks";

// Hàm sắp xếp danh sách cha con
function sortListByParentId(list: ListAuthozireModel[]): ListAuthozireModel[] {
  // Tạo một Map để lưu các phần tử cha và con
  const map = new Map<number, ListAuthozireModel>();

  // Tạo danh sách đã sắp xếp
  const sortedList: ListAuthozireModel[] = [];

  // Đưa tất cả các phần tử vào Map để dễ tìm kiếm
  list.forEach((item) => {
    map.set(item.id, item);
  });

  // Hàm đệ quy để thêm các phần tử cha và con vào danh sách đã sắp xếp
  function addItemAndChildren(item: ListAuthozireModel) {
    sortedList.push(item);

    // Tìm các phần tử con của phần tử hiện tại
    const children = list.filter((child) => child.parentId === item.id);

    // Đệ quy cho từng phần tử con
    children.forEach((child) => addItemAndChildren(child));
  }

  // Lặp qua danh sách để tìm phần tử cha (không có `parentId`) và bắt đầu quá trình đệ quy
  list.forEach((item) => {
    if (!item.parentId) {
      addItemAndChildren(item);
    }
  });

  return sortedList;
}

const GroupsRoleView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  //data and fetching state
  const [data, setData] = useState<ListAuthozireModel[]>([]);
  const [dataTemp, setDataTemp] = useState<ListAuthozireModel[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(0);
  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    try {
      const response = await repositoryMaster.get<
        MessageResponse<ListAuthozireModel[]>
      >("/api/v1/ListAuthozire/get-list");
      if (response && response.success) {
        const _data = sortListByParentId(response.data ?? []);
        setData(_data);
        setRowCount(response.totalCount);
        setDataTemp(_data);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  useEffect(() => {
    setRowCount(data.length);
  }, [data]);
  const activeData = async (status: boolean) => {
    if (selectIds.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryMaster.post<MessageResponse<boolean>>(
          "/api/v1/ListAuthozire/actives",
          {
            ids: selectIds,
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const activesData = async (status: boolean, id: string) => {
    if (!id || id.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        const res = await repositoryMaster.post<MessageResponse<boolean>>(
          "/api/v1/ListAuthozire/actives",
          {
            ids: [id],
            status: status,
          }
        );
        console.log(res);
        if (res && res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          await fetchData();
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      } catch (error) {
        console.error(error);
        return;
      }
  };
  const deletesData = async (ids: string[]) => {
    if (ids.length < 1)
      NotificationExtension.Success("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa !</Title>
            </>
          ),

          size: "550px",
          children: <>Bạn có thực sự muốn xóa ?</>,
          labels: { confirm: "Lưu lại", cancel: "Hủy bỏ" },
          onConfirm: async () => {
            const res = await repositoryMaster.post<MessageResponse<boolean>>(
              "/api/v1/ListAuthozire/delete",
              ids
            );
            if (res)
              if (res.success) {
                NotificationExtension.Success("Xóa thành công !");
                await fetchData();
              } else NotificationExtension.Fails("Xóa thất bại !");
          },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };
  function createItem() {
    setDeleteViewStatus(0);
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thêm mới !</Title>
        </>
      ),

      size: "550px",
      children: (
        <GroupsRoleAdd onClose={setDeleteViewStatus} load={deleteViewStatus} />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function editItem(id: string) {
    setDeleteViewStatus(0);
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chỉnh sửa !</Title>
        </>
      ),

      size: "550px",
      children: (
        <GroupRoleEdit
          id={Number.parseInt(id)}
          onClose={setDeleteViewStatus}
          load={deleteViewStatus}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const groupAddRoleModal = (idAuthozire: string) => {
    modals.closeAll();

    modals.openConfirmModal({
      title: "Danh sách chức năng",

      size: "100%",
      fullScreen: false,
      children: <GroupAddRole idAuthozire={idAuthozire}></GroupAddRole>,
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };
  const groupAddManufacturerModal = (idAuthozire: string) => {
    modals.closeAll();

    modals.openConfirmModal({
      title: "Danh sách hãng sản xuất",

      size: "80%",
      fullScreen: false,
      children: (
        <GroupAddManufacturerAuth
          idAuthozire={idAuthozire}
        ></GroupAddManufacturerAuth>
      ),
      labels: { confirm: "Confirm", cancel: "Hủy bỏ" },
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  const columns = React.useMemo<MRT_ColumnDef<ListAuthozireModel>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "name",
        header: "Tên chức năng",
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row && row.original?.parentId && row.original?.parentId > 0
              ? "----- " + renderedCellValue
              : renderedCellValue}
          </>
        ),
      },
      {
        accessorKey: "description",
        header: "Mô tả",
      },
      {
        accessorKey: "inActive",
        header: "Trạng thái",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(row.original.inActive ?? false)}>
              {row.original.inActive ? "Đang hoạt động" : "Dừng hoạt động"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "createBy",
        header: "Người tạo",
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo (UTC)",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        accessorKey: "action",
        header: "Phân quyền và thao tác",
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Tooltip label="Chức năng">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={() => {
                  groupAddRoleModal(row.original.id.toString());
                }}
                // disabled={
                //   !(
                //     (row.original.parentId ?? "").length > 0 ||
                //     (dataNguyenThuy.filter(
                //       (x) => x.parentId === row.original.id
                //     ).length === 0 &&
                //       (row.original.parentId ?? "").length === 0)
                //   )
                // }
              >
                <IconFunction size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Hàng hóa">
              <ActionIcon variant="light" aria-label="Settings" color="blue">
                <IconShoppingBag size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Hãng sản xuất">
              <ActionIcon
                onClick={() => {
                  groupAddManufacturerModal(row.original.id.toString());
                }}
                variant="light"
                aria-label="Settings"
                color="grape"
              >
                <IconManualGearbox size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editItem(row.original.id.toString());
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              label={
                row.original.inActive === true ? "Dừng hoạt động" : "Hoạt động"
              }
            >
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color={row.original.inActive === true ? "violet" : "green"}
                onClick={async () => {
                  await activesData(
                    !row.original.inActive,
                    row.original.id.toString()
                  );
                }}
              >
                {row.original.inActive === true ? (
                  <IconHandStop size={20} />
                ) : (
                  <IconActivity size={20} />
                )}
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deletesData([row.original.id.toString()]);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );
  const [openedMenu, setOpenedMenu] = useState(false);

  const initialExpandedRootRows = useMemo<MRT_ExpandedState>(
    () =>
      data
        .map((originalRow) => originalRow.id) //get all the root row ids, use recursion for additional levels
        .reduce((a, v) => ({ ...a, [v]: true }), {}), //convert to an object with all the ids as keys and `true` as values
    []
  );
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const [debounced, setValuedebounced] = useDebouncedState("", 500);
  useEffect(() => {
    if (debounced) {
      const searchResult = dataTemp.filter(
        (product) =>
          product.name?.toLowerCase().includes(debounced) ||
          product.description?.toLowerCase().includes(debounced)
      );
      setData(sortListByParentId(searchResult));
    } else {
      setData(sortListByParentId(dataTemp));
    }
  }, [debounced]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setValuedebounced(e.target.value ?? "");
            }}
          />
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              createItem();
            }}
          >
            Thêm mới
          </Button>

          <Button
            leftSection={<IconActivity size={14} />}
            color="green"
            variant="outline"
            onClick={async () => {
              await activeData(true);
            }}
            disabled={selectIds.length < 1}
          >
            Hoạt động
          </Button>
          <Button
            leftSection={<IconHandStop size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await activeData(false);
            }}
            disabled={selectIds.length < 1}
          >
            Dừng hoạt động
          </Button>
          <Button
            leftSection={<IconTrash size={14} />}
            color="red"
            variant="outline"
            onClick={async () => {
              await deletesData(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Xóa nhóm (đã chọn)
          </Button>
          <Button
            leftSection={<IconZoomReset size={14} />}
            color="violet"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Load data
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    // enableExpanding: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      expanded: initialExpandedRootRows,
      pagination: {
        pageIndex: 0,
        pageSize: 150,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["50", "100", "150"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default GroupsRoleView;
