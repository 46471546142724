"use strict";

import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
import { createRoot } from "react-dom/client";
import {
  AgGridReact,
  CustomCellRendererProps,
  CustomFilterProps,
  useGridFilter,
} from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconActivity,
  IconEdit,
  IconHandStop,
  IconPlus,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import {
  paginationPageSize,
  paginationPageSizeSelector,
  repositoryPos,
} from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import { TblfileReportModel } from "../../model/Report/TblfileReportModel";
import { modals } from "@mantine/modals";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { _localization } from "../../config/location";
import AddOrUpdateFileReport from "./AddOrUpdateFileReport";

export const AddOrUpdateFileReportView = () => {
  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);
  //data and fetching state
  const [data, setData] = useState<TblfileReportModel[]>([]);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //

  const [keySearch, setKeySearch] = useState<string>("");

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    try {
      let urlGet =
        "/api/v1/Report/get-add-or-update-file?skip=" +
        pagination.pageIndex * pagination.pageSize +
        "&take=" +
        pagination.pageSize;

      if (keySearch && keySearch.length > 0)
        urlGet = urlGet + "&keySearch=" + keySearch;
      const response = await repositoryPos.get<
        MessageResponse<TblfileReportModel[]>
      >(urlGet);
      if (response && response.success) {
        setData(response.data ?? []);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  function createItem(id?: number, model?: TblfileReportModel) {
    const _id = id && id > 0 ? id : 0;
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thêm mới !</Title>
        </>
      ),

      size: "550px",
      children: (
        <AddOrUpdateFileReport model={model} id={_id} onLoad={fetchData} />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const columns = React.useMemo<MRT_ColumnDef<TblfileReportModel>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },
      {
        accessorKey: "name",
        header: "Tên",
      },
      {
        accessorKey: "router",
        header: "Đường dẫn",
      },
      {
        accessorKey: "createBy",
        header: "Người tạo",
      },
      {
        accessorKey: "action",
        header: "Phân quyền và thao tác",
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  createItem(row.original.id,row.original);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setKeySearch(e.target.value ?? "");
            }}
          />
          <Button
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              createItem();
            }}
          >
            Thêm mới
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableRowNumbers: true,
    localization: _localization,
    enableRowSelection: true,
    // getRowId: (row) => row.id.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["50", "100", "150"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
