import "./tab.component.css";

import { Paper, Tabs } from "@mantine/core";
import { useState } from "react";

import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import { LoginHistory } from "./LoginHistory/LoginHistory";
import { ApproveLogin } from "./ApproveLogin/ApproveLogin";
import { ConfigSystem } from "./ConfigSystem/ConfigSystem";

export const HistoryAndApprove = () => {
  const [activeTab, setActiveTab] = useState<string | null>("loginhistory");

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Paper shadow="md" radius="lg">
        <Tabs p={"xs"} value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="loginhistory">
              Lịch sử đăng nhập và đăng xuất
            </Tabs.Tab>
            <Tabs.Tab value="approvelogin">
              Phê duyệt thiết bị đăng nhập{" "}
            </Tabs.Tab>
            <Tabs.Tab value="configsystem">Cấu hình hệ thống</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="loginhistory">
            {activeTab === "loginhistory" ? <LoginHistory /> : <></>}
          </Tabs.Panel>

          <Tabs.Panel value="approvelogin">
            {activeTab === "approvelogin" ? <ApproveLogin /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="configsystem">
            {activeTab === "configsystem" ? <ConfigSystem /> : <></>}
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </>
  );
};
