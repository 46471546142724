import { Flex, Paper, Text } from "@mantine/core";
import { IconUser, IconUsers } from "@tabler/icons-react";

const SelectOption = ({
  handleSelectedOption,
}: {
  handleSelectedOption: any;
}) => {
  return (
    <>
      <Text fw={"bold"} m={"5px 0px"}>
        Chọn loại khách hàng
      </Text>
      <Flex gap={"md"}>
        <Paper shadow="md" style={{ borderRadius: "10px" }}>
          <Flex
            bd={"1px solid #eeeeee"}
            direction={"column"}
            align={"center"}
            gap={"md"}
            p={10}
            w={200}
            style={{ borderRadius: "10px", cursor: "pointer" }}
            onClick={() => {
              handleSelectedOption(1);
            }}
          >
            <IconUser size={50} />
            <Text>Khách hàng cá nhân</Text>
          </Flex>
        </Paper>
        <Paper shadow="md" style={{ borderRadius: "10px", cursor: "pointer" }}>
          <Flex
            bd={"1px solid #eeeeee"}
            direction={"column"}
            align={"center"}
            gap={"md"}
            p={10}
            w={200}
            style={{ borderRadius: "10px", cursor: "pointer" }}
            onClick={() => handleSelectedOption(2)}
          >
            <IconUsers size={50} />
            <Text>Khách hàng doanh nghiệp</Text>
          </Flex>
        </Paper>
      </Flex>
    </>
  );
};

export default SelectOption;
