import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  Menu,
  NumberFormatter,
  Select,
  Switch,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState, useDisclosure, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCheck,
  IconMaximize,
  IconMaximizeOff,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { anyAsciiCode } from "../../../../_base/helper/AnyAscii";
import { _localization } from "../../../../config/location";
import { sky_blue } from "../../../../const/variables";
import { MessageResponse } from "../../../../model/MessageResponse";
import { OrderDetailOutWardNew } from "../../../../model/SaleOrderModel";
import {
  TblInventorySerialItemDetailOnHand,
  TblInventorySerialOrderDetailOnHand,
} from "../../../../model/TblInventorySerialOnHand";
import {
  detailOrderForInWardModel,
  TblInWard,
  TblInwardDetail,
} from "../../../../model/TblInWard";
import { getTblInventoryTransactionTypeInWard } from "../../../../service/getSelectApi";
import RequestTransferInWard from "../components/RequestTransferInWard";
import { getActionFromPath } from "../utils";
import RequestOrderReturnInWard from "../components/RequestOrderReturnInWard";
import RequestFinishedProductionInWard from "../components/RequestFinishedProductionInWard";
import { visibleCol, visibleColDetail } from "../constants";
import RequestVendorInWard from "../components/RequestVendorInWard";
import AddProductBySerialModal from "../components/AddProductBySerialModal";
import { outward } from "../../WarehouseOutWard/services";

interface TblInventorySerialOrderDetailOnHandEdit
  extends TblInventorySerialOrderDetailOnHand {
  type?: string;
  poPrice1?: number;
  note?: string | null;
}

const WarehouseInWardAction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sourceCode = location.state && location.state?.sourceCode;
  const sourceType = location.state && location.state?.sourceType;
  const transactionCode = location.state && location.state?.transactionCode;

  const locker = location.state && location.state.isLocked;
  const id = location.state && location.state.id;
  const branchCode = location.state && location.state.branchCode;
  const action = getActionFromPath(location.pathname ?? "");
  //#region render ui outward
  const getInWardProps = (additionalProps = {}) => ({
    dataSearchSourceCodeOption,
    dataPosition,
    dataInWard: dataInWard?.tblItemTransactionHeaderCommand,
    dataOrderDetail: dataOrderDetail?.tblInventoryTransactionModel,
    userInfo,
    handleChangeValueInput,
    setSourceCode,
    handleKeyDownSourceCode,
    sourceCode,
    isSave,
    searchSourceCode,
    setSearchSourceCode,
    isLocked: locker,
    haveSourceCode: sourceCode,
    ...additionalProps,
  });

  // Thong tin chung nhap lien chi nhanh
  const requestTransfetInWard = () => {
    return (
      <RequestTransferInWard
        {...getInWardProps({
          haveStatus: true,
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };

  // Thong tin chung nhap noi bo
  const requestTransferInternalInWard = () => {
    return (
      <RequestTransferInWard
        {...getInWardProps({
          haveStatus: true,
          isInternal: true,
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };
  // Thong tin chung nhap noi bo serial
  const requestTransferInternalSerialInWard = () => {
    return (
      <RequestTransferInWard
        {...getInWardProps({
          haveStatus: true,
          isInternal: true,
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };

  // Thong tin chung nhap dieu chuyen noi bo
  const serialInWard = () => {
    return <RequestTransferInWard {...getInWardProps()} />;
  };

  // Thong tin chung nhap dieu chuyen noi bo serial
  const returnOrderInWard = () => {
    return (
      <RequestOrderReturnInWard
        {...getInWardProps({
          isInternal: true,
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };

  // Thong tin chung nhap hang NCC
  const returnVendorInWard = () => {
    return (
      <RequestVendorInWard
        {...getInWardProps({
          isInternal: true,
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };

  // Thong tin chung nhap thanh pham
  const requestFinishedProductInWard = () => {
    return (
      <RequestFinishedProductionInWard
        {...getInWardProps({
          haveStatus: true,
          isEdit: ["EDIT", "DETAIL"].includes(action),
        })}
      />
    );
  };

  //#region state
  const outWardEntity = {
    sourceId: 39,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: sourceCode || transactionCode || "",
      sourceType: sourceType || "",
      inventoryId: null,
      subInvId: null,
      departmentId: 0,
      status: "NEW",
      description: "",
      atribute1: "",
      atribute2: "",
      atribute3: "",
      atribute4: "",
      atribute5: "",
      atribute6: "",
      atribute7: "",
      atribute8: "",
      atribute9: "",
      atribute10: "",
      createBy: 0,
      createName: "",
      createDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    serials: [],
  };

  const [dataInWard, setDataInWard] = useState<TblInWard>(outWardEntity);
  const [handleItemCode, setHandleItemCode] = useState<boolean>(false);

  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();

  //table state
  const entityOrderInWard = {
    tblInventoryTransactionModel: {},
    inventoryTransactionRequestSourceModels: [],
    invCode: null,
    invId: null,
    invName: null,
    outWardCode: null,
    receiver: null,
    sourceCode: null,
    sourceId: null,
    subInvId: null,
    toAddess: null,
  };

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataOrderDetail, setDataOrderDetail] =
    useState<OrderDetailOutWardNew>(entityOrderInWard);

  const [serial, setSerial] = useState("");
  const [keySearch, setKeySearch] = useDebouncedState("", 300);
  const [searchSourceCode, setSearchSourceCode] = useDebouncedState("", 300);
  const [dataItem, setDataItem] = useState<
    TblInventorySerialItemDetailOnHand[]
  >([]);
  const [dataSearchSourceCodeOption, setDataSearchSourceCodeOption] = useState<
    any[]
  >([]);
  const [dataSerialOnHand, setDataSerialOnHand] = useState<
    TblInventorySerialOrderDetailOnHandEdit[]
  >([]);
  const [dataPosition, setDataPosition] = useState<any[]>([]);

  const [checkDuplicateCode, setCheckDuplicateCode] = useState<string[]>([]);
  const [isExceedQuantity, setIsExceedQuantity] = useState(false);
  const [transactionTempId, setTransactionTempId] = useState(0);
  const [isSave, setIsSave] = useState(false);
  const [sourceCodeRef, setSourceCode] = useState(
    location.state && location.state.sourceCode
  );
  const [isLockForOrder, setIsLockForOrder] = useState(true);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const sidePanelRef = React.useRef<HTMLDivElement>(null);
  const [isCollapse, { toggle: toggleCollapse }] = useDisclosure(false);

  const [mainContentWidth, setMainContentWidth] = useState(0);

  //#endregion

  //#region function

  const renderMainInWard = () => {
    const _sourceType =
      sourceType ?? dataInWard.tblItemTransactionHeaderCommand.sourceType;
    switch (_sourceType) {
      case "NHAP_HANG_NCC":
        return returnVendorInWard();
      case "NHAP_DIEU_CHUYEN_CHI_NHANH":
        return requestTransfetInWard();
      case "NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return serialInWard();
      case "NHAP_DIEU_CHUYEN_NOI_BO":
        return requestTransferInternalInWard();
      case "NHAP_DIEU_CHUYEN_NOI_BO_SERIAL":
        return requestTransferInternalSerialInWard();
      case "NHAP_HANG_DOI_TRA":
        return returnOrderInWard();
      case "NHAP_THANH_PHAM_SAN_XUAT":
        return requestFinishedProductInWard();
      default:
        return requestTransfetInWard();
    }
  };

  // func render ui => title
  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap={20}>
              <Text c={"red"} fw={700}>
                Loại phiếu
              </Text>
              <Select
                searchable
                clearable
                w={200}
                size="xs"
                placeholder="Chọn loại phiếu"
                disabled={
                  dataInWard.tblItemTransactionHeaderCommand.status ===
                    "LOCK" ||
                  sourceCode ||
                  isSave
                }
                data={dataInventoryTransactionType}
                value={
                  dataInWard.tblItemTransactionHeaderCommand.sourceType?.toString() ||
                  ""
                }
                onChange={(value) => {
                  handleChangeValueInput(value || "", "sourceType");
                }}
              />
            </Group>
            <Tooltip
              label={isCollapse ? "Mở rộng" : "Thu gọn"}
              position="bottom"
            >
              <Group align="center">
                <Switch
                  size="sm"
                  color={isCollapse ? "red" : "green"}
                  checked={!isCollapse}
                  onChange={toggleCollapse}
                  label={isCollapse ? "Thu gọn (Ctrl+M)" : "Mở rộng (Ctrl+M)"}
                  thumbIcon={
                    !isCollapse ? (
                      <IconMaximize
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    ) : (
                      <IconMaximizeOff
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    )
                  }
                />
              </Group>
            </Tooltip>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
            {dataInWard?.tblItemTransactionHeaderCommand?.sourceType !==
              "NHAP_THANH_PHAM_SAN_XUAT" && (
              <Button
                type="submit"
                color={sky_blue.base}
                disabled={
                  dataInWard.tblItemTransactionHeaderCommand.status ===
                    "LOCK" ||
                  locker ||
                  dataSerialOnHand?.length === 0
                }
                onClick={() => {
                  setIsSave(true);
                  handleCreateInWard("OPEN");
                }}
              >
                Lưu (Ctrl+S)
              </Button>
            )}
            <Button
              type="button"
              color="green"
              disabled={
                dataInWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                locker ||
                isLockForOrder ||
                dataSerialOnHand?.length === 0
              }
              onClick={() => {
                handleCreateInWard("LOCK", true);
              }}
            >
              Hoàn thành (Ctrl+Q)
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  // func render ui => select item outward
  const TableSelect = () => (
    <Table.ScrollContainer minWidth={600} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Tuổi tồn</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
            {sourceType === "NHAP_HANG_NCC" && <Table.Th>Hủy</Table.Th>}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItem?.map((item, index) => (
            <Table.Tr
              key={item.serial}
              bg={
                checkDuplicateCode.some((select) => select === item.serial)
                  ? "var(--mantine-color-blue-light)"
                  : undefined
              }
            >
              <Table.Td>
                {sourceType === "NHAP_HANG_NCC" ||
                sourceType === "NHAP_HANG_NCC" ? (
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      if (!checkDuplicateCode.includes(item.itemCode)) {
                        notFetchDataSerialOnhand(item);
                      }
                    }}
                    disabled={checkDuplicateCode.includes(item.itemCode)}
                  >
                    {checkDuplicateCode.includes(item.itemCode)
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                ) : (
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      fetchDataSerialOnHand(item.serial);
                    }}
                    disabled={checkDuplicateCode.includes(item.serial)}
                  >
                    {checkDuplicateCode.includes(item.serial)
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                )}
              </Table.Td>
              <Table.Td>{item.itemCode}</Table.Td>
              <Table.Td>
                <Tooltip label={item.itemName}>
                  <Text size="sm" w={150} lineClamp={2} truncate="end">
                    {item.itemName}
                  </Text>
                </Tooltip>
              </Table.Td>
              <Table.Td>{item.serial}</Table.Td>
              <Table.Td>1</Table.Td>
              <Table.Td>
                <Text size="sm" w={125} truncate="end">
                  {/* {item.subInvName} */}
                </Text>
              </Table.Td>
              <Table.Td>1</Table.Td>
              <Table.Td>
                {sourceType === "NHAP_HANG_NCC" && (
                  <Tooltip label="Hủy">
                    <ActionIcon
                      variant="transparent"
                      color="red"
                      onClick={() =>
                        handleCancelSelection(item.itemCode, item.serial)
                      }
                    >
                      <IconTrash size={20} stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                )}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
        {dataItem?.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  const isValidSerial = (input: string): boolean => {
    const pattern = /^[a-zA-Z0-9]+$/;
    if (input.length === 0) return true;
    return pattern.test(input);
  };

  // func render ui => text input transaction header
  const TextTransactionHeader = () => (
    <TextInput
      flex={3}
      placeholder="Nhập mã vạch"
      size="xs"
      disabled={dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"}
      value={serial}
      onKeyDown={(e) => {
        if (e.key === " ") {
          e.preventDefault();
          return;
        }
        if (e.key === "Enter" && e.currentTarget.value.trim() !== "") {
          if (
            dataInWard?.tblItemTransactionHeaderCommand?.sourceType ===
            "NHAP_HANG_NCC"
          ) {
            handleSerialInput(serial);
          } else handleAddProduct();
        }
      }}
      // error={
      //   !isValidSerial(anyAsciiCode(serial))
      //     ? "Serial không hợp lệ !"
      //     : undefined
      // }
      onChange={(e) =>
        setSerial(anyAsciiCode(e?.currentTarget?.value).toLocaleUpperCase())
      }
    />
  );

  // func render ui => select item transaction header
  const MenuDataOutWart = () => (
    <Menu
      opened={
        keySearch.length > 2 &&
        dataItem?.length !== 0 &&
        dataInWard?.tblItemTransactionHeaderCommand?.sourceType !==
          "NHAP_HANG_NCC"
      }
      trapFocus={false}
      shadow="md"
      width={"800"}
      position="bottom-start"
    >
      <Menu.Target>
        <TextInput
          placeholder="Hoặc tìm kiếm mã sản phẩm"
          size="xs"
          disabled={
            dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"
          }
          value={keySearch}
          onChange={async (e) => {
            const _key = e.target.value ?? "";
            setKeySearch(_key);
          }}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <TableSelect />
      </Menu.Dropdown>
    </Menu>
  );

  const RenderTransactionHeader = (type?: number) => {
    switch (type) {
      case 1:
        return sourceType === "NHAP_HANG_NCC"
          ? MenuDataOutWart()
          : TextTransactionHeader();
      case 2:
        return sourceType === "NHAP_HANG_NCC"
          ? TextTransactionHeader()
          : MenuDataOutWart();
      default:
        return null; // Trường hợp không khớp
    }
  };

  const RenderAddBarcodeButton = () => {
    if (sourceType === "NHAP_HANG_NCC") return null;
    const isDisabled =
      serial?.length < 3 ||
      dataInWard.tblItemTransactionHeaderCommand.status === "LOCK";
    return (
      <Grid.Col span={{ base: 6, md: 2, lg: 2 }}>
        <Button
          leftSection={<IconPlus size={14} />}
          size="xs"
          w="100%"
          onClick={handleAddProduct}
          disabled={isDisabled}
        >
          Thêm barcode
        </Button>
      </Grid.Col>
    );
  };

  const RenderSerialButtons = () => {
    if (sourceType !== "NHAP_HANG_NCC") return null;
    const isDisabled = serial?.length < 3;

    return (
      <>
        <Grid.Col span={{ base: 6, md: 2, lg: 2 }}>
          <Button
            onClick={() => handleSerialInput(serial)}
            size="xs"
            w="100%"
            disabled={isDisabled}
            leftSection={<IconPlus size={14} />}
          >
            Thêm
          </Button>
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 2, lg: 2 }}>
          <Button
            onClick={() => {
              handleAddListProduct();
            }}
            size="xs"
            w="100%"
            disabled={!selectedItem}
          >
            Nhập serial theo chuỗi
          </Button>
        </Grid.Col>
      </>
    );
  };

  const handleCancelSelection = (itemCode: string, serial: string) => {
    // setDataSerialOnHand((prevData) =>
    //   prevData.filter(
    //     (item) => item.itemCode !== itemCode && item.serial !== serial
    //   )
    // );
    setSelectedItem([""]);
    setKeySearch("");
    setSerial("");
    setCheckDuplicateCode([""]);
    setCheckDuplicateCode((prevCodes) =>
      prevCodes.filter((code) => code !== itemCode && code !== serial)
    );
  };

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleKeyDownSourceCode = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      if (dataSearchSourceCodeOption.length === 1) {
        handleChangeValueInput(searchSourceCode || "", "sourceCode");
      }
    }
  };

  const handleChangeValueInput = (
    value: null | string | boolean,
    key: string
  ) => {
    setDataInWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        [key]: value,
      },
    }));
  };

  const handleAddProduct = () => {
    if (dataInWard.tblItemTransactionHeaderCommand?.sourceCode) {
      if (checkDuplicateCode.length === 0) {
        fetchDataSerialOnHand();
        setSerial("");
      } else if (checkDuplicateCode.length > 0) {
        if (checkDuplicateCode.includes(serial)) {
          NotificationExtension.Fails("Mã Serial đã được quét !");
        } else {
          fetchDataSerialOnHand();
          setSerial("");
        }
      }
    } else {
      NotificationExtension.Fails("Chưa nhập số chứng từ");
    }
  };

  const handleAddListProduct = () => {
    modals.openConfirmModal({
      size: "auto",
      title: (
        <>
          <Title order={5}>Nhập serial theo chuỗi !</Title>
        </>
      ),
      children: (
        <AddProductBySerialModal
          onSubmit={handleSerialsInput}
          item={selectedItem}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const deleteItemSerial = (serial: string) => {
    setDataSerialOnHand((prevData) => {
      return prevData.filter((item) => item.serial !== serial);
    });
    setCheckDuplicateCode((prevData) =>
      prevData.filter((item) => item !== serial)
    );
  };

  const checkVarianceItem = (items: detailOrderForInWardModel[]) => {
    let variance = false;
    let variaceItems: { itemName: string; varianceQuantity: number }[] = [];
    items.forEach((item) => {
      const _varianceQuantity =
        (item.scannedQuantity || 0) - (item.primaryQuantity || 0);
      if (_varianceQuantity !== 0) {
        variaceItems = [
          ...variaceItems,
          {
            itemName: item.itemName,
            varianceQuantity: _varianceQuantity,
          },
        ];
        variance = true;
      }
    });
    return {
      isVariance: variance,
      variaceItems: variaceItems,
    };
  };

  const openCofirmModal = (variaceItems: any[]): Promise<boolean> => {
    return new Promise((resolve) => {
      modals.openConfirmModal({
        size: "40%",
        children: (
          <Box component="form" mx="auto" style={{ position: "relative" }}>
            <Text size="20px" fw={"bold"} my={10}>
              Có những sản phẩm sau có lệch?
            </Text>
            <Box mt={3}>
              <Table
                highlightOnHover
                striped
                withColumnBorders
                horizontalSpacing="md"
                verticalSpacing="sm"
                bd={"1px solid #E0E0E0"}
              >
                <thead>
                  <tr style={{ backgroundColor: "#F8F9FD" }}>
                    <th
                      style={{
                        textAlign: "left",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Tên sản phẩm
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Số lượng lệch
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {variaceItems?.map((item, index) => {
                    const varianceQuantity =
                      sourceType === "NHAP_HANG_NCC"
                        ? item.scannedQuantity
                        : item.varianceQuantity || 0;
                    const primaryQuantity = item.primaryQuantity || 0;

                    //Tổng các số lượng lệch
                    const deviationAmount =
                      sourceType === "NHAP_HANG_NCC"
                        ? varianceQuantity - primaryQuantity
                        : varianceQuantity - primaryQuantity;

                    return (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "12px",
                            fontWeight: "bold",
                            textAlign: "left",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {item.itemName || item.itemCode}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "red",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {deviationAmount}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Box>

            <Group
              justify="end"
              mt="xs"
              style={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
              }}
            >
              <Button
                type="button"
                color="gray"
                onClick={() => {
                  handleChangeValueInput("", "status");
                  modals.closeAll();
                  resolve(false);
                }}
                leftSection={<IconX size={18} />}
              >
                Hủy
              </Button>
              {sourceType !== "NHAP_HANG_NCC" && (
                <Button
                  onClick={() => {
                    handleChangeValueInput("LOCK", "status");
                    modals.closeAll();
                    resolve(true);
                  }}
                  color={sky_blue.base}
                  leftSection={<IconCheck size={18} />}
                >
                  Xác nhận
                </Button>
              )}
            </Group>
          </Box>
        ),

        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
        onClose() {
          handleChangeValueInput("", "status");
        },
      });
    });
  };

  const checkExceedQuantityFound = (data: any[]) => {
    const _sourceType = dataInWard.tblItemTransactionHeaderCommand.sourceType;
    const handeSupplierRequest = _sourceType === "NHAP_HANG_NCC";

    const _exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = data
            .filter((item) => {
              const checkItem =
                item.serial === order.serial ||
                (handeSupplierRequest && item.itemCode === order.itemCode);
              return checkItem && !item.type;
            })
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

            return remainQuantity < 0;
          }

          return false;
        }
      );
    if (_exceedQuantityFound) {
      return true;
    }
    return false;
  };

  const handleCreateInWard = async (status: string, isLocked?: boolean) => {
    if (dataSerialOnHand.some((item) => item.warning)) {
      NotificationExtension.Fails("Có sản phẩm không hợp lệ");
      return;
    }

    if (
      dataSerialOnHand.some(
        (item) => item.locatorId == null || item.locatorId === ""
      )
    ) {
      NotificationExtension.Fails("Có sản phẩm chưa có vị trí");
      return;
    }

    if (isExceedQuantity) {
      NotificationExtension.Fails("Có sản phẩm vượt quá số lượng cần nhập");
      return;
    }
    if (
      dataInWard?.tblItemTransactionHeaderCommand.description &&
      dataInWard?.tblItemTransactionHeaderCommand.description.length > 300
    ) {
      NotificationExtension.Fails("Ghi chú phiếu nhập không quá 300 ký tự");
      return;
    }

    setKeySearch("");
    handleChangeValueInput(status, "status");

    const serials = dataSerialOnHand?.map((item) => ({
      serialStatus: item.serialStatus,
      itemCode: item.itemCode,
      primaryQuantity: item.primaryQuantity,
      subQuantity: item.subQuantity ?? 0,
      serial: item.serial,
      invId:
        dataOrderDetail?.tblInventoryTransactionModel?.toInvId ??
        dataInWard?.tblItemTransactionHeaderCommand?.subInvId,
      note: item.note,
      locatorId: item.locatorId,
    }));

    const attributes = getAttributes(
      dataInWard.tblItemTransactionHeaderCommand.sourceType
    );

    const dataCreate = {
      ...dataInWard,
      tblItemTransactionHeaderCommand: {
        ...dataInWard.tblItemTransactionHeaderCommand,
        createDate: dataInWard.tblItemTransactionHeaderCommand?.createDate,
        createBy: dataInWard.tblItemTransactionHeaderCommand?.createBy,
        createName: dataInWard.tblItemTransactionHeaderCommand?.createName,
        createRoleName:
          dataInWard.tblItemTransactionHeaderCommand?.createRoleName,
        transactionId:
          transactionTempId ||
          dataInWard.tblItemTransactionHeaderCommand.transactionId,
        transactionCode:
          dataOrderDetail.tblInventoryTransactionModel.transactionCode,
        transactionDate:
          dataInWard.tblItemTransactionHeaderCommand.transactionDate,
        sourceCode:
          dataOrderDetail.tblInventoryTransactionModel.sourceCode || sourceCode,
        sourceType: dataInWard.tblItemTransactionHeaderCommand.sourceType,
        branchId: dataOrderDetail?.tblInventoryTransactionModel?.toBranchId,
        subInvId:
          dataOrderDetail?.tblInventoryTransactionModel?.toInvId ?? 6014,
        departmentId: dataInWard.tblItemTransactionHeaderCommand?.departmentId,
        status,
        description: dataInWard.tblItemTransactionHeaderCommand.description,
        subSourceId: dataOrderDetail.tblInventoryTransactionModel?.subSourceId,
        toPositionId: dataInWard.tblItemTransactionHeaderCommand?.toPositionId,
        ...attributes,
      },
      serials,
      isLock: isLocked,
    };

    const url = getInwardApiUrl(
      "create",
      dataInWard.tblItemTransactionHeaderCommand.sourceType
    );

    try {
      if (status === "LOCK") {
        const variance = checkVarianceItem(
          dataOrderDetail?.inventoryTransactionRequestSourceModels
        );
        if (variance.isVariance) {
          const confirm = await handleMismatch();
          if (!confirm) {
            handleChangeValueInput("OPEN", "status");
            return;
          }
        }
        const lockResponse = await postInwardRequest(url, dataCreate);
        if (lockResponse?.success) {
          handleChangeValueInput("LOCK", "status");
          handleChangeValueInput(Date().toString(), "finishDate");
          NotificationExtension.Success("Khóa phiếu nhập kho thành công");
        } else {
          handleChangeValueInput("", "status");
        }
        return;
      }

      const response = await postInwardRequest(url, dataCreate);
      if (response && status === "OPEN") {
        handleChangeValueInput("OPEN", "status");
        setTransactionTempId(response?.data?.data);
        NotificationExtension.Success("Lưu phiếu nhập kho thành công");
      } else {
        handleChangeValueInput("", "status");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMismatch = async () => {
    let hasUnmatchedItems = false;
    const handelBill = dataOrderDetail?.inventoryTransactionRequestSourceModels;

    dataSerialOnHand.forEach((serialOnHand) => {
      const primaryQuantity = serialOnHand.primaryQuantity ?? 0;

      const matchingItem = handelBill?.find((item) =>
        sourceType === "NHAP_HANG_NCC"
          ? item.itemCode === serialOnHand.itemCode
          : item.serial === serialOnHand.serial
      );

      if (matchingItem) {
        const difference = matchingItem.primaryQuantity - primaryQuantity;
        if (difference !== 0) {
          hasUnmatchedItems = true;
        }
      }
    });

    const unmatchedItems = handelBill?.filter(
      (itemInWard) =>
        !dataSerialOnHand.some(
          (serialOnHand) => serialOnHand.serial === itemInWard.serial
        )
    );

    if (unmatchedItems?.length) {
      hasUnmatchedItems = await openCofirmModal(unmatchedItems);
    }

    return hasUnmatchedItems;
  };

  const postInwardRequest = async (url: string, dataCreate: any) => {
    const response = await repositoryPos.post<MessageResponse<any>>(
      url,
      dataCreate
    );
    if (response?.httpStatusCode === 200 && response?.success) {
      return response;
    } else {
      return null;
    }
  };

  const fetchDataSerialOnHand = async (serialCode?: string) => {
    const _sourceCode =
      dataInWard.tblItemTransactionHeaderCommand?.sourceCode || sourceCode;
    let url = `?SourceCode=${_sourceCode}&Type=${
      dataInWard.tblItemTransactionHeaderCommand.sourceType
    }&Serial=${serialCode || serial}`;

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInventorySerialOrderDetailOnHand>
      >(`api/v1/TblItemTransaction/search-serial-item-transaction${url}`);

      if (response && response.success) {
        const responseData = response.data;

        if (responseData.warning) {
          NotificationExtension.Fails(responseData.warning);
        } else {
          const dataDetail = {
            ...responseData,
            subInvName: responseData.invName,
            note: null,
            locatorId:
              dataInWard?.tblItemTransactionHeaderCommand?.toPositionId,
          };
          const checkData = [...dataSerialOnHand, { ...dataDetail }];
          if (checkExceedQuantityFound(checkData)) {
            NotificationExtension.Warn(
              "Sản phẩm vượt quá số lượng cần nhập, vui lòng kiểm tra lại"
            );
          } else {
            setDataSerialOnHand((prevData) => [...prevData, { ...dataDetail }]);
            setCheckDuplicateCode((prev) => [...prev, serialCode || serial]);
          }
        }
      } else {
        NotificationExtension.Fails(response?.message || "");
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
  };

  const fetchDataPosition = async (fromInvId: number) => {
    try {
      const response = await outward.getSelectItemPosition(fromInvId);

      if (response?.success && Array.isArray(response.data)) {
        const mappedData = response.data.map((p) => ({
          label: p.positionName?.toString() || "",
          value: p.id?.toString() || "",
          code: p.positionCode?.toString() || "",
        }));

        setDataPosition(mappedData);
      } else {
        setDataPosition([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    let url = getInwardApiUrl(null, sourceType);
    try {
      if (transactionCode) {
        url += `?transactionCode=${transactionCode}`;
      } else {
        url += `?id=${id}`;
      }
      const response = await repositoryPos.get<
        MessageResponse<TblInwardDetail>
      >(`${url}`);
      if (response?.success && response?.data) {
        setDataInWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand:
            response?.data?.tblInventoryTransactionModel,
        }));
        setDataOrderDetail((prevData) => ({
          ...prevData,
          inventoryTransactionRequestSourceModels:
            response?.data?.inventoryTransactionRequestSourceModels?.map(
              (order) => {
                const matchingItem = dataSerialOnHand
                  .filter(
                    (item) =>
                      item.serial === order.serial ||
                      item.itemCode === order.itemCode
                  )
                  .map((item) => item.primaryQuantity || 0);

                const totalQuantity = matchingItem.reduce(
                  (acc, curr) => acc + curr,
                  0
                );
                const orderQuantity = order.primaryQuantity ?? 0;
                const orderTransferQuantity = order.transactionQuantity ?? 0;
                const remainQuantity =
                  orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

                return {
                  ...order,
                  scannedQuantity: totalQuantity,
                  remainQuantity: remainQuantity,
                };
              }
            ),
          tblInventoryTransactionModel:
            response?.data?.tblInventoryTransactionModel,
        }));
        setDataSerialOnHand(
          response?.data.inventoryTransactionSerialModels.map((item) => ({
            ...item,
            primaryQuantity: 1,
            type: "scanned",
          })) || []
        );
        setCheckDuplicateCode((prev) => [
          ...prev,
          ...response.data.inventoryTransactionSerialModels
            .map((item) => item.serial)
            .filter((serial): serial is string => serial !== null),
        ]);
        if (response?.data?.tblInventoryTransactionModel?.toInvId)
          fetchDataPosition(
            response?.data?.tblInventoryTransactionModel?.toInvId
          );
      } else {
        setDataOrderDetail(entityOrderInWard);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderDetail(entityOrderInWard);
    }
  };

  const getInwardApiUrl = (
    submitAction: string | null,
    sourceType: string | null
  ): string => {
    const _action = submitAction ?? action.toLowerCase();
    switch (sourceType) {
      case `NHAP_HANG_NCC`:
        return `/api/v1/Inward/${_action}-in-ward-po`;
      case `NHAP_DIEU_CHUYEN_CHI_NHANH`:
        return `/api/v1/Inward/${_action}-in-ward-request-transfer`;
      case `NHAP_DIEU_CHUYEN_CHI_NHANH_SERIAL`:
        return `/api/v1/Inward/${_action}-request-transfer-internal-inward-serial`;
      case `NHAP_DIEU_CHUYEN_NOI_BO`:
        return `/api/v1/Inward/${_action}-request-transfer-internal-inward`;
      case `NHAP_DIEU_CHUYEN_NOI_BO_SERIAL`:
        return `/api/v1/Inward/${_action}-request-transfer-internal-inward-serial`;
      case `NHAP_HANG_DOI_TRA`:
        return `/api/v1/Inward/${_action}-in-ward-return`;
      case `NHAP_THANH_PHAM_SAN_XUAT`:
        return `/api/v1/Inward/${_action}-in-ward-mfg-finished-goods`;
      case `NHAP_KHO_BAO_HANH`:
        return `/api/v1/Outward/${_action}-warranty-out-ward`;
      case "NHAP_THAO_DO":
        return `/api/v1/Inward/${_action}-common`;
      case "NHAP_DIEU_CHUYEN_LINH_KIEN_SAN_XUAT":
        return `/api/v1/Inward/${_action}-common`;
      case "NHAP_MUON_BAO_HANH":
        return `/api/v1/Inward/${_action}-common`;
      default:
        throw new Error("Unknown source type");
    }
  };

  const getAttributes = (
    sourceType: string | null
  ): { attribute4: string; attribute5: string } => {
    const { fromInvName, cusCode, cusName, toInvName } =
      dataOrderDetail?.tblInventoryTransactionModel || {};

    switch (sourceType) {
      case "NHAP_HANG_NCC":
      case "NHAP_HANG_DOI_TRA":
        return {
          attribute4: fromInvName,
          attribute5: `${cusCode} - ${cusName}`,
        };
      default:
        return {
          attribute4: fromInvName,
          attribute5: toInvName,
        };
    }
  };

  const fetchDataInWard = async () => {
    const _sourceCode =
      dataInWard?.tblItemTransactionHeaderCommand?.sourceCode ||
      searchSourceCode;
    const _sourceType =
      dataInWard?.tblItemTransactionHeaderCommand?.sourceType || sourceType;

    let url = getInwardApiUrl(null, _sourceType);
    url += `?prefix=NK&SourceCode=${_sourceCode}&SourceType=${_sourceType}`;
    if (branchCode) {
      url += `&BranchCode=${branchCode}`;
    }
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response?.success) {
        setDataInWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: {
            ...prevData.tblItemTransactionHeaderCommand,
            transactionCode:
              response?.data?.tblInventoryTransactionModel?.transactionCode,
          },
        }));
        const dataDetail = response?.data;
        if (_sourceType === "NHAP_THANH_PHAM_SAN_XUAT") {
          const inventoryTransactionRequestSourceModels =
            response?.data?.inventoryTransactionRequestSourceModels.map(
              (item: any) => ({
                ...item,
                primaryQuantity: 1,
              })
            );
          dataDetail.inventoryTransactionRequestSourceModels =
            inventoryTransactionRequestSourceModels;
        }
        if (response?.data?.tblInventoryTransactionModel?.toInvId)
          fetchDataPosition(
            response?.data?.tblInventoryTransactionModel?.toInvId
          );
        setDataOrderDetail(dataDetail);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useHotkeys([
    [
      "ctrl+S",
      () => {
        handleCreateInWard("OPEN");
      },
    ],
    [
      "ctrl+Q",
      () => {
        handleCreateInWard("LOCK", true);
      },
    ],
    ["ctrl+M", () => toggleCollapse()],
  ]);

  //#endregion

  const fetchdataInventoryTransactionType = async () => {
    const responseInWard = await getTblInventoryTransactionTypeInWard();

    setDataInventoryTransactionType(
      responseInWard.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  // Get Data source code
  const fetchDataSourceCode = async () => {
    try {
      const _sourceType = dataInWard.tblItemTransactionHeaderCommand.sourceType;
      let url = `api/v1/RequestInventoryTransaction/get-list-code-transaction-request?Type=${_sourceType}&Page=30`;
      if (searchSourceCode && sourceCode) {
        url += `?Key=${searchSourceCode || sourceCode}`;
      }
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);

      if (response?.success) {
        setDataSearchSourceCodeOption(response.data.map((data) => data) || []);
      }
    } catch (error) {
      setDataSearchSourceCodeOption([]);
    }
  };

  // Get data sản phẩm
  const fetchDataItem = async () => {
    const _sourceType =
      dataInWard?.tblItemTransactionHeaderCommand?.sourceType || sourceType;
    const _sourceCode =
      _sourceType === "NHAP_HANG_NCC"
        ? dataInWard.tblItemTransactionHeaderCommand?.subSourceId
        : dataInWard.tblItemTransactionHeaderCommand?.sourceCode ?? sourceCode;
    if (keySearch && _sourceType && _sourceCode) {
      try {
        let url = `/api/v1/TblItemTransaction/search-list-serial-item-transaction?KeySearch=${keySearch}&SourceCode=${_sourceCode}&Page=30&Type=${_sourceType}`;
        const response = await repositoryPos.get(url);

        setDataItem(response?.data || []);
      } catch (error) {
        setDataItem([]);
      }
    }
  };

  const handleSerialInput = async (serial: string) => {
    const _sourceCode =
      dataInWard.tblItemTransactionHeaderCommand?.sourceCode || sourceCode;

    let url = `?SourceCode=${_sourceCode}&Type=${dataInWard.tblItemTransactionHeaderCommand.sourceType}&Serial=${serial}`;

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInventorySerialOrderDetailOnHand>
      >(`api/v1/TblItemTransaction/search-serial-item-transaction${url}`);

      if (!response || !response.success) {
        return;
      }
    } catch (error) {
      setIsError(true);
      console.error("Error fetching serial data:", error);
      return;
    }

    if (selectedItem && serial) {
      const isSerialDuplicate = dataSerialOnHand?.some(
        (item) => item.serial === serial
      );
      if (isSerialDuplicate) {
        NotificationExtension.Warn(
          "Mã serial đã tồn tại, vui lòng nhập mã khác"
        );
        return;
      }

      const matchingItemInArray2 =
        dataOrderDetail?.inventoryTransactionRequestSourceModels?.find(
          (item) => item.itemCode === selectedItem?.itemCode
        );

      if (matchingItemInArray2) {
        const totalAddedQuantityForItem =
          dataSerialOnHand
            ?.filter((item) => item.itemCode === selectedItem?.itemCode)
            ?.reduce((sum, item) => sum + (item.primaryQuantity || 0), 0) || 0;

        const totalPrimaryQuantityForItem =
          matchingItemInArray2.primaryQuantity || 0;

        if (totalAddedQuantityForItem < totalPrimaryQuantityForItem) {
          setDataSerialOnHand((prevData) => [
            {
              ...selectedItem,
              serial,
              invId: dataInWard?.tblItemTransactionHeaderCommand?.sourceCode,
              primaryQuantity: 1,
            },
            ...prevData,
          ]);
          setSerial("");
        } else {
          NotificationExtension.Fails(
            "Sản phẩm vượt quá số lượng cần nhập, vui lòng kiểm tra lại"
          );
        }
      } else {
        NotificationExtension.Fails(
          "Không tìm thấy sản phẩm trong danh sách nhập, vui lòng kiểm tra lại"
        );
      }
    }
  };

  const handleSerialsInput = (serials: string[]) => {
    let tempSerials = [...(dataSerialOnHand || [])];

    for (const serial of serials) {
      if (!serial) continue;

      const isSerialDuplicate = tempSerials.some(
        (item) => item.serial === serial
      );
      if (isSerialDuplicate) {
        NotificationExtension.Warn(
          `Mã serial "${serial}" đã tồn tại, vui lòng nhập mã khác`
        );
        continue;
      }

      if (selectedItem) {
        const totalAddedQuantityForItem = tempSerials
          .filter((item) => item?.itemCode === selectedItem?.itemCode)
          .reduce((sum, item) => sum + (item.primaryQuantity || 0), 0);

        const totalPrimaryQuantityForItem = selectedItem?.primaryQuantity || 0;

        if (totalAddedQuantityForItem < totalPrimaryQuantityForItem) {
          tempSerials = [
            {
              ...selectedItem,
              serial,
              invId: dataInWard?.tblItemTransactionHeaderCommand?.sourceCode,
              primaryQuantity: 1,
              locatorId:
                dataInWard?.tblItemTransactionHeaderCommand?.toPositionId,
              positionCode: dataPosition?.find(
                (p) =>
                  p.value?.toString() ===
                  dataInWard?.tblItemTransactionHeaderCommand?.toPositionId?.toString()
              )?.code,
            },
            ...tempSerials,
          ];
        } else {
          NotificationExtension.Warn(
            `Sản phẩm vượt quá số lượng cần nhập với serial "${serial}".`
          );
          break;
        }
      } else {
        NotificationExtension.Warn(
          `Không tìm thấy sản phẩm tương ứng với serial "${serial}".`
        );
      }
    }

    // Cập nhật trạng thái cuối cùng
    setDataSerialOnHand(tempSerials);
  };

  const updateItemByKeys = <T extends TblInventorySerialOrderDetailOnHandEdit>(
    prev: T[],
    serial: string,
    updates: Partial<T>
  ): T[] => {
    return prev?.map((item) =>
      item.serial === serial ? { ...item, ...updates } : item
    );
  };

  const notFetchDataSerialOnhand = (item: any) => {
    if (!checkDuplicateCode.includes(item.itemCode)) {
      setSelectedItem(item);
      setCheckDuplicateCode((prev) => [...prev, item.itemCode]);
    }
  };
  //#endregion
  //#region useEffects
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([
          fetchdataInventoryTransactionType(), // FetchData cho loại phiếu nhập
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) setUserInfo(JSON.parse(userLogin));

    const shouldFetchDataInWard =
      sourceCode &&
      action === "CREATE" &&
      dataInWard.tblItemTransactionHeaderCommand?.sourceCode;
    if (id || transactionCode) {
      fetchData();
    } else if (shouldFetchDataInWard) {
      fetchDataInWard();
    }

    fetchAllData();
  }, []);

  useEffect(() => {
    setDataInWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        departmentId: userInfo?.depId || null,
      },
    }));
  }, [userInfo]);

  useEffect(() => {
    const _sourceType = dataInWard.tblItemTransactionHeaderCommand.sourceType;
    const handeSupplierRequest = _sourceType === "NHAP_HANG_NCC";
    const exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = dataSerialOnHand
            .filter((item) => {
              const checkItem =
                item.serial === order.serial ||
                (handeSupplierRequest && item.itemCode === order.itemCode);
              return checkItem && !item.type;
            })
            .map((item) => item.primaryQuantity || 0);

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng

            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;
            return remainQuantity < 0;
          }

          return false;
        }
      );

    if (exceedQuantityFound) {
      setIsExceedQuantity(true);
    } else {
      setIsExceedQuantity(false);
    }

    setDataOrderDetail((prevData) => ({
      ...prevData,
      inventoryTransactionRequestSourceModels:
        prevData.inventoryTransactionRequestSourceModels?.map((order) => {
          const quantities = dataSerialOnHand
            .filter(
              (item) =>
                handeSupplierRequest
                  ? item.itemCode === order.itemCode
                  : item.serial === order.serial
              // ? item.serial === order.serial
              // : item.itemCode === order.itemCode
            )
            .map((item) => item.primaryQuantity || 0);

          const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0);
          const orderQuantity = order.primaryQuantity ?? 0;
          const orderTransferQuantity = order.transactionQuantity ?? 0;
          const remainQuantity =
            orderQuantity - (orderTransferQuantity + totalQuantity);

          return {
            ...order,
            scannedQuantity: totalQuantity,
            remainQuantity: remainQuantity,
            primaryQuantity:
              _sourceType === "NHAP_THANH_PHAM_SAN_XUAT"
                ? 1
                : order.primaryQuantity,
          };
        }) ?? [],
    }));
  }, [dataSerialOnHand]);

  useEffect(() => {
    const _sourceType =
      dataInWard.tblItemTransactionHeaderCommand.sourceType ?? "";
    if (table && action === "DETAIL") {
      table.setColumnVisibility({ action: false });
    }
    const colVisibilityDetail = visibleColDetail.get(_sourceType);
    const cosVisibility = visibleCol.get(_sourceType);

    if (cosVisibility) {
      table.setColumnVisibility(cosVisibility);
    }

    if (colVisibilityDetail) {
      tableDetail.setColumnVisibility(colVisibilityDetail);
    }

    // Clear input field
    if (action === "CREATE") {
      setDataSearchSourceCodeOption([]);
      setDataInWard({
        ...outWardEntity,
        tblItemTransactionHeaderCommand: {
          ...outWardEntity.tblItemTransactionHeaderCommand,
          sourceType: _sourceType,
          sourceCode: null,
        },
      });
      setDataOrderDetail(entityOrderInWard);
      setDataSerialOnHand([]);
      setCheckDuplicateCode([]);
    }

    if (_sourceType) {
      if (action === "CREATE") fetchDataSourceCode();
      setIsLockForOrder(_sourceType === "XUAT_BAN");
    } else {
      setIsLockForOrder(false);
    }
  }, [dataInWard.tblItemTransactionHeaderCommand.sourceType]);

  useEffect(() => {
    if (action !== "CREATE") {
      return;
    }
    const _sourceType = dataInWard.tblItemTransactionHeaderCommand.sourceType;
    const _sourceCode = dataInWard.tblItemTransactionHeaderCommand.sourceCode;
    // Clear input field
    setDataInWard({
      ...outWardEntity,
      tblItemTransactionHeaderCommand: {
        ...outWardEntity.tblItemTransactionHeaderCommand,
        sourceType: _sourceType,
        sourceCode: _sourceCode,
      },
    });
    setDataOrderDetail(entityOrderInWard);
    setDataSerialOnHand([]);
    setCheckDuplicateCode([]);
  }, [dataInWard.tblItemTransactionHeaderCommand.sourceCode]);

  useEffect(() => {
    if (handleItemCode) {
      const autoSelectItems = dataItem?.filter(
        (item) => !checkDuplicateCode.includes(item.itemCode)
      );
      autoSelectItems.forEach((item) => {
        notFetchDataSerialOnhand(item);
        setCheckDuplicateCode((prev) => [...prev, item.itemCode]);
      });
    }
  }, [dataItem, handleItemCode]);

  useEffect(() => {
    const _sourceType = dataInWard.tblItemTransactionHeaderCommand.sourceType;
    if (
      _sourceType === "XUAT_BAN" &&
      dataOrderDetail?.inventoryTransactionRequestSourceModels.length > 0
    ) {
      const variance = checkVarianceItem(
        dataOrderDetail?.inventoryTransactionRequestSourceModels
      );
      if (!variance.isVariance) {
        setIsLockForOrder(false);
      } else {
        setIsLockForOrder(true);
      }
    }
  }, [dataOrderDetail?.inventoryTransactionRequestSourceModels]);

  useEffect(() => {
    if (
      dataInWard.tblItemTransactionHeaderCommand?.sourceType &&
      dataInWard.tblItemTransactionHeaderCommand?.sourceCode &&
      action === "CREATE"
    ) {
      fetchDataInWard();
    }
  }, [dataInWard.tblItemTransactionHeaderCommand?.sourceCode]);

  useEffect(() => {
    const _posId = dataInWard.tblItemTransactionHeaderCommand?.toPositionId;
    const _positionCode = dataPosition?.find(
      (p) =>
        p.value?.toString() ===
        dataInWard?.tblItemTransactionHeaderCommand?.toPositionId?.toString()
    )?.code;
    if (_posId) {
      setDataSerialOnHand((prev) =>
        prev.map((i) => ({
          ...i,
          locatorId: _posId,
          positionCode: _positionCode,
        }))
      );
    }
  }, [dataInWard.tblItemTransactionHeaderCommand?.toPositionId]);

  useEffect(() => {
    if (!searchSourceCode && searchSourceCode.length > 3) {
      fetchDataSourceCode();
    }
  }, [searchSourceCode]);

  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItem();
    } else {
      setDataItem([]);
    }
  }, [keySearch]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updateMainContentWidth = () => {
      const containerWidth = containerRef.current?.offsetWidth || 0;
      const sidePanelWidth = sidePanelRef.current?.offsetWidth || 0;
      const calculatedMainWidth = isCollapse
        ? containerWidth
        : containerWidth - sidePanelWidth;
      setMainContentWidth(calculatedMainWidth);
    };

    const containerObserver = new ResizeObserver(updateMainContentWidth);
    const sidePanelObserver = new ResizeObserver(updateMainContentWidth);

    if (containerRef.current) containerObserver.observe(containerRef.current);
    if (sidePanelRef.current) sidePanelObserver.observe(sidePanelRef.current);

    updateMainContentWidth();

    return () => {
      if (containerRef.current)
        containerObserver.unobserve(containerRef.current);
      if (sidePanelRef.current)
        sidePanelObserver.unobserve(sidePanelRef.current);
    };
  }, [isCollapse]);
  //#endregion

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },
      {
        accessorKey: "positionCode",
        header: "Mã vị trí",
        size: 30,
      },
      {
        accessorKey: "locatorId",
        header: "Vị trí kho",
        size: 250,
        Cell: ({ row }) =>
          !locker ? (
            <Tooltip
              label={
                dataPosition?.find(
                  (p) => p.value === row?.original?.locatorId?.toString()
                )?.label
              }
            >
              <Select
                disabled={
                  dataInWard.tblItemTransactionHeaderCommand.status ===
                    "LOCK" || locker
                }
                data={dataPosition}
                value={row?.original?.locatorId?.toString()}
                placeholder={"Chọn vị trí"}
                onChange={(v) => {
                  setDataSerialOnHand((prev: any) => {
                    return updateItemByKeys(prev, row?.original?.serial, {
                      locatorId: Number(v),
                      positionCode: dataPosition?.find(
                        (p) => p.value?.toString() === v?.toString()
                      )?.code,
                    });
                  });
                }}
              />
            </Tooltip>
          ) : (
            <>{row?.original?.positionName}</>
          ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên SP",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "primaryUomName",
        header: "ĐVT1",
        size: 30,
        Cell: ({ row }) =>
          row?.original?.primaryUomName ? (
            <Text size="sm" w={300}>
              {row.original.primaryUomName}
            </Text>
          ) : (
            row?.original.primaryUomCode ?? row?.original.primaryUom
          ),
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm" w={300}>
            1
          </Text>
        ),
      },
      {
        accessorKey: "poPrice",
        header:
          dataInWard?.tblItemTransactionHeaderCommand?.sourceType === "XUAT_BAN"
            ? "Giá bán"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataInWard?.tblItemTransactionHeaderCommand?.sourceType ===
            "XUAT_BAN"
              ? row.original?.soPrice && row.original.soPrice?.toLocaleString()
              : row.original?.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUomName",
        header: "ĐVT2",
        size: 30,
      },
      {
        accessorKey: "subPrice",
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        accessorKey: "totalAmount",
        header: "Thành tiền",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataInWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice &&
                (
                  Number(row.original.soPrice) *
                  (row.original.primaryQuantity || 0)
                ).toLocaleString()
              : row.original.poPrice &&
                (
                  Number(row.original.poPrice) *
                  Number(row.original.primaryQuantity || 0)
                ).toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 200,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 300,
        Cell: ({ row, renderedCellValue }) =>
          !locker ? (
            <TextInput
              disabled={
                dataInWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                locker
              }
              placeholder="Nhập ghi chú"
              onChange={(e) => {
                const newValue = e.target?.value;

                setDataSerialOnHand((prev) => {
                  const item = prev?.find(
                    (item) => item.serial === row.original?.serial
                  );

                  if (!item) return prev;

                  if (item.note === newValue) return prev;

                  return prev?.map((d) =>
                    d.serial === row.original?.serial
                      ? { ...d, note: newValue }
                      : d
                  );
                });
              }}
            />
          ) : (
            <>{renderedCellValue}</>
          ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xóa">
              <ActionIcon
                disabled={
                  dataInWard.tblItemTransactionHeaderCommand.status === "LOCK"
                }
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  deleteItemSerial(row.original.serial);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [
      dataSerialOnHand,
      dataInWard.tblItemTransactionHeaderCommand.toPositionId,
      dataInWard.tblItemTransactionHeaderCommand.sourceType,
      dataInWard.tblItemTransactionHeaderCommand.status,
      locker,
      dataPosition,
    ]
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },
      ...(sourceType === "NHAP_HANG_NCC"
        ? [
            {
              accessorKey: "itemCode",
              header: "Mã hàng",
              size: 30,
              Cell: ({ row }: any) => (
                <Text
                  onClick={() => {
                    setHandleItemCode(true);
                    setKeySearch(row.original.itemCode);
                    setSerial("");
                    notFetchDataSerialOnhand(row?.original);
                  }}
                  size="sm"
                  style={{ cursor: "pointer" }}
                  w={300}
                >
                  {row.original.itemCode}
                </Text>
              ),
            },
          ]
        : [
            {
              accessorKey: "itemCode",
              header: "Mã hàng",
              size: 30,
            },
          ]),
      ...(sourceType === "NHAP_HANG_NCC"
        ? []
        : [
            {
              accessorKey: "serial",
              header: "Serial",
              size: 35,
            },
          ]),
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "primaryUom",
        header: "ĐVT1",
        size: 30,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataInWard.tblItemTransactionHeaderCommand.sourceType ===
            "NHAP_THANH_PHAM_SAN_XUAT"
              ? 1
              : row?.original?.primaryQuantity}
          </Text>
        ),
      },
      {
        accessorKey: "poPrice",
        header:
          dataInWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataInWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 && row.original.soPrice1?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUom",
        header: "ĐVT2",
        size: 30,
      },
      {
        accessorKey: "limitQuantity",
        header: "SL định mức ",
        size: 30,
      },
      {
        accessorKey: "subPrice",
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        accessorKey: "totalAmount",
        header: "Thành tiền",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataInWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 &&
                (
                  Number(row.original.soPrice1) *
                  (row.original.primaryQuantity || 0)
                ).toLocaleString()
              : row.original.poPrice &&
                (
                  Number(row.original.poPrice) *
                  Number(row.original.primaryQuantity || 0)
                ).toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "itemStatus",
        header: "Tình trạng",
        size: 30,
      },
      {
        header: "Ghi chú",
      },
      {
        accessorKey: "deviated",
        header: "Lệch",
        Cell: ({ row }) => {
          return (
            <Text fw={"bold"} c={"red"} size="sm">
              {(row.original.scannedQuantity || 0) -
                (row.original.primaryQuantity || 0)}
            </Text>
          );
        },
        size: 30,
      },
    ],
    [dataSerialOnHand, dataInWard.tblItemTransactionHeaderCommand.sourceType]
  );

  const table = useMantineReactTable({
    columns,
    data: dataSerialOnHand,
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    enableTopToolbar: action !== "DETAIL",
    //add custom action buttons to top-left of top toolbar\
    renderTopToolbarCustomActions: () => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Grid>
          <Grid.Col span={{ base: 6, md: 3, lg: 3 }}>
            {RenderTransactionHeader(1)}
          </Grid.Col>

          <RenderAddBarcodeButton />

          <Grid.Col span={{ base: 6, md: 3, lg: 3 }}>
            {RenderTransactionHeader(2)}
          </Grid.Col>

          <RenderSerialButtons />

          <Grid.Col
            span={{
              base: 12,
              md: sourceType === "NHAP_HANG_NCC" ? 2 : 4,
              lg: sourceType === "NHAP_HANG_NCC" ? 2 : 4,
            }}
          >
            <Flex
              direction="row"
              align="center"
              justify="flex-end"
              w="100%"
              pr={20}
              gap={15}
            >
              <Text fw={700}>Số lượng:</Text>
              <Text c="red" fw={700}>
                <NumberFormatter
                  value={dataSerialOnHand?.length || 0}
                  thousandSeparator
                />
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
      </div>
    ),
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index"],
        right: ["action"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.55 - 20,
        minHeight: height * 0.55 - 20,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      //  pagination,
      showAlertBanner: isError,
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data: dataOrderDetail?.inventoryTransactionRequestSourceModels ?? [],
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["index", "itemCode"],
        right: ["deviated"],
      },
      // columnVisibility: getColumnVisibility(),
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.45,
        minHeight: height * 0.45,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
        // userSelect: "none",
        // pointerEvents: "none",
      },
    }),
    state: {
      //  pagination,
      showAlertBanner: isError,
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box my={"10px"}>{titleSell()}</Box>
      </div>
      <Box
        style={{
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            width: "max-width",
          }}
          ref={containerRef}
        >
          {!isCollapse && (
            <Box
              style={{
                flexShrink: 0,
                transition: "width 0.3s ease, max-width 0.3s ease",
                display: "flex",
                alignItems: "stretch",
                overflow: "hidden",
                height: "100%",
                width: "fit-content",
              }}
              ref={sidePanelRef}
            >
              <Collapse
                in={!isCollapse}
                transitionDuration={100}
                transitionTimingFunction="ease-in-out"
              >
                <Fieldset
                  w="fit-content"
                  h="100%"
                  legend="Thông tin chung"
                  p={5}
                >
                  {renderMainInWard()}
                </Fieldset>
              </Collapse>
            </Box>
          )}

          <Box
            style={{
              flexGrow: 1,
              flexShrink: 0,
              width: `${mainContentWidth}px`,
              transition: "width 0.3s ease",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Fieldset w={"max-width"} p={10} pb={0} legend="Bên nhận">
              <MantineReactTable table={table} />
              <Group my="10px" justify="end" />
            </Fieldset>

            <Fieldset p={10} pb={0} w={"max-width"} legend={"Bên xuất"}>
              <MantineReactTable table={tableDetail} />
            </Fieldset>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default WarehouseInWardAction;
