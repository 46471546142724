import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Fieldset,
  Text,
  Grid,
  Group,
  Select,
  TextInput,
} from "@mantine/core";
import { DatePickerInput, DateTimePicker } from "@mantine/dates";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDeliveryAssignment } from "../../../model/TblDeliveryAssignment";
import { getDeliveryWorkSelect } from "../../../service/getSelectApi";
import { SocketExtension } from "../../../_base/socket/socket";
import { sky_blue } from "../../../const/variables";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import DeliveryList from "./DeliveryList";

const EditAsignment = ({ id, onClose, branchId }: EditAsignmentProps) => {
  const entity = {
    id: "0",
    code: null,
    assignmentDate: null,
    fromExpectedDate: null,
    toExpectedDate: null,
    deliveryWorkId: null,
    employee1: null,
    employee2: null,
    mainResponsibility1: false,
    mainResponsibility2: false,
    payroll1: false,
    payroll2: false,
    vehicleCode: null,
    driverId: null,
    driverName: null,
    driverMobileNumber: null,
    fromLocationId: null,
    toLocationId: null,
    distance: null,
    distanceLevel: null,
    distanceTotal: null,
    partnerId: null,
    partnerEmployeeName: null,
    description: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [dataEmployeedSelect, setDataEmployeedSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataDriverSelect, setDataDriverSelect] = useState<ComboboxItem[]>([]);
  const [dataWorkSelect, setDataWorkSelect] = useState<ComboboxItem[]>([]);
  const [enable, setEnable] = useState(false);

  const form = useForm<TblDeliveryAssignment>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      employee1: Number(values.employee1),
      employee2: Number(values.employee2),
    }),

    validate: {
      driverId: (value: number | null | undefined) => {
        if (!value) {
          return "Vui lòng chọn người lái xe !";
        }
      },
      driverMobileNumber: (value: string | null | undefined) => {
        if (value) {
          return hasLength(
            { max: 10, min: 10 },
            "Số điện thoại phải đủ 10 ký tự !"
          )(value as string);
        }
      },
      employee1: (value: number | null | undefined) => {
        if (!value) {
          return "Vui lòng nhập tên nhân viên giao hàng !";
        }
      },
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryDelivery.get<
      MessageResponse<TblDeliveryAssignment>
    >(`/api/v1/TblDeliveryAssignment/update?id=${id}`);
    if (!callapi?.success) {
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        Promise.all([dataDeliveryWorkSelect(), dataEmployeeSelect()]);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const handleEditTblDeliveryAssignment = async (
    dataSubmit: TblDeliveryAssignment
  ) => {
    open();
    const dataApi = await repositoryDelivery.post<
      MessageResponse<TblDeliveryAssignment>
    >("/api/v1/TblDeliveryAssignment/update", dataSubmit);
    if (dataApi?.success) {
      NotificationExtension.Success("Sửa thành công !");
      setTimeout(() => {
        modals.closeAll();
        onClose((prev: any) => !prev);
      }, 1000);
    } else NotificationExtension.Fails("Sửa thất bại !");
    close();
  };

  const dataEmployeeSelect = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${branchId}&Take=200`
    );

    if (res && res?.success) {
      setDataEmployeedSelect(
        res.data
          .filter((item: any) => item.fullname != null && item.id != null)
          .map((item: any) => ({
            value: item.id?.toString(),
            label: `${item.fullname} - ${item.code}`,
            phoneNumber: item.phoneNumber,
          }))
      );
    }
  };

  const dataDeliveryWorkSelect = async () => {
    const get = await getDeliveryWorkSelect();
    setDataWorkSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  useEffect(() => {
    const selectedEmployee1 = dataEmployeedSelect.find(
      (item: any) => item.value === form.getValues().employee1?.toString()
    );
    const selectedEmployee2 = dataEmployeedSelect.find(
      (item: any) => item.value === form.getValues().employee2?.toString()
    );

    if (selectedEmployee1) {
      setDataDriverSelect((prev) => {
        const updatedPrev = [...prev];
        updatedPrev[0] = selectedEmployee1;
        return updatedPrev;
      });
    }

    if (selectedEmployee2) {
      setDataDriverSelect((prev) => {
        const updatedPrev = [...prev];
        updatedPrev[1] = selectedEmployee2;
        return updatedPrev;
      });
    }
  }, [form]);

  return (
    <Box
      component="form"
      m={"5px 0px"}
      w={"82.5vw"}
      maw={1300}
      onSubmit={form.onSubmit((e: TblDeliveryAssignment) => {
        handleEditTblDeliveryAssignment(e);
        SocketExtension.SendMessageToEmp<any>(
          Number(form.getValues().employee1),
          {
            message: "Có đơn hàng",
          }
        );
      })}
      style={{ position: "relative" }}
    >
      <Grid>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <Fieldset legend="Thông tin giao nhận" p={"0px 15px 7.5px 15px"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <TextInput
                  label="Mã phân công giao vận"
                  placeholder="Nhập mã phân công giao vận"
                  value={form.getValues().code ?? ""}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <DateTimePicker
                  label="Ngày phân công"
                  placeholder="Nhập ngày phân công"
                  value={new Date()}
                  valueFormat="DD/MM/YYYY HH:mm A"
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <Select
                  label="Phương tiện giao hàng"
                  placeholder="Chọn phương tiện giao hàng"
                  value={
                    form.getValues().vehicleCode
                      ? form.getValues().vehicleCode?.toString()
                      : null
                  }
                  clearable
                  data={[
                    { label: "Xe máy", value: "XE_MAY" },
                    { label: "Ô tô", value: "O_TO" },
                  ]}
                  {...form.getInputProps("vehicleCode")}
                  onChange={(e) => {
                    form.setValues((prev) => ({
                      ...prev,
                      vehicleCode: e ? e?.toString() : null,
                    }));
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <Select
                  label="Công việc"
                  placeholder="Chọn loại công việc"
                  value={
                    form.getValues().deliveryWorkId
                      ? form.getValues().deliveryWorkId?.toString()
                      : null
                  }
                  clearable
                  data={dataWorkSelect}
                  onChange={(value) =>
                    form.setFieldValue(
                      "deliveryWorkId",
                      value !== null ? Number(value) : null
                    )
                  }
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <TextInput
                  label="Tổng khoảng cách"
                  placeholder="Bấm nút tính khoảng cách để hiển thị"
                  value={`${Number(form.getValues().distanceTotal) / 1000} Km`}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              {/* <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                <TextInput
                  label="Thời gian giao ước tính"
                  value={`${Math.floor(
                    Number(form.getValues()?.estimatedTime) / 3600
                  )} giờ - ${Math.floor(
                    (Number(form.getValues()?.estimatedTime) % 3600) / 60
                  )} phút`}
                  variant="filled"
                  readOnly
                />
              </Grid.Col> */}
              <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                <TextInput
                  label="Ghi chú"
                  placeholder="Ghi chú"
                  {...form.getInputProps("description")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <Fieldset legend="Nhân viên phân công" p={"0px 15px 7.5px 15px"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 7.5 }}>
                <Select
                  label="Lái xe"
                  placeholder="Nhập tên nhân viên lái xe"
                  value={
                    form.getValues().driverId
                      ? form.getValues().driverId?.toString()
                      : null
                  }
                  data={dataDriverSelect?.map((item: any) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  searchable
                  nothingFoundMessage={
                    dataDriverSelect.length > 0
                      ? "Không tìm thấy dữ liệu !"
                      : "Vui lòng chọn nhân viên giao hàng hoặc hỗ trợ !"
                  }
                  {...form.getInputProps("driverId")}
                  onChange={(e) =>
                    form.setValues((prev) => ({
                      ...prev,
                      driverId: e ? Number(e) : null,
                      driverMobileNumber: getValueById(
                        e?.toString() ?? "",
                        dataEmployeedSelect,
                        "phoneNumber"
                      ),
                    }))
                  }
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
                <TextInput
                  label="Số điện thoại người lái"
                  placeholder="Nhập số điện thoại người lái"
                  {...form.getInputProps("driverMobileNumber")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 7.5 }}>
                <Select
                  label="Nhân viên giao hàng"
                  placeholder="Nhập tên nhân viên giao hàng"
                  data={dataEmployeedSelect
                    ?.filter(
                      (item: any) =>
                        item.value !== form.getValues().employee2?.toString()
                    )
                    ?.map((item: any) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                  value={
                    form.getValues().employee1
                      ? form.getValues().employee1?.toString()
                      : null
                  }
                  {...form.getInputProps("employee1")}
                  onChange={(e) => {
                    const selectedEmployee = dataEmployeedSelect.find(
                      (item: any) => item.value === e
                    );

                    form.setValues((prev) => ({
                      ...prev,
                      employee1: e ? Number(e) : null,
                      payroll1: e ? true : false,
                      driverId: e ? Number(e) : null,
                      driverMobileNumber: getValueById(
                        e?.toString() ?? "",
                        dataEmployeedSelect,
                        "phoneNumber"
                      ),
                    }));

                    if (selectedEmployee) {
                      setDataDriverSelect((prev) => {
                        const updatedPrev = [...prev];
                        updatedPrev[0] = selectedEmployee;
                        return updatedPrev;
                      });
                    }
                  }}
                  searchable
                  clearable
                  nothingFoundMessage="Không tìm thấy dữ liệu !"
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
                <Checkbox
                  mt={30}
                  label="Tính lương"
                  checked={form.getValues().payroll1}
                  readOnly
                  {...form.getInputProps("payroll1")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 7.5 }}>
                <Select
                  label="Nhân viên hỗ trợ"
                  placeholder="Nhập tên nhân viên hỗ trợ"
                  data={dataEmployeedSelect
                    ?.filter(
                      (item: any) =>
                        item.value !== form.getValues().employee1?.toString()
                    )
                    ?.map((item: any) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                  value={
                    form.getValues().employee2
                      ? form.getValues().employee2?.toString()
                      : null
                  }
                  searchable
                  clearable
                  nothingFoundMessage="Không tìm thấy dữ liệu !"
                  disabled={!form.getValues().employee1}
                  {...form.getInputProps("employee2")}
                  onChange={(e) => {
                    const selectedEmployee = dataEmployeedSelect.find(
                      (item: any) => item.value === e
                    );

                    form.setValues((prev) => ({
                      ...prev,
                      employee2: e ? Number(e) : null,
                      payroll2: e ? true : false,
                    }));

                    if (selectedEmployee) {
                      setDataDriverSelect((prev) => {
                        const updatedPrev = [...prev];
                        updatedPrev[1] = selectedEmployee;
                        return updatedPrev;
                      });
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
                <Checkbox
                  mt={30}
                  label="Tính lương"
                  checked={form.getValues().payroll2}
                  readOnly
                  {...form.getInputProps("payroll2")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
      </Grid>
      <Grid align="center">
        <Grid.Col span={12}>
          <Text size="12.5px" fw={"bold"} my={6}>
            Danh sách đơn giao hàng
          </Text>
          <DeliveryList
            dataDetail={
              form.getValues()?.tblDeliveryModels
                ? form.getValues()?.tblDeliveryModels
                : []
            }
            isSetHeight={true}
          />
        </Grid.Col>
      </Grid>
      <Group
        justify="end"
        mt="md"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
          disabled={!enable}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

type EditAsignmentProps = {
  id: string | number;
  onClose: any;
  branchId: any;
};

export default EditAsignment;
