import {
  Box,
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect } from "react";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import Repository from "../../_base/helper/HttpHelper";
import { ListAppModel } from "../../model/ListAppModel";
import { MessageResponse } from "../../model/MessageResponse";
import { _validateListAppModelValidator } from "../../validate/ListAppModelValidator";
import { repositoryMaster } from "../../_base/_const/_constVar";
import { sky_blue } from "../../const/variables";

const AppEditView = function ({
  id,
  onClose,
  load,
}: {
  id: string;
  onClose: any;
  load: number;
}) {
  const entity: ListAppModel = {
    id: "",
    name: "",
  };
  const form = useForm<ListAppModel>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: _validateListAppModelValidator,
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const callApiGetData = async () => {
    open();
    let urlCreate = `/api/v1/ListApp/edit?id=` + id;
    const callapi = await repositoryMaster.get<MessageResponse<ListAppModel>>(
      urlCreate
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      } else {
        NotificationExtension.Fails("Dữ liệu không tồn tại");
        modals.closeAll();
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const apiCreate = async (data: ListAppModel) => {
    open();
    let urlCreate = `/api/v1/ListApp/edit`;
    const callapi = await repositoryMaster.post(urlCreate, data);
    if (callapi)
      if (callapi?.success) {
        onClose(load + 1);
        NotificationExtension.Success("Chỉnh sửa thành công !");
        modals.closeAll();
      } else if (callapi != null)
        NotificationExtension.Fails("Chỉnh sửa thất bại !");
    close();
  };

  useEffect(() => {
    callApiGetData();
  }, []);

  return (
    <>
      <Box
        component="form"
        onSubmit={form.onSubmit(async (e: ListAppModel) => {
          await apiCreate(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Box className="flex-none" component="form" mx="auto">
          <TextInput
            label="Tên App: "
            placeholder="Nhập..."
            withAsterisk
            {...form.getInputProps("name")}
          />
          <TextInput
            mt={"lg"}
            label="Mô tả: "
            placeholder="Nhập..."
            {...form.getInputProps("description")}
          />
          <TextInput
            label="Đường dẫn: "
            placeholder="Nhập..."
            {...form.getInputProps("url")}
          />
          <TextInput
            mt={"lg"}
            label="Đường dẫn Api: "
            placeholder="Nhập..."
            {...form.getInputProps("urlApi")}
          />
          <Checkbox
            mt={"xs"}
            key={form.key("inActive")}
            label="Trạng thái"
            defaultValue={form.getValues().inActive ?? false}
            defaultChecked={form.getValues().inActive ?? false}
            {...form.getInputProps("inActive")}
          />
        </Box>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default AppEditView;
