import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridCol,
  Group,
  Modal,
  NumberFormatter,
  Text,
  Title,
} from "@mantine/core";
import { IconCheck, IconSearch } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryPos } from "../../../_base/_const/_constVar";
import InputSearch from "../../../common/InputSearch/InputSearch";
import { _localization } from "../../../config/location";
import "./tab.component.css";
import { green } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";

interface Item {
  poCode: string;
  serial: string;
  itemCode: string;
  itemName: string;
  itemId: number;
  quantity: number;
  uom: string;
  poPrice: number;
}

interface AccessoryViewProps {
  idItems: string;
}

const AccessoryView: React.FC<AccessoryViewProps> = ({ idItems }) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<Item[]>([]);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [opened, { open, close }] = useDisclosure(false);

  console.log(rowSelection, "adadasdasd");
  const [search, setSearch] = useState({
    keySearch: "",
    StartDate: "",
    BranchId: "",
    EndDate: "",
    status: "",
  });

  const sizeGrid = { base: 6, sm: 2, md: 2, lg: 2, xl: 2 };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    const queryParams = new URLSearchParams();
    queryParams.append(
      "Skip",
      (pagination?.pageIndex * pagination?.pageSize).toString()
    );
    queryParams.append("Take", pagination.pageSize.toString());

    if (idItems) {
      queryParams.append("ProductOrderCode", idItems);
    }

    if (search.keySearch) {
      queryParams.append("KeySearch", encodeURIComponent(search.keySearch));
    }

    if (sorting && sorting.length > 0) {
      const { id, desc } = sorting[0] || {};
      if (id) {
        queryParams.append("Sort", encodeURIComponent(id));
        queryParams.append("isOrder", desc.toString());
      }
    }

    const url = `/api/v1/TblMfgProductOrder/get-list-serial-mfg?${queryParams.toString()}`;

    try {
      const response = await repositoryPos.get(url);

      if (response && response.success) {
        const result = response.data.lists || [];
        setData(result);
        setRowCount(result.length === 0 ? 0 : response.count);
        table.resetRowSelection();
      } else {
        setData([]);
        setRowCount(0);
      }
    } catch (error) {
      setIsError(true);
      setData([]);
      setRowCount(0);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };
  const handleCancelAccessory = async (ids: string[] | number[]) => {
    const res = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/TblMfgProductOrder/exchange-serial",
      ids
    );

    if (res && res.success) {
      fetchData();
      setTimeout(() => {
        close();
      }, 500);
      NotificationExtension.Success("Đổi linh kiện thành công!");
    }
  };
  

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (pagination.pageIndex === 0 && data.length < pagination.pageSize) {
      fetchData();
    } else {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting, idItems]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<Item>[]>(
    () => [
      {
        accessorKey: "poCode",
        header: "Mã lệnh sản xuất",
        enableColumnActions: false,
        enableSorting: false,
        size: 100,
        Cell: ({ renderedCellValue, row }: any) => (
          <Badge
            radius="sm"
            variant="dot"
            style={{ cursor: "pointer" }}
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },

      {
        accessorKey: "itemCode",
        header: "Mã linh kiện",
        enableColumnActions: false,
        enableSorting: false,
        size: 100,
        Cell: ({ renderedCellValue, row }: any) => (
          <Badge variant="light">
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên linh kiện",
        enableColumnActions: false,
        enableSorting: false,
        size: 300,
      },
      {
        accessorKey: "serial",
        header: "Mã serial",
        enableColumnActions: false,
        enableSorting: false,
        size: 100,
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        enableColumnActions: false,
        enableSorting: false,
        size: 50,
      },
      {
        accessorKey: "uom",
        header: "Đơn vị",
        enableColumnActions: false,
        enableSorting: false,
        size: 100,
      },
      {
        accessorKey: "poPrice",
        header: "Giá",
        enableColumnActions: false,
        enableSorting: false,
        size: 100,
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={Math.abs(Number(renderedCellValue))}
            thousandSeparator=","
          />
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable<Item>({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["maLsx", "id"],
        right: ["trangThai", "action"],
      },
      columnVisibility: { id: true },
    },
    enableRowSelection: true,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    getRowId: (row) => row.serial,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "550", //conditional styling
        fontSize: "12.5px",
        padding: "7px 15px",
      },
    }),
    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  return (
    <Box>
      <Modal
        zIndex={99999}
        opened={opened}
        onClose={close}
        title={<Title order={5}>Tiến hành xác nhận</Title>}
      >
        <Text size="lg" fw={600} m="10px 0">
          Bạn có chắc chắn muốn đổi linh kiện không?
        </Text>
        <Group w="100%" justify="end" mt={15}>
          <Button
            leftSection={<IconCheck size={14} />}
            color="blue"
            variant="filled"
            onClick={() => handleCancelAccessory(selectIds)}
          >
            Xác nhận
          </Button>
        </Group>
      </Modal>
      <Group mt={10} justify="space-between" ref={headerRef}>
        <Flex gap={"10px"}>
          <InputSearch
            placeholder="Từ khoá"
            onSearch={fetchData}
            onChange={(value) => {
              handleChangeSearchValue(value, "keySearch");
            }}
          />
          <Button
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Flex>
        <Button
          variant="filled"
          color="blue"
          disabled={selectIds.length < 1}
          leftSection={<IconCheck size={14} />}
          onClick={open}
        >
          Đổi linh kiện
        </Button>
      </Group>
      <Box mt={10}>
        <MantineReactTable table={table} />
      </Box>
    </Box>
  );
};

export default AccessoryView;
