import { AxiosResponse } from "axios";
import { API_ROUTE } from "../const/apiRoute";
import { apiGHTK, apiPos, apiSCA } from "../library/axios";

export const takeDeliveryfeeSCA = async (data: any): Promise<any> => {
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhZGRyZXNzIjoiMTMxIGzDqiB0aGFuaCBuZ2jhu4siLCJ3YXJkc19pZCI6OTUsInBvc3RJZCI6MCwidG9rZW4iOm51bGwsInVzZXJfaWQiOjI4NjU5LCJwaG9uZSI6Ijg0OTQyMDQ0MDc4IiwicHJvdmluY2VfaWQiOjEsIm5hbWUiOiJIYWNvbSIsInBvc3RDb2RlIjpudWxsLCJkaXN0cmljdF9pZCI6NywiZXhwIjoxNzY1MjY0NjgxLCJpYXQiOjE3MzM3Mjg2ODEyNzEsInVzZXJuYW1lIjoiODQ5NDIwNDQwNzgifQ.XURT7wFNa9xNnjQ7v67J_59ClD96Jqha2RjZpamCid4ygiV59_v5vMcT0DQA_w7c3eUmPRnf3U3uMFjd0WlSmRc5qBRmHqqT72_Hg2Pnn3xa5dVooDPwmeYxoH8FxihgRxAgeurqihWANYUd-aOQqN8kHtSef6QIRvtHO8lGPxA";
  try {
    const response: AxiosResponse = await apiSCA.post(
      `${API_ROUTE.GET_PRICE_ALL}`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const takeDeliveryfeeGHTK = async (query: string): Promise<any> => {
  const token = "28968247a8f3462d882ca5bca7d81971fa842cbc";
  try {
    const response: AxiosResponse = await apiGHTK.get(
      `${API_ROUTE.GET_DATA_DELIVERY_FEE_GHTK}` + query,
      {
        headers: {
          Token: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    // HandleResponseError(error);
    return [];
  }
};

export const takeProviceId = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await apiSCA.get(
      `${API_ROUTE.GET_LIST_PROVINCE_SCA}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const takeDistrictId = async (idProvince: any): Promise<any> => {
  try {
    const response: AxiosResponse = await apiSCA.get(
      `${API_ROUTE.GET_LIST_DISTRICT_SCA}?province_id=${idProvince}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const takeDeliveryfeeViettel = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await apiPos.post(
      `${API_ROUTE.GET_DATA_DELIVERY_FEE_VIETTEL}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // HandleResponseError(error);
    return [];
  }
};
