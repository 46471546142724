import React, { useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { repositoryMdm } from "../../_base/_const/_constVar";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import PrintedInvoice from "../Invoice/PrintedInvoice";

const PrintInvoice = ({
  idPrint,
  setIdPrint,
  isPrinting,
  setIsPrinting,
}: any) => {
  // Invoice
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: `
    @page{
      size: 80mm 297mm;
      margin:0;
    }
    body:{
      margin: 0;
      color: #000;
    }`,
  });
  const [valueQRCode, setValueQRCode] = useState(null);
  const [invoiceData, setInvoiceData] = useState<any>(null);
  const fetchInvoiceData = async () => {
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/print-order?ids=${idPrint}`
      );
      if (response && response.success) {
        let result = response.data;
        setInvoiceData(result[0]);
      } else {
        setInvoiceData(null);
        setIsPrinting(false);
        NotificationExtension.Fails("In hóa đơn không thành công");
      }
    } catch (error) {
      setInvoiceData(null);
      setIsPrinting(false);
      NotificationExtension.Fails("In hóa đơn không thành công");
    }
  };
  useEffect(() => {
    if (idPrint && idPrint !== null) {
      fetchInvoiceData();
    }
  }, [idPrint]);
  useEffect(() => {
    if (isPrinting && invoiceData && invoiceData !== null) {
      handlePrint();
      setIsPrinting(false);
      setIdPrint(null);
    }
  }, [invoiceData]);
  // console.log("invoiceData", invoiceData);
  // console.log("isPrinting", isPrinting);
  // console.log("idPrint", idPrint);
  // console.log("đang ở file xử lý in hóa đơn");
  return (
    <>
      <PrintedInvoice
        componentRef={componentRef}
        invoiceData={invoiceData}
        valueQRCode={valueQRCode}
      />
    </>
  );
};

export default PrintInvoice;
