import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMLocation } from "../../../../model/TblDMLocation";
import {
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
} from "../../../../service/getSelectApi";
import { sky_blue } from "../../../../const/variables";

const EditDataView = ({ id, onClose }: EditDataViewProps) => {
  const entity = {
    id: "0",
    cityId: null,
    districtId: null,
    active: false,
    communeId: null,
    code: null,
    name: null,
    modifiedAt: null,
  };

  const [dataTblProvinceSelect, setDataTblProvinceSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblDistrictSelect, setDataTblDistrictSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblCommuneSelect, setDataTblCommuneSelect] = useState<
    ComboboxItem[]
  >([]);

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [enable, setEnable] = useState(false);
  const [cityId, setCityId] = useState<string | null>(null);
  const [districtId, setDistrictId] = useState<string | null>(null);

  const [location, setLocation] = useState({
    city: null,
    district: null,
    commune: null,
  });

  const form = useForm<TblDMLocation>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      code: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập mã địa điểm !";
        }
        return hasLength(
          { max: 225, min: 5 },
          "Mã địa điểm phải nằm trong khoảng từ 5 đến 225 ký tự !"
        )(value as string);
      },
      name: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên địa điểm !";
        }
        return hasLength(
          { max: 30, min: 5 },
          "Tên địa điểm phải nằm trong khoảng từ 5 đến 30 ký tự !"
        )(value as string);
      },
    },
  });

  const handleChangeValue = (value: any, key: string) => {
    if (value !== "Invalid date")
      setLocation((prevData: any) => ({ ...prevData, [key]: value }));
    else {
      setLocation((prevData: any) => ({ ...prevData, [key]: "" }));
    }
  };

  const handleEditTblDMLocation = async (dataSubmit: TblDMLocation) => {
    open();
    const dataApi = await repositoryMdm.post<MessageResponse<TblDMLocation>>(
      "/api/v1/TblDmLocation/update",
      dataSubmit
    );
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Cập nhật thành công!!!");
    }

    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<TblDMLocation>>(
      `/api/v1/TblDmLocation/update?id=${id}`
    );
    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        setCityId(dataApi.cityId?.toString() ?? "");
        setDistrictId(dataApi.districtId?.toString() ?? "");
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  const fetchDataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataTblProvinceSelect(
      getData
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataDistrictSelect = async () => {
    if (cityId !== null) {
      const getData = await getDistrictSelect(cityId ?? "");
      setDataTblDistrictSelect(
        getData
          ?.filter((item) => item.value != null && item.text != null)
          ?.map((item) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const fetchDataCommuneSelect = async () => {
    if (districtId !== null) {
      const getData = await getCommuneSelect(districtId ?? "");
      setDataTblCommuneSelect(
        getData
          ?.filter((item) => item.value != null && item.text != null)
          ?.map((item) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  useEffect(() => {
    fetchDataProvinceSelect();
  }, []);

  useEffect(() => {
    if (cityId !== null && cityId.length > 0) {
      fetchDataDistrictSelect();
    }
  }, [cityId]);

  useEffect(() => {
    if (districtId !== null && districtId?.length > 0) {
      fetchDataCommuneSelect();
    }
  }, [districtId]);

  useEffect(() => {
    form.setValues((prev) => ({ ...prev, cityId: Number(location.city) }));
    form.setValues((prev) => ({
      ...prev,
      districtId: Number(location.district),
    }));
    form.setValues((prev) => ({
      ...prev,
      communeId: Number(location.commune),
    }));
  }, [location]);

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "250px", md: "300px", lg: "400px" }}
        onSubmit={form.onSubmit((e: TblDMLocation) => {
          handleEditTblDMLocation(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã"}
              placeholder={"Nhập mã"}
              type="text"
              withAsterisk
              {...form.getInputProps("code")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <TextInput
              label={"Tên"}
              placeholder={"Nhập tên"}
              type="text"
              withAsterisk
              {...form.getInputProps("name")}
            />
          </Grid.Col>
        </Grid>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <Select
              label={"Mã tỉnh/thành phố"}
              placeholder={"Nhập mã"}
              data={dataTblProvinceSelect}
              value={location.city}
              clearable
              searchable
              {...form.getInputProps("cityId")}
              onChange={(e) => {
                setCityId(e);
                handleChangeValue(e, "city");
                handleChangeValue(null, "district");
                handleChangeValue(null, "commune");
              }}
              nothingFoundMessage="Không tìm thấy dữ liệu !!!"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <Select
              label={"Mã quận/huyện"}
              placeholder={"Nhập mã"}
              data={dataTblDistrictSelect}
              value={location.district}
              clearable
              searchable
              disabled={cityId === null}
              {...form.getInputProps("districtId")}
              onChange={(e) => {
                setDistrictId(e);
                handleChangeValue(e, "district");
                handleChangeValue(null, "commune");
              }}
              nothingFoundMessage="Không tìm thấy dữ liệu !!!"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <Select
              label={"Mã phường/xã"}
              placeholder={"Nhập mã"}
              data={dataTblCommuneSelect}
              value={location.commune}
              searchable
              clearable
              disabled={cityId === null || districtId === null}
              {...form.getInputProps("communeId")}
              onChange={(e) => {
                handleChangeValue(e, "commune");
              }}
              nothingFoundMessage="Không tìm thấy dữ liệu !!!"
            />
          </Grid.Col>
        </Grid>
        <Checkbox
          label={"Sử dụng"}
          checked={form.getValues().active}
          {...form.getInputProps("active")}
          mt={10}
        />
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            disabled={!enable}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default EditDataView;

type EditDataViewProps = {
  id: string | number;
  onClose: any;
};
