import {
  Box,
  Button,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblInventoryCycleCountPeriod } from "../../../../model/TblInventoryCycleCountPeriod";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import {
  getCatSegment2bSelect,
  getBranchSelectMdm,
  getInventorySelectMdm,
} from "../../../../service/getSelectApi";
import { useEffect, useState } from "react";
import { DateTimePicker } from "@mantine/dates";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";

const CreateDataView = ({ onClose }: CreateDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    cycleCountPeriod: new Date().getMonth() + 1,
    startTime: null,
    endTime: null,
    note: null,
    orgInvId: null,
    subInvOrgCode: null,
    industryId: null,
  };

  const [dataCatSegment2bSelect, setDataCatSegment2bSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataWarehouseSelect, setDataWarehouseSelect] = useState<
    ComboboxItem[]
  >([]);
  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [endTime, setEndTime] = useState<Date | null>();
  const [listSubInvOrg, setListSubInvOrg] = useState([]);

  const handleSubInvOrgChange = (values: any) => {
    setListSubInvOrg(values);
    if (values.length > 1) {
      form.setValues({ subInvOrgCode: values.join(",") });
    } else {
      form.setValues({ subInvOrgCode: values });
    }
  };

  const form = useForm<TblInventoryCycleCountPeriod>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      code: (value: any | null) => {
        const invalidChars =
          /[âăáàảãạêếềệễểíìịĩỉóòỏõọôốồộổỗơớờợỡởúùủũụưứừựữửýỳỷỹỵđ]/i;
        if (invalidChars.test(value)) {
          return "Mã đợt kiểm kê chứa những kí tự không hợp lệ !";
        }
        if (!value) {
          return "Vui lòng nhập mã đợt kiểm kê !";
        }
        return hasLength(
          { max: 50 },
          "Mã đợt kiểm kê không được dài hơn 50 ký tự !"
        )(value as string);
      },
      startTime: (value, values) => {
        if (
          value &&
          values.endTime &&
          new Date(value) > new Date(values.endTime)
        ) {
          return "Thời gian bắt đầu không được trước thời gian kết thúc !";
        }
      },
      endTime: (value, values) => {
        if (
          value &&
          values.startTime &&
          new Date(value) < new Date(values.startTime)
        ) {
          return "Thời gian kết thúc không được sau thời gian bắt đầu !";
        }
      },
      orgInvId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập trung tâm !";
        }
      },
      note: (value: string | null) => {
        if (value) {
          return hasLength(
            { max: 500 },
            "Ghi chú không được dài hơn 500 ký tự !"
          )(value as string);
        }
      },
    },
  });

  const handleCreateTblInventoryCycleCountPeriod = async (
    dataSubmit: TblInventoryCycleCountPeriod
  ) => {
    open();
    const dataApi = await repositoryMdm.post<
      MessageResponse<TblInventoryCycleCountPeriod>
    >("/api/v1/TblInventoryCycleCountPeriod/create", dataSubmit);
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  const fetchCatSegment2bSelect = async () => {
    const get = await getCatSegment2bSelect();
    setDataCatSegment2bSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchBranchSelect = async () => {
    const get = await getBranchSelectMdm();
    setDataBranchSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchInventorySelect = async () => {
    const get = await getInventorySelectMdm();
    setDataWarehouseSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    Promise.all([
      fetchCatSegment2bSelect(),
      fetchBranchSelect(),
      fetchInventorySelect(),
    ]);
  }, []);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "300px", md: "350px", lg: "450px" }}
        onSubmit={form.onSubmit((e: TblInventoryCycleCountPeriod) => {
          handleCreateTblInventoryCycleCountPeriod(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Kỳ kiểm kê"
              variant="filled"
              value={`Tháng ${new Date().getMonth() + 1}`}
              defaultValue={new Date().getMonth() + 1}
              withAsterisk
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
            <DateTimePicker
              label="Thời gian bắt đầu"
              placeholder="Thời gian bắt đầu"
              defaultValue={startTime ?? ""}
              minDate={new Date()}
              locale="vi"
              clearable
              {...form.getInputProps("startTime")}
              onChange={(value: any) => {
                setStartTime(value);
                form.setValues((prev) => ({
                  ...prev,
                  startTime: value
                    ? new Date(value ?? "").toDateString()
                    : null,
                }));
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
            <DateTimePicker
              label="Thời gian kết thúc"
              placeholder="Thời gian kết thúc"
              locale="vi"
              value={endTime}
              minDate={new Date(startTime ?? "")}
              clearable
              {...form.getInputProps("endTime")}
              onChange={(value) => {
                setEndTime(value);
                form.setValues((prev) => ({
                  ...prev,
                  endTime: value ? new Date(value ?? "").toDateString() : null,
                }));
              }}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Mã kiểm kê"
              placeholder="Nhập mã đợt kiểm kê"
              onKeyDown={handleKeyDown}
              {...form.getInputProps("code")}
              withAsterisk
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 9 }}>
            <MultiSelect
              label="Kho"
              placeholder="Nhập kho"
              data={dataWarehouseSelect}
              searchable
              clearable
              hidePickedOptions
              nothingFoundMessage="Không tìm thấy dữ liệu !!!"
              {...form.getInputProps("subInvOrgCode")}
              value={listSubInvOrg}
              onChange={handleSubInvOrgChange}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label="Ngành hàng"
              placeholder="Nhập ngành hàng"
              data={dataCatSegment2bSelect}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !!!"
              {...form.getInputProps("industryId")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label="Trung tâm"
              placeholder="Nhập trung tâm"
              data={dataBranchSelect}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !!!"
              withAsterisk
              {...form.getInputProps("orgInvId")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              label="Ghi chú"
              placeholder="Nhập ghi chú"
              {...form.getInputProps("note")}
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  onClose: any;
};
