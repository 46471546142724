import { Input } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import React from "react";
type Props = {
  width?: string;
  placeholder?: string;
  leftSection?: React.ReactNode;
  onChange?: (value: string) => void;
  onSearch?: Function;
  defaultValue?: string;
};
const InputSearch = ({
  width,
  placeholder,
  onSearch,
  onChange,
  defaultValue,
}: Props) => {
  const onhandleFetchData = async () => {
    if (onSearch) {
      await onSearch();
    }
  };
  return (
    <Input
      w={width}
      placeholder={placeholder}
      type="text"
      defaultValue={defaultValue}
      leftSection={<IconSearch color="#15aabf" />}
      onKeyDown={async (e) => {
        if (e.key === "Enter") {
          onhandleFetchData();
        }
      }}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
    />
  );
};

export default InputSearch;
