import { useState, useEffect, useRef } from "react";

/**
 * Hook tính toán chiều cao động dựa trên kích thước của cửa sổ và phần tử tham chiếu
 * @param headerRef Tham chiếu đến phần tử header
 * @param offset Tổng chiều cao cố định (pagination, margin, padding, etc.)
 * @returns Chiều cao động được tính toán
 */
const useDynamicHeight = (
  headerRef: React.RefObject<HTMLElement>,
  offset: number
) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const calculateHeight = () => {
      const headerHeight = headerRef.current?.offsetHeight || 0;
      setHeight(window.innerHeight - (offset + headerHeight));
    };

    calculateHeight(); // Tính toán ban đầu
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, [headerRef, offset]);

  return height;
};

export default useDynamicHeight;
