import {
  Box,
  ComboboxItem,
  Fieldset,
  Grid,
  LoadingOverlay,
  ScrollArea,
  Select,
  Table,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblExportWarrantyVendor } from "../../../../model/TblExportWarrantyVendor";
import { DatePickerInput, DateTimePicker } from "@mantine/dates";
import cx from "clsx";
import classes from "../../../../Styles/TableScrollArea.module.css";
import {
  getBranchSelect,
  getDeliveryDepartmentSelect,
  getTblDmDeliverPrioritySelect,
  getTblDmEmployeeSelect,
  getTblLocationSelect,
} from "../../../../service/getSelectApi";
import { tblItem } from "../../../../model/TblItem";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const [faultyItemListId, setFaultyItemListId] = useState<number>(0);
  const [faultyItemListCode, setFaultyItemListCode] = useState<string>("");
  const [locationId, setLocationId] = useState<number>(0);
  const [cityName, setCityName] = useState<string>("");
  const [districtName, setDistrictName] = useState<string>("");
  const [communeName, setCommuneName] = useState<string>("");
  const [listItem, setListItem] = useState<any[]>([]);
  const [listItemId, setListItemId] = useState<number[]>([]);
  const [faultyItemListDetailCommands, setFaultyItemListDetailCommands] =
    useState<any[]>([]);
  console.log(faultyItemListDetailCommands);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataLocationSelect, setDataLocationSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataDeliveryDepartmentSelect, setDataDeliveryDepartmentSelect] =
    useState<ComboboxItem[]>([]);
  const [dataDeliverPrioritySelect, setDataDeliverPrioritySelect] = useState<
    ComboboxItem[]
  >([]);

  const [scrolled, setScrolled] = useState(false);
  const [visible, { close, open }] = useDisclosure(false);

  const entity = {
    id: "0",
    deliveryDepId: null,
    empId: null,
    vendorId: null,
    branchId: null,
    faultyItemListId: null,
    code: null,
    note: null,
    deliveryTime: null,
    locationId: null,
    deliverPriorityId: null,
    tblFaultyItemListDetailCommands: null,
  };

  const form = useForm<any>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const getItemDetailsById = (itemId: number, value: string) => {
    const item = listItem.find((d) => d.itemId === itemId);
    if (item) {
      return `${item[value]}`;
    }
    return "";
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblExportWarrantyVendor/detail?id=${id}`
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        setLocationId(dataApi.locationId);
        setFaultyItemListId(dataApi.faultyItemListId);
        setFaultyItemListDetailCommands(
          dataApi.tblFaultyItemListDetailModels ?? []
        );
        Promise.all([
          fetchLocationSelect(),
          fetchDataEmployeeSelect(),
          fetchDataBranchSelect(),
          fetchDeliveryDepartmentSelect(),
          fetchDeliverPrioritytSelect(),
        ]);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const getDetailFaultyListItem = async () => {
    let url = `?id=${faultyItemListId}`;
    const response = await repositoryMdm.get(
      `/api/v1/TblFaultyItemList/detail${url}`
    );
    if (response && response.data !== null) {
      const data = response.data;
      setFaultyItemListCode(data.code);
    }
  };

  const getLocationDetail = async () => {
    let url = `?id=${locationId}`;

    const response = await repositoryMdm.get(
      `/api/v1/TblDmLocation/detail${url}`
    );
    if (response && response.data !== null) {
      const data = response.data;
      setCityName(data.cityName);
      setDistrictName(data.districtName);
      setCommuneName(data.communeName);
    }
  };

  const getNameProduct = async () => {
    if (listItemId.length > 0) {
      let url = "";
      for (var i = 0; i < listItemId.length; i++) {
        url += `Ids=${listItemId[i]}&`;
      }
      const res = await repositoryPos.get<MessageResponse<tblItem[]>>(
        `/api/v1/TblItem/get-list-for-warranty-service-request?${url}`
      );

      if (res && res.success) {
        const data = res.data;
        setListItem(data);
      } else {
        setListItem([]);
      }
    }
  };

  const fetchDataEmployeeSelect = async () => {
    const getData = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataBranchSelect = async () => {
    const getData = await getBranchSelect();
    setDataBranchSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchLocationSelect = async () => {
    const getData = await getTblLocationSelect();
    setDataLocationSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDeliveryDepartmentSelect = async () => {
    const getData = await getDeliveryDepartmentSelect();
    setDataDeliveryDepartmentSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDeliverPrioritytSelect = async () => {
    const getData = await getTblDmDeliverPrioritySelect();
    setDataDeliverPrioritySelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (faultyItemListId !== 0) {
      getDetailFaultyListItem();
    }
  }, [faultyItemListId]);

  useEffect(() => {
    if (locationId !== 0) {
      getLocationDetail();
    }
  }, [locationId]);

  useEffect(() => {
    getNameProduct();
  }, [listItemId]);

  useEffect(() => {
    const updateItemId = () => {
      const uniqueItemId = Array.from(
        new Set(
          faultyItemListDetailCommands
            .map((item) => item.itemId)
            .filter((itemId) => itemId !== null)
        )
      ).map(Number);
      setListItemId(uniqueItemId);
    };

    updateItemId();
  }, [faultyItemListDetailCommands]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={"85vw"}
        maw={1300}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Fieldset legend="Thông tin phiếu xuất" mt={10}>
          <Grid>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Số phiếu"
                variant="filled"
                size="xs"
                readOnly
                {...form.getInputProps("code")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <DatePickerInput
                label="Ngày lập"
                valueFormat="DD/MM/YYYY"
                variant="filled"
                size="xs"
                readOnly
                value={
                  form.getValues().createDate
                    ? new Date(form.getValues().createDate ?? "")
                    : null
                }
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Người lập"
                data={dataEmployeeSelect}
                value={form.getValues().createBy?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Nhân viên thực hiện"
                data={dataEmployeeSelect}
                value={form.getValues().empId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Chi nhánh"
                data={dataBranchSelect}
                value={form.getValues().branchId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Số phiếu lỗi"
                value={faultyItemListCode}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Nhà cung cấp"
                value={form.getValues().vendorName ?? ""}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Địa điểm giao"
                placeholder="Nhập địa điểm giao"
                data={dataLocationSelect}
                value={form.getValues().locationId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Tỉnh/TP"
                value={cityName}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Quận/Huyện"
                value={districtName}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Phường/Xã"
                value={communeName}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Bộ phân giao vận"
                data={dataDeliveryDepartmentSelect}
                value={form.getValues().deliveryDepId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Mức độ ưu tiên"
                data={dataDeliverPrioritySelect}
                value={form.getValues().deliverPriorityId?.toString() ?? ""}
                rightSection={" "}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <DateTimePicker
                label="Thời gian giao hàng"
                valueFormat="DD/MM/YYYY HH:mm:ss"
                value={
                  form.getValues().deliveryTime
                    ? new Date(form.getValues().deliveryTime)
                    : null
                }
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 8 }}>
              <TextInput
                label="Ghi chú"
                variant="filled"
                size="xs"
                {...form.getInputProps("note")}
              />
            </Grid.Col>
          </Grid>
        </Fieldset>
        <Fieldset legend="Thông tin sản phẩm" mt={10}>
          <ScrollArea
            h={235}
            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
            scrollbars="x"
          >
            <Table
              striped
              highlightOnHover
              withTableBorder
              withColumnBorders
              w={1920}
            >
              <Table.Thead
                className={cx(classes.header, {
                  [classes.scrolled]: scrolled,
                })}
              >
                <Table.Tr>
                  <Table.Th>Số phiếu biên nhận</Table.Th>
                  <Table.Th>Mã sản phẩm</Table.Th>
                  <Table.Th>Tên sản phẩm</Table.Th>
                  <Table.Th>Mã vạch</Table.Th>
                  <Table.Th>Serial gốc</Table.Th>
                  <Table.Th>Phụ kiện</Table.Th>
                  {/* <Table.Th>Ngày nhập TA</Table.Th>
                  <Table.Th>Ngày hết hạn NCC</Table.Th> */}
                  <Table.Th>Loại dịch vụ</Table.Th>
                  <Table.Th>Phiếu bảo hành</Table.Th>
                  <Table.Th>Thời hạn bảo hành</Table.Th>
                  <Table.Th>Mô tả lỗi</Table.Th>
                  <Table.Th>Ghi chú nhận</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {faultyItemListDetailCommands?.map((item, index) => (
                  <Table.Tr key={index}>
                    <Table.Th>{item.warrantyReceiveTicketCode}</Table.Th>
                    <Table.Th>
                      {getItemDetailsById(item.itemId ?? 0, "itemCode")}
                    </Table.Th>
                    <Table.Th>
                      {getItemDetailsById(item.itemId ?? 0, "itemName")}
                    </Table.Th>
                    <Table.Th>{item.serial}</Table.Th>
                    <Table.Th>{item.orgSerial}</Table.Th>
                    <Table.Th>{item.accessory}</Table.Th>
                    {/* <Table.Th></Table.Th>
                    <Table.Th></Table.Th> */}
                    <Table.Th>{item.serviceType}</Table.Th>
                    <Table.Th>{item.warrantyStatus}</Table.Th>
                    <Table.Th>
                      {getItemDetailsById(item.itemId ?? 0, "warrantyVendor")}
                    </Table.Th>
                    <Table.Th>{item.description}</Table.Th>
                    <Table.Th>{item.note}</Table.Th>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </ScrollArea>
        </Fieldset>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
