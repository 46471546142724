import {
  Box,
  Checkbox,
  ComboboxItem,
  Grid,
  LoadingOverlay,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryMdm,
} from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMDeliveryJob } from "../../../../model/TblDeliveryJob";
import { getTblDmEmployeeSelect } from "../../../../service/getSelectApi";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    companyCode: null,
    name: null,
    note: null,
    active: false,
    branchId: null,
    managerId: null,
  };

  const [branchName, setBranchName] = useState<string | null>(null);
  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [dataEmployeedSelect, setDataEmployeedSelect] = useState<
    ComboboxItem[]
  >([]);

  const form = useForm<TblDMDeliveryJob>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<TblDMDeliveryJob>>(
      `/api/v1/TblDMDeliveryJob/detail?id=${id}`
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        Promise.all([getDetailBranch(), dataEmployeeSelect()]);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const getDetailBranch = async () => {
    const callapi = await repositoryDelivery.get<MessageResponse<any>>(
      `/api/v1/TblDMMasterOrg/detail?id=${form.getValues().branchId ?? ""}`
    );

    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        setBranchName(dataApi.name);
      }
    }
  };

  const dataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeedSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          // value: item.text,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "250px", md: "300px", lg: "400px" }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã CVGH"}
              type="text"
              variant="filled"
              {...form.getInputProps("code")}
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <TextInput
              label={"Tên CVGH"}
              type="text"
              variant="filled"
              {...form.getInputProps("name")}
              readOnly
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"Chi nhánh"}
              placeholder={"Nhập tên chi nhánh"}
              variant="filled"
              value={branchName ?? ""}
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label={"Người quản lý"}
              variant="filled"
              data={dataEmployeedSelect}
              value={form.values.managerId?.toString()}
              rightSection=" "
              readOnly
            />
          </Grid.Col>
        </Grid>

        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Textarea
              label={"Ghi chú"}
              placeholder="Nhập ghi chú"
              variant="filled"
              {...form.getInputProps("note")}
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <Checkbox
              label={"Sử dụng"}
              checked={form.values.active}
              readOnly
              {...form.getInputProps("active")}
              onChange={() => {}}
            />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
