import { Badge, Box, Text } from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import React from "react";
import { green } from "../../const/variables";
import { _localization } from "../../config/location";

const TableDetail = ({
  dataDetail,
  isDetail,
}: {
  dataDetail: any;
  isDetail: boolean;
}) => {
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
      },

      {
        accessorKey: "serial",
        header: "Số serial",
      },
      {
        accessorKey: "primaryUomCode",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "quality",
        header: "Số lượng",
      },
      {
        accessorKey: "lineTyle",
        header: "Loại mặt hàng",
      },
      {
        accessorKey: "testingStatus",
        header: "Trạng thái kiểm tra",
        Cell: ({ renderedCellValue, row }) => (
          <Text>
            {renderedCellValue === "TEST_OK" ? "test ok" : "test lỗi"}
          </Text>
        ),
      },

      {
        accessorKey: "noteByEmployee",
        header: "Ghi chú",
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
      },
    ],
    []
  );
  const table = useMantineReactTable({
    columns,
    data: dataDetail ? dataDetail : [],
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableBottomToolbar: false,
    enablePagination: false,
    localization: _localization,
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: 255, minHeight: 100 },
    },
    enableStickyHeader: true,
    manualFiltering: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        // padding: "5px",
      },
    }),
    manualPagination: false,
    manualSorting: false,
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  return (
    <Box>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default TableDetail;
