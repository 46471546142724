import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Menu,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowLeft, IconCheck, IconTrash } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  TblDeliveryCreate,
  TblDeliveryDetails,
} from "../../../model/TblDeliveryCreate";
import {
  getDeliveryDepartmentSelect,
  getProvinceSelect,
  getDistrictSelect,
  getCommuneSelect,
  getStreetSelect,
  getTblDmUomCodeSelect,
} from "../../../service/getSelectApi";
import { TblDelivery } from "../../../model/TblDelivery";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import React from "react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import { paginationBase } from "../../../_base/model/_base/BaseTable";

const CreateDataView = ({
  handleCreate,
  setSearch,
}: {
  handleCreate: any;
  setSearch: any;
}) => {
  const entity = {
    id: "0",
    assignmentDate: null,
    finishDate: null,
    orderHeaderId: 1,
    statusId: null,
    sourceCode: null,
    sourceType: null,
    description: null,
    isDraft: null,
    printedNumber: null,
    confirmationDate: null,
    fromDeliveryDate: null,
    toDeliveryDate: null,
    method: null,
    branchId: null,
    type: null,
    depId: null,
    returnReason: null,
    customerAddress: null,
    customerProvinceId: null,
    customerDistrictId: null,
    customerCommuneId: null,
    streetId: null,
    fromAddress: null,
    toAddress: null,
    lastUpdateDate: null,
    createBy: null,
    createDate: new Date().toDateString(),
    lastUpdateBy: null,
    parentId: null,
    priorityId: null,
    customerId: null,
    telephoneNumber: null,
    transactionNumber: null,
    transactionContent: null,
    deliveryAppointmentTime: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
    tblDeliveryDetailCommands: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataCustomer, setDataCustomer] = useState<any[]>([]);
  const [dataBranch, setDataBranch] = useState<any[]>([]);
  const [provinceId, setProvinceId] = useState<string | null>(null);
  const [districtId, setDistrictId] = useState<string | null>(null);
  const [communeId, setCommuneId] = useState<string | null>(null);
  const [dataToAddress, setDataToAddress] = useState({
    province: null,
    district: null,
    commune: null,
    specificAddress: null,
  });
  const [dataUomCode, setDataUomCode] = useState<ComboboxItem[]>([]);
  const [dataProvinceSelect, setDataProvinceSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataDistrictSelect, setDataDistrictSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataCommuneSelect, setDataCommuneSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataStreetSelect, setDataStreetSelect] = useState<ComboboxItem[]>([]);
  const [branchId, setBranchId] = useState<number | null>(null);
  const [listProductCallApi, setListProductCallApi] = useState<any[]>([]);
  const [listProduct, setListProduct] = useState<any[]>([]);
  const [product, setProduct] = useState({
    productName: "",
    quantity: 0,
  });
  const [itemCodeSelect, setItemCodeSelect] = useState<Set<any>>(new Set());
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [dataTblDeliveryDepartmentSelect, setDataTblDeliveryDepartmentSelect] =
    useState<ComboboxItem[]>([]);
  const [transactionNumber, setTransactionNumber] = useState<string>("");
  const [selectedFromAddr, setSelectedFromAddr] = useState<any | null>(null);
  const [scrolled, setScrolled] = useState(false);
  const [isEditProduct, setIsEditProduct] = useState({
    productName: false,
    quantity: false,
  });
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [queryDepartment, setQueryDepartment] = useState<string | null>(null);
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "attribute2",
        header: "Mã sản phẩm",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute5",
        header: "Serial",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.original.attribute4 === "HANG_HACOM"
              ? row.original.attribute5
              : null}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "productName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={350}>
            <Tooltip label={row.original.productName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.productName}
              </Text>
            </Tooltip>
          </Box>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute3",
        header: "Đơn vị tính",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={() => {
                  handleDeleteProduct(row.index);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [listProduct]
  );

  const form = useForm<TblDeliveryCreate>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      depId: Number(values.depId),
      customerId: Number(values.customerId),
    }),

    validate: {
      customerId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn khách hàng !";
        }
      },
      fromDeliveryDate: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn ngày giao !";
        }
      },
      deliveryAppointmentTime: (value, values) =>
        !value
          ? "Vui lòng chọn ngày hẹn giao !"
          : new Date(value) < new Date(values.fromDeliveryDate ?? "")
          ? "Ngày giao không được vượt quá ngày hẹn giao !"
          : null,
      // depId: isNotEmpty("Vui lòng chọn bộ phận GH !"),
      transactionContent: isNotEmpty("Vui lòng nhập nội dung giao hàng !"),
      telephoneNumber: (value: string | null) => {
        if (value) {
          return hasLength(
            { min: 10, max: 10 },
            "Số điện thoại phải bao gồm 10 chữ số !"
          )(value as string);
        }
      },
      branchId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập địa điểm giao hàng !";
        }
      },
      customerProvinceId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập tỉnh/thành phố !";
        }
      },
      customerDistrictId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập quận/huyện !";
        }
      },
      customerCommuneId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập phường/xã !";
        }
      },
    },
  });

  const callApiGetData = async () => {
    Promise.all([getDataBranch(), getDataProvinceSelect(), getDataUom()]);
  };

  const fetchDataCustomer = async () => {
    let url = `?Skip=0&Take=${100}`;

    if (customerId) {
      url += `&KeySearch=${customerId.trim()}`;
    }

    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmCustomer/get-customer-for-delivery${url}`
      );
      if (response && response.success) {
        const filteredData = response.data.lists.filter(
          (customer: any) =>
            customer.customerCode !== null || customer.customerName !== null
        );
        const mappedData = filteredData.map((item: any) => ({
          value: item.customerId.toString(),
          label: `${item.customerName} - ${item.customerCode}`,
        }));
        setDataCustomer(mappedData);
      }
    } catch (error) {
      setDataCustomer([]);
      return;
    }
  };

  const getDataBranch = async () => {
    const res = await repositoryDelivery.get("/api/v1/TblDmMasterOrg/get-all");

    if (res && res.success) {
      const filteredData = res.data.filter(
        (item: any) => item.code !== null || item.name !== null
      );
      const mappedData = filteredData.map((item: any) => ({
        value: item.id.toString(),
        label: item.name,
      }));
      const mappedDataAddress = filteredData.map((addr: any) => ({
        id: addr.id,
        road: addr.road,
        province: addr.province,
        country: addr.country,
      }));
      setDataBranch(mappedData);
      setSelectedFromAddr(mappedDataAddress);
    }
  };

  const handleCreateTblDelivery = async (dataSubmit: TblDeliveryCreate) => {
    if (listProduct.length === 0) {
      NotificationExtension.Fails(
        "Bạn chưa điền thông tin sản phẩm cho đơn hàng !"
      );
      return;
    } else if (
      form.getValues().customerId === null ||
      form.getValues().customerId === 0
    ) {
      NotificationExtension.Fails("Bạn chưa điền thông tin khách hàng !");
      return;
    }

    if (Array.isArray(dataSubmit.tblDeliveryDetailCommands)) {
      dataSubmit.tblDeliveryDetailCommands =
        dataSubmit.tblDeliveryDetailCommands.map((detail: any) => {
          if (detail?.attribute4 === "HANG_NGOAI") {
            return { ...detail, attribute5: null };
          }
          return detail;
        });
    }

    open();
    const dataApi = await repositoryDelivery.post<
      MessageResponse<TblDeliveryCreate>
    >("/api/v1/TblDelivery/create-other-delivery", dataSubmit);

    if (dataApi?.success) {
      const newStartDate = new Date(
        new Date(new Date().setDate(new Date().getDate() - 2)).getTime() +
          7 * 60 * 60 * 1000
      ).toISOString();

      const newEndDate = new Date(
        new Date().getTime() + 7 * 60 * 60 * 1000
      ).toISOString();

      NotificationExtension.Success("Thêm thành công !");
      setTimeout(() => {
        setSearch((prev: any) => ({
          ...prev,
          startDate: newStartDate,
          endDate: newEndDate,
        }));
        handleCreate();
      }, 500);
    } else {
      NotificationExtension.Fails("Thêm thất bại !");
    }

    close();
  };

  const getCreate = async () => {
    const dataResponse = await repositoryDelivery.get<
      MessageResponse<TblDelivery>
    >("/api/v1/TblDelivery/create?prefix=GH-");
    if (dataResponse?.success) {
      const data = dataResponse.data;
      setTransactionNumber(data.transactionNumber ?? "");
    }
  };

  const dataDeliveryDepartmentSelect = async () => {
    const getData = await getDeliveryDepartmentSelect();
    setDataTblDeliveryDepartmentSelect(
      getData
        ?.filter(
          (item: any) =>
            item.value != null &&
            item.text != null &&
            item.text.includes(queryDepartment)
        )
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataUom = async () => {
    const getData = await getTblDmUomCodeSelect();
    setDataUomCode(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataProvinceSelect = async () => {
    const getData = await getProvinceSelect();
    setDataProvinceSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataDistrictSelect = async () => {
    if (provinceId !== null) {
      const getData = await getDistrictSelect(provinceId ?? "");
      setDataDistrictSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataCommuneSelect = async () => {
    if (districtId !== null) {
      const getData = await getCommuneSelect(districtId ?? "");
      setDataCommuneSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataStreetSelect = async () => {
    if (communeId !== null) {
      const getData = await getStreetSelect(communeId ?? "");
      setDataStreetSelect(
        getData
          ?.filter((item: any) => item.value != null && item.text != null)
          ?.map((item: any) => ({
            value: item.value,
            label: item.text,
          }))
      );
    }
  };

  const getDataItem = async () => {
    const res = await repositoryPos.get(
      `/api/v1/TblInventorySerialOnHand/get-list?KeySearch=${product.productName}&Take=100`
    );

    if (res && res?.success) {
      const result = res.data;
      setListProductCallApi(result);
    } else {
      setListProductCallApi([]);
    }
  };

  const handleSelectProduct = (listData: any, index: number) => {
    setListProduct((prev) => [
      ...prev,
      {
        id: "0",
        orderHeaderId: null,
        orderDetailId: null,
        deliveryQuantity: 1,
        cancelledQuantity: null,
        shippedQuantity: null,
        productName: listData[index].tenSp,
        lastUpdateDate: null,
        createBy: null,
        createDate: null,
        lastUpdateBy: null,
        deliveryId: null,
        attribute5: listData[index].maBarcode,
        attribute2: listData[index].maSp,
        attribute3: listData[index].dvt,
        attribute4: "HANG_HACOM",
      },
    ]);
  };

  const handleAddProduct = () => {
    setListProduct((prev) => [
      ...prev,
      {
        id: "0",
        orderHeaderId: null,
        orderDetailId: null,
        deliveryQuantity: 1,
        cancelledQuantity: null,
        shippedQuantity: null,
        productName: product.productName,
        lastUpdateDate: null,
        createBy: null,
        createDate: null,
        lastUpdateBy: null,
        deliveryId: null,
        attribute5: `${Math.floor(100000 + Math.random() * 900000)}`,
        attribute2: null,
        attribute3: null,
        attribute4: "HANG_NGOAI",
      },
    ]);
    setProduct({ productName: "", quantity: 0 });
  };

  const handleDeleteProduct = (index: number) => {
    const productName = listProduct[index]?.attribute5?.toString();

    if (productName) {
      setItemCodeSelect((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(productName)) {
          newSet.delete(productName);
        }
        return newSet;
      });
      setListProduct((prevData) => prevData.filter((_, i) => i !== index));
    }
  };

  const handleChangeValue = (key: string, value: any, index: number) => {
    setListProduct((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      )
    );
  };

  const handleOnChange = (value: any, key: string) => {
    if (value !== "Invalid date")
      setDataToAddress((prevData: any) => ({ ...prevData, [key]: value }));
    else {
      setDataToAddress((prevData: any) => ({ ...prevData, [key]: "" }));
    }
  };

  useEffect(() => {
    callApiGetData();
    getCreate();
  }, []);

  useEffect(() => {
    if (product.productName && product.productName.length > 2) {
      getDataItem();
    } else {
      setListProductCallApi([]);
    }
  }, [product.productName]);

  useEffect(() => {
    if (queryDepartment) {
      dataDeliveryDepartmentSelect();
    } else {
      setDataTblDeliveryDepartmentSelect([]);
    }
  }, [queryDepartment]);

  useEffect(() => {
    if (
      dataToAddress.province !== null &&
      dataCommuneSelect.length > 0 &&
      dataStreetSelect.length > 0
    ) {
      const toAddress = `${getValueById(
        dataToAddress.specificAddress ?? "",
        dataStreetSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.commune ?? "",
        dataCommuneSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.district ?? "",
        dataDistrictSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.province,
        dataProvinceSelect,
        "label"
      )}`;
      form.setValues((prev) => ({
        ...prev,
        toAddress: toAddress,
      }));
    } else if (
      dataToAddress.province !== null &&
      dataCommuneSelect.length === 0
    ) {
      const toAddress = `${dataToAddress.specificAddress} - ${
        dataToAddress.commune
      } - ${getValueById(
        dataToAddress.district ?? "",
        dataDistrictSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.province,
        dataProvinceSelect,
        "label"
      )}`;
      form.setValues((prev) => ({
        ...prev,
        toAddress: toAddress,
      }));
    } else if (
      dataToAddress.province !== null &&
      dataCommuneSelect.length > 0 &&
      dataStreetSelect.length === 0
    ) {
      const toAddress = `${dataToAddress.specificAddress} - ${getValueById(
        dataToAddress.commune ?? "",
        dataCommuneSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.district ?? "",
        dataDistrictSelect,
        "label"
      )} - ${getValueById(
        dataToAddress.province,
        dataProvinceSelect,
        "label"
      )}`;
      form.setValues((prev) => ({
        ...prev,
        toAddress: toAddress,
      }));
    }
    form.setValues((prev) => ({
      ...prev,
      customerAddress: dataToAddress.specificAddress,
      streetId: Number(dataToAddress.specificAddress),
    }));
    form.setValues((prev) => ({
      ...prev,
      customerCommuneId: Number(dataToAddress.commune),
    }));
    form.setValues((prev) => ({
      ...prev,
      customerDistrictId: Number(dataToAddress.district),
    }));
    form.setValues((prev) => ({
      ...prev,
      customerProvinceId: Number(dataToAddress.province),
    }));
  }, [dataToAddress]);

  useEffect(() => {
    form.setValues((prev) => ({
      ...prev,
      tblDeliveryDetailCommands: listProduct,
    }));
    form.setValues((prev) => ({
      ...prev,
      transactionNumber: transactionNumber,
    }));
    form.setValues((prev) => ({
      ...prev,
      fromAddress: getValueById(branchId ?? 0, selectedFromAddr, "road"),
    }));
    form.setValues((prev) => ({ ...prev, branchId: branchId }));
  }, [listProduct, transactionNumber, branchId]);

  useEffect(() => {
    const updateHeight = () => {
      if (scrollAreaRef.current) {
        const height = window.innerHeight - 500;
        scrollAreaRef.current.style.height = `${height}px`;
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (customerId && customerId.trim().length > 2) {
      const delayDebounce = setTimeout(() => {
        fetchDataCustomer();
      }, 300);

      return () => clearTimeout(delayDebounce);
    } else {
      setDataCustomer([]);
    }
  }, [customerId]);

  useEffect(() => {
    if (provinceId !== null && provinceId.length > 0) {
      getDataDistrictSelect();
    }
  }, [provinceId]);

  useEffect(() => {
    if (districtId !== null && districtId.length > 0) {
      getDataCommuneSelect();
    }
  }, [districtId]);

  useEffect(() => {
    if (communeId !== null && communeId.length > 0) {
      getDataStreetSelect();
    }
  }, [communeId]);

  const table = useMantineReactTable({
    columns,
    data: listProduct,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    renderTopToolbarCustomActions: ({ table }) => <></>,
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionNumber"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 450, minHeight: 450 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <Box
        component="form"
        onSubmit={form.onSubmit((e: TblDeliveryCreate) => {
          handleCreateTblDelivery(e);
        })}
        onMouseDown={() =>
          setIsEditProduct({ productName: false, quantity: false })
        }
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Group justify="space-between">
          <Badge>Thêm mới đơn giao hàng</Badge>
          <Flex gap={"md"}>
            <Button
              type="button"
              color="red"
              loading={visible}
              onClick={() => handleCreate()}
              leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
            >
              Quay lại
            </Button>
            <Button
              type="submit"
              color="teal"
              loading={visible}
              leftSection={!visible ? <IconCheck size={18} /> : undefined}
            >
              Lưu
            </Button>
          </Flex>
        </Group>
        <Flex gap={"md"}>
          <Fieldset
            legend="Thông tin đơn giao hàng"
            w={"50%"}
            p={"5px 15px 15px 15px"}
          >
            <Grid>
              <Grid.Col span={12}>
                <Fieldset legend="Thông tin đơn hàng" p={"5px 15px 15px 15px"}>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <TextInput
                        label="Số giao dịch"
                        size="xs"
                        value={transactionNumber}
                        onChange={(e) =>
                          setTransactionNumber(e.currentTarget.value)
                        }
                        variant="filled"
                        withAsterisk
                        readOnly
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <DatePickerInput
                        label="Ngày lập"
                        size="xs"
                        placeholder={"Chọn ngày"}
                        defaultValue={new Date()}
                        readOnly
                        variant="filled"
                        value={new Date()}
                        valueFormat="DD/MM/YYYY"
                        // {...form.getInputProps("createDate")}
                        // onChange={(e) => {
                        //   form.setFieldValue(
                        //     "createDate",
                        //     e?.toISOString() ?? new Date().toISOString()
                        //   );
                        // }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <TextInput
                        label="Người lập"
                        size="xs"
                        variant="filled"
                        readOnly
                        value={
                          JSON.parse(localStorage.getItem("userLogin") || "{}")
                            ?.fullName || ""
                        }
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                      <DatePickerInput
                        label="Ngày giao"
                        size="xs"
                        clearable
                        valueFormat="DD/MM/YYYY"
                        minDate={new Date()}
                        placeholder={"Chọn ngày"}
                        withAsterisk
                        {...form.getInputProps("fromDeliveryDate")}
                        onChange={(e) =>
                          form.setValues((prev) => ({
                            ...prev,
                            fromDeliveryDate: e
                              ? new Date(e ?? "").toDateString()
                              : null,
                          }))
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
                      <TextInput
                        label="Ghi chú"
                        size="xs"
                        placeholder={"Nhập ghi chú"}
                        {...form.getInputProps("description")}
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>
              </Grid.Col>
              <Grid.Col span={12} mt={-5}>
                <Fieldset legend="Thông tin giao hàng" p={"5px 15px 15px 15px"}>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <DatePickerInput
                        label="Ngày hẹn giao"
                        size="xs"
                        placeholder={"Chọn ngày"}
                        valueFormat="DD/MM/YYYY"
                        minDate={new Date()}
                        withAsterisk
                        {...form.getInputProps("deliveryAppointmentTime")}
                        onChange={(e) =>
                          form.setValues((prev) => ({
                            ...prev,
                            deliveryAppointmentTime: e
                              ? new Date(e ?? "").toDateString()
                              : null,
                          }))
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <TextInput
                        label="SĐT"
                        size="xs"
                        type="number"
                        placeholder={"Nhập số điện thoại"}
                        {...form.getInputProps("telephoneNumber")}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <Menu
                        trigger="hover"
                        openDelay={100}
                        closeDelay={400}
                        width={1000}
                      >
                        <Menu.Target>
                          <TextInput
                            label="Khách hàng"
                            placeholder="Nhập mã hoặc tên khách hàng"
                            value={customerId ?? ""}
                            {...form.getInputProps("customerId")}
                            onChange={(e) =>
                              setCustomerId(e.currentTarget.value)
                            }
                            size="xs"
                            withAsterisk
                          />
                        </Menu.Target>
                        <Menu.Dropdown w={210}>
                          <Table.ScrollContainer
                            minWidth={180}
                            mah={200}
                            type="native"
                          >
                            <Table highlightOnHover>
                              <Table.Tbody>
                                {dataCustomer && dataCustomer.length > 0 ? (
                                  dataCustomer.map((item, index) => (
                                    <Table.Tr key={index}>
                                      <Table.Td
                                        onClick={() => {
                                          form.setValues((prev) => ({
                                            ...prev,
                                            customerId: Number(item.value),
                                          }));
                                          setCustomerId(
                                            item.label.split(" - ")[0]
                                          );
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Text size="xs">{item.label}</Text>
                                      </Table.Td>
                                    </Table.Tr>
                                  ))
                                ) : (
                                  <Table.Tr>
                                    <Table.Td ta={"center"}>
                                      <Text size="xs" c={"#B5B5CC"}>
                                        {customerId === null ||
                                        customerId?.length < 3
                                          ? "Nhập tối thiểu 3 kí tự"
                                          : "Không tìm thấy dữ liệu !"}
                                      </Text>
                                    </Table.Td>
                                  </Table.Tr>
                                )}
                              </Table.Tbody>
                            </Table>
                          </Table.ScrollContainer>
                        </Menu.Dropdown>
                      </Menu>
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
                      <TextInput
                        label="Nội dung giao"
                        size="xs"
                        placeholder={"Nhập nội dung"}
                        type="text"
                        withAsterisk
                        {...form.getInputProps("transactionContent")}
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>
              </Grid.Col>
              <Grid.Col span={12} mt={-5}>
                <Grid>
                  <Grid.Col span={12}>
                    <Fieldset
                      legend="Địa chỉ giao hàng"
                      p={"5px 15px 15px 15px"}
                    >
                      <Grid>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                          <Select
                            label="Địa điểm"
                            size="xs"
                            placeholder={"Chọn địa điểm"}
                            data={dataBranch}
                            searchable
                            clearable
                            {...form.getInputProps("branchId")}
                            onChange={(e) => {
                              setBranchId(e ? Number(e) ?? "" : null);
                              form.setValues((prev) => ({
                                ...prev,
                                depId: null,
                              }));
                              setQueryDepartment(
                                getValueById(
                                  e?.toString() ?? "",
                                  dataBranch,
                                  "label"
                                ).replace("HACOM", "")
                              );
                            }}
                            nothingFoundMessage="Không tìm thấy dữ liệu !"
                            withAsterisk
                          />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
                          <TextInput
                            label="Tỉnh/TP"
                            size="xs"
                            readOnly
                            variant="filled"
                            value={getValueById(
                              branchId ?? 0,
                              selectedFromAddr,
                              "province"
                            )}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 12, lg: 7 }}>
                          <TextInput
                            label="Địa chỉ"
                            size="xs"
                            readOnly
                            variant="filled"
                            value={getValueById(
                              branchId ?? 0,
                              selectedFromAddr,
                              "road"
                            )}
                          />
                        </Grid.Col>
                      </Grid>
                    </Fieldset>
                  </Grid.Col>
                  <Grid.Col span={12} mt={-5}>
                    <Fieldset legend="Địa chỉ nhận hàng">
                      <Grid>
                        <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                          <Select
                            label="Tỉnh/TP"
                            placeholder="Nhập tỉnh/thành phố"
                            data={dataProvinceSelect}
                            value={dataToAddress.province}
                            searchable
                            clearable
                            nothingFoundMessage="Không tìm thấy dữ liệu !"
                            size="xs"
                            withAsterisk
                            {...form.getInputProps("customerProvinceId")}
                            onChange={(e) => {
                              setProvinceId(e);
                              handleOnChange(e, "province");
                              handleOnChange(null, "district");
                              handleOnChange(null, "commune");
                              handleOnChange(null, "specificAddress");
                              form.setValues((prev) => ({
                                ...prev,
                                attribute3: null,
                              }));
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                          <Select
                            label="Quận/Huyện"
                            placeholder="Nhập quận/huyện"
                            data={dataDistrictSelect}
                            value={dataToAddress.district}
                            searchable
                            clearable
                            disabled={provinceId === null}
                            nothingFoundMessage="Không tìm thấy dữ liệu !"
                            size="xs"
                            withAsterisk
                            {...form.getInputProps("customerDistrictId")}
                            onChange={(e) => {
                              setDistrictId(e);
                              handleOnChange(e, "district");
                              handleOnChange(null, "commune");
                              handleOnChange(null, "specificAddress");
                              form.setValues((prev) => ({
                                ...prev,
                                attribute3: null,
                              }));
                            }}
                          />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
                          {dataCommuneSelect && dataCommuneSelect.length > 0 ? (
                            <Select
                              label="Phường/Xã"
                              placeholder="Nhập phường/xã"
                              data={dataCommuneSelect}
                              value={dataToAddress.commune}
                              searchable
                              clearable
                              disabled={
                                districtId === null || provinceId === null
                              }
                              nothingFoundMessage="Không tìm thấy dữ liệu !"
                              size="xs"
                              withAsterisk
                              {...form.getInputProps("customerCommuneId")}
                              onChange={(e) => {
                                setCommuneId(e);
                                handleOnChange(e, "commune");
                                handleOnChange(null, "specificAddress");
                                form.setValues((prev) => ({
                                  ...prev,
                                  attribute3: null,
                                }));
                              }}
                            />
                          ) : (
                            <TextInput
                              label="Phường/Xã"
                              placeholder="Nhập phường/xã"
                              value={dataToAddress.commune}
                              disabled={
                                districtId === null || provinceId === null
                              }
                              size="xs"
                              withAsterisk
                              {...form.getInputProps("customerCommuneId")}
                              onChange={(e) => {
                                setCommuneId(e.currentTarget.value);
                                handleOnChange(e, "commune");
                                handleOnChange(null, "specificAddress");
                                form.setValues((prev) => ({
                                  ...prev,
                                  attribute3: null,
                                }));
                              }}
                            />
                          )}
                        </Grid.Col>
                      </Grid>
                      <Grid>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                          {dataStreetSelect && dataStreetSelect.length > 0 ? (
                            <Select
                              label="Tên đường"
                              placeholder="Nhập tên đường"
                              data={dataStreetSelect}
                              value={dataToAddress.specificAddress}
                              disabled={
                                communeId === null ||
                                districtId === null ||
                                provinceId === null
                              }
                              onChange={(e) => {
                                handleOnChange(e, "specificAddress");
                                form.setValues((prev) => ({
                                  ...prev,
                                  attribute3: null,
                                }));
                              }}
                              size="xs"
                            />
                          ) : (
                            <TextInput
                              label="Tên đường"
                              placeholder="Nhập tên đường"
                              value={dataToAddress.specificAddress ?? ""}
                              disabled={
                                communeId === null ||
                                districtId === null ||
                                provinceId === null
                              }
                              onChange={(e) => {
                                handleOnChange(
                                  e.currentTarget.value,
                                  "specificAddress"
                                );
                                form.setValues((prev) => ({
                                  ...prev,
                                  attribute3: null,
                                }));
                              }}
                              size="xs"
                            />
                          )}
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                          <TextInput
                            label="Địa chỉ cụ thể"
                            placeholder="Nhập địa chỉ cụ thể"
                            value={form.getValues().attribute3 ?? ""}
                            onChange={(e) =>
                              form.setValues((prev) => ({
                                ...prev,
                                attribute3: e.currentTarget.value,
                              }))
                            }
                            disabled={
                              communeId === null ||
                              districtId === null ||
                              provinceId === null
                            }
                            size="xs"
                          />
                        </Grid.Col>
                      </Grid>
                    </Fieldset>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Fieldset>
          <Fieldset legend="Thông tin sản phẩm" w={"50%"}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 9 }}>
                <Menu
                  trigger="hover"
                  openDelay={100}
                  closeDelay={400}
                  width={1000}
                >
                  <Menu.Target>
                    <TextInput
                      label="Tên sản phẩm"
                      placeholder="Nhập tên sản phẩm"
                      value={product.productName}
                      onChange={(e: any) =>
                        setProduct((prev) => ({
                          ...prev,
                          productName: e ? e.target.value : null,
                        }))
                      }
                      size="xs"
                    />
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Table.ScrollContainer
                      minWidth={200}
                      mah={480}
                      type="native"
                    >
                      <Table striped withTableBorder>
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Chọn</Table.Th>
                            <Table.Th>Mã sản phẩm</Table.Th>
                            <Table.Th>Serial</Table.Th>
                            <Table.Th>Sản phẩm</Table.Th>
                            <Table.Th>Đơn vị tính</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                          {listProductCallApi &&
                          listProductCallApi.length > 0 ? (
                            listProductCallApi?.map((item, index) => (
                              <Table.Tr key={index}>
                                <Table.Th>
                                  <Button
                                    variant="filled"
                                    color="teal"
                                    onClick={() => {
                                      handleSelectProduct(
                                        listProductCallApi,
                                        index
                                      );
                                      setItemCodeSelect((prev) => {
                                        const newSet = new Set(prev);
                                        newSet.add(item.maBarcode);
                                        return newSet;
                                      });
                                    }}
                                    disabled={itemCodeSelect.has(
                                      item.maBarcode
                                    )}
                                    w={85}
                                  >
                                    {itemCodeSelect.has(item.maBarcode)
                                      ? "Đã chọn"
                                      : "Chọn"}
                                  </Button>
                                </Table.Th>
                                <Table.Th>{item.maSp}</Table.Th>
                                <Table.Th>{item.maBarcode}</Table.Th>
                                <Table.Th>{item.tenSp}</Table.Th>
                                <Table.Th>{item.dvt}</Table.Th>
                              </Table.Tr>
                            ))
                          ) : (
                            <Table.Tr>
                              <Table.Th colSpan={5} ta={"center"}>
                                {product.productName.length === 0
                                  ? "Hãy nhập mã sản phẩm, serial hoặc tên sản phẩm !"
                                  : "Không tìm thấy sản phẩm !"}
                              </Table.Th>
                            </Table.Tr>
                          )}
                        </Table.Tbody>
                      </Table>
                    </Table.ScrollContainer>
                  </Menu.Dropdown>
                </Menu>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 3 }}>
                <Button
                  size="xs"
                  mt={window.innerWidth > 1200 ? 22 : 0}
                  onClick={handleAddProduct}
                  disabled={product.productName.length === 0}
                  w={"100%"}
                >
                  Thêm sản phẩm
                </Button>
              </Grid.Col>
            </Grid>
            <Box pt={15}>
              <MantineReactTable table={table} />
            </Box>
          </Fieldset>
        </Flex>
      </Box>
    </>
  );
};

export default CreateDataView;
