import {
  Box,
  Checkbox,
  ComboboxItem,
  Grid,
  LoadingOverlay,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblInventoryCycleCountPeriod } from "../../../../model/TblInventoryCycleCountPeriod";
import {
  getCatSegment2bSelect,
  getBranchSelectMdm,
} from "../../../../service/getSelectApi";
import { DateTimePicker } from "@mantine/dates";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    cycleCountPeriod: null,
    startTime: new Date().toString(),
    endTime: new Date().toString(),
    note: null,
    orgInvId: null,
    subInvOrgCode: null,
    industryId: null,
  };

  const [dataCatSegment2bSelect, setDataCatSegment2bSelect] = useState<
    ComboboxItem[]
  >([]);

  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblInventoryCycleCountPeriod>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<
      MessageResponse<TblInventoryCycleCountPeriod>
    >(`/api/v1/TblInventoryCycleCountPeriod/detail?id=${id}`);
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        Promise.all([fetchCatSegment2bSelect(), fetchBranchSelect()]);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const fetchCatSegment2bSelect = async () => {
    const get = await getCatSegment2bSelect();
    setDataCatSegment2bSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchBranchSelect = async () => {
    const get = await getBranchSelectMdm();
    setDataBranchSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
      console.log(form.values);
    }
  }, [id]);

  return (
    <>
      <Box component="form" mx="auto" w={450} style={{ position: "relative" }}>
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Kỳ kiểm kê"
              readOnly
              {...form.getInputProps("cycleCountPeriod")}
              variant="filled"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
            <DateTimePicker
              label="Thời gian bắt đầu"
              value={
                form.values.startTime ? new Date(form.values.startTime) : null
              }
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
            <DateTimePicker
              label="Thời gian kết thúc"
              value={form.values.endTime ? new Date(form.values.endTime) : null}
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
            <TextInput
              label="Mã kiểm kê"
              placeholder="Nhập mã đợt kiểm kê"
              readOnly
              {...form.getInputProps("code")}
              variant="filled"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 9 }}>
            <TextInput
              label="Kho"
              readOnly
              {...form.getInputProps("subInvOrgCode")}
              variant="filled"
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label="Ngành hàng"
              data={dataCatSegment2bSelect}
              value={form.values.industryId?.toFixed(1).toString()}
              rightSection=" "
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label="Trung tâm"
              {...form.getInputProps("orgInvName")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Textarea
              label="Ghi chú"
              placeholder="Nhập ghi chú"
              {...form.getInputProps("note")}
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
