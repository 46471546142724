import moment from "moment";
import { dataOrderDetailTable } from "../../../../model/SaleOrderModel";
import { EmployeeModel } from "../../../../model/Employee";
import { DepartmentModel } from "../../../../model/DepartmentModel";
import { repositoryPos } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { ProductModel } from "../../../../model/TblDMItem";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";

export const formatDateString = (dateStr: string) => {
  if (dateStr === "") return "";
  const date = new Date(dateStr);
  const adjustedDate = date.setHours(date.getHours());
  return moment(new Date(adjustedDate)).format("DD-MM-YYYY HH:mm:ss");
};

export function containsDT(input: string): boolean {
  return input.includes("DT");
}

export function getGroupCode(input: string) {
  return input.split("||")[1];
}

export const handleCheckIsSuccessValidateItem = (listAdd: any[]): boolean => {
  return listAdd
    .filter((item) => !item?.attribute11)
    .every((item) => item?.attribute14 && item?.attribute2);
};

export const formatOrderDate = (date: string | undefined) => {
  // Kiểm tra nếu `date` có tồn tại và bắt đầu bằng "0001"
  if (date && date.startsWith("0001")) {
    return "";
  }
  return date || ""; // Trả về giá trị gốc nếu không bắt đầu bằng "0001"
};

export const handleRenderOptionListAdd = (
  listAdd: any[]
): { value: string; label: string }[] => {
  return listAdd
    .filter((item) => item.lineTyle === "HT" && item.attribute13)
    .map((item) => ({
      value: item.attribute13,
      label: item.attribute13,
    }));
};

export const handleCheckBackgroundColor = (type: string): string => {
  switch (type) {
    case "G":
      return "#E5E6E7"; // Màu xám
    case "H":
      return "#E5E6E7"; // Màu xám
    case "T":
      return "#E5E6E7"; // Màu đỏ cho sp trả
    default:
      return "white"; // Màu xám nếu không khớp với loại nào
  }
};

export const getTotalQuality = (items: dataOrderDetailTable[]): number => {
  return items?.reduce((total, item) => total + (item.quality || 0), 0);
};

// hàm check sản phẩm đã chọn hay chưa
export const handleCheckChooseProduct = (
  product: any,
  listAdd: any[]
): boolean => {
  const found = listAdd.some(
    (item) => item.id === product.id && item.warehouse === product.warehouse
  );
  return found;
};

export const handleGetTechnicialRequireId = (name: string) => {
  const options = [
    "Không",
    "Lắp ráp(mới)",
    "Kiểm tra(mới)",
    "Lắp ráp(mới) + Kiểm tra(mới)",
  ];

  return options?.findIndex((item) => item === name) ?? 0;
};

export const handleGetTechnicialRequireName = (id: number) => {
  const options = [
    "Không",
    "Lắp ráp(mới)",
    "Kiểm tra(mới)",
    "Lắp ráp(mới) + Kiểm tra(mới)",
  ];

  return options[id];
};

export const handlerRenderOptionSelect = (object: any) => {
  if (object) {
    const newObject = object?.map((item: any) => {
      return item?.name;
    });
    return newObject;
  }
};

export const handlerRenderOptionSelectAccountant = (object: any) => {
  if (object) {
    const newObject = object?.map((item: any) => {
      return item?.fullname;
    });
    return newObject;
  }
};

export const handleGetNameEmployee = (Employee: EmployeeModel[]) => {
  const dataName = Employee?.map((item: any) => {
    return item?.fullName;
  });
  return dataName;
};

export const handleGetNameEmployeeAll = (Employee: any) => {
  const dataName = Employee?.map((item: any) => {
    return item?.fullname;
  });
  return dataName;
};

export const handleGetNameDepartment = (Department: DepartmentModel[]) => {
  const dataName = Department?.map((item: any) => {
    return item?.name;
  });
  return dataName;
};

export const handleGetNameBranch = (Branch: any[]) => {
  const dataName = Branch?.map((item: any) => {
    return item?.text;
  });
  return dataName;
};

export const handleKeyDown = (event: any) => {
  if (event.key === "-") {
    event.preventDefault();
  }
};

export const getDeliveryMethodCode = (value: string) => {
  switch (value) {
    case "Nhận tại cửa hàng":
      return "KHONG";
    case "Nội bộ":
      return "HACOM";
    case "Đối tác vận chuyển":
      return "DOI_TAC_VAN_CHUYEN";
    default:
      return null; // Giá trị mặc định nếu không có giá trị phù hợp
  }
};

export const parseAttribute19 = (attribute19?: string) => {
  try {
    return JSON.parse(attribute19 || "{}");
  } catch {
    return { machineCode: "", quantity: 0 };
  }
};

export const handlePaymentChange = (
  key: string,
  value: any,
  setters: {
    setCashPayment: (value: any) => void;
    setBankTransfer: (value: any) => void;
    setCardPayment: (value: any) => void;
    setVnpayPayment: (value: any) => void;
  }
) => {
  switch (key) {
    case "cashPayment":
      setters.setCashPayment(value);
      break;
    case "bankTransfer":
      setters.setBankTransfer(value);
      break;
    case "cardPayment":
      setters.setCardPayment(value);
      break;
    case "vnpayPayment":
      setters.setVnpayPayment(value);
      break;
    default:
      console.warn(`Unhandled payment key: ${key}`);
  }
};

export const handleQuantityChange = async (
  e: any,
  row: any,
  branchId: number | undefined,
  location: any,
  setListAdd: any
) => {
  if (location?.state?.actionType !== "edit") {
    // Lấy số lượng tồn kho từ API
    const url = `/api/v1/TblItem/search-sell?inv=${branchId}&key=${row?.original?.itemCode.toLowerCase()}`;
    const searchResult = await repositoryPos.get<
      MessageResponse<ProductModel[]>
    >(url);

    const tonAo =
      searchResult?.data?.find(
        (item) => item?.warehouse === row?.original?.warehouse
      )?.tonao || 0;

    // Kiểm tra nếu số lượng nhập vào lớn hơn tồn kho
    if (Number(e) > tonAo) {
      NotificationExtension.Warn(
        "Số lượng vừa nhập lớn hơn tồn hàng trong kho !"
      );
      return; // Nếu không hợp lệ, không cập nhật danh sách
    }
  }

  // Cập nhật lại danh sách với số lượng mới
  setListAdd((prevData: any) => {
    const updatedData = prevData.map((item: any, i: any) =>
      i === row.index
        ? {
            ...item,
            quality: e || null,
            totalLineAmount: row?.original?.priceFinal * e,
          }
        : item
    );

    return updatedData.map((item: any) =>
      row?.original?.listIdGift?.includes(item?.id)
        ? {
            ...item,
            // Tạm bỏ logic thay đổi số lượng của quà
            // quality: e || null,
          }
        : item
    );
  });
};
