import { useDisclosure } from "@mantine/hooks";
import { TblDMInventoryGroup } from "../../../../model/TblDMInventoryGroup";
import { hasLength, useForm } from "@mantine/form";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";
import { IconCheck, IconWindow } from "@tabler/icons-react";

export const CreateDataView = ({ onClose }: CreateDateViewProps) => {
  const entity: TblDMInventoryGroup = {
    id: 0,
    invGroupCode: null,
    invGroupName: null,
    dienGiai: null,
    chucNangBan: null,
    idNguoiQuanLy: null,
    dieuChuyenLcn: null,
    idNguoiDieuChuyenLcn: null,
    dieuChuyenNoibo: null,
    idNguoiDieuChuyenNbcn: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
    createBy: null,
    empNameQuanLy: null,
    empNameLienchinhanh: null,
    empNameNoibochinhanh: null,
    text: null,
  };

  const [visible, { close, open }] = useDisclosure(false);
  const [hasLoadedData, setHasLoadedData] = useState(false);
  const form = useForm<TblDMInventoryGroup>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      invGroupCode: (value: string | null) => {
        const invalidChars =
          /[âăáàảãạêếềệễểíìịĩỉóòỏõọôốồộổỗơớờợỡởúùủũụưứừựữửýỳỷỹỵđ]/i;

        if (!value) {
          return "Vui lòng nhập mã kho !";
        }
        if (invalidChars.test(value ?? "")) {
          return "Mã kho chứa những kí tự không hợp lệ !";
        }
        return hasLength(
          { max: 20 },
          "Mã kho không được dài hơn 20 ký tự !"
        )(value as string);
      },
      invGroupName: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập loại kho!";
        }
        return hasLength(
          { max: 200 },
          "Loi kho phải nhỏ hơn 10 kí tự!"
        )(value as string);
      },
      dienGiai: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập diễn giải!";
        }
        return hasLength(
          { max: 1000 },
          "Diễn giải không quá 1000 kí tự!"
        )(value as string);
      },
      idNguoiQuanLy: (value: number | null) => {
        if (value === null) {
          return "Vui lòng chọn người quản lý!";
        }
        return null;
      },
      idNguoiDieuChuyenLcn: (value: number | null) => {
        if (value === null) {
          return "Vui lòng chọn người điều chuyển liên chi nhánh!";
        }
        return null;
      },
      idNguoiDieuChuyenNbcn: (value: number | null) => {
        if (value === null) {
          return "Vui lòng chọn người điều chuyển nội bộ!";
        }
        return null;
      },
    },
  });
  const [listData, setListData] = useState<{ value: string; label: string }[]>(
    []
  );
  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<
      MessageResponse<TblDMInventoryGroup>
    >("/api/v1/TblDmInventoryGroup/create");
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Bạn không có quyền tạo!!!");
      modals.closeAll();
    }
  };
  const fetchDataNV = async () => {
    if (hasLoadedData) return;

    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmEmployee/get-select`
      );
      if (response && response.success) {
        const data = response.data;
        const formattedData = data.map((item: any) => ({
          value: item.value,
          label: item.text,
        }));
        setListData(formattedData);
        setHasLoadedData(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleCreateTblDMInventoryGroup = async (dataSubmit: any) => {
    open();
    const dataApi = await repositoryMdm.post<MessageResponse<any>>(
      "/api/v1/TblDmInventoryGroup/create",
      dataSubmit
    );
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  useEffect(() => {
    callApiGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "300px", md: "400px", lg: "480px" }}
        onSubmit={form.onSubmit((e: any) => {
          handleCreateTblDMInventoryGroup(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={4}>
            <TextInput
              label={"Mã loại"}
              placeholder={"Nhập mã loại"}
              type="text"
              withAsterisk
              onKeyDown={handleKeyDown}
              {...form.getInputProps("invGroupCode")}
            />
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              label={"Tên loại"}
              placeholder={"Nhập tên loại "}
              type="text"
              withAsterisk
              onKeyDown={handleKeyDown}
              w={"100%"}
              {...form.getInputProps("invGroupName")}
            />
          </Grid.Col>
        </Grid>

        <Textarea
          label={"Diễn giải"}
          placeholder={"Nhập diễn giải"}
          autosize
          withAsterisk
          onKeyDown={handleKeyDown}
          {...form.getInputProps("dienGiai")}
        />

        <Grid>
          <Grid.Col>
            <Checkbox
              label={"Chức năng bán"}
              onKeyDown={handleKeyDown}
              {...form.getInputProps("chucNangBan")}
              mt={16}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col>
            {/* <TextInput
              label={"Người quản lý"}
              placeholder={"Nhập người quản lý"}
              type="text"
              onKeyDown={handleKeyDown}
              withAsterisk
              {...form.getInputProps("nguoiQuanLy")}
            /> */}
            <Select
              label={"Người quản lý"}
              placeholder={"Nhập người quản lý"}
              withAsterisk
              data={listData}
              {...form.getInputProps("idNguoiQuanLy")}
              onDropdownOpen={fetchDataNV}
            />
          </Grid.Col>
        </Grid>
        <Grid mt={8}>
          <Grid.Col>
            <Text size="md" fw="bold" ta="center">
              Điều chuyển liên chi nhánh
            </Text>
          </Grid.Col>
          <Grid.Col>
            <Checkbox
              label={"Điều chuyển"}
              onKeyDown={handleKeyDown}
              {...form.getInputProps("dieuChuyenLcn")}
            />
          </Grid.Col>
          <Grid.Col>
            {/* <TextInput
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              type="text"
              onKeyDown={handleKeyDown}
              
              {...form.getInputProps("nguoiDieuChuyenLienchinhanh")}
            /> */}
            <Select
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              withAsterisk
              data={listData}
              {...form.getInputProps("idNguoiDieuChuyenLcn")}
              onDropdownOpen={fetchDataNV}
            />
          </Grid.Col>
        </Grid>
        <Grid mt={8}>
          <Grid.Col>
            <Text size="md" fw="bold" ta="center">
              Điều chuyển nội bộ
            </Text>
          </Grid.Col>
          <Grid.Col>
            <Checkbox
              label={"Điều chuyển"}
              onKeyDown={handleKeyDown}
              {...form.getInputProps("dieuChuyenNoibo")}
            />
          </Grid.Col>
          <Grid.Col>
            {/* <TextInput
                  label={"Người điều chuyển"}
                  placeholder={"Nhập người điều chuyển"}
              type="text"
              onKeyDown={handleKeyDown}
              
              {...form.getInputProps("nguoiDieuChuyenNoibo")}
            /> */}
            <Select
              label={"Người điều chuyển"}
              placeholder={"Nhập người điều chuyển"}
              withAsterisk
              data={listData}
              {...form.getInputProps("idNguoiDieuChuyenNbcn")}
              onDropdownOpen={fetchDataNV}
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

type CreateDateViewProps = {
  onClose: any; // Function run on close the modal
};
