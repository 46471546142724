import { Badge, Group } from "@mantine/core";
import {
  IconCheck,
  IconLock,
  IconPlus,
  IconTransferIn,
  IconTransferOut,
  IconX,
} from "@tabler/icons-react";

// Define the possible status values
type RequestTransferStatusType = "201" | "224" | "230" | "223" | "227" | string;

// Props interface for RequestTransferStatus component
interface RequestTransferStatusProps {
  status: RequestTransferStatusType;
}

// RequestTransferStatus component
export const RequestTransferStatus: React.FC<RequestTransferStatusProps> = ({
  status,
}) => {
  let statusInfo: {
    color: string;
    text: string;
    icon: React.ReactNode;
  };

  switch (status) {
    case "NHAP_KHO":
      statusInfo = {
        color: "#12B886",
        text: "Nhập kho",
        icon: <IconTransferIn size={18} />,
      };
      break;
    case "QUAN_LY_TU_CHOI":
      statusInfo = {
        color: "red",
        text: "Quản lý từ chối",
        icon: <IconX size={18} />,
      };
      break;
    case "KHOA":
      statusInfo = {
        color: "red",
        text: "Khóa",
        icon: <IconLock size={18} />,
      };
      break;
    case "XUAT_KHO":
      statusInfo = {
        color: "#87DE57",
        text: "Xuất kho",
        icon: <IconTransferOut size={18} />,
      };
      break;
    case "HOAN_THANH":
      statusInfo = {
        color: "green",
        text: "Hoàn thành",
        icon: <IconCheck size={18} />,
      };
      break;
    case "DPV_TU_CHOI":
      statusInfo = {
        color: "red",
        text: "Điều phối viên từ chối",
        icon: <IconX size={18} />,
      };
      break;
    case "PHIEU_TAO_MOI":
      statusInfo = {
        color: "blue",
        text: "Phiếu tạo mới",
        icon: <IconPlus size={18} />,
      };
      break;
    default:
      statusInfo = {
        color: "gray",
        text: "Không xác định",
        icon: null,
      };
  }

  return (
    <Group>
      <Badge color={statusInfo.color} size="lg" leftSection={statusInfo.icon}>
        {statusInfo.text}
      </Badge>
    </Group>
  );
};
