import { IconMail, IconPhone, IconWorldWww } from "@tabler/icons-react";
import QRCode from "react-qr-code";
import styles from "./style.module.css";

type Item = {
  itemCode: string;
  itemName: string;
  quantity: number;
  uom: string;
  price: number;
  totalLineAmount: number;
  vatRate: number;
  vatAmount: number;
};
type Order = {
  orderId: number;
  orderNumber: string;
  createDate: string;
  casherCode: string | null;
  casherName: string | null;
  custCode: string;
  custName: string;
  telephoneNumber: string;
  amountDiscount: number;
  amountVoucher: number | null;
  pointExchangeAmout: number | null;
  amountTotal: number;
  payAmount: number | null;
  cashAmount: number | null;
  vatAmount: number | null;
  custAmountTransfer: number | null;
  cardAmount: number | null;
  codAmount: number | null;
  paymentMethod: string | null;
  discountAdded: number | null;
  discountAddedTotal: number;
  approveDate: string | null;
  approveStatus: string | null;
  orderStatus: string;
  items: Item[];
  invoiceNumber?: string; // Mã số mua hàng
  salerCode?: string; // Mã nhân viên bán hàng
  shippingFree?: number; // Phí vận chuyển
  totalQuantity?: number; // Tổng số lượng
  attribute20?: string; //thanh toán sau
  add1?: string;
  add2?: string;
  add3?: string;
  add4?: string | null;
  add5?: string | null;
  add6?: string;
};
const PrintedInvoice = ({
  invoiceData: data,
  valueQRCode,
  componentRef,
}: {
  invoiceData: Order;
  valueQRCode: any;
  componentRef: any;
}) => {
  const currentTime = new Date();
  // Định dạng ngày giờ, bao gồm cả giây
  const formattedDateTime = currentTime
    .toLocaleString("en-US", {
      year: "2-digit", // Lấy hai chữ số cuối của năm
      month: "2-digit", // Lấy tháng dưới dạng hai chữ số
      day: "2-digit", // Lấy ngày dưới dạng hai chữ số
      hour: "2-digit", // Lấy giờ dưới dạng hai chữ số
      minute: "2-digit", // Lấy phút dưới dạng hai chữ số
      second: "2-digit", // Lấy giây dưới dạng hai chữ số
      hourCycle: "h23", // Sử dụng định dạng 24 giờ
    })
    .replace(/\//g, ".")
    .replace(/, /g, " ");

  // Đổi vị trí ngày và tháng
  const rearrangedDateTime = formattedDateTime.replace(
    /^(\d{2})\.(\d{2})\.(\d{4}) (.+)$/,
    "$2.$1.$3 $4"
  );
  const formatCurrency = (value: any) => {
    if (value == null) {
      return 0;
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };
  function formatDate(dateString: string): string {
    const date = new Date(dateString);

    // Tạo các phần cần thiết cho định dạng
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); // Lấy 2 chữ số cuối của năm

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Trả về chuỗi theo định dạng mong muốn
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <div
      ref={componentRef}
      style={{
        width: "80mm",
        color: "#000",
        height: "auto",
        padding: "0 8px",
        paddingTop: "8px",
        // margin: "0 4px",
      }}
      // className={styles.container}
    >
      <div
        className={styles.textHeader}
        style={{
          textAlign: "center",
        }}
      >
        <h4
          className={styles.text}
          style={{ fontSize: "12px", marginTop: "5px", fontWeight: "700" }}
        >
          Công Ty Cổ Phần Đầu Tư Công Nghệ HACOM
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0 2px",
            marginRight: "2px",
          }}
        >
          <span
            className={styles.text}
            style={{
              fontSize: "9.3px",
              margin: "0",
              marginTop: "5px",
              padding: "0 3px",
            }}
          >
            <span>.</span> CN: 129 Lê Thanh Nghị, P. Đồng Tâm, Q. Hai Bà Trưng,
            TP. Hà Nội
          </span>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              fontSize: "9.7px",
              alignItems: "center",
              padding: "0 3px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "2px",
              }}
            >
              <span>.</span>
              <IconPhone
                stroke={2}
                style={{
                  margin: 0,
                  width: "10px",
                  marginRight: "1px",
                  marginTop: "-2px",
                }}
              />
              <span className={styles.text}>19001903</span>
            </div>
            <span style={{ margin: "0 4px" }}>|</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconMail
                stroke={2}
                style={{ width: "11px", marginRight: "2px" }}
              />
              <span className={styles.text}> dichvukhachhang@hacom.vn</span>
            </div>
            <span style={{ margin: "0 4px" }}>|</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconWorldWww
                stroke={2}
                style={{ width: "11px", marginRight: "2px" }}
              />
              <span className={styles.text} style={{ fontSize: "10px" }}>
                Hacom.vn
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3
          className={styles.text}
          style={{
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "700",
            marginTop: "3px",
            marginBottom: "7px",
            lineHeight: "1.4",
            padding: "0 2px",
          }}
        >
          HÓA ĐƠN BÁN HÀNG
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "10px",
            justifyContent: "space-between",
            margin: "0 3px",
            marginBottom: "7px",
            padding: "0 3px",
            marginRight: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              className={styles.text}
              style={{
                lineHeight: "1.2",
              }}
            >
              <span>Thời gian: {formatDate(data?.createDate)}</span>
            </p>
            <p
              className={styles.text}
              style={{
                lineHeight: "1.2",
              }}
            >
              <span>NVBH: </span>
              <span>{data?.salerCode}</span>
            </p>
            <p
              className={styles.text}
              style={{
                lineHeight: "1.2",
              }}
            >
              <span>Thu Ngân: {data?.casherCode}</span>
            </p>
            <p
              className={styles.text}
              style={{
                lineHeight: "1.2",
              }}
            >
              <span>Lần in: 01</span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "2px",
            }}
          >
            <p
              className={styles.text}
              style={{
                lineHeight: "1.2",
              }}
            >
              <span>Số ĐH: {data?.orderNumber}</span>
            </p>
            <p
              className={styles.text}
              style={{
                lineHeight: "1.2",
              }}
            >
              <span>MSKH: {data?.custCode}</span>
            </p>{" "}
            <p
              className={styles.text}
              style={{
                lineHeight: "1.2",
              }}
            >
              <span>Thời gian in: {rearrangedDateTime}</span>
            </p>
            <p
              className={styles.text}
              style={{
                lineHeight: "1.2",
              }}
            >
              <span>Số điện thoại: {data?.telephoneNumber}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          borderTop: "1px dotted #000",
          borderBottom: "1px dotted #000",
          padding: "5px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "3px",
            fontSize: "11px",
            marginBottom: "20px",
          }}
        >
          <span>Tên hàng hóa</span>
          <span>|</span>
          <span>Mã hàng</span> <span>|</span>
          <span>SL</span> <span>|</span> <span>ĐVT</span> <span>|</span>{" "}
          <span>Giá bán</span>| <span>Thành tiền</span>
        </div>
        {data?.items?.map((value: any, index: number) => (
          <div key={index} style={{ fontSize: "11.8px" }}>
            <div
              className={styles.text}
              style={{
                margin: 0,
                width: "100% !important",
                textAlign: "justify",
                textAlignLast: "left",
                marginRight: "2px",
                lineHeight: "1",
                marginTop: "-10px",
              }}
            >
              <p>{value?.itemName}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ fontWeight: 400 }}>
                {value?.itemCode} x{" "}
                {value?.quantity < 10 ? `0${value?.quantity}` : value?.quantity}{" "}
                {value?.uom} x {formatCurrency(value?.price)} =
              </div>
              <div>
                <p
                  className={styles.text}
                  style={{
                    marginRight: "4px",
                  }}
                >
                  {formatCurrency(value?.totalLineAmount)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          borderBottom: "1px dotted #000",
          padding: "6px",
          fontSize: "12px",
          margin: "-2px 0",
          marginRight: "2px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className={styles.text}>Tổng số lượng</p>
          <p
            className={styles.text}
            style={{
              fontWeight: "700",
            }}
          >
            {" "}
            {(data?.totalQuantity ?? 0) < 10
              ? `0${data?.totalQuantity ?? ""}`
              : data?.totalQuantity ?? 0}
          </p>
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className={styles.text}>Tổng tiền hàng</p>
          <p className={styles.text}>{formatCurrency(data?.amountTotal)}</p>
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* phí vận chuyển chưa có */}
          <p className={styles.text}>Phí vận chuyển</p>
          <p className={styles.text}>
            {formatCurrency(data?.shippingFree) ?? 0}
          </p>
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className={styles.text}>Tổng giảm trừ</p>
          <p className={styles.text}>
            {formatCurrency(data?.amountDiscount) ?? 0}
          </p>
        </div>{" "}
        {/* <Flex style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
                <p className={styles.text}>Mã số mua hàng</p>
                <p className={styles.text}>{data?.invoiceNumber}</p>
              </Flex>{" "} */}
        {/* <Flex style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <p className={styles.text}>Tiền tích lũy sử dụng</p>
            <p className={styles.text}>{formatCurrency(data?.amountVoucher)}</p>
          </Flex>{" "} */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className={styles.text}>Số tiền cần thanh toán</p>
          <p
            className={styles.text}
            style={{
              fontWeight: "700",
            }}
          >
            {formatCurrency(data?.vatAmount)}
          </p>
        </div>{" "}
        <div>
          <p
            className={styles.text}
            style={{ textAlign: "center", fontStyle: "italic" }}
          >
            (Giá đã bao gồm thuế GTGT)
          </p>
        </div>{" "}
        {/* <Flex style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
                <p className={styles.text}>Số tiền tích lũy</p>
                <p className={styles.text}>
                  {formatCurrency(data?.pointExchangeAmout)}
                </p>
              </Flex>{" "} */}
      </div>
      <div
        style={{
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          fontSize: "12px",
          margin: "-1px 0",
          marginRight: "2px",
          paddingBottom: "-4px",
        }}
      >
        <p className={styles.text} style={{ fontWeight: "700" }}>
          Thanh toán trước
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "4px",
          }}
        >
          <p className={styles.text}>Tiền mặt</p>
          <p className={styles.text}>{formatCurrency(data?.cashAmount)}</p>
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "4px",
          }}
        >
          <p className={styles.text}>Chuyển khoản</p>
          <p className={styles.text}>
            {formatCurrency(data?.custAmountTransfer)}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "4px",
          }}
        >
          <p className={styles.text}>Thanh toán thẻ</p>
          <p className={styles.text}>{formatCurrency(data?.cardAmount)}</p>
        </div>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "4px",
          }}
        >
          <p className={styles.text} style={{ fontWeight: "700" }}>
            Tổng
          </p>
          <p className={styles.text} style={{ fontWeight: "700" }}>
            {formatCurrency(
              (data?.cashAmount ?? 0) +
                (data?.custAmountTransfer ?? 0) +
                (data?.cardAmount ?? 0)
            )}
          </p>
        </div>
        <p className={styles.text} style={{ fontWeight: "700" }}>
          Thanh toán sau
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "4px",
          }}
        >
          <p className={styles.text}>
            {data?.attribute20 === "COD" ? "C.O.D" : data?.attribute20}
          </p>
          <p className={styles.text} style={{ fontWeight: "600" }}>
            {formatCurrency(data?.codAmount)}
          </p>
        </div>{" "}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          paddingBottom: "-4px",
          fontSize: "12.2px",
        }}
      >
        <p
          className={styles.text}
          style={{ flex: "60%", fontSize: "12.2px", paddingRight: "3px" }}
        >
          Quý khách vui lòng quét mã QR để thanh toán chuyển khoản
        </p>
        <QRCode
          value={""}
          style={{
            width: "50px",
            height: "50px",
            marginRight: "3px",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          fontSize: "12.2px",
        }}
      >
        <p className={styles.text}>
          Mọi góp ý, thắc mắc, khiếu nại Quý Khách vui lòng liên hệ với bộ phận
          CSKH theo hotline{" "}
          <span
            style={{
              fontWeight: "700",
            }}
          >
            19001903
          </span>{" "}
          hoặc tại{" "}
          <span style={{ fontStyle: "italic", color: "blue" }}>
            hacom.vn/lien-he
          </span>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          fontSize: "12.2px",
        }}
      >
        <p className={styles.text}>
          <span style={{ fontWeight: "700" }}> HACOM </span>chỉ xuất hóa đơn VAT
          trong ngày.
        </p>
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          fontSize: "12.2px",
        }}
      >
        <p className={styles.text}>
          Chính sách bảo hành vui lòng xem tại: <br />
          <span
            style={{
              fontStyle: "italic",
              textDecoration: "underline",
              color: "blue",
            }}
          >
            hacom.vn/hacom-chinh-sach-bao-hanh
          </span>
        </p>
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          borderBottom: "1px dotted #000",
          padding: "5px 6px",
          fontSize: "12.2px",
        }}
      >
        <p className={styles.text} style={{ flex: "60%" }}>
          Quý Khách đánh giá chất lượng hàng hóa, dịch vụ tại
        </p>
        <QRCode
          value={""}
          style={{
            width: "50px",
            height: "50px",
            marginRight: "3px",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "start",
          padding: "5px 6px",
          fontSize: "11px",
        }}
      >
        <p className={styles.text}>
          Cảm ơn Quý Khách đã mua sắm tại{" "}
          <span
            style={{
              fontWeight: "700",
            }}
          >
            HACOM
          </span>
          .
          <br />
          Hãy mua hàng tại{" "}
          <span
            style={{
              fontWeight: "700",
            }}
          >
            hacom.vn
          </span>{" "}
          để được hưởng giá ưu đãi hơn
        </p>
      </div>{" "}
    </div>
  );
};

export default PrintedInvoice;
