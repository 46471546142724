import { Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

export const UpdateStatusView = ({
  idItems,
  onClose,
  mode,
}: UpdateStatusViewProps) => {
  const handleUpdateStatus = async (status: boolean) => {
    try {
      const res = await repositoryMdm.post<MessageResponse<boolean>>(
        `/api/v1/TblApproposal/update-active?status=${status}`,
        idItems
      );
      if (res)
        if (res.success) {
          NotificationExtension.Success("Cập nhật thành công !");
          onClose((prev: any) => !prev);
        } else NotificationExtension.Fails("Cập nhật thất bại !");
      modals.closeAll();
    } catch (error) {
      NotificationExtension.Fails("Cập nhật thất bại !");
      modals.closeAll();
    }
  };
  return (
    <div>
      <Text size="20px" mt={5}>
        {mode === "approve"
          ? "Bạn có chắc chắn phê duyệt đề xuất này?"
          : "Bạn có chắc chắn từ chối đề xuất này?"}
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        {mode === "approve" && (
          <Button
            type="button"
            color={"green"}
            onClick={async () => {
              await handleUpdateStatus(true);
            }}
            leftSection={<IconCheck size={18} />}
          >
            Phê duyệt
          </Button>
        )}
        {mode === "reject" && (
          <Button
            type="button"
            color={"red"}
            onClick={async () => {
              await handleUpdateStatus(false);
            }}
            leftSection={<IconCheck size={18} />}
          >
            Từ chối
          </Button>
        )}
      </Group>
    </div>
  );
};

type UpdateStatusViewProps = {
  idItems: string[];
  onClose: any;
  mode: string;
};
