import { Box, Button, Input } from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";

import { useForm } from "@mantine/form";
import { IconSearch } from "@tabler/icons-react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { _localization } from "../../../../config/location";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import { ItemDataList } from "../../../../model/TblOrderHeader";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";
// hàm này để cho chọn ngày đúng , chứ không khi chọn sẽ bị lùi 1 ngày so với ngày chọn
const addOneDay = (isoDate: string | null) => {
  if (!isoDate) return "";
  // Tạo đối tượng Date từ chuỗi ISO
  const date = new Date(isoDate);
  // Thêm 1 ngày vào đối tượng Date
  date.setDate(date.getDate() + 1);
  // Trả về chuỗi ngày giờ theo chuẩn ISO
  return date.toISOString().slice(0, -3);
};
const ReportProductDefects = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<ItemDataList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  //chi nhánh
  const [dataBranchCode, setDataBranchCode] = useState<SelectListItemModel[]>(
    []
  );
  //Kho
  const [dataInventory, setDataInventory] = useState<SelectListItemModel[]>([]);
  //Bộ phận
  const [dataDepartment, setDataDepartment] = useState<SelectListItemModel[]>(
    []
  );
  //Khách hàng
  const [dataCustomer, setDataCustomer] = useState<SelectListItemModel[]>([]);
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      StartTime: "",
      EndTime: "",
    },
  });

  const [search, setSearch] = useState({
    itemVt: [], //chi nhán
  });
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }

    if (search.itemVt && search.itemVt.length > 0) {
      search.itemVt.forEach((itemVt) => {
        url += `&ItemId=${itemVt}`;
      });
    }
    // if (search.KhoIds && search.KhoIds.length > 0) {
    //   search.KhoIds.forEach((KhoIds) => {
    //     url += `&MaNhomVt=${KhoIds}`;
    //   });
    // }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/report-profit-loss-by-item${url}`
      );
      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };
  const columns = React.useMemo<MRT_ColumnDef<ItemDataList>[]>(
    () => [
      {
        accessorKey: "maVt",
        header: "Mã vật tư",
        size: 30,
      },
      {
        accessorKey: "tenVt",
        header: "Tên vật tư",
      },
      {
        accessorKey: "soLuong",
        header: "Số lượng vật tư",
      },
      {
        accessorKey: "tongDoanhThu",
        header: "Tổng doanh thu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "tongCk",
        header: "Tổng chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "tongSauCk",
        header: "Tổng sau chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "tongGiaVon",
        header: "Tổng giá vốn",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "loiNhuan",
        header: "Tổng lãi",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "maNhomVt",
        header: "Mã nhóm vật tư",
        size: 30,
      },
      {
        accessorKey: "tenNhomVt",
        header: "Tên nhóm vật tư",
        size: 30,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["itemCode"],
        // right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  // Lấy danh sách mã chi nhánh
  // Lấy danh sách mã bộ phận
  //   const fetchDataBp = async (Cnids: string[]) => {
  //     try {
  //       if (Array.isArray(Cnids) && Cnids.length > 0) {
  //         const response = await repositoryPos.get(
  //           `/api/v1/TblDepartment/get-all?branchId=${Cnids}`
  //         );
  //         if (response && response.httpStatusCode === 200) {
  //           const transformedData = response.data.map((item: any) => ({
  //             value: String(item.departmentId),
  //             label: item.departmentId + " - " + item.name,
  //           }));
  //           setDataDepartment(transformedData);
  //         }
  //       } else {
  //         const response = await repositoryPos.get(
  //           `/api/v1/TblDepartment/get-all`
  //         );
  //         if (response && response.httpStatusCode === 200) {
  //           const transformedData = response.data.map((item: any) => ({
  //             value: String(item.departmentId),
  //             label: item.departmentId + " - " + item.name,
  //           }));
  //           setDataDepartment(transformedData);
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       return;
  //     }
  //   };
  // Lấy danh sách mã khách hàng

  useEffect(() => {
    fetchData();
  }, [pagination]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (160 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        <Input
          style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
          placeholder="Từ khoá"
          type="text"
          leftSection={<IconSearch color="#15aabf" />}
          key={formSeachInput.key("keySearch")}
          {...formSeachInput.getInputProps("keySearch")}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              fetchData();
            }
          }}
        />

        <Button
          type="submit"
          leftSection={<IconSearch size={14} />}
          color="blue"
          variant="outline"
          onClick={async () => {
            await fetchData();
          }}
        >
          Tìm kiếm
        </Button>
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ReportProductDefects;
