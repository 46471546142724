import style from "./Printer.module.css";
import logo from "../../../assets/images/logo-hacom-2024.png";
import { table } from "console";

const PrinterReturnCustomers = ({
  innerRef,
  dataPrint,
}: {
  innerRef: any;
  dataPrint: any;
}) => {
  return (
    <div ref={innerRef} className={style.main}>
      <div className={style.header}>
        <img src={logo} alt="logo-hacom-2024" width={150} height={"auto"} />
        <div>
          <div className={style.line}>
            <h3>CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG NGHỆ HACOM</h3>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Chi nhánh: </p>
            <p>
              {
                JSON.parse(localStorage.getItem("userLogin") || "{}")
                  ?.branchName
              }
            </p>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Bộ phận/Phòng ban:</p>
            <p>Phòng kỹ thuật bảo hành</p>
          </div>
          <div className={style.line}>
            <div>
              <p className={style.cText}>Tel: </p>
              <p>19001903</p>
            </div>
            <div>
              <p className={style.cText}>Máy lẻ:</p>
              <p>25393</p>
            </div>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Email:</p>
            <p>baohanh@hacom.vn</p>
          </div>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.title}>
          <h2>PHIẾU XUẤT TRẢ BẢO HÀNH</h2>
          <p className={`${style.italic} ${style.mtt20}`}>
            Ngày {String(new Date().getDate()).padStart(2, "0")} tháng{" "}
            {String(new Date().getMonth() + 1).padStart(2, "0")} năm{" "}
            {new Date().getFullYear()}
          </p>
          <p className={`${style.mtt15}`}>Mã:</p>
        </div>
        <div className={style.info}>
          <div className={`${style.flexGap5} ${style.mbt25}`}>
            <p>Khách hàng:</p>
            <p>{dataPrint?.custMeeting}</p>
          </div>
          <div className={`${style.flexGap5} ${style.mbt25}`}>
            <p>Địa chỉ:</p>
            <p>{`${
              dataPrint?.additionalAddress &&
              dataPrint?.additionalAddress !== null
                ? `${dataPrint?.additionalAddress} -`
                : ""
            } ${
              dataPrint?.streetName && dataPrint?.streetName !== null
                ? `${dataPrint?.streetName} -`
                : ""
            } ${dataPrint?.communeName} - ${dataPrint?.districtName} - ${
              dataPrint?.provinceName
            }`}</p>
          </div>
          <div className={`${style.mbt25} ${style.flexGap50}`}>
            <div className={`${style.flexGap5}`}>
              <p>Người giao dịch:</p>
              <p>{dataPrint?.custMeeting}</p>
            </div>
            <div className={`${style.flexGap5}`}>
              <p>Số điện thoại:</p>
              <p>{dataPrint?.custPhone}</p>
            </div>
          </div>
          <div className={`${style.mbt25} ${style.flexGap5}`}>
            <p>Diễn giải:</p>
            <p>
              ....................................................................................................................................................................................
            </p>
          </div>
        </div>
        <div className={`${style.product} ${style.mt15}`}>
          <p className={`${style.italic} ${style.fw} ${style.mbt25}`}>
            Dưới đây là bảng kê chi tiết sản phẩm trả cho quý khách hàng:
          </p>
          <table className={style.table}>
            <thead>
              <tr>
                <th rowSpan={2}>STT</th>
                <th colSpan={3}>Sản phẩm nhận bảo hành</th>
                <th rowSpan={2}>Ngày mua</th>
                <th rowSpan={2}>Mã phiếu nhập</th>
                <th colSpan={3}>Sản phẩm trả bảo hành</th>
                <th rowSpan={2}>Loại dịch vụ</th>
                <th rowSpan={2}>Ghi chú</th>
              </tr>
              <tr>
                <th>Tên sản phẩm</th>
                <th>Serial</th>
                <th>ĐVT</th>
                <th>Tên sản phẩm</th>
                <th>Serial</th>
                <th>ĐVT</th>
              </tr>
            </thead>
            <tbody>
              {dataPrint?.tblWarrantyServiceRequestFormDetailModels?.map(
                (item: any, index: any) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              )}
              <tr>
                <td style={{ border: "none" }}>Tổng cộng</td>
                <td
                  style={{ alignItems: "center", border: "none" }}
                  colSpan={3}
                >
                  {dataPrint?.tblWarrantyServiceRequestFormDetailModels?.length}
                </td>
                <td colSpan={2} style={{ border: "none" }}></td>
                <td
                  style={{ alignItems: "center", border: "none" }}
                  colSpan={3}
                >
                  {dataPrint?.tblWarrantyServiceRequestFormDetailModels?.length}
                </td>
                <td colSpan={2} style={{ border: "none" }}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={style.signature}>
        <div>
          <p className={style.fw}>Trưởng bộ phận</p>
          <p>
            {"("}Ký & ghi rõ họ tên{")"}
          </p>
        </div>
        <div>
          <p className={style.fw}>Người lập phiếu</p>
          <p>
            {"("}Ký & ghi rõ họ tên{")"}
          </p>
        </div>
        <div>
          <p className={style.fw}>Thủ kho</p>
          <p>
            {"("}Ký & ghi rõ họ tên{")"}
          </p>
        </div>
        <div>
          <p className={style.fw}>Người nhận hàng</p>
          <p>
            {"("}Ký & ghi rõ họ tên{")"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrinterReturnCustomers;
