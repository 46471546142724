import {
  Box,
  Checkbox,
  Flex,
  Grid,
  NumberInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core";

const OrtherConditions = () => {
  return (
    <Box w={"100%"} mt={10}>
      <Grid align="center">
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Checkbox label="Duy nhất CMND" />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Checkbox label="Duy nhất số điện thoại" />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Flex align={"center"} gap={20}>
            <Text size="12px">Độ tuổi</Text>
            <NumberInput hideControls size="xs" w={"10%"} />
            <Text size="12px">Đến</Text>
            <NumberInput hideControls size="xs" w={"10%"} />
          </Flex>
        </Grid.Col>
      </Grid>
      <Grid m={"10px 0px"}>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Select label="Giới tính" data={["Nam", "Nữ"]} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Select
            label="Tỉnh/Thành phố"
            data={["Tỉnh 1", "Tỉnh 2", "Tỉnh 3", "Tỉnh 4", "Tỉnh 5"]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Select label="Quận/Huyện" data={["Huyện 1", "Huyện 2", "Huyện 3"]} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Select
            label="Nghề nghiệp"
            data={["Học sinh", "Sinh viên", "Đi làm", "Nghỉ hưu"]}
          />
        </Grid.Col>
      </Grid>
      <Grid m={"10px 0px"}>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput label="Giá trị đơn hàng tối thiểu" />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput label="Giá trị đơn hàng đã mua" />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <TextInput label="Thời hạn đã mua (ngày)" />
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default OrtherConditions;
