import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Menu,
  NumberFormatter,
  rem,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
  IconCaretDown,
  IconEdit,
  IconFileUpload,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryPos } from "../../../../../../_base/_const/_constVar";
import { _localization } from "../../../../../../config/location";
import { MessageResponse } from "../../../../../../model/MessageResponse";
import {
  tblPromotion,
  tblPromotionItemPrimaryCommand,
} from "../../../../../../model/TblPromotion";
import ModalItemPrimaryDiscountToAll from "../../Modals/ModalItemPrimaryDiscountToAll";
import ModalItemPrimaryEditDiscount from "../../Modals/ModalItemPrimaryEditDiscount";
import ModalSearchItems from "../../Modals/SearchItems";
import { tblItemByCat } from "../../../../../../model/TblItem";
import { NotificationExtension } from "../../../../../../_base/extension/NotificationExtension";

const TableItemPrimaryPromotion = ({
  form,
  setDeleteData,
  dataInit,
}: {
  form: UseFormReturnType<tblPromotion, (values: tblPromotion) => tblPromotion>;
  setDeleteData: any;
  dataInit: tblPromotionItemPrimaryCommand[];
}) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });
  const [keySearch, setKeySearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [data, setData] = useState<tblPromotionItemPrimaryCommand[]>(dataInit);
  const [dataTemp, setDataTemp] = useState<tblPromotionItemPrimaryCommand[]>(
    []
  );

  const entity: tblPromotionItemPrimaryCommand = {
    id: 0,
    promoteId: null,
    idItemPrimary: null,
    price: null,
    priceNotTakePromote: null,
    discountPercent: null,
    discountAmount: null,
    quantityNeededToBuyRule: null,
    amountNeededToBuyRule: null,
    quantityMin: null,
    quantityMax: null,
    // attribute1: null,
    // attribute2: null,
    // attribute3: null,
    // attribute4: null,
    // attribute5: null,
    // attribute6: null,
    // attribute7: null,
    // attribute8: null,
    // attribute9: null,
    // attribute10: null,
    // attribute11: null,
    // attribute12: null,
    // attribute13: null,
    // attribute14: null,
    // attribute15: null,
    itemPrimaryCode: null,
    itemPrimaryName: null,
  };

  const columns = React.useMemo<
    MRT_ColumnDef<tblPromotionItemPrimaryCommand>[]
  >(
    () => [
      {
        accessorKey: "itemPrimaryCode",
        header: "Mã hàng",
        size: 100,
      },
      {
        accessorKey: "itemPrimaryName",
        header: "Tên hàng",
        size: 500,
      },
      {
        accessorKey: "discountPercent",
        header: "% chiết khấu",
        Cell: ({ renderedCellValue, cell }) => `${renderedCellValue || 0}%`,
      },
      {
        accessorKey: "discountAmount",
        header: "Số tiền chiết khấu",
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "quantityMin",
        header: "SL tối thiểu 1 lần phải mua/1 Đ.Hàng",
      },
      {
        accessorKey: "quantityMax",
        header: "SLượng tối đa 1 lần được mua/1 Đ.Hàng",
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableColumnActions: false,
        enableSorting: false,
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {processTaskActionMenu(row)}
          </Box>
        ),
      },
    ],
    []
  );

  function processTaskActionMenu(row: MRT_Row<tblPromotionItemPrimaryCommand>) {
    return (
      <>
        <Tooltip label="Sửa chiết khấu">
          <ActionIcon variant="light" aria-label="Settings" color="yellow">
            <IconEdit
              size={14}
              stroke={1.5}
              onClick={() => openModalItemPrimaryEditDiscount(row.original)}
            />
          </ActionIcon>
        </Tooltip>
        {/* <Tooltip label="Xem chi tiết chiết khấu">
          <ActionIcon variant="light" aria-label="Settings" color="cyan">
            <IconEye
              size={14}
              stroke={1.5}
              //   onClick={}
            />
          </ActionIcon>
        </Tooltip> */}
        <Tooltip label="Xóa sản phẩm">
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color="red"
            onClick={() => deletesData([row.id])}
          >
            <IconTrash size={14} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </>
    );
  }

  const openSearchItems = () => {
    modals.openConfirmModal({
      size: "90vw",
      centered: true,
      title: <Title order={5}>Tìm kiếm mặt hàng</Title>,
      children: (
        <ModalSearchItems
          handleAddItem={handleAddItem}
          handleAddSearchItem={handleAddSearchItem}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const openModalItemPrimaryDiscountToAll = () => {
    modals.openConfirmModal({
      size: "450",
      title: <Title order={5}>Nhập chiết khấu</Title>,
      children: (
        <ModalItemPrimaryDiscountToAll
          appleDiscountToAll={appleDiscountToAll}
          itemType="primary"
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const openModalItemPrimaryEditDiscount = (
    dataEdit: tblPromotionItemPrimaryCommand
  ) => {
    modals.openConfirmModal({
      size: "450",
      title: <Title order={5}>Nhập chiết khấu</Title>,
      children: (
        <ModalItemPrimaryEditDiscount
          dataEdit={dataEdit}
          editItemDiscount={editItemDiscount}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const mapSegments = (array: { code: string; type: number }[]) => {
    const segments: { [key: string]: string[] } = {
      segment1: [],
      segment2: [],
      segment3: [],
      segment4: [],
      segment5: [],
      segment6: [],
    };

    array?.forEach((item) => {
      const segmentKey = `segment${item?.type}`;

      if (segments[segmentKey]) {
        segments[segmentKey].push(item?.code);
      }
    });

    return segments;
  };

  const handleAddItem = async (
    selectedCatSegment: { code: string; type: number }[],
    selectedManuFacTurer: string[],
    selectCat: string | null
  ) => {
    let url = `/api/v1/TblPromotion/get-list-item`;

    const dataSegment = selectedCatSegment.filter(
      (item) => item !== null && item !== undefined
    );

    const dataSubmit = {
      keySearch: "",
      sort: "",
      isOrder: true,
      active: true,
      skip: 0,
      take: 1000,
      ...mapSegments(dataSegment),
    };

    const dataApi = await repositoryPos.post<MessageResponse<any[]>>(
      url,
      dataSubmit
    );
    if (dataApi?.success && dataApi.data) {
      if (dataApi?.data?.length < 1) {
        NotificationExtension.Warn("Không có sản phẩm tồn kho !");
        return;
      }
      const newData: tblPromotionItemPrimaryCommand[] = dataApi?.data.map(
        (item) => ({
          ...entity,
          idItemPrimary: item.itemId,
          itemPrimaryCode: item.itemCode,
          itemPrimaryName: item.itemName,
        })
      );
      setData(newData);
      setDataTemp(newData);
      setDeleteData((prevData: any) => [...prevData, ...newData]);
      setRowCount(dataApi?.totalCount || 0);
      setSelectIds([]);
      table.resetRowSelection();
      NotificationExtension.Success("Thêm sản phẩm thành công !");
      modals.closeAll();
    } else {
      setData([]);
      setDataTemp([]);
      setSelectIds([]);
      table.resetRowSelection();
    }
  };

  const handleAddSearchItem = (selectItem: tblItemByCat[]) => {
    const newData: tblPromotionItemPrimaryCommand[] = selectItem?.map(
      (item) => ({
        ...entity,
        idItemPrimary: item.itemId,
        itemPrimaryCode: item.itemCode,
        itemPrimaryName: item.itemName,
        quantityMin: 1,
      })
    );

    const addUniqueItems = (prevData: tblPromotionItemPrimaryCommand[]) => {
      const uniqueItemsMap = new Map<number, tblPromotionItemPrimaryCommand>();

      [...newData, ...prevData].forEach((item) => {
        if (item.idItemPrimary !== null) {
          uniqueItemsMap.set(item.idItemPrimary, item);
        }
      });
      return Array.from(uniqueItemsMap.values());
    };

    setData((prevData) => addUniqueItems(prevData));
    setDataTemp((prevData) => addUniqueItems(prevData));
    setRowCount((prevData) => prevData + selectItem.length);
    setSelectIds([]);
    table.resetRowSelection();
    modals.closeAll();
  };

  const handleChangeSearch = (e: any) => {
    const value = e.target.value?.trim() ?? "";
    setKeySearch(value);
    if (value.toLowerCase()) {
      const searchResult = dataTemp.filter(
        (product) =>
          product.itemPrimaryCode?.toLowerCase().includes(value) ||
          product.itemPrimaryName?.toLowerCase().includes(value) ||
          product.itemPrimaryCode?.toUpperCase().includes(value) ||
          product.itemPrimaryName?.toUpperCase().includes(value)
      );
      setRowCount(searchResult.length);
      setSelectIds([]);
      table.resetRowSelection();
      setData(searchResult);
    } else {
      setRowCount(dataTemp.length);
      setData(dataTemp);
      setSelectIds([]);
      table.resetRowSelection();
    }
  };

  const appleDiscountToAll = (dataSubmit: {
    discountPercent: null;
    discountAmount: null;
    quantityMin: null;
    quantityMax: null;
  }) => {
    const select = table.getSelectedRowModel().rows.map((row) => row.original);

    if (select.length > 0) {
      setDataTemp((prevData) =>
        prevData.map((item) => {
          const selectedItem = select.find(
            (selected) => selected.itemPrimaryCode === item.itemPrimaryCode
          );
          return selectedItem ? { ...item, ...dataSubmit } : item;
        })
      );
      setData((prevData) =>
        prevData.map((item) => {
          const selectedItem = select.find(
            (selected) => selected.itemPrimaryCode === item.itemPrimaryCode
          );
          return selectedItem ? { ...item, ...dataSubmit } : item;
        })
      );
    } else {
      setDataTemp((prevData) =>
        prevData.map((item) => ({ ...item, ...dataSubmit }))
      );
      setData((prevData) =>
        prevData.map((item) => ({ ...item, ...dataSubmit }))
      );
    }

    modals.closeAll();
  };

  const editItemDiscount = (dataSubmit: tblPromotionItemPrimaryCommand) => {
    setDataTemp((prevData) =>
      prevData.map((item) =>
        item.idItemPrimary === dataSubmit.idItemPrimary ? dataSubmit : item
      )
    );
    setData((prevData) =>
      prevData.map((item) =>
        item.idItemPrimary === dataSubmit.idItemPrimary ? dataSubmit : item
      )
    );

    modals.closeAll();
  };

  const deletesData = (ids: string[]) => {
    setDataTemp((prevData) =>
      prevData.filter(
        (item) => !ids.includes(item?.idItemPrimary?.toString() || "")
      )
    );
    setData((prevData) =>
      prevData.filter(
        (item) => !ids.includes(item?.idItemPrimary?.toString() || "")
      )
    );

    setSelectIds([]);
    table.resetRowSelection();
  };

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (465 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    form.setFieldValue("tblPromotionItemPrimaryCommands", data);
  }, [data]);

  useEffect(() => {
    console.log("Init");
    setData(dataInit);
    setRowCount(dataInit.length);
    form.setFieldValue("tblPromotionItemPrimaryCommands", dataInit);
  }, [dataInit]);

  const table = useMantineReactTable<tblPromotionItemPrimaryCommand>({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Box>
            <Flex gap="md">
              <TextInput
                placeholder="Nhập từ khoá"
                value={keySearch}
                onChange={handleChangeSearch}
              />

              {/* <TextInput placeholder="Hãng SX" /> */}
            </Flex>
          </Box>
          {/* <Button
            leftSection={<IconSearch size={14} />}
            color="blue"
            variant="outline"
            onClick={async () => {
              // await fetchData();
            }}
          >
            Lọc bảng giá
          </Button> */}
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={"flex-end"}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconPlus size={14} />}
            color="blue"
            variant="outline"
            onClick={openSearchItems}
          >
            Thêm mới (F11)
          </Button>

          <Button
            leftSection={<IconTrash size={14} />}
            color="red"
            variant="outline"
            onClick={() => {
              deletesData(selectIds);
            }}
            disabled={selectIds.length < 1}
          >
            Xóa (đã chọn)
          </Button>

          <Button
            leftSection={<IconEdit size={14} />}
            color="green"
            variant="outline"
            onClick={() => openModalItemPrimaryDiscountToAll()}
            disabled={data.length < 1}
          >
            Thiết lập chiếu khấu
          </Button>

          <Menu shadow="md">
            <Menu.Target>
              <Button
                rightSection={
                  <IconCaretDown style={{ width: rem(14), height: rem(14) }} />
                }
              >
                Chức năng
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Dữ liệu</Menu.Label>
              <Tooltip label="Chức năng đang cạp nhật">
                <Menu.Item
                  leftSection={
                    <IconFileUpload
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Import Excel
                </Menu.Item>
              </Tooltip>
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    enableRowVirtualization: true,
    enableBatchRowSelection: true,
    enablePagination: true,
    selectAllMode: "all",
    rowVirtualizerOptions: { overscan: 8 },
    getRowId: (row) => row?.idItemPrimary?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: [
          "mrt-row-select",
          "apply",
          "itemPrimaryCode",
          "namePurchase",
          "nameManufacturer",
        ],
        right: ["allocationRate", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        // fontWeight: "bold",
        fontSize: "11px",
        padding: "4px 12px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      // showProgressBars: isRefetching,
      // showSkeletons: isLoading, //loading for the first time with no data
      //   sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return <MantineReactTable table={table} />;
};

export default TableItemPrimaryPromotion;
