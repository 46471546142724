import { repositoryPos } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { GetListInwardParams } from "../types/GetListInwardParams";
import { TblInwardGetList } from "../types/TblWarehouseOutward";

export const getListInward = async (
  params: GetListInwardParams
): Promise<MessageResponse<TblInwardGetList[]> | undefined> => {
  const baseUrl = "/api/v1/TblItemTransaction/get-list?";

  const url = Object.entries(params)
    .reduce((acc, [key, value]) => {
      if (value !== undefined && value !== "" && value !== null) {
        return `${acc}&${key}=${encodeURIComponent(value)}`;
      }
      return acc;
    }, baseUrl)
    .replace("&", "");

  return repositoryPos.get<MessageResponse<TblInwardGetList[]>>(url);
};
