import {
  Box,
  Button,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { sky_blue } from "../../../const/variables";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { getTblDmEmployeeSelect } from "../../../service/getSelectApi";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { useDebouncedCallback, useDisclosure } from "@mantine/hooks";
import {
  CreateWarrantyInformation,
  UpdateWarrantyInformation,
} from "../../../model/WarratyInformation";
import { useForm } from "@mantine/form";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

const UpdateWarrantyAssignment = ({
  id,
  onClose,
  dataRequestFormSelect,
}: {
  id: any;
  onClose: any;
  dataRequestFormSelect: any;
}) => {
  const entity = {
    assignmentCode: null,
    assignmentInchargeId: null,
    assignmentSupportId: null,
    requestFormId: null,
    empInchargeId: null,
    empSupportId: null,
    note: null,
    levelId: null,
    assignDate: null,
    points: null,
  };

  console.log(dataRequestFormSelect);

  const form = useForm<UpdateWarrantyInformation>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      empInchargeId: Number(values.empInchargeId),
      empSupportId: Number(values.empSupportId),
    }),

    validate: {
      empInchargeId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập tên người nhận phân công !";
        }
      },
    },
  });

  const [dataSubmit, setDataSubmit] = useState<UpdateWarrantyInformation>();
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataEmployeeSelectForDep, setDataEmployeeSelectForDep] = useState<
    ComboboxItem[]
  >([]);

  const [visible, { close, open }] = useDisclosure(false);
  const [enable, setEnable] = useState(false);

  const handleChangeValue = useDebouncedCallback((value: any, key: string) => {
    if (value !== "Invalid date")
      setDataSubmit((prevData: any) => ({ ...prevData, [key]: value }));
    else {
      setDataSubmit((prevData: any) => ({ ...prevData, [key]: "" }));
    }
  }, 300);

  const handleChangeValueForm = useDebouncedCallback(
    (value: string, key: string) => {
      if (value !== "Invalid date")
        form.setValues((prevData: any) => ({
          ...prevData,
          [key]: value,
        }));
      else {
        form.setValues((prevData: any) => ({
          ...prevData,
          [key]: "",
        }));
      }
    },
    300
  );

  const handleUpdateWarrantyAssign = async () => {
    open();
    const req = await repositoryMdm.post<
      MessageResponse<CreateWarrantyInformation>
    >(`/api/v1/TblWarrantyServiceRequestForm/update-assignment`, dataSubmit);
    if (req?.success) {
      NotificationExtension.Success("Chỉnh sửa phân công thành công !");
      setTimeout(() => {
        onClose((prev: any) => !prev);
        modals.closeAll();
      }, 1500);
    }
    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblWarrantyServiceRequestForm/get-to-update-error?id=${id}`
    );
    if (!callapi?.success) {
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        setDataSubmit({
          assignmentCode: dataApi.assignmentCode,
          assignmentInchargeId: dataApi.assignmentInchargeId,
          assignmentSupportId: dataApi.assignmentSupportId,
          requestFormId: id,
          empInchargeId: dataApi.empInchargeId,
          empSupportId: dataApi.empSupportId,
          note: dataApi.note,
          levelId: dataApi.levelId,
          assignDate: dataApi.assignDate,
          points: dataApi.points,
        });
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  const getDataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataEmployeeForDep = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${dataRequestFormSelect?.branchId}&Take=200`
    );

    if (res && res?.success) {
      setDataEmployeeSelectForDep(
        res.data
          ?.filter(
            (item: any) =>
              item.id != null &&
              item.fullname != null &&
              (item.roleName.toLowerCase().includes("bảo hành") ||
                item.roleName.toLowerCase().includes("giao vận"))
          )
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.fullname,
          }))
      );
    }
  };

  const callApi = async () => {
    try {
      open();
      await Promise.all([getDataEmployeeSelect(), getDataEmployeeForDep()]);
    } catch (error) {
      console.error(error);
    } finally {
      close();
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  return (
    <Box
      component="form"
      mx="auto"
      w={"50vw"}
      maw={700}
      onSubmit={form.onSubmit(() => {
        handleUpdateWarrantyAssign();
      })}
      mt={5}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Grid>
        <Grid.Col span={{ base: 12, lg: 4 }}>
          <TextInput
            label="Số phân công"
            value={form.getValues().assignmentCode ?? ""}
            variant="filled"
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 4 }}>
          <DateTimePicker
            label="Ngày phân công"
            placeholder="Chọn ngày phân công"
            value={
              form.getValues().assignDate
                ? new Date(form.getValues().assignDate ?? "")
                : null
            }
            valueFormat="DD/MM/YYYY hh:mm A"
            clearable
            variant="filled"
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 4 }}>
          <TextInput
            label="Người phân công"
            value={
              JSON.parse(localStorage.getItem("userLogin") ?? "")?.fullName ||
              ""
            }
            variant="filled"
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 6 }}>
          <Select
            label="Người nhận phân công"
            placeholder="Nhập tên người nhận phân công"
            data={dataEmployeeSelectForDep?.filter(
              (item: any) =>
                item.value !== form.getValues().empSupportId?.toString()
            )}
            searchable
            clearable
            {...form.getInputProps("empInchargeId")}
            value={dataSubmit?.empInchargeId?.toString()}
            onChange={(e) => {
              handleChangeValue(Number(e), "empInchargeId");
              handleChangeValueForm(e?.toString() ?? "", "empInchargeId");
            }}
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            withAsterisk
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 6 }}>
          <Select
            label="Người hỗ trợ"
            placeholder="Nhập tên người hỗ trợ"
            data={dataEmployeeSelectForDep?.filter(
              (item: any) =>
                item.value !== form.getValues().empInchargeId?.toString()
            )}
            searchable
            clearable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            {...form.getInputProps("empSupportId")}
            value={dataSubmit?.empSupportId?.toString()}
            onChange={(e) => {
              handleChangeValue(Number(e), "empSupportId");
              handleChangeValueForm(e?.toString() ?? "", "empSupportId");
            }}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            label="Nội dung phân công"
            placeholder="Nhập nội dung phân công"
            {...form.getInputProps("note")}
            onChange={(e) => {
              handleChangeValue(e.currentTarget.value, "note");
              handleChangeValueForm(e.currentTarget.value, "note");
            }}
          />
        </Grid.Col>
      </Grid>
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
        >
          Quay lại
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          disabled={!enable}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default UpdateWarrantyAssignment;
