import { FormValidateInput } from "@mantine/form/lib/types";
import { hasLength, isNotEmpty } from "@mantine/form";
import { ListAuthorizeCommand } from "../model/ListAuthorizeCommand";
import { ListAppModel } from "../model/ListAppModel";

export const _validateListAppModelValidator: FormValidateInput<ListAppModel> = {
    name: (value: string | null) => {
        if (!value) {
            return "Vui Lòng Nhập Tên !";
        }
        return hasLength(
            { min: 2, max: 50 },
            "Tên phải từ 2-50 kí tự!"
        )(value as string);
    }
};
