import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
  repositoryDelivery,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { sky_blue } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDeliveryAssignment } from "../../../model/TblDeliveryAssignment";
import { getDeliveryWorkSelect } from "../../../service/getSelectApi";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { SocketExtension } from "../../../_base/socket/socket";

const ModalDeliveryAssignment = ({
  selectIds,
  dataDeliverySelect,
  onClose,
}: {
  selectIds: string[] | number[];
  dataDeliverySelect: any[];
  onClose: any;
}) => {
  const entity = {
    id: "0",
    code: null,
    assignmentDate: new Date().toDateString(),
    fromExpectedDate: null,
    toExpectedDate: null,
    deliveryWorkId: null,
    employee1: null,
    employee2: null,
    mainResponsibility1: false,
    mainResponsibility2: false,
    payroll1: true,
    payroll2: true,
    vehicleCode: null,
    driverId: null,
    driverName: null,
    driverMobileNumber: null,
    fromLocationId: null,
    toLocationId: null,
    distance: null,
    distanceLevel: null,
    distanceTotal: null,
    partnerId: null,
    partnerEmployeeName: null,
    description: null,
    deliveryIds: selectIds,
    estimatedTime: null,
    tblDeliveryModels: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [dataEmployeedSelect, setDataEmployeedSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataRoadBranchs, setDataRoadBranchs] = useState<string[]>([]);
  const [listToAddress, setListToAddress] = useState<any[]>([]);
  const [dataTravelDistance, setDataTravelDistance] = useState<string[]>([]);
  const [dataSortOrder, setDataSortOrder] = useState<any[]>([]);
  const [dataSortNew, setDataSortNew] = useState<any[]>([]);
  const [dataMatric, setDataMatric] = useState<any>({
    origins: [],
    destinations: [],
  });
  const [distance, setDistance] = useState<any[]>([]);
  const [distanceTotal, setDistanceTotal] = useState<any[]>([]);
  const [dataWorkSelect, setDataWorkSelect] = useState<ComboboxItem[]>([]);
  const [distanceAPI, setDistanceAPI] = useState<number>(0);

  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [code, setCode] = useState<string | null>("");
  const [scrolled, setScrolled] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isEditSortOrder, setIsEditSortOrder] = useState({
    sortOrder: false,
  });

  const form = useForm<TblDeliveryAssignment>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      employee1: Number(values.employee1),
      employee2: Number(values.employee2),
    }),

    validate: {
      code: (value: any | null) => {
        const invalidChars =
          /[âăáàảãạêếềệễểíìịĩỉóòỏõọôốồộổỗơớờợỡởúùủũụưứừựữửýỳỷỹỵđ]/i;
        if (invalidChars.test(value)) {
          return "Mã phân công giao vận chứa những kí tự không hợp lệ !";
        }
        if (!value) {
          return "Vui lòng nhập mã phân công giao vận !";
        }
      },
      driverMobileNumber: (value: string | null | undefined) => {
        if (value) {
          return hasLength(
            { max: 10, min: 10 },
            "Số điện thoại phải đủ 10 ký tự !"
          )(value as string);
        }
      },
      employee1: (value: number | null | undefined) => {
        if (!value) {
          return "Vui lòng nhập tên nhân viên giao hàng !";
        }
      },
      fromExpectedDate: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn ngày dự kiến giao";
        }
      },
      toExpectedDate: (value, values) =>
        !value
          ? "Vui lòng chọn ngày dự kiến giao đến !"
          : new Date(value) < new Date(values.fromExpectedDate ?? "")
          ? "Ngày dự kiến giao đến không được trước ngày dự kiến giao !"
          : null,
    },
  });

  const customHeightTable = () => {
    if (dataDeliverySelect.length === 1) {
      return 80;
    } else if (dataSortOrder.length === 3) {
      return 115;
    } else if (dataSortOrder.length === 4) {
      return 145;
    } else {
      return 180;
    }
  };

  const handleChangeValue = (key: string, value: any, index: number) => {
    setDataSortOrder((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      )
    );
  };

  const handleSortData = () => {
    if (dataDeliverySelect.length > 1) {
      const dataSort = dataSortOrder.sort((a, b) => a.sortOrder - b.sortOrder);
      setDataSortNew(dataSort);
      NotificationExtension.Info("Đang tính quãng đường ...");
      handleCalculateDistance();
    } else {
      NotificationExtension.Info("Đang tính quãng đường ...");
      handleCalculateDistance();
    }
  };

  const handleCreateTblDeliveryAssignment = async (
    dataSubmit: TblDeliveryAssignment
  ) => {
    open();
    const dataApi = await repositoryDelivery.post<
      MessageResponse<TblDeliveryAssignment>
    >("/api/v1/TblDeliveryAssignment/create", dataSubmit);
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Phân đơn giao hàng thành công !");
    }
    close();
  };

  const getCreateDeliveryAssignment = async () => {
    const dataApi = await repositoryDelivery.get<
      MessageResponse<TblDeliveryAssignment>
    >("/api/v1/TblDeliveryAssignment/create?prefix=VD-");
    if (dataApi !== null) {
      const data = dataApi?.data;
      if (data) {
        setCode(data.code);
      }
    }
  };

  // const getDetailDelivery = async () => {
  //   if (selectIds.length === 1) {
  //     const dataApi = await repositoryDelivery.get<
  //       MessageResponse<TblDelivery>
  //     >(`/api/v1/TblDelivery/detail?id=${selectIds}`);
  //     if (dataApi !== null) {
  //       const data = dataApi?.data;
  //       if (data) {
  //         setFromLocation(data.fromAddress);
  //         setToLocation(data.toAddress);
  //       }
  //     }
  //   }
  // };

  const dataEmployeeSelect = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${dataDeliverySelect[0]?.branchId}&Take=200`
    );

    if (res && res?.success) {
      setDataEmployeedSelect(
        res.data
          .filter((item: any) => item.fullname != null && item.id != null)
          .map((item: any) => ({
            value: item.id?.toString(),
            label: item.fullname,
          }))
      );
    }
  };

  const dataDeliveryWorkSelect = async () => {
    const get = await getDeliveryWorkSelect();
    setDataWorkSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const handleCalculateDistance = async () => {
    const res = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Location/matric",
      dataMatric
    );

    if (res && res.success) {
      const dataResponse1 = res.data.rows;
      const dataResponse2 = res.data.rows?.slice(0, -1);
      NotificationExtension.Success("Hoàn tất tính khoảng cách !");
      if (dataDeliverySelect.length > 1) {
        if (dataResponse2) {
          dataResponse2?.map((item: any) =>
            setDistance((prev) => [...prev, item.elements].flat())
          );
        }
      } else {
        if (dataResponse1) {
          setDistance(dataResponse1);
        }
      }
    }
  };

  useEffect(() => {
    Promise.all([
      dataEmployeeSelect(),
      dataDeliveryWorkSelect(),
      getCreateDeliveryAssignment(),
      // getDetailDelivery(),
    ]);
  }, []);

  useEffect(() => {
    form.setValues((prev) => ({
      ...prev,
      code: code,
      distance: distanceAPI,
    }));
  }, [code, distanceAPI]);

  useEffect(() => {
    const updateBranchId = () => {
      const uniqueBranchId = Array.from(
        new Set(
          dataDeliverySelect
            .map((item) => item.fromAddress)
            .filter((item) => item !== null)
        )
      ).map(String);
      setDataRoadBranchs(uniqueBranchId);
    };

    const updateToAddress = () => {
      const uniqueToAddress = Array.from(
        new Set(
          dataDeliverySelect
            .map((item) => item.toAddress)
            .filter((item) => item !== null)
        )
      ).map(String);
      setListToAddress(uniqueToAddress);
    };

    updateToAddress();
    updateBranchId();
  }, [dataDeliverySelect]);

  useEffect(() => {
    setDataTravelDistance(
      Array.from(new Set([...dataRoadBranchs, ...listToAddress]))
    );

    const sortOrder = Array.from(
      new Set([...dataRoadBranchs, ...listToAddress])
    ).map((item, index) => ({
      address: item?.toString(),
      sortOrder: index,
    }));

    setDataSortOrder(sortOrder);
  }, [dataRoadBranchs, listToAddress]);

  useEffect(() => {
    if (dataDeliverySelect.length === 1) {
      setDataMatric({
        origins: dataRoadBranchs,
        destinations: listToAddress,
      });
    } else {
      setDataMatric({
        origins: dataTravelDistance,
        destinations: dataTravelDistance,
      });
    }
  }, [dataTravelDistance]);

  useEffect(() => {
    const updateDistanceValues = () => {
      let updatedDistances = [];

      for (let i = 0; i < dataSortOrder.length - 1; i++) {
        const originAddress = dataSortOrder[i].address;
        const destinationAddress = dataSortOrder[i + 1].address;

        const matchingDistance = distance.find(
          (item) =>
            (item.origin === originAddress &&
              item.destination === destinationAddress) ||
            (item.origin === destinationAddress &&
              item.destination === originAddress)
        );

        if (matchingDistance) {
          updatedDistances.push({
            origin: originAddress,
            destination: destinationAddress,
            distanceValue: matchingDistance.distance.value,
            durationValue: matchingDistance.duration.value,
          });
        }
      }

      setDistanceTotal(updatedDistances);
    };

    const updateDistance = () => {
      if (distance.length === 1) {
        setDistanceAPI(distance[0].elements[0].distance.value);
      }
    };

    updateDistanceValues();
    updateDistance();
  }, [dataSortOrder, distance]);

  useEffect(() => {
    if (distanceTotal.length > 0) {
      setDistanceAPI(
        distanceTotal.reduce((total, item) => total + item.distanceValue, 0) /
          1000
      );
    }
  }, [distanceTotal]);

  return (
    <Box
      component="form"
      m={"5px 0px"}
      w={"70vw"}
      maw={1000}
      onSubmit={form.onSubmit((e: TblDeliveryAssignment) => {
        handleCreateTblDeliveryAssignment(e);
        SocketExtension.SendMessageToEmp<any>(
          Number(form.getValues().employee1),
          {
            message: "Có đơn hàng",
            quantity: dataDeliverySelect.length,
          }
        );
      })}
      onMouseDown={() => setIsEditSortOrder({ sortOrder: false })}
      style={{ position: "relative" }}
    >
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Mã phân công giao vận"
            placeholder="Nhập mã phân công giao vận"
            value={code ?? ""}
            onChange={(e) => setCode(e.currentTarget.value)}
            size="xs"
            readOnly
            withAsterisk
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <DatePickerInput
            label="Ngày phân công"
            placeholder="Nhập ngày phân công"
            value={new Date()}
            valueFormat="DD/MM/YYYY"
            readOnly
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <DatePickerInput
            label="Dự kiến giao từ"
            placeholder="Ngày dự kiến giao"
            defaultValue={startTime}
            valueFormat="DD/MM/YYYY"
            minDate={new Date()}
            clearable
            {...form.getInputProps("fromExpectedDate")}
            onChange={(value: any) => {
              setStartTime(value);
              form.setValues((prev) => ({
                ...prev,
                fromExpectedDate: new Date(value ?? "").toDateString(),
              }));
              form.setValues({ toExpectedDate: "" });
            }}
            withAsterisk
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <DatePickerInput
            label="Dự kiến giao đến"
            placeholder="Ngày dự kiến đến"
            valueFormat="DD/MM/YYYY"
            minDate={new Date()}
            clearable
            {...form.getInputProps("toExpectedDate")}
            onChange={(value) =>
              form.setValues((prev) => ({
                ...prev,
                toExpectedDate: value
                  ? new Date(value ?? "").toDateString()
                  : null,
              }))
            }
            withAsterisk
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Select
            label="Phương tiện giao hàng"
            placeholder="Chọn phương tiện giao hàng"
            clearable
            data={[
              { label: "Xe máy", value: "Xe máy" },
              { label: "Ô tô", value: "Ô tô" },
            ]}
            {...form.getInputProps("vehicleCode")}
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Select
            label="Công việc"
            placeholder="Chọn loại công việc"
            clearable
            data={dataWorkSelect}
            onChange={(value) =>
              form.setFieldValue(
                "deliveryWorkId",
                value !== null ? Number(value) : null
              )
            }
            size="xs"
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Lái xe"
            placeholder="Nhập tên nhân viên lái xe"
            {...form.getInputProps("driverName")}
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Số điện thoại người lái"
            placeholder="Nhập số điện thoại người lái"
            {...form.getInputProps("driverMobileNumber")}
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 8 }}>
          <TextInput
            label="Ghi chú"
            placeholder="Ghi chú"
            {...form.getInputProps("description")}
            size="xs"
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Select
            label="Nhân viên GH"
            placeholder="Nhập tên nhân viên GH"
            data={dataEmployeedSelect}
            {...form.getInputProps("employee1")}
            searchable
            clearable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            withAsterisk
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Checkbox
            mt={30}
            label="Người chịu trách nhiệm"
            size="xs"
            {...form.getInputProps("mainResponsibility1")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Checkbox
            mt={30}
            label="Tính lương"
            size="xs"
            {...form.getInputProps("payroll1")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Select
            label="Nhân viên HT"
            placeholder="Nhập tên nhân viên HT"
            data={dataEmployeedSelect}
            searchable
            clearable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            {...form.getInputProps("employee2")}
            size="xs"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Checkbox
            mt={30}
            label="Người chịu trách nhiệm"
            size="xs"
            {...form.getInputProps("mainResponsibility2")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <Checkbox
            mt={30}
            label="Tính lương"
            size="xs"
            {...form.getInputProps("payroll2")}
          />
        </Grid.Col>
      </Grid>
      <ScrollArea
        h={customHeightTable()}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table
          striped
          highlightOnHover
          withTableBorder
          withColumnBorders
          mt={10}
        >
          <Table.Thead
            className={cx(classes.header, {
              [classes.scrolled]: scrolled,
            })}
          >
            <Table.Tr>
              <Table.Th>Danh sách điểm đi</Table.Th>
              <Table.Th>Danh sách điểm đến</Table.Th>
              {dataDeliverySelect.length > 1 ? (
                <Table.Th>Thứ tự đi giao</Table.Th>
              ) : (
                <></>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {dataDeliverySelect.length === 1 ? (
              dataDeliverySelect?.map((item, index) => (
                <Table.Tr key={index}>
                  <Table.Td>{item.fromAddress}</Table.Td>
                  <Table.Td>{item.toAddress}</Table.Td>
                </Table.Tr>
              ))
            ) : dataSortOrder.length > 1 ? (
              <>
                {dataSortOrder.map((item, index) => (
                  <Table.Tr key={index}>
                    {index === 0 ? (
                      <Table.Td rowSpan={dataSortOrder.length}>
                        {item.address}
                      </Table.Td>
                    ) : (
                      <></>
                    )}
                    {index > 0 && (
                      <>
                        <Table.Td>{dataSortOrder[index].address}</Table.Td>
                        <Table.Td
                          onMouseDown={(event: React.MouseEvent) =>
                            event.stopPropagation()
                          }
                          onDoubleClick={() => {
                            setIsEditSortOrder((prev) => ({
                              ...prev,
                              sortOrder: true,
                            }));
                            setEditIndex(index);
                          }}
                        >
                          {editIndex === Number(index) &&
                          isEditSortOrder.sortOrder ? (
                            <NumberInput
                              value={dataSortOrder[index].sortOrder}
                              onChange={(value: any) =>
                                handleChangeValue(
                                  "sortOrder",
                                  value,
                                  Number(index)
                                )
                              }
                              min={1}
                              max={dataSortOrder.length - 1}
                              clampBehavior="strict"
                              hideControls
                              size="xs"
                              w={80}
                            />
                          ) : (
                            <Text size="12.5px">
                              {dataSortOrder[index].sortOrder}
                            </Text>
                          )}
                        </Table.Td>
                      </>
                    )}
                  </Table.Tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </Table.Tbody>
        </Table>
      </ScrollArea>
      <Grid mt={10} align="center">
        <Grid.Col span={12}>
          <Textarea
            label="Quãng đường"
            placeholder="Nhập thứ tự giao để hiển thị quãng đường"
            value={
              dataDeliverySelect.length === 1 && distance.length > 0
                ? `${dataRoadBranchs} --- ( ${
                    distance[0]?.elements[0].distance?.text
                  } - ${(distance[0]?.elements[0].duration.value / 60).toFixed(
                    0
                  )} Phút ) ---> ${listToAddress}`
                : dataSortNew.length > 0
                ? dataSortNew
                    .map((item, index) => {
                      return index === dataSortNew.length - 1 &&
                        distanceTotal.length > 0
                        ? item.address
                        : `${item.address} --- ( ${
                            distanceTotal[index]?.distanceValue / 1000
                          } Km - ${(
                            distanceTotal[index]?.durationValue / 60
                          ).toFixed(0)} Phút ) --->`;
                    })
                    .join(" ")
                : ""
            }
            variant="filled"
            maxRows={4}
            autosize
            readOnly
          />
        </Grid.Col>
      </Grid>
      <Flex justify={"end"} gap={"md"}>
        <TextInput
          label="Khoảng cách"
          placeholder="Bấm nút tính khoảng cách để hiển thị"
          value={
            dataDeliverySelect.length === 1 && distance.length > 0
              ? `${distance[0].elements[0].distance.text}`
              : `${
                  distanceTotal.reduce(
                    (total, item) => total + item.distanceValue,
                    0
                  ) / 1000
                } Km`
          }
          variant="filled"
          readOnly
          size="xs"
        />
        <TextInput
          label="Thời gian giao ước tính"
          placeholder="Bấm nút tính khoảng cách để hiển thị"
          value={
            dataDeliverySelect.length === 1 && distance.length > 0
              ? `${(distance[0].elements[0].duration.value / 60).toFixed(
                  0
                )} Phút`
              : `${(
                  distanceTotal.reduce(
                    (total, item) => total + item.durationValue,
                    0
                  ) / 60
                ).toFixed(0)} Phút`
          }
          variant="filled"
          readOnly
          size="xs"
        />
        <Button color="teal" onClick={() => handleSortData()} mt={22} size="xs">
          Tính khoảng cách
        </Button>
      </Flex>
      <Group
        justify="end"
        mt="md"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
        <></>
      </Group>
    </Box>
  );
};

export default ModalDeliveryAssignment;
