import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Input,
  List,
  NumberFormatter,
  Select,
  SelectProps,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandBootstrap,
  IconBuildingSkyscraper,
  IconCheck,
  IconCoinPound,
  IconDownload,
  IconEdit,
  IconPencil,
  IconSearch,
  IconTicket,
  IconTimeDuration15,
  IconUser,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { DatePickerInput } from "@mantine/dates";
import { useDebouncedCallback } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import * as xlsx from "xlsx";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { SelectListItemBase } from "../../../../_base/model/_base/SelectListItemBase";
import { AvatarUtils } from "../../../../common/ColorByName/AvatarUtils";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import useWindowSize from "../../../../common/WindownSize/WindownSize";
import { _localization } from "../../../../config/location";
import { green, orange, purple } from "../../../../const/variables";
import { MessageResponse } from "../../../../model/MessageResponse";
import { PaymentReceiptDetailModel } from "../../../../model/PaymentReceiptDetailModel";
import { SelectListItem } from "../../../../model/SelectListItem";
import { TblDMPaymentMethod } from "../../../../model/TblDMPaymentMethod";
import DetaiReceipts from "./DetaiReceipts";
import { toast } from "react-toastify";
import { BASE_API_POS } from "../../../../config";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";

interface DataList {
  lists: any[];
  count: number;
}

interface DepartmentCustomerOrder {
  id: number;
  name: string;
  details: {
    fullName: string;
    phoneNumber: string;
  };
}

interface DepartmentUser {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  departmentCode: string;
  creationDate: string;
  createdBy: string;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
}

interface Program {
  id: number;
  code: string;
  name: string;
  createBy: string | null;
  createDate: string;
  lastUpdateDate: string | null;
  lastUpdateBy: string | null;
}

interface Permission {
  id: number;
  code: string;
  name: string;
  createBy: string | null;
  createDate: string;
  lastUpdateDate: string | null;
  lastUpdateBy: string | null;
}

interface AddressInfo {
  active: boolean;
  address: string;
  birthOfDate: string;
  code: string;
  buyerName: string;
  commune: number;
  companyName: string | null;
  contact: string | null;
  country: string | null;
  debtLimit: number | null;
  description: string | null;
  district: number;
  email: string;
  fax: string | null;
  groupId: number;
  groupName: string;
  id: number;
  name: string;
  province: number;
  sex: number;
  taxCode: string | null;
  tblDmCustomerGroupModel: any | null;
  telephoneNumber: string;
  type: number;
}

interface Customer {
  addr: string;
  createdBy: number;
  creationDate: string;
  creditLimit: number;
  email: string;
  employeeId: number;
  enabledFlag: boolean | null;
  endDateActive: string;
  id: number;
  orgId: number;
  phone: string;
  startDateActive: string;
  vatRegistrationNum: string;
  vendorCode: string;
  vendorName: string;
  vendorTypeLookupCode: string;
}
interface Customer {
  addr: string;
  createdBy: number;
  creationDate: string;
  creditLimit: number;
  email: string;
  employeeId: number;
  enabledFlag: boolean | null;
  endDateActive: string;
  id: number;
  orgId: number;
  phone: string;
  startDateActive: string;
  vatRegistrationNum: string;
  vendorCode: string;
  vendorName: string;
  vendorTypeLookupCode: string;
}

interface User {
  id: number;
  code: string;
  fullname: string;
  dateOfBirth: string;
  gender: number;
  departmentId: number | null;
  departmentName: string | null;
  roleId: number;
  roleName: string;
  managerName: string | null;
  address: string | null;
  phoneNumber: string;
  active: boolean;
  email: string;
  description: string | null;
  accountingCenterId: number | null;
  managerId: number | null;
  isCrm: boolean | null;
  currentCardId: number;
  partCode: string | null;
  createDate: string;
  listBranch: string | null;
  listInventory: string | null;
  listGroup: string;
  branchId: number;
  branchName: string;
  workAddress: string | null;
}

const ListReceipts = () => {
  const navigate = useNavigate();
  //#region  table
  //data and fetching state
  const [data, setData] = useState<PaymentReceiptDetailModel[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const [dataFetch, setDataFetch] = useState<SelectListItem[]>([]);
  const [dataWarehouse, setDataWarehouse] = useState<SelectListItemBase[]>([]);
  // const [handleType, setHandeValueType] = useState<string>("");
  const [dataListCustomer, setDataListCustomer] = useState<SelectListItem[]>(
    []
  );
  const [addressInfoDetail, setAddressInfo] = useState<AddressInfo[]>([]);
  const [customerOrders, setCustomerOrders] = useState<
    DepartmentCustomerOrder[]
  >([]);
  const [branchAll, setBranchAll] = useState<DepartmentUser[]>([]);

  const [dataGetReceiptContract, setDataGetReceiptContract] = useState<
    Program[]
  >([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const [typeHande, setTyleHandle] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(1);
  const [take, setTake] = useState<number>(30);
  const [searchTerm, setSearchTerm] = useState<string>("");
  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  // const [typeKey, settypeKey] = useState<number | null>(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);
  //
  const [dataGetReceiptFee, setDataReceiptFee] = useState<Permission[]>([]);
  const [customerDetail, setCustomerDetail] = useState<Customer[]>([]);
  const debounceTimeoutRef = React.useRef<number | null>(null);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [dataCodeFund, setDataCodeFunch] = useState<SelectListItemBase[]>([]);
  const [dataBranchs, setDataBranchs] = useState<SelectListItemBase[]>([]);

  const size = useWindowSize();

  const [search, setSearch] = useState({
    keySearch: "",
    customerId: "",
    startDate: "",
    endDate: "",
    codefund: "",
    accountfund: "",
    deartment: "",
    branch: "",
    type: "",
    workplace: "",
    creator: "",
    Type: 0,
    typeReceipt: "",
  });

  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const sizeGrid = { base: 6, sm: 2, md: 2, lg: 2, xl: 2 };

  const editBill = (id: string, typeReceipt: number, type: number) => {
    let url = "";
    if (!id) {
      return;
    }
    if (type === 3) {
      url = "/bill/debt-note-branches";
    } else if (type === 4) {
      url = "/bill/credit-advice";
    } else if (typeReceipt === 1) {
      if (type === 1) {
        debugger;
        url = "/bill/payment-receipt";
      } else if (type === 2) {
        url = "/bill/internal-receipt";
      }
    } else if (typeReceipt === 2) {
      if (type === 1) {
        return;
      }
      url = "/bill/payment-slip-branches";
    } else if (typeReceipt === 3) {
      return;
    }

    navigate(url, {
      state: {
        id: id,
        typeReceipt: typeReceipt,
        type: type,
      },
    });
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        CustomerId: search.customerId,
        StartDate: search.startDate,
        EndDate: search.endDate,
        KeySearch: (search.keySearch || keySearch).toLowerCase(),
        Codefund: search.codefund,
        Accountfund: search.accountfund,
        Creator: search.creator,
        Workplace: search.workplace,
        Branch: search.branch,
        Type: search.type || search.Type,
        TypeReceipt: search.typeReceipt,
        Department: search.deartment,
      };

      let sortingQuery = "";
      if (sorting && sorting.length > 0) {
        const { id, desc } = sorting[0] || {};
        if (id) {
          sortingQuery = `&sort=${encodeURIComponent(id)}&isOrder=${desc}`;
        }
      }

      const url =
        Object.entries(queryParams)
          .reduce((acc, [key, value]) => {
            if (value !== undefined && value !== "") {
              return `${acc}&${key}=${encodeURIComponent(value)}`;
            }
            return acc;
          }, "/api/v1/CreateSellItem/list-receipt?")
          .replace("?&", "?") + sortingQuery;

      const response = await repositoryPos.get<MessageResponse<DataList>>(url);

      if (response && response.success) {
        const dataList = response?.data;
        setData(dataList?.lists);
        setRowCount(dataList?.count);
        // await dataOrderTypeSelect();
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  // Hàm gọi API phòng ban
  const fetchDataSelect = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDepartment/get-select"
    );

    if (dataApi && dataApi.success) {
      setDataFetch(dataApi.data);
    }
  };
  // Hàm gọi API chi nhánh
  const fetchDataBranch = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmInventory/get-select-branch"
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.slice(1);
      setDataBranch(filteredData);
    }
  };

  const fetchDataWarehouse = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-select"
    );

    if (dataApi && dataApi.success) {
      setDataWarehouse(dataApi.data);
    }
  };

  const getTblDmExpense = async () => {
    const response = await repositoryMdm.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmExpense/get-select");

    if (response && response.success) {
      let result = response.data;
      setDataCodeFunch(result);
    }
  };

  const fetchDataReceiptFee = async () => {
    const response = await repositoryPos.get<MessageResponse<Permission[]>>(
      "/api/v1/TblDmFee/get-list?Skip=0&Take=9999"
    );

    if (response && response.success) {
      let result = response.data;
      setDataReceiptFee(result);
    }
  };

  const fetchDataGetAll = async () => {
    try {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentUser[]>
      >(`/api/v1/TblDepartment/get-all`);

      if (response && response.success) {
        const result = response.data;
        setBranchAll(result);
      } else {
        console.error("Failed to fetch departmentFund details");
      }
    } catch (error) {
      console.error("Error fetching departmentFund details", error);
    }
  };

  const fetchDataReceiptContract = async () => {
    const response = await repositoryPos.get<MessageResponse<Program[]>>(
      "/api/v1/TblDmContract/get-list?Skip=0&Take=99999"
    );

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptContract(result);
    }
  };

  useEffect(() => {
    getTblDmExpense();
    fetchDataGetAll();
    fetchDataReceiptFee();
    fetchDataWarehouse();
    fetchDataReceiptContract();
  }, []);

  const handleChangeSearchValue1 = (value: string, key: string) => {
    if (key === "customerId") {
      setSelectedCustomer(value);
    }
    setSearch((prevData) => ({ ...prevData, [key]: value }));
    setSelectedValue(value);
  };

  const handleChangeSearchValue = useDebouncedCallback(
    (value: string, key: string) => {
      if (key === "customerId") {
        setSelectedCustomer(value);
      }
      setSearch((prevData) => ({ ...prevData, [key]: value }));
      setSelectedValue(value);
    },
    500
  );

  const handleRangeChange = (dates: [Date | null, Date | null]) => {
    console.log(dates);
    setValue(dates);
    const [startDate, endDate] = dates;
    const startDateISO = startDate ? startDate.toLocaleDateString() : null;
    const endDateISO = endDate ? endDate.toLocaleDateString() : null;

    handleChangeSearchValue(startDateISO ?? "", "startDate");

    handleChangeSearchValue(endDateISO ?? "", "endDate");
  };

  const parseAttribute5Data = (row: any) => {
    try {
      const parsedData = row.attribute5 ? JSON.parse(row.attribute5) : [];
      return Array.isArray(parsedData) ? parsedData : [];
    } catch {
      return [];
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<PaymentReceiptDetailModel>[]>(
    () => [
      {
        accessorKey: "receiptNumber",
        enableColumnActions: false,
        header: "Mã phiếu thu",
        Cell: ({ renderedCellValue, cell, row }) => {
          //    settypeKey(row.original.type);
          return (
            <Tooltip label="Xem chi tiết">
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                style={{ cursor: "pointer" }}
                color={renderedCellValue === null ? "red" : green.base}
                onDoubleClick={async () => {
                  navigate("/bill/detail-payment-receipt", {
                    state: {
                      id: row.original.id,
                      type: row.original.type,
                      receptype: row.original.typeReceipt,
                    },
                  });
                  // detailReceipts(row.original.id, row.original.type);
                }}
              >
                {renderedCellValue}
              </Badge>
            </Tooltip>
          );
        },
      },
      {
        header: "Thời gian",
        id: "thoigian",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              width: "100%",
              backgroundColor: "#f1f3f5",
            },
          };
        },
        columns: [
          {
            accessorKey: "createdDate",
            enableColumnActions: false,
            size: 180,
            header: "Thời gian tạo ",
            Cell: ({ renderedCellValue }: any) => (
              <>{formatDateTransfer(renderedCellValue)}</>
            ),
          },
          {
            accessorKey: "completiontime",
            enableColumnActions: false,
            size: 180,
            header: "Thời gian hoàn thành ",
            Cell: ({ renderedCellValue }: any) => (
              <>{formatDateTransfer(renderedCellValue)}</>
            ),
          },
        ],
      },

      {
        accessorKey: "type",
        enableColumnActions: false,
        header: "Loại phiếu thu / chi",
        Cell: ({ row }) => {
          const renderBadge = () => {
            const { typeReceipt, type } = row.original;

            if (type === 3) {
              return <Badge color="gray">Phiếu báo nợ</Badge>;
            } else if (type === 4) {
              return (
                <Badge color="#9D3BC7" c="#fff">
                  Phiếu báo có
                </Badge>
              );
            } else if (typeReceipt === 1) {
              if (type === 1) {
                return (
                  <Badge color="#C3FF36" c="#000">
                    Phiếu thu đơn hàng
                  </Badge>
                );
              }else if (type === 6) {
                return (
                  <Badge color="yellow" c={"#000"}>
                    Phiếu báo có đơn hàng
                  </Badge>
                );
              }
              return <Badge color="#FF8800">Phiếu thu khác</Badge>;
            } else if (typeReceipt === 2) {
              if (type === 1) {
                return <Badge color="blue">Phiếu chi đơn hàng</Badge>;
              }
              return <Badge color="red">Phiếu chi khác</Badge>;
            } else if (typeReceipt === 3) {
              return (
                <Badge color="purple" c="#fff">
                  Phiếu điều chỉnh
                </Badge>
              );
            }

            return null;
          };

          return <>{renderBadge()}</>;
        },
      },
      {
        accessorKey: "attribute1",
        enableColumnActions: false,
        enableSorting: false,
        header: "Danh sách đơn hàng",
        Cell: ({ cell }) => {
          const value = cell.getValue<string>() ?? "";
          const orders = value?.split(",").reverse();
          return (
            <div>
              {orders.map((order, index) => (
                <div key={index}>{order}</div>
              ))}
            </div>
          );
        },
      },
      {
        header: "Người lập Phiếu",
        id: "Nguoilapphieu",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              width: "100%",
              backgroundColor: "#f1f3f5",
            },
          };
        },
        columns: [
          {
            accessorKey: "creatorbranch",
            enableColumnActions: false,
            header: "Chi nhánh ",
            // Cell: ({ renderedCellValue, cell }) => (
            //   <Text fw={600}>
            //     {renderedCellValue === null ? null : renderedCellValue}
            //   </Text>
            // ),
          },
          {
            accessorKey: "creatorworkplace",
            enableColumnActions: false,
            header: "Địa điểm ",
            size: 220,
            // Cell: ({ renderedCellValue, cell }) => (
            //   <Text fw={600}>
            //     {renderedCellValue === null ? null : renderedCellValue}
            //   </Text>
            // ),
          },
          {
            accessorKey: "creatordepartment",
            enableColumnActions: false,
            header: "Phòng ban ",
            size: 250,
            // Cell: ({ renderedCellValue, cell }) => (
            //   <Text fw={600}>{renderedCellValue}</Text>
            // ),
          },
          {
            accessorKey: "createrolename",
            enableColumnActions: false,
            header: "Chức danh ",
            // Cell: ({ renderedCellValue, cell }) => (
            //   <Text fw={600}>{renderedCellValue}</Text>
            // ),
          },
          {
            accessorKey: "creatorfullname",
            enableColumnActions: false,
            header: "Họ tên",
            Cell: ({ renderedCellValue, cell, row }) => (
              <>
                <AvatarUtils
                  value={row.original.creatorfullname?.split("-")[0]}
                />
              </>
            ),
          },
        ],
      },

      {
        accessorKey: "creatorsalesbranch",
        enableColumnActions: false,
        header: "Chi nhánh bán",
        // Cell: ({ renderedCellValue, cell }) => (
        //   <Text fw={600}>
        //     {renderedCellValue === null ? null : renderedCellValue}
        //   </Text>
        // ),
      },
      {
        header: "Người nộp tiền / Người nhận tiền",
        id: "nguoi_nop_tien_nhan_tien",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              width: "100%",
              backgroundColor: "#f1f3f5",
            },
          };
        },
        columns: [
          {
            accessorKey: "fundbranch",
            enableColumnActions: false,
            header: "Chi nhánh ",
            size: 220,
            // Cell: ({ renderedCellValue }) => {
            //   return <Text fw={600}>{renderedCellValue}</Text>;
            // },
          },
          {
            accessorKey: "fundworkplace",
            enableColumnActions: false,
            header: "Địa điểm ",
            size: 260,
            // Cell: ({ renderedCellValue, cell }) => (
            //   <Text fw={600}>
            //     {renderedCellValue === null ? null : renderedCellValue}
            //   </Text>
            // ),
          },
          {
            accessorKey: "funddepartment",
            enableColumnActions: false,
            header: "Phòng ban ",
            size: 220,
            // Cell: ({ renderedCellValue }) => {
            //   return <Text fw={600}>{renderedCellValue}</Text>;
            // },
          },
          {
            accessorKey: "attribute3",
            enableColumnActions: false,
            header: "Chức danh ",
            size: 250,
            // Cell: ({ renderedCellValue, cell }) => {
            //   return <Text fw={600}>{renderedCellValue}</Text>;
            // },
          },
          {
            accessorKey: "funfullname",
            enableColumnActions: false,
            header: "Họ tên ",
            size: 250,
            Cell: ({ renderedCellValue, cell, row }) => (
              <>
                {row.original.funfullname && (
                  <AvatarUtils value={row.original.funfullname} />
                )}
              </>
            ),
          },
          // {
          //   accessorKey: "creatorfullname",
          //   enableColumnActions: false,
          //   header: "Nhân viên bán hàng",
          //   Cell: ({ renderedCellValue, cell, row }) => (
          //     <>
          //       <AvatarUtils value={row.original.creatorfullname?.split("-")[0]} />
          //     </>
          //   ),
          // },
        ],
      },
      {
        header: "Khác ",
        id: "khac",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              width: "100%",
              backgroundColor: "#dee2e6",
            },
          };
        },
        columns: [
          {
            accessorKey: "payertype",
            enableColumnActions: false,
            header: "Loại",
            Cell: ({ renderedCellValue, row }) => {
              const payerType = row.original.payertype;
              let badgeColor;

              switch (payerType) {
                case "Nhân viên":
                  badgeColor = "#0056D2";
                  break;
                case "Nội bộ":
                  badgeColor = "#FF8800";
                  break;
                case "Khách hàng":
                  badgeColor = "#28A745";
                  break;
                case "NCC":
                  badgeColor = "#ffcc5c";
                  break;
                case "Khác":
                  badgeColor = "#6C757D";
                  break;
                default:
                  badgeColor = "red";
              }

              return <Badge color={badgeColor}>{renderedCellValue}</Badge>;
            },
          },
          {
            accessorKey: "codecustomer",
            enableColumnActions: false,
            header: "Mã",
            Cell: ({ renderedCellValue, row }) => {
              return (
                <Text fw="600">
                  {(() => {
                    // const addressItem = addressInfoDetail?.find(
                    //   (item) => String(item.id) === String(renderedCellValue)
                    // );

                    // if (addressItem) {
                    //   return `${addressItem.code} - ${addressItem.buyerName}`;
                    // }

                    // const customerItem = customerDetail?.find(
                    //   (item) => String(item.id) === String(renderedCellValue)
                    // );

                    return renderedCellValue || "";
                  })()}
                </Text>
              );
            },
          },
          {
            accessorKey: "anothername",
            enableColumnActions: false,
            header: "Tên",
            // Cell: ({ renderedCellValue, row }) => {
            //   return (
            //     <Text tt="capitalize" fw={600}>
            //       {renderedCellValue}
            //     </Text>
            //   );
            // },
          },
          {
            accessorKey: "phonenumber",
            enableColumnActions: false,
            header: "Số điện thoại",
            // Cell: ({ renderedCellValue, row }) => {
            //   return (
            //     <Text tt="capitalize" fw={600}>
            //       {renderedCellValue}
            //     </Text>
            //   );
            // },
          },
        ],
      },

      {
        accessorKey: "payerdescription",
        enableColumnActions: false,
        header: "Nội dung",
        // Cell: ({ renderedCellValue, row }) => {
        //   return (
        //     <Text tt="capitalize" fw={600}>
        //       {renderedCellValue}
        //     </Text>
        //   );
        // },
      },

      {
        header: "Đối tượng công nợ",
        id: "doi_tuong_cong_no",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              width: "100%",
              backgroundColor: "#f1f3f5",
            },
          };
        },
        columns: [
          {
            accessorKey: "attribute2",
            enableColumnActions: false,
            header: "Mã ",
            // Cell: ({ renderedCellValue, row }) => {
            //   return (
            //     <Text tt="capitalize" fw={600}>
            //       {renderedCellValue}
            //     </Text>
            //   );
            // },
          },
          {
            accessorKey: "attribute7",
            enableColumnActions: false,
            header: "Tên",
            // Cell: ({ renderedCellValue, row }) => {
            //   return (
            //     <Text tt="capitalize" fw={600}>
            //       {renderedCellValue}
            //     </Text>
            //   );
            // },
          },
        ],
      },

      {
        accessorKey: "accountfund",
        enableColumnActions: false,
        header: "Tỷ giá",
      },
      {
        header: "Tài khoản nợ / Tài khoản có",
        id: "tai_khoan_no_tai_khoan_co",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              width: "100%",
              backgroundColor: "#f1f3f5",
            },
          };
        },
        columns: [
          {
            accessorKey: "codefund",
            header: "Mã tài khoản",
          },
          {
            accessorKey: "attribute6",
            enableColumnActions: false,
            header: "Tên tài khoản",
          },
        ],
      },
      {
        header: "Chứng từ",
        id: "Chung_tu",
        mantineTableHeadCellProps: () => {
          return {
            style: {
              width: "100%",
              backgroundColor: "#dee2e6",
            },
          };
        },
        columns: [
          {
            id: "originalDocuments",
            header: "Tên chứng từ gốc",
            enableColumnActions: false,
            enableSorting: false,
            accessorFn: (row) => {
              const data = parseAttribute5Data(row)
                .map((item: any) => item.originalDocuments)
                .filter(Boolean);

              return (
                <List fw={600} type="ordered" withPadding>
                  {data.map((credit: string, index: number) => (
                    <List.Item key={index}>
                      <Text fw={600}>{credit}</Text>
                    </List.Item>
                  ))}
                </List>
              );
            },
          },
          {
            id: "originalDocumentNumber",
            header: "Số chứng từ gốc",
            enableColumnActions: false,
            enableSorting: false,
            accessorFn: (row) => {
              const data = parseAttribute5Data(row)
                .map((item: any) => item.originalDocumentNumber)
                .filter(Boolean);

              return (
                <List fw={600} type="ordered" withPadding>
                  {data.map((credit: string, index: number) => (
                    <List.Item key={index}>
                      <Text fw={600}>{credit}</Text>
                    </List.Item>
                  ))}
                </List>
              );
            },
          },
        ],
      },

      {
        id: "codeForFees",
        header: "Mã phí",
        enableColumnActions: false,
        enableSorting: false,
        size: 350,
        accessorFn: (row) => {
          const data = parseAttribute5Data(row)
            .map((item: any) => item.codeForFees)
            .filter(Boolean);
          return (
            <List fw={600} type="ordered" withPadding>
              {data.map((credit: string, index: number) => (
                <List.Item key={index}>
                  <Text fw={600}>{credit}</Text>
                </List.Item>
              ))}
            </List>
          );
        },
      },
      // {
      //   id: "incurDebt",
      //   header: "Phát sinh nợ",
      //   enableColumnActions: false,
      //   enableSorting: false,
      //   accessorFn: (row) => {
      //     const data = parseAttribute5Data(row)
      //       .map((item: any) => item.occurringCredit)
      //       .filter(Boolean);

      //     return (
      //       <List fw={600} type="ordered" withPadding>
      //         {data.map((credit: string, index: number) => (
      //           <List.Item key={index}>
      //             <Text fw={600}>
      //               <NumberFormatter
      //                 value={typeKey === 2 || typeKey === 4 ? credit : ""}
      //                 thousandSeparator=","
      //               />
      //             </Text>
      //           </List.Item>
      //         ))}
      //       </List>
      //     );
      //   },
      // },
      {
        id: "occurringCredit",
        header: "Phát sinh có / phát sinh nợ",
        enableColumnActions: false,
        enableSorting: false,
        accessorFn: (row) => {
          const data = parseAttribute5Data(row)
            .map((item: any) => item.occurringCredit)
            .filter(Boolean);

          return (
            <List fw={600} type="ordered" withPadding>
              {data.map((credit: string, index: number) => (
                <List.Item key={index}>
                  <Text fw={600}>
                    <NumberFormatter value={credit} thousandSeparator="," />
                  </Text>
                </List.Item>
              ))}
            </List>
          );
        },
      },
      {
        id: "branchesFund",
        header: "Chi nhánh",
        enableColumnActions: false,
        enableSorting: false,
        size: 250,
        accessorFn: (row) => {
          const data = parseAttribute5Data(row)
            .map((item: any) => item.branchesFund)
            .filter(Boolean);
          return (
            <List fw={600} type="ordered" withPadding>
              {data.map((credit: string, index: number) => (
                <List.Item key={index}>
                  <Text fw={600}>
                    {dataBranchs.find(
                      (ele) => String(ele.att2) === String(credit)
                    )?.text ?? ""}
                  </Text>
                </List.Item>
              ))}
            </List>
          );
        },
      },
      {
        id: "employeeFund",
        header: "Nhân viên",
        enableColumnActions: false,
        enableSorting: false,
        size: 250,
        accessorFn: (row) => {
          const data = parseAttribute5Data(row)
            .map((item: any) => item.employeeFund)
            .filter(Boolean);

          return (
            <List fw={600} type="ordered" withPadding>
              {data.map((credit: string, index: number) => {
                const x = dataWarehouse.find(
                  (ele) => String(ele.att1) === String(credit)
                );
                return (
                  <List.Item key={index}>
                    <Text fw={600}>{credit + " - " + x?.text}</Text>
                  </List.Item>
                );
              })}
            </List>
          );
        },
      },
      {
        id: "debtObjectCode",
        header: "Mã đối tượng công nợ",
        enableColumnActions: false,
        enableSorting: false,
        accessorFn: (row) => {
          const data = parseAttribute5Data(row)
            .map((item: any) => item.debtObjectCode)
            .filter(Boolean);

          return (
            <List fw={600} type="ordered" withPadding>
              {data.map((credit: string, index: number) => (
                <List.Item key={index}>
                  <Text fw={600}>{credit}</Text>
                </List.Item>
              ))}
            </List>
          );
        },
      },
      {
        id: "debtObjectName",
        header: "Tên đối tượng công nợ",
        enableColumnActions: false,
        enableSorting: false,
        size: 300,
        accessorFn: (row) => {
          const data = parseAttribute5Data(row)
            .map((item: any) => item.debtObjectName)
            .filter(Boolean);

          return (
            <List fw={600} type="ordered" withPadding>
              {data.map((credit: string, index: number) => (
                <List.Item key={index}>
                  <Text fw={600}>{credit}</Text>
                </List.Item>
              ))}
            </List>
          );
        },
      },
      {
        id: "description",
        header: "Nội dung",
        enableColumnActions: false,
        enableSorting: false,

        accessorFn: (row) => {
          const data = parseAttribute5Data(row)
            .map((item: any) => item.description)
            .filter(Boolean);

          return (
            <List fw={600} type="ordered" withPadding>
              {data.map((credit: string, index: number) => (
                <List.Item key={index}>
                  <Text fw={600}>{credit}</Text>
                </List.Item>
              ))}
            </List>
          );
        },
      },

      {
        id: "fee",
        header: "CTKM",
        enableColumnActions: false,
        enableSorting: false,

        accessorFn: (row) => {
          const data = parseAttribute5Data(row)
            .map((item: any) => item.fee)
            .filter(Boolean);

          return (
            <List fw={600} type="ordered" withPadding>
              {data.map((credit: string, index: number) => (
                <List.Item key={index}>
                  <Text fw={600}>{credit}</Text>
                </List.Item>
              ))}
            </List>
          );
        },
      },
      {
        id: "contract",
        header: "Hợp Đồng",
        enableColumnActions: false,
        enableSorting: false,

        accessorFn: (row) => {
          const data = parseAttribute5Data(row)
            .map((item: any) => item.contract)
            .filter(Boolean);
          return (
            <List fw={600} type="ordered" withPadding>
              {data.map((credit: string, index: number) => (
                <List.Item key={index}>
                  <Text fw={600}>{credit}</Text>
                </List.Item>
              ))}
            </List>
          );
        },
      },
      {
        accessorKey: "totalAmount",
        enableColumnActions: false,
        header: "Tổng số tiền",
        Cell: ({ renderedCellValue, cell }) => (
          <Text fw={700}>
            {renderedCellValue !== undefined &&
            !isNaN(Number(renderedCellValue)) ? (
              <NumberFormatter
                value={Math.abs(Number(renderedCellValue))}
                thousandSeparator=","
              />
            ) : (
              <></>
            )}
          </Text>
        ),
      },
      {
        accessorKey: "actions",
        enableColumnActions: false,
        enableSorting: false,
        header: "Thao tác",
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <Flex gap={10}>
            <Tooltip label="Chức năng đang cập nhật">
              <ActionIcon
                disabled
                variant="light"
                aria-label="Settings"
                color="orange"
              >
                <IconEdit
                  onClick={() => {
                    if (row.original.receiptNumber) {
                      editBill(
                        row.original.receiptNumber,
                        row.original.typeReceipt,
                        row.original.type
                      );
                    }
                  }}
                  size={16}
                />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ),
      },
    ],
    [
      addressInfoDetail,
      customerDetail,
      // dataTblOrderTypeSelect,
      // dataWarehouse,
      // dataCodeFund,
      // //    typeKey,
      // customerOrders,
      //  handleType,
    ]
  );

  const table = useMantineReactTable<PaymentReceiptDetailModel>({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => <></>,
    // mantineTableHeadCellProps: {
    //   align: "center",
    // },
    enableStickyHeader: true,
    mantineTopToolbarProps: {
      style: {
        // borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        // marginTop: "10px",
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: false,
    // getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["receiptNumber", "mrt-row-select"],
        right: ["totalAmount", "actions"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    mantineTableProps: {
      striped: false,
    },
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,

    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "550", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
  });

  const transformToComboboxItem = (item: SelectListItemBase): ComboboxItem => {
    return {
      label: item.text,
      value: item.value,
    };
  };

  const fetchDataCustomer = async (searchTerm: string) => {
    setLoading(true);
    const query = searchTerm ? `KeySearch=${searchTerm}&` : "";
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmCustomer/get-select?${query}Skip=${1}&Take=${30}`
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.filter(
        (item: any) => item.text !== null
      );
      setDataListCustomer(filteredData);
      setSkip((prevSkip) => prevSkip + 1);
    }
    setLoading(false);
  };

  const debouncedFetchDataCustomer = React.useCallback((value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(() => {
      fetchDataCustomer(value);
    }, 300);
  }, []);

  const fetchDataCustomerDetail = async () => {
    let url = `?Skip=0&Take=9999`;

    try {
      // Fetch NCC data
      const nccResponse = await repositoryMdm.get(`/api/v1/TblVendor/get-all`);
      const nccData = nccResponse?.success ? nccResponse.data : [];

      // Map NCC data
      const nccMappedData: DepartmentCustomerOrder[] = nccData.map(
        (customer: any) => ({
          id: customer.id,
          name: customer.vendorCode,
          details: {
            fullName: customer.vendorName,
            phoneNumber: customer.phone ?? "",
          },
        })
      );

      // Fetch Khách hàng data
      const customerResponse = await repositoryPos.get(
        `/api/v1/TblDmCustomer/get-list${url}`
      );
      const customerData = customerResponse?.success
        ? customerResponse.data
        : [];

      // Map Khách hàng data
      const customerMappedData: DepartmentCustomerOrder[] = customerData.map(
        (customer: any) => ({
          id: customer.id,
          name: customer.code,
          details: {
            fullName: customer.name,
            phoneNumber: customer.telephoneNumber,
          },
        })
      );

      // Combine both NCC and Khách hàng data
      const mappedData = [...nccMappedData, ...customerMappedData];
      setCustomerOrders(mappedData);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  useEffect(() => {
    fetchDataCustomerDetail();
  }, []);

  const fetchDataBranchs = async () => {
    const response = await repositoryPos.get<
      MessageResponse<SelectListItemBase[]>
    >("/api/v1/TblDmInventory/get-select-branch?auth=false");

    if (response && response.success) {
      let result = response.data;
      setDataBranchs(result);
    }
  };

  const fetchDataTblDmCustomer = async () => {
    const response = await repositoryPos.get<MessageResponse<AddressInfo[]>>(
      "/api/v1/TblDmCustomer/get-list?Skip=0&Take=50"
    );

    if (response && response.success) {
      let result = response.data;
      setAddressInfo(result);
    }
  };

  const fetchDataTblVendor = async () => {
    const response = await repositoryMdm.get<MessageResponse<Customer[]>>(
      "/api/v1/TblVendor/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setCustomerDetail(result);
    }
  };

  useEffect(() => {
    fetchDataCustomer("");
    fetchDataBranchs();
    fetchDataTblVendor();
    fetchDataTblDmCustomer();
  }, []);

  useEffect(() => {
    debouncedFetchDataCustomer(searchTerm);
  }, [searchTerm, debouncedFetchDataCustomer]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (pagination.pageIndex === 0 && data.length < pagination.pageSize) {
      fetchData();
    } else {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting]);

  // Icon color mappings based on value
  const optionColors: Record<
    "Tiền dư" | "Khác" | "Thanh lý" | "Hoàn ứng công tác" | "Hoàn ứng thu mua",
    string
  > = {
    "Tiền dư": "cyan",
    Khác: "green",
    "Thanh lý": "blue",
    "Hoàn ứng công tác": orange.medium,
    "Hoàn ứng thu mua": purple.medium,
  };

  // Render function to customize the option appearance
  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColors[
            option.label as
              | "Tiền dư"
              | "Khác"
              | "Thanh lý"
              | "Hoàn ứng công tác"
              | "Hoàn ứng thu mua"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  // account fund
  const optionColorsAccount: Record<
    "VND" | "USD" | "Vàng SJC 9999" | "Vàng PNJ 9999",
    string
  > = {
    VND: "cyan",
    USD: "green",
    "Vàng SJC 9999": "blue",
    "Vàng PNJ 9999": orange.medium,
  };

  const dataAccountFund = [
    { value: "1", label: "VND" },
    { value: "2", label: "USD" },
    { value: "3", label: "Vàng SJC 9999" },
    { value: "4", label: "Vàng PNJ 9999" },
  ];
  // Render function to customize the option appearance
  const renderSelectOptionAccount: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsAccount[
            option.label as "VND" | "USD" | "Vàng SJC 9999" | "Vàng PNJ 9999"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  const TypeTicket: SelectProps["renderOption"] = ({ option, checked }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={option.value === "1" ? "red" : "yellow"}
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  const renderSelectOptionType: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={option.value === "1" ? "blue" : "green"}
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  const handleExport = async () => {
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        CustomerId: search.customerId,
        StartDate: search.startDate,
        EndDate: search.endDate,
        KeySearch: (search.keySearch || keySearch).toLowerCase(),
        Codefund: search.codefund,
        Accountfund: search.accountfund,
        Creator: search.creator,
        Workplace: search.workplace,
        Branch: search.branch,
        Type: search.type || search.Type,
        TypeReceipt: search.typeReceipt,
        Department: search.deartment,
      };

      let sortingQuery = "";
      if (sorting && sorting.length > 0) {
        const { id, desc } = sorting[0] || {};
        if (id) {
          sortingQuery = `&sort=${encodeURIComponent(id)}&isOrder=${desc}`;
        }
      }

      const url =
        Object.entries(queryParams)
          .reduce((acc, [key, value]) => {
            if (value !== undefined && value !== "") {
              return `${acc}&${key}=${encodeURIComponent(value)}`;
            }
            return acc;
          }, "/api/v1/CreateSellItem/export-list-receipt?")
          .replace("?&", "?") + sortingQuery;

      const _url = BASE_API_POS + url;
      const token = localStorage.getItem("token");

      const response = await fetch(_url, {
        method: "GET",
        headers: {
          "Content-Type": "application/vnd.ms-excel",
          Authorization: `Bearer ${token?.replace(`"`, "").replace(`"`, "")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download file");
      }
      const blob = await response.blob();
      const urlss = window.URL.createObjectURL(blob);
      const _name = new Date().toISOString().slice(0, 10);
      let filename = "bao_cao_phieu_thu_chi_" + _name + ".xlsx";
      const link = document.createElement("a");
      link.href = urlss;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Group mb={5} justify="space-between">
          <_breadcrumb></_breadcrumb>
          <Button
            leftSection={<IconDownload size={14} />}
            color="violet"
            variant="outline"
            onClick={handleExport}
          >
            Export Excel
          </Button>
        </Group>
        <Grid>
          <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
            <Grid>
              <Grid.Col span={sizeGrid}>
                <Input
                  placeholder="Từ khóa"
                  type="text"
                  onChange={(e) => {
                    handleChangeSearchValue(e.target.value ?? "", "keySearch");
                  }}
                  leftSection={<IconSearch color="#15aabf" />}
                  onKeyDown={async (e) => {
                    if (e.key === "Enter") {
                      await fetchData();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col
                span={sizeGrid}
                // style={{ flexBasis: "100%", maxWidth: "320px", flex: "1" }}
              >
                <DatePickerInput
                  type="default"
                  leftSection={<IconTimeDuration15 color="#15aabf" />}
                  size="sm"
                  placeholder="Từ ngày"
                  locale="vi"
                  //   value={value}
                  valueFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    const startDateISO = e ? e.toLocaleDateString() : null;
                    handleChangeSearchValue(startDateISO ?? "", "startDate");
                  }}
                  clearable
                />
              </Grid.Col>
              <Grid.Col
                span={sizeGrid}
                // style={{ flexBasis: "100%", maxWidth: "320px", flex: "1" }}
              >
                <DatePickerInput
                  leftSection={<IconTimeDuration15 color="#15aabf" />}
                  size="sm"
                  placeholder="Đến ngày"
                  locale="vi"
                  //  value={value}
                  valueFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    const startDateISO = e ? e.toLocaleDateString() : null;

                    handleChangeSearchValue(startDateISO ?? "", "endDate");
                  }}
                  clearable
                />
              </Grid.Col>

              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Phòng ban"
                  limit={50}
                  leftSection={<IconBuildingSkyscraper color="#15aabf" />}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={dataFetch
                    .filter((item) => item.text !== "Tất cả")
                    .map((item) => {
                      return {
                        label: item.text ?? "",
                        value: String(item.value ?? ""),
                      };
                    })}
                  onClick={async () => {
                    if (!dataFetch || dataFetch.length < 1) {
                      await fetchDataSelect();
                    }
                    return;
                  }}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "department");
                  }}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Chi nhánh"
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  limit={50}
                  leftSection={<IconBrandBootstrap color="#15aabf" />}
                  data={dataBranch.map((item) => {
                    return {
                      label: item.text ?? "",
                      value: String(item.value ?? ""),
                    };
                  })}
                  onClick={async () => {
                    if (!dataBranch || dataBranch.length < 1) {
                      await fetchDataBranch();
                    }
                    return;
                  }}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "branch");
                  }}
                />
              </Grid.Col>
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Nhân viên"
                  leftSection={<IconUser color="#15aabf" />}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  limit={50}
                  data={dataWarehouse.map((item) => {
                    return {
                      label: item.text ?? "",
                      value: String(item.value ?? ""),
                    };
                  })}
                  onClick={async () => {
                    if (!dataWarehouse || dataWarehouse.length < 1) {
                      await fetchDataWarehouse();
                    }
                    return;
                  }}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "creator");
                  }}
                />
              </Grid.Col>
              {/* <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Mã phí"
                  leftSection={<IconReceipt color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={transformedData}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "codefund");
                  }}
                  // renderOption={renderSelectOption}
                />
              </Grid.Col> */}
              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Tài khoản quỹ"
                  leftSection={<IconCoinPound color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={dataAccountFund}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "accountfund");
                  }}
                  renderOption={renderSelectOptionAccount}
                />
              </Grid.Col>
              {/* <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Chọn danh sách phiếu"
                  leftSection={<IconTicket color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={[
                    { value: "1", label: "Danh sách phiếu thu / chi" },
                    { value: "2", label: "Danh sách phiếu chi" },
                  ]}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "Type");
                    handelType(Number(value));
                  }}
                  renderOption={TypeTicket}
                />
              </Grid.Col> */}

              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Loại phiếu"
                  leftSection={<IconTicket color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={[
                    { value: "1", label: "Thu" },
                    { value: "2", label: "Chi" },
                    // { value: "1", label: "Phiếu chi" },
                    // { value: "2", label: "Phiếu báo nợ" },
                  ]}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "typeReceipt");
                  }}
                  renderOption={renderSelectOptionType}
                />
              </Grid.Col>

              <Grid.Col span={sizeGrid}>
                <Select
                  searchable
                  clearable
                  placeholder="Kiểu phiếu"
                  leftSection={<IconTicket color="#15aabf" />}
                  limit={50}
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  data={[
                    { value: "1", label: "Đơn hàng" },
                    { value: "2", label: "Nội bộ" },
                    { value: "3", label: "Báo nợ" },
                    { value: "4", label: "Báo có" },
                    { value: "5", label: "Khác" },
                  ]}
                  onChange={(value) => {
                    handleChangeSearchValue(value ?? "", "type");
                  }}
                  //     renderOption={renderSelectOptionType}
                />
              </Grid.Col>

              <Grid.Col span={sizeGrid}>
                <Button
                  leftSection={<IconSearch size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    await fetchData();
                  }}
                >
                  Tìm kiếm
                </Button>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default ListReceipts;
