import style from "./PrintStyle.module.css";
import logo from "../../../../assets/images/logo-hacom-2024.png";
import { IconCheck, IconSquare } from "@tabler/icons-react";
import { numberToWords } from "../../../../_base/helper/FunctionHelper";

const PrintImportBorrowedGood = ({
  innerRef,
  dataPrint,
}: {
  innerRef: any;
  dataPrint: any;
}) => {
  console.log(dataPrint);

  return (
    <div ref={innerRef} className={style.main}>
      <div className={style.header}>
        <img src={logo} alt="logo-hacom-2024" width={150} height={"auto"} />
        <div>
          <div className={style.line}>
            <h3>CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG NGHỆ HACOM</h3>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Trụ sở: </p>
            <p>129+131 Lê Thanh Nghị, Q.Hai Bà Trưng, TP.Hà Nội</p>
          </div>
          <div className={style.line}>
            <div>
              <p className={style.cText}>Tel: </p>
              <p>19001903</p>
            </div>
            <div>
              <p className={style.cText}>Email:</p>
              <p>info@hacom.vn</p>
            </div>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Website:</p>
            <p>www.hacom.vn</p>
          </div>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.title}>
          <h2>BIÊN BẢN HOÀN TRẢ SẢN PHẨM CHO MƯỢN</h2>
        </div>
        <div className={style.wellFounded}>
          <div
            className={`${style.inline} ${style.indent} ${style.mb} ${style.italic}`}
          >
            <p>Căn cứ Phiếu tiếp nhận – Bàn giao sản phẩm bảo hành: </p>
            <p className={style.fw}>{dataPrint?.receiveTicketCode}</p>
            <p>
              {" "}
              ngày{" "}
              {String(new Date(dataPrint?.receptionDate).getDate()).padStart(
                2,
                "0"
              )}{" "}
              tháng{" "}
              {String(
                new Date(dataPrint?.receptionDate).getMonth() + 1
              ).padStart(2, "0")}{" "}
              năm {new Date(dataPrint?.receptionDate).getFullYear()};
            </p>
          </div>
          <p className={`${style.indent} ${style.mb} ${style.italic}`}>
            Căn cứ nhu cầu và năng lực các Bên.
          </p>
          <p className={`${style.indent} ${style.mb}`}>
            Hôm nay, ngày {String(new Date().getDate()).padStart(2, "0")} tháng{" "}
            {String(new Date().getMonth() + 1).padStart(2, "0")} năm{" "}
            {new Date().getFullYear()}, tại{" "}
            {JSON.parse(localStorage.getItem("userLogin") || "{}")?.branchName},
            chúng tôi gồm:
          </p>
        </div>
        <div className={`${style.partyA} ${style.mt}`}>
          <div className={`${style.inline}`}>
            <p className={`${style.fw}`}>
              A.BÊN A {"("}Bên mượn{")"}: Ông/Bà
            </p>
            <p>
              ..................................................................................................................................................
            </p>
          </div>
          <div className={`${style.inline} ${style.italic} ${style.mtl}`}>
            <IconCheck size={13} style={{ marginRight: "5px" }} />
            <p>
              Địa
              chỉ:.........................................................................................................................
            </p>
            <p>Điện thoại:.........................................</p>
          </div>
          <div className={`${style.inline} ${style.italic} ${style.mtl}`}>
            <IconCheck size={13} style={{ marginRight: "5px" }} />
            <p>
              Số
              CCCD:................................................................
            </p>
            <p>Ngày cấp:........................................</p>
            <p>Nơi cấp:.........................................</p>
          </div>
        </div>
        <div className={`${style.partyB} ${style.mtl}`}>
          <div className={`${style.inline}`}>
            <p className={`${style.fw}`}>
              B.BÊN B {"("}Bên cho mượn{")"}: CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG NGHỆ
              HACOM
            </p>
          </div>
          <div className={`${style.spaceBetween}`}>
            <div className={`${style.italic} ${style.mtl}`}>
              <div className={`${style.inline}`}>
                <IconCheck size={13} style={{ marginRight: "5px" }} />
                <p>Địa chỉ: {dataPrint?.branchAddress}</p>
              </div>
              <div className={`${style.inline} ${style.mtl}`}>
                <IconCheck size={13} style={{ marginRight: "5px" }} />
                <p>Đại diện: {dataPrint?.createByName}</p>
              </div>
            </div>
            <div className={`${style.italic} ${style.mtl}`}>
              <div className={`${style.inline}`}>
                <p>Điện thoại:.........................................</p>
              </div>
              <div className={`${style.inline} ${style.mtl}`}>
                <p>Chức vụ: {dataPrint?.roleName}</p>
              </div>
            </div>
          </div>
        </div>
        <p className={`${style.fw} ${style.mtl} ${style.mbt2}`}>
          Hai bên tiến hành bàn giao sản phẩm với nội dung sau:{" "}
        </p>
        <div className={`${style.article1}`}>
          <p className={`${style.fw} ${style.mbt2}`}>
            1. Sản phẩm cần bàn giao hoàn trả:
          </p>
          <p className={`${style.mbt2}`}>
            Bên A tiến hành bàn giao hoàn trả cho bên B các sản phẩm (máy móc,
            thiết bị, vật tư) sau:
          </p>
        </div>
        <table className={`${style.table}`}>
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên sản phẩm</th>
              <th>Số lượng</th>
              <th>Tình trạng</th>
              <th>Ghi chú</th>
            </tr>
          </thead>
          <tbody>
            {dataPrint?.tblWarrantyExportItemBorrowTicketDetailModels
              ?.filter((product: any) => product.type === false)
              ?.map((item: any, index: any) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.itemName}</td>
                  <td>{item.quantity}</td>
                  <td>{item.status}</td>
                  <td>{item.note}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className={`${style.article2}`}>
          <p className={`${style.fw} ${style.mbt2}`}>
            2. Tình trạng sản phẩm khi tiếp nhận hoàn trả:
          </p>
          <p className={`${style.fw} ${style.mbt2}`}>2.1. Tình trạng vật lý:</p>
          <div className={`${style.flex} ${style.mtt3} ${style.mbt3}`}>
            <div className={`${style.align}`} style={{ width: "50%" }}>
              <IconSquare size={16} style={{ marginRight: "5px" }} />
              <p>Đủ điều kiện tiếp nhận lại sản phẩm</p>
            </div>
            <div className={`${style.align}`} style={{ width: "50%" }}>
              <IconSquare size={16} style={{ marginRight: "5px" }} />
              <p>Không đủ điều kiện tiếp nhận lại sản phẩm</p>
            </div>
          </div>
          <p className={`${style.mbt2}`}>
            Diễn giải chi tiết:
            .........................................................................................................................................................................
          </p>
          <p className={`${style.mbt2}`}>
            ......................................................................................................................................................................................................
          </p>
          <p className={`${style.mbt2}`}>
            ......................................................................................................................................................................................................
          </p>
          <p className={`${style.fw} ${style.mbt2}`}>2.2. Tính năng sử dụng:</p>
          <div className={`${style.flex} ${style.mtt3} ${style.mbt3}`}>
            <div className={`${style.align}`} style={{ width: "50%" }}>
              <IconSquare size={16} style={{ marginRight: "5px" }} />
              <p>Đủ điều kiện tiếp nhận lại sản phẩm</p>
            </div>
            <div className={`${style.align}`} style={{ width: "50%" }}>
              <IconSquare size={16} style={{ marginRight: "5px" }} />
              <p>Không đủ điều kiện tiếp nhận lại sản phẩm</p>
            </div>
          </div>
          <p className={`${style.mbt2}`}>
            Diễn giải chi tiết:
            .........................................................................................................................................................................
          </p>
          <p className={`${style.mbt2}`}>
            ......................................................................................................................................................................................................
          </p>
          <p className={`${style.mbt2}`}>
            ......................................................................................................................................................................................................
          </p>
        </div>
        <div className={`${style.article3}`}>
          <p className={`${style.fw} ${style.mbt2}`}>
            3. Phương án xử lý đề xuất trong trường hợp sản phẩm không đảm bảo
            tình trạng vật lý, công năng sử dụng như tại thời điểm bên B bàn
            giao cho bên A mượn:
          </p>
          <div className={`${style.align} ${style.mbt}`}>
            <IconSquare size={16} style={{ marginRight: "5px" }} />
            <p>
              Bên A bồi thường tổn thất cho bên B bằng hiện vật có giá trị tương
              đương.
            </p>
          </div>
          <div className={`${style.align} ${style.mbt}`}>
            <IconSquare size={16} style={{ marginRight: "5px" }} />
            <p>Bên A bồi thường tổn thất cho bên B bằng tiền.</p>
          </div>
          <div className={`${style.align} ${style.mbt}`}>
            <IconSquare size={16} style={{ marginRight: "5px" }} />
            <p>
              Bên B tự tiến hành sửa chữa, khắc phục sản phẩm và bàn giao lại
              cho bên A theo tình trạng ban đầu.
            </p>
          </div>
          <p className={`${style.mbt2}`}>
            Diễn giải chi tiết:
            .........................................................................................................................................................................
          </p>
          <p className={`${style.mbt2}`}>
            ......................................................................................................................................................................................................
          </p>
          <p className={`${style.mbt2}`}>
            ......................................................................................................................................................................................................
          </p>
          <p className={`${style.mbt2}`}>
            ......................................................................................................................................................................................................
          </p>
          <p className={`${style.mbt2}`}>
            ......................................................................................................................................................................................................
          </p>
        </div>
        <div className={`${style.article4} ${style.mtt3}`}>
          <p className={`${style.fw} ${style.mbt2}`}>
            4. Số tiền đặt cọc bên B hoàn trả cho bên A:{" "}
          </p>
          <p className={`${style.mbt2}`}>
            Số tiền: {dataPrint?.totalDeposit?.toLocaleString()} VNĐ
          </p>
          <p className={`${style.mbt2}`}>
            Viết bằng chữ: {numberToWords(dataPrint?.totalDeposit)} việt nam
            đồng
          </p>
          <div
            className={`${style.align} ${style.mbt}`}
            style={{ gap: "40px" }}
          >
            <div>
              <p>Hình thức hoàn trả:</p>
            </div>
            <div className={`${style.align}`}>
              <IconSquare size={16} style={{ marginRight: "5px" }} />
              <p>Tiền mặt</p>
            </div>
            <div className={`${style.align}`}>
              <IconSquare size={16} style={{ marginRight: "5px" }} />
              <p>Chuyển khoản</p>
            </div>
          </div>
          <p className={`${style.mbt2}`}>
            Ngày thực hiện hoàn trả: ngày{" "}
            {String(new Date().getDate()).padStart(2, "0")} tháng{" "}
            {String(new Date().getMonth() + 1).padStart(2, "0")} năm{" "}
            {new Date().getFullYear()}
          </p>
        </div>
        <div className={`${style.article5} ${style.mtt3}`}>
          <p className={`${style.fw} ${style.mbt2}`}>
            5. Số tiền bên A bồi thường cho bên B do bên A làm hỏng/mất trong
            quá trình mượn sản phẩm (nếu có):
          </p>
          <p className={`${style.mbt2}`}>
            Số
            tiền:.........................................................................................................................................................................................
          </p>
          <p className={`${style.mbt2}`}>
            Viết bằng
            chữ:..............................................................................................................................................................................
          </p>
          <div
            className={`${style.align} ${style.mbt}`}
            style={{ gap: "40px" }}
          >
            <div>
              <p>Hình thức bồi thường:</p>
            </div>
            <div className={`${style.align}`}>
              <IconSquare size={16} style={{ marginRight: "5px" }} />
              <p>Tiền mặt</p>
            </div>
            <div className={`${style.align}`}>
              <IconSquare size={16} style={{ marginRight: "5px" }} />
              <p>Chuyển khoản</p>
            </div>
          </div>
          <p className={`${style.mbt2}`}>
            Ngày thực hiện bồi thường: ngày{" "}
            {String(new Date().getDate()).padStart(2, "0")} tháng{" "}
            {String(new Date().getMonth() + 1).padStart(2, "0")} năm{" "}
            {new Date().getFullYear()}
          </p>
        </div>
        <div className={`${style.article6} ${style.mtt3}`}>
          <p className={`${style.fw} ${style.mbt2}`}>6. Điều khoản chung</p>
          <p className={`${style.mbt2}`}>
            - Hai Bên cam kết và bảo đảm rằng khi một Bên đã thực hiện đầy đủ
            trách nhiệm theo Biên bản này, Bên còn lại sẽ không có bất kì khiếu
            nại hoặc tiến hành bất cứ hành động pháp lý nào khác nhằm chống lại
            hoặc gây ảnh hưởng/gây thiệt hại đến uy tín, danh dự, hình ảnh, tài
            sản… của Bên kia.
          </p>
          <p className={`${style.mbt2}`}>
            - Biên bản có hiệu lực ngay sau khi có đầy đủ chữ ký của cả Hai Bên,
            xác nhận Hai Bên đã hoàn tất trách nhiệm, nghĩa vụ phát sinh từ việc
            mượn – trả sản phẩm trong quá trình Bên A bảo hành sản phẩm tại Bên
            B.
          </p>
          <p className={`${style.mbt2}`}>
            - Biên bản này sẽ mặc nhiên hết hiệu lực sau khi các Bên thực hiện
            xong các nội dung ghi nhận trong Biên bản.
          </p>
          <p className={`${style.mbt2}`}>
            - Biên bản này được lập thành 02 bản, Bên A giữ 01 bản, Bên B giữ 01
            bản có giá trị như nhau làm căn cứ thực hiện.
          </p>
        </div>
      </div>
      <div className={`${style.signature} ${style.mtl2}`}>
        <div>
          <p className={`${style.mbt2} ${style.fw}`}>ĐẠI DIỆN BÊN A</p>
          <p className={`${style.italic}`}>
            {"("}Ký và ghi rõ họ tên{")"}
          </p>
        </div>
        <div>
          <p className={`${style.mbt2} ${style.fw}`}>ĐẠI DIỆN BÊN B</p>
          <p className={`${style.italic}`}>
            {"("}Ký và ghi rõ họ tên{")"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrintImportBorrowedGood;
