import {
  Box,
  Checkbox,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMDeliveryDepartment } from "../../../../model/TblDMDeliveryDepartment";
import { getTblDmEmployeeSelect } from "../../../../service/getSelectApi";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    companyCode: null,
    ddName: null,
    note: null,
    active: null,
    branch: null,
    managerId: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const [dataEmployeedSelect, setDataEmployeedSelect] = useState<
    ComboboxItem[]
  >([]);

  const form = useForm<TblDMDeliveryDepartment>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<
      MessageResponse<TblDMDeliveryDepartment>
    >(`/api/v1/TblDMDeliveryDepartment/detail?id=${id}`);
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        dataEmployeeSelect();
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const dataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeedSelect(
      get
        .filter((item) => item.value != null && item.text != null)
        .map((item) => ({
          value: item.value,
          // value: item.text,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "250px", md: "300px", lg: "400px" }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <TextInput
              label={"Mã BPGH"}
              placeholder={"Nhập mã BPGH"}
              type="text"
              variant="filled"
              readOnly
              {...form.getInputProps("code")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 8 }}>
            <TextInput
              label={"Tên BPGH"}
              placeholder={"Nhập tên BPGH"}
              type="text"
              variant="filled"
              readOnly
              {...form.getInputProps("ddName")}
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"Chi nhánh"}
              variant="filled"
              readOnly
              {...form.getInputProps("branch")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label={"Người quản lý"}
              variant="filled"
              data={dataEmployeedSelect}
              value={form.values.managerId?.toString()}
              rightSection=" "
              readOnly
            />
          </Grid.Col>
        </Grid>

        <Grid align="center">
          <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
            <TextInput
              label={"Công ty"}
              variant="filled"
              readOnly
              {...form.getInputProps("companyCode")}
            />
          </Grid.Col>
        </Grid>
        <Textarea
          label={"Ghi chú"}
          placeholder="Nhập ghi chú"
          variant="filled"
          readOnly
          {...form.getInputProps("note")}
        />
        <Checkbox
          label={"Sử dụng"}
          checked={form.values.active}
          readOnly
          {...form.getInputProps("active")}
          onChange={() => {}}
          mt={15}
        />
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
