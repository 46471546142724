import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useDebouncedCallback, useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import {
  getBranchSelect,
  getErrorCodeSelect,
  getTblDmEmployeeSelect,
} from "../../../service/getSelectApi";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { sky_blue } from "../../../const/variables";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { useForm } from "@mantine/form";
import { ProcessingAssignment } from "../../../model/ProcessingAssignment";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { UpdateError } from "../../../model/WarratyInformation";

const RemoteProcessing = ({
  id,
  onClose,
  status,
}: {
  id: any;
  onClose: any;
  status: any;
}) => {
  const entity = {
    id: id,
    priority: "Thấp",
    levelTest: "DE",
    employee: [
      {
        id: null,
        handleDate: null,
        errorCode: null,
        conclusionError: null,
        newWarrantyterm: null,
        resolution: null,
        status: null,
        note: null,
      },
    ],
  };

  const [priority, setPriority] = useState<string>("Thấp");
  const [dataUpdateError, setDataUpdateError] = useState<UpdateError>();
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataErrorCodeSelect, setDataErrorCodeSelect] = useState<
    ComboboxItem[]
  >([]);
  const [listDetailId, setListDetailId] = useState<number[]>([]);

  const [scrolled, setScrolled] = useState(false);
  const [editAndSave, setEditAndSave] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isEditPCTX, setIsEditPCTX] = useState({
    handleDate: false,
    conclusionError: false,
    resolution: false,
    status: false,
    note: false,
  });

  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<ProcessingAssignment>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {},
  });

  const warrantyServiceRequestFormModels = form.getValues().employee ?? [];

  const handleChangeValue = useDebouncedCallback(
    (key: string, value: any, index: number) => {
      form.setValues((prevData: any) => {
        const prevEmployee = prevData.employee || {};

        const updatedModels = [...prevEmployee];
        updatedModels[index] = {
          ...updatedModels[index],
          [key]: value,
          id: listDetailId[index],
          handleDate:
            value && key === "handleDate" ? value : new Date().toISOString(),
          newWarrantyterm: value && key === "newWarrantyterm" ? value : "0",
        };

        return {
          ...prevData,
          employee: updatedModels,
        };
      });
    },
    300
  );

  const handleAssignRemoteProcessing = async (
    dataSubmit: ProcessingAssignment
  ) => {
    open();
    let url = "";
    if (status === "Hãng xử lý") {
      url = "/api/v1/TblWarrantyServiceRequestForm/update-error-by-brand";
    } else {
      url = "/api/v1/TblWarrantyServiceRequestForm/update-error";
    }
    const data = await repositoryMdm.post<
      MessageResponse<ProcessingAssignment>
    >(`${url}`, dataSubmit);
    if (data?.success) {
      NotificationExtension.Success(
        "Cập nhật bảo hành thông tin sản phẩm thành công !"
      );
      setTimeout(() => {
        onClose((prev: any) => !prev);
        modals.closeAll();
      }, 1500);
    }
    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblWarrantyServiceRequestForm/get-to-update-error?id=${id}`
    );
    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        setDataUpdateError(dataApi);
        const updatedEmployees =
          dataApi.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels.map(
            (item: any) => ({
              id: item.detailId,
              handleDate: item.handleDate,
              errorCode: item.attribute9,
              conclusionError: item.conclusionError,
              newWarrantyterm: item.newWarrantyterm,
              resolution: item.resolution,
              status: item.itemStatus,
              note: item.detailNote,
            })
          );

        form.setValues({
          ...form.values,
          employee: updatedEmployees,
        });
      }
      Promise.all([getDataEmployeeSelect(), getDataBranchSelect()]);
      close();
    } else {
      modals.closeAll();
    }
  };

  const getDataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataBranchSelect = async () => {
    const get = await getBranchSelect();
    setDataBranchSelect(
      get
        ?.filter(
          (item) =>
            item.value != null && item.value != "-1" && item.text != null
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getSelectErrorCode = async () => {
    const get = await getErrorCodeSelect();
    setDataErrorCodeSelect(
      get
        ?.filter(
          (item) =>
            item.value != null && item.value != "-1" && item.text != null
        )
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    getSelectErrorCode();
  }, []);

  useEffect(() => {
    if (id && dataErrorCodeSelect.length > 0) {
      callApiGetData();
    }
  }, [id, dataErrorCodeSelect]);

  useEffect(() => {
    const updateItemId = () => {
      if (
        dataUpdateError?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels
      ) {
        const uniqueItemId = Array.from(
          new Set(
            dataUpdateError.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels
              .map((item) => item.detailId)
              .filter((detailId) => detailId !== null)
          )
        ).map(Number);
        setListDetailId(uniqueItemId);
      }
    };

    updateItemId();
  }, [dataUpdateError]);

  return (
    <Box
      component="form"
      mx="auto"
      w={"82.5vw"}
      maw={1350}
      onSubmit={form.onSubmit((e: ProcessingAssignment) => {
        handleAssignRemoteProcessing(e);
      })}
      onMouseDown={() => {
        setIsEditPCTX({
          handleDate: false,
          conclusionError: false,
          resolution: false,
          status: false,
          note: false,
        });
        setEditAndSave(false);
      }}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Fieldset legend="Thông tin phân công" mt={10}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              label="Số phân công"
              value={dataUpdateError?.assignmentCode ?? ""}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <DateTimePicker
              label="Ngày phân công"
              placeholder="Chọn ngày phân công"
              value={
                dataUpdateError?.assignDate
                  ? new Date(dataUpdateError.assignDate)
                  : null
              }
              valueFormat="DD/MM/YYYY hh:mm A"
              clearable
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <TextInput
              label="Người phân công"
              value={dataUpdateError?.assignerName ?? ""}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <Select
              label="Người nhận phân công"
              data={dataEmployeeSelect}
              value={dataUpdateError?.empInchargeId?.toString() ?? ""}
              rightSection={" "}
              comboboxProps={{ width: 225 }}
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
            <Select
              label="Người hỗ trợ"
              placeholder="Nhập tên người hỗ trợ"
              data={dataEmployeeSelect}
              value={dataUpdateError?.empSupportId?.toString() ?? ""}
              rightSection={" "}
              comboboxProps={{ width: 225 }}
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              label="Nội dung phân công"
              placeholder="Nhập nội dung phân công"
              value={dataUpdateError?.note ?? ""}
              variant="filled"
              readOnly
            />
          </Grid.Col>
        </Grid>
      </Fieldset>
      <Fieldset legend="Thông tin bảo hành sản phẩm" mt={10}>
        <Flex wrap={"wrap"} justify={"space-between"}>
          <TextInput
            label="Thông tin khách hàng"
            value={`${
              dataUpdateError
                ?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels?.[0]
                ?.custMeeting
            }${
              dataUpdateError
                ?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels?.[0]
                ?.custPhone !== null
                ? `- ${dataUpdateError?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels?.[0]?.custPhone}`
                : ``
            } - ${
              dataUpdateError
                ?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels?.[0]
                ?.meetingAddress
            }`}
            variant="filled"
            readOnly
            w={550}
          />
          <Flex gap={"md"}>
            <Select
              label="Mức độ kiểm tra"
              placeholder="Chọn mức độ kiểm tra"
              data={[
                {
                  label: "Dễ",
                  value: "DE",
                },
                {
                  label: "Đơn giản",
                  value: "DON_GIAN",
                },
                {
                  label: "Bình thường",
                  value: "BINH_THUONG",
                },
                {
                  label: "Khó",
                  value: "KHO",
                },
                {
                  label: "Phức tạp",
                  value: "PHUC_TAP",
                },
              ]}
              value={form.getValues().levelTest?.toString()}
              onChange={(e) =>
                form.setValues((prev) => ({
                  ...prev,
                  levelTest: e ? e : "DE",
                }))
              }
            />
            <Select
              label="Độ ưu tiên"
              placeholder="Chọn độ ưu tiên"
              data={[
                { value: "Cao", label: "Cao" },
                { value: "Thấp", label: "Thấp" },
              ]}
              value={form.getValues().priority?.toString()}
              onChange={(e) =>
                form.setValues((prev) => ({
                  ...prev,
                  priority: e ? e : "Thấp",
                }))
              }
            />
          </Flex>
        </Flex>
        <ScrollArea
          h={225}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
          scrollbars="x"
          mt={10}
        >
          <Table
            striped
            highlightOnHover
            withTableBorder
            withColumnBorders
            w={1750}
          >
            <Table.Thead
              className={cx(classes.header, {
                [classes.scrolled]: scrolled,
              })}
            >
              <Table.Tr>
                <Table.Th>Tên sản phẩm</Table.Th>
                <Table.Th>Ngày xử lý</Table.Th>
                <Table.Th>Ghi nhận lỗi ban đầu</Table.Th>
                <Table.Th>Diễn giải</Table.Th>
                <Table.Th>Cách khắc phục</Table.Th>
                <Table.Th>Trạng thái xử lý</Table.Th>
                <Table.Th>Hạn bảo hành mới</Table.Th>
                <Table.Th>Ghi chú</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {(dataUpdateError
                ?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels
                ?.length ?? 0) > 0 &&
                dataUpdateError?.getToUpdateWarrantyServiceRequestFormForAssignmentCommandModels?.map(
                  (item: any, index) => (
                    <Table.Tr key={index}>
                      <Table.Td w={400}>
                        <Tooltip
                          label={
                            item.itemName
                              ? item?.itemName
                              : item?.itemNameOutside
                          }
                        >
                          <Text truncate="end" w={400} size="12.5px">
                            {item.itemName
                              ? item?.itemName
                              : item?.itemNameOutside}
                          </Text>
                        </Tooltip>
                      </Table.Td>
                      <Table.Td w={150}>
                        <DateTimePicker
                          // value={
                          //   warrantyServiceRequestFormModels[index]?.handleDate
                          //     ? new Date(
                          //         warrantyServiceRequestFormModels[index]
                          //           .handleDate ?? ""
                          //       )
                          //     : null
                          // }
                          defaultValue={new Date()}
                          minDate={new Date()}
                          locale="vi"
                          onChange={(value: any) =>
                            handleChangeValue(
                              "handleDate",
                              value
                                ? new Date(value ?? "").toISOString()
                                : null,
                              Number(index)
                            )
                          }
                          clearable
                          size="xs"
                        />
                      </Table.Td>
                      <Table.Td w={225}>
                        <Select
                          data={dataErrorCodeSelect}
                          defaultValue={
                            warrantyServiceRequestFormModels[index]
                              ?.errorCode ?? ""
                          }
                          searchable
                          clearable
                          size="xs"
                          nothingFoundMessage="Không tìm thấy dữ liệu !"
                          onChange={(e) =>
                            handleChangeValue(
                              "errorCode",
                              e ? e : null,
                              Number(index)
                            )
                          }
                        />
                      </Table.Td>
                      <Table.Td w={200}>
                        <TextInput
                          defaultValue={
                            warrantyServiceRequestFormModels[index]
                              ?.conclusionError ?? ""
                          }
                          onChange={(e) =>
                            handleChangeValue(
                              "conclusionError",
                              e.currentTarget.value,
                              Number(index)
                            )
                          }
                          size="xs"
                        />
                      </Table.Td>
                      <Table.Td w={200}>
                        <TextInput
                          defaultValue={
                            warrantyServiceRequestFormModels[index]
                              ?.resolution ?? ""
                          }
                          onChange={(e) =>
                            handleChangeValue(
                              "resolution",
                              e.currentTarget.value,
                              Number(index)
                            )
                          }
                          size="xs"
                        />
                      </Table.Td>
                      <Table.Td w={150}>
                        <Select
                          data={[
                            { label: "Trả khách", value: "TRA_KHACH" },
                            { label: "Nhập kho", value: "NHAP_KHO" },
                          ]}
                          defaultValue={
                            warrantyServiceRequestFormModels[index]?.status ??
                            ""
                          }
                          onChange={(e) =>
                            handleChangeValue(
                              "status",
                              e ? e?.toString() : null,
                              Number(index)
                            )
                          }
                          size="xs"
                        />
                      </Table.Td>
                      <Table.Td w={150}>
                        <NumberInput
                          defaultValue={0}
                          allowNegative={false}
                          suffix=" tháng"
                          hideControls
                          size="xs"
                          onChange={(e) =>
                            handleChangeValue(
                              "newWarrantyterm",
                              e ? e?.toString() : "0",
                              Number(index)
                            )
                          }
                          disabled={
                            warrantyServiceRequestFormModels[index]?.status !==
                            "TRA_KHACH"
                          }
                        />
                      </Table.Td>
                      <Table.Td>
                        <TextInput
                          defaultValue={
                            warrantyServiceRequestFormModels[index]?.note ?? ""
                          }
                          onChange={(e) =>
                            handleChangeValue(
                              "note",
                              e.currentTarget.value,
                              Number(index)
                            )
                          }
                          size="xs"
                        />
                      </Table.Td>
                    </Table.Tr>
                  )
                )}
            </Table.Tbody>
          </Table>
        </ScrollArea>
      </Fieldset>
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
        >
          Quay lại
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default RemoteProcessing;
