import "../../../Styles/tab.component.css";

import { Paper, Tabs } from "@mantine/core";
import { useState } from "react";
import DMDeliveryMethod from "./DMDeliveryMethod/DMDeliveryMethod";
import DMUomCode from "../Commodities/DMUomCode/DMUomCode";
import DMSaleChannel from "../Commodities/DMSaleChannel/DMSaleChannel";
import DMSerialStatus from "../Commodities/SerialStatus/DMSerialStatus";
import DMOrderType from "./DMOrderType/DMOrderType";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import DMSupplies from "./DMSupplies/DMSupplies";
import CategoryCombination from "../../Warehouse/CategoryCombination/CategoryCombination";

const Commodities = () => {
  const [activeTab, setActiveTab] = useState<string | null>("deliverymethod");
  const breadCrumb = false;

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Paper shadow="md" radius="lg">
        <Tabs p={"xs"} value={activeTab} onChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="deliverymethod">Danh mục cách giao hàng</Tabs.Tab>
            <Tabs.Tab value="uomCode">Danh mục đơn vị tính</Tabs.Tab>
            <Tabs.Tab value="saleChannel">Danh mục kênh bán hàng</Tabs.Tab>
            <Tabs.Tab value="serialStatus">Danh mục trạng thái serial</Tabs.Tab>
            <Tabs.Tab value="orderType">Danh mục loại đơn hàng</Tabs.Tab>
            <Tabs.Tab value="supplies">Danh mục vật tư</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="deliverymethod">
            {activeTab === "deliverymethod" ? <DMDeliveryMethod /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="uomCode">
            {activeTab === "uomCode" ? <DMUomCode /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="saleChannel">
            {activeTab === "saleChannel" ? <DMSaleChannel /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="serialStatus">
            {activeTab === "serialStatus" ? <DMSerialStatus /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="orderType">
            {activeTab === "orderType" ? <DMOrderType /> : <></>}
          </Tabs.Panel>
          <Tabs.Panel value="supplies">
            {activeTab === "supplies" ? (
              <CategoryCombination breadCrumb={breadCrumb} />
            ) : (
              <></>
            )}
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </>
  );
};

export default Commodities;
