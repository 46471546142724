import React from "react";
import { Box, Button, Group, LoadingOverlay, TextInput } from "@mantine/core";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { modals } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { MessageResponse } from "../../model/MessageResponse";
import { useDisclosure } from "@mantine/hooks";
import { sky_blue } from "../../const/variables";

interface AppDeleteCacheViewProps {
  valuePath: string;
  onClose: (load: number) => void;
  load: number;
}

interface CachePrefix {
  redis: string[];
  memory: string[];
}

export const AppDeleteCacheView: React.FC<AppDeleteCacheViewProps> = ({
  valuePath,
  onClose,
  load,
}) => {
  const form = useForm({
    initialValues: { cache: "" },
    validateInputOnChange: true,
  });

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const callApiGetData = async (prefix: string) => {
    open();
    try {
      const response = await fetch(
        `${valuePath}/api/v1/cache/delete-cache-prefix?prefix=${prefix}`
      );
      if (!response.ok) throw new Error("Network response was not ok");

      const callApi: MessageResponse<CachePrefix> = await response.json();
      if (callApi?.success) {
        const dataApi = callApi.data;
        if (dataApi.memory.length === 0 && dataApi.redis.length === 0) {
          onClose(load + 1);
          NotificationExtension.Success("Xóa Cache thành công.");
          modals.closeAll();
        }
      } else {
        NotificationExtension.Fails("Error!");
        modals.closeAll();
      }
    } catch (error) {
      NotificationExtension.Fails("Cache nhập đang để rỗng!");
      console.error("Error fetching data:", error);
      modals.closeAll();
    } finally {
      close();
    }
  };

  const handleSubmit = async (values: { cache: string }) => {
    await callApiGetData(values.cache);
  };

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit(handleSubmit)}
      style={{ position: "relative" }}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <TextInput
        mt="lg"
        label="Nhập: "
        placeholder="Nhập..."
        {...form.getInputProps("cache")}
      />
      <Group mt={20}>
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => modals.closeAll()}
          leftSection={!visible && <IconWindow size={18} />}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible && <IconCheck size={18} />}
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};
