import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Input,
  Select,
  Tooltip,
} from "@mantine/core";
import {
  IconHistory,
  IconTransactionEuro,
  IconTrash,
  IconUpload,
  IconZoomReset,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_ExpandedState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { _localization } from "../../config/location";
import { ListRoleModel } from "../../model/ListRoleModel ";
import { MessageResponse } from "../../model/MessageResponse";
import "../../Styles/tab.component.css";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { repositoryMaster } from "../../_base/_const/_constVar";
import { ListAuthozireModel } from "../../model/ListAuthozireModel";

function sortListByParentId(list: ListAuthozireModel[]): ListAuthozireModel[] {
  // Tạo một Map để lưu các phần tử cha và con
  const map = new Map<number, ListAuthozireModel>();

  // Tạo danh sách đã sắp xếp
  const sortedList: ListAuthozireModel[] = [];

  // Đưa tất cả các phần tử vào Map để dễ tìm kiếm
  list.forEach((item) => {
    map.set(item.id, item);
  });

  // Hàm đệ quy để thêm các phần tử cha và con vào danh sách đã sắp xếp
  function addItemAndChildren(item: ListAuthozireModel) {
    sortedList.push(item);

    // Tìm các phần tử con của phần tử hiện tại
    const children = list.filter((child) => child.parentId === item.id);

    // Đệ quy cho từng phần tử con
    children.forEach((child) => addItemAndChildren(child));
  }

  // Lặp qua danh sách để tìm phần tử cha (không có `parentId`) và bắt đầu quá trình đệ quy
  list.forEach((item) => {
    if (!item.parentId) {
      addItemAndChildren(item);
    }
  });

  return sortedList;
}

const UserAddGroup = ({ idAuthozire }: { idAuthozire: string }) => {
  //#region  table

  //data and fetching state
  const [data, setData] = useState<ListAuthozireModel[]>([]);
  const [dataTemp, setDataTemp] = useState<ListAuthozireModel[]>([]);
  const [dataApi, setDataApi] = useState<ListAuthozireModel[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dateSelect, setDataSelect] = useState<string[]>([]);
  const [roleList, setRoleList] = useState<string[]>([]);
  const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    // if (valuesList.length < 1) setSelectIds([]);
    // else {
    //   const valuesList = Object.keys(rowSelection);
    //   setSelectIds(valuesList);
    // }
    console.log(valuesList);
  }, [rowSelection]);

  useEffect(() => {
    //
    const res: MRT_RowSelectionState = {};
    for (let index = 0; index < roleList.length; index++) {
      const element = roleList[index];
      const filtered = dataApi.find((x) => x.id.toString() === element);
      if (filtered?.id) res[filtered.id] = true;
    }
    setRowSelection(res);
  }, [roleList]);

  useEffect(() => {
    let searchResult: ListAuthozireModel[] = dataTemp;
    if (keySearch.length > 0) {
      searchResult = dataTemp.filter(
        (product) =>
          // Chuyển đổi các giá trị trong các trường thành chữ thường và so sánh với từ khóa tìm kiếm
          product.description?.toLowerCase().includes(keySearch) ||
          product.name?.toLowerCase().includes(keySearch)
      );
    }

    setData(searchResult);
  }, [keySearch]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setOpenedMenu(false);
    try {
      const json = await repositoryMaster.get<
        MessageResponse<ListAuthozireModel[]>
      >("/api/v1/ListAuthozire/get-list");
      if (json && json.data) {
        await getAuthRole(idAuthozire);
        const _d = json.data;
        // Sắp xếp danh sách sao cho các đối tượng có id nằm trong danh sách keys lên đầu
        const sortedList = sortListByParentId(_d);
        setDataApi(sortedList);
        setData(sortedList);
        setRowCount(sortedList.length);
        setDataTemp(sortedList);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };
  function buildTree(
    data: ListAuthozireModel[],
    parentId: number | null = null
  ): ListAuthozireModel[] {
    return data
      .filter((item) => item.parentId === parentId)
      .map((item) => ({
        ...item,
        subRows: buildTree(data, item.id),
      }));
  }

  const getAuthRole = async (idAuth: string) => {
    if (!idAuth || idAuth.length < 1) {
      NotificationExtension.Fails("Nhóm quyền rỗng !");
      return;
    }
    const url = "/api/v1/auth/get-role?id=" + idAuthozire;
    const res = await repositoryMaster.get<MessageResponse<string[]>>(url);
    if (res && res.data && res.data.length > 0) setRoleList(res.data);
  };

  const authRole = async () => {
    if (!idAuthozire || idAuthozire.length < 1) {
      NotificationExtension.Fails("Nhóm quyền rỗng !");
      return;
    }

    const url = "/api/v1/auth/update-group?id=" + idAuthozire;
    const res = await repositoryMaster.post<MessageResponse<any>>(url, {
      ids: Object.keys(rowSelection),
      id: idAuthozire,
    });
    if (res)
      if (res.success) {
        NotificationExtension.Success("Phân quyền thành công !");
        return;
      } else {
        NotificationExtension.Fails("Phân quyền thất bại !");
        return;
      }
  };

  function getColorStatus(text: boolean) {
    switch (text) {
      case true:
        return "teal";
      default:
        return "red";
    }
  }

  const columns = React.useMemo<MRT_ColumnDef<ListAuthozireModel>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
      },

      {
        accessorKey: "name",
        header: "Tên nhóm",
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row && row.original?.parentId && row.original?.parentId > 0
              ? "----- " + renderedCellValue
              : renderedCellValue}
          </>
        ),
      },
      {
        accessorKey: "description",
        header: "Mô tả",
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row && row.original?.parentId && row.original?.parentId > 0
              ? "----- " + renderedCellValue
              : renderedCellValue}
          </>
        ),
      },
      {
        accessorKey: "inActive",
        header: "Trạng thái",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorStatus(row.original.inActive ?? false)}>
              {row.original.inActive ? "Đang hoạt động" : "Dừng hoạt động"}
            </Badge>
          </>
        ),
      },
    ],
    []
  );
  const [openedMenu, setOpenedMenu] = useState(false);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input
            placeholder="Nhập từ khóa ..."
            onChange={(e) => {
              setKeySearch(e.target.value ?? "");
            }}
          />
        </Flex>
      </>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify={{ sm: "flex-start", lg: "flex-end" }}
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            leftSection={<IconTransactionEuro size={14} />}
            color=""
            variant="outline"
            onClick={async () => {
              await authRole();
            }}
          >
            Phân quyền
          </Button>
          <Button
            leftSection={<IconZoomReset size={14} />}
            color="violet"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Load data
          </Button>
        </Flex>
      </>
    ),

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    enableExpanding: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand", "name"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
      expanded: true,
    },
    mantineTableContainerProps: {
      style: { maxHeight: "80vh", minHeight: "50vh" },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    rowCount: rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "bold", //conditional styling
        fontSize: "11px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};
export default UserAddGroup;
