import { Box, Button, Input, Select, Text } from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";

import { useForm } from "@mantine/form";
import { IconMapPin, IconSearch } from "@tabler/icons-react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import _breadcrumb from "../../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../../_base/model/_base/BaseTable";
import { _localization } from "../../../../config/location";
import { SelectListItemModel } from "../../../../model/SelectListItem";
import { ItemDataList } from "../../../../model/TblOrderHeader";
import BreadcrumbLayout1 from "../../../../_base/component/_layout/_breadcrumb";

type ReportPhoneItem = {
  organizationId: number;
  maCn: string;
  tenCn: string;
  soLuongGd: number;
  tGiaVon: number;
  tDoanhThu: number;
  tongSauCk: number;
  tLai: number;
  fx: number;
};
const ReportBrach = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<ReportPhoneItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
    },
  });
  const [search, setSearch] = useState({
    branchCode: [],
  });
  const handleChangeSearchValue = (value: string[] | null, key: string) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: value ? value : [],
    }));
  };
  const [dataBranchCode, setDataBranchCode] = useState<SelectListItemModel[]>(
    []
  );
  const formatCurrencyVND = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };
  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination.pageSize}&Take=${
      pagination.pageSize
    }`;
    if (search.branchCode && search.branchCode.length > 0) {
      search.branchCode.forEach((branchCode) => {
        url += `&OrganizationId=${branchCode}`;
      });
    }
    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/report-sales-branch${url}`
      );
      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setData(result);
        setRowCount(response.totalCount);
      } else {
        setData([]);
        setRowCount(0);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<ReportPhoneItem>[]>(
    () => [
      {
        accessorKey: "maCn",
        header: "Mã chi nhánh",
      },
      {
        accessorKey: "tenCn",
        header: "Tên chi nhánh",
      },
      {
        accessorKey: "soLuongGd",
        header: "Số lượng giao dịch",
      },
      {
        accessorKey: "tGiaVon",
        header: "Giá vốn",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tDoanhThu",
        header: "Doanh thu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tongSauCk",
        header: "Sau chiết khấu",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "tLai",
        header: "Lãi",
        Cell: ({ renderedCellValue }: any) => (
          <>{formatCurrencyVND(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "fx",
        header: "Tỷ lệ",
        Cell: ({ row }: any) => {
          const value = row.original.fx;
          if (value != null) {
            const formattedValue = (value * 100).toFixed(2) + "%";
            return <Text>{formattedValue}</Text>;
          }
          return null;
        },
      },
    ],
    []
  );
  const DataBranchCode = async () => {
    try {
      const response = await repositoryPos.get(
        `api/v1/TblDmInventory/get-select-branch?auth=true`
      );
      if (response && response.success && response.data) {
        const transformedData = response.data
          .filter(
            (branch: any) => branch.att2 !== null && branch.text !== "Tất cả"
          )
          .map((branch: any) => ({
            value: branch.value,
            label: branch.att2 + " - " + branch.text,
          }));

        setDataBranchCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["itemCode"],
        // right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height - 100, minHeight: height - 100 },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  useEffect(() => {
    fetchData();
  }, [pagination]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (160 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        <Input
          style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
          placeholder="Từ khoá"
          type="text"
          leftSection={<IconSearch color="#15aabf" />}
          key={formSeachInput.key("keySearch")}
          {...formSeachInput.getInputProps("keySearch")}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              fetchData();
            }
          }}
        />
        <Select
          size="sm"
          placeholder="Mã chi nhánh"
          searchable
          clearable
          data={dataBranchCode}
          leftSection={<IconMapPin size={20} color="#15aabf" />}
          onClick={() => {
            if (dataBranchCode.length === 0) {
              DataBranchCode();
            }
          }}
          onChange={(value) =>
            handleChangeSearchValue(value ? [value] : null, "branchCode")
          }
        />
        <Button
          type="submit"
          leftSection={<IconSearch size={14} />}
          color="blue"
          variant="outline"
          onClick={async () => {
            await fetchData();
          }}
        >
          Tìm kiếm
        </Button>
      </Box>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default ReportBrach;
