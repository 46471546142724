// xanh rêu
export const olive_green = {
  dark: "#607d04", // Dark olive green
  medium: "#789d28", // Medium olive green
  light: "#a1b96c", // Light olive green
  pale: "#c8d4a3", // Pale olive green
  yellowish: "#b3b750", // Yellowish olive green
};

// xanh da trời
export const sky_blue = {
  base: "#3598dc", // Base sky blue
  medium: "#5474b4", // Medium sky blue
  light: "#66b3e8", // Light sky blue
  light_alt: "#66CCFF", // Alternative light sky blue
  dark: "#2a79b0", // Dark sky blue
  pale: "#a4d3f5", // Pale sky blue
  deep: "#1e5f8c", // Deep sky blue
};

// xanh navy
export const navy_blue = {
  base: "#03346E", // Base navy blue
  light: "#4A5D81", // Light navy blue
  dark: "#001F3F", // Dark navy blue
  pale: "#7F8C8D", // Pale navy blue
  deep: "#002F5D", // Deep navy blue
};

// xanh coban
export const cobalt_blue = {
  base: "#228BE6", // Base cobalt blue
  light: "#4aa3ec", // Light cobalt blue
  dark: "#1a6db5", // Dark cobalt blue
  pale: "#86c2f1", // Pale cobalt blue
  deep: "#14528b", // Deep cobalt blue
};

// xám
export const gray = {
  base: "#868e96", // Base gray
  light: "#adb5bd", // Light gray
  dark: "#495057", // Dark gray
  pale: "#ced4da", // Pale gray
  deep: "#343a40", // Deep gray
};

// xanh ngọc
export const teal = {
  base: "#15AABF", // Base teal
  light: "#4DB6AC", // Light teal
  dark: "#00796B", // Dark teal
  pale: "#80CBC4", // Pale teal
  deep: "#004D40", // Deep teal
};

// đỏ
export const red = {
  base: "#FA5252", // Base vivid red
  light: "#FF6B6B", // Light red
  medium: "#f76d6d", // Medium coral red
  dark: "#C9302C", // Dark red
  pale: "#F5C6C6", // Pale red
  deep: "#C82333", // Deep red
};

// màu be
export const beige = {
  base: "#E2E2B6", // Base beige
  light: "#F5F5DC", // Light beige
  dark: "#D2B48C", // Dark beige
  pale: "#F7F4E4", // Pale beige
  deep: "#C9B89C", // Deep beige
};

// xanh là cây
export const green = {
  base: "#21d01b", // Base bright green
  light: "#4CAF50", // Light green
  dark: "#006400", // Dark green
  pale: "#C6E5B1", // Pale green
  deep: "#004d00", // Deep green
};

// cam
export const orange = {
  base: "#FD821B", // Base vivid orange
  light: "#FFB84D", // Light orange
  medium: "#fca13a", // Medium tangerine
  vivid: "#EE4E29", // Vivid red-orange
  dark: "#E67E22", // Dark orange
  pale: "#FFD8B1", // Pale orange
  deep: "#D35400", // Deep orange
};

// tím
export const purple = {
  base: "#4F23C0", // Base purple
  medium: "#7b2eda", // Medium deep purple
  light: "#9b59b6", // Light purple
  dark: "#5e35b1", // Dark purple
  pale: "#d1c4e9", // Pale purple
  deep: "#4a148c", // Deepest purple
};

// cam hồng
export const peach = {
  base: "#F8A793", // Base peach
  light: "#FADAB3", // Light peach
  dark: "#F57C6C", // Dark peach
  pale: "#FCE8E1", // Pale peach
  deep: "#E57373", // Deep peach
};

// vàng
export const yellow = {
  base: "#D9D02F", // Base bright yellow
  light: "#F1F5C6", // Light yellow
  dark: "#F5C518", // Dark yellow
  pale: "#F7F7A0", // Pale yellow
  deep: "#F4B400", // Deep yellow
};

// đỏ sậm
export const magenta = {
  base: "#F01879", // Base magenta
  light: "#F46F9A", // Light magenta
  dark: "#C8105E", // Dark magenta
  pale: "#F4A0B7", // Pale magenta
  deep: "#A50032", // Deep magenta
};

// hồng
export const pink = {
  base: "#F018E8", // Base bright pink
  light: "#F56DCE", // Light pink
  dark: "#C810A0", // Dark pink
  pale: "#F8A4E6", // Pale pink
  deep: "#A50080", // Deep pink
};

// trắng
export const white = {
  light: "#FEFEFE",
  default: "#FFFFFF",
  extraLight: "#F8F9FD",
};
