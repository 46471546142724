import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridCol,
  Group,
  Menu,
  Select,
  SelectProps,
  Text,
  TextInput,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { modals } from "@mantine/modals";
import {
  IconCaretDown,
  IconCheck,
  IconCopy,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileLike,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconTimeDuration15,
  IconTrash,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import { repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { green } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDmEmployee } from "../../../model/TblDmEmployee";
import { tblPromotionGetList } from "../../../model/TblPromotion";
import DeleteView from "./DeleteView";
import ModalApprovePromotion from "./ModalApprovePromotion";
import InputSearch from "../../../common/InputSearch/InputSearch";
import CloneView from "./CloneView";
import { BASE_API_POS } from "../../../config";
import { IconActivity } from "@tabler/icons-react";
import { useDebouncedState } from "@mantine/hooks";

function getColorStatus(status: string, effDate: Date, expDate: Date): string {
  const now = new Date();
  const effectiveDate = effDate ? new Date(effDate) : null;
  const expirationDate = expDate ? new Date(expDate) : null;
  switch (status) {
    case "2": // Đã duyệt
      if (effectiveDate && expirationDate) {
        if (now >= effectiveDate && now <= expirationDate) {
          return "teal"; // Đang chạy
        } else if (now > expirationDate) {
          return "gray"; // Hết hiệu lực
        } else if (now < effectiveDate) {
          return "teal.4"; // Hết hiệu lực
        }
        return "teal";
      }
      return "blue"; // Đang xét duyệt
    case "3": // Dừng duyệt
      return "yellow"; // Đang xét duyệt (có thể chỉnh sửa, không cho xóa)
    case "4": // Từ chối
      return "red"; // Không áp dụng (có thể sửa, không cho xóa)
    case "5": // Hủy duyệt
      return "blue"; // Chưa áp dụng (có thể sửa, không cho xóa)
    default: // Chưa duyệt
      return "blue"; // Đang xét duyệt (có thể chỉnh sửa, có thể xóa)
  }
}

function getStatus(text: any) {
  switch (text) {
    case "2":
      return "Đã duyệt";
    case "3":
      return "Tạm dừng";
    case "4":
      return "Từ chối";
    case "5":
      return "Hủy duyệt";
    default:
      return "Chưa duyệt";
  }
}

function getStatus2(
  text: any,
  effDate?: string | Date,
  expDate?: string | Date
): string {
  const now = new Date();
  const effectiveDate = effDate ? new Date(effDate) : null;
  const expirationDate = expDate ? new Date(expDate) : null;

  switch (text) {
    case "2": // Đã duyệt
      if (effectiveDate && expirationDate) {
        if (now >= effectiveDate && now <= expirationDate) {
          return "Đang chạy";
        } else if (now > expirationDate) {
          return "Hết hiệu lực";
        } else if (now < effectiveDate) {
          return "Chưa đến thời gian chạy";
        }
      }
      return "Đang xét duyệt";
    case "3": // Dừng duyệt
      return "Đang xét duyệt";
    case "4": // Từ chối
      return "Không áp dụng ";
    case "5": // Hủy duyệt
      return "Chưa áp dụng";
    default: // Chưa duyệt
      return "Chưa duyệt";
  }
}

const sizeGrid = { base: 6, sm: 4, lg: 1.7 };

const PromotionPolicy = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<tblPromotionGetList[]>([]);
  // const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [search, setSearch] = useDebouncedState(
    {
      keySearch: "",
      effDate: "",
      expDate: "",
      fromApproveDate: "",
      toApproveDate: "",
      approveStatus: "",
    },
    300
  );
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [dataEmployee, setDataEmployee] = useState<TblDmEmployee[]>([]);

  const dataStatus = React.useMemo<any[]>(
    () => [
      {
        value: "1",
        label: "Chưa duyệt",
      },
      {
        value: "2",
        label: "Đã duyệt",
      },
      {
        value: "3",
        label: "Tạm dừng",
      },
      {
        value: "4",
        label: "Từ chối",
      },
      {
        value: "5",
        label: "Hủy duyệt",
      },
    ],
    []
  );

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Mã chính sách",
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => handleDetailPromotion(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "approveStatus1",
        header: "Trạng thái duyệt",
        size: 100,
        Cell: ({ renderedCellValue, row, cell }) => (
          <>
            <Badge
              color={getColorStatus(
                row.original.approveStatus,
                row.original.effDate,
                row.original.expDate
              )}
            >
              {getStatus(row.original.approveStatus)}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "approveStatus",
        header: "Trạng thái",
        size: 100,
        Cell: ({ renderedCellValue, row, cell }) => (
          <>
            <Badge
              color={getColorStatus(
                row.original.approveStatus,
                row.original.effDate,
                row.original.expDate
              )}
            >
              {getStatus2(
                row.original.approveStatus,
                row.original.effDate,
                row.original.expDate
              )}
            </Badge>
          </>
        ),
      },

      {
        accessorKey: "note",
        header: "Tên chương trình",
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo",
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <></>;
        },
      },
      {
        accessorKey: "createBy",
        header: "Người tạo",
        //  size: 100,
        enableSorting: false,
      },
      {
        accessorKey: "effDate",
        header: "Thời gian hiệu lực",
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <></>;
        },
      },
      {
        accessorKey: "expDate",
        header: "Thời gian hết hạn",
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <></>;
        },
      },

      {
        accessorKey: "priority",
        header: "Độ ưu tiên",
        // size: 100,
      },

      {
        accessorKey: "approveBy",
        header: "Người duyệt",
        //  size: 100,
        enableSorting: false,
        Cell: ({ renderedCellValue, cell }) =>
          getEmployeeName(renderedCellValue?.toString() || ""),
      },
      {
        accessorKey: "approveDate",
        header: "Ngày duyệt",
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <></>;
        },
      },
      {
        accessorKey: "approveNote",
        header: "Ghi chú duyệt",
        // size: 100,
      },

      // {
      //   accessorKey: "listCustGroup",
      //   header: "Danh sách khách hàng",
      // },

      // {
      //   accessorKey: "listOtherCondition",
      //   header: "Danh sách tình trạng khác",
      // },

      // {
      //   accessorKey: "checkNomalPromote",
      //   header: "Check chính sách thường",
      // },

      // {
      //   accessorKey: "checkRewardPoint",
      //   header: "Check Điểm thưởng",
      // },

      // {
      //   accessorKey: "checkUploadWebsite",
      //   header: "Check upload web",
      // },

      {
        accessorKey: "action",
        header: "Thao tác",
        enableColumnActions: false,
        enableSorting: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {processTaskActionMenu(row)}
          </Box>
        ),
      },
    ],
    [dataEmployee]
  );

  function processTaskActionMenu(row: MRT_Row<any>): any {
    return (
      <>
        {!(
          row.original.approveStatus === "2" ||
          row.original.approveStatus === "4"
        ) && (
          <Tooltip label="Duyệt chính sách">
            <ActionIcon
              variant="light"
              aria-label="Settings"
              color="teal"
              onClick={async () => {
                handleApprovePromotion([row.id]);
              }}
            >
              <IconFileLike size={20} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        )}

        {(row.original.approveStatus === "1" ||
          !row.original.approveStatus) && (
          <Tooltip label="Sửa chính sách">
            <ActionIcon variant="light" aria-label="Settings" color="yellow">
              <IconEdit
                size={14}
                stroke={1.5}
                onClick={() =>
                  navigate("/config-system/edit-promotion/" + row.id)
                }
              />
            </ActionIcon>
          </Tooltip>
        )}
        <Tooltip label="Xem chi tiết">
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color="cyan"
            onClick={async () => {
              handleDetailPromotion(row.id);
            }}
          >
            <IconEye size={20} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Sao chép">
          <ActionIcon
            variant="light"
            aria-label="Settings"
            color="blue"
            onClick={async () => {
              await cloneData(Number.parseInt(row.id));
            }}
          >
            <IconCopy size={20} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
        {row.original.approveStatus === "1" && (
          <Tooltip label="Xóa chính sách">
            <ActionIcon
              variant="light"
              aria-label="Settings"
              color="red"
              onClick={async () => {
                await deletesData([row.id]);
              }}
            >
              <IconTrash size={14} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        )}
      </>
    );
  }

  const getEmployeeName = (id: string) => {
    const employee = dataEmployee.find(
      (employee) => employee.id.toString() === id
    );

    return employee?.fullname;
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const handleExport = () => {
    let url = `/api/v1/tblPromotion/export?Skip=${
      pagination.pageIndex * pagination.pageSize
    }&Take=${pagination.pageSize}`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    if (search.effDate) {
      url += `&EffDate=${search.effDate}`;
    }
    if (search.expDate) {
      url += `&ExpDate=${search.expDate}`;
    }

    if (search.fromApproveDate) {
      url += `&FromApproveDate=${search.fromApproveDate}`;
    }
    if (search.toApproveDate) {
      url += `&ToApproveDate=${search.toApproveDate}`;
    }

    if (search.approveStatus) {
      url += `&ApproveStatus=${search.approveStatus}`;
    }

    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        url = url + "&sort=" + _q.id + "&isOrder=" + _q.desc;
      }
    }

    const _url = BASE_API_POS + url;

    const fullUrl = new URL(_url);
    window.open(
      fullUrl.toString(),
      "_blank"
      //"toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600"
    );
  };

  const deletesData = async (ids: string[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa chính sách khuyến mãi!</Title>
            </>
          ),
          size: "550px",
          children: <DeleteView idItems={ids} fetchData={fetchData} />,
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const cloneData = async (id: number) => {
    if (id < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Sao chép chính sách khuyến mãi!</Title>
            </>
          ),
          size: "550px",
          children: <CloneView id={id} fetchData={fetchData} />,
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      let url = `/api/v1/tblPromotion/get-list?Skip=${
        pagination.pageIndex * pagination.pageSize
      }&Take=${pagination.pageSize}`;

      if (search.keySearch) {
        url += `&KeySearch=${search.keySearch}`;
      }

      if (search.effDate) {
        url += `&EffDate=${search.effDate}`;
      }
      if (search.expDate) {
        url += `&ExpDate=${search.expDate}`;
      }

      if (search.fromApproveDate) {
        url += `&FromApproveDate=${search.fromApproveDate}`;
      }
      if (search.toApproveDate) {
        url += `&ToApproveDate=${search.toApproveDate}`;
      }

      if (search.approveStatus) {
        url += `&ApproveStatus=${search.approveStatus}`;
      }

      if (sorting && sorting.length > 0) {
        let _q = sorting[0];
        if (_q) {
          url = url + "&sort=" + _q.id + "&isOrder=" + _q.desc;
        }
      }

      const response = await repositoryPos.get<
        MessageResponse<tblPromotionGetList[]>
      >(url);

      if (response && response.success) {
        setData(response.data);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreatePP = () => {
    navigate("/config-system/create-promotion-policy");
    localStorage.removeItem("policyType");
  };

  const handleApprovePromotion = (ids: string[]) => {
    modals.openConfirmModal({
      size: "500px",
      title: (
        <>
          <Title order={5}>Duyệt chính sách khuyến mại</Title>
        </>
      ),
      children: <ModalApprovePromotion ids={ids} fetchData={fetchData} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleEditPromotion = (id: string) => {
    navigate("/config-system/edit-promotion/" + id);
    //  localStorage.removeItem("policyType");
  };

  const handleDetailPromotion = (id: string) => {
    navigate("/config-system/detail-promotion", { state: { id: id } });
    localStorage.removeItem("policyType");
  };

  const optionColorsStatus: Record<
    "Chưa duyệt" | "Đã duyệt" | "Tạm dừng" | "Từ chối" | "Hủy duyệt",
    string
  > = {
    "Chưa duyệt": "blue",
    "Đã duyệt": "teal",
    "Tạm dừng": "yellow",
    "Từ chối": "red",
    "Hủy duyệt": "gray",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsStatus[
            option.label as
              | "Chưa duyệt"
              | "Đã duyệt"
              | "Tạm dừng"
              | "Từ chối"
              | "Hủy duyệt"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  useEffect(() => {
    const fetchDataEmployee = async () => {
      try {
        let url = `/api/v1/tblDmEmployee/get-all`;

        const response = await repositoryPos.get<
          MessageResponse<TblDmEmployee[]>
        >(url);

        if (response && response.success) {
          setDataEmployee(response.data);
        }
      } catch (error) {
        return;
      }
    };

    fetchDataEmployee();

    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const valuesList = Object.keys(rowSelection);
  //   setSelectIds(valuesList);
  //   if (valuesList.length < 1) setSelectIds([]);
  //   else {
  //     const valuesList = Object.keys(rowSelection);
  //     setSelectIds(valuesList);
  //   }
  // }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [pagination, sorting]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    // renderTopToolbarCustomActions: ({ table }) => (

    // ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row?.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "code", "approveStatus1"],
        right: ["applicableObject", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    //onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500",
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      // showProgressBars: isRefetching,
      // showSkeletons: isLoading, //loading for the first time with no data
      sorting,
      // rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["25", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // mantineTableBodyRowProps: ({ row }) => ({
    //   onClick: row.getToggleSelectedHandler(),
    //   sx: { cursor: "pointer" },
    // }),
  });

  return (
    <>
      <Box
        style={{ border: "1px solid #dee2e6", padding: "5px", width: "100%" }}
        ref={headerRef}
        pb={10}
      >
        <Flex justify="space-between">
          <_breadcrumb></_breadcrumb>
          <Flex gap="md" justify={"flex-end"} align="center" direction="row">
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={handleCreatePP}
            >
              Thêm mới (F11)
            </Button>

            <Button
              leftSection={<IconActivity size={14} />}
              color="violet"
              variant="outline"
              onClick={async () => {
                const select = table
                  .getSelectedRowModel()
                  .rows.map((row) => row.original);
                if (select.length > 0)
                  await handleApprovePromotion(select.map((item) => item.id));
                else NotificationExtension.Fails("Chọn dữ liệu để thao tác !");
              }}
            >
              Duyệt
            </Button>

            <Button
              leftSection={<IconTrash size={14} />}
              color="red"
              variant="outline"
              onClick={async () => {
                const select = table
                  .getSelectedRowModel()
                  .rows.map((row) => row.original);
                if (select.length > 0)
                  await deletesData(select.map((item) => item.id));
                else NotificationExtension.Fails("Chọn dữ liệu để thao tác !");
              }}
            >
              Xóa (đã chọn)
            </Button>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Grid mt={15}>
          <GridCol span={sizeGrid}>
            <TextInput
              flex={1}
              placeholder="Từ khoá"
              onChange={(e) => {
                handleChangeSearchValue(
                  e.currentTarget.value ?? "",
                  "keySearch"
                );
              }}
            />
          </GridCol>
          <GridCol span={sizeGrid}>
            <Select
              flex={1}
              placeholder="Trạng thái duyệt"
              clearable
              data={dataStatus}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              renderOption={renderSelectOptionStatus}
              onChange={(e) => {
                handleChangeSearchValue(e ?? "", "approveStatus");
              }}
            />
          </GridCol>

          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              type="default"
              flex={1}
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              size="sm"
              placeholder="Hiệu lực từ ngày"
              locale="vi"
              //   value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                const startDateISO = e ? e.toLocaleDateString() : null;
                handleChangeSearchValue(startDateISO ?? "", "effDate");
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              type="default"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              size="sm"
              flex={1}
              placeholder="Hiệu lực đến ngày"
              locale="vi"
              //   value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                const startDateISO = e ? e.toLocaleDateString() : null;
                handleChangeSearchValue(startDateISO ?? "", "expDate");
              }}
              clearable
            />
          </Grid.Col>
          {/* <GridCol span={sizeGrid}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              placeholder="Thời gian hiệu lực từ ngày - đến ngày"
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "effDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "expDate"
                );
              }}
              clearable
            />
          </GridCol> */}
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              type="default"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              size="sm"
              placeholder="Duyệt từ ngày"
              locale="vi"
              //   value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                const startDateISO = e ? e.toLocaleDateString() : null;
                handleChangeSearchValue(startDateISO ?? "", "fromApproveDate");
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              type="default"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              size="sm"
              placeholder="Duyệt đến ngày"
              locale="vi"
              //   value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                const startDateISO = e ? e.toLocaleDateString() : null;
                handleChangeSearchValue(startDateISO ?? "", "toApproveDate");
              }}
              clearable
            />
          </Grid.Col>
          {/* <GridCol span={sizeGrid}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              placeholder="Duyệt từ ngày - đến ngày"
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "fromApproveDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "toApproveDate"
                );
              }}
              clearable
            />
          </GridCol> */}

          <GridCol span={sizeGrid}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </Box>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default PromotionPolicy;
