import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Grid,
  Group,
  LoadingOverlay,
  ScrollArea,
  Select,
  Table,
  TextInput,
  Tooltip,
  Text,
  Flex,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCheck,
  IconDeviceFloppy,
  IconEdit,
} from "@tabler/icons-react";
import {
  repositoryMdm,
  repositoryPos,
} from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import {
  TblExportWarrantyVendor,
  TblFaultyItemListDetailCommand,
} from "../../../../model/TblExportWarrantyVendor";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { useEffect, useState } from "react";
import { DatePickerInput, DateTimePicker } from "@mantine/dates";
import cx from "clsx";
import classes from "../../../../Styles/TableScrollArea.module.css";
import {
  getBranchSelect,
  getTblDmEmployeeSelect,
  getDeliveryDepartmentSelect,
  getTblDmDeliverPrioritySelect,
  getTblLocationSelect,
} from "../../../../service/getSelectApi";
import dayjs from "dayjs";
import { tblItem } from "../../../../model/TblItem";
import { sky_blue } from "../../../../const/variables";

const CreateDataView = ({ onClose }: CreateDataViewProps) => {
  const entity = {
    id: "0",
    deliveryDepId: null,
    empId: null,
    vendorId: null,
    branchId: null,
    faultyItemListId: null,
    code: null,
    note: null,
    deliveryTime: null,
    locationId: null,
    deliverPriorityId: null,
    tblFaultyItemListDetailCommands: null,
    createBy: null,
    createDate: null,
  };

  //data
  const [code, setCode] = useState("");
  const [vendorId, setVendorId] = useState<number | null>(null);
  const [vendorName, setVendorName] = useState<string | null>(null);
  const [locationId, setLocationId] = useState<number | null>(null);
  const [cityName, setCityName] = useState<string>("");
  const [districtName, setDistrictName] = useState<string>("");
  const [communeName, setCommuneName] = useState<string>("");
  const [faultyItemListId, setFaultyItemListId] = useState<string | null>("");
  const [faultyItemListCode, setFaultyItemListCode] = useState<string | null>(
    ""
  );
  const [dataFaultyItemListSelect, setDataFaultyItemListSelect] = useState<
    ComboboxItem[]
  >([]);
  const [faultyItemListDetailCommand, setFaultyItemListDetailCommand] =
    useState<TblFaultyItemListDetailCommand[]>([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataDeliveryDepartmentSelect, setDataDeliveryDepartmentSelect] =
    useState<ComboboxItem[]>([]);
  const [dataDeliverPrioritySelect, setDataDeliverPrioritySelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataLocationSelect, setDataLocationSelect] = useState<ComboboxItem[]>(
    []
  );
  const [listItem, setListItem] = useState<any[]>([]);
  const [listItemId, setListItemId] = useState<number[]>([]);

  //state
  const [scrolled, setScrolled] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isEditFILDC, setIsEditFILDC] = useState({
    warrantyStatus: false,
  });
  const [editAndSave, setEditAndSave] = useState(false);

  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<TblExportWarrantyVendor>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      deliveryDepId: Number(values.deliveryDepId),
      empId: Number(values.empId),
      vendorId: Number(values.vendorId),
      branchId: Number(values.branchId),
      locationId: Number(values.locationId),
      deliverPriorityId: Number(values.deliverPriorityId),
    }),

    validate: {
      deliveryDepId(value: number | null) {
        if (!value) {
          return "Vui lòng nhập bộ phận giao vận !";
        }
      },
      empId(value: number | null) {
        if (!value) {
          return "Vui lòng nhập nhân viên thực hiện !";
        }
      },
      branchId(value: number | null) {
        if (!value) {
          return "Vui lòng nhập trung tâm !";
        }
      },
      faultyItemListId(value: number | null) {
        if (!value) {
          return "Vui lòng nhập số phiếu lỗi !";
        }
      },
      deliveryTime(value: string | null) {
        if (!value) {
          return "Vui lòng chọn thời gian giao hàng !";
        }
      },
      locationId(value: number | null) {
        if (!value) {
          return "Vui lòng nhập địa điểm giao hàng !";
        }
      },
      deliverPriorityId(value: number | null) {
        if (!value) {
          return "Vui lòng nhập mức độ ưu tiên giao hàng !";
        }
      },
      note(value: string | null) {
        if (value) {
          return hasLength(
            { max: 200 },
            "Ghi chú không được dài quá 200 kí tự !"
          )(value as string);
        }
      },
    },
  });

  const getItemDetailsById = (itemId: number, value: string) => {
    const item = listItem.find((d) => d.itemId === itemId);
    if (item) {
      return `${item[value]}`;
    }
    return "";
  };

  const handleChangeValue = (key: string, value: any, index: number) => {
    setFaultyItemListDetailCommand((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      )
    );
  };

  const handleCreateTblExportWarrantyVendor = async (
    dataSubmit: TblExportWarrantyVendor
  ) => {
    open();
    const dataApi = await repositoryMdm.post<
      MessageResponse<TblExportWarrantyVendor>
    >("/api/v1/TblExportWarrantyVendor/create", dataSubmit);
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Thêm thành công !");
    }
    close();
  };

  const getCreate = async () => {
    const dataApi = await repositoryMdm.get<
      MessageResponse<TblExportWarrantyVendor>
    >("/api/v1/TblExportWarrantyVendor/create?prefix=PXBHNCC-");
    if (dataApi?.success) {
      const data = dataApi.data;
      if (data !== null) {
        setCode(data.code ?? "");
      }
    }
  };

  useEffect(() => {
    Promise.all([
      fetchDataEmployeeSelect(),
      fetchDataBranchSelect(),
      fetchDeliveryDepartmentSelect(),
      fetchDeliverPrioritytSelect(),
      fetchLocationSelect(),
    ]);
  }, []);

  const getFaultyItemList = async () => {
    if (faultyItemListCode && faultyItemListCode.trim().length > 5) {
      let url = `?Take=100&KeySearch=${faultyItemListCode}`;

      const response = await repositoryMdm.get(
        `/api/v1/TblFaultyItemList/get-list${url}`
      );
      if (response && response.data !== null) {
        const data = response.data;
        const mappedData = data.map((item: any) => ({
          value: item.id.toString(),
          label: item.code,
        }));
        setDataFaultyItemListSelect(mappedData);
      }
    }
  };

  const getFaultyItemListDetail = async () => {
    if (faultyItemListId && faultyItemListId?.trim().length > 0) {
      let url = `?id=${faultyItemListId}`;

      const response = await repositoryMdm.get(
        `/api/v1/TblFaultyItemList/detail${url}`
      );

      if (response && response.data !== null) {
        const data = response.data;
        setFaultyItemListDetailCommand(
          data.tblFaultyItemListDetailModels ?? []
        );
        setVendorId(data.vendorId ?? "");
        setVendorName(data.vendorName ?? "");
      }
    }
  };

  const getLocationDetail = async () => {
    if (locationId && locationId !== null) {
      let url = `?id=${locationId}`;

      const response = await repositoryMdm.get(
        `/api/v1/TblDmLocation/detail${url}`
      );
      if (response && response.data !== null) {
        const data = response.data;
        setCityName(data.cityName);
        setDistrictName(data.districtName);
        setCommuneName(data.communeName);
      }
    }
  };

  const getNameProduct = async () => {
    if (listItemId.length > 0) {
      let url = "";
      for (var i = 0; i < listItemId.length; i++) {
        url += `Ids=${listItemId[i]}&`;
      }
      const res = await repositoryPos.get<MessageResponse<tblItem[]>>(
        `/api/v1/TblItem/get-list-for-warranty-service-request?${url}`
      );

      if (res && res.success) {
        const data = res.data;
        setListItem(data);
      } else {
        setListItem([]);
      }
    }
  };

  const fetchDataEmployeeSelect = async () => {
    const getData = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataBranchSelect = async () => {
    const getData = await getBranchSelect();
    setDataBranchSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDeliveryDepartmentSelect = async () => {
    const getData = await getDeliveryDepartmentSelect();
    setDataDeliveryDepartmentSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDeliverPrioritytSelect = async () => {
    const getData = await getTblDmDeliverPrioritySelect();
    setDataDeliverPrioritySelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchLocationSelect = async () => {
    const getData = await getTblLocationSelect();
    setDataLocationSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    getCreate();
  }, []);

  useEffect(() => {
    getNameProduct();
  }, [listItemId]);

  useEffect(() => {
    if (locationId && locationId !== null) {
      getLocationDetail();
    } else {
      setCityName("");
      setDistrictName("");
      setCommuneName("");
    }
  }, [locationId]);

  useEffect(() => {
    if (faultyItemListCode && faultyItemListCode.trim().length > 5) {
      const delayDebounce = setTimeout(() => {
        getFaultyItemList();
      }, 500);

      return () => clearTimeout(delayDebounce);
    } else {
      setDataFaultyItemListSelect([]);
      setVendorId(null);
      setFaultyItemListDetailCommand([]);
    }
  }, [faultyItemListCode]);

  useEffect(() => {
    if (faultyItemListId && faultyItemListId.length > 0) {
      getFaultyItemListDetail();
    }
  }, [faultyItemListId]);

  useEffect(() => {
    const updateItemId = () => {
      const uniqueItemId = Array.from(
        new Set(
          faultyItemListDetailCommand
            .map((item) => item.itemId)
            .filter((itemId) => itemId !== null)
        )
      ).map(Number);
      setListItemId(uniqueItemId);
    };

    updateItemId();
  }, [faultyItemListDetailCommand]);

  useEffect(() => {
    form.setValues((prev) => ({ ...prev, code: code }));
    form.setValues((prev) => ({
      ...prev,
      tblFaultyItemListDetailCommands: faultyItemListDetailCommand,
    }));
    form.setValues((prev) => ({ ...prev, vendorId: vendorId }));
    form.setValues((prev) => ({
      ...prev,
      faultyItemListId: Number(faultyItemListId),
    }));
    form.setValues((prev) => ({ ...prev, locationId: locationId }));
  }, [
    code,
    faultyItemListDetailCommand,
    vendorId,
    faultyItemListId,
    locationId,
  ]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={"85vw"}
        maw={1300}
        onSubmit={form.onSubmit((e: TblExportWarrantyVendor) => {
          handleCreateTblExportWarrantyVendor(e);
        })}
        style={{ position: "relative" }}
        onMouseDown={() =>
          setIsEditFILDC({
            warrantyStatus: false,
          })
        }
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Fieldset legend="Thông tin phiếu xuất" mt={10}>
          <Grid>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Số phiếu"
                value={code}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <DatePickerInput
                label="Ngày lập"
                valueFormat="DD/MM/YYYY"
                value={new Date()}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Người lập"
                value={localStorage.getItem("userName") ?? ""}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Nhân viên thực hiện"
                placeholder="Nhập tên nhân viên thực hiện"
                data={dataEmployeeSelect}
                searchable
                clearable
                size="xs"
                withAsterisk
                {...form.getInputProps("empId")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Chi nhánh"
                placeholder="Nhập tên chi nhánh"
                data={dataBranchSelect}
                searchable
                clearable
                size="xs"
                withAsterisk
                {...form.getInputProps("branchId")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Số phiếu lỗi"
                placeholder="Nhập số phiếu lỗi"
                data={dataFaultyItemListSelect}
                searchable
                clearable
                searchValue={faultyItemListCode ?? ""}
                onSearchChange={setFaultyItemListCode}
                nothingFoundMessage={
                  faultyItemListCode && faultyItemListCode?.trim().length > 5
                    ? "Không tìm thấy dự liệu !"
                    : "Vui lòng nhập tối thiểu 6 kí tự !"
                }
                size="xs"
                withAsterisk
                {...form.getInputProps("faultyItemListId")}
                onChange={(event) => setFaultyItemListId(event)}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Nhà cung cấp"
                value={vendorName ?? ""}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Địa điểm giao"
                placeholder="Nhập địa điểm giao"
                data={dataLocationSelect}
                searchable
                clearable
                size="xs"
                withAsterisk
                {...form.getInputProps("locationId")}
                onChange={(event) => setLocationId(Number(event))}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Tỉnh/TP"
                value={cityName}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Quận/Huyện"
                value={districtName}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <TextInput
                label="Phường/Xã"
                value={communeName}
                variant="filled"
                size="xs"
                readOnly
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Bộ phân giao vận"
                placeholder="Nhập tên bộ phận giao vận"
                data={dataDeliveryDepartmentSelect}
                searchable
                clearable
                size="xs"
                withAsterisk
                {...form.getInputProps("deliveryDepId")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <Select
                label="Mức độ ưu tiên"
                placeholder="Nhập mức độ ưu tiên"
                data={dataDeliverPrioritySelect}
                searchable
                clearable
                size="xs"
                withAsterisk
                {...form.getInputProps("deliverPriorityId")}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
              <DateTimePicker
                label="Thời gian giao hàng"
                placeholder="Chọn thời gian giao hàng"
                valueFormat="DD/MM/YYYY HH:mm:ss"
                minDate={new Date()}
                size="xs"
                {...form.getInputProps("deliveryTime")}
                onChange={(value) =>
                  form.setValues((prev) => ({
                    ...prev,
                    deliveryTime: value
                      ? dayjs(value).add(7, "hour").toDate().toISOString()
                      : "",
                  }))
                }
                clearable
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 4, lg: 8 }}>
              <TextInput
                label="Ghi chú"
                placeholder="Ghi chú"
                size="xs"
                {...form.getInputProps("note")}
              />
            </Grid.Col>
          </Grid>
        </Fieldset>
        <Fieldset legend="Thông tin sản phẩm" mt={10}>
          {faultyItemListDetailCommand.length > 0 ? (
            <ScrollArea
              h={235}
              onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
              scrollbars="x"
            >
              <Table
                striped
                highlightOnHover
                withTableBorder
                withColumnBorders
                w={1920}
              >
                <Table.Thead
                  className={cx(classes.header, {
                    [classes.scrolled]: scrolled,
                  })}
                >
                  <Table.Tr>
                    <Table.Th>Thao tác</Table.Th>
                    <Table.Th>Số phiếu biên nhận</Table.Th>
                    <Table.Th>Phiếu bảo hành</Table.Th>
                    <Table.Th>Mã sản phẩm</Table.Th>
                    <Table.Th>Tên sản phẩm</Table.Th>
                    <Table.Th>Mã vạch</Table.Th>
                    <Table.Th>Serial gốc</Table.Th>
                    <Table.Th>Phụ kiện</Table.Th>
                    {/* <Table.Th>Ngày nhập TA</Table.Th>
                  <Table.Th>Ngày hết hạn NCC</Table.Th> */}
                    <Table.Th>Loại dịch vụ</Table.Th>
                    <Table.Th>Thời hạn bảo hành</Table.Th>
                    <Table.Th>Mô tả lỗi</Table.Th>
                    <Table.Th>Ghi chú nhận</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {faultyItemListDetailCommand?.map((item, index) => (
                    <Table.Tr key={index}>
                      <Table.Th>
                        {!editAndSave ? (
                          <Tooltip label="Chỉnh sửa">
                            <ActionIcon
                              onClick={() => {
                                setIsEditFILDC({
                                  warrantyStatus: true,
                                });
                                setEditIndex(Number(index));
                                setEditAndSave(true);
                              }}
                              size={"24px"}
                            >
                              <IconEdit size={"18px"} />
                            </ActionIcon>
                          </Tooltip>
                        ) : (
                          <Tooltip label="Lưu">
                            <ActionIcon
                              onClick={() => {
                                setIsEditFILDC({
                                  warrantyStatus: false,
                                });
                                setEditAndSave(false);
                              }}
                              size={"24px"}
                            >
                              <IconDeviceFloppy size={"18px"} />
                            </ActionIcon>
                          </Tooltip>
                        )}
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {item.warrantyReceiveTicketCode}
                        </Text>
                      </Table.Th>
                      <Table.Th
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                      >
                        {editIndex === Number(index) &&
                        isEditFILDC.warrantyStatus ? (
                          <Select
                            data={[
                              {
                                label: "Có phiếu bảo hành",
                                value: "Có phiếu bảo hành",
                              },
                              {
                                label: "Đã chuyển lên trước",
                                value: "Đã chuyển lên trước",
                              },
                              {
                                label: "Không có phiếu bảo hành",
                                value: "Không có phiếu bảo hành",
                              },
                            ]}
                            value={item.warrantyStatus ?? ""}
                            onChange={(value: any) =>
                              handleChangeValue(
                                "warrantyStatus",
                                value,
                                Number(index)
                              )
                            }
                            size="xs"
                          />
                        ) : (
                          <Text fw={"bold"} size="xs">
                            {item.warrantyStatus}
                          </Text>
                        )}
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {getItemDetailsById(item.itemId ?? 0, "itemCode")}
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {getItemDetailsById(item.itemId ?? 0, "itemName")}
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {item.serial}
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {item.orgSerial}
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {item.accessory}
                        </Text>
                      </Table.Th>
                      {/* <Table.Th></Table.Th>
                    <Table.Th></Table.Th> */}
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {item.serviceType}
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {getItemDetailsById(
                            item.itemId ?? 0,
                            "warrantyVendor"
                          )}
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {item.description}
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {item.note}
                        </Text>
                      </Table.Th>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </ScrollArea>
          ) : (
            <Flex h={235} justify={"center"} align={"center"}>
              <Text fw={"bold"} size="12.5px" c={"gray"}>
                Vui lòng chọn số phiếu lỗi để lấy thông tin sản phẩm
              </Text>
            </Flex>
          )}
        </Fieldset>
        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
          >
            Quay lại
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
        </Group>
      </Box>
    </>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  onClose: any;
};
