import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  IconSearch,
  IconTimeDuration15,
  IconUpload,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import BreadcrumbLayout1 from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
  formatToReverseDate,
} from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
type dataSubmit = {
  keySearch: string;
  typeMessage: string;
  startDate: string;
  endDate: string;
  status: string;
};
const SyncCheckingExist = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [visible, { toggle, open, close }] = useDisclosure(false);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
      skip: 0,
      take: 100,
      typeMessage: "",
      startDate: "",
      endDate: "",
      status: "",
    },
  });
  const dataSelectTable = [
    {
      value: "TblSalesOrder",
      label: "Hóa đơn bán lẻ",
    },
    {
      value: "TblSalesReturn",
      label: "Đơn trả lại",
    },
    // {
    //   value: "TblInternalInvoice",
    //   label: "Hóa đơn nội bộ",
    // },
    {
      value: "TblWarehouseTransfer",
      label: "Phiếu điều chuyển kho",
    },
    {
      value: "TblCashReceipt",
      label: "Phiếu thu",
    },
    {
      value: "TblCashDisbursement",
      label: "Phiếu chi",
    },
    {
      value: "TblCreditNote",
      label: "Phiếu báo có",
    },
    {
      value: "TblDebitNote",
      label: "Phiếu báo nợ",
    },
    {
      value: "TblReceiptNote",
      label: "Phiếu nhập",
    },
    {
      value: "TblDeliveryNote",
      label: "Phiếu xuất",
    },
  ];

  //Call api danh sách thiếu sót đơn đồng bộ
  const fetchData = async (data: dataSubmit) => {
    const processedData = {
      ...data,
      status:
        data.status === "true"
          ? true
          : data.status === "false"
          ? false
          : data.status,
    };
    if (processedData.startDate === "" || processedData.endDate === "") {
      NotificationExtension.Fails(
        "Vui lòng chọn ngày bắt đầu và ngày kết thúc"
      );
      return;
    }
    open();
    try {
      const response = await repositoryMdm.post(
        "/api/v1/SyncData/get-sync-checking-exist",
        processedData
      );
      if (response && response.success) {
        setData(response.data);
        setRowCount(response.totalCount || 0);
      } else {
      }
    } catch (error) {
      setData([]);
      setRowCount(0);
      console.log("error", error);
    } finally {
      close();
    }
  };
  //Đồng bộ tất cả
  const handleDongBoAll = async () => {
    const Codes = data.map((item: any) => item.data_json);
    if (Codes) {
      const valueSubmit = {
        typeMessage: form.getValues().typeMessage,
        codes: Codes,
        startDate: formatToReverseDate(form.getValues().startDate),
        endDate: formatToReverseDate(form.getValues().endDate),
        type: 0,
        skip: 0,
        take: 100,
      };
      if (valueSubmit) {
        try {
          const response = await repositoryMdm.post(
            "/api/v1/SyncData/repeat-sync-checking-exist",
            valueSubmit
          );
          if (response && response.success) {
            NotificationExtension.Success("Đồng bộ thành công");
            setTimeout(() => {
              fetchData(form.getValues());
            }, 500);
          } else {
            NotificationExtension.Fails("Đồng bộ không thành công");
          }
        } catch (error) {
          NotificationExtension.Fails("Đồng bộ không thành công");
        }
      }
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  // Xử lý đồng bộ
  const handleDongBo = async (value: any) => {
    setIsLoading(true);
    setIsRefetching(true);
    const valueSubmit = {
      typeMessage: form.getValues().typeMessage,
      codes: value,
      startDate: formatToReverseDate(form.getValues().startDate),
      endDate: formatToReverseDate(form.getValues().endDate),
      type: 0,
      skip: 0,
      take: 100,
    };

    if (valueSubmit) {
      try {
        const response = await repositoryMdm.post(
          "/api/v1/SyncData/repeat-sync-checking-exist",
          valueSubmit
        );
        if (response && response.success) {
          NotificationExtension.Success("Đồng bộ thành công");
          setTimeout(() => {
            fetchData(form.getValues());
          }, 500);
        } else {
          NotificationExtension.Fails("Đồng bộ không thành công");
        }
      } catch (error) {
        NotificationExtension.Fails("Đồng bộ không thành công");
      }
    }
    setIsLoading(false);
    setIsRefetching(false);
  };
  // console.log("data", data);
  // console.log("form.getValues()", form.getValues());
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "type_message",
        header: "Tên bảng",
        size: 50,
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "data_json",
        header: "Dữ liệu",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "ngay_ct",
        header: "Ngày chứng từ",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },
      {
        accessorKey: "status",
        header: "Trạng thái",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <>
            <Badge
              color={renderedCellValue === false ? "red" : "green"}
              radius="sm"
            >
              {renderedCellValue === false ? "Không thành công" : "Thành công"}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "error",
        header: "Lỗi",
        Cell: ({ renderedCellValue }) => (
          <Textarea
            readOnly
            autosize
            style={{ width: "300px" }}
            value={
              `${renderedCellValue}` == "null" ? "" : `${renderedCellValue}`
            }
            minRows={2}
            maxRows={2}
          />
        ),
      },
      {
        accessorKey: "count_repeat",
        header: "Lần chạy lại",
        size: 10,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "create_date",
        header: "Ngày tạo",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{formatDateTransfer(renderedCellValue)}</>
        ),
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 20,
        Cell: ({ row }) => (
          <Box>
            <Tooltip label="Đồng bộ">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="violet"
                onClick={() => handleDongBo([row.original.data_json])}
              >
                <IconUpload size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [data]
  );
  const table = useMantineReactTable({
    columns,
    data: data || [],
    enableTopToolbar: false,
    enableColumnFilters: true,
    enableSorting: true,
    enableColumnActions: true,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        // left: ["type_message"],
        right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (240 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <div
        style={{
          border: "1px solid #dee2e6",
          padding: "0px 10px",
          overflowX: "hidden",
        }}
      >
        <BreadcrumbLayout1></BreadcrumbLayout1>
      </div>
      <Flex
        direction={"row"}
        mt={10}
        justify={"space-between"}
        align={"center"}
      >
        <form
          onSubmit={form.onSubmit(async (e) => {
            await fetchData(e);
          })}
        >
          {/* <LoadingOverlay
            visible={visible}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          /> */}
          <Flex direction={"row"} align={"center"} gap={"md"} wrap="wrap">
            <Select
              size="sm"
              placeholder="Chọn tên bảng"
              searchable
              clearable
              data={dataSelectTable}
              key={form.key("typeMessage")}
              {...form.getInputProps("typeMessage")}
            />
            <Select
              size="sm"
              placeholder="Chọn trạng thái"
              searchable
              clearable
              data={[
                {
                  value: "true",
                  label: "Thành công",
                },
                {
                  value: "false",
                  label: "Không thành công",
                },
              ]}
              value={form.getValues().status}
              key={form.key("status")}
              onChange={(e) => {
                form.setFieldValue("status", e || "");
              }}
            />
            <DatePickerInput
              size="sm"
              w={150}
              placeholder="Ngày bắt đầu"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              onChange={(e) => {
                form.setFieldValue(
                  "startDate",
                  formatDateNotTimeZone(e) || "" // Định dạng StartTime
                );
              }}
              clearable
            />
            <DatePickerInput
              size="sm"
              w={150}
              placeholder="Ngày kết thúc"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              onChange={(e) => {
                form.setFieldValue(
                  "endDate",
                  formatDateNotTimeZone(e) || "" // Định dạng StartTime
                );
              }}
              clearable
            />

            <Button
              type="submit"
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
            >
              Tìm kiếm
            </Button>
          </Flex>
        </form>
        <div>
          <Box>
            <Button
              color="blue"
              variant="outline"
              leftSection={<IconUpload size={14} />}
              onClick={() => handleDongBoAll()}
            >
              Đồng bộ tất cả
            </Button>
          </Box>
        </div>
      </Flex>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default SyncCheckingExist;
