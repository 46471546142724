import {
  ComboboxItem,
  Flex,
  Group,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import React from "react";
import { useLocation } from "react-router-dom";
import { anyAsciiCode } from "../../../../_base/helper/AnyAscii";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { tblInventoryTransaction } from "../../../../model/TblOutWard";
import RenderTable from "./RenderTable";

const RequestProductionOutWard = ({
  dataSearchSourceCodeOption,
  dataPosition,
  dataOutWard,
  sourceCode,
  setSourceCode,
  handleKeyDownSourceCode,
  dataOrderDetail,
  handleChangeValueInput,
  isSave,
  setSearchSourceCode,
  isEdit,
  isLocked,
  haveStatus,
  haveSourceCode,
}: RequestProductionOutWardProps) => {
  const location = useLocation();
  const dataUrlLocation = location.pathname;

  const headers = [
    { label: "", style: { width: "25%" } },
    { label: "Bên xuất", style: { width: "25%" } },
    { label: "", style: { width: "25%" } },
    { label: "Bên nhận", style: { width: "25%" } },
  ];

  const getDeliveryText = (delivery: string) => {
    switch (delivery) {
      case "KHONG":
        return "Nhận tại cửa hàng";
      case "NOI_BO":
      case "HA_COM":
        return "Nội bộ";
      default:
        return delivery;
    }
  };

  const isValidSerial = (input: string): boolean => {
    if (input) {
      const pattern = /^[A-Z0-9]+$/;
      return pattern.test(input);
    }
    return true;
  };

  const rows = [
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian tạo
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.createDate}>
            <Text
              size="sm"
              w={"150px"}
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {formatDateTransfer(dataOrderDetail?.createDate ?? "")}
            </Text>
          </Tooltip>
        ),
      },
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã khách hàng
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.cusCode}>
            <Text
              size="sm"
              w={"150px"}
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail?.cusCode ?? ""}
            </Text>
          </Tooltip>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian hoàn thành
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOutWard?.finishDate}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {formatDateTransfer(dataOutWard?.finishDate)}
            </Text>
          </Tooltip>
        ),
      },
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Tên khách hàng
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.cusName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail?.cusName ?? ""}
            </Text>
          </Tooltip>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu xuất kho
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOutWard?.transactionCode}>
            <Text
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOutWard?.transactionCode}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu yêu cầu xuất kho
          </Text>
        ),
      },
      {
        content:
          !isEdit && !haveSourceCode ? (
            <Select
              placeholder="Mã phiếu yêu cầu xuất kho"
              data={dataSearchSourceCodeOption}
              size="sm"
              disabled={
                !dataOutWard.sourceType ||
                dataOutWard.status === "LOCK" ||
                sourceCode ||
                isSave
              }
              searchable
              clearable
              onSearchChange={(e) => {
                if (setSearchSourceCode) {
                  setSearchSourceCode(e);
                }
              }}
              onKeyDown={handleKeyDownSourceCode}
              value={sourceCode || dataOutWard.sourceCode}
              onChange={(value) => {
                if (handleChangeValueInput) {
                  handleChangeValueInput(value || "", "sourceCode");
                }
                if (setSourceCode) {
                  setSourceCode(value);
                }
              }}
              nothingFoundMessage={"Không tìm thấy dữ liệu !"}
            />
          ) : (
            <Tooltip label={dataOutWard?.sourceCode}>
              <Text
                w={"150px"}
                size="sm"
                style={{ whiteSpace: "normal" }}
                lineClamp={2}
                truncate
              >
                {dataOutWard.sourceCode}
              </Text>
            </Tooltip>
          ),
      },
      { content: null },
      { content: null },
    ],
    haveStatus
      ? [
          {
            content: (
              <Text
                w={"100px"}
                fw={700}
                size="sm"
                style={{ whiteSpace: "normal" }}
                lineClamp={2}
                truncate
              >
                Tình trạng
              </Text>
            ),
          },
          { content: null },
          { content: null },
          { content: null },
        ]
      : [],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chi nhánh
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.fromBranchName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.fromBranchName}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      {
        content: (
          <Tooltip label={dataOrderDetail?.toBranchName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.toBranchName}
            </Text>
          </Tooltip>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Địa điểm làm việc
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.fromBranchAddress}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.fromBranchAddress}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      {
        content: (
          <Tooltip label={dataOrderDetail?.toBranchAddress}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.toBranchAddress}
            </Text>
          </Tooltip>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Kho
          </Text>
        ),
      },
      {
        content: (
          <Tooltip
            label={
              dataOrderDetail.fromInvCode
                ? `${dataOrderDetail.fromInvCode} - ${
                    dataOrderDetail.fromInvName || ""
                  }`
                : dataOrderDetail.fromInvName || ""
            }
          >
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.fromInvCode
                ? `${dataOrderDetail.fromInvCode} - ${
                    dataOrderDetail.fromInvName || ""
                  }`
                : dataOrderDetail.fromInvName || ""}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      {
        content: (
          <Tooltip label={dataOrderDetail?.toInvName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail.toInvName &&
                `${dataOrderDetail.toInvCode} - ${dataOrderDetail.toInvName}`}
            </Text>
          </Tooltip>
        ),
      },
    ],
    // [
    //   {
    //     content: (
    //       <Text fw={700} size={"sm"}>
    //         Vị trí
    //       </Text>
    //     ),
    //   },
    //   {
    //     content: !isEdit ? (
    //       <Tooltip
    //         label={
    //           dataOutWard?.fromPositionId
    //             ? dataPosition?.find(
    //                 (p) => p.value === dataOutWard?.fromPositionId?.toString()
    //               )?.label
    //             : "Vui lòng chọn vị trí"
    //         }
    //       >
    //         <Select
    //           w={"160px"}
    //           placeholder="Vị trí"
    //           data={dataPosition}
    //           size="sm"
    //           disabled={
    //             !dataOutWard.sourceType ||
    //             dataOutWard.status === "LOCK" ||
    //             isSave
    //           }
    //           searchable
    //           clearable
    //           value={dataOutWard.fromPositionId?.toString()}
    //           onChange={(value) => {
    //             handleChangeValueInput(value, "fromPositionId");
    //           }}
    //           nothingFoundMessage={"Không tìm thấy dữ liệu !"}
    //         />
    //       </Tooltip>
    //     ) : (
    //       <Text
    //         w={"150px"}
    //         size="sm"
    //         style={{ whiteSpace: "normal" }}
    //         lineClamp={2}
    //         truncate
    //       >
    //         {dataOutWard.fromPositionName}{" "}
    //       </Text>
    //     ),
    //   },
    //   {
    //     content: null,
    //   },
    // ],

    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Họ & Tên
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.createName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail?.createName || ""}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chức danh
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.createRoleName}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOrderDetail?.createRoleName || ""}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Giao hàng
          </Text>
        ),
      },
      {
        content: (
          <Tooltip label={dataOrderDetail?.delivery}>
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {getDeliveryText(dataOrderDetail?.delivery || "")}
            </Text>
          </Tooltip>
        ),
      },
      { content: null },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã bộ
          </Text>
        ),
      },
      {
        content: (
          <Group>
            <Flex gap={15}>
              <Text>{dataOrderDetail?.itemCode ?? ""}</Text>
            </Flex>
            {dataUrlLocation ===
              "/warehouse-config/detail-warehouse-out-ward" && (
              <>
                <Flex gap={15}>
                  <Text>SL bộ đã lắp:</Text>
                  <Text fw={600} c={"red"}>
                    {dataOrderDetail?.tranferQuantity ?? ""}
                  </Text>
                </Flex>
                <Flex gap={15}>
                  <Text>SL còn lại:</Text>
                  <Text fw={600} c={"red"}>
                    {dataOrderDetail?.quantity -
                      dataOrderDetail?.tranferQuantity}
                  </Text>
                </Flex>
                <Flex gap={15}>
                  <Text>Tổng số bộ:</Text>
                  <Text fw={600} c={"red"}>
                    {dataOrderDetail?.quantity}
                  </Text>
                </Flex>
              </>
            )}
          </Group>
        ),
        colSpan: 3,
      },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã serial mới
          </Text>
        ),
      },
      {
        content: (
          <TextInput
            error={
              !!dataOutWard?.parentSerial || dataOutWard?.serial?.toString()
                ? !isValidSerial(dataOutWard?.parentSerial ?? "")
                  ? "Nhập mã serial"
                  : null
                : "Vui lòng nhập mã serial"
            }
            value={
              dataOutWard?.parentSerial ?? dataOutWard?.serial?.toString() ?? ""
            }
            onChange={(e) => {
              const newValue = anyAsciiCode(e.target.value.toLocaleUpperCase());
              handleChangeValueInput(newValue, "parentSerial");
            }}
            disabled={isLocked}
            placeholder={isLocked ? "" : "Vui lòng nhập serial"}
          ></TextInput>
        ),
        colSpan: 3,
      },
    ],
    [
      {
        content: (
          <Text
            w={"100px"}
            fw={700}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Ghi chú
          </Text>
        ),
      },
      {
        content: (
          <TextInput
            error={
              dataOutWard?.description && dataOutWard.description.length > 300
                ? "Ghi chú không được vượt quá 300 kí tự!"
                : null
            }
            value={dataOutWard?.description ?? ""}
            onChange={(e) => {
              handleChangeValueInput(e.target.value, "description");
            }}
            disabled={isLocked}
            placeholder={isLocked ? "" : "Vui lòng nhập"}
          />
        ),
        colSpan: 3,
      },
    ],
  ];

  return (
    <RenderTable
      headers={headers}
      rows={rows.filter((item) => item.length !== 0)}
    />
  );
};

type RequestProductionOutWardProps = {
  dataSearchSourceCodeOption?: any[];
  dataPosition?: ComboboxItem[];
  dataOutWard: tblInventoryTransaction;
  dataOrderDetail: any;
  handleChangeValueInput: (value: string | boolean | null, key: string) => void;
  setSourceCode?: (value: any) => void;
  handleKeyDownSourceCode?: (e: any) => void;
  sourceCode?: any;
  isSave?: boolean;
  setSearchSourceCode?: (newValue: React.SetStateAction<string>) => void;
  isEdit?: boolean;
  isLocked?: boolean;
  haveStatus?: boolean;
  haveSourceCode?: boolean;
};

export default RequestProductionOutWard;
