import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconFall,
  IconInfoCircle,
  IconNote,
  IconX,
} from "@tabler/icons-react";
import { Bounce, toast, ToastOptions } from "react-toastify";
import Push from "push.js";
const _autoClose = 30000;
const _autoPushBrowser = 5000;
function Success(noti: string | undefined) {
  if (noti)
    //toast.success(noti, setting);
    notifications.show({
      title: "Thông báo",
      message: noti,
      withCloseButton: true,
      autoClose: _autoClose,
      // onClose: () => console.log('unmounted'),
      // onOpen: () => console.log('mounted'),
      color: "green",
      icon: <IconCheck />,
    });
}
function Fails(noti: React.ReactNode | string) {
  if (noti)
    notifications.show({
      title: "Thông báo",
      message: noti,
      withCloseButton: true,
      autoClose: _autoClose,
      // onClose: () => console.log('unmounted'),
      // onOpen: () => console.log('mounted'),
      color: "red",
      icon: <IconX />,
    });
  // toast.error(noti, setting);
}

function Info(noti: string) {
  if (noti)
    notifications.show({
      title: "Thông báo",
      message: noti,
      withCloseButton: true,
      autoClose: _autoClose,
      // onClose: () => console.log('unmounted'),
      // onOpen: () => console.log('mounted'),
      color: "",
      icon: <IconInfoCircle />,
    });
  // toast.info(noti, setting);
}

function Warn(noti: string) {
  if (noti)
    notifications.show({
      title: "Thông báo",
      message: noti,
      withCloseButton: true,
      autoClose: _autoClose,
      // onClose: () => console.log('unmounted'),
      // onOpen: () => console.log('mounted'),
      color: "orange",
      icon: <IconNote />,
    });
  // toast.info(noti, setting);
}

function PushBrowser(
  body?: string,
  title?: string,
  handle?: Function,
  icon?: string
) {
  const status = Push.Permission.get();
  const check = Push.Permission.has();
  if (check && status !== Push.Permission.DENIED) {
    Push.Permission.request();
    Push.create(title ? title : "Thông báo !", {
      body: body,
      icon: icon
        ? icon
        : "https://hanoicomputercdn.com/media/lib/30-05-2024/favicon-hacom-2024.png",
      timeout: _autoPushBrowser, // Timeout before notification closes automatically.
      vibrate: true, // An array of vibration pulses for mobile devices.
      onClick: handle,
    });
  } else Info("Xin vui lòng bật thông báo trên trình duyệt !");
}

export const NotificationExtension = {
  Success,
  Fails,
  Info,
  Warn,
  PushBrowser,
};
