import {
  Badge,
  Box,
  Button,
  Group,
  Input,
  MultiSelect,
  MultiSelectProps,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  IconBarcode,
  IconFileBarcode,
  IconPhone,
  IconSearch,
  IconUser,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { VSerialHistorySearchQuery } from "../../../model/BaseSearchModel";
import { MessageResponse } from "../../../model/MessageResponse";
import { SelectListItemModel } from "../../../model/SelectListItem";

interface DatacustomerCodeModel {
  branchId: number | null;
  loaiCt: string | null;
  soCt: string | null;
  ngayCt: string | null;
  serial: string | null;
  serialThayThe: string | null;
  custId: number;
  itemId: string | null;
  maVt: string | null;
  tenVt: string | null;
  maKh: string;
  tenKh: string | null;
  soDt: string | null;
  dienGiai: string | null;
  thoiHanBhNcc: string | null;
  soCtGoc: string | null;
  text: string;
  value: string;
}

const BarcodeHistory = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [height, setHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [data, setData] = useState<Department[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [dataCustomerCode, setDataCustomerCode] = useState<
    SelectListItemModel[]
  >([]);
  const [dataVTCode, setDataVTCode] = useState<SelectListItemModel[]>([]);
  // const [dataItemCode, setDataItemCode] = useState<any>([]);
  // const [change, setchange] = useState<{ maCn: boolean; maKho: boolean }>({
  //   maCn: true,
  //   maKho: true,
  // });
  const form = useForm<VSerialHistorySearchQuery>({
    mode: "controlled",
  });
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "soCtGoc",
        header: "Chứng từ gốc",
        size: 230,
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "soCt",
        header: "Số chứng từ",
        size: 230,
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : "#21d01b"}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "loaiCt",
        header: "Loại chứng từ",
      },
      {
        accessorKey: "ngayCt",
        header: "Ngày chứng từ",
      },
      {
        accessorKey: "serial",
        header: "Mã vạch",
      },
      {
        accessorKey: "serialThayThe",
        header: "Mã vạch thay thế",
      },
      {
        accessorKey: "maVt",
        header: "Mã vật tư",
      },
      {
        accessorKey: "tenVt",
        header: "Tên vật tư",
      },
      {
        accessorKey: "maKh",
        header: "Mã khách hàng",
      },
      {
        accessorKey: "tenKh",
        header: "Tên khách hàng",
      },
      {
        accessorKey: "soDt",
        header: "Số điện thoại",
      },
      {
        accessorKey: "dienGiai",
        header: "Diễn giải",
      },

      {
        accessorKey: "thoiHanBhNcc",
        header: "Hạn bảo hành NCC",
      },
    ],
    []
  );
  const search = form.getValues();
  const fetchData = async () => {
    console.log(form.getValues());

    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch?.trim()?.toLowerCase()}`;
    }
    if (search.serial) {
      url += `&Serial=${search.serial?.trim()}`;
    }
    if (search.maKh) {
      url += `&MaKh=${search.maKh}`;
    }
    if (search.maVt) {
      url += `&MaVt=${search.maVt}`;
    }
    if (search.soDienThoai) {
      url += `&SoDt=${search.soDienThoai.trim()}`;
    }
    try {
      const response = await repositoryMdm.get(
        `api/v1/VSerialHistory/get-list${url}`
      );

      if (response && response.success) {
        let result = response.data.lists;
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };
  const DatacustomerCode = async () => {
    try {
      const response = await repositoryMdm.get<
        MessageResponse<DatacustomerCodeModel[]>
      >(`api/v1/VSerialHistory/get-select-customer-code`);
      if (response && response.success) {
        const transformedData: Array<SelectListItemModel> = [];
        const seenValues = new Set<string>();
        response.data
          ?.filter((item) => item.value !== null && item.text !== null)
          ?.forEach((customer) => {
            if (!seenValues.has(customer.value)) {
              seenValues.add(customer.value);
              transformedData.push({
                value: customer.value,
                label: customer.text,
              });
            }
          });
        setDataCustomerCode(transformedData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const DataVatTu = async () => {
    try {
      let url = "";
      if (search.maKh) {
        url = `?makh=${search.maKh}`;
      }
      const response = await repositoryMdm.get(
        `api/v1/VSerialHistory/get-select-material-code` + url
      );
      if (response && response.success) {
        const transformedData = response.data.map((customer: any) => ({
          value: customer.maVt,
          label: customer.text,
        }));
        const uniqueData = Array.from(
          new Set(transformedData.map((item: any) => item.value))
        ).map((value) =>
          transformedData.find((item: any) => item.value === value)
        );
        setDataVTCode(uniqueData);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  const table = useMantineReactTable({
    columns,
    data,
    enableColumnFilters: false,
    enableSorting: true,
    enableColumnActions: true,
    // manualPagination: true,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    onPaginationChange: setPagination,
    positionToolbarAlertBanner: "bottom",
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["soCt", "soCtGoc"],
        right: [""],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập từ khóa "
            type="text"
            leftSection={<IconSearch size={"20"} color="#15aabf" />}
            key={form.key("keySearch")}
            {...form.getInputProps("keySearch")}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await fetchData();
              }
            }}
          />
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Số điện thoại"
            type="text"
            leftSection={<IconPhone size={"20"} color="#15aabf" />}
            key={form.key("soDienThoai")}
            {...form.getInputProps("soDienThoai")}
          />
          <Input
            style={{ flex: 1, maxWidth: "180px", minWidth: "180px" }}
            placeholder="Nhập serial"
            type="text"
            leftSection={<IconFileBarcode size={"20"} color="#15aabf" />}
            key={form.key("serial")}
            {...form.getInputProps("serial")}
          />
          <MultiSelect
            size="sm"
            placeholder="Mã khách hàng"
            searchable
            hidePickedOptions
            clearable
            data={dataCustomerCode.map((x) => x.value)}
            value={(form.getValues().maKh as []) ?? []}
            leftSection={<IconUser size={20} color="#15aabf" />}
            onClick={() => {
              if (dataCustomerCode.length === 0) {
                DatacustomerCode();
              }
            }}
            renderOption={renderMultiSelectOption}
            key={form.key("maKh")}
            {...form.getInputProps("maKh")}
          />
          <MultiSelect
            size="sm"
            placeholder="Mã vật tư"
            hidePickedOptions
            searchable
            clearable
            data={dataVTCode.map((x) => x.value)}
            value={(form.getValues().maVt as []) ?? []}
            leftSection={<IconBarcode size={20} color="#15aabf" />}
            onSearchChange={(value) => {
              if (value.length >= 3) {
                DataVatTu();
              }
            }}
            renderOption={renderMultiSelectOptionVT}
            key={form.key("maVt")}
            {...form.getInputProps("maVt")}
          />

          <Button
            color="blue"
            variant="outline"
            onClick={async () => {
              await fetchData();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.departmentId,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (210 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    fetchData();
  }, [pagination]);

  const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>
        {dataCustomerCode.find((x) => x.value === option.value)?.label}
      </Text>
    </Group>
  );
  const renderMultiSelectOptionVT: MultiSelectProps["renderOption"] = ({
    option,
  }) => (
    <Group gap="sm">
      <Text>{dataVTCode.find((x) => x.value === option.value)?.label}</Text>
    </Group>
  );

  //#region new

  //#endregion
  return (
    <>
      <div className="mt-5">
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default BarcodeHistory;
type Department = {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  deparmentCode: string;
  creationDate: string | null;
  createdBy: string | null;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
};
