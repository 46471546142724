import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  InputLabel,
  LoadingOverlay,
  NumberInput,
  Pill,
  PillsInput,
  Stack,
  TagsInput,
  Text,
  Title,
} from "@mantine/core";
import { IconEdit, IconSettings, IconSparkles } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryMaster } from "../../../../_base/_const/_constVar";
import { TblDMConfigSystem } from "../../../../model/TblDMConfigSystem";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { MessageResponse } from "../../../../model/MessageResponse";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import "./tab.component.css";
export const ConfigSystem = () => {
  const [ipWhiteList, setIpWhiteList] = useState<string[]>([]);
  const entity: TblDMConfigSystem = {
    id: 0,
    createby: "",
    createdate: new Date(),
    loginapprovefrist: false,
    numberdevice: 0,
    timesession: 60,
    modifyby: null,
    modifydate: null,
    listipallow: [],
  };
  const form = useForm<TblDMConfigSystem>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      timesession: (value) => {
        if (value < 1) {
          return "Thời gian đăng nhập phải lớn hơn một phút!";
        }
        return null;
      },
      numberdevice: (value) => {
        if (value < 1) {
          return "Số thiết bị phải lớn hơn hoặc bằng một!";
        }
        return null;
      },
    },
  });
  const [visible, { toggle, close, open }] = useDisclosure(false);
  useEffect(() => {
    fetchConfig();
  }, []);
  const fetchConfig = async () => {
    try {
      const response = await repositoryMaster.get<
        MessageResponse<TblDMConfigSystem>
      >("/api/v1/Auth/get-config-system");
      if (
        response &&
        response.httpStatusCode === 200 &&
        response.success &&
        response.data
      ) {
        const dataApi = response.data;
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        setIpWhiteList(dataApi.listipallow);
      }
    } catch (error) {
      console.error(error);
    } finally {
      close();
    }
  };
  const handleEdit = async (dataSubmit: TblDMConfigSystem) => {
    try {
      open();
      dataSubmit.listipallow = ipWhiteList;
      const dataApi = await repositoryMaster.post<
        MessageResponse<TblDMConfigSystem>
      >("/api/v1/Auth/config-system", dataSubmit);
      if (dataApi?.success) {
        NotificationExtension.Success("Cập nhật thành công !");
        form.setValues(dataSubmit);
        form.resetDirty(dataSubmit);
      }
    } catch (error) {}
    close();
  };

  return (
    <>
      <Box
        component="form"
        w={"100%"}
        onSubmit={form.onSubmit((e: TblDMConfigSystem) => {
          handleEdit(e);
        })}
      >
        <Flex
          justify={"end"}
          w={"100%"}
          style={{
            borderBottom: "2px solid #E6E7EA",
          }}
        >
          <LoadingOverlay
            visible={visible}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <Button
            variant="filled"
            m={8}
            type="submit"
            leftSection={<IconEdit />}
          >
            Cập nhật
          </Button>
        </Flex>
        <Stack px={8} gap={16}>
          <Grid
            py={16}
            style={{
              borderBottom: "2px solid #E6E7EA",
            }}
          >
            <Grid.Col className="setting-header" span={{ sm: 12, md: 3 }}>
              <Flex gap={8} className="setting-header-title">
                <Title
                  order={4}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  Đăng nhập
                </Title>
                <IconSparkles color="blue" />
              </Flex>
            </Grid.Col>
            <Grid.Col className="setting-main" span={{ sm: 12, md: 8 }}>
              <Stack gap={16}>
                <Flex gap={8}>
                  <Title order={6} fw={500}>
                    Phiên đăng nhập
                  </Title>
                  <IconSparkles color="blue" size={18} />
                </Flex>
                <Grid>
                  <Grid.Col span={{ base: 6 }}>
                    <InputLabel htmlFor="session_life_time">
                      Thời gian tồn tại của một phiên
                    </InputLabel>
                    <Flex gap={16} align={"center"}>
                      <NumberInput
                        size="sm"
                        name="session_life_time"
                        w={100}
                        {...form.getInputProps("timesession")}
                      />
                      <Text size="sm" color="gray">
                        phút(s)
                      </Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={{ base: 6 }}>
                    <InputLabel htmlFor="max_current_device_logon">
                      Số lượng thiết bị đăng nhập đồng thời
                    </InputLabel>
                    <NumberInput
                      size="sm"
                      name="max_current_device_logon"
                      {...form.getInputProps("numberdevice")}
                      w={100}
                    />
                  </Grid.Col>
                </Grid>
                <Flex gap={8}>
                  <Checkbox
                    name="approve_on_first_time_login"
                    checked={form.values.loginapprovefrist}
                    {...form.getInputProps("loginapprovefrist")}
                  />
                  <InputLabel htmlFor="approve_on_first_time_login">
                    Phê duyệt ở mọi lần đăng nhập
                  </InputLabel>
                </Flex>
                <Flex gap={8}>
                  <Title order={6} fw={500}>
                    Địa chỉ IP
                  </Title>
                  <IconSparkles color="blue" size={18} />
                </Flex>
                <TagsInput
                  value={ipWhiteList}
                  onChange={(e) => {
                    setIpWhiteList(e);
                  }}
                  // {...form.getInputProps("listipallow")}
                />
              </Stack>
            </Grid.Col>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};
