import { Box, Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";

export const DeleteProposalView = ({
  idItems,
  onClose,
}: DeleteProposalViewProps) => {
  const handleDelete = async () => {
    try {
      const res = await repositoryMdm.post<MessageResponse<boolean>>(
        "/api/v1/TblApproposal/delete",
        idItems
      );
      if (res)
        if (res.success) {
          NotificationExtension.Success("Xóa thành công !");
          onClose((prev: any) => !prev);
        } else NotificationExtension.Fails("Xóa thất bại !");
      modals.closeAll();
    } catch (error) {
      NotificationExtension.Fails("Xóa thất bại !");
      modals.closeAll();
    }
  };
  return (
    <Box size={"auto"}>
      <Text size="20px" mt={5}>
        Bạn có chắc chắn muốn xóa{idItems.length === 1 ? "" : " các"} đề xuất
        này này ?
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color="red"
          onClick={handleDelete}
          leftSection={<IconCheck size={18} />}
        >
          Xóa
        </Button>
      </Group>
    </Box>
  );
};

type DeleteProposalViewProps = {
  idItems: (number | string)[];
  onClose: any;
};
