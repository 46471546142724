import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { repositoryMdm } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";

type SerialStatus = {
  id: number;
  itemCode: string | null;
  itemName: string | null;
  serialCode: string | null;
  itemType: string | null;
  sell: boolean | null;
  active: boolean | null;
  display: boolean | null;
  experience: string | null;
  promotion: boolean | null;
  warrantyRenew: boolean | null;
  warrantyRepair: boolean | null;
  warrantyExchange: boolean | null;
  billing: boolean | null;
  createBy: number | null;
  createDate: string | null;
  lastUpdateBy: number | null;
  lastUpdateDate: string | null;
};

type EditDataViewProps = {
  id: string | number;
  onClose: any;
};

const EditWarrantyRenewal = ({ id, onClose }: EditDataViewProps) => {
  const entity = {
    id: 0,
    itemCode: null,
    itemName: null,
    serialCode: null,
    itemType: null,
    sell: null,
    active: null,
    display: null,
    experience: null,
    promotion: null,
    warrantyRenew: null,
    warrantyRepair: null,
    warrantyExchange: null,
    billing: null,
    createBy: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [enable, setEnable] = useState(false);

  const form = useForm<SerialStatus>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      itemType: (value) => (value ? null : "Bắt buộc chọn loại hàng"),
      experience: (value) => (value ? null : "Bắt buộc chọn trải nghiệm"),
    },
  });

  const experienceOptions = [
    { label: "Được trải nghiệm", value: "Được trải nghiệm" },
    { label: "Theo quy định Hacom", value: "Theo quy định Hacom" },
    { label: "Tùy theo NCC", value: "Tùy theo NCC" },
    { label: "Không áp dụng", value: "Không áp dụng" },
  ];

  const itemTypeOptions = [
    { label: "Hàng thanh lý", value: "Hàng thanh lý" },
    { label: "Hàng thông thường", value: "Hàng thông thường" },
    { label: "Hàng ký gửi", value: "Hàng ký gửi" },
    { label: "Hàng tặng", value: "Hàng tặng" },
    {
      label: "Hàng hóa đổi linh kiện bên trong",
      value: "Hàng hóa đổi linh kiện bên trong",
    },
    {
      label: "Linh kiện tháo ra từ hàng hóa",
      value: "Linh kiện tháo ra từ hàng hóa",
    },
  ];

  const handleEditTblDMBranch = async (dataSubmit: SerialStatus) => {
    open();
    const dataApi = await repositoryMdm.post<MessageResponse<SerialStatus>>(
      "/api/v1/TblSerialStatus/update",
      dataSubmit
    );

    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
    } else {
      NotificationExtension.Fails("Cập nhật thất bại");
    }
    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<SerialStatus>>(
      `/api/v1/TblSerialStatus/update?id=${id}`
    );

    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
      return;
    }

    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    setEnable(form.isDirty());
  }, [form]);

  return (
    <Box
      component="form"
      mx="auto"
      onSubmit={form.onSubmit((e: SerialStatus) => {
        handleEditTblDMBranch(e);
      })}
      style={{ position: "relative", width: "350px", height: "auto" }}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label={"Mã sản phẩm"}
            placeholder={"Mã sản phẩm"}
            type="text"
            withAsterisk
            readOnly
            disabled
            style={{ cursor: "not-allowed" }}
            {...form.getInputProps("itemCode")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label={"Tên sản phẩm"}
            placeholder={"Tên sản phẩm"}
            type="text"
            withAsterisk
            readOnly
            disabled
            {...form.getInputProps("itemName")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label={"Mã serial"}
            placeholder={"Mã serial"}
            type="text"
            withAsterisk
            readOnly
            disabled
            {...form.getInputProps("serial")}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Select
            id="itemType"
            key={form.key("itemType")}
            label="Loại hàng"
            placeholder="Chọn loại hàng"
            data={itemTypeOptions}
            withAsterisk
            clearable
            searchable
            error={form.errors.itemType}
            {...form.getInputProps("itemType")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Select
            id="experience"
            key={form.key("experience")}
            label="Trải Nghiệm"
            withAsterisk
            placeholder="Chọn Trải Nghiệm"
            data={experienceOptions}
            clearable
            searchable
            error={form.errors.experience}
            {...form.getInputProps("experience")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <TextInput
            label={"Gia hạn bảo hành"}
            placeholder={"Gia hạn bảo hành"}
            type="text"
            withAsterisk
            {...form.getInputProps("period")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Checkbox
            key={form.key("sell")}
            label="Bán"
            defaultValue={form.getValues().sell ?? false}
            defaultChecked={form.getValues().sell ?? false}
            {...form.getInputProps("sell")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Checkbox
            key={form.key("active")}
            label="Mới 100%"
            defaultValue={form.getValues().active ?? false}
            defaultChecked={form.getValues().active ?? false}
            {...form.getInputProps("active")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Checkbox
            key={form.key("display")}
            label="Trưng bày"
            defaultValue={form.getValues().display ?? false}
            defaultChecked={form.getValues().display ?? false}
            {...form.getInputProps("display")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Checkbox
            key={form.key("promotion")}
            label="Khuyến mãi"
            defaultValue={form.getValues().promotion ?? false}
            defaultChecked={form.getValues().promotion ?? false}
            {...form.getInputProps("promotion")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Checkbox
            key={form.key("warrantyRenew")}
            label="Gia hạn bảo hành"
            defaultValue={form.getValues().warrantyRenew ?? false}
            defaultChecked={form.getValues().warrantyRenew ?? false}
            {...form.getInputProps("warrantyRenew")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Checkbox
            key={form.key("warrantyRepair")}
            label="Bảo hành sửa chữa"
            defaultValue={form.getValues().warrantyRepair ?? false}
            defaultChecked={form.getValues().warrantyRepair ?? false}
            {...form.getInputProps("warrantyRepair")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Checkbox
            key={form.key("warrantyExchange")}
            label="Bảo hành đổi trả"
            defaultValue={form.getValues().warrantyExchange ?? false}
            defaultChecked={form.getValues().warrantyExchange ?? false}
            {...form.getInputProps("warrantyExchange")}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
          <Checkbox
            key={form.key("billing")}
            label="Tính hóa đơn"
            defaultValue={form.getValues().billing ?? false}
            defaultChecked={form.getValues().billing ?? false}
            {...form.getInputProps("billing")}
          />
        </Grid.Col>
      </Grid>

      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
        >
          Đóng
        </Button>
        <Button type="submit" loading={visible}>
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default EditWarrantyRenewal;
