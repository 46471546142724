import { Box, Button, Group, Input } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconSearch } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { WarrantyRenewalListItem } from "./WarrantyRenewalList";
export const DataPickerView = ({ data, setData }: DataPickerViewProps) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [filterdData, setFilterdData] = useState<WarrantyRenewalListItem[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [search, setSearch] = useState({
    keySearch: "",
  });
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const handleSave = () => {
    const termData = data.filter((item) =>
      selectedIds.includes(item.id.toString())
    );
    setData(termData);
    modals.closeAll();
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };
  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    if (valuesList.length < 1) setSelectedIds([]);
    else setSelectedIds(valuesList);
  }, [rowSelection]);
  const columns = React.useMemo<MRT_ColumnDef<WarrantyRenewalListItem>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 50,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        size: 30,
      },
      {
        accessorKey: "serial",
        header: "Mã serial",
        size: 30,
      },

      {
        accessorKey: "itemType",
        header: "Loại hàng",
        size: 30,
      },
    ],
    [data]
  );
  useEffect(() => {
    setFilterdData(data);
  }, []);

  const handleFilter = () => {
    if (!search.keySearch) {
      setFilterdData(data);
    } else {
      const termData = data.filter((item) => {
        console.log("object", data);
        // Check if each property exists before calling toLowerCase
        return (
          item.itemName
            ?.toLowerCase()
            .includes(search.keySearch.trim().toLowerCase()) ||
          item.serial
            ?.toLowerCase()
            .includes(search.keySearch.trim().toLowerCase()) ||
          item.itemCode
            ?.toLowerCase()
            .includes(search.keySearch.trim().toLowerCase())
        );
      });
      setFilterdData(termData);
    }
  };

  const table = useMantineReactTable({
    columns,
    data: filterdData,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    // mantineTableContainerProps: {
    //   style: {},
    // },
    enablePagination: false,
    enableBottomToolbar: false, //hide the bottom toolbar as well if you want
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {/* <Select
            size="sm"
            placeholder="Trạng thái"
            searchable
            clearable
            data={[
              { value: "true", label: "Mới 100%" },
              { value: "false", label: "Đã qua sử dụng" },
            ]}
            style={{ flex: 1, maxWidth: "180px" }}
            leftSection={<IconUser size={20} />}
            onChange={(value) => handleChangeSearchValue(value ?? "", "status")}
          /> */}
          <Input
            style={{ flex: 1, maxWidth: "180px" }}
            placeholder="Nhập từ khóa tìm kiếm"
            type="text"
            leftSection={<IconSearch size={"20"} />}
            onChange={(e) => {
              handleChangeSearchValue(e.target.value ?? "", "keySearch");
            }}
          />
          <Button
            color="blue"
            variant="outline"
            onClick={() => {
              handleFilter();
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
  });
  return (
    <div>
      <MantineReactTable table={table} />
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          onClick={() => {
            modals.closeAll();
          }}
        >
          Đóng
        </Button>
        <Button type="submit" onClick={handleSave}>
          Lưu
        </Button>
      </Group>
    </div>
  );
};

type DataPickerViewProps = {
  data: WarrantyRenewalListItem[];
  setData: any;
};
