import { useEffect, useState } from "react";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { TblOutwardWarrantyVendor } from "../../../model/TblOutWard";
import { useDisclosure } from "@mantine/hooks";
import { DatePickerInput } from "@mantine/dates";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import {
  getTblDmDeliverPrioritySelect,
  getTblDMInventory,
} from "../../../service/getSelectApi";
import { modals } from "@mantine/modals";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { sky_blue } from "../../../const/variables";
import React from "react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";

const OutWardWarrantyVendor = ({
  handleVendorWarrantyOutward,
  invId,
  dataRowProps,
}: OutWardWarrantyVendorProps) => {
  const entity = {
    createDate: new Date().toISOString(),
    empInchargeId: null,
    createBy: Number(
      JSON.parse(localStorage.getItem("userLogin") || "{}")?.id || ""
    ),
    vendorId: null,
    vendorName: null,
    invId: invId,
    priority: null,
    departmentId: "KHONG",
    addressVendor: null,
    note: null,
  };

  const form = useForm<TblOutwardWarrantyVendor>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      vendorId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập tên nhà cung cấp !";
        }
      },
      empInchargeId: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn nhân viên thực hiện !";
        }
      },
    },
  });

  const [visible, { close, open }] = useDisclosure(false);

  const [isShipping, setIsShipping] = useState<boolean>(false);
  const [dataVendorSelect, setDataVendorSelect] = useState<any[]>([]);
  const [dataDeliverPriority, setDataDeliverPriority] = useState<
    ComboboxItem[]
  >([]);
  const [dataInvSelect, setDataInvSelect] = useState<ComboboxItem[]>([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 75,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "vendorName",
        header: "Nhà cung cấp",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "serialStatus",
        header: "Trạng thái serial",
        size: 75,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "invOrgName",
        header: "Kho",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
    ],
    [dataRowProps]
  );

  const getDataVendor = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      `/api/v1/TblVendor/get-all`
    );

    if (res && res?.success) {
      const dataApi = res.data;
      setDataVendorSelect(
        dataApi
          ?.filter((item: any) => item.vendorName != null && item.id != null)
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.vendorName,
            addr: item.addr?.toString(),
            phone: item.phone?.toString(),
          }))
      );
    }
  };

  const getDataDeliverPrioritySelect = async () => {
    const res = await getTblDmDeliverPrioritySelect();
    setDataDeliverPriority(
      res
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataInvSelect = async () => {
    const res = await getTblDMInventory();
    setDataInvSelect(
      res
        ?.filter(
          (item: any) =>
            item.text != null && item.value != null && item.value != "-1"
        )
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataEmployeeSelect = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-all"
    );

    if (res && res?.success) {
      const result = res.data;
      setDataEmployeeSelect(
        result
          ?.filter(
            (item: any) =>
              item.fullname != null &&
              item.roleName != null &&
              (item.roleName.toLowerCase().includes("bảo hành") ||
                item.roleName.toLowerCase().includes("giao vận"))
          )
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.fullname?.toString(),
          }))
      );
    }
  };

  useEffect(() => {
    getDataInvSelect();
  }, []);

  const table = useMantineReactTable({
    columns,
    data: dataRowProps,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    renderTopToolbarCustomActions: ({ table }) => <></>,
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionNumber"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 225, minHeight: 225 },
    },
    enableStickyHeader: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <Box
      component="form"
      mx="auto"
      w={"82.5vw"}
      maw={1250}
      onSubmit={form.onSubmit((e: TblOutwardWarrantyVendor) => {
        handleVendorWarrantyOutward(e, isShipping);
      })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Grid mt={10}>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <Fieldset legend="Thông tin xuất bảo hành NCC">
            <Grid>
              <Grid.Col span={12}>
                <Select
                  label="Xuất tới NCC"
                  placeholder="Nhập tên NCC"
                  searchable
                  clearable
                  data={dataVendorSelect?.map((item: any) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  nothingFoundMessage="Không tìm thấy dữ liệu !"
                  {...form.getInputProps("vendorId")}
                  withAsterisk
                  onChange={(e) =>
                    form.setValues((prev) => ({
                      ...prev,
                      vendorId: e ? Number(e) : null,
                      vendorName: e
                        ? getValueById(e?.toString(), dataVendorSelect, "label")
                        : null,
                      addressVendor: e
                        ? getValueById(e?.toString(), dataVendorSelect, "addr")
                        : null,
                    }))
                  }
                  onClick={() => {
                    if (dataVendorSelect.length === 0) {
                      getDataVendor();
                    }
                  }}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label="Địa chỉ cụ thể"
                  placeholder="Địa chỉ cụ thể"
                  value={form.getValues().addressVendor ?? ""}
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <TextInput
                  label="Số điện thoại NCC"
                  placeholder="Số điện thoại NCC"
                  variant="filled"
                  readOnly
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <Select
                  label="Kho"
                  placeholder="Nhập tên kho"
                  data={dataInvSelect}
                  value={form.getValues().invId?.toString()}
                  variant="filled"
                  nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                  readOnly
                  {...form.getInputProps("invId")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <Fieldset legend="Thông tin giao vận">
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <DatePickerInput
                  label="Ngày lập"
                  value={
                    form.getValues().createDate
                      ? new Date(form.getValues().createDate ?? "")
                      : null
                  }
                  valueFormat="DD-MM-YYYY"
                  variant="filled"
                  readOnly
                  {...form.getInputProps("createDate")}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <TextInput
                  label="Nhân viên lập"
                  value={
                    JSON.parse(localStorage.getItem("userLogin") || "{}")
                      ?.fullName || ""
                  }
                  variant="filled"
                  readOnly
                  {...form.getInputProps("createBy")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <Select
                  label="Bộ phận giao hàng"
                  placeholder="Nhập bộ phận giao hàng"
                  data={[
                    { label: "Không", value: "KHONG" },
                    { label: "Nội bộ", value: "NOI_BO" },
                    { label: "GHTK", value: "GHTK" },
                    { label: "Nhất tín", value: "NHAT_TIN" },
                    { label: "SCA", value: "SCA" },
                    { label: "Tín thành", value: "TIN_THANH" },
                    { label: "Viettel", value: "VIETTEL" },
                    { label: "VN Post", value: "VN_POST" },
                  ]}
                  value={form.getValues().departmentId}
                  searchable
                  nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                  withAsterisk
                  {...form.getInputProps("departmentId")}
                  onChange={(e) => {
                    form.setValues((prev) => ({
                      ...prev,
                      departmentId: e ? e : "KHONG",
                    }));
                    if (e === "NOI_BO") {
                      setIsShipping(true);
                    } else {
                      setIsShipping(false);
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <Select
                  label="Mức độ ưu tiên"
                  placeholder="Nhập mức độ ưu tiên"
                  data={dataDeliverPriority}
                  searchable
                  clearable
                  nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                  disabled={form.getValues().departmentId === "KHONG"}
                  {...form.getInputProps("priority")}
                  onClick={() => {
                    if (dataDeliverPriority.length === 0) {
                      getDataDeliverPrioritySelect();
                    }
                  }}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <Select
                  label="Nhân viên thực hiện"
                  placeholder="Nhập tên nhân viên thực hiện"
                  data={dataEmployeeSelect}
                  searchable
                  clearable
                  nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                  withAsterisk
                  {...form.getInputProps("empInchargeId")}
                  onClick={() => {
                    if (dataEmployeeSelect.length === 0) {
                      getDataEmployeeSelect();
                    }
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <TextInput
                  label="Ghi chú"
                  placeholder="Ghi chú"
                  {...form.getInputProps("note")}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
      </Grid>
      <MantineReactTable table={table} />
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
        >
          Quay lại
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default OutWardWarrantyVendor;

type OutWardWarrantyVendorProps = {
  handleVendorWarrantyOutward: any;
  invId: any;
  dataRowProps: any;
};
