import style from "./ReceiveTicketForm.module.css";
import logo from "../../../assets/images/logo-hacom-2024.png";

const PrinterReceiveTicket = ({
  innerRef,
  dataPrint,
}: {
  innerRef: any;
  dataPrint: any;
}) => {
  console.log(dataPrint);
  return (
    <div ref={innerRef} className={style.main}>
      <div className={style.header}>
        <img src={logo} alt="logo-hacom-2024" width={150} height={"auto"} />
        <div>
          <div className={style.line}>
            <h3>CÔNG TY CỔ PHẦN ĐẦU TƯ CÔNG NGHỆ HACOM</h3>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Chi nhánh: </p>
            <p>
              {
                JSON.parse(localStorage.getItem("userLogin") || "{}")
                  ?.branchName
              }
            </p>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Bộ phận/Phòng ban:</p>
            <p>Phòng kỹ thuật bảo hành</p>
          </div>
          <div className={style.line}>
            <div>
              <p className={style.cText}>Tel: </p>
              <p>19001903</p>
            </div>
            <div>
              <p className={style.cText}>Máy lẻ:</p>
              <p>25377</p>
            </div>
          </div>
          <div className={style.line}>
            <p className={style.cText}>Email:</p>
            <p>baohanh@hacom.vn</p>
          </div>
        </div>
      </div>
      <div className={style.body}>
        <div className={style.title}>
          <h2>PHIẾU TIẾP NHẬN HÀNG LỖI</h2>
        </div>
        <div>
          <div className={style.infoForm}>
            <div style={{ width: "50%" }}>
              <div>
                <p>Tên khách:</p>
                <p>{dataPrint?.custMeeting}</p>
              </div>
              <div>
                <p>Số điện thoại:</p>
                <p>{dataPrint?.custPhone}</p>
              </div>
              <div>
                <p>Địa chỉ:</p>
                <p>{dataPrint?.meetingAddress}</p>
              </div>
              <div>
                <p>Mã phiếu tiếp nhận yêu cầu:</p>
                <p>{dataPrint?.requestNum}</p>
              </div>
            </div>
            <div style={{ width: "45%" }}>
              <div>
                <p>Mã phiếu tiếp nhận hàng lỗi:</p>
                <p>{dataPrint?.errorNumber}</p>
              </div>
              <div>
                <p>Kho:</p>
                <p>{dataPrint?.branchName}</p>
              </div>
              <div>
                <p>Ngày nhận:</p>
                <p>
                  {String(new Date(dataPrint?.createDate).getHours()).padStart(
                    2,
                    "0"
                  )}
                  :
                  {String(
                    new Date(dataPrint?.createDate).getMinutes()
                  ).padStart(2, "0")}{" "}
                  {String(new Date(dataPrint?.createDate).getDate()).padStart(
                    2,
                    "0"
                  )}
                  /
                  {String(
                    new Date(dataPrint?.createDate).getMonth() + 1
                  ).padStart(2, "0")}
                  /{new Date(dataPrint?.createDate).getFullYear()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <table className={style.table}>
          <thead>
            <tr>
              <th>STT</th>
              <th style={{ width: "120px" }}>Serial</th>
              <th>Tên sản phẩm</th>
              <th>Mã sản phẩm</th>
              <th>Số lượng</th>
              <th>Ngày bán</th>
              <th>Phụ kiện</th>
              <th>Ngày hẹn trả</th>
              <th>Ghi chú</th>
            </tr>
          </thead>
          <tbody>
            {dataPrint?.tblWarrantyReceiveTicketDetailModels?.map(
              (item: any, index: any) => (
                <tr>
                  <td>{index + 1}</td>
                  <td style={{ wordBreak: "break-word" }}>{item?.serial}</td>
                  <td>{item?.itemName}</td>
                  <td>{item?.itemCode}</td>
                  <td>1</td>
                  <td>
                    {String(new Date(item?.buyingDate).getDate()).padStart(
                      2,
                      "0"
                    )}
                    /
                    {String(new Date(item?.buyingDate).getMonth() + 1).padStart(
                      2,
                      "0"
                    )}
                    /{new Date(item?.buyingDate).getFullYear()}
                  </td>
                  <td>{item?.acessory}</td>
                  <td>
                    {String(new Date(item?.scheduleTime).getDate()).padStart(
                      2,
                      "0"
                    )}
                    /
                    {String(
                      new Date(item?.scheduleTime).getMonth() + 1
                    ).padStart(2, "0")}
                    /{new Date(item?.scheduleTime).getFullYear()}
                  </td>
                  <td>{item?.note}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
        <div className={style.note1}>
          <p>Ghi chú:</p>
          <p>
            ......................................................................................................................................................................................
          </p>
        </div>
        <div className={style.note2}>
          <p>Yêu cầu của khách hàng:</p>
          <p>
            ..........................................................................................................................................................
          </p>
        </div>
        <div className={style.careful}>
          <div>
            <p>Xin lưu ý:</p>
          </div>
          <div>
            <p>Công ty chúng tôi không chịu trách nhiệm về dữ liệu.</p>
            <p>Quý khách đến nhận bảo hành cần mang theo giấy này.</p>
            <p>Phiếu này chỉ có giá trị trong 30 ngày kể từ ngày lập phiếu.</p>
          </div>
        </div>
        <div className={style.signature}>
          <div>
            <p>Khách gửi BH</p>
            <p>
              {"("}Ký, ghi rõ họ tên{")"}
            </p>
          </div>
          <div>
            <p>NV nhận BH</p>
            <p>
              {"("}Ký, ghi rõ họ tên{")"}
            </p>
          </div>
          <div>
            <p>Khách nhận lại</p>
            <p>
              {"("}Ký, ghi rõ họ tên{")"}
            </p>
          </div>
          <div>
            <p>NV trả BH</p>
            <p>
              {"("}Ký, ghi rõ họ tên{")"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrinterReceiveTicket;
