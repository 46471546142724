import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridCol,
  Group,
  Input,
  LoadingOverlay,
  Select,
  SelectProps,
  Text,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import {
  IconCheck,
  IconPrinter,
  IconSearch,
  IconStatusChange,
  IconTimeDuration15,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import {
  _timerDelayClick,
  repositoryMdm,
  repositoryPayment,
} from "../../_base/_const/_constVar";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { _localization } from "../../config/location";
import { cobalt_blue, green, red } from "../../const/variables";
import { TblDMInvoice } from "../../model/tblInvoice";
import PrintedInvoice from "./PrintedInvoice";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import InputSearch from "../../common/InputSearch/InputSearch";
const WarehouseOutWard = () => {
  const navigate = useNavigate();
  // Reference data from another table

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMInvoice[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [referencelabelcode, setReferencelabelcode] = useState(null);
  const [valueQRCode, setValueQRCode] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [idPrint, setIdPrint] = useState<any>([]);
  const [invoiceData, setInvoiceData] = useState<any>(null);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  const [search, setSearch] = useState({
    keySearch: "",
    fromTransationDate: "",
    toTransationDate: "",
    status: "",
  });
  // hàm này để cho chọn ngày đúng , chứ không khi chọn sẽ bị lùi 1 ngày so với ngày chọn
  const addOneDay = (isoDate: string | null) => {
    if (!isoDate) return "";

    // Tạo đối tượng Date từ chuỗi ISO
    const date = new Date(isoDate);

    // Thêm 1 ngày vào đối tượng Date
    date.setDate(date.getDate() + 1);

    // Trả về chuỗi ngày giờ theo chuẩn ISO
    return date.toISOString();
  };
  function getColorStatus(text: any) {
    switch (text) {
      case "DON_HANG_TAM":
        return cobalt_blue.base; // ví dụ về màu
      case "DON_HANG_KHOA":
        return red.base;
      case "KHO_XUAT_HANG":
        return green.light;
      case "DON_HANG_HUY":
        return "grey"; // Giả sử màu xám cho đơn hàng đã hủy
      case "THANH_TOAN_CHUA_HOAN_THANH":
        return "yellow"; // Giả sử màu vàng cho thanh toán chưa hoàn thành
      case "DON_HANG_BAN_TAI_SHOP":
        return "purple"; // Giả sử màu xanh nhạt cho đơn hàng bán tại cửa hàng
      case "Pending":
        return "orange"; // Giả sử màu cam cho trạng thái đang chờ
      default:
        return "orange";
    }
  }

  function getStatus(text: any) {
    switch (text) {
      case "DON_HANG_TAM":
        return "Đơn hàng tạm";
      case "DON_HANG_KHOA":
        return "Đơn hàng khoá";
      case "KHO_XUAT_HANG":
        return "Kho xuất hàng";
      case "DON_HANG_HUY":
        return "Đơn hàng đã hủy";
      case "THANH_TOAN_CHUA_HOAN_THANH":
        return "Thanh toán chưa hoàn thành";
      case "DON_HANG_BAN_TAI_SHOP":
        return "Đơn hàng bán tại cửa hàng";
      case "Pending":
        return "Đang chờ xử lý";
      default:
        return "Đang chờ xử lý";
    }
  }

  const dataStatusOption = [
    {
      value: "DON_HANG_TAM",
      label: "Đơn hàng tạm",
    },
    {
      value: "KHO_XUAT_HANG",
      label: "Kho xuất hàng",
    },
    {
      value: "DON_HANG_KHOA",
      label: "Đơn hàng đã khóa",
    },
    {
      value: "DON_HANG_HUY",
      label: "Đơn hàng đã hủy",
    },
    {
      value: "THANH_TOAN_CHUA_HOAN_THANH",
      label: "Thanh toán chưa hoàn thành",
    },
    {
      value: "DON_HANG_BAN_TAI_SHOP",
      label: "Đơn hàng bán tại cửa hàng",
    },
    {
      value: "Pending",
      label: "Đang chờ xử lý",
    },
  ];
  const optionColorsAccount: Record<string, string> = {
    "Đơn hàng tạm thời": "blue",
    "Kho xuất hàng": "green",
    "Đơn hàng đã khóa": "red",
    "Đơn hàng đã hủy": "grey", // Màu cho đơn hàng đã hủy
    "Thanh toán chưa hoàn thành": "yellow", // Màu cho thanh toán chưa hoàn thành
    "Đơn hàng bán tại cửa hàng": "purple", // Màu cho đơn hàng bán tại cửa hàng
    "Đang chờ xử lý": "orange", // Màu cho trạng thái đang chờ xử lý
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={optionColorsAccount[option.label]}
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );
  // Hàm định dạng tiền tệ
  const formatCurrency = (value: any) => {
    if (value == null) {
      return "";
    }
    // Định dạng số theo chuẩn Việt Nam
    return value.toLocaleString("vi-VN", {
      minimumFractionDigits: 0, // Không hiển thị phần thập phân
      maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
    });
  };
  const columns = React.useMemo<MRT_ColumnDef<TblDMInvoice>[]>(
    () => [
      {
        accessorKey: "orderNumber",
        header: "Mã đơn hàng",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo đơn hàng",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "invoiceDate",
        header: "Ngày hoàn thành",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "orderStatus",
        header: "Trạng thái của đơn hàng",
        width: 200,
        Cell: ({ renderedCellValue }) => (
          <Badge color={getColorStatus(renderedCellValue)}>
            {getStatus(renderedCellValue)}
          </Badge>
        ),
      },
      {
        accessorKey: "branchCode",
        header: "Chi nhánh",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
        size: 30,
      },
      {
        accessorKey: "saleChanelCode",
        header: "Kênh bán hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
        size: 30,
      },
      {
        accessorKey: "saleName",
        header: "Nhân viên bán hàng",
        width: 200,
        Cell: ({ renderedCellValue }) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "techEmpCode",
        header: "Nhân viên kỹ thuật",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "departmentName",
        header: "Phòng ban",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text fw={700} size="sm">
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "custCode",
        header: "Mã khách hàng",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "custName",
        header: "Tên khách hàng",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "telephoneNumber",
        header: "Thông tin liên lạc",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "orderAmount",
        header: "Tổng tiền hàng",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {formatCurrency(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "discountAddedTotal",
        header: "Số tiền giảm giá",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {formatCurrency(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "pointExchangeAmout",
        header: "Điểm tích lũy",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {renderedCellValue}
          </Text>
        ),
      },
      // {
      //   accessorKey: "approveStatus",
      //   header: "Trạng thái duyệt giảm giá",
      //   Cell: ({ renderedCellValue }) => (
      //     <Text size="sm" maw={300}>
      //       {renderedCellValue}
      //     </Text>
      //   ),
      // },
      {
        accessorKey: "approveName",
        header: "Tên người phê duyệt",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {renderedCellValue}
          </Text>
        ),
      },

      {
        accessorKey: "amountTotal",
        header: "Tổng tiền thanh toán",
        width: 100,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" fw={700} maw={300}>
            {formatCurrency(renderedCellValue)}
          </Text>
        ),
      },
      // {
      //   accessorKey: "paymentMethod",
      //   header: "Phương thức thanh toán",
      //   width: 200,
      //   Cell: ({ renderedCellValue }) => (
      //     <Text size="sm">{renderedCellValue}</Text>
      //   ),
      // },
      {
        accessorKey: "cashAmount",
        header: "Thanh toán tiền mặt",
        width: 200,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{formatCurrency(renderedCellValue)}</Text>
        ),
      },
      {
        accessorKey: "payAmount",
        header: "Thanh toán qua pay",
        width: 200,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {formatCurrency(renderedCellValue)}
          </Text>
        ),
      },

      {
        accessorKey: "custAmountTransfer",
        header: "Thanh toán chuyển khoản",
        width: 200,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {formatCurrency(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "codAmount",
        header: "Thanh toán sau COD",
        width: 200,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {formatCurrency(renderedCellValue)}
          </Text>
        ),
      },
      {
        accessorKey: "manage",
        header: "Quản lý",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" maw={300}>
            {renderedCellValue}
          </Text>
        ),
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              position: "relative",
              width: "100%",
            }}
            onClick={() => handleOnClickPrint(row.original?.id)}
          >
            <Tooltip label={"In hóa đơn"}>
              <ActionIcon variant="light" aria-label="Settings">
                <IconPrinter stroke={2} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );
  const handleOnClickPrint = (id: any) => {
    setIsPrinting(true);
    setIdPrint(id);
  };
  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    if (search.fromTransationDate) {
      url += `&StartTimeCreate=${addOneDay(search?.fromTransationDate)}`;
    }

    if (search.toTransationDate) {
      url += `&EndTimeCreate=${addOneDay(search?.toTransationDate)}`;
    }

    if (search.status) {
      url += `&OrderStatus=${search.status}`;
    }
    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
      url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
    }
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/get-list-order-header${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        // Promise.all([dataLocation()]);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      } else setData([]);
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };
  const fetchInvoiceData = async () => {
    if (idPrint.length === 0) return;
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/print-order?ids=${idPrint}`
      );
      if (response && response.success) {
        let result = response.data;
        setInvoiceData(result || null);
      } else {
        setInvoiceData(null);
        setIsPrinting(false);
        NotificationExtension.Fails("In hóa đơn không thành công");
      }
    } catch (error) {
      setInvoiceData(null);
      console.error(error);
      return;
    }
  };
  const CallApiQrCode = async (qrCodeData: any) => {
    try {
      const response = await repositoryPayment.post(
        `api/TblMBQRcode/gen-qr-mb`,
        qrCodeData
      );
      if (response && response.success !== false) {
        setReferencelabelcode(response.data.referencelabelcode);
      } else {
        setIsPrinting(false);
        NotificationExtension.Fails("In hóa đơn không thành công");
      }
    } catch (error) {
      setIsPrinting(false);
      console.log(error);
    }
  };
  const fetApiQrCodeValue = async () => {
    try {
      if (referencelabelcode === null) return;
      const response = await repositoryPayment.get(
        `/api/TblMBQRcode/get-qr-mb?key=${referencelabelcode}`
      );
      if (response && response.success) {
        setValueQRCode(response.data.qrcodevalue);
      } else {
        setIsPrinting(false);
      }
    } catch (error) {
      setIsPrinting(false);
      console.log(error);
    }
  };
  useEffect(() => {
    const Process = async () => {
      try {
        if (invoiceData && !isLoading) {
          const qrCodeData = {
            id: 0,
            terminalID: "12345678",
            qrcodeType: 1,
            parterType: 2,
            initMethod: 1,
            transactionAmount: invoiceData[0]?.payAmount,
            billNumber: invoiceData[0]?.orderNumber,
            referenceLabelTime: invoiceData[0]?.createDate,
            referenceLabelCode: "",
            transactionPurpose: "Thanh toán đơn hàng",
            additionAddress: invoiceData[0]?.custName,
            additionMobile: invoiceData[0]?.telephoneNumber,
            additionEmail: "",
            createdBy: invoiceData[0]?.casherName,
            lastUpdateDate:
              invoiceData[0]?.approveDate || "2024-10-01T03:52:55.361Z",
            lastUpdatedBy: invoiceData[0]?.casherName,
            lastUpdateLogin: "loginInfo",
            creationDate: invoiceData[0]?.createDate,
            qrcodevalue: "",
            ordercode: "ORDER12345",
          };

          await CallApiQrCode(qrCodeData);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    Process();
  }, [invoiceData]);
  useEffect(() => {
    if (referencelabelcode) {
      const Process = async () => {
        try {
          await fetApiQrCodeValue();
        } catch (error) {
          console.log("error", error);
        }
      };
      Process();
    }
  }, [referencelabelcode]);

  useEffect(() => {
    fetchInvoiceData();
  }, [idPrint]);
  useEffect(() => {
    fetchData();
  }, [sorting, pagination.pageIndex, pagination.pageSize]);
  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, [height]);
  useEffect(() => {
    // Chỉ kích hoạt handlePrint khi cả invoiceData và valueQRCode đã sẵn sàng
    if (
      (invoiceData || null) &&
      (valueQRCode || null) &&
      (componentRef.current || null)
    ) {
      handlePrint();
      setIsPrinting(false);
      setIdPrint([]);
      setInvoiceData(null);
      setValueQRCode(null);
    }
  }, [invoiceData, valueQRCode]);
  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    // getRowId: (row) => row.transactionId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["orderNumber"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading: isLoading || isPrinting,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading || isPrinting,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
        </Flex>

        <Grid mt={"10px"}>
          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            {/* <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            /> */}
            <InputSearch
              width="100%"
              placeholder="Từ khoá"
              onSearch={fetchData}
              onChange={(value) => {
                handleChangeSearchValue(value, "keySearch");
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 2 }}>
            <Select
              placeholder={"Trạng thái"}
              searchable
              clearable
              width={"100%"}
              data={dataStatusOption}
              leftSection={<IconStatusChange color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              renderOption={renderSelectOptionStatus}
              nothingFoundMessage={"Không có dữ liệu"}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "status")
              }
            />
          </GridCol>

          <GridCol span={{ base: 8, sm: 6, md: 3.35, lg: 2.5 }}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              allowSingleDateInRange
              placeholder="Chọn khoảng ngày"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  e[0] ? e[0].toISOString() : "",
                  "fromTransationDate"
                );
                handleChangeSearchValue(
                  e[1] ? e[1].toISOString() : "",
                  "toTransationDate"
                );
              }}
              clearable
            />
          </GridCol>

          <GridCol span={{ base: 4, sm: 4, md: 1.5, lg: 1.25 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
      {invoiceData && valueQRCode && (
        <div style={{ display: "none" }}>
          <PrintedInvoice
            componentRef={componentRef}
            invoiceData={invoiceData}
            valueQRCode={valueQRCode}
          />
        </div>
      )}
    </>
  );
};

export default WarehouseOutWard;
