import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  TextInput,
  Textarea,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMDeliveryMethod } from "../../../../model/TblDMDeliveryMethod";
import { handleKeyDown } from "../../../../_base/helper/FunctionHelper";
import { sky_blue } from "../../../../const/variables";

const EditDataView = ({ id, onClose }: EditDataViewProps) => {
  const entity = {
    id: "0",
    name: "",
    code: "",
    note: null,
    active: false,
    createBy: null,
    createDate: null,
    lastUpdateBy: null,
    lastUpdateDate: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [enable, setEnable] = useState(false);

  const form = useForm<TblDMDeliveryMethod>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      name: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên cách giao hàng!";
        }
        return hasLength(
          { min: 5, max: 150 },
          "Tên phải từ 5-150 kí tự!"
        )(value as string);
      },
      code: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập mã cách giao hàng!";
        }
        return hasLength(
          { max: 50 },
          "Mã phải nhỏ hơn 50 kí tự!"
        )(value as string);
      },
      note: (value?: string | null) => {
        if (value) {
          return hasLength(
            { max: 250 },
            "Ghi chú phải nhỏ hơn 250 kí tự!"
          )(value as string);
        }
      },
    },
  });

  const handleEditTblDMDeliveryMethod = async (
    dataSubmit: TblDMDeliveryMethod
  ) => {
    open();
    const dataApi = await repositoryMdm.post<
      MessageResponse<TblDMDeliveryMethod>
    >("/api/v1/TblDmDeliveryMethod/update", dataSubmit);
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      modals.closeAll();
      NotificationExtension.Success("Cập nhật thành công!!!");
    }

    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<
      MessageResponse<TblDMDeliveryMethod>
    >(`/api/v1/TblDmDeliveryMethod/update?id=${id}`);
    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "280px", md: "336px", lg: "448px" }}
        onSubmit={form.onSubmit((e: TblDMDeliveryMethod) => {
          handleEditTblDMDeliveryMethod(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={6}>
            <TextInput
              label={"Mã cách giao hàng"}
              placeholder={"Nhập mã cách giao hàng"}
              withAsterisk
              onKeyDown={handleKeyDown}
              {...form.getInputProps("code")}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label={"Tên cách giao hàng"}
              placeholder={"Nhập tên cách giao hàng"}
              withAsterisk
              w={"100%"}
              {...form.getInputProps("name")}
            />
          </Grid.Col>
        </Grid>

        <Grid align="center">
          <Grid.Col span={12}>
            <Textarea
              label={"Ghi chú"}
              placeholder="Nhập ghi chú"
              w={"100%"}
              {...form.getInputProps("note")}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox
              label={"Sử dụng"}
              checked={form.values.active}
              {...form.getInputProps("active")}
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            disabled={!enable}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default EditDataView;

type EditDataViewProps = {
  id: string | number;
  onClose: any;
};
