import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Menu,
  NumberInput,
  rem,
  Select,
  SelectProps,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowBackUp,
  IconBrandGrammarly,
  IconBuildingSkyscraper,
  IconCalendar,
  IconCaretDown,
  IconChartCandle,
  IconCheck,
  IconFileExport,
  IconFileUpload,
  IconSearch,
  IconTransferIn,
  IconTransferOut,
  IconX,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import "../../../Styles/tab.component.css";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { DatePickerInput } from "@mantine/dates";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { green } from "../../../const/variables";
import { DefectiveItem } from "../../../model/TblFaultyItemList";
import { useDebouncedState } from "@mantine/hooks";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { IconCubeSend } from "@tabler/icons-react";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import OutWardWarrantyVendor from "./OutWardWarrantyVendor";
import InWardWarrantyVendor from "./InWardWarrantyVendor";

const DefectiveGoodsList = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [dataTemp, setDataTemp] = useState<DefectiveItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  const [dataVendorSelect, setDataVendorSelect] = useState<ComboboxItem[]>([]);
  const [dataItemStatusSelect] = useState<any[]>([
    { label: "Test lỗi", value: "Test lỗi" },
    { label: "Đang gửi NCC", value: "Đang gửi nhà cung cấp" },
    { label: "Chờ test", value: "Chờ test" },
    { label: "Test OK", value: "Test OK" },
    { label: "Gửi lại NCC", value: "Gửi lại nhà cung cấp" },
  ]);
  const [dataDebtStatusSelect] = useState<any[]>([
    { label: "Nợ khách", value: "NO_KHACH" },
    { label: "Đã trả khách", value: "DA_TRA_KHACH" },
    { label: "Nợ hàng kho", value: "NO_HANG_KHO" },
    { label: "Đã trả hàng kho", value: "DA_TRA_HANG_KHO" },
    { label: "NCC nợ", value: "NCC_NO" },
    { label: "Đã nhập từ NCC", value: "DA_NHAP_TU_NCC" },
  ]);
  const [selectStatusSerial, setSelectStatusSerial] = useState<string | null>(
    null
  );

  const [sourceCodeExport, setSourceCodeExport] = useState();
  console.log(sourceCodeExport);
  const [dataRowSelect, setDataRowSelect] = useState<any[]>([]);
  const [dataRowProps, setDataRowProps] = useDebouncedState<any[]>([], 300);
  const [tblItemTransactionHeaderCommand, setTblItemTransactionHeaderCommand] =
    useState({
      transactionId: null,
      transactionCode: null,
      transactionDate: new Date().toISOString(),
      subSourceId: null,
      // sourceCode: `XBHN${new Date().getDate()}.${new Date().getMonth()}.${new Date()
      //   .getFullYear()
      //   .toString()
      //   .slice(-2)}.${Math.floor(100000 + Math.random() * 900000)}`,
      sourceCode: null,
      sourceType: null,
      branchId: null,
      subInvId: null,
      departmentId: null,
      status: null,
      description: null,
      parentSerial: null,
      atribute1: null,
      atribute2: null,
      atribute3: null,
      atribute4: null,
      atribute5: null,
      atribute6: null,
      atribute7: null,
      atribute8: null,
      atribute9: null,
      atribute10: null,
      createBy: null,
      createDate: new Date().toISOString(),
      lastUpdateDate: new Date().toISOString(),
      lastUpdateBy: null,
      type: null,
      finishDate: new Date().toISOString(),
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      attribute16: null,
      attribute17: null,
      attribute18: null,
      attribute19: null,
      attribute20: null,
    });
  const [navigateInWard, setNavigateInWard] = useState(false);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    vendorName: "",
    itemStatus: "",
    debtStatus: "",
    serialStatus: "",
  });

  const [keySearch, setKeySearch] = useDebouncedState("", 500);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "errorNumber",
        header: "Số phiếu tiếp nhận hàng lỗi",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && renderedCellValue !== null ? (
            <Tooltip label="Xem chi tiết">
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                color={renderedCellValue === null ? "red" : green.base}
              >
                {renderedCellValue}
              </Badge>
            </Tooltip>
          ) : (
            <></>
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "ewHeaderCode",
        header: "Số phiếu xuất kho BH NCC",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Chưa xuất kho" : renderedCellValue}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 200,
      },
      {
        accessorKey: "iwItemHeaderCode",
        header: "Số phiếu nhập kho BH NCC",
        size: 200,
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Chưa nhập kho" : renderedCellValue}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "serial",
        header: "Mã serial",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={350}>
            <Tooltip label={row.original.itemName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.itemName}
              </Text>
            </Tooltip>
          </Box>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "quantity",
        header: "Số lượng",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "serialStatus",
        header: "Trạng thái serial",
        // Cell: ({ row }) => (
        //   <Badge
        //     color={getColorStatus(
        //       row.original.serialStatus?.toString() ?? null
        //     )}
        //     radius={"sm"}
        //   >
        //     {getValueById(
        //       row.original.serialStatus?.toString() ?? "",
        //       dataItemStatusSelect,
        //       "label"
        //     )}
        //   </Badge>
        // ),
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "vendorName",
        header: "Nhà cung cấp",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute1",
        header: "Loại hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "transactionType",
        header: "Loại giao dịch gần nhất",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "conclusionError",
        header: "Mô tả lỗi",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "itHeaderCode",
        header: "Số phiếu nhập kho BH",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && renderedCellValue !== null ? (
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue}
            </Badge>
          ) : (
            <></>
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "rcHeaderCode",
        header: "Số phiếu xuất trả",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && renderedCellValue !== null ? (
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue}
            </Badge>
          ) : (
            <></>
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "attribute7",
        header: "Trạng thái nợ",
        Cell: ({ row }) => (
          <Badge
            color={getColorDebtStatus(row.original.attribute7)}
            radius={"sm"}
            variant="light"
            bd={`1px solid ${getColorDebtStatus(row.original.attribute7)}`}
          >
            {getValueById(
              row.original.attribute7?.toString() ?? "",
              dataDebtStatusSelect,
              "label"
            )}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "subInvName",
        header: "Kho",
        Cell: ({ row }) => (
          <Text fw={500} size="12.5px">
            {row.original.subInvCode} - {row.original.subInvName}
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo phiếu",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "serialNote",
        header: "Ghi chú",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      // {
      //   accessorKey: "action",
      //   header: "Thao tác",
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnFilter: false,
      //   size: 10,
      //   Cell: ({ row }) => (
      //     <Box
      //       style={{
      //         display: "flex",
      //         alignItems: "center",
      //         gap: "10px",
      //       }}
      //     >
      //       <Tooltip label="Chi tiết">
      //         <ActionIcon variant="light" aria-label="Settings" color="cyan">
      //           <IconEye size={20} stroke={1.5} />
      //         </ActionIcon>
      //       </Tooltip>
      //     </Box>
      //   ),
      // },
    ],
    [dataItemStatusSelect, dataDebtStatusSelect]
  );

  const columnsProduct = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "warrantyItemNew",
        header: "Hạn BH mới",
        Cell: ({ row }) => (
          <NumberInput
            placeholder="Hạn BH mới"
            hideControls
            allowNegative={false}
            suffix=" tháng"
            onChange={(e) =>
              handleChange(row.original.serial, "warrantyItemNew", e)
            }
          />
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "serialStatus",
        header: "Trạng thái serial",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "invOrgName",
        header: "Kho",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
    ],
    [dataRowProps]
  );

  const getColorStatus = (value: string | null) => {
    switch (value) {
      case "TEST_LOI":
        return "#f21616";
      case "DANG_GUI_NCC":
        return "#099cff";
      case "CHO_TEST":
        return "#d9d02f";
      case "TEST_OK":
        return "#2bdd66";
      case "GUI_LAI_NCC":
        return "#f06418";
      case null:
        return "rgba(255, 255, 255, 0)";
    }
  };

  const getColorDebtStatus = (value: string | null) => {
    switch (value) {
      case "NO_KHACH":
      case "NO_HANG_KHO":
      case "NCC_NO":
        return "#f21616";
      case "DA_TRA_KHACH":
      case "DA_NHAP_TU_NCC":
      case "DA_TRA_HANG_KHO":
        return "#2bdd66";
      case null:
        return "rgba(255, 255, 255, 0)";
    }
  };

  const optionColorDebtStatus: Record<
    | "Nợ khách"
    | "Đã trả khách"
    | "Nợ hàng kho"
    | "Đã trả hàng kho"
    | "NCC nợ"
    | "Đã nhập từ NCC",
    string
  > = {
    "Nợ khách": "#f21616",
    "Đã trả khách": "#2bdd66",
    "Nợ hàng kho": "#f21616",
    "Đã trả hàng kho": "#2bdd66",
    "NCC nợ": "#f21616",
    "Đã nhập từ NCC": "#2bdd66",
  };

  const renderSelectOptionDebtStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorDebtStatus[
            option.label as
              | "Nợ khách"
              | "Đã trả khách"
              | "Nợ hàng kho"
              | "Đã trả hàng kho"
              | "NCC nợ"
              | "Đã nhập từ NCC"
          ]
        }
        radius="sm"
        variant="light"
        bd={`1px solid ${
          optionColorDebtStatus[
            option.label as
              | "Nợ khách"
              | "Đã trả khách"
              | "Nợ hàng kho"
              | "Đã trả hàng kho"
              | "NCC nợ"
              | "Đã nhập từ NCC"
          ]
        }`}
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  // const handleInWard = () => {
  //   navigate("/warehouse-config/create-warehouse-in-ward");
  // };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const handleChange = (serial: string, field: string, value: any) => {
    const updatedData = dataRowProps.map((item: any) =>
      item.serial?.toString() === serial?.toString()
        ? {
            ...item,
            [field]: value,
          }
        : item
    );
    setDataRowProps(updatedData);
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-bang-ke-hang-loi.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.vendorName) {
      url += `&VendorCode=${search.vendorName}`;
    }

    if (search.serialStatus) {
      url += `&SerialStatus=${search.serialStatus}`;
    }

    if (search.debtStatus) {
      url += `&DebtStatus=${search.debtStatus}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<DefectiveItem[]>
      >(`/api/v1/TblWarrantyReceiveTicket/get-list-defective-item${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(response.totalCount);
        setSelectIds([]);
        Promise.all([getDataVendorSelect()]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const handleShippingOutward = async (id: string | number) => {
    await repositoryDelivery.post<MessageResponse<any>>(
      "/api/v1/TblDelivery/create-delivery-from-transaction",
      { transactionId: id }
    );
  };

  const handleVendorWarrantyOutward = async (
    itemTransaction: any,
    dep: boolean
  ) => {
    const req = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Outward/create-vendor-warranty-outward",
      {
        sourceId: 0,
        tblItemTransactionHeaderCommand: {
          ...tblItemTransactionHeaderCommand,
          sourceType: "XUAT_KHO_BAO_HANH_NCC",
          sourceCode: sourceCodeExport,
          attribute20: JSON.stringify(itemTransaction),
        },
        serials: dataRowSelect,
        isLock: true,
        isAuto: true,
      }
    );

    if (req && req?.success) {
      const id = req?.data;
      if (dep) {
        handleShippingOutward(id?.data);
      }
      NotificationExtension.Success("Xuất bảo hành nhà cung cấp thành công !");
      setTimeout(() => {
        setDeleteViewStatus(!deleteViewStatus);
        modals.closeAll();
      }, 1000);
    }
  };

  const handleWarrantyOutward = async (serialStatusSelect: string) => {
    const req = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Outward/create-warranty-outward",
      {
        sourceId: 0,
        tblItemTransactionHeaderCommand: {
          ...tblItemTransactionHeaderCommand,
          sourceType: "XUAT_KHO_BAO_HANH",
        },
        serials: dataRowSelect?.map((item: any) => ({
          ...item,
          serialStatus:
            serialStatusSelect === "Test lỗi" ? "Trả khách" : "Trả khách (NCC)",
          transactionSourceTypeCode: "XUAT_KHO_BAO_HANH",
        })),
        isLock: true,
        isAuto: true,
      }
    );

    if (req && req?.success) {
      NotificationExtension.Success("Xuất trả khách thành công !");
      setTimeout(() => {
        setDeleteViewStatus(!deleteViewStatus);
      }, 1000);
    }
  };

  const handleWarrantyInward = async () => {
    const req = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/Inward/create-in-ward-vendor-warranty",
      {
        sourceId: 0,
        tblItemTransactionHeaderCommand: {
          ...tblItemTransactionHeaderCommand,
          sourceType: "NHAP_KHO_BAO_HANH_NCC",
        },
        serials: dataRowSelect?.map((item: any) => ({
          ...item,
          serialStatus: "Chờ test",
          transactionSourceTypeCode: "NHAP_KHO_BAO_HANH_NCC",
        })),
        isLock: true,
      }
    );

    if (req && req?.success) {
      NotificationExtension.Success("Nhập kho bảo hành thành công !");
      setTimeout(() => {
        setDeleteViewStatus(!deleteViewStatus);
        setNavigateInWard(false);
      }, 1000);
    }
  };

  const getDataVendorSelect = async () => {
    const getData = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblVendor/get-all"
    );

    if (getData && getData?.success) {
      const result = getData.data;
      setDataVendorSelect(
        result
          ?.filter(
            (item: any) => item.vendorName != null && item.vendorCode != null
          )
          ?.map((item: any) => ({
            value: item.vendorCode,
            label: item.vendorName,
          }))
      );
    }
  };

  function outWardWarrantyVendor() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xuất bảo hành nhà cung cấp</Title>
        </>
      ),
      size: "auto",
      children: (
        <OutWardWarrantyVendor
          handleVendorWarrantyOutward={handleVendorWarrantyOutward}
          invId={dataRowSelect[0].invId}
          dataRowProps={dataRowProps}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const modalsReturnCustomer = (serialStatusSelect: string) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xuất trả khách</Title>
        </>
      ),
      size: "auto",
      children: (
        <Box pt={10}>
          <MantineReactTable table={tableProduct} />
          <Flex justify={"end"} gap={"md"} mt={10}>
            <Button
              color="red"
              leftSection={<IconArrowBackUp size={14} />}
              variant="outline"
              onClick={() => modals.closeAll()}
            >
              Quay lại
            </Button>
            <Button
              color="teal"
              leftSection={<IconCheck size={14} />}
              variant="outline"
              onClick={() => {
                handleWarrantyOutward(serialStatusSelect);
                modals.closeAll();
              }}
            >
              Lưu
            </Button>
          </Flex>
        </Box>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus, pagination]);

  useEffect(() => {
    const numericIds = selectIds.map((id) => Number(id));

    const filtered = data.filter((item) =>
      numericIds.includes(Number(item.id))
    );

    const filtered2 = filtered?.map((item: any) => ({
      primaryQuantity: 1,
      subQuantity: item.subQuantity ? item.subQuantity : 0,
      serial: item.serial,
      invId: item.subInvId,
      serialStatus: "Đang gửi nhà cung cấp",
      serialNote: item.serialNote,
      itemCode: item.itemCode,
      itemName: item.itemName,
      transactionId: 0,
      organizationId: item?.invOrgId,
      inventoryItemId: item.itemId,
      serialAge: null,
      subinventoryCode: item.subInvCode,
      locatorId: item.positionid,
      transactionStatus: null,
      note: null,
      transactionTypeId: null,
      transactionSourceId: null,
      trxSourceLineId: null,
      transactionReference: null,
      transactionQuantity: 1,
      transactionUom: null,
      transactionDate: null,
      periodId: null,
      actualCost: null,
      transactionCost: null,
      priorCost: null,
      newCost: null,
      departmentId: null,
      operationSeqNum: null,
      transferTransactionId: null,
      transferOrganizationId: null,
      transferSubinventory: null,
      transferLocatorId: null,
      rcvTransactionId: null,
      shipmentNumber: null,
      freightCode: null,
      expCode: null,
      numberOfContainers: null,
      valueChange: null,
      percentageChange: null,
      errorCode: null,
      errorExplanation: null,
      costedFlag: null,
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      attribute5: null,
      attribute6: null,
      attribute7: null,
      attribute8: null,
      attribute9: null,
      attribute10: null,
      attribute11: null,
      attribute12: null,
      attribute13: null,
      attribute14: null,
      attribute15: null,
      formChange: "KHONG_DOI",
      supplierSlip: null,
      createdBy: null,
      creationDate: null,
      clentId: null,
      requestBy: null,
      requestDate: null,
      approveBy: null,
      approveDate: null,
      lastUpdatedBy: null,
      lastUpdateDate: null,
      primaryUom: item.uomCode,
      itemTransactionHeaderCode: item.attribute2,
      transactionSourceTypeCode: "XUAT_KHO_BAO_HANH_NCC",
      headerId: null,
      isDraf: true,
      soPrice: null,
    }));

    if (selectIds.length > 0) {
      setSelectStatusSerial(filtered[0]?.serialStatus);
      setDataRowProps(filtered);
    } else {
      setSelectStatusSerial(null);
    }
    setSourceCodeExport(filtered[0]?.attribute2);
    setDataRowSelect(filtered2);
    setTblItemTransactionHeaderCommand((prev) => ({
      ...prev,
      subInvId: filtered[0]?.subInvId,
      branchId: filtered[0]?.invOrgId,
    }));
  }, [selectIds]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    enableRowSelection: (row) => {
      if (selectStatusSerial === null || selectStatusSerial === "") {
        return true;
      } else {
        return row.original.serialStatus === selectStatusSerial;
      }
    },
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ row }: any) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify="end"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={<IconTransferIn size={14} />}
              color="blue"
              variant="outline"
              onClick={() => setNavigateInWard(true)}
              disabled={
                selectIds.length === 0 ||
                selectStatusSerial !== "Đang gửi nhà cung cấp"
              }
            >
              Nhập BH NCC
            </Button>
            <Button
              leftSection={<IconTransferOut size={14} />}
              color="blue"
              variant="outline"
              disabled={
                selectIds.length === 0 ||
                (selectStatusSerial !== "Test lỗi" &&
                  selectStatusSerial !== "Bán bình thường")
              }
              // onClick={() => handleVendorWarrantyOutward()}
              onClick={() => outWardWarrantyVendor()}
            >
              Xuất BH NCC
            </Button>
            <Button
              leftSection={<IconCubeSend size={14} />}
              color="green"
              variant="outline"
              onClick={() => {
                if (selectStatusSerial === "Test lỗi") {
                  modalsReturnCustomer(selectStatusSerial ?? "");
                } else {
                  handleWarrantyOutward(selectStatusSerial ?? "");
                }
              }}
              disabled={
                selectIds.length === 0 ||
                selectStatusSerial === "Trả khách" ||
                selectStatusSerial === "Đang gửi nhà cung cấp" ||
                selectStatusSerial === "Chờ test"
              }
            >
              Xuất trả khách
            </Button>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
                <Menu.Item
                  leftSection={
                    <IconX style={{ width: rem(14), height: rem(14) }} />
                  }
                >
                  Đóng
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 12, lg: 2.75 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Từ ngày - Đến ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconCalendar size={"22px"} color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "startDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Nhà cung cấp"
              data={dataVendorSelect}
              leftSection={<IconBuildingSkyscraper color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              comboboxProps={{ width: 425 }}
              onChange={(e) => handleChangeSearchValue(e ?? "", "vendorName")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Trạng thái sản phẩm"
              data={dataItemStatusSelect}
              leftSection={<IconChartCandle color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              onChange={(e) => handleChangeSearchValue(e ?? "", "serialStatus")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Trạng thái nợ"
              data={dataDebtStatusSelect}
              leftSection={<IconBrandGrammarly color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              onChange={(e) => handleChangeSearchValue(e ?? "", "debtStatus")}
              renderOption={renderSelectOptionDebtStatus}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              leftSection={<IconSearch color="#15aabf" />}
              defaultValue={keySearch}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  await fetchData();
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.25 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={fetchData}
              w={"100%"}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "itHeaderCode", "rcHeaderCode"],
        right: ["subInvName", "action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableProduct = useMantineReactTable({
    columns: columnsProduct,
    data: dataRowProps,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    renderTopToolbarCustomActions: ({ table }) => <></>,
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionNumber"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: 450, minHeight: 450 },
    },
    enableStickyHeader: true,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      {navigateInWard ? (
        <InWardWarrantyVendor
          back={setNavigateInWard}
          dataRowProps={dataRowSelect}
          setDataRowProps={setDataRowSelect}
          handleWarrantyInward={handleWarrantyInward}
        />
      ) : (
        <MantineReactTable table={table} />
      )}
    </>
  );
};

export default DefectiveGoodsList;
