import {
  Box,
  Checkbox,
  Group,
  LoadingOverlay,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMOrderType } from "../../../../model/TblDMOrderType";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    ordertype: null,
    name: null,
    note: null,
    active: null,
    linetype: null,
    lineck: null,
    industry: null,
    createDate: null,
    createBy: null,
    lastUpdateDate: null,
    lastUpdateBy: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMOrderType>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<TblDMOrderType>>(
      `/api/v1/TblDMOrderType/detail?id=${id}`
    );
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "280px", md: "336px", lg: "448px" }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Group wrap="nowrap" mt={10} gap={"lg"}>
          <TextInput
            variant="filled"
            readOnly
            label={"Loại đơn hàng"}
            {...form.getInputProps("ordertype")}
          />
          <TextInput
            variant="filled"
            readOnly
            label={"Tên đơn hàng"}
            w={"100%"}
            {...form.getInputProps("name")}
          />
        </Group>

        <Group grow wrap="nowrap" mt={10} mb={"md"} gap={"lg"} align="center">
          <Textarea
            label={"Ghi chú"}
            variant="filled"
            readOnly
            w={"100%"}
            {...form.getInputProps("note")}
          />
        </Group>
        <Checkbox
          label={"Sử dụng"}
          readOnly
          checked={form.values.active}
          {...form.getInputProps("active")}
          onChange={() => {}}
        />
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
