import {
  Box,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconWindow } from "@tabler/icons-react";
import { repositoryPos } from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { useEffect } from "react";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    groupCode: null,
    groupName: null,
    taxCode: null,
    address: null,
    phone: null,
    contactPerson: null,
    classification: null,
    classificationName: null,
    debtTerm: null,
    debtLimit: null,
    type: null,
    typeName: null,
    createBy: null,
    createByName: null,
    lastUpdateBy: null,
    lastCreateByName: null,
    lastUpdateDate: null,
    createDate: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);
  const form = useForm<any>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {},
  });

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDebtGroup/get-detail?id=${id}`
    );
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
    }
    close();
  };

  useEffect(() => {
    if (id) callApiGetData();
  }, [id]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{
          base: "300px",
          xs: "400px",
          sm: "500px",
          md: "650px",
          lg: "700px",
        }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              readOnly
              variant={"filled"}
              label={"Mã nhóm công nợ"}
              {...form.getInputProps("groupCode")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              readOnly
              variant={"filled"}
              label={"Tên nhóm công nợ"}
              {...form.getInputProps("groupName")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              readOnly
              variant={"filled"}
              label={"Mã số thuế"}
              {...form.getInputProps("taxCode")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              readOnly
              variant={"filled"}
              label={"Người giao dịch"}
              {...form.getInputProps("contactPerson")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              readOnly
              variant={"filled"}
              label={"Điện thoại"}
              {...form.getInputProps("phone")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              readOnly
              variant={"filled"}
              label={"Địa chỉ"}
              {...form.getInputProps("address")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              readOnly
              variant={"filled"}
              label={"Phân loại"}
              {...form.getInputProps("classification")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              readOnly
              variant={"filled"}
              label={"Tên phân loại"}
              {...form.getInputProps("classificationName")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <NumberInput
              readOnly
              variant={"filled"}
              label={"Thời hạn nợ"}
              placeholder={"Nhập thời hạn nợ"}
              value={Number(form.getValues()?.debtTerm)}
              {...form.getInputProps("debtTerm")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <NumberInput
              readOnly
              variant={"filled"}
              thousandSeparator=","
              hideControls
              label={"Giới hạn nợ"}
              value={Number(form.getValues()?.debtLimit)}
              {...form.getInputProps("debtLimit")}
            />
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
        </Group>
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
