import {
  Box,
  Checkbox,
  Group,
  LoadingOverlay,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useEffect } from "react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../../model/MessageResponse";
import { TblDMPaymentMethod } from "../../../../model/TblDMPaymentMethod";

const DetailDataView = ({ id }: DetailDataViewProps) => {
  const entity = {
    id: "0",
    name: null,
    code: null,
    note: null,
    active: false,
    paymentType: null,
    orderBy: null,
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<TblDMPaymentMethod>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
  });

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<
      MessageResponse<TblDMPaymentMethod>
    >(`/api/v1/TblDMPaymentMethod/detail?id=${id}`);
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{ base: "250px", md: "300px", lg: "400px" }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Group grow wrap="nowrap" mt={10} gap={"lg"}>
          <TextInput
            readOnly
            variant="filled"
            label={"Mã hình thức thanh toán"}
            type="text"
            {...form.getInputProps("code")}
          />
          <TextInput
            readOnly
            variant="filled"
            label={"Tên hình thức thanh toán"}
            type="text"
            {...form.getInputProps("name")}
          />
        </Group>

        <Group grow wrap="nowrap" mt={10} gap={"lg"}>
          <TextInput
            readOnly
            variant="filled"
            label={"Loại thanh toán"}
            type="text"
            {...form.getInputProps("paymentTypeName")}
          />
          <TextInput
            readOnly
            variant="filled"
            label={"Số thứ tự"}
            type="number"
            {...form.getInputProps("orderBy")}
          />
        </Group>

        <Group grow wrap="nowrap" mt={10} mb={"xs"} gap={"lg"} align="center">
          <Textarea
            label={"Ghi chú"}
            readOnly
            variant="filled"
            w={"100%"}
            {...form.getInputProps("note")}
          />
        </Group>
        <Checkbox
          label={"Sử dụng"}
          checked={form.values.active}
          readOnly
          {...form.getInputProps("active")}
          onChange={() => {}}
        />
      </Box>
    </>
  );
};

export default DetailDataView;

type DetailDataViewProps = {
  id: string | number;
};
