import { useDisclosure } from "@mantine/hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { TblDMApproveProposal } from "../../../model/TblDMApproveProposal";
import { useForm } from "@mantine/form";
import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Divider,
  FileInput,
  Flex,
  Grid,
  LoadingOverlay,
  Menu,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import {
  IconArrowLeft,
  IconCheck,
  IconDownload,
  IconEye,
  IconTrash,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { MessageResponse } from "../../../model/MessageResponse";
import { ComboboxItem } from "../../../model/ComboboxItem";
import { useLocation, useNavigate } from "react-router-dom";
import { sky_blue } from "../../../const/variables";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { SocketExtension } from "../../../_base/socket/socket";

type User = {
  id: number;
  code: number;
  fullname: string;
  departmentId: number | null;
  departmentName: string | null;
  roleId: number;
  roleName: string;
  branchId: number;
  brachName: string;
  workAddress: string | null;
};
export const EditProposalView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [height, setHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const [enable, setEnable] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [receiveUserList, setReceiveUserList] = useState<User[]>([]);
  const [username, setUsername] = useState("");
  const [visible, { open, close }] = useDisclosure(false);
  const priorityItems = useMemo<ComboboxItem[]>(
    () => [
      { label: "Cao", value: "1" },
      { label: "Thấp", value: "0" },
    ],
    []
  );
  const [selectedReceiveUser, setSelectedReceiveUser] = useState<User>({
    id: 0,
    code: 0,
    fullname: "",
    departmentId: null,
    departmentName: null,
    roleId: 0,
    roleName: "",
    branchId: 0,
    brachName: "",
    workAddress: null,
  });
  const entity = {
    id: "",
    code: "",
    idDepeartment: 0,
    idWorkAddress: "",
    idPart: 0,
    idRole: 0,
    idEmployee: 0,
    note: "",
    urlFile: "Không có file",
    priorityLevel: 0,
    active: null,
    respond: "",
    createDate: new Date(),
    createBy: 0,
    lastUpdateBy: 0,
    lastUpdateDate: new Date(),
    nameDepeartment: "",
    nameEmployee: "",
    nameRole: "",
    nmaePart: "",
    idDepeartmentReceive: 0,
    idPartReceive: 0,
    idRoleReceive: 0,
    idEmployeeReceive: 0,
    nameDepeartmentReceive: "",
    namePartReceive: "",
    nameRoleReceive: "",
    nameEmployeeReceive: "",
    fileNameExtension: "",
  };
  const form = useForm<TblDMApproveProposal>({
    mode: "controlled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      priorityLevel: (value: number | null) => {
        if (value === null || value === 2) {
          return "Vui lòng chọn mức độ ưu tiên!";
        }
      },
      idEmployeeReceive: (value: number | null) => {
        if (!value) {
          return "Vui lòng chọn nhân viên nhận đề xuất!";
        }
      },
    },
  });

  function formatDate(isoDateString: string | Date, add?: boolean): string {
    const date: Date = new Date(isoDateString);

    // Cộng thêm 7 tiếng
    if (add) date.setHours(date.getHours() + 7);
    // Lấy các thành phần ngày giờ sau khi cộng thêm số giờ
    const hours: string = date.getHours().toString().padStart(2, "0");
    const minutes: string = date.getMinutes().toString().padStart(2, "0");
    const seconds: string = date.getSeconds().toString().padStart(2, "0");
    const day: string = date.getDate().toString().padStart(2, "0");
    const month: string = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0
    const year: number = date.getFullYear();

    // Định dạng thành chuỗi "giờ:phút:giây ngày-tháng-năm"
    return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
  }
  // fetch

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<
      MessageResponse<TblDMApproveProposal>
    >(`/api/v1/TblApproposal/update?id=${location.state.id}`);
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        if (dataApi.nameEmployeeReceive && dataApi.idEmployeeReceive) {
          setUsername(
            dataApi.nameEmployeeReceive + " - " + dataApi.idEmployeeReceive
          );
        }
        setSelectedReceiveUser({
          ...selectedReceiveUser,
          brachName: dataApi.nameDepeartmentReceive ?? "",
          departmentName: dataApi.namePartReceive ?? "",
          roleName: dataApi.nameRoleReceive ?? "",
        });
      }
      close();
    } else {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
    }
  };
  const fetchDataReceiveUsers = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?KeySearch=${username}&Skip=0&Take=10`
    );

    if (response && response.success) {
      let result = response.data;
      setReceiveUserList(result);
    }
  };

  const handleSubmit = async (dataSubmit: TblDMApproveProposal) => {
    let data: TblDMApproveProposal = {
      ...dataSubmit,
    };
    if (selectedReceiveUser.id !== 0) {
      data.idEmployeeReceive = selectedReceiveUser.id;
      data.idDepeartmentReceive = selectedReceiveUser.branchId ?? 0;
      data.idPartReceive = selectedReceiveUser.departmentId ?? 0;
      data.idRoleReceive = selectedReceiveUser.roleId ?? 0;
    }
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
      formData.append("urlFile", "Development/ApproveProposal/" + data.code);
      const fileNameExtension = file.name.split(".").pop();
      formData.append(
        "fileNameExtension",
        `${form.getValues().code}.${fileNameExtension}`
      );
    }

    (Object.keys(data) as (keyof TblDMApproveProposal)[]).forEach((key) => {
      const value = data[key];
      if (value !== null && value !== undefined) {
        formData.append(key, String(value)); // Chuyển đổi thành string
      }
    });

    try {
      const response = await repositoryMdm.post(
        "/api/v1/TblApproposal/update",
        formData
      );
      if (response && response.success) {
        NotificationExtension.Success("Sửa đề xuất thành công!");
        SocketExtension.SendMessageToEmp(Number(data.idEmployeeReceive), {
          message: "Có một đề xuất mới chỉnh sửa gửi tới bạn!",
        });
        navigate("/proposal/approve-proposal");
      }
    } catch (error) {
      NotificationExtension.Fails("Sửa đề xuất thất bại!");
    }
  };

  const openFile = () => {
    modals.openConfirmModal({
      size: "auto",
      title: <Title order={5}>{form.values.code}</Title>,
      fullScreen: true,
      children: (
        <DocViewer
          style={{
            width: "fit-content",
            height: "fit-content",
            maxHeight: "80vh",
            minWidth: "60vh",
          }}
          documents={[
            `https://files.hacom.vn/hacom/${form.values.urlFile}`,
          ].map((file) => ({
            uri: file,
          }))}
          config={{
            header: {
              disableHeader: true,
            },
            pdfZoom: {
              zoomJump: 0.1,
              defaultZoom: 2,
            },
          }}
          pluginRenderers={DocViewerRenderers}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  // useEffect

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (230 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (username.length > 3) {
      fetchDataReceiveUsers();
    }
  }, [username]);

  useEffect(() => {
    const getAllData = async () => {
      return Promise.all([callApiGetData()]);
    };
    getAllData();
  }, [location]);

  useEffect(() => {
    if (form.isDirty() || file) {
      setEnable(true);
    } else {
      setEnable(false);
    }

    if (file && file.size >= 5 * 1024 * 1024) {
      form.setFieldError("file", "File quá lớn, vui lòng chọn file dưới 5mb!");
      setEnable(false);
    } else form.clearFieldError("file");
  }, [form, file]);

  return (
    <>
      <Box
        component="form"
        onSubmit={form.onSubmit((e: TblDMApproveProposal) => {
          handleSubmit(e);
        })}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex justify={"end"} style={{ position: "sticky" }} mb={10} gap={8}>
          <Button
            variant="outline"
            color={sky_blue.light}
            leftSection={<IconArrowLeft size={16} />}
            onClick={() => {
              navigate("/proposal/approve-proposal");
            }}
          >
            Trở lại
          </Button>
          <Button
            variant="outline"
            leftSection={<IconCheck size={16} />}
            type="submit"
            disabled={!enable}
          >
            Lưu
          </Button>
        </Flex>

        <Box p={15} bg={"#f8f8f8"}>
          <Grid gutter={"xs"}>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p={0}
                style={{
                  maxHeight: height,
                  minHeight: height,
                }}
              >
                <Badge variant="gradient" size="lg" m={12}>
                  Sửa phiếu đề xuất
                </Badge>
                <Table
                  striped
                  horizontalSpacing="sm"
                  withColumnBorders
                  withTableBorder
                >
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th w={"25%"}></Table.Th>
                      <Table.Th w={"25%"}>
                        <Center>
                          <Text fw={700}>Người lập</Text>
                        </Center>
                      </Table.Th>
                      <Table.Th w={"25%"}>
                        <Center>
                          <Text fw={700}>Người nhận</Text>
                        </Center>
                      </Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Thời gian tạo</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{formatDate(form.values.createDate)}</Text>
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Mã đề xuất</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.getValues().code}</Text>
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Họ và tên</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>
                          {form.values.nameEmployee +
                            " - " +
                            form.values.idEmployee}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Menu
                          shadow="md"
                          width={240}
                          trigger="hover"
                          openDelay={100}
                          closeDelay={400}
                        >
                          <Menu.Target>
                            <TextInput
                              placeholder="Nhập tên người nhận"
                              error={form.errors.idEmployeeReceive}
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </Menu.Target>
                          <Menu.Dropdown
                            mah={240}
                            style={{
                              overflowY: "auto",
                            }}
                          >
                            <Table.ScrollContainer
                              minWidth={180}
                              mah={200}
                              type="native"
                            >
                              <Table highlightOnHover>
                                <Table.Tbody>
                                  {receiveUserList &&
                                  receiveUserList.length > 0 &&
                                  username.length > 2 ? (
                                    receiveUserList.map((item, index) => (
                                      <Table.Tr key={index}>
                                        <Table.Td
                                          onClick={() => {
                                            form.setValues((prev) => ({
                                              ...prev,
                                              idEmployeeReceive: Number(
                                                item.id
                                              ),
                                            }));
                                            setSelectedReceiveUser(item);
                                            setUsername(
                                              item.fullname + " - " + item.id
                                            );
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Text size="xs">
                                            {item.fullname + " - " + item.id}
                                          </Text>
                                        </Table.Td>
                                      </Table.Tr>
                                    ))
                                  ) : (
                                    <Table.Tr>
                                      <Table.Td ta={"center"}>
                                        <Text size="xs" c={"#B5B5CC"}>
                                          {username === null ||
                                          username?.length < 3
                                            ? "Nhập tối thiểu 3 kí tự"
                                            : "Không tìm thấy dữ liệu !"}
                                        </Text>
                                      </Table.Td>
                                    </Table.Tr>
                                  )}
                                </Table.Tbody>
                              </Table>
                            </Table.ScrollContainer>
                          </Menu.Dropdown>
                        </Menu>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Chức danh</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.values.nameRole}</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{selectedReceiveUser.roleName ?? ""}</Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Bộ phận</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.values.nmaePart ?? ""}</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{selectedReceiveUser?.departmentName ?? ""}</Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Phòng ban</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.values.nameDepeartment ?? ""}</Text>
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Địa điểm làm việc</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text>{form.values.idWorkAddress ?? ""}</Text>
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>
                        <Text fw={700}>Mức độ ưu tiên</Text>
                      </Table.Td>
                      <Table.Td>
                        <Select
                          label="Mức độ ưu tiên"
                          placeholder="Nhập mức độ ưu tiên"
                          data={priorityItems}
                          error={form.errors.priorityLevel}
                          defaultSearchValue={
                            priorityItems[form.values.priorityLevel].label
                          }
                          onChange={(value) => {
                            if (value)
                              form.setFieldValue(
                                "priorityLevel",
                                Number(value)
                              );
                            else form.setFieldValue("priorityLevel", 2);
                          }}
                          nothingFoundMessage="Không tìm thấy dữ liệu !"
                        />
                      </Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                  </Table.Tbody>
                </Table>
              </Card>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                p="lg"
                style={{
                  maxHeight: height,
                  minHeight: height,
                }}
              >
                <Center>
                  <Text fw={700} size={"xl"}>
                    Nội dung phiếu đề xuất
                  </Text>
                </Center>

                <Grid>
                  <Grid.Col span={{ base: 12 }}>
                    <Textarea
                      placeholder="Nhập nội dung đề xuất"
                      size="lg"
                      minRows={6}
                      maxRows={6}
                      autosize
                      {...form.getInputProps("note")}
                    />
                  </Grid.Col>

                  <Grid.Col pb={0} span={{ base: 12 }}>
                    <Divider
                      my="xs"
                      label={
                        <Text size="lg" fw={600} c="#000">
                          File đính kèm
                        </Text>
                      }
                      labelPosition="left"
                    />
                  </Grid.Col>
                  {form.values.urlFile !== "Không có file" ? (
                    <Grid.Col>
                      <Flex gap={12} justify={"start"}>
                        <Button
                          id="btn_delete_file_proposal"
                          variant="outline"
                          color="red"
                          leftSection={<IconTrash />}
                          onClick={() => {
                            form.setFieldValue("urlFile", "Không có file");
                            form.setFieldValue("fileNameExtension", null);
                          }}
                        >
                          Xóa file cũ
                        </Button>
                        {[
                          "jpg",
                          "jpeg",
                          "xls",
                          "png",
                          "pdf",
                          "doc",
                          "docx",
                          "xlsx",
                        ].includes(
                          form.values.urlFile?.split(".").pop() ?? ""
                        ) && (
                          <Button
                            leftSection={<IconEye></IconEye>}
                            onClick={openFile}
                          >
                            Xem file đính kèm
                          </Button>
                        )}
                        <a
                          href={`https://files.hacom.vn/hacom/${form.values.urlFile}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button leftSection={<IconDownload />}>
                            Tải xuống file
                          </Button>
                        </a>
                      </Flex>
                    </Grid.Col>
                  ) : (
                    <Grid.Col>
                      <FileInput
                        id="file_attachment"
                        name="file_attachment"
                        error={form.errors.file}
                        accept="application/vnd.rar,application/zip,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        onChange={setFile}
                        placeholder="Chọn file đính kèm"
                        clearable
                      />
                    </Grid.Col>
                  )}
                </Grid>
              </Card>
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
