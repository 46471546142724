import { Box, Button, Group, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { repositoryMdm } from "../../../../_base/_const/_constVar";
import { MessageResponse } from "../../../../model/MessageResponse";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";

const DeleteView = ({ idItems, onClose }: DeleteProduct) => {
  const handleDeleteBranch = async () => {
    const res = await repositoryMdm.post<MessageResponse<boolean>>(
      "/api/v1/TblDmDeliverPriority/delete",
      idItems
    );
    if (res)
      if (res.success) {
        NotificationExtension.Success("Xóa thành công !");
        onClose((prev: any) => !prev);
      } else NotificationExtension.Fails("Xóa thất bại !");
    modals.closeAll();
  };

  return (
    <Box size={"auto"}>
      <Text size="20px" mt={5}>
        Bạn có chắc chắn muốn xóa các danh mục mức độ ưu tiên giao hàng ?
      </Text>
      <Group justify="center" mt="lg">
        <Button
          type="button"
          color="gray"
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Hủy
        </Button>
        <Button
          type="button"
          color="red"
          onClick={handleDeleteBranch}
          leftSection={<IconCheck size={18} />}
        >
          Xóa
        </Button>
      </Group>
    </Box>
  );
};

type DeleteProduct = {
  idItems: (number | string)[];
  onClose: any;
};

export default DeleteView;
